import React from "react";
import { Paper, InputLabel } from '@mui/material';
import { styled } from '@mui/system';

import "./styles.css";

const PaperStyles = styled(Paper)(({ theme }) => ({
  width: "10rem",
  height: "3.5rem",
  textAlign: "center",
  fontWeight: "bold",
  fontSize: "0.8rem",
}));

const InputLabelStyles = styled(InputLabel)(({ theme }) => ({
  color: "black",
  fontSize: "1.5rem",
}));

export default function Totalizador(props) {
  const {
    total,
    coletado,
    agendado,
    limite
  } = props;

  const titulo = ['Limite', 'Coletado', 'Agendado', 'Total'];
  const valor = [limite, coletado, agendado, total];

  return (
    <div 
      style={{
        display: "flex",
        flexWrap: "wrap",
        flexDirection: "row",
        justifyContent: "space-around",
        padding: "0.5rem" 
      }}
    >
      <PaperStyles>
        <InputLabel>{titulo[0]}</InputLabel>
        <InputLabelStyles>
          {valor[0]}
        </InputLabelStyles>
      </PaperStyles>
      <PaperStyles>
        <InputLabel>{titulo[1]}</InputLabel>
        <InputLabelStyles>
          {valor[1]}
        </InputLabelStyles>
      </PaperStyles>
      <PaperStyles>
        <InputLabel>{titulo[2]}</InputLabel>
        <InputLabelStyles>
          {valor[2]}
        </InputLabelStyles>
      </PaperStyles>
      <PaperStyles>
        <InputLabel>{titulo[3]}</InputLabel>
        <InputLabelStyles>
          {valor[3]}
        </InputLabelStyles>
      </PaperStyles>
    </div>
  )
}


