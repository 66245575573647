import React, { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { Button, Dialog, Input, InputLabel, Paper } from "@mui/material";
import { styled } from "@mui/system";
import { format } from "date-fns";

import BodyModal from "../../../components/BodyModal";
// import DatePicker from "../../../components/DatePicker";
import DataPickerAtualizado from "../../../components/DataPickerAtualizado";

import { api } from "../../../services/Api";
import dayjs from "dayjs";

const ContainerStyled = styled(Paper)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  margin: "0.5rem 2rem",
  padding: "0.7rem 4rem 2rem 4rem",
}));

const TitleStyled = styled(InputLabel)(({ theme }) => ({
  display: "flex",
  color: "#000",
  fontWeight: "bold",
  marginBottom: "1rem",
}));

const ButtonStyled = styled(Button)(({ theme }) => ({
  backgroundColor: "#003399",
  color: "#FFF",
}));

export default function ModalLimite(props) {
  const { enqueueSnackbar } = useSnackbar();

  const { exibeModalLimites, setExibeModalLimites, limite } = props;

  const [data, setData] = useState(new Date());
  const [limiteDia, setLimiteDia] = useState("");

  const [limitePadrao, setLimitePadrao] = useState([]);

  useEffect(() => {
    async function iniciar() {
      try {
        const dataInformada = format(data, "yyyy-MM-dd");
        const { data: result } = await api.get(
          `/api/agendamentos/limite/${dataInformada}/Drive-Thru`
        );
        return setLimiteDia(result.limite);
      } catch (error) {
        return null;
      }
    }
    iniciar();
  }, [data, limite]);

  useEffect(() => {
    async function iniciar() {
      try {
        const { data: result } = await api.get(`/v2/agenda/limitePadrao`);
        setLimitePadrao(result);
      } catch (error) {
        enqueueSnackbar(
          "Não foi possível obter os limites padrão, tente novamente.",
          { variant: "error" }
        );
      }
    }
    iniciar();
  }, [enqueueSnackbar]);

  async function alteraLimite() {
    try {
      await api.put(`/api/agendamentos/limiteDia`, { data, limiteDia });
      enqueueSnackbar("Limite alterado.", { variant: "success" });
    } catch (error) {
      enqueueSnackbar(
        "Não foi possível obter os limites padrão, tente novamente.",
        { variant: "error" }
      );
    }
  }

  function fechaModalLimite() {
    return setExibeModalLimites(false);
  }

  return (
    <>
      <Dialog
        fullWidth
        maxWidth="md"
        open={exibeModalLimites}
        onClose={() => setExibeModalLimites(false)}
      >
        <BodyModal
          titulo="Definir Limites"
          fechaModal={() => fechaModalLimite()}
        >
          <ContainerStyled style={{ marginTop: "1.5rem" }}>
            <TitleStyled>Define limite do dia</TitleStyled>
            <div
              style={{
                display: "flex",
                width: "60%",
                flexDirection: "row",
                flexWrap: "wrap",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              {/* <DatePicker
                format="dd/MM/yyyy"
                style={{ marginRight: "1rem", width: "6rem" }}
                label="Data"
                value={dayjs(data)}
                onChange={setData}
              /> */}
              <DataPickerAtualizado
                data={dayjs(data)}
                setData={setData}
                variant="inline"
                labelName={"Data"}
              />
              <div>
                <InputLabel>Limite</InputLabel>
                <Input
                  placeholder="Defina um limite"
                  value={limiteDia}
                  onChange={(e) => {
                    const num = e.target.value;
                    // eslint-disable-next-line
                    if (!isNaN(num)) {
                      setLimiteDia(Number(num));
                    }
                  }}
                >
                  {limiteDia}
                </Input>
              </div>
              <ButtonStyled variant="contained" onClick={() => alteraLimite()}>
                Alterar
              </ButtonStyled>
            </div>
          </ContainerStyled>
          <ContainerStyled style={{ marginBottom: "2.5rem" }}>
            <TitleStyled>Define limite padrão</TitleStyled>
            <div
              style={{
                display: "flex",
                width: "60%",
                flexDirection: "row",
                flexWrap: "wrap",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "1rem",
              }}
            >
              <InputLabel>Dia da Semana</InputLabel>
              <InputLabel>Limite</InputLabel>
            </div>
            {limitePadrao.map((l) => (
              <div
                key={l.id}
                style={{
                  display: "flex",
                  width: "60%",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <InputLabel>{l.dia_semana}</InputLabel>
                <Input
                  type="number"
                  placeholder="Defina um limite"
                  value={l.limite}
                  onChange={() => {}}
                >
                  {limiteDia}
                </Input>
              </div>
            ))}
          </ContainerStyled>
        </BodyModal>
      </Dialog>
    </>
  );
}
