/* eslint-disable react/jsx-wrap-multilines */
import React, { useState, useMemo, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  InputAdornment,
  TextField,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Paper,
  Snackbar,
  TablePagination,
} from '@mui/material';
import { DataGrid } from "@mui/x-data-grid";
import { styled } from '@mui/system';
import SearchIcon from '@mui/icons-material/Search';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import CancelIcon from "@mui/icons-material/Cancel";
import DescriptionIcon from "@mui/icons-material/Description";
import AnnouncementIcon from "@mui/icons-material/Announcement";
import { useSnackbar } from "notistack";

import Button from "../../../../components/Button";
import Dialog from "../../../../components/Dialog";
import OptionsModal from "../../../../components/OptionsModal";
import Loading from "../../../../components/Loading";
import Modal from "../../../../components/Modal";

import { api } from "../../../../services/Api";

import debounce from "../../../../utils/debounce";
import withProps from "../../../../utils/withProps";

import "./styles.css";

function CustomPagination({ count, rowsPerPage, page, onPageChange, ...rest }) {
  const totalAmountOfPages = Math.ceil(count / rowsPerPage);

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        borderBottom: "1px solid rgba(224, 224, 244, 1)",
        width: "100%",
        paddingTop: "5px",
      }}
    >
      <FormControl style={{ minWidth: "50px", marginTop: "-16px" }}>
        <InputLabel id="select-page-label">Pág</InputLabel>
        <Select
          labelId="select-page-label"
          value={page}
          onChange={e => onPageChange(e.target.value)}
        >
          {new Array(totalAmountOfPages).fill(0).map((_, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <MenuItem key={index} value={index}>
              {index + 1}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <TablePagination
        count={count}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={(_, newPage) => onPageChange(newPage)}
        style={{ border: "none" }}
        {...rest}
      />
    </div>
  );
}

const useSnackbarStyles = styled(() => ({
  root: {
    backgroundColor: "#003399",
    fontWeight: 500,
    fontSize: "1rem",
  },
}));

const useDataGridStyles = styled(() => ({
  root: {
    backgroundColor: "white",
    marginBottom: "2rem",
  },
  row: {
    "&:hover": {
      cursor: "pointer",
    },
  },
}));

const dataGridDateGetter = params => {
  const { value } = params;
  const date = new Date(value);
  return `${date.toLocaleDateString("pt-BR", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  })} - ${date.toLocaleTimeString("pt-BR", {
    hour: "2-digit",
    minute: "2-digit",
  })}`;
};

const dataGridTitleSubtitleGetter = params => params.value || "-";

export default function Conteudo() {
  const [showDialog, setShowDialog] = useState(false);
  const [contentType, setContentType] = useState("artigos");
  const [showOptionsModal, setShowOptionsModal] = useState(false);
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState([]);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [selectedRow, setSelectedRow] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [searchInputValue, setSearchInputValue] = useState("");
  const [deleteList, setDeleteList] = useState([]);
  const [showDeleteSnackbar, setShowDeleteSnackbar] = useState(false);
  const inputRef = useRef(null);
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useSnackbarStyles();
  const dataGridClasses = useDataGridStyles();

  // options for the OptionsModal
  const options = useMemo(
    () => [
      {
        title: "Artigo",
        subtitle: "Um artigo de conhecimento técnico",
        onClick: () => history.push("/portalMedico/editor/artigo"),
        icon: <DescriptionIcon style={{ color: "rgba(0,0,0,0.6)", fontSize: "40px" }} />,
      },
      {
        title: "Post",
        subtitle: "Algo que você queira noticiar ou algum conteúdo corriqueiro",
        onClick: () => history.push("/portalMedico/editor/post"),
        icon: <AnnouncementIcon style={{ color: "rgba(0,0,0,0.6)", fontSize: "40px" }} />,
      },
    ],
    [history]
  );

  const columns = useMemo(() => {
    if (contentType === "artigos") {
      return [
        {
          field: "titulo",
          headerName: "Título",
          flex: 1,
          valueGetter: dataGridTitleSubtitleGetter,
          minWidth: 150,
        },
        {
          field: "subtitulo",
          headerName: "Subtítulo",
          flex: 0.8,
          valueGetter: dataGridTitleSubtitleGetter,
          minWidth: 150,
        },
        { field: "estado", headerName: "Estado", flex: 0.4, minWidth: 100 },
        {
          field: "alterado_em",
          headerName: "Editado em",
          sortable: true,
          flex: 0.4,
          valueGetter: dataGridDateGetter,
          minWidth: 125,
        },
        {
          field: "likes",
          headerName: "Likes",
          sortable: true,
          type: "number",
          flex: 0.3,
          minWidth: 50,
        },
        {
          field: "dislikes",
          headerName: "Dislikes",
          sortable: true,
          type: "number",
          flex: 0.3,
          minWidth: 50,
        },
      ];
    }

    return [
      {
        field: "titulo",
        headerName: "Título",
        flex: 1,
        valueGetter: dataGridTitleSubtitleGetter,
      },
      {
        field: "alterado_em",
        headerName: "Editado em",
        sortable: true,
        flex: 0.5,
        valueGetter: dataGridDateGetter,
      },
      { field: "likes", headerName: "Likes", sortable: true, type: "number", flex: 0.4 },
      {
        field: "dislikes",
        headerName: "Dislikes",
        sortable: true,
        type: "number",
        flex: 0.4,
      },
    ];
  }, [contentType]);

  useEffect(() => {
    setDeleteList([]);
  }, [contentType]);

  useEffect(() => {
    (async () => {
      const url =
        contentType === "artigos" ? `/v2/portalMedico/artigos` : `/v2/portalMedico/posts`;
      setLoading(true);
      try {
        const {
          data: { result, count: amount },
        } = await api.get(`${url}?offset=${page * 50}&search=${searchInputValue}`);
        setRows(result);
        setCount(amount);
      } catch (e) {
        enqueueSnackbar(`Não foi possível recuperar os ${contentType}.`, {
          variant: "error",
        });
      }
      setLoading(false);
    })();
  }, [enqueueSnackbar, contentType, page, searchInputValue]);

  const openDialog = row => {
    setShowDialog(true);
    setSelectedRow(row);
  };

  const closeDialog = () => {
    setShowDialog(false);
    setSelectedRow(null);
  };

  const editRow = () => {
    const path =
      contentType === "artigos"
        ? `/portalMedico/editor/artigo/${selectedRow.id}`
        : `/portalMedico/editor/post/${selectedRow.id}`;
    history.push(path);
  };

  const deleteRow = async () => {
    const url = `/v2/portalMedico/${contentType === "artigos" ? "artigo" : "post"}/${
      selectedRow.id
    }`;
    const targetIndex = rows.findIndex(row => row.id === selectedRow.id);
    setLoading(true);
    try {
      await api.delete(url);
      const copy = [...rows];
      copy.splice(targetIndex, 1);
      setRows(copy);
      setCount(prev => prev - 1);
      enqueueSnackbar(`${contentType === "artigos" ? "Artigo" : "Post"} excluído!`, {
        variant: "success",
      });
    } catch (e) {
      enqueueSnackbar(
        `Não foi possível deletar este ${
          contentType === "artigos" ? "artigo" : "post"
        }. Por favor, tente novamente.`,
        { variant: "error" }
      );
    }
    setShowDeleteModal(false);
    setLoading(false);
  };

  const bulkDelete = async () => {
    const url = `/v2/portalMedico/${contentType}`;
    setLoading(true);
    try {
      await api.delete(url, {
        data: {
          [contentType]: deleteList,
        },
      });
      const notDeletedRows = rows.filter(
        row => deleteList.findIndex(id => id === row.id) === -1
      );
      setRows(notDeletedRows);
      setCount(prev => prev - deleteList.length);
      setDeleteList([]);
      enqueueSnackbar("Operação concluída com sucesso!", { variant: "success" });
    } catch (e) {
      enqueueSnackbar(
        "Não conseguimos completar esta ação. Por favor, tente novamente.",
        { variant: "error" }
      );
    }
    setShowDeleteModal(false);
    setLoading(false);
  };

  const onConfirmDelete = async () => {
    const isBulkDeleting = deleteList.length > 0;
    if (isBulkDeleting) {
      await bulkDelete();
    } else {
      await deleteRow();
    }
  };

  return (
    <>
      {loading && <Loading />}
      <OptionsModal
        options={options}
        open={showOptionsModal}
        onClose={() => setShowOptionsModal(false)}
      />
      <Modal
        title={`Excluir ${contentType === "artigos" ? "artigo" : "post"}`}
        text="Tem certeza que deseja continuar com esta ação?"
        open={showDeleteModal}
        closeModal={() => setShowDeleteModal(false)}
        onCancel={() => {
          setDeleteList([]);
          setShowDeleteModal(false);
        }}
        onConfirm={onConfirmDelete}
      />
      <Snackbar
        ContentProps={{ classes }}
        open={showDeleteSnackbar}
        message={`${deleteList.length} ${
          deleteList.length > 1 ? `itens` : `item`
        } selecionado${deleteList.length > 1 ? "s" : ""}`}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        action={
          <>
            <div>
              <Button
                onClick={() => {
                  setShowDeleteSnackbar(false);
                  setDeleteList([]);
                }}
                startIcon={<CancelIcon />}
              >
                Cancelar
              </Button>
            </div>

            <div>
              <Button
                onClick={() => {
                  setShowDeleteSnackbar(false);
                  setShowDeleteModal(true);
                }}
                startIcon={<DeleteIcon />}
              >
                Excluir
              </Button>
            </div>
          </>
        }
      />

      <Dialog open={showDialog} title="Opções">
        <List>
          <ListItem onClick={editRow}>
            <ListItemIcon>
              <EditIcon />
            </ListItemIcon>
            <ListItemText>Editar</ListItemText>
          </ListItem>
          <ListItem
            onClick={() => {
              setShowDeleteModal(true);
              setShowDialog(false);
            }}
          >
            <ListItemIcon>
              <DeleteIcon />
            </ListItemIcon>
            <ListItemText>Excluir</ListItemText>
          </ListItem>
          <ListItem onClick={closeDialog}>
            <ListItemIcon>
              <KeyboardBackspaceIcon />
            </ListItemIcon>
            <ListItemText>Voltar</ListItemText>
          </ListItem>
        </List>
      </Dialog>
      <Paper style={{ padding: "1.25rem", marginBottom: "3rem" }}>
        <div className="conteudo-top-container">
          <FormControl style={{ width: "200px" }}>
            <InputLabel id="tipo-conteudo-label">Tipo do conteúdo</InputLabel>
            <Select
              labelId="tipo-conteudo-label"
              value={contentType}
              onChange={e => {
                const {
                  target: { value },
                } = e;
                setContentType(value);
              }}
            >
              <MenuItem value="artigos">Artigos</MenuItem>
              <MenuItem value="posts">Posts</MenuItem>
            </Select>
          </FormControl>
          <div className="top-container-actions">
            <Button
              onClick={() => setShowOptionsModal(true)}
              style={{
                fontSize: "1.1rem",
                padding: ".5rem .75rem",
                marginLeft: ".75rem",
              }}
            >
              Criar
            </Button>
          </div>
        </div>
      </Paper>
      <TextField
        style={{ marginLeft: "auto", marginBottom: "15px" }}
        placeholder="Busca"
        inputRef={inputRef}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <SearchIcon style={{ color: "rgba(0,0,0,0.6)", cursor: "pointer" }} />
            </InputAdornment>
          ),
          onChange: e => {
            const {
              target: { value },
            } = e;
            debounce(() => setSearchInputValue(value), 500);
          },
        }}
      />
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={50}
        checkboxSelection
        autoHeight
        disableColumnFilter
        disableSelectionOnClick
        onRowClick={params => openDialog(params.row)}
        page={page}
        paginationMode="server"
        rowsPerPageOptions={[]}
        onPageChange={newPage => setPage(newPage)}
        rowCount={count}
        classes={dataGridClasses}
        localeText={{
          footerRowSelected: selectCount =>
            selectCount !== 1
              ? `${selectCount} itens selecionados`
              : "1 item selecionado",
          MuiTablePagination: {
            labelDisplayedRows: pagInfo => {
              const { from, to, count: total } = pagInfo;
              return `${from}-${to} de ${total}`;
            },
          },
          noRowsLabel: "Parece que não há nada por aqui",
        }}
        selectionModel={deleteList}
        onSelectionModelChange={model => {
          if (model.length === 0) {
            setShowDeleteSnackbar(false);
          } else {
            setShowDeleteSnackbar(true);
          }
          setDeleteList(model);
        }}
        components={{
          Pagination: withProps(CustomPagination, {
            count,
            page,
            onPageChange: newPage => setPage(newPage),
            rowsPerPageOptions: [],
            rowsPerPage: 50,
          }),
        }}
      />
    </>
  );
}
