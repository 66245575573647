/* Ao adicionar um filtro, podemos ter diferentes tipos de valores e componentes
para preencher esses valores. Por isso, vou encapsular tudo nesse componente */
import React from "react";
import {
  Select,
  TextField,
  FormControl,
  MenuItem,
  InputLabel,
} from '@mui/material';

export default function FiltroValor({ tipo, atualizarValor, valor }) {
  const valorCorrespondente = {
    Cliente: (
      <TextField
        label="Nome do cliente"
        valor={valor}
        style={{ width: "45%" }}
        onChange={(event) => {
          atualizarValor(event.target.value);
        }}
      />
    ),
    Respondido: (
      <FormControl style={{ width: "45%" }}>
        <InputLabel id="novo-filtro-valor-label">Valor</InputLabel>
        <Select
          labelId="novo-filtro-valor-label"
          id="novo-filtro-valor"
          label="Valor"
          value={valor}
          onChange={(event) => {
            atualizarValor(event.target.value);
          }}
        >
          <MenuItem value="Sim">Sim</MenuItem>
          <MenuItem value="Não">Não</MenuItem>
        </Select>
      </FormControl>
    ),
    Notificado: (
      <FormControl style={{ width: "45%" }}>
        <InputLabel id="novo-filtro-valor-label">Valor</InputLabel>
        <Select
          labelId="novo-filtro-valor-label"
          id="novo-filtro-valor"
          label="Valor"
          value={valor}
          onChange={(event) => {
            atualizarValor(event.target.value);
          }}
        >
          <MenuItem value="Sim">Sim</MenuItem>
          <MenuItem value="Não">Não</MenuItem>
        </Select>
      </FormControl>
    ),
  };
  return valorCorrespondente[tipo];
}
