import { Paper as MuiPaper } from "@mui/material";
import React from "react";

export default function Paper({ children, ...rest }) {
  return (
    <MuiPaper elevation={2} sx={{ padding: 1 }} {...rest}>
      {children}
    </MuiPaper>
  );
}
