import React, { useEffect, useState, useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useSnackbar } from "notistack";
import {
  Autocomplete,
  Button as MuiButton,
  Divider,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import Button from "../../../../../../componentsv2/Button";
import { Input } from "../../../../../../componentsv2/Input";

import DataPickerAtualizado from "../../../../../../components/DataPickerAtualizado";
import SelectUnidade from "../../../../components/SelectUnidade";
import PesquisaUsuario from "../../../../components/PesquisaUsuario";
import SelectSetor from "../../../../components/SelectSetor";

import { Context as PermissoesContext } from "../../../../../../Context/PermissoesContext";
import useUser from "../../../../../../hooks/useUser";

import { api } from "../../../../../../services/Api";
import Header from "../../../../../../components/Header";
import Container from "../../../../../../componentsv2/Container";

export default function CriaTransferencia() {
  const usuario = useUser();
  const history = useHistory();
  const [unidadeOrigem, setUnidadeOrigem] = useState("");
  const [setorOrigem, setSetorOrigem] = useState("");
  const [usuarioOrigem, setUsuarioOrigem] = useState(null);
  const [unidadeDestino, setUnidadeDestino] = useState("");
  const [setorDestino, setSetorDestino] = useState("");
  const [usuarioDestino, setUsuarioDestino] = useState(null);
  const [bem, setBem] = useState(null);
  const [dataSolicitacao, setDataSolicitacao] = useState(new Date());
  const [observacao, setObservacao] = useState("");
  const [arrayDeBens, setArrayDeBens] = useState([]);
  const [dataAceite, setDataAceite] = useState(null);
  const [usuarioAceite, setUsuarioAceite] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();
  const edicaoTransferencia = location.state;
  const { checarPermissao } = useContext(PermissoesContext);

  const [unidade, setUnidade] = useState("");

  async function checaUnidadeFuncionario() {
    try {
      const { data } = await api.get(`v2/controleAcesso/funcionarioUnidade`, {
        params: { cod_usu: usuario },
      });

      if (!(await checarPermissao("INV030"))) {
        setUnidade(data.funcionario.unidade_id);
      }
    } catch (error) {
      history.push("/home");
      enqueueSnackbar(
        "Erro ao encontrar unidade de origem, solicite o cadastramento da unidade do seu usuário!",
        {
          variant: "error",
        }
      );
    }
  }
  useEffect(() => {
    checaUnidadeFuncionario();
    // eslint-disable-next-line
  }, [checarPermissao]);

  useEffect(() => {
    async function puxarDadosTransferencia() {
      if (edicaoTransferencia) {
        try {
          const {
            data: { data },
          } = await api.get(
            `/v2/inventario/transferenciaBem/${edicaoTransferencia.dados.id}`
          );

          setUnidadeOrigem(data.origemUnidade.id);
          setSetorOrigem(data.origemSetor.id);
          setUsuarioOrigem(data.origemUsuario);
          setUnidadeDestino(data.destinoUnidade.id);
          setSetorDestino(data.destinoSetor.id);
          setUsuarioDestino(data.destinoUsuario);
          setBem(data.bem);
          setUsuarioAceite(data.usuarioAceite1);

          setDataSolicitacao(data.dataSolicitacao);
          setObservacao(data.observacao);
          setDataAceite(data.dataAceite);
        } catch (error) {
          enqueueSnackbar("Erro! Não foi possível buscar a transferência cadastrada.", {
            variant: "error",
          });
        }
      }
    }
    puxarDadosTransferencia();
  }, [enqueueSnackbar, edicaoTransferencia]);

  useEffect(() => {
    if (bem) {
      setUnidadeOrigem(bem.unidadeId);
      setSetorOrigem(bem.setorId);

      if (bem.usuarioResponsavel) {
        setUsuarioOrigem(bem.usuarioResponsavel);
      }
    }
  }, [bem]);

  useEffect(() => {
    async function getBens() {
      try {
        const {
          data: { data },
        } = await api.get(`/v2/inventario/opcoesCodTombador`, { params: { unidade } });

        setArrayDeBens(data.bem);
      } catch (error) {
        enqueueSnackbar("Erro! Não foi possível buscar os bens.", {
          variant: "error",
        });
      }
    }
    getBens();

    // eslint-disable-next-line
  }, [enqueueSnackbar, unidade]);

  const handleSubmit = async e => {
    e.preventDefault();
    try {
      const data = {
        origemUnidadeId: unidadeOrigem,
        origemSetorId: setorOrigem,
        origemUsuarioId: usuarioOrigem?.id,
        destinoUnidadeId: unidadeDestino,
        destinoSetorId: setorDestino,
        destinoUsuarioId: usuarioDestino.id,
        bemId: bem.id,
        dataSolicitacao: new Date(dataSolicitacao).toISOString(),
        observacao,
      };

      if (edicaoTransferencia) {
        if (checarPermissao("INV024")) {
          await api.put(
            `/v2/inventario/transferenciaBem/${edicaoTransferencia.dados.id}`,
            data
          );
          enqueueSnackbar("Transferência editada com sucesso.", {
            variant: "success",
            autoHideDuration: 3000,
          });
        } else {
          enqueueSnackbar("Você não possui permissão para editar uma transferência!", {
            variant: "error",
            autoHideDuration: 3000,
          });
        }
      } else {
        if (checarPermissao("INV023")) {
          await api.post(`/v2/inventario/transferenciaBem`, data);
          enqueueSnackbar("Transferência cadastrada com sucesso.", {
            variant: "success",
            autoHideDuration: 3000,
          });
        } else {
          enqueueSnackbar("Você não possui permissão para realizar uma transferência!", {
            variant: "error",
            autoHideDuration: 3000,
          });
        }
      }

      history.push({ pathname: "/inventario", state: { dados: 5 } });
    } catch (error) {
      enqueueSnackbar("Erro ao tentar realizar uma transferência.", {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  return (
    <>
    <Header titulo="Transferir Bem" />
    <Container>
      <MuiButton onClick={() => {}}>
        <ArrowBackIcon
          style={{ color: "#003399" }}
          onClick={() => history.push({ pathname: "/inventario", state: { dados: 5 } })}
        />
      </MuiButton>
      <Paper style={{ padding: "40px", marginTop: "10px" }} elevation={2}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={4}>
            <Grid xs={12} item>
              <Autocomplete
                value={bem}
                onChange={(event, value) => setBem(value)}
                disablePortal
                options={arrayDeBens}
                getOptionLabel={option =>
                  `${option.codTombador ? option.codTombador + "-" : ""}${
                    option.material.modelo
                  }(${option.material.marca})-${option.serial}`
                }
                fullWidth
                renderInput={params => (
                  <Input required {...params} variant="standard" label="Lista de bens" />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography variant="subtitle1">Origem</Typography>
              <Divider sx={{ width: "100%" }} />
            </Grid>

            <Grid item xs={6}>
              <SelectUnidade
                disabled={true}
                value={unidadeOrigem}
                onChange={e => setUnidadeOrigem(e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <SelectSetor
                disabled={true}
                value={setorOrigem}
                onChange={e => setSetorOrigem(e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <PesquisaUsuario
                label="Usuário Origem"
                value={usuarioOrigem}
                onChange={setUsuarioOrigem}
                disabled
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1">Destino</Typography>
              <Divider sx={{ width: "100%" }} />
            </Grid>

            <Grid item xs={6}>
              <SelectUnidade
                required={true}
                value={unidadeDestino}
                onChange={e => setUnidadeDestino(e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <SelectSetor
                required={true}
                value={setorDestino}
                onChange={e => setSetorDestino(e.target.value)}
              />
            </Grid>

            <Grid item xs={6}>
              <PesquisaUsuario
                required={true}
                label="Usuário Destino"
                value={usuarioDestino}
                onChange={setUsuarioDestino}
              />
            </Grid>

            <Grid xs={6} item>
              <DataPickerAtualizado
                data={dataSolicitacao}
                setData={setDataSolicitacao}
                variant="standard"
                labelName="Data da Solicitação"
              />
            </Grid>
            {edicaoTransferencia ? (
              <>
                <Grid item xs={6}>
                  <PesquisaUsuario
                    disabled
                    label="Usuário Aceite"
                    value={usuarioAceite}
                    onChange={setUsuarioAceite}
                  />
                </Grid>
                <Grid xs={6} item>
                  <DataPickerAtualizado
                    disabled={true}
                    data={dataAceite}
                    setData={setDataAceite}
                    variant="standard"
                    labelName="Data Aceite"
                  />
                </Grid>{" "}
              </>
            ) : null}

            <Grid xs={12} item>
              <Input
                value={observacao}
                onChange={e => setObservacao(e.target.value)}
                multiline
                maxRows={5}
                fullWidth
                label="Observação"
                type="text"
                variant="standard"
              />
            </Grid>

            <Grid
              item
              sx={{ marginTop: "30px" }}
              container
              justifyContent="space-between"
              spacing={0}
            >
              <Grid item>
                <Button
                  onClick={() =>
                    history.push({ pathname: "/inventario", state: { dados: 5 } })
                  }
                  color="red"
                  variant="outlined"
                >
                  Cancelar
                </Button>
              </Grid>

              <Grid item>
                <Button type="submit" color="green" variant="contained">
                  Salvar
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Paper>
      </Container>
      </>
  );
}
