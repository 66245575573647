import { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import { api } from "../../../../../services/Api";
import formatDateBD from "../../../../../utils/formatarDataBD";

export default function useTabelaAgendamentos() {
  const { enqueueSnackbar } = useSnackbar();
  const [atualizar, setAtualizar] = useState(false);
  const [rows, setRows] = useState([]);
  const [situacao, setSituacao] = useState("");
  const [hora, setHora] = useState("");
  const [enfermeiroId, setEnfermeiroId] = useState("");
  const [data, setData] = useState(new Date());

  const atualizarTabela = () => {
    setAtualizar(!atualizar);
  };

  const dadosAgendamentos = async () => {
    try {
      const response = await api.get("/v2/agendaCare/agendamentos", {
        params: { situacao, data: formatDateBD(data), hora, enfermeiroId },
      });
      setRows(response.data.data);
    } catch (error) {
      enqueueSnackbar("Não foi possível encontrar os agendamentos", { variant: "error" });
    }
  };

  useEffect(() => {
    dadosAgendamentos();
    // eslint-disable-next-line
  }, [situacao, data, hora, enfermeiroId, atualizar]);

  return {
    atualizarTabela,
    rows,
    situacao,
    setSituacao,
    hora,
    setHora,
    enfermeiroId,
    setEnfermeiroId,
    data,
    setData,
  };
}
