import React, { useState, useContext } from "react";

import { Box } from "@mui/material";

import { HeaderConteinerComSideBar } from "../../../../componentsv2/HeaderConteinerComSideBar";
import Loading from "../../../../components/Loading";
import Tabela from "../../../../componentsv2/Tabela";
import useNotificacoesDados from "./hooks/useNotificacoesDados";
import colunasTabela from "./utils/colunasTabela";
import { Cabecalho } from "./components/Cabecalho";
import DadosContext from "./Context/DadosContext";
import BI from "./BI";
import { Context as PermissoesContext } from "../../../../Context/PermissoesContext";
import { useSnackbar } from "notistack";

export default function NotificacoesCovid() {
  const [carregando, setCarregando] = useState(false);
  const [filtro, setFiltro] = useState("");
  const [quantidadePorPaginas, setQuantidadePorPaginas] = useState(10);
  const [pagina, setPagina] = useState(10);
  const [tipo, setTipo] = useState(10);
  const [status, setStatus] = useState(10);
  const [dataInicio, setDataInicial] = useState(new Date());
  const [dataFim, setDataFim] = useState(new Date());
  const [aba, setAba] = useState(1);
  const [notificacoes, atualizar, totalNotificacoes] =
    useNotificacoesDados(setCarregando);
  const { checarPermissaoSemLog, checarPermissao } = useContext(PermissoesContext);
  const { enqueueSnackbar } = useSnackbar();

  return (
    <DadosContext.Provider
      value={{
        tipo,
        setTipo,
        status,
        setStatus,
        atualizar,
        setFiltro,
        filtro,
        dataInicio,
        setDataInicial,
        dataFim,
        setDataFim,
        setCarregando,
      }}
    >
      <HeaderConteinerComSideBar
        titulo="Notificações Covid"
        categoria="Administrativo"
        width="lg"
      >
        {carregando && <Loading />}
        <div style={{ paddingBottom: "0" }} id="container-aba-ctrl">
          <button
            type="button"
            id={aba === 1 ? "button-aba-ctrl-samples-selected" : "Aba1"}
            className="button-aba-ctrl"
            style={{ fontSize: "16px" }}
            onClick={() => {
              if (checarPermissao("SUS002")) {
                setAba(1);
              } else {
                enqueueSnackbar(
                  "Você não possui permissão para acessar as notificações.",
                  {
                    variant: "error",
                  }
                );
              }
            }}
          >
            Notificações
          </button>

          <button
            type="button"
            id={aba === 2 ? "button-aba-ctrl-samples-selected" : "Aba2"}
            className="button-aba-ctrl"
            style={{ fontSize: "16px" }}
            onClick={() => setAba(2)}
          >
            BI
          </button>
        </div>
        {aba === 1 && checarPermissaoSemLog("SUS002") && (
          <>
            <Cabecalho />
            <Box sx={{ height: 1, width: "100%" }}>
              <Tabela
                columns={colunasTabela}
                rows={notificacoes}
                autoHeight
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={totalNotificacoes}
                rowsPerPage={quantidadePorPaginas}
                page={pagina}
                onPageChange={(e, valor) => {
                  setPagina(valor);
                }}
                onRowsPerPageChange={e => {
                  setQuantidadePorPaginas(e.target.value);
                }}
              />
            </Box>
          </>
        )}
        {aba === 2 && <BI />}
      </HeaderConteinerComSideBar>
    </DadosContext.Provider>
  );
}
