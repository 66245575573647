import React, { useContext } from "react";
import { Close, MyLocation } from "@mui/icons-material";
import { Button, Dialog, DialogContent, Grid, Typography } from "@mui/material";

import { ColetaDomiciliarContext } from "../../../Context/ColetaDomiciarContext";

import { ModaisColetaDomiciliarContext } from "../../../Context/ModaisColetaDomiciarContext";
import { FormularioColetaDomiciliarContext } from "../../../Context/FormularioColetaDomiciliarContext";

export default function ModalVisualizaCliente() {
  const { coleta, setColeta } = useContext(ColetaDomiciliarContext);
  const { zeraCliente } = useContext( FormularioColetaDomiciliarContext );
  const { visualizaCliente, setVisualizaCliente } = useContext(
    ModaisColetaDomiciliarContext
  );

  function abreMaps(localizacaoC) {
    const local = `${localizacaoC.split(" ").join("+")}`;
    window.open(`https://www.google.com/maps/search/?api=1&query=${local}`);
  }

  function abreMapsAntigo(enderecoC, endereco_numeroC, bairroC, cidadeC, estadoC, cepC) {
    const local = `${enderecoC},+${endereco_numeroC}+-+${bairroC},+${cidadeC}+-+${estadoC},+${cepC}`;
    window.open(`https://www.google.com/maps/search/?api=1&query=${local}`);
  }

  function fechaVisualizaCliente() {
    setVisualizaCliente(false);
    zeraCliente();
    setColeta("");
  }

  function dataBrasil(data) {
    const [ano, mes, dia] = data.split("-");
    return `${dia}/${mes}/${ano}`;
  }

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={visualizaCliente}
      onClose={() => {
        fechaVisualizaCliente();
      }}
    >
      <div>
        <div id="header-information-client">
          <i id="icon-info-client" className="fa fa-user-circle" aria-hidden="true" />
          <span>Informações do cliente</span>
          <button
            type="button"
            id="btn-close-info-client"
            onClick={() => fechaVisualizaCliente()}
          >
            <Close id="icon-back-info-client" />
          </button>
        </div>
        <DialogContent>
          <Grid container spacing={1}>
            {coleta.hora ? (
              <Grid item xs={12} sm={6}>
                <Typography variant="h2" id="nomeCliente">
                  Hora
                </Typography>
                <Typography variant="h8" id="nome">
                  {coleta.hora}
                </Typography>
              </Grid>
            ) : (
              ""
            )}
            {coleta.data ? (
              <Grid item xs={12} sm={6}>
                <Typography variant="h2" id="nomeCliente">
                  Data
                </Typography>
                <Typography variant="h8" id="nome">
                  {dataBrasil(coleta.data)}
                </Typography>
              </Grid>
            ) : (
              ""
            )}

            {coleta.localizador ? (
              <Grid item xs={12} sm={6}>
                <Typography variant="h2" id="nomeCliente">
                  Localizador
                </Typography>
                <Typography variant="h8" id="nome">
                  {coleta.localizador}
                </Typography>
              </Grid>
            ) : (
              ""
            )}

            {coleta.tipo_cliente ? (
              <Grid item xs={12} sm={6}>
                <Typography variant="h2" id="nomeCliente">
                  Tipo de cliente
                </Typography>
                <Typography variant="h8" id="nome">
                  {coleta.tipo_cliente}
                </Typography>
              </Grid>
            ) : (
              ""
            )}

            {coleta.nome ? (
              <Grid item xs={12} sm={6}>
                <Typography variant="h2" id="nomeCliente">
                  Nome
                </Typography>
                <Typography variant="h8" id="nome">
                  {coleta.nome}
                </Typography>
              </Grid>
            ) : (
              ""
            )}

            {coleta.localizacao ? (
              <Grid item lg>
                <Typography variant="h2" id="nomeCliente">
                  Endereço
                </Typography>
                <Typography variant="h8" id="nome">
                  {coleta.localizacao}
                </Typography>
              </Grid>
            ) : (
              ""
            )}

            {!coleta.localizacao ? (
              <Grid item lg>
                <Typography variant="h2" id="nomeCliente">
                  Endereço
                </Typography>
                <Typography variant="h8" id="nome">
                  {coleta.endereco}, {coleta.endereco_numero}
                </Typography>
              </Grid>
            ) : (
              ""
            )}

            {coleta.complemento ? (
              <Grid item xs={12} sm={6}>
                <Typography variant="h2" id="nomeCliente">
                  Complemento
                </Typography>
                <Typography variant="h8" id="nome">
                  {coleta.complemento}
                </Typography>
              </Grid>
            ) : (
              ""
            )}

            {!coleta.localizacao ? (
              <Grid item xs={12} sm={6}>
                <Typography variant="h2" id="nomeCliente">
                  Bairro
                </Typography>
                <Typography variant="h8" id="nome">
                  {coleta.bairro}
                </Typography>
              </Grid>
            ) : (
              ""
            )}

            {!coleta.localizacao ? (
              <Grid item xs={12} sm={6}>
                <Typography variant="h2" id="nomeCliente">
                  Cidade
                </Typography>
                <Typography variant="h8" id="nome">
                  {coleta.cidade}-{coleta.estado}
                </Typography>
              </Grid>
            ) : (
              ""
            )}

            {!coleta.localizacao ? (
              <Grid item xs={12} sm={6}>
                <Typography variant="h2" id="nomeCliente">
                  CEP
                </Typography>
                <Typography variant="h8" id="nome">
                  {coleta.cep}
                </Typography>
              </Grid>
            ) : (
              ""
            )}

            {coleta.celular ? (
              <Grid item xs={12} sm={6}>
                <Typography variant="h2" id="nomeCliente">
                  Celular
                </Typography>
                <Typography variant="h8" id="nome">
                  {coleta.celular}
                </Typography>
              </Grid>
            ) : (
              ""
            )}

            {coleta.fone ? (
              <Grid item xs={12} sm={6}>
                <Typography variant="h2" id="nomeCliente">
                  Telefone
                </Typography>
                <Typography variant="h8" id="nome">
                  {coleta.fone}
                </Typography>
              </Grid>
            ) : (
              ""
            )}

            {coleta.email ? (
              <Grid item xs={12} sm={6}>
                <Typography variant="h2" id="nomeCliente">
                  Email
                </Typography>
                <Typography variant="h8" id="nome">
                  {coleta.email}
                </Typography>
              </Grid>
            ) : (
              ""
            )}

            {coleta.observacoes ? (
              <Grid item lg>
                <Typography variant="h2" id="nomeCliente">
                  Observação
                </Typography>
                <Typography variant="h8" id="nome">
                  {coleta.observacoes}
                </Typography>
              </Grid>
            ) : (
              ""
            )}
            {coleta.ponto_referencia ? (
              <Grid item xs={12} sm={6}>
                <Typography variant="h2" id="nomeCliente">
                  Ponto de referência
                </Typography>
                <Typography variant="h8" id="nome">
                  {coleta.ponto_referencia}
                </Typography>
              </Grid>
            ) : (
              ""
            )}
            {coleta.valor_recebido ? (
              <Grid item xs={12} sm={6}>
                <Typography variant="h2" id="nomeCliente">
                  Valor Recebido
                </Typography>
                <Typography variant="h8" id="nome">
                  R$
                  {coleta.valor_recebido}
                </Typography>
              </Grid>
            ) : (
              ""
            )}

            {!coleta.localizacao ? (
              <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
                <Button
                  id="btnMaps"
                  onClick={() => {
                    abreMapsAntigo(
                      coleta.endereco,
                      coleta.endereco_numero,
                      coleta.bairro,
                      coleta.cidade,
                      coleta.estado,
                      coleta.cep
                    );
                  }}
                  startIcon={<MyLocation />}
                >
                  Abrir google maps
                </Button>
              </Grid>
            ) : (
              ""
            )}
            {coleta.localizacao ? (
              <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
                <Button
                  id="btnMaps"
                  onClick={() => {
                    abreMaps(coleta.localizacao);
                  }}
                  startIcon={<MyLocation />}
                >
                  Abrir google maps
                </Button>
              </Grid>
            ) : (
              ""
            )}
          </Grid>
        </DialogContent>
      </div>
    </Dialog>
  );
}
