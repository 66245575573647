import React, { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import {
  Title,
  Card,
  Text,
  Metric,
  BarList,
  Flex,
  Bold,
  Col,
  Grid,
} from "@tremor/react";

import { HStack } from "../../../../componentsv2/Stack";
import DataPickerAtualizado from "../../../../components/DataPickerAtualizado";
import formatDateBD from "../../../../utils/formatarDataBD";
import { api } from "../../../../services/Api";

export default function BI() {
  const { enqueueSnackbar } = useSnackbar();

  const [dataInicio, setDataInicio] = useState(() => {
    const valueDateI = new Date();
    valueDateI.setDate(valueDateI.getDate() - 30);
    return valueDateI;
  });

  const [dataFim, setDataFim] = useState(new Date());

  const [soma, setSoma] = useState();
  const [somaManifestacao, setSomaManifestacao] = useState([]);
  const [somaSetor, setSomaSetor] = useState([]);
  const [somaCanal, setSomacanal] = useState([]);

  function transformaTipo(objeto) {
    const vet = objeto.map((el) => ({
      name: el.tipoManifestacao,
      value: parseInt(el.count, 10),
    }));
    return vet;
  }

  function transformaSetor(objeto) {
    const vet = objeto.map((el) => ({
      name: el.nome,
      value: parseInt(el.count, 10),
    }));
    return vet;
  }

  const formataArrayCanais = (vet) => {
    const resultado = vet.map((el) => {
      let name = el.canal;
      if (!name) {
        name = "Não Informado";
      }
      return {
        name,
        value: parseInt(el.count, 10),
      };
    });
    return resultado;
  };

  function acumulado(vet) {
    const novaLista = vet.reduce((somaAtual, cur) => {
      const { name } = cur;
      const repetido = somaAtual.find((el) => el.name === name);
      if (repetido) {
        repetido.value += cur.value;
      } else {
        somaAtual.push(cur);
      }
      return somaAtual;
    }, []);
    return novaLista;
  }

  useEffect(() => {
    (async () => {
      try {
        const {
          data: { data },
        } = await api.get(`/v2/atendimentoAoCliente/bi`, {
          params: {
            dataInicio: formatDateBD(dataInicio),
            dataFim: formatDateBD(dataFim),
          },
        });

        const arrayTipoManifestacaoOuvi = transformaTipo(
          data.totalOuvidoriaTipoManifestacao
        );
        const arraySetorOuvi = transformaSetor(data.totalOuvidoriaSetor);

        const arrayTipoManifestacaoSac = transformaTipo(
          data.totalSacTipoManifestacao
        );
        const arraySetorSac = transformaSetor(data.totalSacSetor);

        const arraySetor = acumulado(arraySetorOuvi.concat(arraySetorSac)).sort(
          (vet1, vet2) =>
            vet1.value < vet2.value ? 1 : vet1.value > vet2.value ? -1 : 0
        );

        const arrayTipoManifestacao = acumulado(
          arrayTipoManifestacaoOuvi.concat(arrayTipoManifestacaoSac)
        ).sort((vet1, vet2) =>
          vet1.value < vet2.value ? 1 : vet1.value > vet2.value ? -1 : 0
        );

        const arrayCanal = formataArrayCanais(data.totalOuvidoriaCanal);

        setSomacanal(arrayCanal);
        setSoma(data.totalOuvidoria + data.totalSac);
        setSomaSetor(arraySetor);
        setSomaManifestacao(arrayTipoManifestacao);
      } catch (e) {
        enqueueSnackbar(
          "Não foi possível encontrar manifestações desta data. Por favor, tente novamente.",
          { variant: "error" }
        );
      }
    })();
  }, [enqueueSnackbar, dataInicio, dataFim]);

  return (
    <HStack>
      <div className="container">
        <div className="grid-container-Dom">
          <div className="grid-item" md="3">
            <DataPickerAtualizado
              data={dataInicio}
              setData={setDataInicio}
              labelName="Selecionar Data Inicial"
              variant="standard"
            />
          </div>
          <div className="grid-item" md="3">
            <DataPickerAtualizado
              data={dataFim}
              setData={setDataFim}
              labelName="Selecionar Data Final"
              variant="standard"
            />
          </div>
        </div>
      </div>
      <Card
        maxWidth="max-w-fit"
        hFull={false}
        shadow
        decoration=""
        decorationColor="blue"
        marginTop="mt-0"
      >
        <Text>Total de Manifestações</Text>
        <Metric>{soma}</Metric>
      </Card>
      <Grid gapX="gap-x-6" gapY="gap-y-6" marginTop="mt-2">
        {/* <Grid spacing={6} mt={2}> */}
        <Col numColsMd={3} numColsLg={3}>
          <Card>
            <Title>Manifestação por Tipo</Title>
            <Flex marginTop="mt-4">
              <Text>
                <Bold>Manifestação</Bold>
              </Text>
              <Text>
                <Bold>Qtd</Bold>
              </Text>
            </Flex>
            <BarList data={somaManifestacao} marginTop="mt-2" />
          </Card>
          <Card>
            <Title>Manifestação por Setor</Title>
            <Flex marginTop="mt-4">
              <Text>
                <Bold>Setor</Bold>
              </Text>
              <Text>
                <Bold>Qtd</Bold>
              </Text>
            </Flex>
            <BarList data={somaSetor} marginTop="mt-2" />
          </Card>
          <Card>
            <Title>Manifestação por Canal</Title>
            <Flex marginTop="mt-4">
              <Text>
                <Bold>Canal</Bold>
              </Text>
              <Text>
                <Bold>Qtd</Bold>
              </Text>
            </Flex>
            <BarList data={somaCanal} marginTop="mt-2" />
          </Card>
        </Col>
      </Grid>
    </HStack>
  );
}
