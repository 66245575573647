import { Container, Stack, TextField } from "@mui/material";

import React, { useState } from "react";
import SecoesHome from "../../components/SecoesHome";

import "./styles.css";

export default function Search({ details }) {
  const [searchField, setSearchFiel] = useState("");

  const filteredPopover = details.map(el => ({
    ...el,
    ferramentas: el.ferramentas.filter(elFerramenta =>
      elFerramenta.nome.toLowerCase().includes(searchField.toLowerCase())
    ),
  }));

  const handleChange = e => {
    setSearchFiel(e.target.value);
  };

  return (
    <Container maxWidth="lg">
      <Stack direction="column">
        <TextField
          fullWidth
          size="medium"
          label="Pesquisar módulo"
          variant="outlined"
          margin="normal"
          type="search"
          onChange={handleChange}
          sx={{ zIndex: 1 }}
        />
        <SecoesHome filteredPopover={filteredPopover} />
      </Stack>
    </Container>
  );
}
