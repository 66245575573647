import React, { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { Divider, IconButton, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";

import DataPickerAtualizado from "../../../../../components/DataPickerAtualizado";

import Button from "../../../../../componentsv2/Button";
import Container from "../../../../../componentsv2/Container";
import GridContainer from "../../../../../componentsv2/GridContainer";
import GridItem from "../../../../../componentsv2/GridItem";
import { Input } from "../../../../../componentsv2/Input";
import ModalSettings from "../../../../../componentsv2/ModalSettings";
import { Select } from "../../../../../componentsv2/Select";

import formatDateBD from "../../../../../utils/formatarDataBD";
import SalvaLog from "../../../../../utils/SalvaLog";

import useUser from "../../../../../hooks/useUser";

import { api } from "../../../../../services/Api";
import { useContext } from "react";
import { ColetaDomiciliarContext } from "../../../Context/ColetaDomiciarContext";

export default function ModalReservarHorario({ open, setOpen }) {
  const { atualizaTabela, setAtualizaTabela, coletadores } = useContext(
    ColetaDomiciliarContext
  );
  const [coletador, setColetador] = useState("");
  const [data, setData] = useState(new Date());
  const [hora, setHora] = useState("");
  const [nomePaciente, setNomePaciente] = useState("");
  const [enderecoPaciente, setEnderecoPaciente] = useState("");

  // const [coletadores, setColetadores] = useState([]);
  const [horariosDisponiveis, setHorariosDisponiveis] = useState([]);
  const usuario = useUser();
  const { enqueueSnackbar } = useSnackbar();

  const zeraCliente = () => {
    setColetador("");
    setData(new Date());
    setHora("");
    setNomePaciente("");
    setEnderecoPaciente("");
  };
  const onClose = () => {
    setOpen(false);
    zeraCliente();
  };

  const autenticacao = () => {
    if (!hora || hora === "") {
      throw new Error("Selecione um horário!");
    }
    if (!coletador || coletador === "") {
      throw new Error("Selecione um coletador!");
    }
    if (!nomePaciente || nomePaciente === "") {
      throw new Error("Digite o nome do paciente!");
    }
  };

  const agendar = async () => {
    const coletadordados = {
      coletador,
      data: formatDateBD(data),
      hora,
      nomePaciente,
      usuario,
      enderecoPaciente,
    };
    try {
      await api.post("/api/coletaDomiciliar/reservarHorario", {
        coletador,
        data: formatDateBD(data),
        hora,
        nomePaciente,
        usuario,
        enderecoPaciente,
      });

      SalvaLog(
        `Reservou para ${coletador} o dia ${data} as ${hora}`,
        "Coleta Domiciliar",
        JSON.stringify(coletadordados),
        ``
      );

      zeraCliente();
      enqueueSnackbar("Horário reservado com sucesso", { variant: "success" });
      setOpen(false);
      setAtualizaTabela(!atualizaTabela);
    } catch (error) {
      SalvaLog(
        `Tentou reservar para ${coletador} o dia ${data} as ${hora}`,
        "Coleta Domiciliar",
        JSON.stringify(coletadordados),
        ``
      );
      enqueueSnackbar("Falha na operação", { variant: "error" });
      zeraCliente();
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    try {
      autenticacao();
      agendar();
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  // useEffect(() => {
  //   (async () => {
  //     try {
  //       const { data: resultado } = await api.get(
  //         "/api/coletaDomiciliar/coletadores"
  //       );
  //       // setColetadores(resultado);
  //     } catch (error) {
  //       enqueueSnackbar("Erro ao buscar coletadores!", { variant: "error" });
  //     }
  //   })();
  // }, [enqueueSnackbar]);

  useEffect(() => {
    (async () => {
      if (coletador && data) {
        try {
          const response = await api.get(
            `/api/ColetaDomiciliar/disponibilidade/${formatDateBD(
              data
            )}/${coletador}`
          );
          setHorariosDisponiveis(response.data);
        } catch (error) {
          enqueueSnackbar("Horários disponiveis não encontrados!", {
            variant: "error",
          });
        }
      }
    })();
  }, [enqueueSnackbar, coletador, data]);

  const dividerStyle = {
    width: "100%",
    backgroundColor: "#909099",
  };
  const containerStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };
  const titleStyle = {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  };
  return (
    <ModalSettings open={open} onClose={onClose}>
      <Container md sx={containerStyle}>
        <form onSubmit={handleSubmit}>
          <GridContainer spacing={3}>
            <GridItem xs={12} item sx={titleStyle}>
              <Typography variant="h6" sx={{ color: "black" }}>
                Reservar horário
              </Typography>
              <IconButton onClick={() => onClose(false)}>
                <Close />
              </IconButton>
            </GridItem>
            <GridItem xs={12}>
              <Divider sx={dividerStyle} />
            </GridItem>
            <GridItem xs={12}>
              {coletadores.length === 0 ? (
                <Input
                  value="Carregando coletadores..."
                  disabled
                  variant="standard"
                  fullWidth
                />
              ) : (
                <Select
                  variant="standard"
                  label="Selecione o coletador"
                  value={coletador}
                  onChange={(e) => {
                    setColetador(e.target.value);
                  }}
                  textAttribute="nome"
                  valueAttribute="nome"
                  arrayOptions={coletadores}
                />
              )}
            </GridItem>
            <GridItem xs={12}>
              <DataPickerAtualizado
                setData={setData}
                data={data}
                variant="standard"
                labelName="Selecione a data "
              />
            </GridItem>
            <GridItem xs={12}>
              <Select
                variant="standard"
                label="Selecione o horário"
                value={hora}
                onChange={(e) => {
                  setHora(e.target.value);
                }}
                textAttribute="hora"
                valueAttribute="hora"
                arrayOptions={horariosDisponiveis}
              />
            </GridItem>
            <GridItem xs={12}>
              <Input
                fullWidth
                label="Nome do paciente"
                variant="standard"
                value={nomePaciente}
                onChange={(e) => {
                  setNomePaciente(e.target.value);
                }}
              />
            </GridItem>
            <GridItem xs={12}>
              <Input
                fullWidth
                label="Endereço do paciente"
                variant="standard"
                value={enderecoPaciente}
                onChange={(e) => {
                  setEnderecoPaciente(e.target.value);
                }}
              />
            </GridItem>
            <GridItem item container justifyContent="flex-end">
              <Button type="submit" variant="contained" color="green">
                Reservar
              </Button>
            </GridItem>
          </GridContainer>
        </form>
      </Container>
    </ModalSettings>
  );
}
