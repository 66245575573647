import React from "react";

export default function BlueIcon({ children, onClick, style }) {
  return (
    <div
      style={{
        backgroundColor: "#003399",
        padding: ".25rem",
        display: "inline-block",
        borderRadius: "4px",
        cursor: "pointer",
        ...style,
      }}
      onClick={() => {
        if (onClick) {
          onClick();
        }
      }}
    >
      {children}
    </div>
  );
}
