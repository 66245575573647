import GridContainer from "../../../../componentsv2/GridContainer";
import GridItem from "../../../../componentsv2/GridItem";
import Card from "./Card";

export default function ListaCards({ arrayEstoques }) {
  return (
    <GridContainer>
      {arrayEstoques?.map((estoque) => (
        <GridItem
          key={estoque.id}
          sx={{ paddingRight: 1, marginBottom: 1 }}
          xs={12}
          md={3}
        >
          <Card estoque={estoque} />
        </GridItem>
      ))}
    </GridContainer>
  );
}
