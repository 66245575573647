import React from "react";

import { Select, MenuItem, Button, Container } from "@mui/material";
import dayjs from "dayjs";

import "./styles.css";
import DataPickerAtualizado from "../../../components/DataPickerAtualizado";

export default function SelecionarVisualizacao(props) {
  const { televisao, setSituacao, situacao, data_fim, setData_fim } = props;

  return (
    <Container maxWidth="md">
      <div className="container">
        <div className="grid-container-Dom-vis">
          <div className="grid-item" md="4">
            <DataPickerAtualizado
              data={dayjs(data_fim)}
              setData={setData_fim}
              variant="inline"
            />
            {/* <MyDatePicker
              disableToolbar
              variant="inline"
              format="dd/MM/yyyy"
              margin="normal"
              id="date-picker-inline"
              value={dayjs(data_fim)}
              onChange={setData_fim}
              KeyboardButtonProps={{
                "aria-label": "Mudar",
              }}
            /> */}
          </div>
          <div className="grid-item" md="4">
            <Select
              labelId="novo-filtro-valor-label"
              id="novo-filtro-valor"
              label="Valor"
              value={situacao}
              onChange={(event) => {
                setSituacao(event.target.value);
              }}
            >
              <MenuItem value="pendente">Pendentes</MenuItem>
              <MenuItem value="concluido">Concluídos</MenuItem>
            </Select>
          </div>
          <div className="grid-item" md="4">
            <Button
              onClick={() => {
                televisao();
              }}
              type="submit"
              variant="contained"
              color="primary"
              margin="normal"
              id="button"
            >
              Abrir para Tv
            </Button>
          </div>
        </div>
      </div>
    </Container>
  );
}
