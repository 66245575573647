import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import { Autocomplete, TextField } from "@mui/material";

import Button from "../Button";

import { api } from "../../services/Api";

export default function AutoCompleteClientes({
  label,
  value,
  onChange,
  required = false,
  ...rest
}) {
  const [clientes, setClientes] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  useEffect(() => {
    async function pegaClientes() {
      try {
        const { data } = await api.get("/v2/controleDna/clientes");

        setClientes(data.data);
      } catch (error) {
        enqueueSnackbar("Erro! Não foi possível buscar os clientes.", {
          variant: "error",
        });
      }
    }
    pegaClientes();
  }, [enqueueSnackbar]);

  return (
    <>
      <Autocomplete
        {...rest}
        noOptionsText={
          <Button
            onClick={() => {
              history.push({
                pathname: "/gestaoDeClientes/cadastroDeClientes",
                state: { data: "care" },
              });
            }}
          >
            Cadastrar novo cliente
          </Button>
        }
        value={value}
        onChange={(event, value) => onChange(value)}
        disablePortal
        id="combo-box-demo"
        options={clientes}
        getOptionLabel={option => `${option.nome} - ${option.cpf}`}
        fullWidth
        renderInput={params => (
          <TextField
            required={required}
            variant="standard"
            {...params}
            label="Clientes"
          />
        )}
      />
    </>
  );
}
