import React from "react";
import MaskedInput from "react-text-mask";

export default function MascaraDeCompetencia(props) {
    const { inputRef, ...other } = props;
    return (
      <MaskedInput
        {...other}
        // getInputRef={ref => {
        //   inputRef(ref ? ref.inputElement : null);
        // }}
        mask={[/[0-1]/, /\d/, '/', /2/, /0/, /2/, /[2-9]/]}
        guide={false}
      />
    );
}
