import { Button as MuiButton, Container, Grid, Paper } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";

import { api } from "../../../../services/Api";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSnackbar } from "notistack";
import { useHistory, useLocation } from "react-router-dom";

// import DadosContextFornecedor from "../DadosContextFornecedor";
import { Context as PermissoesContext } from "../../../../Context/PermissoesContext";
import Header from "../../../../components/Header";
import Button from "../../../../componentsv2/Button";
import { Modal } from "../../../../componentsv2/Modal";
import { Input } from "../../../../componentsv2/Input";
import SalvaLog from "../../../../utils/SalvaLog";

export default function FormFornecedor({ row }) {
  const [nome, setNome] = useState("");
  const history = useHistory();
  const location = useLocation();
  const edicaoFornecedor = location.state;
  const [cpf, setCpf] = useState("");
  const [logradouro, setLogradouro] = useState("");
  const [bairro, setBairro] = useState("");
  const [cidade, setCidade] = useState("");
  const [telefone, setTelefone] = useState("");
  const [telefoneSecundario, setTelefoneSecundario] = useState("");
  const [estado, setEstado] = useState("");
  const [numero, setNumero] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  // const { update, setUpdate } = useContext(DadosContextFornecedor);

  const { enqueueSnackbar } = useSnackbar();
  const { checarPermissao } = useContext(PermissoesContext);

  useEffect(() => {
    if (edicaoFornecedor) {
      setNome(edicaoFornecedor.dados.nome);
      setCpf(edicaoFornecedor.dados.cpf_cnpj);
      setLogradouro(edicaoFornecedor.dados.logradouro);
      setBairro(edicaoFornecedor.dados.bairro);
      setCidade(edicaoFornecedor.dados.cidade);
      setTelefone(edicaoFornecedor.dados.telefone);
      setTelefoneSecundario(edicaoFornecedor.dados.telefone_secundario);
      setEstado(edicaoFornecedor.dados.estado);
      setNumero(edicaoFornecedor.dados.numero);
    }
  }, [edicaoFornecedor]);

  const handleDelete = async () => {
    if (edicaoFornecedor) {
      try {
        await api.delete(
          `v2/gestaoContratos/fornecedor/${edicaoFornecedor.dados.id}`
        );
        // setUpdate(!update);
        enqueueSnackbar("Contrato excluído com fornecedor!", {
          variant: "success",
          autoHideDuration: 3000,
        });
        history.push("/financeiro/fornecedor");
      } catch (error) {
        enqueueSnackbar("Erro ao tentar excluír fornecedor!", {
          variant: "error",
          autoHideDuration: 3000,
        });
      }
    }
    setOpenDialog(false);
  };

  const clearFields = () => {
    setNome("");
    setCpf("");
    setLogradouro("");
    setBairro("");
    setCidade("");
    setTelefone("");
    setEstado("");
    setNumero("");
    setTelefoneSecundario("");
  };

  const submit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const data = {
        cpf_cnpj: cpf,
        nome,
        logradouro,
        numero,
        bairro,
        cidade,
        estado,
        telefone,
        telefone_secundario: telefoneSecundario,
      };

      const detalhe = JSON.stringify(data);

      if (edicaoFornecedor) {
        await api.put(
          `v2/gestaoContratos/fornecedor/${edicaoFornecedor.dados.id}`,
          data
        );
        SalvaLog("Editou uma empresa", "Gestão de Contratos", detalhe);
        // setUpdate(!update);
        clearFields();

        enqueueSnackbar("Fornecedor editado com sucesso!", {
          variant: "success",
          autoHideDuration: 3000,
        });
      } else {
        await api.post(`v2/gestaoContratos/fornecedor`, data);
        // setUpdate(!update);
        SalvaLog("Cadastrou uma empresa", "Gestão de Contratos", detalhe);
        enqueueSnackbar("Fornecedor cadastrado com sucesso!", {
          variant: "success",
          autoHideDuration: 3000,
        });
      }

      clearFields();
      history.push({
        pathname: "/financeiro/contratos",
        state: {
          tab: "fr",
        },
      });
    } catch (error) {
      enqueueSnackbar("Erro ao tentar cadastrar ou exlcuír!", {
        variant: "error",
        autoHideDuration: 3000,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Header titulo="Financeiro" />
      <Container maxWidth="md">
        <MuiButton
          onClick={() =>
            history.push({
              pathname: "/financeiro/contratos",
              state: {
                tab: "fr",
              },
            })
          }
        >
          <ArrowBackIcon style={{ color: "#003399" }} />
        </MuiButton>
        <Paper style={{ padding: "40px", marginTop: "10px" }} elevation={2}>
          <form onSubmit={submit}>
            <Grid container spacing={0}>
              <Grid item>
                <Grid container spacing={4}>
                  <Grid lg={4} sm={4} xs={12} item>
                    <Input
                      required
                      onChange={(e) => {
                        setCpf(e.target.value);
                      }}
                      value={cpf}
                      fullWidth
                      label="CPF/CNPJ"
                      type="text"
                      variant="standard"
                    />
                  </Grid>
                  <Grid lg={8} sm={8} xs={12} item>
                    <Input
                      required
                      onChange={(e) => setNome(e.target.value)}
                      value={nome}
                      fullWidth
                      label="Nome"
                      type="text"
                      variant="standard"
                    />
                  </Grid>
                  <Grid lg={10} sm={10} xs={12} item>
                    <Input
                      onChange={(e) => setLogradouro(e.target.value)}
                      value={logradouro}
                      fullWidth
                      label="Logradouro"
                      type="text"
                      variant="standard"
                    />
                  </Grid>
                  <Grid lg={2} sm={2} xs={12} item>
                    <Input
                      onChange={(e) => setNumero(e.target.value)}
                      value={numero}
                      fullWidth
                      label="N*"
                      type="number"
                      variant="standard"
                    />
                  </Grid>
                  <Grid lg={5} sm={5} xs={12} item>
                    <Input
                      onChange={(e) => setBairro(e.target.value)}
                      value={bairro}
                      fullWidth
                      type="text"
                      label="Bairro"
                      variant="standard"
                    />
                  </Grid>
                  <Grid lg={5} sm={5} xs={12} item>
                    <Input
                      onChange={(e) => setCidade(e.target.value)}
                      value={cidade}
                      fullWidth
                      label="Cidade"
                      type="text"
                      variant="standard"
                    />
                  </Grid>
                  <Grid lg={2} sm={2} xs={12} item>
                    <Input
                      onChange={(e) => setEstado(e.target.value)}
                      value={estado}
                      fullWidth
                      label="UF"
                      type="text"
                      variant="standard"
                    />
                  </Grid>
                  <Grid lg={6} sm={6} xs={12} item>
                    <Input
                      onChange={(e) => setTelefone(e.target.value)}
                      value={telefone}
                      fullWidth
                      type="number"
                      label="Telefone"
                      variant="standard"
                    />
                  </Grid>
                  <Grid lg={6} sm={6} xs={12} item>
                    <Input
                      onChange={(e) => setTelefoneSecundario(e.target.value)}
                      value={telefoneSecundario}
                      fullWidth
                      type="number"
                      label="Telefone 2"
                      variant="standard"
                    />
                  </Grid>

                  <Grid
                    sx={{ marginTop: "30px" }}
                    container
                    justifyContent="space-between"
                    spacing={0}
                  >
                    <Grid sx={{ marginLeft: "30px" }} item>
                      {edicaoFornecedor ? (
                        <Button
                          onClick={() => {
                            if (checarPermissao("GEC004")) {
                              setOpenDialog(true);
                            } else {
                              enqueueSnackbar(
                                "Você não possui permissão para excluir um fornecedor!",
                                {
                                  variant: "error",
                                  autoHideDuration: 3000,
                                }
                              );
                            }
                          }}
                          variant="outlined"
                          color="red"
                        >
                          Excluir
                        </Button>
                      ) : (
                        <Button
                          onClick={() =>
                            history.push({
                              pathname: "/financeiro/contratos",
                              state: {
                                tab: "fr",
                              },
                            })
                          }
                          color="red"
                          variant="outlined"
                        >
                          Cancelar
                        </Button>
                      )}
                      <Modal
                        open={openDialog}
                        text="Tem certeza que deseja remover este fornecedor?"
                        onClose={() => setOpenDialog(false)}
                        onClick={handleDelete}
                        buttonColor="red"
                        buttonText="Remover"
                      />
                    </Grid>
                    <Grid item>
                      <Button
                        type="submit"
                        disabled={loading}
                        color="green"
                        variant="contained"
                      >
                        Salvar
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Container>
    </>
  );
}
