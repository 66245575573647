import React from "react";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TextField } from "@mui/material";
import dayjs from "dayjs";
import formatDateBD from "../../utils/formatarDataBD";
import "dayjs/locale/pt-br";

export default function DataPickerAtualizado({
  setDataFormatada = null,
  variant,
  size,
  data, // Esta data precisa vir no formato US (yyyy-mm-dd)
  setData,
  labelName,
  formatarNaData = false,
  disabled = false,
  ...rest
}) {
  function handleChangeData(e) {
    if (formatarNaData) {
      return setData(formatDateBD(e));
    }

    if (setDataFormatada) {
      setDataFormatada(formatDateBD(e));
    }

    return setData(e);
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
      <DatePicker
        {...rest}
        label={labelName || "Validade"}
        disabled={disabled}
        variant="standard"
        value={dayjs(data)}
        onChange={(e) => handleChangeData(e)}
        renderInput={(params) => (
          <TextField
            fullWidth
            variant={variant}
            size={size}
            {...params}
            inputProps={{
              ...params.inputProps,
              placeholder: "dia/mes/ano",
            }}
          />
        )}
      />
    </LocalizationProvider>
  );
}
