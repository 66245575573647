import React, { useContext } from "react";
import { IconButton, Tooltip } from "@mui/material";

import Waiter from "../../../../../components/Backdrop";
import Tabela from "../../../../../componentsv2/Tabela";
import InputValorRecebido from "../InputValorRecebido";
import InputMotivo from "../InputMotivo";
import SelectSituacao from "../SelectSituacao";

import { RetornoLoteContext } from "../../Context/RetornoLoteContext";

export default function TabelaRecebimentoManual() {
  const { glosas, setGlosas, setAtualizaValoresRecebimentoManual } =
    useContext(RetornoLoteContext);

  const DeleteRow = ({ row }) => {
    const handleEditClick = () => {
      setGlosas((prevState) => {
        const apagaLinha = prevState.filter((r) => r.id !== row.id);
        return apagaLinha;
      });
      setAtualizaValoresRecebimentoManual((prevState) => !prevState);
    };

    return (
      <Tooltip title="Remover linha">
        <IconButton onClick={handleEditClick}>
          <i className="fa fa-trash-o" style={{ color: "#003399" }} />
        </IconButton>
      </Tooltip>
    );
  };

  const colunas = [
    {
      field: "id",
      headerName: "ID",
      headerAlign: "center",
      hide: true,
    },
    {
      field: "situacao",
      headerName: "Situação",
      headerAlign: "center",
      flex: 1,
      editable: true,
      disableClickEventBubbling: true,
      maxWidth: 150,
      type: "string",

      renderCell: (params) => (
        <div style={{ display: "flex", cursor: "pointer" }}>
          <SelectSituacao row={params.row} />
        </div>
      ),
    },
    {
      field: "motivo",
      headerName: "Motivo",
      headerAlign: "center",
      flex: 1,
      editable: false,
      disableClickEventBubbling: true,
      minWidth: 150,
      type: "number",
      renderCell: (params) => (
        <div style={{ display: "flex", cursor: "pointer", width: "100%" }}>
          <InputMotivo row={params.row} />
        </div>
      ),
    },
    {
      field: "valor_recebido",
      headerName: "Valor",
      headerAlign: "center",
      align: "right",
      flex: 1,
      editable: false,
      maxWidth: 100,
      type: "number",
      renderCell: (params) => (
        <div style={{ display: "flex", cursor: "pointer" }}>
          <InputValorRecebido row={params.row} />
        </div>
      ),
    },
    {
      field: "opcoes",
      headerName: "Opções",
      headerAlign: "center",
      width: 130,
      type: "number",
      editable: false,
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (params) => (
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
            cursor: "pointer",
          }}
        >
          <DeleteRow row={params.row} />
        </div>
      ),
    },
  ];

  return (
    <>
      <Tabela
        ocultarToolbar
        disableColumnMenu
        columns={colunas}
        rows={glosas}
        pageSize={10}
        rowsPerPageOptions={[10, 20]}
        initialState={{
          columns: { columnVisibilityModel: { id: false } },
        }}
      />
      <Waiter espera={false} />
    </>
  );
}
