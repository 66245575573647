import React from "react";
import { Backdrop, CircularProgress } from '@mui/material';
import { styled } from '@mui/system';

const StyledBackdrop = styled(Backdrop)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1040,
  color: '#fff',
}));

export default function Waiter(props) {
    const { espera } = props

    return (
      <StyledBackdrop
        children="node"
        open={espera}
      >
        <CircularProgress color="inherit" />
      </StyledBackdrop>
    )
}
