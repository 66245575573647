import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import MenuIcon from "@mui/icons-material/Menu";
import {
  Popover,
  AppBar,
  Box,
  CssBaseline,
  Drawer,
  IconButton,
  List,
  Toolbar,
  Typography,
  Button,
} from "@mui/material";

import { Context as AuthContext } from "../../Context/AuthContext";

import useUser from "../../hooks/useUser";

import { Agendas, Administrativo, Financeiro, Aplicativos, Laboratorio } from "./itens";
import ListaComponent from "./listaComponent";

import { api } from "../../services/Api";
import logoDNA from "../../assets/logo_branca_dna.png";
import PermanentListComponent from "./PermanentListComponent";

const drawerWidth = 218;

export default function HeaderBar({ window, title, categoria = "", open, setOpen }) {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [openAgendas, setOpenAgendas] = useState(false);
  const [openAdministrativo, setOpenAdministrativo] = useState(false);
  const [openFinanceiro, setOpenFinanceiro] = useState(false);
  const [openAplicativos, setOpenAplicativos] = useState(false);
  const [openLaboratorio, setOpenLaboratorio] = useState(false);

  const usuario = useUser();
  const history = useHistory();

  const [userIconAnchor, setUserIconAnchor] = useState(null);
  const [preview, setPreview] = useState();

  const { logout } = useContext(AuthContext);

  useEffect(() => {
    async function chamaFuncao() {
      try {
        const resultImage = await api.get("/v2/controleAcesso/avatar", {
          params: { username: usuario },
          responseType: "blob",
        });

        if (resultImage.status !== 202) {
          setPreview(URL.createObjectURL(resultImage.data));
        }
      } catch (error) {
        console.log(error);
      }
    }
    chamaFuncao();

    if (categoria.toLowerCase() === "agendas") {
      setOpenAgendas(true);
    }
    if (categoria.toLowerCase() === "administrativo") {
      setOpenAdministrativo(true);
    }
    if (categoria.toLowerCase() === "financeiro") {
      setOpenFinanceiro(true);
    }
    if (categoria.toLowerCase() === "aplicativos") {
      setOpenAplicativos(true);
    }
    if (categoria.toLowerCase() === "laboratorio") {
      setOpenLaboratorio(true);
    }
    // eslint-disable-next-line
  }, []);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <div>
      <List component="nav">
        <ListaComponent
          open={openAgendas}
          setOpen={setOpenAgendas}
          lista={Agendas}
          history={history}
        />
        <ListaComponent
          open={openLaboratorio}
          setOpen={setOpenLaboratorio}
          lista={Laboratorio}
          history={history}
        />
        <ListaComponent
          open={openFinanceiro}
          setOpen={setOpenFinanceiro}
          lista={Financeiro}
          history={history}
        />
        <ListaComponent
          open={openAdministrativo}
          setOpen={setOpenAdministrativo}
          lista={Administrativo}
          history={history}
        />
        <ListaComponent
          open={openAplicativos}
          setOpen={setOpenAplicativos}
          lista={Aplicativos}
          history={history}
        />
      </List>
    </div>
  );

  const permanentDrawer = (
    <List component="nav">
      <PermanentListComponent
        open={openAgendas}
        setOpen={setOpenAgendas}
        lista={Agendas}
        history={history}
        openDrawer={open}
      />
      <PermanentListComponent
        open={openLaboratorio}
        setOpen={setOpenLaboratorio}
        lista={Laboratorio}
        history={history}
        openDrawer={open}
      />
      <PermanentListComponent
        open={openFinanceiro}
        setOpen={setOpenFinanceiro}
        lista={Financeiro}
        history={history}
        openDrawer={open}
      />
      <PermanentListComponent
        open={openAdministrativo}
        setOpen={setOpenAdministrativo}
        lista={Administrativo}
        history={history}
        openDrawer={open}
      />
      <PermanentListComponent
        open={openAplicativos}
        setOpen={setOpenAplicativos}
        lista={Aplicativos}
        history={history}
        openDrawer={open}
      />
      <Button
        sx={{ display: "flex", justifyContent: "flex-end" }}
        fullWidth
        onClick={() => setOpen(!open)}
      >
        <ArrowBackIosIcon
          sx={{
            mr: open ? 2.5 : 1.5,
            transform: open ? "rotate(180deg)" : "rotate(0)",
            transition: "0.2s",
            color: "#27272A",
          }}
          fontSize="small"
        />
      </Button>
    </List>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          backgroundColor: "#003399",
        }}
      >
        <Toolbar className="cabecalho">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Box
            sx={{
              ml: 2,
              display: { xs: "none", sm: "flex" },
              alignItems: "center",
              justifyContent: "center",
              height: "70px",
              width: "100px",
              cursor: "pointer",
            }}
            onClick={() => history.push("/home")}
          >
            <img src={logoDNA} alt="logo-dna" />
          </Box>
          <Typography
            variant="h6"
            noWrap
            component="div"
            onClick={() => history.push("/home")}
            sx={{ cursor: "pointer" }}
          >
            {title}
          </Typography>
          <div>
            <div
              className="icone-usuario"
              onClick={e => setUserIconAnchor(e.currentTarget)}
            >
              {preview ? (
                <img className="imagem-usuario" src={preview} alt="icone do usuario" />
              ) : (
                usuario?.charAt(0) || '0'
              )}
            </div>
          </div>

          <Popover
            open={Boolean(userIconAnchor)}
            anchorEl={userIconAnchor}
            onClose={() => setUserIconAnchor(null)}
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          >
            <div className="acessos-header" onClick={() => history.push("/home")}>
              <span> Página inicial </span>
            </div>
            <div className="acessos-header" onClick={() => history.push("/perfil")}>
              <span> Perfil </span>
            </div>
            <div className="user-icon-row" onClick={() => logout()}>
              <p>Sair</p>
              <ExitToAppIcon />
            </div>
          </Popover>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{
          width: open ? { sm: 60 } : { sm: drawerWidth },
          flexShrink: { sm: 0 },
        }}
        aria-label="mailbox folders"
      >
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "flex" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              borderRightColor: "#d4d4d8",
              width: open ? 60 : drawerWidth,
              mt: "70px",
              transition: "0.2s",
            },
          }}
          open={open}
        >
          {permanentDrawer}
        </Drawer>
      </Box>
    </Box>
  );
}
