import React, { useEffect } from "react";

import { KeyboardArrowDown } from '@mui/icons-material';
import { ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";

export default function PermanentListComponent({
  open,
  setOpen,
  lista,
  history,
  openDrawer,
}) {
  useEffect(() => {
    if (openDrawer) setOpen(false);
  }, [openDrawer, setOpen]);
  return (
    <>
      {lista.map((el, index) => (
        <>
          <ListItemButton
            key={el.titulo}
            alignItems="flex-start"
            onClick={() => (openDrawer ? null : setOpen(!open))}
            sx={{
              px: 3,
              pt: 1,
              pb: open ? 0 : 2.5,
              "&:hover, &:focus": { "& svg": { opacity: open ? 1 : 0 } },
            }}
          >
            <ListItemIcon disablePadding sx={{ mr: -2, mt: open ? 0.5 : 1 }}>
              <div className={`${el.icone}`} />
            </ListItemIcon>
            <ListItemText
              primary={el.titulo}
              primaryTypographyProps={{
                fontSize: 15,
                fontWeight: "bold",
                lineHeight: "20px",
                mb: "2px",
              }}
              secondary={el.label}
              secondaryTypographyProps={{
                noWrap: true,
                fontSize: 12,
                fontWeight: "medium",
                lineHeight: "16px",
                color: open ? "rgba(0,0,0,0)" : "#71717a",
              }}
              sx={{ my: 0 }}
            />
            <KeyboardArrowDown
              sx={{
                mr: -1,
                opacity: 0,
                transform: open ? "rotate(-180deg)" : "rotate(0)",
                transition: "0.2s",
              }}
            />
          </ListItemButton>
          {el.ferramentas.map((unidade, index) => {
            if (open) {
              return (
                <ListItem
                  key={index}
                  disablePadding
                  onClick={() => history.push(unidade.path)}
                >
                  <ListItemButton sx={{ height: 36, ml: 4 }}>
                    <ListItemText
                      primary={unidade.nome}
                      primaryTypographyProps={{
                        fontWeight: "normal",
                        variant: "body2",
                      }}
                    />
                  </ListItemButton>
                </ListItem>
              );
            }
            return null;
          })}
        </>
      ))}
    </>
  );
}
