import React from "react";
import { Dialog, DialogTitle, DialogContent } from "@mui/material";
import { styled } from "@mui/system";

import "./styles.css";

const CustomDialog = styled(Dialog)(({ theme }) => ({
  width: "95%",
  margin: "0 auto",
  // maxWidth: "500px",
  minWidth: "330px",
}));

export default function MyDialog({
  open,
  children,
  title,
  style,
  classes,
  ...rest
}) {
  return (
    <CustomDialog open={open} classes={classes} {...rest}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent style={style}>{children}</DialogContent>
    </CustomDialog>
  );
}
