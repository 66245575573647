import React, { useEffect } from "react";
import { Modal } from "../../../componentsv2/Modal";
import { Box } from "@mui/material";
import Button from "../../../componentsv2/Button";

export default function BI() {
  return (
    <Box textAlign="center">
      <Button 
        style={{ 
          marginTop: '20px',
          backgroundColor: '#003399',
          color: 'white',
          padding: '10px 20px',
          borderRadius: '5px',
          border: 'none',
          cursor: 'pointer' 
        }}
        onClick={() => { window.open("https://app.powerbi.com/reportEmbed?reportId=1f11dc10-6b69-42e6-83e9-437284bf6e1b&autoAuth=true&ctid=a5da2cd0-815f-4af4-ac60-6c6b593b7957", "_blank"); }}
      >
        Abrir o BI!
      </Button>
    </Box>
  );
}
