import axios from "axios";

const baseURL = process.env.REACT_APP_BASEURL_WEB;

const api = axios.create({
  baseURL,
});

api.interceptors.response.use(
  response => response,
  error => {
    if (error.response.status === 401) {
      document.location.reload(true);
    }
    return Promise.reject(error);
  }
);

const baseUrl = baseURL;

export { api, baseUrl };
