import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Button, Container, Grid, Paper } from "@mui/material";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Header from "../../../components/Header";
import useUser from "../../../hooks/useUser";
import AdicionarProduto from "./AdicionarProduto";
import BarraDePesquisa from "./BarraDePesquisa";
import DadosContext from "./DadosContext";
import "./styles.css";
import TabelaProdutos from "./TabelaProdutos";

export default function HomeCadastro() {
  const history = useHistory();
  const usuario = useUser();

  const [pesquisado, setPesquisado] = useState("");

  return (
    <DadosContext.Provider value={{ pesquisado, setPesquisado }}>
      <Header titulo="Cadastro de Produtos" usuario={usuario} history={history} />
      <Container maxWidth="lg" style={{ padding: "0" }}>
        <Grid container>
          <Button
            onClick={() => history.push("/almoxarifado")}
            style={{ color: "#003399" }}
          >
            <ArrowBackIcon />
          </Button>
          <Grid className="paper-categoria" item xs={12} sm={12}>
            <Paper id="header-categoria" elevation={2}>
              <Grid className="paper-categoria" item xs={12} sm={9}>
                <BarraDePesquisa />
              </Grid>
              <Grid className="paper-categoria" item xs={12} sm={3}>
                <AdicionarProduto />
              </Grid>
            </Paper>
          </Grid>
          <Grid className="paper-categoria" item xs={12} sm={12}>
            <TabelaProdutos />
          </Grid>
        </Grid>
      </Container>
    </DadosContext.Provider>
  );
}
