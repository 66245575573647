import React from "react";

import SelectSetor from "../../../../components/SelectSetor";
import SelectSituacao from "../../../../components/SelectSituacao";
import Button from "../../../../../../componentsv2/Button";
import Paper from "../../../../../../componentsv2/Paper";
import GridContainer from "../../../../../../componentsv2/GridContainer";
import GridItem from "../../../../../../componentsv2/GridItem";
import SelectManifestacao from "../../../../components/SelectManifestacao";

export default function Cabecalho({
  onClick,
  setSetor,
  setor,
  situacao,
  setSituacao,
  tipoManifestacao,
  setTipoManifestacao,
}) {
  return (
    <Paper>
      <GridContainer sx={{ display: "flex", justifyContent: "space-between" }}>
        <GridContainer sx={{ paddingLeft: 2 }} xs={12} md={8} spacing={2}>
          <GridItem md={3} xs={12}>
            <SelectSituacao
              value={situacao}
              onChange={e => {
                setSituacao(e.target.value);
              }}
            />
          </GridItem>
          <GridItem md={3} xs={12}>
            <SelectSetor
              value={setor}
              onChange={e => {
                setSetor(e.target.value);
              }}
            />
          </GridItem>
          <GridItem md={4} xs={12}>
            <SelectManifestacao
              todas={true}
              value={tipoManifestacao}
              onChange={e => {
                setTipoManifestacao(e.target.value);
              }}
            />
          </GridItem>
        </GridContainer>
        <GridItem className="paper-categoria" md={2} xs={12}>
          <Button variant="contained" fullWidth onClick={onClick}>
            Adicionar
          </Button>
        </GridItem>
      </GridContainer>
    </Paper>
  );
}
