import React, { useContext } from "react";
import { useSnackbar } from "notistack";

import { api } from "../../../../../services/Api";
import Tabela from "../../../../../componentsv2/Tabela";
import { useState } from "react";
import { useEffect } from "react";
import { Context } from "../../../../../Context/PermissoesContext";

export default function TabelaProcedimentos({
  setSelectedRows,
  atualizaTabela,
  selectedRows,
  desconto,
  setTotal,
}) {
  const [listaProcedimentos, setListaProcedimentos] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const { checarPermissao } = useContext(Context);
  const columns = [
    {
      field: "nome",
      headerName: "Procedimento",
      headerAlign: "",
      align: "",
      width: 240,
    },
    {
      field: "precoExame",
      headerName: "Valor",
      headerAlign: "center",
      align: "center",
      hide: !checarPermissao("AGC002"),
      width: 100,
    },
    {
      field: "tempoEstimado",
      headerName: "Tempo Estimado",
      headerAlign: "center",
      align: "center",
      width: 100,
    },
  ];
  const dadosProcedimento = async () => {
    try {
      const response = await api.get("/v2/agendaCare/procedimentos");
      setListaProcedimentos(response.data.data);
    } catch (error) {
      enqueueSnackbar("Não foi possível encontrar os procedimentos", {
        variant: "error",
      });
    }
  };

  useEffect(() => {
    dadosProcedimento();
    // eslint-disable-next-line
  }, [atualizaTabela]);

  useEffect(() => {
    if (selectedRows.length > 0) {
      let arrayDePrecos = [];
      listaProcedimentos.forEach((procedimento) => {
        if (selectedRows.find((el) => procedimento.id === el)) {
          arrayDePrecos.push(procedimento.precoExame);
        }
      });
      let result =
        arrayDePrecos.length > 1
          ? arrayDePrecos?.reduce((a, b) => {
              return a + b;
            })
          : arrayDePrecos[0];
      result = result - desconto;
      const resultado = result.toLocaleString("pt-br", {
        style: "currency",
        currency: "BRL",
      });
      setTotal(resultado);
    } else {
      setTotal(0);
    }
    // eslint-disable-next-line
  }, [selectedRows, desconto]);

  return (
    <div style={{ height: 600, width: "100%" }}>
      <Tabela
        rowSelectionModel={selectedRows}
        ocultarToolbar
        dataSet="Commodity"
        rowLength={listaProcedimentos.Length}
        rows={listaProcedimentos}
        columns={columns}
        checkboxSelection
        onRowSelectionModelChange={(newSelectionModel) => {
          setSelectedRows(newSelectionModel);
        }}
      />
    </div>
  );
}
