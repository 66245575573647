import Header from "../../components/Header";
import Container from "../../componentsv2/Container";
import Tabela from "../../componentsv2/Tabela";
import Paper from "../../componentsv2/Paper";
import { Input } from "../../componentsv2/Input";
import { useState, useEffect } from "react";
import GridContainer from "../../componentsv2/GridContainer";
import GridItem from "../../componentsv2/GridItem";
import { Typography, Box } from "@mui/material";
import "./styles.css";
import SalvaLog from "../../utils/SalvaLog";

function getMultiplicador(quantidade) {
  if (quantidade >= 1 && quantidade <= 999) {
    return 0.025;
  } else if (quantidade >= 1000 && quantidade <= 1499) {
    return 0.03;
  } else if (quantidade >= 1500 && quantidade <= 1999) {
    return 0.035;
  } else if (quantidade >= 2000 && quantidade <= 2499) {
    return 0.04;
  } else if (quantidade >= 2500 && quantidade <= 2999) {
    return 0.045;
  } else if (quantidade >= 3000 && quantidade <= 3499) {
    return 0.05;
  } else if (quantidade >= 3500 && quantidade <= 3999) {
    return 0.055;
  } else if (quantidade >= 4000 && quantidade <= 4499) {
    return 0.06;
  } else if (quantidade >= 4500 && quantidade <= 4999) {
    return 0.065;
  } else if (quantidade >= 5000 && quantidade <= 5499) {
    return 0.07;
  } else if (quantidade >= 5500 && quantidade <= 5999) {
    return 0.075;
  } else if (quantidade >= 6000 && quantidade <= 99999) {
    return 0.08;
  } else {
    return 0;
  }
}

function calcularPorcentagem(valor) {
  // Limita o valor a 100 se for maior
  if (valor > 100) {
    valor = 100;
  }

  // Calcula a porcentagem
  let porcentagem = valor / 100;

  return porcentagem;
}

export default function CalculadoraProdutividade() {
  const [listaValores, setListaValores] = useState([
    {
      id: 1,
      tipo: "Particular",
      quantidadeExames: "",
      multiplicador: 0,
      valorFinal: 0,
    },
    {
      id: 2,
      tipo: "Convênio",
      quantidadeExames: "",
      multiplicador: 0,
      valorFinal: 0,
    },
    {
      id: 3,
      tipo: "SUS",
      quantidadeExames: "",
      multiplicador: 0,
      valorFinal: 0,
    },
    {
      id: 4,
      tipo: "Bitrix",
      quantidadeExames: "",
      multiplicador: 0,
      valorFinal: 0,
    },
  ]);
  const [quantidadeExamesBitrix, setQuantidadeExamesBitrix] = useState(0);
  const [numeroTotal, setNumeroTotal] = useState(0);
  const [numeroTotalExames, setNumeroTotalExames] = useState(0);
  const [update, setUpdate] = useState(false);

  const salvarLogCalculadora = async () => {
    try {
      SalvaLog("Entrou na agenda de mensagens do app", "Mensagens App");
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    salvarLogCalculadora();
  }, []);

  const columns = [
    {
      field: "tipo",
      headerName: "Tipo",
      type: "string",
      flex: 1,
    },
    {
      field: "quantidadeExames",
      headerName: "Quantidade Exames",
      type: "number",
      //   editable: true,
      headerAlign: "left",
      align: "left",
      flex: 1,
      renderCell: (params) => (
        <div>
          <Input
            type="number"
            variant="standard"
            placeholder="Digite a quantidade"
            sx={{
              border: "1px solid #000",
              borderRadius: "5px",
              padding: "5px",
              backgroundColor: "#f0f0f0",
            }}
            value={
              params.row.tipo === "Total" || params.row.tipo === "Bônus Bitrix"
                ? params.row.quantidadeExames
                : undefined
            }
            InputProps={{
              disableUnderline: true,
              inputProps: {
                style: {
                  textAlign: "center",
                  fontSize: "15px",
                  appearance: "none",
                },
              },
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.target.blur();
              }
            }}
            onBlur={(e) => {
              handleChange(params.row, e.target.value, params.row.tipo);
            }}
            // disabled={params.row.tipo === "Total"}
          />
        </div>
      ),
    },
    {
      field: "multiplicador",
      headerName: "Multiplicador",
      type: "string",
      flex: 1,
      valueGetter: (params) => {
        if (params.row.tipo === "SUS") {
          return numeroTotalExames
            ? getMultiplicador(numeroTotalExames) / 2
            : 0;
        } else if (params.row.tipo === "Particular") {
          return numeroTotalExames
            ? (getMultiplicador(numeroTotalExames) * 1.5).toFixed(4)
            : 0;
        } else if (params.row.tipo === "Convênio") {
          return numeroTotalExames
            ? getMultiplicador(numeroTotalExames).toFixed(4)
            : 0;
        } else if (params.row.tipo === "Bitrix") {
          return numeroTotalExames
            ? getMultiplicador(numeroTotalExames).toFixed(4)
            : 0;
        } else if (params.row.tipo === "Bônus Bitrix") {
          return numeroTotalExames
            ? getMultiplicador(numeroTotalExames).toFixed(4)
            : 0;
        }
      },
    },
    {
      field: "valorFinal",
      headerName: "Valor Final",
      type: "string",
      flex: 1,

      renderCell: (params) => (
        <Box>
          {params.row.tipo === "Total" ? (
            <Typography color="green" variant="h6" fontWeight="bold">
              {params.value.toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL",
              })}
            </Typography>
          ) : (
            <Typography color="black" variant="body2">
              {params.value.toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL",
              })}
            </Typography>
          )}
        </Box>
      ),
    },
  ];

  useEffect(() => {
    const totalExames = listaValores.reduce((acc, item) => {
      return acc + Number(item.quantidadeExames);
    }, 0);

    setNumeroTotalExames(totalExames);

    const total = totalExames;

    const multiplicadorSUS = total ? getMultiplicador(total) / 2 : 0;
    const multiplicadorParticular = total ? getMultiplicador(total) * 1.5 : 0;
    const multiplicadorConvenio = total ? getMultiplicador(total) : 0;
    const multiplicadorBitrix = total ? getMultiplicador(total) : 0;
    const multiplicadorBonusBitrix = total ? getMultiplicador(total) : 0;

    setListaValores((prevState) => {
      return prevState.map((item) => {
        let multiplicador = 0;
        if (item.tipo === "SUS") multiplicador = multiplicadorSUS;
        else if (item.tipo === "Particular")
          multiplicador = multiplicadorParticular;
        else if (item.tipo === "Convênio")
          multiplicador = multiplicadorConvenio;
        else if (item.tipo === "Bitrix") multiplicador = multiplicadorBitrix;
        else if (item.tipo === "Bônus Bitrix")
          multiplicador = multiplicadorBonusBitrix;

        return {
          ...item,
          multiplicador,
          valorFinal: item.quantidadeExames * multiplicador,
        };
      });
    });
  }, [update]);

  const handleChange = (row, e, tipo) => {
    setUpdate(!update);
    const totalExames = listaValores.reduce((acc, item) => {
      if (tipo === item.tipo) return acc + Number(e);
      return acc + Number(item.quantidadeExames);
    }, 0);

    const total = totalExames;

    if (row.tipo === "Bitrix") {
      setQuantidadeExamesBitrix(Number(e));
    }

    const multiplicadorSUS = total ? getMultiplicador(total) / 2 : 0;
    const multiplicadorParticular = total ? getMultiplicador(total) * 1.5 : 0;
    const multiplicadorConvenio = total ? getMultiplicador(total) : 0;
    const multiplicadorBitrix = total ? getMultiplicador(total) : 0;
    const multiplicadorBonusBitrix = total ? getMultiplicador(total) : 0;

    setListaValores((prevState) => {
      return prevState.map((item) => {
        let multiplicador = 0;
        if (item.tipo === "SUS") multiplicador = multiplicadorSUS;
        else if (item.tipo === "Particular")
          multiplicador = multiplicadorParticular;
        else if (item.tipo === "Convênio")
          multiplicador = multiplicadorConvenio;
        else if (item.tipo === "Bitrix") multiplicador = multiplicadorBitrix;
        else if (item.tipo === "Bônus Bitrix")
          multiplicador = multiplicadorBonusBitrix;

        if (item.id === row.id) {
          return {
            ...item,
            multiplicador,
            quantidadeExames: e,
            valorFinal: e * multiplicador,
          };
        }
        return item;
      });
    });
    setUpdate(!update);
  };

  const listaValoresComTotais = [
    ...listaValores,
    {
      id: listaValores.length + 1,
      tipo: "Bônus Bitrix",
      quantidadeExames: quantidadeExamesBitrix,
      multiplicador: getMultiplicador(numeroTotalExames),
      valorFinal: listaValores.find((item) => item.tipo === "Bitrix")
        .valorFinal,
    },
    {
      id: listaValores.length + 2,
      tipo: "Total",
      quantidadeExames: numeroTotalExames,
      multiplicador: getMultiplicador(numeroTotalExames),
      valorFinal: numeroTotal,
    },
  ];

  useEffect(() => {
    const totalValor =
      (listaValores.reduce((acc, item) => {
        return acc + item.valorFinal;
      }, 0) +
        listaValores[listaValores.length - 1].valorFinal) *
      calcularPorcentagem(
        listaValoresComTotais?.find((item) => item.tipo === "Bônus Bitrix")
          .quantidadeExames
      );

    setNumeroTotal(totalValor);
  }, [listaValoresComTotais, listaValores]);

  return (
    <>
      <Header titulo="Calculadora Produtividade" />
      <Container>
        <Paper elevation={2} sx={{ marginTop: "50px" }}>
          <Tabela
            getRowClassName={(params) => {
              if (params.row.tipo === "Total") {
                return "custom-row";
              }
              return "";
            }}
            initialState={{
              columns: {
                columnVisibilityModel: {
                  multiplicador: false,
                },
              },
            }}
            columns={columns}
            rows={listaValoresComTotais}
          />
        </Paper>
      </Container>
    </>
  );
}
