import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Button,
} from "@mui/material";

import React, { useContext } from "react";
import DataPickerAtualizado from "../../../../components/DataPickerAtualizado";
import DadosContext from "../DadosContext";
import { colorTheme } from "../../../../theme";
import SelectLocal from "../../components/SelectLocal";
import useDadosContextEstoque from "../../hooks/useDadosContextEstoque";

export default function HeaderSelect() {
  const {
    todosOsLocais,
    localOrigem,
    setLocalOrigem,
    localDestino,
    setLocalDestino,
    status,
    setStatus,

    setDataInicialFormatada,
    setDataFinalFormatada,
    semAcessoUnidadeTransferencias,

    limparFiltros,
    dataInicialTransferencias,
    setDataInicialTransferencias,
    dataFinalTransferencias,
    setDataFinalTransferencias,
  } = useDadosContextEstoque();

  return (
    <Paper elevation={2}>
      <Grid container>
        <Grid className="paper-categoria" item xs={6} sm={2}>
          <DataPickerAtualizado
            variant="standard"
            size="small"
            data={dataInicialTransferencias}
            setData={setDataInicialTransferencias}
            labelName="Data inicial"
            setDataFormatada={setDataInicialFormatada}
          />
        </Grid>
        <Grid className="paper-categoria" item xs={6} sm={2}>
          <DataPickerAtualizado
            variant="standard"
            size="small"
            data={dataFinalTransferencias}
            setData={setDataFinalTransferencias}
            labelName="Data final"
            setDataFormatada={setDataFinalFormatada}
          />
        </Grid>
        <Grid className="paper-categoria" item xs={6} sm={3}>
          <SelectLocal
            label="Remetente"
            value={localOrigem}
            setValue={setLocalOrigem}
            locais={todosOsLocais}
          />
        </Grid>
        <Grid className="paper-categoria" item xs={6} sm={3}>
          <SelectLocal
            label="Destinatário"
            value={localDestino}
            setValue={setLocalDestino}
            locais={todosOsLocais}
            ativo={semAcessoUnidadeTransferencias}
          />
        </Grid>
        <Grid className="paper-categoria" item xs={6} sm={2}>
          <FormControl
            defaultValue=" "
            size="small"
            variant="standard"
            fullWidth
          >
            <InputLabel id="select-label">Situação</InputLabel>
            <Select
              value={status}
              onChange={(e) => setStatus(e.target.value)}
              labelId="select-label"
              id="select"
              label="Ramal"
            >
              <MenuItem value="">Todos</MenuItem>
              <MenuItem value="Pendente">Pendente</MenuItem>
              <MenuItem value="Enviado">Enviado</MenuItem>
              <MenuItem value="Recebido">Recebido</MenuItem>
              <MenuItem value="Cancelado">Cancelado</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid className="paper-categoria" item xs={12} sm={12}>
          <Button
            onClick={() => limparFiltros()}
            size="small"
            style={{ color: colorTheme.primary[500] }}
          >
            Limpar datas
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
}
