import React, { useEffect } from "react";
import { HashRouter, BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "@mui/material";

import { PermissoesProvider } from "./Context/PermissoesContext";
import { AuthProvider } from "./Context/AuthContext";

import RoutasDNA from "./routes";

import { globalTheme } from "./theme/themeMUI";
import { DadosEstoqueProvider } from "./pages/Almoxarifado/context/dadosEstoqueContext";
import { AgendaCareProvider } from "./Context/AgendaCareContext";

function App() {
  const { pathname } = window.location;

  useEffect(() => {
    if (pathname === "/intranet") {
      window.location.href = "/";
    }
  }, []);

  return (
    <ThemeProvider theme={globalTheme}>
      <BrowserRouter>
        <AuthProvider>
          <PermissoesProvider>
            <DadosEstoqueProvider>
              <AgendaCareProvider>
                <RoutasDNA />
              </AgendaCareProvider>
            </DadosEstoqueProvider>
          </PermissoesProvider>
        </AuthProvider>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
