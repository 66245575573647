export default function checarData(data) {
  const dataValida = Date.parse(data);
  // eslint-disable-next-line
  if (!dataValida || isNaN(dataValida)) {
    return false
  }

  const ano = Number(data.slice(0, 4));
  if (ano < 2000 && ano > 2030) {
    return false
  }

  return true
}
