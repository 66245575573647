import { Button, Grid, useMediaQuery, useTheme } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { Context as PermissoesContext } from "../../../../Context/PermissoesContext";

export default function BotaoCadastrarTransferencia() {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { checarPermissao } = useContext(PermissoesContext);

  const theme = useTheme();
  const tamanho = useMediaQuery(theme.breakpoints.up("md"));

  const handleClickButton = async () => {
    if (await !checarPermissao("AL012")) {
      enqueueSnackbar("É necessário a permissão AL012", {
        variant: "warning",
      });
    } else {
      history.push("/almoxarifado/transferencia/criar");
    }
  };
  return (
    <Grid container>
      <Grid
        style={{ display: "flex", alignItems: "center", justifyContent: "end" }}
        item
        xs={12}
        sm={12}
      >
        <Button
          onClick={() => handleClickButton()}
          style={{ color: "#fff", backgroundColor: "#003399" }}
          variant="outlined"
          size={tamanho ? "medium" : "small"}
        >
          Realizar Transferência
        </Button>
      </Grid>
    </Grid>
  );
}
