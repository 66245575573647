import React from "react";
import { Modal, Paper } from "@mui/material";
import { styled } from "@mui/system";

import Button from "../Button";

import "./styles.css";

const Body = styled(Paper)({
  root: {
    width: "95%",
    marginLeft: "auto",
    marginRight: "auto",
    maxWidth: "700px",
    padding: "1.5rem",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
});

export default function MyModal({
  open,
  closeModal,
  title,
  text,
  cancelButtonLabel,
  confirmButtonLabel,
  cancelButtonColor,
  confirmButtonColor,
  onCancel,
  onConfirm,
  notShowCancel,
}) {
  return (
    <Modal open={open} onClose={closeModal}>
      <Body className="my-modal-body">
        <h1 className="modal-title">{title}</h1>
        <p className="modal-text">{text}</p>
        <div className="modal-buttons">
          {!notShowCancel && (
            <Button
              secondary
              onClick={onCancel}
              style={{
                backgroundColor: cancelButtonColor,
                marginRight: ".5rem",
              }}
            >
              {cancelButtonLabel || "CANCELAR"}
            </Button>
          )}
          <Button
            secondary
            onClick={onConfirm}
            style={{ backgroundColor: confirmButtonColor }}
          >
            {confirmButtonLabel || "CONFIRMAR"}
          </Button>
        </div>
      </Body>
    </Modal>
  );
}
