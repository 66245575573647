import React, { useState, useEffect } from "react";
import { format } from "date-fns";
import { useSnackbar } from "notistack";
import useUser from "../../hooks/useUser";

import "./styles.css";

import { api } from "../../services/Api";

import Tabela from "./Tabela";
import Formulario from "./Formulario";
import Totalizador from "./Totalizador";
import ModalLimite from "./ModalLimite";
import Header from "../../components/Header";
import { Container } from "@mui/system";

export default function Agendamento() {
  const usuario = useUser();

  const { enqueueSnackbar } = useSnackbar();

  const agenda = "Drive-Thru";
  const [agendamentos, setAgendamentos] = useState([]);
  const [totalAgendado, setTotalAgendado] = useState([]);
  const [totalColetado, setTotalColetado] = useState([]);

  const [novaData, setNovaData] = useState(format(new Date(), "yyyy-MM-dd"));
  const [vetorHora, setVetorHora] = useState([]);

  const [atualizaTabela, setAtualizaTabela] = useState(false);

  const [exibeModalLimites, setExibeModalLimites] = useState(false);
  const [opcao, setOpcao] = useState(false);
  const [menuStatus, setMenuStatus] = useState(false);
  const [id, setId] = useState("");
  const [status, setStatus] = useState("");
  const [limite, setLimite] = useState(null);

  useEffect(() => {
    async function iniciar() {
      try {
        const result = await api.get(
          `/api/agendamentos/disponibilidade/${novaData}/${agenda}`
        );
        setVetorHora(result.data);
      } catch (error) {
        enqueueSnackbar("Horários não carregados", { variant: "error" });
      }
    }
    iniciar();
  }, [enqueueSnackbar, novaData, agenda, atualizaTabela]);

  useEffect(() => {
    async function iniciar() {
      try {
        const { data: result } = await api.get(`/api/agendamentos/${novaData}/${agenda}`);
        setTotalAgendado(result.filter(r => r.status.includes("Agendado")));
        setTotalColetado(result.filter(r => r.status.includes("Coletado")));
        setAgendamentos(result);
      } catch (error) {
        enqueueSnackbar("Falha na operação", { variant: "error" });
      }
    }
    iniciar();
  }, [enqueueSnackbar, novaData, agenda, atualizaTabela]);

  useEffect(() => {
    async function iniciar() {
      try {
        const { data: result } = await api.get(
          `/api/agendamentos/limite/${novaData}/${agenda}`
        );
        setLimite(result.limite);
      } catch (error) {
        enqueueSnackbar("Não foi possível obter o limite", { variant: "error" });
      }
    }
    iniciar();
  }, [enqueueSnackbar, novaData, agenda, limite]);

  return (
    <div>
      <Header titulo="Agendamentos" />
        <Container>
          <Formulario
            id={id}
            usuario={usuario}
            opcao={opcao}
            setOpcao={setOpcao}
            novaData={novaData}
            vetorHora={vetorHora}
            setVetorHora={setVetorHora}
            setNovaData={setNovaData}
            status={status}
            menuStatus={menuStatus}
            setMenuStatus={setMenuStatus}
            setAtualizaTabela={setAtualizaTabela}
            setExibeModalLimites={setExibeModalLimites}
          />
          <Totalizador
            total={agendamentos.length || 0}
            agendado={totalAgendado.length || 0}
            coletado={totalColetado.length || 0}
            limite={limite}
          />
          <Tabela
            setOpcao={setOpcao}
            setMenuStatus={setMenuStatus}
            agendamentos={agendamentos}
            setAgendamentos={setAgendamentos}
            setId={setId}
            setStatus={setStatus}
          />
          <ModalLimite
            exibeModalLimites={exibeModalLimites}
            setExibeModalLimites={setExibeModalLimites}
            limite={limite}
          />
        </Container>
    </div>
  );
}
