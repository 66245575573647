import GridItem from "../../componentsv2/GridItem";
import React, { useEffect, useContext } from "react";
import { HeaderConteinerComSideBar } from "../../componentsv2/HeaderConteinerComSideBar";
import Tabela from "./components/Tabela";
import { Context as PermissoesContext } from "../../Context/PermissoesContext";
import { useHistory } from "react-router";
import Header from "../../components/Header";
import Container from "../../componentsv2/Container";

export default function VisualizarBI() {
  const history = useHistory();
  const { checarPermissaoSemLog } = useContext(PermissoesContext);

  useEffect(() => {
    const chegarVisualizacao = async () => {
      if (!checarPermissaoSemLog("CTB001")) {
        history.push("/home");
      }
    };
    chegarVisualizacao();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Header titulo="Central de BI´S"/>
      <Container>
        <GridItem>
          <Tabela/>
        </GridItem>
      </Container>
    </>
  );
}
