import React, { useState } from "react";
import { Grid, Paper } from "@mui/material";
import SelectLocal from "../../components/SelectLocal";
import { useEffect } from "react";

import { api } from "../../../../services/Api";
import DataPickerAtualizado from "../../../../components/DataPickerAtualizado";
import SelectTipo from "../../components/SelectTipo";
import useDadosContextEstoque from "../../hooks/useDadosContextEstoque";

export function HeaderSelectBaixa() {
  const [locais, setLocais] = useState([]);

  const { setLocalizacaoBaixa, localizacaoBaixa, tipoBaixa, setTipoBaixa } =
    useDadosContextEstoque();

  const tipos = [
    { id: "1", tipo: "Entrada" },
    { id: "2", tipo: "Entrada - Transferencia" },
    { id: "3", tipo: "Saída" },
    { id: "4", tipo: "Saída - Transferencia" },
  ];

  useEffect(() => {
    const obterUnidades = async () => {
      try {
        const { data } = await api.get("/v2/almoxarifado/estoque/unidades");
        setLocais(data.unidades);
      } catch (e) {
        console.log(e);
      }
    };

    obterUnidades();
  }, []);
  return (
    <Paper elevation={2}>
      <Grid container>
        {/* <Grid className="paper-categoria" item xs={6} sm={2}>
          <DataPickerAtualizado
            variant="standard"
            size="small"
            data={dataInicial}
            setData={setDataInicial}
            labelName="Data inicial"
            // setDataFormatada={setDataInicialFormatada}
            //disabled
          />
        </Grid>
        <Grid className="paper-categoria" item xs={6} sm={2}>
          <DataPickerAtualizado
            variant="standard"
            size="small"
            data={dataFinal}
            setData={setDataFinal}
            labelName="Data final"
            // setDataFormatada={setDataFinalFormatada}
            //disabled
          />
        </Grid> */}
        <Grid className="paper-categoria" item xs={12} sm={4}>
          <SelectLocal
            label="Localização"
            value={localizacaoBaixa}
            setValue={setLocalizacaoBaixa}
            locais={locais}
            disabled
          />
        </Grid>
        <Grid className="paper-categoria" item xs={12} sm={4}>
          <SelectTipo
            label="Tipo"
            value={tipoBaixa}
            setValue={setTipoBaixa}
            tipos={tipos}
            disabled
          />
        </Grid>
      </Grid>
    </Paper>
  );
}
