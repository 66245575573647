import React, { createContext, useContext, useEffect, useState } from "react";
import { useSnackbar } from "notistack";

import formatDateBD from "../../../utils/formatarDataBD";
import SalvaLog from "../../../utils/SalvaLog";

import { ColetaDomiciliarContext } from "./ColetaDomiciarContext";
import { FormularioColetaDomiciliarContext } from "./FormularioColetaDomiciliarContext";
import { ModaisColetaDomiciliarContext } from "./ModaisColetaDomiciarContext";

import { api } from "../../../services/Api";

const ColetaDomiciliarBloqueioContext = createContext();

function ColetaDomiciliarBloqueioContextProvider({ children }) {
  const { enqueueSnackbar } = useSnackbar();

  const { novaHora } = useContext(FormularioColetaDomiciliarContext);
  const { novaData, coletador } = useContext(ColetaDomiciliarContext);
  const { fechaModalMotivoBloqueio } = useContext(
    ModaisColetaDomiciliarContext
  );

  const [bloqueados, setBloqueados] = useState([]);
  const [atualizaTabelaBloqueio, setAtualizaTabelaBloqueio] = useState(false);
  const [modalOpcoesBloqueio, setModalOpcoesBloqueio] = useState(false);
  const [idBloqueio, setIdBloqueio] = useState("");
  const [motivo, setMotivo] = useState("");

  const [listaBloqueios, setListaBloqueios] = useState([]);

  useEffect(() => {
    async function iniciar() {
      try {
        const { data: resultado } = await api.get(
          `/api/coletaDomiciliarBloqueio/${formatDateBD(novaData)}`
        );

        setBloqueados(resultado);
      } catch (error) {
        enqueueSnackbar("Falha na operação", { variant: "error" });
      }
    }
    iniciar();
  }, [enqueueSnackbar, novaData, atualizaTabelaBloqueio]);

  async function criaBloqueio() {
    const bloqueio = {
      data: formatDateBD(novaData),
      hora: novaHora,
      coletador,
      motivo,
    };
    try {
      const { data: resultado } = await api.post(
        "/api/coletaDomiciliarBloqueio/",
        {
          data: formatDateBD(novaData),
          hora: novaHora,
          coletador,
          motivo,
        }
      );

      SalvaLog(
        `Bloqueou agendamentos de Id ${
          resultado.id
        } para ${coletador}, no dia ${formatDateBD(
          novaData
        )} e horário ${novaHora}`,
        "Coleta Domiciliar",
        JSON.stringify(bloqueio),
        `${resultado.id}`
      );

      setAtualizaTabelaBloqueio((prevState) => !prevState);

      setMotivo("");
      fechaModalMotivoBloqueio();
    } catch (error) {
      enqueueSnackbar("Falha na criação do bloqueio", { variant: "error" });
    }
  }

  async function atualizaBloqueio() {
    const bloqueioAtualziado = {
      idBloqueio,
      novaData,
      novaHora,
      coletador,
      motivo,
    };
    try {
      await api.put("/api/coletaDomiciliarBloqueio/", {
        idBloqueio,
        novaData,
        novaHora,
        coletador,
        motivo,
      });

      SalvaLog(
        `Atualizou o bloqueio de Id ${idBloqueio} para o ${coletador}, no dia ${novaData} e horário ${novaHora}`,
        "Coleta Domiciliar",
        JSON.stringify(bloqueioAtualziado),
        `${idBloqueio}`
      );

      setAtualizaTabelaBloqueio((prevState) => !prevState);
    } catch (error) {
      enqueueSnackbar("Falha na atualização do bloqueio", { variant: "error" });
    }
  }

  async function apagaBloqueio() {
    try {
      await api.delete(`/api/coletaDomiciliarBloqueio/${idBloqueio}`);

      SalvaLog(
        `Deletou o bloqueio ${idBloqueio}`,
        "Coleta Domiciliar",
        "",
        `${idBloqueio}`
      );

      setAtualizaTabelaBloqueio((prevState) => !prevState);
    } catch (error) {
      enqueueSnackbar("Falha na exclusão do bloqueio", { variant: "error" });
    }
  }

  function fechaModalOpcoesBloqueio() {
    setIdBloqueio("");
    setModalOpcoesBloqueio(false);
  }

  return (
    <ColetaDomiciliarBloqueioContext.Provider
      value={{
        bloqueados,
        setBloqueados,
        atualizaTabelaBloqueio,
        setAtualizaTabelaBloqueio,
        motivo,
        setMotivo,
        criaBloqueio,
        atualizaBloqueio,
        apagaBloqueio,
        idBloqueio,
        setIdBloqueio,
        modalOpcoesBloqueio,
        setModalOpcoesBloqueio,
        fechaModalOpcoesBloqueio,
        listaBloqueios,
        setListaBloqueios,
      }}
    >
      {children}
    </ColetaDomiciliarBloqueioContext.Provider>
  );
}

export {
  ColetaDomiciliarBloqueioContext,
  ColetaDomiciliarBloqueioContextProvider,
};
