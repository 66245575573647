import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import {
  CircularProgress,
  Menu,
  MenuItem,
  Stack,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import PopupState, { bindMenu, bindTrigger } from "material-ui-popup-state";

import Button from "../../../../componentsv2/Button";
import ModalBaixa from "../../components/ModalBaixa";

import "./styles.css";

import { Context as PermissoesContext } from "../../../../Context/PermissoesContext";
import { api } from "../../../../services/Api";
import useDadosContextEstoque from "../../hooks/useDadosContextEstoque";

// function onClickProduto() {}

// function onClickTransacao(){

// }

// "/almoxarifado/CadastraProduto"

export default function Buttons() {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { checarPermissao } = useContext(PermissoesContext);
  const theme = useTheme();
  const tamanho = useMediaQuery(theme.breakpoints.up("sm"));

  const { localSelecionado, sincronizar, setSincronizar } =
    useDadosContextEstoque();

  const [open, setOpen] = useState(false);

  async function onClickProduto() {
    if (await !checarPermissao("AL002")) {
      enqueueSnackbar("É necessário a permissão AL002", {
        variant: "warning",
      });
      history.push("/almoxarifado");
    } else {
      history.push("/almoxarifado/produtos");
    }
  }

  async function onClickCategoria() {
    if (await !checarPermissao("AL003")) {
      enqueueSnackbar("É necessário a permissão AL003", {
        variant: "warning",
      });
      history.push("/almoxarifado");
    } else {
      history.push("/CadastraCategoria");
    }
  }

  // const obtemCliqueBaixa = () => {
  //   setOpen(true);
  // };

  const sincronizaFortes = async () => {
    setSincronizar(true);
    try {
      await api.get("/sincronizaFortes");
      enqueueSnackbar("A sincronização foi concluída", { variant: "success" });
    } catch (e) {
      enqueueSnackbar("Houve um erro ao sincronizar", { variant: "error" });
    }
    setSincronizar(false);
  };

  return (
    <Stack
      justifyContent={{ xs: "space-between", sm: "flex-end" }}
      direction="row"
      spacing={2}
    >
      {/* {tamanho && (
        <Button
          variant="contained"
          endIcon={
            sincronizar && <CircularProgress size={20} sx={{ color: "#fff" }} />
          }
          onClick={() => sincronizaFortes()}
          disabled={sincronizar}
        >
          Sincronizar
        </Button>
      )} */}
      {/* {!tamanho && (
        <Button
          variant="contained"
          onClick={() => setSincronizar(!sincronizar)}
        >
          {sincronizar ? (
            <CircularProgress size={20} sx={{ color: "#fff" }} />
          ) : (
            "Sincronizar"
          )}
        </Button>
      )} */}
      {/* {localSelecionado ? (
        <Button
          onClick={() => setOpen(true)}
          variant="contained"
          color="blue"
          endIcon={<QrCodeScannerIcon />}
        >
          Baixa
        </Button>
      ) : (
        false
      )} */}
      <ModalBaixa open={open} setOpen={setOpen} local={localSelecionado} />
      <PopupState variant="popover" popupId="ações">
        {(popupState) => (
          <div>
            <Button
              color="blue"
              variant="contained"
              {...bindTrigger(popupState)}
            >
              Ações
            </Button>
            <Menu {...bindMenu(popupState)}>
              <MenuItem onClick={() => onClickProduto()}>Produtos</MenuItem>
              <MenuItem onClick={() => onClickCategoria()}>Categorias</MenuItem>
            </Menu>
          </div>
        )}
      </PopupState>
    </Stack>
  );
}

// import { Fab, Grid } from "@mui/material";
// import AddIcon from "@mui/icons-material/Add";
// import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

// <Grid className="buttons-styles" container wrap="nowrap">
//   <Grid item xs={6} sm={2}>
//     <Fab color="primary" size="small" type="button">
//       <AddIcon />
//     </Fab>
//   </Grid>
//   <Grid item xs={6} sm={2}>
//     <Fab color="primary" size="small" type="button">
//       <ArrowDownwardIcon />
//     </Fab>
//   </Grid>
// </Grid>
