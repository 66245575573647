import React from "react";
import { useEffect, useState } from "react";
import {
  Typography,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  IconButton,
  Autocomplete,
  TextField,
  CircularProgress,
} from "@mui/material";
import { Close, Search } from "@mui/icons-material";
import Paper from "../../../../componentsv2/Paper";
import Button from "../../../../componentsv2/Button";
import Tabela from "../../../../componentsv2/Tabela";

import { enqueueSnackbar } from "notistack";

import ModalConfirmaLote from "./ModalConfirmaLote";

import DataPickerAtualizado from "../../../../components/DataPickerAtualizado";

import formatDateBD from "../../../../utils/formatarDataBD";

import { api } from "../../../../services/Api";

const ModalAdicionarLote = ({ open, handleClose, children }) => {
  const [modalAdicionaLote, setAdicionaLote] = useState(false);

  const handleOpenAdicionaModal = () => setAdicionaLote(true);
  const handleCloseAdicionaModal = () => setAdicionaLote(false);

  const [loadingSoftlab, setLoadingSoftlab] = useState(false);

  //const [notaFiscal, setNotaFiscal] = useState("");
  const [convenio, setConvenio] = useState([]);

  const [valorSoftlab, setValorSoftlab] = useState([]);

  const [competencia, setCompetencia] = useState("");

  const [dataInicial, setDataInicial] = useState(() => {
    const valueDateI = new Date();
    valueDateI.setDate(valueDateI.getDate() - 10);
    return valueDateI;
  });

  const [dataFinal, setDataFinal] = useState(new Date());

  const [listaConvenio, setListaConvenio] = useState([]);

  const [lote, setLote] = useState("");

  const [lotes, setLotes] = useState([]);

  const [valorLoteado, setValorLoteado] = useState(0);

  const [valorALotear, setValorALotear] = useState(0);

  const getConvenios = async () => {
    try {
      const results = await api.get("/v2/faturamento/convenios");
      const listaConvenio = results.data;

      setListaConvenio(listaConvenio);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getConvenios();
  }, []);

  const getTotalSoftlab = async () => {
    let inicio = `${formatDateBD(dataInicial)} 00:00:00`;
    let final = `${formatDateBD(dataFinal)} 23:59:59`;
    setLoadingSoftlab(true);
    try {
      const { data } = await api.get("/v2/faturamento/lotesPorPlano", {
        params: {
          data_inicial: inicio,
          data_final: final,
          plano: convenio,
        },
      });

      let soma2 = data?.reduce((soma2, atual2) => soma2 + atual2.valor, 0);

      setValorLoteado(soma2);

      setLotes(data);
    } catch (error) {
      console.log(error);
    }

    try {
      const { data: results } = await api.get(
        `/api/BuscaPorPedidos/geradoSoftlab`,
        {
          params: {
            data_inicial: dataInicial,
            data_final: dataFinal,
            competencia,
          },
        }
      );

      if (convenio !== null) {
        let filtrado = [];

        filtrado = results?.filter((item) => convenio === item.cod_guia.trim());

        const soma = filtrado.reduce(
          (soma, atual) => soma + atual.faturamento,
          0
        );

        setValorSoftlab(soma);
      } else {
        let soma = results?.reduce(
          (soma, atual) => soma + atual.faturamento,
          0
        );

        setValorSoftlab(soma);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingSoftlab(false);
    }
  };

  const ultimoLote = async () => {
    try {
      const loteUnico = await api.get("/v2/faturamento/ultimoLote", {
        params: {
          plano: convenio,
        },
      });
      setLote(loteUnico.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setValorALotear(valorSoftlab);
  }, [valorSoftlab]);

  const columns = [
    {
      field: "id",
      headerName: "ID",
    },
    {
      field: "codigo",
      headerName: "Cod. Lote",
      flex: 1,
    },
    {
      field: "plano",
      headerName: "Convênio",
      flex: 1,
    },
    {
      field: "data_envio_lote",
      headerName: "Data do envio",
      flex: 1,
      valueFormatter: ({ value }) => new Date(value)?.toLocaleDateString(),
    },
    {
      field: "valor",
      headerName: "Valor Loteado",
      type: "number",
      flex: 1,
      valueFormatter: ({ value }) =>
        value?.toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        }),
    },
  ];

  useEffect(() => {
    setValorSoftlab(0);
    setValorLoteado(0);
  }, [dataInicial, dataFinal]);

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={open}
      onClose={handleClose}
      scroll="paper"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle
        id="scroll-dialog-title"
        sx={{ justifyContent: "space-between" }}
      >
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography variant="h6">Adicionar Lote</Typography>
          </Grid>
          <Grid item>
            <IconButton onClick={() => handleClose(false)}>
              <Close />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent dividers>
        <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
          <Grid container spacing={2} direction="column">
            <Grid container item spacing={1}>
              <Grid item xs={5} md={3}>
                <Autocomplete
                  options={listaConvenio?.map((item) => item.convenio)}
                  value={convenio}
                  onChange={(event, value) => {
                    setConvenio(value);
                  }}
                  // sx={{ width: 300 }}
                  renderInput={(params) => (
                    <TextField {...params} label="Convênio" />
                  )}
                />
              </Grid>

              <Grid item xs={5} md={3}>
                <DataPickerAtualizado
                  data={dataInicial}
                  labelName="Data inicial"
                  setData={setDataInicial}
                />
              </Grid>

              <Grid item xs={5} md={3}>
                <DataPickerAtualizado
                  data={dataFinal}
                  labelName="Data final"
                  setData={setDataFinal}
                />
              </Grid>

              <Grid item xs={5} md={3}>
                <IconButton
                  style={{
                    margin: "auto",
                    height: "3rem",
                    background: "#003399",
                  }}
                  onClick={() => {
                    getTotalSoftlab();
                  }}
                >
                  <Search style={{ color: "white" }} />
                </IconButton>
              </Grid>

              <Grid item xs={3} md={3}>
                <Paper>
                  {loadingSoftlab ? (
                    <Grid container direction="column">
                      <Grid item>
                        <Typography
                          variant="body1"
                          sx={{ color: "black", textAlign: "center" }}
                        >
                          Total Gerado Softlab:
                        </Typography>
                      </Grid>
                      <Grid item marginX="auto">
                        <CircularProgress size={15} />
                      </Grid>
                    </Grid>
                  ) : (
                    <Typography
                      variant="body1"
                      sx={{ color: "black", textAlign: "center" }}
                    >
                      Total Gerado Softlab:{" "}
                      {valorSoftlab?.toLocaleString("pt-BR", {
                        style: "currency",
                        currency: "BRL",
                      })}
                    </Typography>
                  )}
                </Paper>
              </Grid>

              <Grid item xs={3} md={3}>
                <Paper>
                  <Typography
                    variant="body1"
                    sx={{ color: "black", textAlign: "center" }}
                  >
                    Total Loteado:{" "}
                    {valorLoteado?.toLocaleString("pt-BR", {
                      style: "currency",
                      currency: "BRL",
                    })}
                  </Typography>
                </Paper>
              </Grid>

              <Grid item xs={3} md={3}>
                <Paper>
                  <Typography
                    variant="body1"
                    sx={{ color: "black", textAlign: "center" }}
                  >
                    Total a Lotear:{" "}
                    {valorALotear?.toLocaleString("pt-BR", {
                      style: "currency",
                      currency: "BRL",
                    })}
                  </Typography>
                </Paper>
              </Grid>
            </Grid>

            <Grid item>
              <Tabela
                disableColumnMenu
                autoHeight
                columns={columns}
                rows={lotes}
                pageSize={5}
                initialState={{
                  columns: {
                    columnVisibilityModel: {
                      id: false,
                    },
                  },
                }}
              />
            </Grid>
          </Grid>
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ padding: "16px 24px" }}>
        <Grid container item justifyContent="space-between" spacing={2}>
          <Grid item>
            <Button
              color="gray"
              variant="outlined"
              onClick={() => handleClose(false)}
            >
              Cancelar
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              color="green"
              onClick={() => {
                handleOpenAdicionaModal(true);
                ultimoLote();
              }}
            >
              Adicionar Lote
            </Button>
          </Grid>
        </Grid>
      </DialogActions>

      <ModalConfirmaLote
        open={modalAdicionaLote}
        handleClose={handleCloseAdicionaModal}
        convenio={convenio}
        dataInicial={dataInicial}
        dataFinal={dataFinal}
        valorSoftlab={valorALotear}
        lote={lote}
        getTotalSoftlab={getTotalSoftlab}
      />
    </Dialog>
  );
};

export default ModalAdicionarLote;
