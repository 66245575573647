import { Stack as MuiStack } from "@mui/material";
import React from "react";

export function HStack({ children, ...rest }) {
  return (
    <MuiStack direction="column" spacing={2} {...rest}>
      {children}
    </MuiStack>
  );
}

export function WStack({ children, ...rest }) {
  return (
    <MuiStack direction="row" spacing={2} {...rest}>
      {children}
    </MuiStack>
  );
}
