import React, { useContext, useEffect, useState } from "react";

import { useSnackbar } from "notistack";
import { useHistory, useLocation } from "react-router-dom";
import { 
  Paper,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography
} from '@mui/material';

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import Header from "../../../../components/Header";
import Loading from "../../../../components/Loading";

import Button from "../../../../componentsv2/Button";
import GridContainer from "../../../../componentsv2/GridContainer";
import GridItem from "../../../../componentsv2/GridItem";
import Container from "../../../../componentsv2/Container";
import { Input } from "../../../../componentsv2/Input";

import formatarData from "../../../../utils/formatarData";
import converteData from "../../../../utils/converteData";

import { Context } from "../../../../Context/PermissoesContext";

import { api } from "../../../../services/Api";

export default function VisualizarAgendamento() {
  const { enqueueSnackbar } = useSnackbar();
  const [procedimentoSelecionado, setProcedimentoSelecionado] = useState([]);
  const { checarPermissao } = useContext(Context);
  const [situacao, setSituacao] = useState("");
  const [loading, setLoading] = useState(false);
  const [cliente, setCliente] = useState({
    id: "",
    nome: "",
    rg: "",
    cpf: "",
    mae: "",
    data_nascimento: "",
    endereco: "",
    endereco_numero: "",
    bairro: "",
    cidade: "",
    celular: "",
  });
  const [endereco, setEndereco] = useState("");
  const [cep, setCep] = useState("");
  const [bairro, setBairro] = useState("");
  const [cidade, setCidade] = useState("");
  const [complemento, setComplemento] = useState("");
  const [observacao, setObservacao] = useState("");
  const [data, setData] = useState("");
  const [hora, setHora] = useState("");
  const [numeroEndereco, setNumeroEndereco] = useState("");
  const [estado, setEstado] = useState("");
  const [totalComDesconto, setTotalComDesconto] = useState(0);
  const [total, setTotal] = useState(0);
  const [formaPagamento, setFormaPagamento] = useState("");

  const history = useHistory();
  const location = useLocation();

  const calculaDesconto =(procedimentos,desconto)=>{
    let somatoria =0;
    procedimentos.map(el=>somatoria+=parseFloat(el.precoExame))
    setTotal(somatoria.toFixed(2))
    setTotalComDesconto((somatoria-parseFloat(desconto)).toFixed(2))
  }

  const pegarDadosAgendamento = async () => {
    setLoading(true)
    if (location.state?.row) {
      try {
        const {
          data: { data },
        } = await api.get(`v2/agendaCare/agendamento/${location.state.row.id}`);

        setProcedimentoSelecionado(data.procedimentos);
        setData(formatarData(new Date(data.data), false));
        setHora(data.hora);
        setSituacao(data.situacao);
        setCliente(data.cliente);
        setEndereco(data.endereco);
        setCep(data.cep);
        setBairro(data.bairro);
        setCidade(data.cidade);
        setComplemento(data.complemento);
        setObservacao(data.observacoes);
        setNumeroEndereco(data.enderecoNumero);
        setFormaPagamento(data.formaPagamento)
        setEstado(data.estado)
        calculaDesconto(data.procedimentos,data.desconto)
      } catch (error) {
        enqueueSnackbar("Erro ao tentar encontrar os dados.", {
          variant: "error",
        });
      }
    } else {
      history.push("/agendaCare");
    }
    setLoading(false)
  };

  useEffect(() => {
    pegarDadosAgendamento();
    // eslint-disable-next-line
  }, []);

  return (
    <>
    <Header titulo="Visualizar Agendamentos"/>
    <Container>
      <Button onClick={() => history.push({ pathname: "/agendaCare" })}>
        <ArrowBackIcon style={{ color: "#003399" }} />
      </Button>
      <Paper style={{ padding: "40px", marginTop: "10px" }} elevation={2}>
        <form>
          <GridContainer spacing={2} xs={12}>
            <GridContainer item sm={6} xs={12} spacing={2}>
              <GridItem xs={4}>
                <Input
                  inputProps={{ readOnly: true }}
                  label="Data"
                  type="text"
                  variant="standard"
                  value={data}
                  required
                />
              </GridItem>
              <GridItem xs={4}>
                <Input
                  inputProps={{ readOnly: true }}
                  label="Hora"
                  type="text"
                  variant="standard"
                  value={hora}
                  required
                />
              </GridItem>
              <GridItem xs={4}>
                <Input
                  inputProps={{ readOnly: true }}
                  label="Situação"
                  type="text"
                  variant="standard"
                  value={situacao}
                  required
                />
              </GridItem>

              <GridItem xs={12}>
                <Input
                  inputProps={{ readOnly: true }}
                  multiline
                  maxRows={5}
                  required
                  fullWidth
                  value={cliente.nome}
                  label="Nome"
                  type="text"
                  variant="standard"
                />
              </GridItem>

              <GridItem xs={8}>
                <Input
                  inputProps={{ readOnly: true }}
                  required
                  fullWidth
                  value={endereco}
                  label="Endereço"
                  type="text"
                  variant="standard"
                />
              </GridItem>

              <GridItem xs={4}>
                <Input
                  inputProps={{ readOnly: true }}
                  value={numeroEndereco}
                  required
                  fullWidth
                  label="Numero"
                  type="text"
                  variant="standard"
                />
              </GridItem>

              <GridItem xs={10}>
                <Input
                  inputProps={{ readOnly: true }}
                  value={cep}
                  required
                  fullWidth
                  label="CEP"
                  type="text"
                  variant="standard"
                />
              </GridItem>
              <GridItem xs={2}>
                <Input
                  inputProps={{ readOnly: true }}
                  value={estado}
                  required
                  fullWidth
                  label="Estado"
                  type="text"
                  variant="standard"
                ></Input>
              </GridItem>
              <GridItem xs={6}>
                <Input
                  inputProps={{ readOnly: true }}
                  value={bairro}
                  required
                  fullWidth
                  label="Bairro"
                  type="text"
                  variant="standard"
                ></Input>
              </GridItem>
              <GridItem xs={6}>
                <Input
                  inputProps={{ readOnly: true }}
                  value={cidade}
                  required
                  fullWidth
                  label="Cidade"
                  type="text"
                  variant="standard"
                ></Input>
              </GridItem>
              <GridItem xs={12}>
                <Input
                  inputProps={{ readOnly: true }}
                  value={complemento}
                  fullWidth
                  label="Complemento"
                  type="text"
                  variant="standard"
                ></Input>
              </GridItem>

              <GridItem xs={12}>
                <Input
                  inputProps={{ readOnly: true }}
                  value={observacao}
                  multiline
                  maxRows={5}
                  fullWidth
                  label="Observações"
                  type="text"
                  variant="standard"
                />
              </GridItem>
              <GridItem xs={12} sm={4}>
                <Input
                  inputProps={{ readOnly: true }}
                  value={formaPagamento}
                  label="Forma de pagamento"
                  type="text"
                  variant="standard"
                  fullWidth
                />
              </GridItem>
             {checarPermissao("AGC002") && 
             <>
              <GridItem xs={12} sm={4}>
                  <Input
                    inputProps={{ readOnly: true }}
                    value={total}
                    label="Total"
                    type="number"
                    variant="standard"
                    fullWidth
                  />
                </GridItem>
                <GridItem xs={12} sm={4}>
                  <Input
                    inputProps={{ readOnly: true }}
                    value={totalComDesconto}
                    fullWidth
                    label="Total com desconto"
                    type="number"
                    variant="standard"
                  />
                </GridItem>
              </>}
              <GridItem xs={12}>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography color="#000000">Informaçoes sobre o cliente</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {cliente.rg && (
                      <Typography color="#000000">
                        <strong>RG:</strong> {cliente.rg}{" "}
                      </Typography>
                    )}
                    {cliente.cpf && (
                      <Typography color="#000000">
                        <strong>CPF: </strong> {cliente.cpf}
                      </Typography>
                    )}
                    {cliente.mae && (
                      <Typography color="#000000">
                        <strong>Mãe: </strong> {cliente.mae}
                      </Typography>
                    )}
                    {cliente.data_nascimento && (
                      <Typography color="#000000">
                        <strong> Data Nascimento: </strong> {converteData(cliente.data_nascimento.split("T")[0])}
                      </Typography>
                    )}
                    {cliente.endereco && (
                      <Typography color="#000000">
                        <strong>Endereço: </strong>
                        {cliente.endereco}
                      </Typography>
                    )}
                    {cliente.endereco_numero && (
                      <Typography color="#000000">
                        <strong>Nº Endereço: </strong>
                        {cliente.endereco_numero}
                      </Typography>
                    )}
                    {cliente.bairro && (
                      <Typography color="#000000">
                        <strong>Bairro:</strong> {cliente.bairro}
                      </Typography>
                    )}
                    {cliente.cidade && (
                      <Typography color="#000000">
                        {" "}
                        <strong>Cidade:</strong> {cliente.cidade}
                      </Typography>
                    )}
                     {cliente.estado && (
                      <Typography color="#000000">
                        {" "}
                        <strong>Estado:</strong> {cliente.estado}
                      </Typography>
                    )}
                    {cliente.celular && (
                      <Typography color="#000000">
                        <strong>Celular:</strong> {cliente.celular}
                      </Typography>
                    )}
                  </AccordionDetails>
                </Accordion>
              </GridItem>
            </GridContainer>

            <GridItem sm={6} xs={12}>
              {procedimentoSelecionado &&
                procedimentoSelecionado.map(procedimento => (
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography sx={{ width: "85%", flexShrink: 0 }} color="#000000">
                        {procedimento.nome}
                      </Typography>
                      {
                      checarPermissao("AGC002")&&(<Typography color="#000000">R${procedimento.precoExame}</Typography>)
                      }
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography color="#000000">
                        <strong>Tempo estimado: </strong>
                        {procedimento.tempoEstimado}
                      </Typography>
                      <Typography color="#000000">
                        <strong>Descrição: </strong>
                        {procedimento.descricao}
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                ))}
            </GridItem>
          </GridContainer>
        </form>
      </Paper>
      </Container>
    { loading && <Loading /> }
    </>
  );
}
