import React, { memo } from 'react';
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  Paper,
  Tooltip,
  TableCell,
  TableRow
} from '@mui/material';

import "./styles.css";



const Tabela = memo((props) => {
  const {
    setId,
    setAbreOpcoes,
    vetor
  } = props;
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="center">id</TableCell>
            <TableCell align="center">Nome </TableCell>
            <TableCell align="center">Opções</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {vetor.map((row) => (
            <TableRow key={row.id}>
              <TableCell align="center">{row.id}</TableCell>
              <TableCell align="center">{row.nome}</TableCell>
              <TableCell align="center">
                <Tooltip title="Abrir opções">
                  <button
                    type="button"
                    className="btnOpcao"
                    onClick={() => {
                      setId(row.id);
                      setAbreOpcoes(true);
                    }}
                  >
                    <i className="fa fa-cog" />
                  </button>
                </Tooltip>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
});

export default Tabela;
