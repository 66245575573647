import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Tooltip
} from '@mui/material';
import {
  DeleteForever,
  Error,
  Warning,
  InvertColors
} from '@mui/icons-material';
import { styled } from '@mui/system';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  color: theme.palette.common.black,
  fontWeight: 'bold',
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
}));

const StyledTable = styled(Table)(() => ({
  minWidth: 700,
  marginBottom: 30,
}));

export default function TabelaDialog(props) {
  const {
    header_tittle,
    mostra_historico,
    content,
    amostras_separadas,
    setAmostras_separadas,
    removeUltimaAmostra,
    setAmostraAvulsa,
    setOpcoesAmostraAvulsa
  } = props;

  let id_filtro = '';

  function removePlacaDoVetor(value) {
    if (Number(value.id) !== Number(id_filtro)) {
      return value;
    }
    return null
  }

  function escolheIcone(tipo) {
    if (tipo === 'Urgência') {
      return <Error id="icon-urgency-add-samples" />
    }
    if (tipo === 'Repetição') {
      return <Warning id="icon-repetition-add-samples" />
    }
    return ''
  }

  return (
    <TableContainer component={Paper}>
      <StyledTable>
        <TableHead>
          <TableRow>
            <StyledTableCell align="center">{null}</StyledTableCell>
            <StyledTableCell align="center">{header_tittle[0]}</StyledTableCell>
            <StyledTableCell align="center">{header_tittle[1]}</StyledTableCell>
            <StyledTableCell align="center">{null}</StyledTableCell>
            <StyledTableCell align="center">{header_tittle[2]}</StyledTableCell>
            <StyledTableCell align="center">{null}</StyledTableCell>
            <StyledTableCell align="center">
              { mostra_historico ? 'Opções' : '' }
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {content.map((row, index) => (
            <StyledTableRow key={row.id}>
              <StyledTableCell align="center">
                { mostra_historico
                ? (
                  <input
                    type="checkbox"
                    name="id-amostra"
                    id="check-amostra"
                    onClick={(e) => {
                      if (e.target.checked){
                        setAmostras_separadas(() => [...amostras_separadas, { id: row.id, numero: row.numero}]);
                      } else {
                        id_filtro = row.id;
                        setAmostras_separadas(amostras_separadas.filter(removePlacaDoVetor));
                      }
                    }}
                  />
                  )
                : '' }
              </StyledTableCell>
              <StyledTableCell align="center">{row.numero || row.data_hora}</StyledTableCell>
              <StyledTableCell align="center">{row.pedido || row.usuario}</StyledTableCell>
              <StyledTableCell align="center">
                {escolheIcone(row.tipo)} {(row.material === "Saliva" ? <InvertColors color="primary" /> : null)}
              </StyledTableCell>
              <StyledTableCell align="center">{row.nome_cliente || row.evento}</StyledTableCell>
              <StyledTableCell align="center">
                {(row.numero && index === 0 && !mostra_historico)
                  ? (
                    <Tooltip title="Remover a amostra">
                      <button
                        type="button"
                        id="button-delete-sample"
                        className="button-delete-dialog-add-samples"
                        onClick={() => {
                          removeUltimaAmostra(row.id);
                        }}
                      >
                        <DeleteForever />
                      </button>
                    </Tooltip>
)
                  : null}
              </StyledTableCell>
              <StyledTableCell align="center">
                {mostra_historico
                  ? (
                    <Tooltip title="Abrir opções">
                      <button
                        type="button"
                        className="btnOpcao"
                        onClick={() => {
                          setAmostraAvulsa(row);
                          setOpcoesAmostraAvulsa(true);
                        }}
                      >
                        <i className="fa fa-cog" />
                      </button>
                    </Tooltip>
)
                  : ''}
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </StyledTable>
    </TableContainer>
  );
}
