import { Box, Grid, Paper } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { useEffect } from "react";
import Tabela from "../../../../componentsv2/Tabela";

import { api } from "../../../../services/Api";
import useDadosContextEstoque from "../../hooks/useDadosContextEstoque";
import filtroPesquisa from "../../../../utils/filtroPesquisa";

export function TabelaBaixas() {
  const { enqueueSnackbar } = useSnackbar();
  const [rows, setRows] = useState([]);
  const { setLocalizacaoBaixa, localizacaoBaixa, tipoBaixa, setTipoBaixa } =
    useDadosContextEstoque();
  const [baixasFiltradas, setBaixasFiltradas] = useState([]);

  useEffect(() => {
    const rowsFormatado = rows?.map((row) => ({
      nomeUnidade: row?.produtoEstoque?.estoque?.unidade?.nome,
      idUnidade: row?.produtoEstoque?.estoque?.unidade?.id,
      tipoId:
        row?.tipo === "Entrada"
          ? "1"
          : row?.tipo === "Entrada - Transferencia"
          ? "2"
          : row?.tipo === "Saida"
          ? "3"
          : "4",
      ...row,
    }));

    const rowsPorLocalizacao = filtroPesquisa(
      rowsFormatado,
      "idUnidade",
      localizacaoBaixa
    );

    const rowPorTipoDeBaixa = filtroPesquisa(
      rowsPorLocalizacao,
      "tipoId",
      tipoBaixa
    );

    const sortedRows = rowPorTipoDeBaixa?.sort(
      (a, b) => new Date(b.data) - new Date(a.data)
    );

    setBaixasFiltradas(sortedRows);
  }, [rows, localizacaoBaixa, tipoBaixa]);

  const columns = [
    {
      field: "id",
      headername: "id",
      headerAlign: "center",
      hide: true,
    },
    {
      field: "codigo_produto",
      headerName: "Código Produto",
      headerAlign: "center",
      valueGetter: ({ row }) => row?.produtoEstoque?.produto?.codigo_produto,
    },
    {
      field: "nome",
      headerName: "Produto",
      flex: 1,
      headerAlign: "center",
      align: "center",
      type: "string",
      valueGetter: ({ row }) => row?.produtoEstoque?.produto?.nome,
    },
    {
      field: "produtoEstoque",
      headerName: "Local",
      flex: 1,
      headerAlign: "center",
      align: "center",
      type: "string",
      valueGetter: ({ row }) =>
        row?.produtoEstoque?.estoque?.unidade?.nome || "Matriz",
    },
    {
      field: "estoque",
      headerName: "Estoque",
      flex: 1,
      headerAlign: "center",
      align: "center",
      type: "string",
      valueGetter: ({ row }) => row?.produtoEstoque?.estoque?.nome,
    },
    {
      field: "usuario",
      headerName: "Usuario",
      // width: 170,
      headerAlign: "center",
      align: "center",
      type: "string",
      valueFormatter: ({ value }) => value.username,
    },
    {
      field: "data",
      headerName: "Data",
      flex: 1,
      headerAlign: "center",
      align: "center",
      type: "date",
      valueFormatter: ({ value }) =>
        value &&
        new Date(value).toLocaleDateString("pt-BR", {
          hour: "numeric",
          minute: "numeric",
        }),
    },
    {
      field: "tipo",
      headerName: "Tipo",
      flex: 1,
      headerAlign: "center",
      align: "center",
      type: "string",
    },
    {
      field: "quantidade",
      headerName: "Quantidade",
      // width: 160,
      headerAlign: "center",
      align: "center",
      type: "number",
    },
  ];

  useEffect(() => {
    const obterMovimentacoes = async () => {
      try {
        const { data } = await api.get(
          "/v2/almoxarifado/movimentcaoProdutoEstoque/todas"
        );

        setRows(data.movimentacoes);
      } catch (e) {
        enqueueSnackbar("Erro ao obter as movimetaçõs", { variant: "error" });
      }
    };
    obterMovimentacoes();
  }, [enqueueSnackbar]);

  return (
    <Grid spacing={2} container>
      <Grid item xs={12} sm={12}>
        <Paper elevation={2}>
          <Box
            sx={{
              height: 600,
              width: "100%",
              "& .entrada": { color: "#003399" },
              "& .saida": { color: "#99250F" },
            }}
          >
            <Tabela
              rows={baixasFiltradas}
              columns={columns}
              linhaNaoSelecionavel={false}
              autoPageSize
              getCellClassName={(params) => {
                if (params.field === "quantidade") {
                  return params.value > 0 ? "entrada" : "saida";
                }

                return "";
              }}
              initialState={{
                columns: {
                  columnVisibilityModel: {
                    id: false,
                    codigo_produto: false,
                  },
                },
              }}
            />
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );
}
