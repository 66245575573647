import { Button as MuiButton, Grid, Paper, Divider, Typography } from "@mui/material";
import React, { useState, useEffect, useContext } from "react";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useHistory, useLocation } from "react-router-dom";
import Button from "../../../../../componentsv2/Button";

import PesquisaUsuario from "../../../components/PesquisaUsuario";
import { Input } from "../../../../../componentsv2/Input";
import { Select } from "../../../../../componentsv2/Select";
import DataPickerAtualizado from "../../../../../components/DataPickerAtualizado";
import { ModalForm } from "../../../../../componentsv2/ModalForm";
import { Modal } from "../../../../../componentsv2/Modal";
import SelectUnidade from "../../../components/SelectUnidade";
import { useSnackbar } from "notistack";
import SelectSetor from "../../../components/SelectSetor";
import { api } from "../../../../../services/Api";

import useUser from "../../../../../hooks/useUser";
import { Context as PermissoesContext } from "../../../../../Context/PermissoesContext";
import Header from "../../../../../components/Header";
import Container from "../../../../../componentsv2/Container";

export default function CadastrarBem() {
  const usuario = useUser();
  const location = useLocation();
  const history = useHistory();
  const edicaoBem = location.state;
  const [deleteModal, setDeleteModal] = useState(false);
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);
  const [tombamentoLocador, setTombamentoLocador] = useState("");
  //const fileInputRef = useRef();
  const [nomeProprietario, setNomeProprietario] = useState("");
  const [arrayDeProprietarios, setArrayDeProprietarios] = useState([]);
  const [serial, setSerial] = useState("");
  const [dataAquisicao, setDataAquisicao] = useState(new Date());
  const [estadoConservacao, setEstadoConservacao] = useState("");
  const [observacao, setObservacao] = useState("");
  const [arrayMateriais, setArrayMateriais] = useState([]);
  const [material, setMaterial] = useState("");
  const [unidade, setUnidade] = useState("");
  const [setor, setSetor] = useState("");
  const [rowId, setRowId] = useState("");
  const [btu, setBtu] = useState("");
  const [numeroTelefone, setNumeroTelefone] = useState("");
  const [polegadas, setPolegadas] = useState("");
  const [usuarioResponsavel, setUsuarioResponsavel] = useState(null);
  const [motivoBaixa, setMotivoBaixa] = useState("");
  //const [foto, setFoto] = useState(null);
  // const [existeFotoPrevia, setExisteFotoPrevia] = useState(false);
  // const [fotoRow, setFotoRow] = useState("");
  const estadosConservacao = ["Excelente", "Ótimo", "Bom", "Regular", "Péssimo"];
  const [semAcessoTodasUnidades, setSemAcessoTodasUnidades] = useState(true);
  const [processador, setProcessador] = useState("");
  const [memoria, setMemoria] = useState("");
  const [disco, setDisco] = useState("");
  const [observacaoBaixa, setObservacaoBaixa] = useState("");
  const { checarPermissao } = useContext(PermissoesContext);
  const { enqueueSnackbar } = useSnackbar();

  const [codTombador, setCodTombador] = useState("");

  async function checaUnidadeFuncionario() {
    try {
      const { data } = await api.get(`v2/controleAcesso/funcionarioUnidade`, {
        params: { cod_usu: usuario },
      });

      if (checarPermissao("INV030")) {
        setSemAcessoTodasUnidades(false);
      }

      setUnidade(data.funcionario.unidade_id);
      if (!data.funcionario.unidade_id) {
        throw new Error("Sem Unidade!");
      }
    } catch (error) {
      history.push("/home");
      enqueueSnackbar(
        "Erro ao encontrar unidade de origem, solicite o cadastramento da unidade do seu usuário!",
        {
          variant: "error",
        }
      );
    }
  }

  useEffect(() => {
    async function puxarDadosBem() {
      if (edicaoBem) {
        try {
          const { data } = await api.get(`/v2/inventario/bem/${edicaoBem.dados.id}`);

          setMotivoBaixa(data.data.motivoBaixa);
          setObservacaoBaixa(data.data.observacaoBaixa);
          setBtu(data.data.btu);
          setProcessador(data.data.processador);
          setMemoria(data.data.memoria);
          setDisco(data.data.disco);
          setNumeroTelefone(data.data.numeroTelefone);
          setPolegadas(data.data.polegadas);
          setUsuarioResponsavel(data.data.usuarioResponsavel);
          setNomeProprietario(data.data.proprietario.id);
          setDataAquisicao(data.data.dataAquisicao);
          setTombamentoLocador(data.data.codTombadorLocador);

          setCodTombador(data.data.codTombador);

          setSerial(data.data.serial);
          setEstadoConservacao(data.data.estadoConservacao);
          setObservacao(data.data.observacao);
          setUnidade(data.data.unidade.id);
          setSetor(data.data.setor.id);
          setMaterial(data.data.material.id);
          // if (data.data.foto) {
          //   setFotoRow(data.data.foto);

          //   setExisteFotoPrevia(true);
          // }

          setRowId(edicaoBem.dados.id);
        } catch (error) {
          enqueueSnackbar("Erro! Não foi possível editar o bem.", {
            variant: "error",
          });
        }
      }
    }
    puxarDadosBem();
  }, [enqueueSnackbar, edicaoBem, semAcessoTodasUnidades]);

  const arrayDeMotivos = [
    "Vendido",
    "Extraviado",
    "Devolvido",
    "Expurgado",
    "Danificado",
    "Obsoleto",
    "Outros",
  ];
  async function getMateriais() {
    try {
      const { data } = await api.get(`/v2/inventario/selectMateriais`);

      setArrayMateriais(data.data);
    } catch (error) {
      enqueueSnackbar("Erro! Não foi possível buscar os materiais.", {
        variant: "error",
      });
    }
  }

  async function getProprietarios() {
    try {
      const { data } = await api.get(`/v2/inventario/proprietarios`);
      setArrayDeProprietarios(data.data);
    } catch (error) {
      enqueueSnackbar("Erro! Não foi possível buscar os proprietários.", {
        variant: "error",
      });
    }
  }

  useEffect(() => {
    getProprietarios();
    getMateriais();
    checaUnidadeFuncionario();
    // eslint-disable-next-line
  }, [enqueueSnackbar, checarPermissao]);

  const handleSubmit = async e => {
    e.preventDefault();
    try {
      const data = {
        proprietarioId: nomeProprietario,
        dataAquisicao: new Date(dataAquisicao).toISOString(),
        codTombador,
        codTombadorLocador: tombamentoLocador,
        serial,
        estadoConservacao,
        situacao: "Ativo",
        observacao,
        unidadeId: unidade,
        setorId: setor,
        materialId: material,
        btu,
        numeroTelefone,
        polegadas,
        usuarioResponsavelId: usuarioResponsavel?.id,
        processador,
        memoria,
        disco,
      };

      if (edicaoBem) {
        if (checarPermissao("INV020")) {
          await api.put(`/v2/inventario/bem/${rowId}`, data);

          enqueueSnackbar("O bem foi editado com sucesso!", {
            variant: "success",
            autoHideDuration: 3000,
          });
        } else {
          enqueueSnackbar("Você não possui permissão para editar um bem!", {
            variant: "error",
            autoHideDuration: 3000,
          });
        }
      } else {
        if (checarPermissao("INV019")) {
          await api.post(`/v2/inventario/bem`, data);

          enqueueSnackbar("O bem foi cadastrado com sucesso!", {
            variant: "success",
            autoHideDuration: 3000,
          });
        } else {
          enqueueSnackbar("Você não possui permissão para cadastrar um bem!", {
            variant: "error",
            autoHideDuration: 3000,
          });
        }
      }

      history.push({ pathname: "/inventario", state: { dados: 4 } });
    } catch (error) {
      enqueueSnackbar("Erro ao tentar criar um bem.", {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  const darBaixa = async e => {
    e.preventDefault();
    try {
      const data = {
        motivoBaixa,
        observacaoBaixa,
      };

      if (motivoBaixa === "Outros" && !observacaoBaixa) {
        enqueueSnackbar(
          "Erro! Observação da baixa necessária para o motivo selecionado.",
          {
            variant: "error",
          }
        );
        return;
      }
      await api.put(`/v2/inventario/darBaixaBem/${rowId}`, data);
      enqueueSnackbar("Baixa cadastrada com sucesso.", {
        variant: "success",
      });

      setConfirmDeleteModal(false);
      setDeleteModal(false);
    } catch (error) {
      enqueueSnackbar("Erro! Não foi possível dar baixa no bem.", {
        variant: "error",
      });
    }
  };

  return (
    <>
    <Header titulo="Cadastrar Bem" />
    <Container>
      <MuiButton onClick={() => {}}>
        <ArrowBackIcon
          style={{ color: "#003399" }}
          onClick={() => history.push({ pathname: "/inventario", state: { dados: 4 } })}
        />
      </MuiButton>
      <Paper style={{ padding: "40px", marginTop: "10px" }} elevation={2}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <SelectUnidade
                required={true}
                disabled={semAcessoTodasUnidades}
                value={unidade}
                onChange={e => setUnidade(e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <Input
                onChange={e => setCodTombador(e.target.value)}
                value={codTombador}
                fullWidth
                label="Código Tombador"
                type="number"
                variant="standard"
              />
            </Grid>
            <Grid item xs={6}>
              <SelectSetor
                required={true}
                value={setor}
                onChange={e => setSetor(e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <Input
                onChange={e => setTombamentoLocador(e.target.value)}
                value={tombamentoLocador}
                required
                fullWidth
                label="Tombamento Locador"
                type="text"
                variant="standard"
              />
            </Grid>
            <Grid xs={6} item>
              <PesquisaUsuario
                value={usuarioResponsavel}
                onChange={setUsuarioResponsavel}
              />
            </Grid>
            <Grid xs={6} item>
              <Select
                onChange={e => setNomeProprietario(e.target.value)}
                value={nomeProprietario}
                textAttribute="nome"
                valueAttribute="id"
                required
                variant="standard"
                label="Nome Proprietário"
                arrayOptions={arrayDeProprietarios}
              />
            </Grid>
            <Grid xs={6} item>
              <Input
                onChange={e => setSerial(e.target.value)}
                value={serial}
                required
                fullWidth
                label="Serial"
                type="text"
                variant="standard"
              />
            </Grid>
            <Grid xs={6} item>
              <DataPickerAtualizado
                setData={setDataAquisicao}
                data={dataAquisicao}
                variant="standard"
                labelName="Data de Aquisição"
              />
            </Grid>
            <Grid xs={6} item>
              <Select
                onChange={e => setEstadoConservacao(e.target.value)}
                value={estadoConservacao}
                required
                variant="standard"
                arrayOptions={estadosConservacao}
                label="Estado de Conservação"
              />
            </Grid>
            <Grid xs={6} item>
              <Input
                onChange={e => setObservacao(e.target.value)}
                value={observacao}
                multiline
                maxRows={5}
                fullWidth
                label="Observação"
                type="text"
                variant="standard"
              />
            </Grid>{" "}
            <Grid xs={6} item>
              <Select
                onChange={e => setMaterial(e.target.value)}
                value={material}
                required
                variant="standard"
                textAttribute="modeloMarca"
                valueAttribute="id"
                arrayOptions={arrayMateriais}
                label="Materiais"
              />
            </Grid>
            <Grid xs={6} item>
              <Input
                value={btu}
                onChange={e => setBtu(e.target.value)}
                fullWidth
                label="BTUs"
                type="text"
                variant="standard"
              />
            </Grid>
            <Grid xs={6} item>
              <Input
                value={numeroTelefone}
                onChange={e => setNumeroTelefone(e.target.value)}
                fullWidth
                label="Telefone"
                type="text"
                variant="standard"
              />
            </Grid>
            <Grid xs={6} item>
              <Input
                value={polegadas}
                onChange={e => setPolegadas(e.target.value)}
                multiline
                maxRows={5}
                fullWidth
                label="Polegadas"
                type="text"
                variant="standard"
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1">Computador</Typography>
              <Divider sx={{ width: "100%" }} />
            </Grid>
            <Grid xs={6} item>
              <Input
                value={processador}
                onChange={e => setProcessador(e.target.value)}
                fullWidth
                label="Processador"
                type="text"
                variant="standard"
              />
            </Grid>
            <Grid xs={6} item>
              <Input
                value={memoria}
                onChange={e => setMemoria(e.target.value)}
                fullWidth
                label="Memória RAM"
                type="text"
                variant="standard"
              />
            </Grid>
            <Grid xs={6} item>
              <Input
                value={disco}
                onChange={e => setDisco(e.target.value)}
                fullWidth
                label="Disco (HD ou SSD)"
                type="text"
                variant="standard"
              />
            </Grid>
            {/* <Grid xs={6} item>
              <div className="anexos-dialog">
                {fotoRow ? (
                  <div className="anexo" key={fotoRow}>
                    <span
                      className="filename"
                      onClick={() => onClickAnexo(fotoRow, foto)}
                    >
                      {fotoRow.split(":").length > 1 ? fotoRow.split(":")[1] : fotoRow}
                    </span>
                    <CloseIcon
                      style={{ cursor: "pointer", marginLeft: "1rem" }}
                      onClick={() => removerAnexo()}
                    />
                  </div>
                ) : null}

                <input
                  accept="*"
                  ref={fileInputRef}
                  onChange={event => {
                    event.preventDefault();
                    const file = event.target.files[0];

                    if (file) {
                      setFoto(file);
                      setFotoRow(file.name);
                    } else {
                      setFoto(null);
                    }
                  }}
                  className="input-image"
                  id="image-upload"
                  multiple
                  type="file"
                  name="avatar_url"
                />
                {fotoRow ? null : (
                  <div
                    className="dialog-add-button"
                    onClick={event => {
                      event.preventDefault();
                      fileInputRef.current.click();
                    }}
                  >
                    <AddIcon fontSize="large" />
                    <span>Adicionar foto(s)</span>
                  </div>
                )}
              </div>
            </Grid> */}
            <Grid
              item
              sx={{ marginTop: "30px" }}
              container
              justifyContent="space-between"
              spacing={0}
            >
              {edicaoBem ? (
                <Grid item>
                  <Button
                    onClick={() => setDeleteModal(true)}
                    color="red"
                    variant="outlined"
                  >
                    Dar baixa
                  </Button>
                </Grid>
              ) : (
                <Grid item>
                  <Button
                    onClick={() =>
                      history.push({ pathname: "/inventario", state: { dados: 4 } })
                    }
                    color="red"
                    variant="outlined"
                  >
                    Cancelar
                  </Button>
                </Grid>
              )}
              <Grid item>
                <Button type="submit" color="green" variant="contained">
                  Salvar
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
        <ModalForm
          title="Dar baixa no bem"
          onClose={setDeleteModal}
          open={deleteModal}
          onSubmit={() => setConfirmDeleteModal(true)}
        >
          <Grid xs={6} item>
            <Select
              value={motivoBaixa}
              onChange={e => setMotivoBaixa(e.target.value)}
              label="Motivo da baixa"
              required
              variant="standard"
              arrayOptions={arrayDeMotivos}
            />
          </Grid>

          <Grid xs={6} item>
            <Input
              onChange={e => setObservacaoBaixa(e.target.value)}
              value={observacaoBaixa}
              multiline
              maxRows={5}
              fullWidth
              label="Observação da baixa"
              type="text"
              variant="standard"
            />
          </Grid>
        </ModalForm>
        <Modal
          onClick={darBaixa}
          open={confirmDeleteModal}
          modalText="Tem certeza que deseja dar baixa neste bem?"
          onClose={setConfirmDeleteModal}
          confirmActionButtonColor="green"
          confirmActionButtonText="Confirmar"
        />
      </Paper>
      </Container>
      </>
  );
}
