import React, { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { Autocomplete, TextField } from "@mui/material";

import { api } from "../../../../services/Api";

export default function PesquisaUsuario({
  label,
  value,
  onChange,
  required = false,
  ...rest
}) {
  const [usuarios, setUsuarios] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    async function recebeUsuarios() {
      try {
        const { data } = await api.get(`/v2/controleAcesso/todosUsuarios`);

        setUsuarios(data.usuarios);
      } catch (error) {
        enqueueSnackbar("Erro! Não foi possível buscar os usuários.", {
          variant: "error",
        });
      }
    }
    recebeUsuarios();
  }, [enqueueSnackbar]);

  return (
    <>
      <Autocomplete
        {...rest}
        value={value}
        onChange={(event, value) => onChange(value)}
        disablePortal
        id="combo-box-demo"
        options={usuarios}
        getOptionLabel={option => option.username}
        fullWidth
        renderInput={params => (
          <TextField
            required={required}
            variant="standard"
            {...params}
            label={label || "Usuário Responsável"}
          />
        )}
      />
    </>
  );
}
