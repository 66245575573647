import React, { useCallback, useEffect, useState } from "react";

import { useHistory } from "react-router-dom";
import { Container } from '@mui/material';
import { format } from "date-fns";
import { useSnackbar } from "notistack";

import Header from "../../components/Header";
import useUser from "../../hooks/useUser";

import Tabela from "./Tabela";
import Formulario from "./Formulario";

import "./styles.css";

import { api } from "../../services/Api";

export default function DriveThru() {
  const history = useHistory();
  const usuario = useUser();

  const { enqueueSnackbar } = useSnackbar();

  const [novaData, setNovaData] = useState(format(new Date(), "yyyy-MM-dd"));

  const [agendamentos, setAgendamentos] = useState([]);
  const [cliente, setCliente] = useState("");
  const [abre_visualiza_dados, setAbre_visualiza_dados] = useState(false);

  const [exibe_ag_coleta, setExibe_ag_coleta] = useState(true);
  const [exibe_ag_faturamento, setExibe_ag_faturamento] = useState(true);
  const [exibe_ag_autorizacao, setExibe_ag_autorizacao] = useState(true);
  const [exibe_cancelados, setExibe_cancelados] = useState(true);
  const [exibe_desistencias, setExibe_desistencias] = useState(false);
  const [exibe_coletados, setExibe_coletados] = useState(false);

  const capturaAgendamentos = useCallback(async () => {
    try {
      const result = await api.get(
        `/api/driveThru/${novaData}/?exibe_ag_coleta=${exibe_ag_coleta}&exibe_ag_autorizacao=${exibe_ag_autorizacao}&exibe_ag_faturamento=${exibe_ag_faturamento}&exibe_coletados=${exibe_coletados}&exibe_cancelados=${exibe_cancelados}&exibe_desistencias=${exibe_desistencias}`
      );
      setAgendamentos(result.data);
    } catch (error) {
      enqueueSnackbar("Falha na operação", { variant: "error" });
    }
  }, [
    novaData,
    exibe_ag_coleta,
    exibe_ag_autorizacao,
    exibe_ag_faturamento,
    exibe_coletados,
    exibe_cancelados,
    exibe_desistencias,
    enqueueSnackbar,
  ]);

  useEffect(() => {
    capturaAgendamentos();
  }, [capturaAgendamentos]);

  useEffect(() => {
    const intervalo = setInterval(() => {
      capturaAgendamentos();
    }, 10000);

    return () => clearInterval(intervalo);
  }, [capturaAgendamentos]);

  return (
    <div>
      <Header
        titulo="Controle Drive-Thru"
        usuario={usuario}
        history={history}
      />
      <Container maxWidth="md">
        <Formulario
          usuario={usuario}
          exibe_ag_coleta={exibe_ag_coleta}
          exibe_ag_faturamento={exibe_ag_faturamento}
          exibe_ag_autorizacao={exibe_ag_autorizacao}
          exibe_coletados={exibe_coletados}
          exibe_cancelados={exibe_cancelados}
          exibe_desistencias={exibe_desistencias}
          setAgendamentos={setAgendamentos}
          setNovaData={setNovaData}
          novaData={novaData}
          setCliente={setCliente}
          cliente={cliente}
          setAbre_visualiza_dados={setAbre_visualiza_dados}
          abre_visualiza_dados={abre_visualiza_dados}
        />

        <div id="container-filter-check-drive">
          <span>Exibir atendimentos:</span>

          <div id="container-check-drive">
            <div className="content-check-drive">
              <label htmlFor="exibe_ag_autorizacao" className="content-label-drive">
                <input
                  id="exibe_ag_autorizacao"
                  className="myCheck-drive-table"
                  type="checkbox"
                  checked={exibe_ag_autorizacao}
                  onClick={() => setExibe_ag_autorizacao(prevState => !prevState)}
                />
                <span>Aguardando autorizacao</span>
              </label>
            </div>

            <div className="content-check-drive">
              <label htmlFor="exibe_ag_faturamento" className="content-label-drive">
                <input
                  id="exibe_ag_faturamento"
                  className="myCheck-drive-table"
                  type="checkbox"
                  checked={exibe_ag_faturamento}
                  onClick={() => setExibe_ag_faturamento(prevState => !prevState)}
                />
                <span> Aguardando faturamento</span>
              </label>

            </div>

            <div className="content-check-drive">
              <label htmlFor="exibe_ag_coleta" className="content-label-drive">
                <input
                  id="exibe_ag_coleta"
                  className="myCheck-drive-table"
                  type="checkbox"
                  checked={exibe_ag_coleta}
                  onClick={() => setExibe_ag_coleta(prevState => !prevState)}
                />
                <span>Aguardando coleta</span>
              </label>
            </div>

            <div className="content-check-drive">
              <label htmlFor="exibe_cancelados" className="content-label-drive">
                <input
                  id="exibe_cancelados"
                  className="myCheck-drive-table"
                  type="checkbox"
                  checked={exibe_cancelados}
                  onClick={() => setExibe_cancelados(prevState => !prevState)}
                />
                <span>Cancelados</span>
              </label>
            </div>

            <div className="content-check-drive">
              <label htmlFor="exibe_coletados" className="content-label-drive">
                <input
                  id="exibe_coletados"
                  className="myCheck-drive-table"
                  type="checkbox"
                  checked={exibe_coletados}
                  onClick={() => setExibe_coletados(prevState => !prevState)}
                />
                <span>Coletados</span>
              </label>
            </div>

            <div className="content-check-drive">
              <label htmlFor="exibe_desistencias" className="content-label-drive">
                <input
                  id="exibe_desistencias"
                  className="myCheck-drive-table"
                  type="checkbox"
                  checked={exibe_desistencias}
                  onClick={() => setExibe_desistencias(prevState => !prevState)}
                />
                <span>Desistências</span>
              </label>
            </div>
          </div>
        </div>

        <Tabela
          agendamentos={agendamentos}
          setAgendamentos={setAgendamentos}
          setCliente={setCliente}
          setAbre_visualiza_dados={setAbre_visualiza_dados}
        />
      </Container>
    </div>
  );
}
