import React, { useContext, useEffect, useState } from "react";

import { useHistory } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Button from "../../../../componentsv2/Button";
import Paper from "../../../../componentsv2/Paper";
import { useSnackbar } from "notistack";

import DataPickerAtualizado from "../../../../components/DataPickerAtualizado";
import GridContainer from "../../../../componentsv2/GridContainer";
import GridItem from "../../../../componentsv2/GridItem";
import { Input } from "../../../../componentsv2/Input";
import SelectEnfermeiro from "../../Components/SelectEnfermeiro";
import TabelaProcedimentos from "./Components/TabelaProcedimentos";
import SelectSituacao from "../../Components/SelectSituacao";
import SelectHorariosDisponiveis from "../../Components/SelectHorariosDisponiveis";
import AutoCompleteClientes from "../../../../componentsv2/AutoCompleteClientes";
import { api } from "../../../../services/Api";
import ModalProcedimento from "../ModalProcedimento";
import useSelectClientes from "../../data/hooks/useSelectClientes";
import SelectEstado from "../../../../componentsv2/SelectEstado";
import SelectFormaPagamento from "../../Components/SelectFormaPagamento";
import { Context } from "../../../../Context/PermissoesContext";
import Header from "../../../../components/Header";
import Container from "../../../../componentsv2/Container";
import AutoCompleteMedicos from "../../../../componentsv2/AutoCompleteMedicos/Index";

export default function FormularioAgendamento() {
  const { enqueueSnackbar } = useSnackbar();
  const [openModalForm, setOpenModalForm] = useState(false);
  const history = useHistory();
  const { checarPermissao } = useContext(Context);
  const [procedimentoSelecionado, setProcedimentoSelecionado] = useState([]);

  const { cliente, setCliente, medico, setMedico } = useSelectClientes();
  const [enfermeiroId, setEnfermeiroId] = useState("");
  const [data, setData] = useState(new Date());
  const [hora, setHora] = useState("");
  const [situacao, setSituacao] = useState("");
  const [endereco, setEndereco] = useState("");
  const [numeroEndereco, setNumeroEndereco] = useState("");
  const [cep, setCep] = useState("");
  const [bairro, setBairro] = useState("");
  const [cidade, setCidade] = useState("");
  const [complemento, setComplemento] = useState("");
  const [observacao, setObservacao] = useState("");
  const [estado, setEstado] = useState("");
  const [totalExames, setTotalExames] = useState(0);
  const [desconto, setDesconto] = useState(0);
  const [formaPagamento, setFormaPagamento] = useState("");

  const [kilometragem, setKilometragem] = useState("");

  const [atualizaTabelaProcedimentos, setAtualizaTabelaProcedimentos] =
    useState(false);

  // const buttonRef = useRef(null);

  const pegarKilometragem = async () => {
    try {
      const enderecosFormatado = await api.get(
        "/v2/agendaCare/pesquisaEndereco",
        {
          params: {
            endereco: endereco,
          },
        }
      );

      const { data } = await api.post("/v2/agendaCare/distancia", {
        destino: [enderecosFormatado.data[0].formatted_address],
      });

      setKilometragem(data[0].distance.text);
    } catch (error) {
      enqueueSnackbar("Erro ao pesquisar endereço!", {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  const atualizarTabelaProcedimentos = () => {
    setAtualizaTabelaProcedimentos(!atualizaTabelaProcedimentos);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const refButton = document.getElementById("agendarButton");
    refButton.disabled = true;
    try {
      const procedimentosId = procedimentoSelecionado.map(
        (procedimento) => procedimento.id
      );
      // Quando o datePicker é utilizado, muda state data para um objeto com multiplas props
      if (data.hasOwnProperty("$d")) {
        setData(data.$d.setHours(0, 0, 0));
      } else {
        setData(data.setHours(0, 0, 0));
      }

      // console.log("dataSetada", dataSetada);
      const dados = {
        procedimentosId,
        enfermeiroId,
        data: data,
        hora,
        complemento,
        observacoes: observacao,
        situacao,
        estado,
        cep,
        cidade,
        bairro,
        enderecoNumero: numeroEndereco,
        endereco,
        clienteId: cliente.id,
        desconto: desconto ? desconto : 0,
        formaPagamento,
        distancia: kilometragem,
        medicoId: medico.id,
      };
      await api.post("/v2/agendaCare/agendamento", dados);
      history.push("/agendaCare");
      enqueueSnackbar("Agendamento feito com sucesso!", {
        variant: "success",
      });
    } catch (error) {
      console.error("Erro no agendamento", error);
      enqueueSnackbar("Erro ao tentar agendar!", { variant: "error" });
    }
  };

  const pegarDadosCliente = async () => {
    if (cliente?.id) {
      try {
        const {
          data: { data },
        } = await api.get(`v2/controleDna/cliente/${cliente.id}`);
        setBairro(data.bairro);
        setEndereco(data.endereco);
        setNumeroEndereco(data.enderecoNumero);
        setCep(data.cep);
        setCidade(data.cidade);
        setEstado(data.estado);
      } catch (error) {
        enqueueSnackbar("Erro ao tentar pegar dados do cliente!", {
          variant: "error",
        });
      }
    }
  };

  useEffect(() => {
    pegarDadosCliente();
    // eslint-disable-next-line
  }, [cliente]);

  useEffect(() => {
    if (procedimentoSelecionado.length > 0) {
      const arrayDePrecos = procedimentoSelecionado?.map(
        (procedimento) => procedimento.precoExame
      );
      let result = arrayDePrecos?.reduce((a, b) => {
        return a + b;
      });
      result = result - desconto;
      const resultado = result.toLocaleString("pt-br", {
        style: "currency",
        currency: "BRL",
      });
      setTotalExames(resultado);
    }
  }, [procedimentoSelecionado, desconto]);

  useEffect(() => {
    if (procedimentoSelecionado.length === 0) {
      setTotalExames(0);
    }
  }, [procedimentoSelecionado]);

  useEffect(() => {
    pegarKilometragem();
    // eslint-disable-next-line
  }, [endereco]);

  return (
    <>
      <Header titulo={"Formulário de Agendamento"} />
      <Container>
        <Button onClick={() => history.push({ pathname: "/agendaCare" })}>
          <ArrowBackIcon style={{ color: "#003399" }} />
        </Button>
        <Paper
          style={{
            padding: "1rem",
            alignItems: "center",
            display: "flex",
            justfyContent: "center",
            marginTop: "10px",
          }}
          elevation={2}
        >
          {openModalForm && (
            <ModalProcedimento
              open={openModalForm}
              onClose={() => {
                setOpenModalForm(false);
                atualizarTabelaProcedimentos();
              }}
            />
          )}
          <form onSubmit={handleSubmit}>
            <GridContainer sx={{ margin: "1px" }} spacing={2} xs={12}>
              <GridContainer item sm={6} xs={12} spacing={2}>
                <GridItem xs={12}>
                  <AutoCompleteClientes
                    value={cliente}
                    onChange={setCliente}
                    required
                  />
                </GridItem>
                <GridItem xs={12}>
                  <AutoCompleteMedicos
                    onChange={setMedico}
                    value={medico}
                    required
                  />
                </GridItem>

                <GridItem xs={6}>
                  <DataPickerAtualizado
                    required
                    variant="standard"
                    labelName="Data"
                    xs={3}
                    data={data}
                    setData={setData}
                  />
                </GridItem>
                <GridItem xs={6}>
                  <SelectSituacao
                    onChange={(e) => {
                      setSituacao(e.target.value);
                    }}
                    value={situacao}
                    todas={false}
                    required
                  />
                </GridItem>

                <GridItem xs={6}>
                  <SelectEnfermeiro
                    onChange={(e) => {
                      setEnfermeiroId(e.target.value);
                      setHora("");
                    }}
                    value={enfermeiroId}
                    required
                  />
                </GridItem>

                <GridItem xs={6}>
                  <SelectHorariosDisponiveis
                    onChange={(e) => {
                      setHora(e.target.value);
                    }}
                    data={data}
                    value={hora}
                    enfermeiroId={enfermeiroId}
                    required
                  />
                </GridItem>

                <GridItem xs={8}>
                  <Input
                    required
                    fullWidth
                    onChange={(e) => {
                      setEndereco(e.target.value);
                    }}
                    value={endereco}
                    label="Endereço"
                    type="text"
                    variant="standard"
                  />
                </GridItem>
                <GridItem xs={4}>
                  <Input
                    onChange={(e) => {
                      setNumeroEndereco(e.target.value);
                    }}
                    value={numeroEndereco}
                    required
                    fullWidth
                    label="Número"
                    type="text"
                    variant="standard"
                  ></Input>
                </GridItem>

                <GridItem xs={12}>
                  <Input
                    required
                    fullWidth
                    onChange={(e) => {
                      setCep(e.target.value);
                    }}
                    value={cep}
                    label="CEP"
                    type="text"
                    variant="standard"
                  />
                </GridItem>
                <GridItem xs={4}>
                  <Input
                    onChange={(e) => {
                      setBairro(e.target.value);
                    }}
                    value={bairro}
                    required
                    fullWidth
                    label="Bairro"
                    type="text"
                    variant="standard"
                  ></Input>
                </GridItem>

                <GridItem xs={4}>
                  <Input
                    onChange={(e) => {
                      setCidade(e.target.value);
                    }}
                    value={cidade}
                    required
                    fullWidth
                    label="Cidade"
                    type="text"
                    variant="standard"
                  ></Input>
                </GridItem>

                <GridItem xs={4}>
                  <SelectEstado
                    onChange={(e) => {
                      setEstado(e.target.value);
                    }}
                    value={estado}
                  />
                </GridItem>

                <GridItem xs={12}>
                  <Input
                    onChange={(e) => {
                      setComplemento(e.target.value);
                    }}
                    value={complemento}
                    fullWidth
                    label="Complemento"
                    type="text"
                    variant="standard"
                  ></Input>
                </GridItem>

                <GridItem xs={12}>
                  <Input
                    onChange={(e) => {
                      setObservacao(e.target.value);
                    }}
                    value={observacao}
                    multiline
                    maxRows={5}
                    fullWidth
                    label="Observações"
                    type="text"
                    variant="standard"
                  />
                </GridItem>
              </GridContainer>

              <GridItem xs={12} sm={6}>
                <GridItem xs={12}>
                  <TabelaProcedimentos
                    atualizaTabela={atualizaTabelaProcedimentos}
                    setSelectedRows={setProcedimentoSelecionado}
                  />
                </GridItem>

                <GridContainer
                  spacing={1}
                  xs={12}
                  justifyContent="space-between"
                  sx={{ marginTop: "5px" }}
                >
                  <GridItem sm={4} xs={12}>
                    <SelectFormaPagamento
                      onChange={(e) => {
                        setFormaPagamento(e.target.value);
                      }}
                      value={formaPagamento}
                      required
                    />
                  </GridItem>
                  {checarPermissao("AGC002") && (
                    <>
                      <GridItem sm={4} xs={12}>
                        <Input
                          onChange={(e) => {
                            setDesconto(e.target.value);
                          }}
                          value={desconto}
                          fullWidth
                          label="Desconto R$:"
                          type="number"
                          variant="standard"
                        />
                      </GridItem>

                      <GridItem sm={4} xs={12}>
                        <Input
                          value={totalExames}
                          fullWidth
                          label="Total"
                          type="text"
                          inputProps={{ readOnly: true }}
                          variant="standard"
                        />
                      </GridItem>

                      <GridItem sm={4} xs={12}>
                        <Input
                          value={kilometragem}
                          fullWidth
                          label="Kilometragem"
                          type="text"
                          inputProps={{ readOnly: true }}
                          variant="standard"
                        />
                      </GridItem>
                    </>
                  )}
                </GridContainer>
              </GridItem>
            </GridContainer>
            <GridContainer
              item
              sx={{ marginTop: "30px" }}
              justifyContent="flex-end"
              spacing={2}
            >
              <GridItem>
                <Button
                  onClick={() => history.push({ pathname: "/agendaCare" })}
                  color="red"
                  variant="outlined"
                >
                  Cancelar
                </Button>
              </GridItem>
              <GridItem>
                <Button
                  id="agendarButton"
                  type="submit"
                  color="green"
                  variant="contained"
                >
                  Agendar
                </Button>
              </GridItem>
            </GridContainer>
          </form>
        </Paper>
      </Container>
    </>
  );
}
