import { useSnackbar } from "notistack";
import { useContext } from "react";

import { Context as PermissoesContext } from "../Context/PermissoesContext";

export default function usePermissao(permissao) {
  const { enqueueSnackbar } = useSnackbar();
  const { checarPermissao } = useContext(PermissoesContext);

  const temPermissao = checarPermissao(permissao);

  if (!temPermissao) {
    enqueueSnackbar(`Para fazer a ação é necessário possuir a permissão ${permissao}`, {
      variant: "error",
    });
  }
  return temPermissao;
}
