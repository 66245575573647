import React, { useEffect, useState, useContext } from "react";
import { Grid, Paper, IconButton, Box } from "@mui/material";
import Tabela from "../../../../componentsv2/Tabela";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { Input } from "../../../../componentsv2/Input";
import { ModalForm } from "../../../../componentsv2/ModalForm";
import Opcoes from "./opcoes";
import { Select } from "../../../../componentsv2/Select";
import { useSnackbar } from "notistack";
import { api } from "../../../../services/Api";
import { Context as PermissoesContext } from "../../../../Context/PermissoesContext";

export default function Material() {
  const [materialModal, setMaterialModal] = useState(false);
  const [arrayMateriais, setArrayMateriais] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [arrayDeTipos, setArrayDeTipos] = useState([]);
  const [marca, setMarca] = useState("");
  const [modelo, setModelo] = useState("");
  const [descricao, setDescricao] = useState("");
  const [tipoMaterial, setTipoMaterial] = useState("");
  const [id, setId] = useState("");
  const { checarPermissao, checarPermissaoSemLog } = useContext(PermissoesContext);

  const [update, setUpdate] = useState(false);

  async function getTipoDeMaterialEdit() {
    try {
      const {
        data: { data },
      } = await api.get(`/v2/inventario/material/${id}`);
      setMarca(data.marca);
      setModelo(data.modelo);
      setDescricao(data.descricao);
      setTipoMaterial(data.tipoMaterialId);
    } catch (error) {
      enqueueSnackbar("Erro! Não foi possível buscar o material.", {
        variant: "error",
      });
    }
  }

  useEffect(() => {
    if (id) {
      getTipoDeMaterialEdit();
    }
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    async function getMateriais() {
      try {
        const { data } = await api.get(`/v2/inventario/materiais`);

        const materiais = data.data.map(element => ({
          ...element,
          nomeTipoMaterial: element.tipoMaterial.nome,
        }));

        setArrayMateriais(materiais);
      } catch (error) {
        enqueueSnackbar("Erro! Não foi possível buscar os materiais.", {
          variant: "error",
        });
      }
    }
    getMateriais();
  }, [update, checarPermissaoSemLog, enqueueSnackbar]);

  useEffect(() => {
    async function getTiposDeMateriais() {
      try {
        const {
          data: { data },
        } = await api.get(`/v2/inventario/tiposMateriais`);

        function ativos(value) {
          return value.ativo === true;
        }

        const tiposAtivos = data.filter(ativos);

        setArrayDeTipos(tiposAtivos);
      } catch (error) {
        enqueueSnackbar("Erro! Não foi possível buscar os tipos de materiais.", {
          variant: "error",
        });
      }
    }
    getTiposDeMateriais();
  }, [enqueueSnackbar, tipoMaterial, update]);

  const clearFields = () => {
    setMarca("");
    setModelo("");
    setDescricao("");
    setTipoMaterial("");
  };

  const handleSubmit = async e => {
    e.preventDefault();
    try {
      const data = {
        marca,
        modelo,
        descricao,
        tipoMaterialId: tipoMaterial,
      };

      clearFields();
      setMaterialModal(false);

      if (id) {
        await api.put(`/v2/inventario/material/${id}`, data);
        setUpdate(!update);
        enqueueSnackbar("Material editado com sucesso!", {
          variant: "success",
          autoHideDuration: 3000,
        });
      } else {
        await api.post(`/v2/inventario/material`, data);
        setUpdate(!update);
        enqueueSnackbar("Material cadastrado com sucesso!", {
          variant: "success",
          autoHideDuration: 3000,
        });
      }
    } catch (error) {
      enqueueSnackbar("Erro ao tentar criar um material.", {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  const columns = [
    { field: "marca", headerName: "Marca", width: 230, type: "text" },
    {
      field: "modelo",
      headerName: "Modelo",
      width: 230,
      editable: true,
      type: "text",
    },
    {
      field: "descricao",
      headerName: "Descricao",
      width: 280,
      editable: true,
      type: "text",
    },
    {
      field: "nomeTipoMaterial",
      headerName: "Tipo do Material",
      width: 200,
      editable: true,
      type: "text",
    },
    {
      field: "opcoes",
      headerName: "Opções",

      width: 150,

      renderCell: params => (
        <div style={{ cursor: "pointer" }}>
          <Opcoes setId={setId} setMaterialModal={setMaterialModal} row={params.row} />
        </div>
      ),
    },
  ];

  return (
    <Grid container alignItems="center" spacing={2} direction="column">
      <Grid container xs={12} item>
        <Paper sx={{ width: "100%", marginBottom: "30px", padding: "10px" }}>
          <Grid item container sx={{ marginLeft: "5px" }} justifyContent="space-between">
            <Grid item xs={6}>
              <Input
                label="Pesquise pelo nome"
                variant="standard"
                size="small"
                fullWidth
                type="text"
              />
            </Grid>
            <Grid xs={2} sm={2} md={1} lg={1} item>
              <IconButton
                onClick={() => {
                  if (checarPermissao("INV015")) {
                    setMaterialModal(true);
                  } else {
                    enqueueSnackbar(
                      "Você não possui permissão para cadastrar um material!",
                      {
                        variant: "error",
                        autoHideDuration: 3000,
                      }
                    );
                  }
                }}
                color="success"
                size="large"
              >
                <AddCircleOutlineIcon fontSize="large" />
              </IconButton>
            </Grid>
            <ModalForm
              onSubmit={handleSubmit}
              title="Adicionar Material"
              onClose={setMaterialModal}
              open={materialModal}
            >
              <Grid item xs={12} sm={12} lg={12}>
                <Select
                  required
                  textAttribute="nome"
                  valueAttribute="id"
                  variant="standard"
                  label="Tipo do Material"
                  value={tipoMaterial}
                  onChange={e => setTipoMaterial(e.target.value)}
                  arrayOptions={arrayDeTipos}
                />
              </Grid>
              <Grid item xs={12} sm={12} lg={12}>
                <Input
                  value={marca}
                  onChange={e => setMarca(e.target.value)}
                  required
                  fullWidth
                  variant="standard"
                  label="Marca"
                  type="text"
                />
              </Grid>
              <Grid item xs={12} sm={12} lg={12}>
                <Input
                  value={modelo}
                  onChange={e => setModelo(e.target.value)}
                  required
                  fullWidth
                  variant="standard"
                  label="Modelo"
                  type="text"
                />
              </Grid>
              <Grid item xs={12} sm={12} lg={12}>
                <Input
                  value={descricao}
                  onChange={e => setDescricao(e.target.value)}
                  multiline
                  maxRows={5}
                  fullWidth
                  variant="standard"
                  label="Descrição"
                  type="text"
                />
              </Grid>
            </ModalForm>
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={12} container>
        <Grid style={{ paddingTop: "10px" }} xs={12} sm={12} item>
          <Grid spacing={2} container>
            <Grid style={{ paddingTop: "10px" }} item xs={12} sm={12}>
              <Paper elevation={2}>
                <Box
                  sx={{
                    height: 1,
                    width: "100%",
                    marginBottom: "10px",
                  }}
                >
                  <div>
                    <Tabela rows={arrayMateriais} columns={columns} autoHeight />
                  </div>
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
