import React, { createContext, useState } from "react";

const IntervaloDataContext = createContext();

function IntervaloDataContextProvider({ children }) {
  const [dataInicio, setDataInicio] = useState(() => {
    const valueDateI = new Date();
    valueDateI.setDate(valueDateI.getDate() - 10);
    return valueDateI;
  });
  const [dataFim, setDataFim] = useState(new Date());

  const [competencia, setCompetencia] = useState("");
  const [notaFiscal, setNotaFiscal] = useState("");

  return (
    <IntervaloDataContext.Provider
      value={{
        dataInicio,
        setDataInicio,
        dataFim,
        setDataFim,
        competencia,
        setCompetencia,
        setNotaFiscal,
        notaFiscal,
      }}
    >
      {children}
    </IntervaloDataContext.Provider>
  );
}

export { IntervaloDataContext, IntervaloDataContextProvider };
