import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  AppBar,
  Box,
  Container,
  Dialog,
  DialogActions,
  DialogTitle,
  InputLabel,
  List,
  ListItem,
  Tab,
  Tabs,
  TextareaAutosize,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  Close,
  Person,
  Replay,
  FileCopyOutlined,
  LocationSearching,
  HowToReg,
  InfoOutlined,
  CheckCircleOutline,
  AddAPhoto
} from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import { format } from "date-fns";
import axios from "axios";

import "./styles.css";

import Waiter from "../../../components/Backdrop";
import InputData from "../../../components/InputData";
import DialogAviso from "../../../components/DialogAviso";
import InputClienteDrive from "../../../components/InputClienteDrive";
import ContainerTitleDrive from "../../../components/ContainerTitleDrive";

import { api, baseUrl } from "../../../services/Api";
import foto from "../../../assets/camera.png";
import SalvaLog from '../../../utils/SalvaLog';
import converteData from "../../../utils/converteData";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

export default function Formulario(props) {
  const { enqueueSnackbar } = useSnackbar();

  const {
    abre_visualiza_dados,
    cliente,
    usuario,
    novaData,
    setCliente,
    setAbre_visualiza_dados,
    exibe_ag_coleta,
    exibe_ag_autorizacao,
    exibe_ag_faturamento,
    exibe_coletados,
    exibe_cancelados,
    exibe_desistencias,
    setAgendamentos,
    setNovaData
   } = props;

  const [amplia_foto, setAmplia_foto] = useState(false);
  const [abre_envio_guia, setAbre_envio_guia] = useState(false);

  const [espera, setEspera] = useState(false);
  const [aviso, setAviso] = useState(false);
  const [abre_cadastro, setAbre_cadastro] = useState(false);
  const [abre_informacoes_pedido, setAbre_informacoes_pedido] = useState(false);
  const [info_atendimento, setInfo_atendimento] = useState(false);
  const [abre_captura_fotos, setCaptura_fotos] = useState(false);

  const [msg, setMsg] = useState("");

  const [confirma_exclusao, setConfirma_exclusao] = useState(false);

  const [cpf, setCpf] = useState("");
  const [nome, setNome] = useState("");
  const [rg, setRg] = useState("");
  const [data_nascimento, setData_nascimento] = useState("");
  const [mae, setMae] = useState("");
  const [sexo, setSexo] = useState("");
  const [cep, setCep] = useState("");
  const [endereco, setEndereco] = useState("");
  const [endereco_numero, setEndereco_numero] = useState("");
  const [complemento, setComplemento] = useState("");
  const [bairro, setBairro] = useState("");
  const [cidade, setCidade] = useState("");
  const [estado, setEstado] = useState("RN");
  const [celular, setCelular] = useState("");
  const [telefone, setTelefone] = useState("");
  const [cod_cliente, setCod_cliente] = useState(null);
  const [posto_cliente, setPosto_cliente] = useState("");

  const [observacao, setObservacao] = useState("");

  const [tipo, setTipo] = useState("Plano");
  const [prioridade, setPrioridade] = useState("Não");
  const [placa, setPlaca] = useState("");
  const [gestante, setGestante] = useState("Não");
  const [peso, setPeso] = useState("");
  const [altura, setAltura] = useState("");

  const [data_ultimo_pedido, setData_ultimo_pedido] = useState("");
  const [codigo_pedido, setCodigo_pedido] = useState("");
  const [status_ultimo_pedido, setStatus_ultimo_pedido] = useState("");
  const [status_atendimento, setStatus_atendimento] = useState("");
  const [data_atendimento, setData_atendimento] = useState("");

  const [id, setId] = useState("");

  const [aba, setAba] = useState(0);

  const [fotoAtual, setFotoAtual] = useState("");
  const [fotoAmpliada, setFotoAmpliada] = useState("");
  const [arquivoImagem, setArquivoImagem] = useState("");
  const [imagensCliente, setImagensCliente] = useState([]);

  const handleChangeAba = (event, newValue) => {
    setAba(newValue);
  };

  const carregaImagem = async (e) => {
    e.preventDefault();

    if (e.target.files[0] && !arquivoImagem) {
      setArquivoImagem(e.target.files[0]);
      setFotoAtual(URL.createObjectURL(e.target.files[0]));
    } else if (e.target.files[0] && arquivoImagem) {
      enqueueSnackbar('Salve ou descarte a imagem atual', { variant: 'error' });
    }
  };

  const salvaOficio = async () => {
    if (arquivoImagem) {
      setEspera(true);
      const formData = new FormData();
      formData.append("foto", arquivoImagem);

      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };

      // axios.post(`${baseURL}/api/upload/${id}/?tipo=${tipo}`, formData, config)
      try {
        const response = await axios.post(`${baseUrl}/api/uploadProxy`, formData, config);
          await api.post(`/api/upload/${id}/?tipo=CapturaFoto&filename=${response.data}`);
            SalvaLog(`Salvou imagem do agendamento de ID ${id}`, 'Controle Drive-Thru');
            enqueueSnackbar('Imagem salva com sucesso', { variant: 'success' });
            setArquivoImagem("");
            setFotoAtual("");

      } catch (error) {
        SalvaLog(`Tentou salvar imagem do agendamento de ID ${id}`, 'Controle Drive-Thru');
        enqueueSnackbar('Falha no carregamento da foto', { variant: 'error' });
      }
      setEspera(false);
    } else {
      enqueueSnackbar('Nenhum arquivo selecionado', { variant: 'warning' });
      setEspera(false);
    }
  };

  function copiarParaClipBoard(texto) {
    if (texto) {
      navigator.clipboard.writeText(texto);
    }
  }

  async function buscaEndereco() {
    setEspera(true);

    let end_cep = [];

    try {
      const { data } = await axios.get(
        `https://api.pagar.me/1/zipcodes/${cep}`
      );

      end_cep = data;

      SalvaLog(`Pesquisou pelo CEP ${cep}`, 'Controle Drive-Thru');
    } catch {
      SalvaLog(`Tentou pesquisar pelo CEP ${cep}`, 'Controle Drive-Thru');
      enqueueSnackbar('Endereço não encontrado ou CEP inválido', { variant: 'warning' })
    }

    setEndereco_numero("");
    setComplemento("");
    setEndereco(end_cep.street || '');
    setBairro(end_cep.neighborhood || '');
    setCidade(end_cep.city || '');
    setEstado(end_cep.state || '');

    setEspera(false);
  }

  function preencheDadosPedido(dados) {
    setStatus_ultimo_pedido(`Material coletado`);
    dados.map((d) => {
      if (!d.data_entrega_material) {
        setStatus_ultimo_pedido(`Material não coletado`);
      }
      return null;
    });
    setData_ultimo_pedido(dados[0].data_atendimento);
    setCodigo_pedido(`${dados[0].codigo_pedido}-${dados[0].posto_pedido}`);
  }

  function preencheDadosCliente(dados) {
    setCpf(dados.cpf);
    setNome(dados.nome);
    setRg(dados.rg);
    setData_nascimento(dados.data_nascimento);
    setMae(dados.mae);
    setSexo(dados.sexo);
    setCep(dados.cep);
    setEndereco(dados.endereco);
    setEndereco_numero(dados.endereco_numero);
    setComplemento(dados.complemento);
    setBairro(dados.bairro);
    setCidade(dados.cidade);
    setEstado(dados.estado);
    setCelular(dados.celular);
    setTelefone(dados.telefone);
    setGestante(dados.gestante);
    setPeso(dados.peso);
    setAltura(dados.altura);
    setCod_cliente(dados.cod_cliente);
    setPosto_cliente(dados.posto_cliente);
  }

  async function capturaInformacoes() {
    setEspera(true);

    const data_conv = converteData(data_atendimento);

    setStatus_atendimento(cliente.status_atendimento);
    setCodigo_pedido(cliente.codigo_pedido);
    setData_atendimento(data_conv);
    setObservacao(cliente.observacao);

    const dados_cliente = await api.get(`/api/driveThru/informacoes/${cliente.id}`);
      preencheDadosCliente(dados_cliente.data);

    const dados_cliente_upload = await api.get(`/api/upload/${cliente.id}`);
      if (dados_cliente_upload.data) {
        setImagensCliente(dados_cliente_upload.data);
      }

    setAbre_visualiza_dados(false);
    setInfo_atendimento(true);
    setEspera(false);
  }

  function capturaPedido() {
    setEspera(true);
    api
      .get(
        `/api/softlab/buscaPedido/?cod_cliente=${cod_cliente}&posto_cliente=${posto_cliente}`
      )
      .then((response) => {
        if (response.data.length > 0) {
          preencheDadosPedido(response.data);
        }
      });
    setEspera(false);
  }

  async function inicializaProcesso() {
    setEspera(true);

    try {
      const result = await api.post(`/api/driveThru/controle`, {
        cadastrador: usuario,
        cpf,
        placa,
        prioridade,
        tipo,
        data_atendimento: format(new Date(), "yyyy-MM-dd"),
      });

      setId(result.data.id);
      SalvaLog(`Iniciou o agendamento de ID ${result.data.id}`, 'Controle Drive-Thru');
    } catch (error) {
      SalvaLog(`Tentou iniciar um agendamento`, 'Controle Drive-Thru');
      enqueueSnackbar('Falha na operação', { variant: 'error' });
    }
    setEspera(false);
  }

  async function atualizaDados() {
    setEspera(true);
    try {
      await api.patch(`/api/driveThru/controle/agenda`, {
        id,
        cpf,
        placa,
        prioridade,
        tipo
      });

      SalvaLog(`Atualizou o agendamento de ID ${id} com CPF ${cpf}, com Placa ${placa}, com Prioridade ${prioridade} e Tipo ${tipo}`, 'Controle Drive-Thru');

    } catch (error) {
      SalvaLog(`Tentou atualizar o agendamento de ID ${id} com CPF ${cpf}, com Placa ${placa}, com Prioridade ${prioridade} e Tipo ${tipo}`, 'Controle Drive-Thru');
      enqueueSnackbar('Falha na operação', { variant: 'error' });
    }
    setEspera(false);
  }

  async function montaTabela(data) {
    setEspera(true);

    try {
      const dados = await api.get(
        `/api/driveThru/${data}/?exibe_ag_coleta=${exibe_ag_coleta}&exibe_ag_autorizacao=${exibe_ag_autorizacao}&exibe_ag_faturamento=${exibe_ag_faturamento}&exibe_coletados=${exibe_coletados}&exibe_cancelados=${exibe_cancelados}&exibe_desistencias=${exibe_desistencias}`
      );
      setAgendamentos(dados.data);
    } catch (error) {
      enqueueSnackbar('Falha na operação', { variant: 'error' });
    }
    setEspera(false);
  }

  function selecionaData(data) {
    setNovaData(data);
    // montaTabela(data);
  }

  function limpaCliente() {
    setNome("");
    setRg("");
    setData_nascimento("");
    setMae("");
    setSexo("");
    setCep("");
    setEndereco("");
    setEndereco_numero("");
    setComplemento("");
    setBairro("");
    setCidade("");
    setEstado("RN");
    setCelular("");
    setTelefone("");
    setCod_cliente(null);
    setPosto_cliente("");
  }

  async function pesquisaCliente() {
    if (!cpf) {
      enqueueSnackbar('CPF não informado', { variant: 'warning' });
    } else {
      setEspera(true);
      const retornoCliente = await api.get(`/api/softlab/cliente/${cpf}`);

      SalvaLog(`Pesquisou o CPF ${cpf}`, 'Controle Drive-Thru');

      if (retornoCliente.data.length === 0) {
        enqueueSnackbar('Cliente não localizado', { variant: 'warning' });
      } else {
        limpaCliente();

        const cep_so_numero = retornoCliente.data.cep.replace("-", "");

        setNome(retornoCliente.data.nome);
        setRg(retornoCliente.data.rg);
        setData_nascimento(retornoCliente.data.data_nascimento);
        setMae(retornoCliente.data.mae);
        setSexo(retornoCliente.data.sexo);
        setCep(Number(cep_so_numero));
        setEndereco(retornoCliente.data.endereco);
        setEndereco_numero(retornoCliente.data.endereco_numero);
        setComplemento(retornoCliente.data.complemento);
        setBairro(retornoCliente.data.bairro);
        setCidade(retornoCliente.data.cidade);
        setEstado(retornoCliente.data.estado);
        setCelular(retornoCliente.data.celular);
        setTelefone(retornoCliente.data.telefone);
        setCod_cliente(retornoCliente.data.cod_cliente);
        setPosto_cliente(retornoCliente.data.posto_cliente);
      }
    }

    setEspera(false);
  }

  function limpaCampos() {
    setCpf("");
    limpaCliente();
    setPlaca("");
    setTipo("Plano");
    setPrioridade("Não");
    setGestante("Não");
    setPeso("");
    setAltura("");
    setId("");
  }

  function limpaPedido() {
    setData_ultimo_pedido("");
    setCodigo_pedido("");
    setStatus_ultimo_pedido("");
  }

  function fechaCadastro() {
    setAbre_cadastro(false);
    limpaCampos();
  }

  function fechaInformacoes_pedido() {
    setAbre_informacoes_pedido(false);
    setAbre_cadastro(true);
    limpaPedido();
  }

  function limpaImagem() {
    setArquivoImagem("");
    setFotoAtual("");
  }

  function fechaCaptura_fotos() {
    enqueueSnackbar('Não é possível retornar para a tela anterior', { variant: 'warning' });
  }

  function fechaVisualiza_dados() {
    setAbre_visualiza_dados(false);
    setCliente('');
  }

  function fechaInfoAtendimento() {
    setInfo_atendimento(false);
    setCodigo_pedido('');
    setCliente('');
    setImagensCliente([]);
    setStatus_atendimento('');
    setObservacao('');
    limpaCampos();
    setAba(0);
  }

  async function formaEnvio(modo) {
    setEspera(true);
    try {
      await api.put(`/api/driveThru/formaEnvioGuiaMedica`, {
        id,
        email_whats: modo,
      });

      SalvaLog(`Atualizou a forma de envio do agendamento de ID: ${id} para ${modo}`, 'Controle Drive-Thru');
      setCaptura_fotos(true);
      setAbre_envio_guia(false);
    } catch (error) {
      SalvaLog(`Tentou atualizar a forma de envio do agendamento de ID: ${id} para ${modo}`, 'Controle Drive-Thru');
      enqueueSnackbar('Falha na operação', { variant: 'error' });
    }

    setEspera(false);
  }

  async function alteraStatusAtendimento(status) {
    try {
      setEspera(true);
      await api.put(`/api/driveThru/statusAtendimento`, {
        id: cliente.id,
        status_atendimento: status,
      });

      montaTabela(novaData);

      SalvaLog(`Atualizou o status de atendimento do agendamento de ID: ${id} para ${status}`, 'Controle Drive-Thru');
      enqueueSnackbar('Status de atendimento alterado', { variant: 'success' });
    } catch (error) {
      SalvaLog(`Tentou atualizar o status de atendimento do agendamento de ID: ${id} para ${status}`, 'Controle Drive-Thru');
      enqueueSnackbar('Falha na operação', { variant: 'error' });
    }
    setEspera(false);
  }

  function abreTelaPedido() {
    capturaPedido();
    setAbre_cadastro(false);
    setAbre_informacoes_pedido(true);
  }

  function verificaControle() {
    if (id) {
      atualizaDados();
      abreTelaPedido();
    } else if (!cpf || !placa) {
      enqueueSnackbar('Adicione CPF e número do carro', { variant: 'warning' });
    } else {
      inicializaProcesso();
      abreTelaPedido();
    }
  }

  async function liberaAgendamento() {
    setEspera(true);
    try {
      await api.put(`/api/driveThru/atualizaAgendamento/${id}?agendado=true`);

      montaTabela(novaData);

      SalvaLog(`Validou o agendamento de ID: ${id}`, 'Controle Drive-Thru');

    } catch (error) {
      SalvaLog(`Tentou validar o agendamento de ID: ${id}`, 'Controle Drive-Thru');
      enqueueSnackbar('Cliente não agendado corretamente', { variant: 'error' });
    }
    setEspera(false);
  }

  function cadastroConcluido() {
    liberaAgendamento();
    limpaCampos();
    limpaPedido();
    setStatus_atendimento("");
    enqueueSnackbar('Sucesso na operação', { variant: 'success' });
  }

  async function novoPedido(novo) {
    setEspera(true);
    let st_atd = '';

    try {
      await api.post(`/api/driveThru/cliente`, {
        id,
        atendente: usuario,
        nome,
        rg,
        cpf,
        mae,
        data_nascimento,
        sexo,
        cep,
        endereco,
        endereco_numero,
        complemento,
        bairro,
        cidade,
        estado,
        celular,
        telefone,
        peso,
        altura,
        gestante,
        cod_cliente,
        posto_cliente,
      });

      if (novo || !codigo_pedido) {
          st_atd = 'Aguardando autorização';

        if (tipo === 'Particular') {
          st_atd = 'Aguardando faturamento';
        }

        await api.put(`/api/driveThru/novoPedido`, {
          id,
          status_atendimento: st_atd
        });
        setAbre_envio_guia(true);
      } else {
        await api.patch(`/api/driveThru/pedido`, {
          id,
          codigo_pedido,
          status_atendimento: "Aguardando coleta",
        });
        cadastroConcluido();
      }
      SalvaLog(`Adicionou cliente de CPF ${cpf} no agendamento de ID: ${id} com status de atendimento ${st_atd || "Aguardando coleta"}`, 'Controle Drive-Thru');
      setAbre_informacoes_pedido(false);
    } catch (error) {
      SalvaLog(`Tentou adicionar cliente de CPF ${cpf} no agendamento de ID: ${id}`, 'Controle Drive-Thru');
      enqueueSnackbar('Falha na operação', { variant: 'error' });
    }
    setEspera(false);
  }

  function fechaAbre_envio_guia() {
    enqueueSnackbar('Não é possível retornar para a tela anterior', { variant: 'error' });
  }

  async function atualizaPedido(id_tabela) {
    setEspera(true);

    if (!codigo_pedido) {
      enqueueSnackbar('Código de pedido vazio', { variant: 'warning' });
    } else {
      try {
        await api.put(`/api/driveThru/atualizaPedido`, {
          id: id_tabela,
          codigo_pedido,
        });

        montaTabela(novaData);

        SalvaLog(`Atualizou o código do pedido ${codigo_pedido} do agendamento de ID: ${id_tabela}`, 'Controle Drive-Thru');
        enqueueSnackbar('Pedido atualizado', { variant: 'success' });
      } catch (error) {
        SalvaLog(`Tentou atualizar o código do pedido do agendamento de ID: ${id_tabela}`, 'Controle Drive-Thru');
        enqueueSnackbar('Falha na operação', { variant: 'error' });
      }
    }

    setEspera(false);
  }

  async function atualizaObservacao(id_tabela) {
    setEspera(true);

    if (!observacao) {
      enqueueSnackbar('Nenhuma observação foi realizada', { variant: 'warning' });
    } else {
      try {
        await api.put(`/api/driveThru/atualizaObservacao`, {
          id: id_tabela,
          observacao
        });

        montaTabela(novaData);

        SalvaLog(`Atualizou a observação ${observacao} do agendamento de ID: ${id_tabela}`, 'Controle Drive-Thru');
        enqueueSnackbar('Observação adicionada', { variant: 'success' });
      } catch (error) {
        SalvaLog(`Tentou atualizar a observação do agendamento de ID: ${id_tabela}`, 'Controle Drive-Thru');
        enqueueSnackbar('Falha na operação', { variant: 'error' });
      }
    }

    setEspera(false);
  }

  function limpaDados() {
    setCliente([]);
    setAbre_visualiza_dados(false);
    setConfirma_exclusao(false);
  }

  async function acompanhaPedido() {
    setEspera(true);

    try {

      await api.put(`/api/driveThru/responsavel/${cliente.id}?usuario=${usuario}`);

      montaTabela(novaData);

      SalvaLog(`Começou a acompanhar o agendamento de ID: ${cliente.id}`, 'Controle Drive-Thru');
      limpaDados();
      enqueueSnackbar('Acompanhando pedido selecionado', { variant: 'success' });
    } catch (error) {
      SalvaLog(`Tentou acompanhar o agendamento de ID: ${cliente.id}`, 'Controle Drive-Thru');
      enqueueSnackbar('Falha na operação', { variant: 'error' });
    }

    setEspera(false);
  }

  function confirmaExclusao() {
    setMsg("Você realmente deseja cancelar este agendamento?");
    setConfirma_exclusao(true);
  }

  async function excluiCliente() {
    try {
      setEspera(true);

      await api.put(`/api/driveThru/atualizaAgendamento/${cliente.id}?agendado=false`);

      montaTabela(novaData);

      SalvaLog(`Excluiu o agendamento de ID: ${cliente.id}`, 'Controle Drive-Thru');
      limpaDados();
      enqueueSnackbar('Cliente removido', { variant: 'success' });
    } catch (error) {
      SalvaLog(`Tentou excluir o agendamento de ID: ${cliente.id}`, 'Controle Drive-Thru');
      enqueueSnackbar('Falha na operação', { variant: 'error' });
    }
    fechaInfoAtendimento();
    setEspera(false);
  }

  return (
    <div>
      <div id="container-form-ctrl-drive">
        <InputData
          labelText="Selecione a data"
          data={novaData}
          acao={selecionaData}
        />

        <button
          type="button"
          id="button-add-drive"
          onClick={() => {
            setCpf("");
            setAbre_cadastro(true);
          }}
        >
          Adicionar
        </button>
      </div>

      <Waiter espera={espera} />

      <DialogAviso
        aviso={aviso}
        setAviso={setAviso}
        mensagem={msg}
        setMensagem={setMsg}
      />

      <Dialog
        fullScreen
        open={abre_cadastro}
        onClose={() => {
          fechaCadastro();
        }}
      >
        <ContainerTitleDrive acao={fechaCadastro} />
        <Container maxWidth="md">
          <div id="container-search-cpf">
            <label htmlFor="input-search-cpf">Informe o CPF <br />
              <input
                id="input-search-cpf"
                placeholder="CPF"
                type="number"
                value={cpf}
                onChange={(e) => {
                e.preventDefault();
                setCpf(e.target.value);
                if (cod_cliente || posto_cliente) {
                  setCod_cliente(null);
                  setPosto_cliente("");
                }
              }}
              />
            </label>
            <button
              type="button"
              id="button-search-cpf"
              onClick={() => {
                pesquisaCliente();
              }}
            >
              Pesquisar
            </button>
          </div>

          <div id="container-data-client-drive">
            <div className="container-input-client-drive-duplo1">
              <InputClienteDrive
                text="Nº do carro"
                type="number"
                value={placa}
                acao={setPlaca}
              />
            </div>

            <div className="container-input-client-drive-small1">
              <label htmlFor="seleciona-tipo-de-pagamento" className="label-default-tittle-drive">Tipo de pagamento
                <select
                  id="seleciona-tipo-de-pagamento"
                  className="container-select-client-drive"
                  value={tipo}
                  onChange={(e) => {
                  e.preventDefault();
                  setTipo(e.target.value);
                }}
                >
                  <option key="10179" value="Plano">
                    Plano
                  </option>
                  <option key="20179" value="Particular">
                    Particular
                  </option>
                </select>
              </label>
            </div>

            <div className="container-input-client-drive-tiple3">
              <label htmlFor="seleciona-prioridade" className="label-default-tittle-drive">Prioridade
                <select
                  id="seleciona-prioridade"
                  className="container-select-client-drive"
                  value={prioridade}
                  onChange={(e) => {
                  e.preventDefault();
                  setPrioridade(e.target.value);
                }}
                >
                  <option key="10290" value="Não">
                    Não
                  </option>
                  <option key="20290" value="Sim">
                    Sim
                  </option>
                </select>
              </label>
            </div>

            <div className="container-input-client-drive-unico">
              <InputClienteDrive
                text="Nome"
                type="text"
                value={nome}
                acao={setNome}
              />
            </div>

            <div className="container-input-client-drive-middle">
              <InputClienteDrive
                text="RG"
                type="number"
                value={rg}
                acao={setRg}
              />
            </div>

            <div className="container-input-client-drive-data">
              <label htmlFor="input-data-drive" id="label-data-drive">Data de Nascimento
                <input
                  id="input-data-drive"
                  type="text"
                  value={data_nascimento}
                  onChange={(e) => {
                  e.preventDefault();
                  const dt = (e.target.value).replace(/(\d{2})(\d{2})(\d{4})/, '$1/$2/$3');
                    setData_nascimento(dt);

                }}
                />
              </label>
            </div>

            <div className="container-input-client-drive-unico">
              <InputClienteDrive
                text="Mãe"
                type="text"
                value={mae}
                acao={setMae}
              />
            </div>

            <div className="container-input-client-drive-tiple1">
              <InputClienteDrive
                text="Peso"
                type="number"
                value={peso}
                acao={setPeso}
              />
            </div>

            <div className="container-input-client-drive-tiple2">
              <InputClienteDrive
                text="Altura"
                type="number"
                value={altura}
                acao={setAltura}
              />
            </div>

            <div className="container-input-client-drive-small1">
              <label htmlFor="seleciona-sexo" className="label-default-tittle-drive">Sexo
                <select
                  id="seleciona-sexo"
                  className="container-select-client-drive"
                  value={sexo}
                  onChange={(e) => {
                  e.preventDefault();
                  setSexo(e.target.value);
                }}
                >
                  <option key="101" value="M">
                    Masculino
                  </option>
                  <option key="201" value="F">
                    Feminino
                  </option>
                </select>
              </label>
            </div>

            <div className="container-input-client-drive-tiple3">
              <label htmlFor="seleciona-gestante" className="label-default-tittle-drive">Gestante
                <select
                  id="seleciona-gestante"
                  className="container-select-client-drive"
                  value={gestante}
                  onChange={(e) => {
                  e.preventDefault();
                  setGestante(e.target.value);
                }}
                >
                  <option key="102" value="Não">
                    Não
                  </option>
                  <option key="202" value="Sim">
                    Sim
                  </option>
                </select>
              </label>
            </div>

            <div className="container-input-client-drive-big-cep">
              <InputClienteDrive
                text="CEP"
                type="number"
                value={cep}
                acao={setCep}
              />
            </div>

            <div className="container-input-client-drive-small1">
              <button
                type="button"
                className="button-copy-drive"
                onClick={() => {
                  buscaEndereco();
                }}
              >
                <LocationSearching id="button-search-adress-drive" />
              </button>
            </div>

            <div className="container-input-client-drive-big">
              <InputClienteDrive
                text="Endereço"
                type="text"
                value={endereco}
                acao={setEndereco}
              />
            </div>

            <div className="container-input-client-drive-small2">
              <InputClienteDrive
                text="Nº"
                type="number"
                value={endereco_numero}
                acao={setEndereco_numero}
              />
            </div>

            <div className="container-input-client-drive-big">
              <InputClienteDrive
                text="Complemento"
                type="text"
                value={complemento}
                acao={setComplemento}
              />
            </div>

            <div className="container-input-client-drive-small2">
              <label htmlFor="seleciona-estado" className="label-default-tittle-drive">Estado
                <select
                  id="seleciona-estado"
                  name="seleciona-estado"
                  className="container-select-client-drive"
                  value={estado}
                  onChange={(e) => {
                  e.preventDefault();
                  setEstado(e.target.value);
                }}
                >
                  <option key="10215" value="RN">
                    RN
                  </option>
                  <option key="20225" value="PB">
                    PB
                  </option>
                  <option key="20235" value="PE">
                    PE
                  </option>
                  <option key="20245" value="CE">
                    CE
                  </option>
                </select>
              </label>
            </div>

            <div className="container-input-client-drive-duplo1">
              <InputClienteDrive
                text="Cidade"
                type="text"
                value={cidade}
                acao={setCidade}
              />
            </div>

            <div className="container-input-client-drive-duplo2">
              <InputClienteDrive
                text="Bairro"
                type="text"
                value={bairro}
                acao={setBairro}
              />
            </div>

            <div className="container-input-client-drive-duplo1">
              <InputClienteDrive
                text="Celular"
                type="number"
                value={celular}
                acao={setCelular}
              />
            </div>

            <div className="container-input-client-drive-duplo2">
              <InputClienteDrive
                text="Telefone"
                type="number"
                value={telefone}
                acao={setTelefone}
              />
            </div>

            <div className="container-input-client-drive-unico">
              <button
                type="button"
                id="button-search-cpf"
                onClick={() => {
                  verificaControle();
                }}
              >
                Próximo passo
              </button>
            </div>
          </div>
        </Container>
      </Dialog>

      <Dialog
        fullScreen
        open={abre_informacoes_pedido}
        onClose={() => {
          fechaInformacoes_pedido();
        }}
      >
        <ContainerTitleDrive acao={fechaInformacoes_pedido} />
        <Container maxWidth="md">
          <div id="container-data-client-drive">
            <div className="container-input-client-drive-unico">
              <span id="label-name-tittle-info-pedido-drive">Nome</span>
              <span id="label-name-info-pedido-drive">{nome}</span>
            </div>

            <div className="container-input-client-drive-unico">
              <span id="label-info-pedido-drive">INFORMAÇÕES DO PEDIDO</span>
            </div>

            <div className="container-input-client-drive-unico2">
              <span id="label-dados-info-pedido-drive">
                Pedido: <strong>{codigo_pedido}</strong>
              </span>
              <span id="label-dados-info-pedido-drive">
                Data: <strong>{data_ultimo_pedido}</strong>
              </span>
              <span id="label-dados-info-pedido-drive">
                Status: <strong>{status_ultimo_pedido}</strong>
              </span>
            </div>

            <div className="container-input-client-drive-unico">
              <span id="label-info-pedido-drive">Cliente possui protocolo?</span>
            </div>

            {codigo_pedido ? (
              <div className="container-input-client-drive-unico">
                <button
                  type="button"
                  id="button-possui-pedido"
                  onClick={() => {
                    novoPedido(false);
                  }}
                >
                  Sim
                </button>
              </div>
            ) : null}

            <div className="container-input-client-drive-unico">
              <button
                type="button"
                id="button-nao-possui-pedido"
                onClick={() => {
                  novoPedido(true);
                }}
              >
                Não
              </button>
            </div>
          </div>
        </Container>
      </Dialog>

      <Dialog
        fullScreen
        open={abre_envio_guia}
        onClose={() => {
          fechaAbre_envio_guia();
        }}
      >
        <ContainerTitleDrive acao={fechaAbre_envio_guia} />
        <Container maxWidth="md">
          <div id="container-send-client-drive">
            <div className="container-input-client-drive-unico">
              <span id="label-name-tittle-info-pedido-drive">Nome</span>
              <span id="label-name-info-pedido-drive">{nome}</span>
            </div>

            <div className="container-input-client-drive-unico">
              <span id="label-info-pedido-drive">
                COMO SERÁ ENCAMINHADA A GUIA MÉDICA?
              </span>
            </div>

            <div className="container-input-client-drive-send">
              <div className="container-input-client-drive-unico">
                <button
                  type="button"
                  id="button-search-cpf"
                  onClick={() => {
                    formaEnvio("");
                  }}
                >
                  Capturar imagens agora
                </button>
              </div>

              <div className="container-input-client-drive-unico">
                <button
                  type="button"
                  id="button-search-cpf"
                  onClick={() => {
                    formaEnvio("email");
                  }}
                >
                  Já enviada por email
                </button>
              </div>

              <div className="container-input-client-drive-unico">
                <button
                  type="button"
                  id="button-search-cpf"
                  onClick={() => {
                    formaEnvio("whatsapp");
                  }}
                >
                  Já enviada pelo whatsapp
                </button>
              </div>
            </div>
          </div>
        </Container>
      </Dialog>

      <Dialog
        fullScreen
        open={abre_captura_fotos}
        onClose={() => {
          fechaCaptura_fotos();
        }}
      >
        <ContainerTitleDrive acao={fechaCaptura_fotos} />
        <Container maxWidth="md">
          <div id="container-data-client-drive">
            <div className="container-input-client-drive-unico">
              <span id="label-name-tittle-info-pedido-drive">Nome</span>
              <span id="label-name-info-pedido-drive">{nome}</span>
            </div>

            <div className="container-input-client-drive-unico">
              <span id="label-info-pedido-drive">
                TIRAR FOTO DA GUIA MÉDICA E DA CARTEIRA DO PLANO
              </span>
            </div>

            <div className="container-input-client-drive-unico">
              <label id="oficioIcon" htmlFor="foto">
                <img
                  id="img-logo-foto"
                  className="imgLogo"
                  src={!fotoAtual ? foto : fotoAtual}
                  alt="DNA Center"
                />
                <input
                  type="file"
                  id="foto"
                  name="foto"
                  accept="image/png, image/jpeg"
                  onChange={carregaImagem}
                />
              </label>
            </div>

            <div className="container-button-client-drive-foto">
              <button
                type="button"
                id="content-button-client-drive-foto-salva"
                onClick={() => {
                  salvaOficio();
                }}
              >
                Salvar
              </button>
              <button
                type="button"
                id="content-button-client-drive-foto-descarta"
                onClick={() => {
                  limpaImagem();
                }}
              >
                Descartar
              </button>
            </div>

            <div className="container-input-client-drive-unico">
              <button
                type="button"
                id="button-search-cpf"
                onClick={() => {
                  if (arquivoImagem) {
                    enqueueSnackbar('A imagem selecionada aguarda confirmação', { variant: 'warning' });
                  } else {
                    limpaImagem();
                    setCaptura_fotos(false);
                    cadastroConcluido();
                  }
                }}
              >
                Finalizar
              </button>
            </div>
          </div>
        </Container>
      </Dialog>

      <Dialog
        open={amplia_foto}
        fullWidth
        maxWidth="xl"
        onClose={() => {
          setAmplia_foto(false);
          setFotoAmpliada("");
        }}
      >
        <div id="container-title-information-drive">
          <p />
          <span id="content-label-title">Drive-Thru DNA Center</span>
          <button
            type="button"
            id="button-dialog-back"
            onClick={() => {
              setAmplia_foto(false);
              setFotoAmpliada("");
            }}
          >
            <Close id="icon-back" fontSize="large" />
          </button>
        </div>
        <div id="container-foto-drive-img">
          <img
            id="img-logo-foto-teste"
            className="imgLogo"
            src={!fotoAmpliada ? foto : fotoAmpliada}
            alt="DNA Center"
          />
        </div>
      </Dialog>

      <Dialog
        open={abre_visualiza_dados}
        onClose={() => {
          fechaVisualiza_dados();
        }}
      >
        <DialogTitle id="selecionaOpcao">Selecione uma opção</DialogTitle>
        <List className="opList">
          <ListItem
            autoFocus
            button
            onClick={() => {
              capturaInformacoes();
            }}
            key="1100"
          >
            <Person color="primary" />
            <InputLabel id="itemLista"> Visualizar</InputLabel>
          </ListItem>

          {(!cliente.usuario)
            ? (
              <ListItem
                button
                onClick={() => {
              acompanhaPedido();
            }}
                key="1200"
              >
                <HowToReg color="primary" />
                <InputLabel id="itemLista"> Tratar Pedido</InputLabel>
              </ListItem>
              )
            : null}

          {(cliente.status_atendimento === 'Aguardando coleta')
            ? (
              <ListItem
                button
                onClick={() => {
                  alteraStatusAtendimento('Coletado');
                  fechaVisualiza_dados();
                }}
                key="14500"
              >
                <CheckCircleOutline color="primary" />
                <InputLabel id="itemLista"> Coletado </InputLabel>
              </ListItem>
              )
            : null}

          {(cliente.status_atendimento === 'Cancelado' || cliente.status_atendimento === 'Desistiu')
            ? (
              <ListItem
                button
                onClick={() => {
                  setMsg(cliente.observacao);
                  setAviso(true);
                  fechaVisualiza_dados();
                }}
                key="1300"
              >
                <InfoOutlined color="primary" />
                <InputLabel id="itemLista"> Observação</InputLabel>
              </ListItem>
              )
            : null}

          <ListItem
            button
            onClick={() => {
              fechaVisualiza_dados();
            }}
            key="1500"
          >
            <Replay />
            <InputLabel id="itemLista"> Voltar</InputLabel>
          </ListItem>
        </List>
      </Dialog>

      <Dialog
        fullScreen
        open={info_atendimento}
        onClose={() => {
          fechaInfoAtendimento();
        }}
      >
        <div id="container-title-information-drive">
          <p />
          <span id="content-label-title">
            INFORMAÇÕES DO ATENDIMENTO DRIVE
          </span>
          <button
            type="button"
            id="button-dialog-back"
            onClick={() => {
              fechaInfoAtendimento();
            }}
          >
            <Close id="icon-back" fontSize="large" />
          </button>
        </div>
        <Container maxWidth="md">
          <div id="container-form-information-drive">

            <span id="content-form-information-drive-label">
              Data atendimento: <strong>{data_atendimento}</strong>
            </span>

            <span id="content-form-information-drive-label">
              Cadastrado por: <strong>{cliente.cadastrador}</strong>
            </span>

            <span id="content-form-information-drive-label">
              Tratado por: <strong>{cliente.usuario}</strong>
            </span>

            <span id="content-form-information-drive-label">
              Número do carro: <strong>{cliente.placa}</strong>
            </span>

            <span id="content-form-information-drive-label">
              Nome: <strong>{cliente.nome}</strong>
            </span>

            <div id="container-select-information-drive">
              <label htmlFor="content-form-information-drive-select" id="content-form-information-drive-tittle-select">
                Definir status atendimento
                <select
                  id="content-form-information-drive-select"
                  value={status_atendimento}
                  onChange={(e) => {
                  e.preventDefault();
                  setStatus_atendimento(e.target.value);
                  alteraStatusAtendimento(e.target.value);
                }}
                >
                  <option key="09101" value="Aguardando autorização">
                    Aguardando autorização
                  </option>
                  <option key="09201" value="Aguardando faturamento">
                    Aguardando faturamento
                  </option>
                  <option key="09301" value="Aguardando coleta">
                    Aguardando coleta
                  </option>
                  <option key="09401" value="Coletado">
                    Coletado
                  </option>
                  <option key="09501" value="Cancelado">
                    Cancelado
                  </option>
                  <option key="09601" value="Desistiu">
                    Desistência
                  </option>
                </select>
              </label>
            </div>

          </div>
          <div className="container-abes-information-drive">
            <AppBar id="appbar-information-drive" position="static">
              <Tabs
                value={aba}
                onChange={handleChangeAba}
                indicatorColor="secondary"
                textColor="inherit"
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
              >
                <Tab label="Dados do cliente" {...a11yProps(0)} />
                <Tab label="Código do pedido" {...a11yProps(1)} />
                <Tab label="Imagens" {...a11yProps(2)} />
                <Tab label="Observação" {...a11yProps(3)} />
              </Tabs>
            </AppBar>
            <TabPanel value={aba} index={0}>
              <div id="container-labels-aba-informacoes-drive">
                <div className="container-label-aba-informacoes-drive">
                  <span className="label-aba-inforamacoes-drive">Nome</span>
                  <div className="container-button-copy-drive">
                    <span className="content-label-aba-informacoes-drive">
                      {nome}
                    </span>
                    <Tooltip title="Copiar texto">
                      <button
                        type="button"
                        className="button-copy-drive"
                        onClick={() => {
                          copiarParaClipBoard(nome);
                        }}
                      >
                        <FileCopyOutlined fontSize="small" />
                      </button>
                    </Tooltip>
                  </div>
                </div>

                <div className="container-label-aba-informacoes-drive">
                  <span className="label-aba-inforamacoes-drive">
                    Identidade
                  </span>
                  <div className="container-button-copy-drive">
                    <span className="content-label-aba-informacoes-drive">
                      {rg}
                    </span>
                    <Tooltip title="Copiar texto">
                      <button
                        type="button"
                        className="button-copy-drive"
                        onClick={() => {
                          copiarParaClipBoard(rg);
                        }}
                      >
                        <FileCopyOutlined fontSize="small" />
                      </button>
                    </Tooltip>
                  </div>
                </div>

                <div className="container-label-aba-informacoes-drive">
                  <span className="label-aba-inforamacoes-drive">CPF</span>
                  <div className="container-button-copy-drive">
                    <span className="content-label-aba-informacoes-drive">
                      {cpf}
                    </span>
                    <Tooltip title="Copiar texto">
                      <button
                        type="button"
                        className="button-copy-drive"
                        onClick={() => {
                          copiarParaClipBoard(cpf);
                        }}
                      >
                        <FileCopyOutlined fontSize="small" />
                      </button>
                    </Tooltip>
                  </div>
                </div>

                <div className="container-label-aba-informacoes-drive">
                  <span className="label-aba-inforamacoes-drive">
                    Data de Nascimento
                  </span>
                  <div className="container-button-copy-drive">
                    <span className="content-label-aba-informacoes-drive">
                      {data_nascimento}
                    </span>
                    <Tooltip title="Copiar texto">
                      <button
                        type="button"
                        className="button-copy-drive"
                        onClick={() => {
                          copiarParaClipBoard(data_nascimento);
                        }}
                      >
                        <FileCopyOutlined fontSize="small" />
                      </button>
                    </Tooltip>
                  </div>
                </div>

                <div className="container-label-aba-informacoes-drive">
                  <span className="label-aba-inforamacoes-drive">Sexo</span>
                  <div className="container-button-copy-drive">
                    <span className="content-label-aba-informacoes-drive">
                      {sexo}
                    </span>
                    <Tooltip title="Copiar texto">
                      <button
                        type="button"
                        className="button-copy-drive"
                        onClick={() => {
                          copiarParaClipBoard(sexo);
                        }}
                      >
                        <FileCopyOutlined fontSize="small" />
                      </button>
                    </Tooltip>
                  </div>
                </div>

                <div className="container-label-aba-informacoes-drive">
                  <span className="label-aba-inforamacoes-drive">Mãe</span>
                  <div className="container-button-copy-drive">
                    <span className="content-label-aba-informacoes-drive">
                      {mae}
                    </span>
                    <Tooltip title="Copiar texto">
                      <button
                        type="button"
                        className="button-copy-drive"
                        onClick={() => {
                          copiarParaClipBoard(mae);
                        }}
                      >
                        <FileCopyOutlined fontSize="small" />
                      </button>
                    </Tooltip>
                  </div>
                </div>

                <div className="container-label-aba-informacoes-drive">
                  <span className="label-aba-inforamacoes-drive">CEP</span>
                  <div className="container-button-copy-drive">
                    <span className="content-label-aba-informacoes-drive">
                      {cep}
                    </span>
                    <Tooltip title="Copiar texto">
                      <button
                        type="button"
                        className="button-copy-drive"
                        onClick={() => {
                          copiarParaClipBoard(cep);
                        }}
                      >
                        <FileCopyOutlined fontSize="small" />
                      </button>
                    </Tooltip>
                  </div>
                </div>

                <div className="container-label-aba-informacoes-drive">
                  <span className="label-aba-inforamacoes-drive">Bairro</span>
                  <div className="container-button-copy-drive">
                    <span className="content-label-aba-informacoes-drive">
                      {bairro}
                    </span>
                    <Tooltip title="Copiar texto">
                      <button
                        type="button"
                        className="button-copy-drive"
                        onClick={() => {
                          copiarParaClipBoard(bairro);
                        }}
                      >
                        <FileCopyOutlined fontSize="small" />
                      </button>
                    </Tooltip>
                  </div>
                </div>

                <div className="container-label-aba-informacoes-drive">
                  <span className="label-aba-inforamacoes-drive">Cidade</span>
                  <div className="container-button-copy-drive">
                    <span className="content-label-aba-informacoes-drive">
                      {cidade}
                    </span>
                    <Tooltip title="Copiar texto">
                      <button
                        type="button"
                        className="button-copy-drive"
                        onClick={() => {
                          copiarParaClipBoard(cidade);
                        }}
                      >
                        <FileCopyOutlined fontSize="small" />
                      </button>
                    </Tooltip>
                  </div>
                </div>

                <div className="container-label-aba-informacoes-drive">
                  <span className="label-aba-inforamacoes-drive">Estado</span>
                  <div className="container-button-copy-drive">
                    <span className="content-label-aba-informacoes-drive">
                      {estado}
                    </span>
                    <Tooltip title="Copiar texto">
                      <button
                        type="button"
                        className="button-copy-drive"
                        onClick={() => {
                          copiarParaClipBoard(estado);
                        }}
                      >
                        <FileCopyOutlined fontSize="small" />
                      </button>
                    </Tooltip>
                  </div>
                </div>

                <div className="container-label-aba-informacoes-drive">
                  <span className="label-aba-inforamacoes-drive">
                    Endereço
                  </span>
                  <div className="container-button-copy-drive">
                    <span className="content-label-aba-informacoes-drive">
                      {endereco}
                    </span>
                    <Tooltip title="Copiar texto">
                      <button
                        type="button"
                        className="button-copy-drive"
                        onClick={() => {
                          copiarParaClipBoard(endereco);
                        }}
                      >
                        <FileCopyOutlined fontSize="small" />
                      </button>
                    </Tooltip>
                  </div>
                </div>

                <div className="container-label-aba-informacoes-drive">
                  <span className="label-aba-inforamacoes-drive">Nº</span>
                  <div className="container-button-copy-drive">
                    <span className="content-label-aba-informacoes-drive">
                      {endereco_numero}
                    </span>
                    <Tooltip title="Copiar texto">
                      <button
                        type="button"
                        className="button-copy-drive"
                        onClick={() => {
                          copiarParaClipBoard(endereco_numero);
                        }}
                      >
                        <FileCopyOutlined fontSize="small" />
                      </button>
                    </Tooltip>
                  </div>
                </div>

                <div className="container-label-aba-informacoes-drive">
                  <span className="label-aba-inforamacoes-drive">
                    Complemento
                  </span>
                  <div className="container-button-copy-drive">
                    <span className="content-label-aba-informacoes-drive">
                      {complemento}
                    </span>
                    <Tooltip title="Copiar texto">
                      <button
                        type="button"
                        className="button-copy-drive"
                        onClick={() => {
                          copiarParaClipBoard(complemento);
                        }}
                      >
                        <FileCopyOutlined fontSize="small" />
                      </button>
                    </Tooltip>
                  </div>
                </div>

                <div className="container-label-aba-informacoes-drive">
                  <span className="label-aba-inforamacoes-drive">
                    Telefone
                  </span>
                  <div className="container-button-copy-drive">
                    <span className="content-label-aba-informacoes-drive">
                      {telefone}
                    </span>
                    <Tooltip title="Copiar texto">
                      <button
                        type="button"
                        className="button-copy-drive"
                        onClick={() => {
                          copiarParaClipBoard(telefone);
                        }}
                      >
                        <FileCopyOutlined fontSize="small" />
                      </button>
                    </Tooltip>
                  </div>
                </div>

                <div className="container-label-aba-informacoes-drive">
                  <span className="label-aba-inforamacoes-drive">
                    Celular
                  </span>
                  <div className="container-button-copy-drive">
                    <span className="content-label-aba-informacoes-drive">
                      {celular}
                    </span>
                    <Tooltip title="Copiar texto">
                      <button
                        type="button"
                        className="button-copy-drive"
                        onClick={() => {
                          copiarParaClipBoard(celular);
                        }}
                      >
                        <FileCopyOutlined fontSize="small" />
                      </button>
                    </Tooltip>
                  </div>
                </div>

                <div className="container-label-aba-informacoes-drive">
                  <span className="label-aba-inforamacoes-drive">Peso</span>
                  <div className="container-button-copy-drive">
                    <span className="content-label-aba-informacoes-drive">
                      {peso}
                    </span>
                    <Tooltip title="Copiar texto">
                      <button
                        type="button"
                        className="button-copy-drive"
                        onClick={() => {
                          copiarParaClipBoard(peso);
                        }}
                      >
                        <FileCopyOutlined fontSize="small" />
                      </button>
                    </Tooltip>
                  </div>
                </div>

                <div className="container-label-aba-informacoes-drive">
                  <span className="label-aba-inforamacoes-drive">Altura</span>
                  <div className="container-button-copy-drive">
                    <span className="content-label-aba-informacoes-drive">
                      {altura}
                    </span>
                    <Tooltip title="Copiar texto">
                      <button
                        type="button"
                        className="button-copy-drive"
                        onClick={() => {
                          copiarParaClipBoard(altura);
                        }}
                      >
                        <FileCopyOutlined fontSize="small" />
                      </button>
                    </Tooltip>
                  </div>
                </div>

                <div className="container-label-aba-informacoes-drive">
                  <span className="label-aba-inforamacoes-drive">
                    Gestante
                  </span>
                  <div className="container-button-copy-drive">
                    <span className="content-label-aba-informacoes-drive">
                      {gestante}
                    </span>
                    <Tooltip title="Copiar texto">
                      <button
                        type="button"
                        className="button-copy-drive"
                        onClick={() => {
                          copiarParaClipBoard(gestante);
                        }}
                      >
                        <FileCopyOutlined fontSize="small" />
                      </button>
                    </Tooltip>
                  </div>
                </div>

                <div className="container-label-aba-informacoes-drive">
                  <span className="label-aba-inforamacoes-drive">
                    Tipo de pagamento
                  </span>
                  <div className="container-button-copy-drive">
                    <span className="content-label-aba-informacoes-drive">
                      {cliente.tipo}
                    </span>
                  </div>
                </div>

                <div className="container-label-aba-informacoes-drive">
                  <span className="label-aba-inforamacoes-drive">
                    Prioridade
                  </span>
                  <div className="container-button-copy-drive">
                    <span className="content-label-aba-informacoes-drive">
                      {cliente.prioridade}
                    </span>
                  </div>
                </div>

                <div className="container-label-aba-informacoes-drive">
                  <span className="label-aba-inforamacoes-drive">
                    Código do cliente
                  </span>
                  <div className="container-button-copy-drive">
                    <span className="content-label-aba-informacoes-drive">
                      {cod_cliente ? `${cod_cliente  }-${  posto_cliente}` : ""}
                    </span>
                    <Tooltip title="Copiar texto">
                      <button
                        type="button"
                        className="button-copy-drive"
                        onClick={() => {
                          copiarParaClipBoard(
                            cod_cliente ? `${cod_cliente  }-${ posto_cliente}` : ""
                          );
                        }}
                      >
                        <FileCopyOutlined fontSize="small" />
                      </button>
                    </Tooltip>
                  </div>
                </div>

                <div className="container-label-aba-informacoes-drive2">
                  <Tooltip title="Apaga o atendimento deste cliente">
                    <span
                      id="label-aba-remove-inforamacoes-drive"
                      onClick={() => confirmaExclusao()}
                    >
                      Remover da tabela
                    </span>
                  </Tooltip>
                </div>
              </div>
            </TabPanel>
            <TabPanel value={aba} index={1}>
              <div id="container-send-client-drive">
                <div className="container-input-client-drive-new-pedido">
                  <h3 id="h2-drive">Código do pedido</h3>
                  <input
                    className="input-new-code"
                    placeholder="Código do pedido"
                    type="text"
                    value={codigo_pedido}
                    onChange={(e) => {
                      e.preventDefault();
                      setCodigo_pedido(e.target.value);
                    }}
                  />
                </div>

                <div id="button-atualiza-pedido">
                  <button
                    type="button"
                    id="content-button-client-drive-foto-salva2"
                    onClick={() => {
                      atualizaPedido(cliente.id);
                    }}
                  >
                    Atualizar
                  </button>
                </div>
              </div>
            </TabPanel>
            <TabPanel value={aba} index={2}>

              <div>
                <button
                  type="button"
                  id="button-add-more-photos"
                  onClick={async () => {
                    const id_temp = cliente.id;
                    const nome_temp = cliente.nome;
                    await fechaInfoAtendimento();
                    setId(id_temp);
                    setNome(nome_temp);
                    setCaptura_fotos(true);
                  }}
                >
                  <AddAPhoto id="icon-add-photo" />
                  <span>Capturar fotos</span>
                </button>
              </div>

              <h3 className="h3-drive">
                Fotos da Guia Médica e Carteira do Plano
              </h3>

              <h4 className="h4-drive">
                {cliente.email_whats
                  ? `Obs: O cliente enviou a guia médica por ${cliente.email_whats}`
                  : null}
              </h4>

              <div className="container-gallery-fotos-client-drive">
                {imagensCliente.map((img) => {
                  const end_imagem = `${baseUrl}/${img.caminho}`;
                  return (
                    <div className="content-gallery-fotos-client-drive">
                      <button
                        type="button"
                        onClick={() => {
                          setAmplia_foto(true);
                          setFotoAmpliada(end_imagem);
                        }}
                      >
                        <label htmlFor="img-logo-foto2">
                          <img
                            id="img-logo-foto2"
                            className="imgLogo"
                            src={end_imagem}
                            alt={img.tipo}
                          />
                        </label>
                      </button>
                    </div>
                  );
                })}
              </div>
            </TabPanel>
            <TabPanel value={aba} index={3}>
              <h3 className="h3-drive">
                Observação
              </h3>

              <div id="container-note-drive">
                <TextareaAutosize
                  id="content-note-drive"
                  rowsMin={4}
                  placeholder='Observação sobre esse pedido'
                  value={observacao}
                  onChange={(e) => {
                    e.preventDefault();
                    setObservacao(e.target.value);
                  }}
                />

                <div id="button-atualiza-pedido">
                  <button
                    type="button"
                    id="content-button-client-drive-foto-salva2"
                    onClick={() => {
                      atualizaObservacao(cliente.id);
                    }}
                  >
                    Atualizar
                  </button>
                </div>

              </div>
            </TabPanel>
          </div>
        </Container>
      </Dialog>

      <Dialog
        open={confirma_exclusao}
        onClose={() => {
          setConfirma_exclusao(false);
        }}
      >
        <DialogTitle id="alert-dialog-title">
          Você deseja remover este cliente?
        </DialogTitle>
        <DialogActions>
          <button
            type="button"
            className="btnDialogs"
            onClick={() => {
              excluiCliente();
            }}
          >
            Confirmar
          </button>
          <button
            type="button"
            className="btnDialogs"
            onClick={() => {
              setConfirma_exclusao(false);
            }}
          >
            Desistir
          </button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
