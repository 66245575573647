import React from "react";

import CloseIcon from "@mui/icons-material/Close";
import EditIcon from '@mui/icons-material/Edit';

import "./styles.css";

export default function TopicPill({ onClose, onEdit, children }) {
  return (
    <div className="topic-pill">
      <p>{children}</p>
      <div className="topic-pill-icons">
        <EditIcon
          onClick={onEdit}
          style={{ cursor: "pointer", color: "white", marginRight: "5px" }}
        />
        <CloseIcon onClick={onClose} style={{ cursor: "pointer", color: "white" }} />
      </div>
    </div>
  );
}
