import React, { useContext } from "react";
import { Button as MuiButton } from "@mui/material";

import { ColetaDomiciliarBloqueioContext } from "../../Context/ColetaDomiciarBloqueioContext";
import MotivoBloqueio from "./MotivoBloqueio";
import OpcoesBloqueio from "./OpcoesBloqueio";
import Tabela from "../../../../componentsv2/Tabela";
import { useState } from "react";

import { GridToolbarContainer, GridToolbarQuickFilter } from "@mui/x-data-grid";

//import { LinearProgress, Stack, useMediaQuery, useTheme } from "@mui/material";
//import { Box } from "@mui/system";
import CustomPagination from "../../../../componentsv2/Tabela/customPagination";
import { api } from "../../../../services/Api";
import { enqueueSnackbar } from "notistack";
//import Button from "../../../../componentsv2/Button";

export default function Controle() {
  const {
    bloqueados,
    listaBloqueios,
    setListaBloqueios,
    setAtualizaTabelaBloqueio,
    atualizaTabelaBloqueio,
  } = useContext(ColetaDomiciliarBloqueioContext);

  const desbloqueiaEmLote = async () => {
    try {
      await api.delete("/api/coletaDomiciliarBloqueio/", {
        data: { ids: listaBloqueios },
      });
      setAtualizaTabelaBloqueio(!atualizaTabelaBloqueio);
      enqueueSnackbar("Horários selecionados desbloqueados com sucesso!", {
        variant: "success",
      });
      return;
    } catch (error) {
      console.log(error);
      enqueueSnackbar("Erro ao desbloquear os horários selecionados!", {
        variant: "error",
      });
    }
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarQuickFilter
          fullWidth
          size="small"
          quickFilterParser={(searchInput) =>
            searchInput
              .split(",")
              .map((value) => value.trim())
              .filter((value) => value !== "")
          }
          sx={{ marginLeft: "1rem", marginTop: "1rem" }}
        />
        {/* <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector /> */}

        <MuiButton
          onClick={desbloqueiaEmLote}
          sx={{ marginLeft: "auto", marginRight: "1rem", marginTop: "1rem" }}
          variant="contained"
          color="error"
        >
          Desbloquear
        </MuiButton>
      </GridToolbarContainer>
    );
  }

  const columns = [
    {
      field: "hora",
      headerName: "Hora",
      headerAlign: "",
      align: "",
      flex: 1,
    },
    {
      field: "coletador",
      headerName: "Coletador",
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "motivo",
      headerName: "Motivo",
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    // {
    //   field: "remover",
    //   headerName: "Remover",
    //   type: "actions",
    //   flex: 1,
    //   headerAlign: "center",
    //   align: "center",
    //   renderCell: (params) => (
    //     <div>
    //       <Tooltip title="Abrir opções">
    //         <button
    //           type="button"
    //           className="btnOpcao"
    //           onClick={() => {
    //             setIdBloqueio(params.row.id);
    //             setModalOpcoesBloqueio(true);
    //           }}
    //         >
    //           <i className="fa fa-cog" />
    //         </button>
    //       </Tooltip>
    //     </div>
    //   ),
    // },
  ];

  return (
    <div id="container-table-drive">
      <Tabela
        columns={columns}
        rows={bloqueados}
        selectionModel={listaBloqueios}
        onRowSelectionModelChange={(model) => {
          // if (model.length === 0) {
          //   //setMostraSnackbar(false);
          // } else if (tabela === "placa") {
          //   //setMostraSnackbar(true);
          // }
          setListaBloqueios(model);
        }}
        slots={{
          toolbar: CustomToolbar,
          pagination: CustomPagination,
        }}
        checkboxSelection
      />
      {/* <table id="table-drive">
        <thead>
          <tr>
            <th align="center">Hora</th>
            <th align="center">Coletador</th>
            <th align="center">Motivo</th>
            <th align="center">Opções</th>
          </tr>
        </thead>
        <tbody>
          {bloqueados.map((row) => (
            <tr key={row.id}>
              <td align="center">{row.hora}</td>
              <td align="center">{row.coletador}</td>
              <td align="center">{row.motivo}</td>
              <td align="center">
                <Tooltip title="Abrir opções">
                  <button
                    type="button"
                    className="btnOpcao"
                    onClick={() => {
                      setIdBloqueio(row.id);
                      setModalOpcoesBloqueio(true);
                    }}
                  >
                    <i className="fa fa-cog" />
                  </button>
                </Tooltip>
              </td>
            </tr>
          ))}
        </tbody>
      </table> */}
      <MotivoBloqueio />
      <OpcoesBloqueio />
    </div>
  );
}
