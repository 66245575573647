// formata um objeto {id, hora, minutos} em uma string como "8:30"
export function formatarHorario(horario) {
  const { hora, minutos } = horario;

  return `${hora}:${minutos}${minutos === 0 ? "0" : ""}`;
}

// formata um objeto DATE do postgres para um string tipo "2021-06-05" (cinco de junho de 2021)
export function formatarData(data) {
  const ano = data.getFullYear();
  const month = data.getMonth() + 1;
  const day = data.getDate();

  return `${ano}-${month}-${day}`;
}
