import React from "react";
import Button from "../Button";
import { Modal, Typography, Box, Grid, IconButton, Divider } from "@mui/material";
import { Close } from "@mui/icons-material";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",

  bgcolor: "background.paper",
  boxShadow: 24,
};

const dividerStyle = {
  width: "100%",
  backgroundColor: "#909099",
};

export function ModalForm({ open, onClose, title, children, onSubmit }) {
  return (
    <Modal open={open} onClose={onClose}>
      <form onSubmit={onSubmit}>
        <Box sx={modalStyle}>
          <Grid
            justifyContent="space-between"
            sx={{ padding: "15px" }}
            item
            container
            spacing={0}
          >
            <Grid lg={11} sm={11} xs={10} item>
              <Typography variant="h6" sx={{ color: "black" }}>
                {title}
              </Typography>
            </Grid>
            <Grid lg={1} sm={1} xs={2} item>
              <IconButton onClick={() => onClose(false)}>
                <Close />
              </IconButton>
            </Grid>
          </Grid>
          <Divider sx={dividerStyle} />
          <Grid sx={{ padding: "30px" }} item container spacing={3}>
            {children}
          </Grid>
          <Grid sx={{ padding: "20px" }} item container justifyContent="flex-end">
            <Button type="submit" variant="contained" color="green">
              Salvar
            </Button>
          </Grid>
        </Box>
      </form>
    </Modal>
  );
}
