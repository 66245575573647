import React, { useContext } from 'react';

import { Context as PermissoesContext } from "../../../../../Context/PermissoesContext";

export default function AbasColetaDomiciliar({ aba, setAba }) {
  const { checarPermissao } = useContext(PermissoesContext);

  return (
    <div id="container-aba-ctrl-samples">
      <button
        type="button"
        id={aba === "Agenda" ? "button-aba-ctrl-samples-selected" : "Aba1"}
        className="button-aba-ctrl-samples"
        onClick={() => {
            setAba("Agenda");
          }}
      >
        Agenda
      </button>

      <button
        type="button"
        id={aba === "BI" ? "button-aba-ctrl-samples-selected" : "Aba2"}
        className="button-aba-ctrl-samples"
        onClick={() => {
            setAba("BI");
          }}
      >
        BI
      </button>

      {(checarPermissao("CDO009", false)) && (
        <button
          type="button"
          id={aba === "Controle" ? "button-aba-ctrl-samples-selected" : "Aba3"}
          className="button-aba-ctrl-samples"
          onClick={() => {
              setAba("Controle");
            }}
        >
          Controle
        </button>
      )}

      {(checarPermissao("CDO010", false)) && (
        <button
          type="button"
          id={aba === "Financeiro" ? "button-aba-ctrl-samples-selected" : "Aba4"}
          className="button-aba-ctrl-samples"
          onClick={() => {
              setAba("Financeiro");
            }}
        >
          Financeiro
        </button>
      )}
    </div>
  )
}
