import React, { memo, useContext } from "react";
import { IconButton } from "@mui/material";
import { format } from "date-fns";

import { ModalRecebimentoContext } from "../../Context/ModalRecebimentoContext";
import { RecebimentoContext } from "../../Context/RecebimentoContext";

import formatoMoeda from "../../../../../utils/formatoMoeda";

import Waiter from "../../../../../components/Backdrop";
import Tabela from "../../../../../componentsv2/Tabela";

function TabelaRecebimento() {
  const { dadosTabela } = useContext(RecebimentoContext);
  const { setId_lote, setInformacoesLote, setOpcoes, loading } = useContext(
    ModalRecebimentoContext
  );
  const handleEditClick = (row) => {
    setInformacoesLote(row);
    setId_lote(row.id);
    setOpcoes(true);
  };
  // Botão de configuração da tabela de recebimento (visão lote)
  const Config = ({ row }) => {
    return (
      <IconButton onClick={() => handleEditClick(row)}>
        <i className="fa fa-cog" style={{ color: "#003399" }} />
      </IconButton>
    );
  };

  // Colunas da tabela de recebimento (visão lote)
  const colunas_recebimento = [
    {
      field: "id",
      headerName: "ID",
      headerAlign: "center",
      width: 100,
      hide: true,
    },
    {
      field: "opcoes",
      headerName: "Opções",
      headerAlign: "center",
      width: 130,
      type: "number",
      editable: false,
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (params) => (
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
            cursor: "pointer",
          }}
        >
          <Config row={params.row} />
        </div>
      ),
    },
    // {
    //   field: "nota_fiscal",
    //   headerName: "Nota Fiscal",
    //   headerAlign: "center",
    //   flex: 1,
    //   minWidth: 120,
    //   editable: false,
    // },
    {
      field: "protocolo",
      headerName: "Protocolo",
      headerAlign: "center",
      flex: 1,
      minWidth: 120,
      editable: false,
    },
    {
      field: "lote",
      headerName: "Lote",
      headerAlign: "center",
      flex: 1,
      minWidth: 120,
      editable: false,
    },
    {
      field: "plano",
      headerName: "Plano",
      headerAlign: "center",
      flex: 1,
      minWidth: 120,
      editable: false,
    },
    {
      field: "competencia",
      headerName: "Competência",
      headerAlign: "center",
      flex: 1,
      minWidth: 120,
      editable: false,
      valueGetter: (params) => {
        const value = `${
          params.row.mes_competencia ? params.row.mes_competencia : ""
        }/${params.row.ano_competencia ? params.row.ano_competencia : ""}`;
        return value;
      },
    },
    {
      field: "qtd_guias",
      headerName: "Qtd. Guias",
      headerAlign: "center",
      type: "number",
      width: 100,
      editable: false,
    },
    {
      field: "data_envio_lote",
      headerName: "Data Envio",
      headerAlign: "center",
      width: 130,
      editable: false,
      valueFormatter: ({ value }) =>
        value ? `${format(new Date(value), "dd/MM/yyyy")}` : "",
    },
    {
      field: "valor_enviado",
      headerName: "Enviado",
      type: "number",
      headerAlign: "center",
      flex: 1,
      minWidth: 120,
      valueFormatter: ({ value }) => {
        const valor = value ? formatoMoeda(value, true, true) : "0,00";
        return valor;
      },
    },
    {
      field: "valor_recuperado",
      headerName: "Faturado",
      type: "number",
      headerAlign: "center",
      flex: 1,
      minWidth: 120,
      valueFormatter: ({ value }) => {
        const valor = value ? formatoMoeda(value, true) : "0,00";
        return valor;
      },
    },
    {
      field: "valor_liquido",
      headerName: "Líquido",
      type: "number",
      headerAlign: "center",
      flex: 1,
      minWidth: 120,
      valueFormatter: ({ value }) => {
        const valor = value ? formatoMoeda(value, true) : "0,00";
        return valor;
      },
    },
    {
      field: "valor_recebido",
      headerName: "Valor Recebido",
      type: "number",
      headerAlign: "center",
      flex: 1,
      minWidth: 120,
      valueFormatter: ({ value }) => {
        const valor = value ? formatoMoeda(value, true) : "0,00";
        return valor;
      },
    },
    {
      field: "valor_diferenca_tabela",
      headerName: "Diferença de Tabelas",
      type: "number",
      headerAlign: "center",
      flex: 1,
      minWidth: 120,
      valueFormatter: ({ value }) => {
        const valor = value ? formatoMoeda(value, true) : "0,00";
        return valor;
      },
    },
    {
      field: "valor_glosado",
      headerName: "Glosado",
      type: "number",
      headerAlign: "center",
      flex: 1,
      minWidth: 120,
      valueFormatter: ({ value }) => {
        const valor = value ? formatoMoeda(value, true) : "0,00";
        return valor;
      },
    },
    {
      field: "valor_acatado",
      headerName: "Acatado",
      type: "number",
      headerAlign: "center",
      flex: 1,
      minWidth: 120,
      valueFormatter: ({ value }) => {
        const valor = value ? formatoMoeda(value, true) : "0,00";
        return valor;
      },
    },

    {
      field: "em_analise",
      headerName: "Em recurso",
      type: "number",
      headerAlign: "center",
      flex: 1,
      minWidth: 120,
      valueFormatter: ({ value }) => {
        const valor = value ? formatoMoeda(value, true) : "0,00";
        return valor;
      },
    },
    {
      field: "por_recurso",
      headerName: "Por Recurso",
      type: "number",
      headerAlign: "center",
      flex: 1,
      minWidth: 120,
      valueFormatter: ({ value }) => {
        const valor = value ? formatoMoeda(value, true) : "0,00";
        return valor;
      },
    },
  ];

  return (
    <>
      <Tabela
        disableColumnMenu
        autoHeight
        columns={colunas_recebimento}
        rows={dadosTabela}
        pageSize={50}
        rowsPerPageOptions={[20, 30, 40, 50, 100]}
        onRowDoubleClick={(params) => {
          handleEditClick(params.row);
        }}
        initialState={{
          columns: {
            columnVisibilityModel: {
              id: false,
            },
          },
        }}
      />
      <Waiter espera={loading} />
    </>
  );
}

export default memo(TabelaRecebimento);
