import React, { useContext, useState } from "react";
import {
  Dialog,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
} from "@mui/material";
import { Close, Create, Replay } from "@mui/icons-material";

import { ModalRecebimentoContext } from "../../Context/ModalRecebimentoContext";
import { RecebimentoContext } from "../../Context/RecebimentoContext";

import CorpoModal from "../../../Componentes/CorpoModal";
import { Modal as ModalConfirmacao } from "../../../../../componentsv2/Modal";
import { useSnackbar } from "notistack";
import { Context as PermissoesContext } from "../../../../../Context/PermissoesContext";
import VisibilityIcon from "@mui/icons-material/Visibility";

export default function ModalOpcoesGerenciaLote() {
  const {
    adicionaOuRetorna,
    opcoesGerenciaLote,
    fechaModalOpcoesGerenciaLote,
    informacoesLote,
    dadosRetornoLoteRow,
  } = useContext(ModalRecebimentoContext);

  const { excluiNotaRetornoLote } = useContext(RecebimentoContext);
  const [openModalConfirmarRemocaoNota, setOpenModalConfirmarRemocaoNota] =
    useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { checarPermissao } = useContext(PermissoesContext);

  return (
    // Modal de opções de gerenciamento de lotes
    <Dialog
      maxWidth="sm"
      open={opcoesGerenciaLote}
      onClose={fechaModalOpcoesGerenciaLote}
      closeAfterTransition
    >
      <ModalConfirmacao
        confirmActionButtonColor="green"
        onClose={setOpenModalConfirmarRemocaoNota}
        open={openModalConfirmarRemocaoNota}
        onClick={() => {
          excluiNotaRetornoLote();
          setOpenModalConfirmarRemocaoNota(false);
        }}
        modalText={"Tem certeza que deseja remover esta nota?"}
      />
      <CorpoModal titulo="Opções" fechaModal={fechaModalOpcoesGerenciaLote}>
        <Paper
          style={{ display: "flex", margin: "1rem", justifyContent: "center" }}
        >
          {!dadosRetornoLoteRow?.valor_recebido ? (
            <List component="nav" aria-label="main mailbox folders">
              <ListItemButton
                onClick={() => {
                  adicionaOuRetorna("Editar");
                }}
              >
                <ListItemIcon>
                  <Create color="primary" />
                </ListItemIcon>
                <ListItemText primary="Editar recebimento" />
              </ListItemButton>
              <ListItemButton
                onClick={() => {
                  setOpenModalConfirmarRemocaoNota(true);
                }}
              >
                <ListItemIcon>
                  <Close color="secondary" />
                </ListItemIcon>
                <ListItemText primary="Excluir nota" />
              </ListItemButton>
              <ListItemButton onClick={fechaModalOpcoesGerenciaLote}>
                <ListItemIcon>
                  <Replay color="black" />
                </ListItemIcon>
                <ListItemText primary="Cancelar" />
              </ListItemButton>
            </List>
          ) : (
            <List component="nav" aria-label="main mailbox folders">
              <ListItemButton
                onClick={() => {
                  adicionaOuRetorna("Editar");
                }}
              >
                <ListItemIcon>
                  <VisibilityIcon color="primary" />
                </ListItemIcon>
                <ListItemText primary="Visualizar Recebimento" />
              </ListItemButton>
            </List>
          )}
        </Paper>
      </CorpoModal>
    </Dialog>
  );
}
