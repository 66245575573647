import React, { useEffect, useState } from "react";
import { format } from "date-fns";
import {
  Card,
  CardContent,
  Grid,
  Paper,
  Typography
} from '@mui/material';

import { Bar } from "react-chartjs-2";

import InputData from "../../../components/InputData";

import { api } from "../../../services/Api";

import "./styles.css";

import "chartjs-plugin-datalabels";

export default function Bi() {
  const [qtdFormularios, setQtdFormularios] = useState();
  const [qtdNotificacao, setQtdNotificacoes] = useState();
  const [qtdNaonotificados, setQtdNaonotificados] = useState();
  const [novaData, setNovaData] = useState(format(new Date(), "yyyy-MM-dd"));

  // eslint-disable-next-line
  const [tipoFormularios, setTipoFormularios] = useState(["Online", "QRCode"]);
  // eslint-disable-next-line
  const [tipoNotificacoes, setTipoNotificacoes] = useState(["PCR", "IGM", "IGG", "IGM / IGG"]);
  // eslint-disable-next-line
  const [tipoNaoNotificados, setNaoNotificados] = useState(["PCR", "IGM", "IGG", "IGM / IGG"]);
  const [FormulariosPorTipos, setFormulariosPorTipos] = useState([]);
  const [naoNotificadosTipo, setNaoNotificadosTipo] = useState([]);
  const [notificacoesPorTipo, setNotificacoesPorTipo] = useState([]);
  const [atualizar, setAtualizar] = useState(true);

  function zeraDados() {
    setQtdFormularios(0);
    setQtdNotificacoes(0);
    setQtdNaonotificados(0);
    setFormulariosPorTipos([]);
    setNaoNotificadosTipo([]);
    setNotificacoesPorTipo([]);
  }

  function selecionaData(data) {
    setNovaData("");
    setNovaData(data);
    setAtualizar(true);
  }

  function capturaNotificaçoesPorTipo(notificacoes) {
    const auxiliar = [];
    notificacoes.forEach(n => {
      if (n.tipo_notificacao === "RT-PCR") {
        auxiliar[0] = Number(n.qtd_por_tipo || 0);
      } else if (n.tipo_notificacao === "IgM") {
        auxiliar[1] = Number(n.qtd_por_tipo || 0);
      } else if (n.tipo_notificacao === "IgG") {
        auxiliar[2] = Number(n.qtd_por_tipo || 0);
      } else {
        auxiliar[3] = Number(n.qtd_por_tipo || 0);
      }
    });
    return auxiliar;
  }

  function capturaFormulariosPorTipo(formulario) {
    const auxiliar = [0, 0];
    formulario.forEach(f => {
      if (f.usuario !== null) {
        auxiliar[0] += Number(f.qtd_formularios || 0);
      } else {
        auxiliar[1] = Number(f.qtd_formularios || 0);
      }
    });
    return auxiliar;
  }

  useEffect(() => {
    async function carregarDados() {
      zeraDados();
      try {
        const responseQtdFormularios = await api.get(`api/formularioCovid/bi/quantidadeDeFormularios/${novaData}`);
        setQtdFormularios(responseQtdFormularios.data.qtd_por_dia);

        const responseQtdNotificacoesTipo = await api.get(`api/formularioCovid/bi/notificacoesPorDia/${novaData}`);

        setQtdNotificacoes(responseQtdNotificacoesTipo.data[1].qtd_notificados || 0);
        setQtdNaonotificados(responseQtdNotificacoesTipo.data[0].qtd_notificados || 0);

        const responseNotificadoPorTipo = await api.get(`api/formularioCovid/bi/notificadoPorTipo/${novaData}`);
        setNotificacoesPorTipo(capturaNotificaçoesPorTipo(responseNotificadoPorTipo.data));

        const responseNaoNotificadoPorTipo = await api.get(
          `api/formularioCovid/bi/naoNotificadoPorTipo/${novaData}`
        );
        setNaoNotificadosTipo(capturaNotificaçoesPorTipo(responseNaoNotificadoPorTipo.data));

        const responseFormulariosPorTipo = await api.get(`api/formularioCovid/bi/FormularioPorTipo/${novaData}`);
        setFormulariosPorTipos(capturaFormulariosPorTipo(responseFormulariosPorTipo.data));
      } catch (error) {
        console.log(error);
      }
    }
    carregarDados();
    setAtualizar(false);
    // eslint-disable-next-line
  }, [atualizar]);

  const pallet = [
    "#8a50ed",
    "#15f2a8",
    "#db8f02",
    "#a51738",
    "#167275",
    "#db9462",
    "#dd4b81",
    "#e0c164",
    "#bd00f2",
    "#23fc88",
    "#e80d6c",
    "#7D9FF5",
    "#FCD92B",
    "#74d604",
    "#3a419e",
  ];

  const GraficoQuantidadePorTipo = {
    labels: tipoFormularios,
    datasets: [
      {
        label: "Amostras",
        backgroundColor: ["#63F557", "#42A5F5", "#FF7A43"],
        data: FormulariosPorTipos,
      },
    ],
  };

  const GraficoNotificacoesPorTipo = {
    labels: tipoNotificacoes,
    datasets: [
      {
        label: "Notificacoes",
        backgroundColor: pallet,
        data: notificacoesPorTipo,
      },
    ],
  };

  const GraficoNaoNotificadosPorTipo = {
    labels: tipoNaoNotificados,
    datasets: [
      {
        label: "Amostras",
        backgroundColor: pallet,
        data: naoNotificadosTipo,
      },
    ],
  };
  return (
    <div>
      <Paper style={{ height: "6rem" }} display="flex">
        <div className="data" style={{ marginLeft: "3rem", paddingTop:"1rem" }}>
          <InputData
            labelText="Selecione a data"
            data={novaData}
            acao={() => selecionaData()}
          />
        </div>
      </Paper>
      <Grid container spacing={1} justify="center" alignItems="center">
        <Grid item>
          <Card className="card">
            <CardContent>
              <Typography variant="h5" component="h1" style={{ textAlign: "center" }}>
                {qtdFormularios || 0}
              </Typography>
              <Typography variant="subtitle1" component="h1" style={{ textAlign: "center" }}>
                Formulários
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item>
          <Card className="card">
            <CardContent>
              <Typography variant="h5" component="h1" style={{ textAlign: "center" }}>
                {qtdNotificacao || 0}
              </Typography>
              <Typography variant="subtitle1" component="h1" style={{ textAlign: "center" }}>
                Notificados
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid>
          <Card className="card">
            <CardContent>
              <Typography variant="h5" component="h1" style={{ textAlign: "center" }}>
                {qtdNaonotificados || 0}
              </Typography>
              <Typography variant="subtitle1" component="h1" style={{ textAlign: "center" }}>
                Não Notificados
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <div>
        <Paper className="grafico">
          <Bar
            data={GraficoQuantidadePorTipo}
            options={{
              showAllTooltips: true,
              plugins: {
                datalabels: { display: "auto", anchor: "end", align: "end", fontSize: 20 },
              },
              responsive: true,
              maintainAspectRatio: false,
              scales: {
                yAxes: [
                  {
                    ticks: {
                      beginAtZero: true,
                      min: 0,
                    },
                  },
                ],
              },

              title: {
                padding: 25,
                display: true,
                text: "Formulários por tipo",
                fontSize: 20,
              },
              legend: {
                display: false,
                position: "bottom",
              },
            }}
          />
        </Paper>
        <Paper className="grafico">
          <Bar
            data={GraficoNotificacoesPorTipo}
            options={{
              showAllTooltips: true,
              plugins: {
                datalabels: { display: "auto", anchor: "end", align: "end", fontSize: 20 },
              },
              responsive: true,
              maintainAspectRatio: false,
              scales: {
                yAxes: [
                  {
                    ticks: {
                      beginAtZero: true,
                      min: 0,
                    },
                  },
                ],
              },

              title: {
                padding: 25,
                display: true,
                text: "Notificações por tipo",
                fontSize: 20,
              },
              legend: {
                display: false,
                position: "bottom",
              },
            }}
          />
        </Paper>
        <Paper className="grafico">
          <Bar
            data={GraficoNaoNotificadosPorTipo}
            options={{
              showAllTooltips: true,
              plugins: {
                datalabels: { display: "auto", anchor: "end", align: "end", fontSize: 20 },
              },
              responsive: true,
              maintainAspectRatio: false,
              scales: {
                yAxes: [
                  {
                    ticks: {
                      beginAtZero: true,
                      min: 0,
                    },
                  },
                ],
              },

              title: {
                padding: 25,
                display: true,
                text: "Não notificados por Tipo",
                fontSize: 20,
              },
              legend: {
                display: false,
                position: "bottom",
              },
            }}
          />
        </Paper>
      </div>
    </div>
  );
}
