import React, { useCallback, useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  TextField,
  List,
  InputLabel,
  TextareaAutosize,
  Select,
  MenuItem,
  ListItemButton,
  Container,
} from '@mui/material';
import { Clear, Create, Replay } from '@mui/icons-material';
import axios from "axios";

import useUser from "../../hooks/useUser";

import Header from "../../components/Header";
import Waiter from "../../components/Backdrop";

import SalvaLog from "../../utils/SalvaLog";

import Tabela from "./Tabela";

import "./styles.css";

import { api, baseUrl } from "../../services/Api";

import { Context as PermissoesContext } from "../../Context/PermissoesContext";

export default function AtualizacoesApp() {
  const history = useHistory();
  const usuario = useUser();

  const { enqueueSnackbar } = useSnackbar();
  const { checarPermissao } = useContext(PermissoesContext);

  useEffect(() => {
    async function inicio() {
      try {
        const {
          data: {
            permitido: {
              0: { id },
            },
          },
        } = await api.get(
          `/v2/controleAcesso/permissaoVisualizaModulo?username=${usuario}&&codigo=AAP001`
        );

        return id;
      } catch (e) {
        return history.push("/home");
      }
    }
    inicio();
  }, [history, usuario]);

  const [aba, setAba] = useState("Unidades");
  const [vetor, setVetor] = useState([]);
  const [espera, setEspera] = useState(false);
  const [abreOpcoes, setAbreOpcoes] = useState(false);
  const [abreUnidade, setAbreUnidade] = useState(false);
  const [abreExame, setAbreExame] = useState(false);
  const [confirmaExcluir, setConfirmaExcluir] = useState(false);

  const [atualizar, setAtualizar] = useState(false);

  const [id, setId] = useState("");

  const [unidade, setUnidade] = useState("");
  const [endereco, setEndereco] = useState("");
  const [pontoReferencia, setPontoReferencia] = useState("");
  const [horarioAtendimento, setHorarioAtendimento] = useState("");
  const [horarioColeta, setHorarioColeta] = useState("");
  const [telefone, setTelefone] = useState("");
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [kids, setKids] = useState("Não possui");
  const [foto, setFoto] = useState(null);
  const [conexao, setConexao] = useState(true);
  const [exibe_no_app, setExibe_no_app] = useState(true);

  const [fotoAtual, setFotoAtual] = useState(null);
  const [arquivoImagem, setArquivoImagem] = useState(null);

  const [nome, setNome] = useState("");
  const [exames, setExames] = useState("");
  const [exame, setExame] = useState("");
  const [material, setMaterial] = useState("");
  const [instrucoes, setInstrucoes] = useState("");
  const [entrega, setEntrega] = useState("");

  useEffect(() => {
    async function iniciar() {
      try {
        setEspera(true);
        if (aba === "Unidades") {
          const resultadoUnidades = await api.get(`v2/geral/unidade`);
          setVetor(resultadoUnidades.data.unidade);
        } else {
          const resultadoExames = await api.get(`v2/geral/exame`);
          setVetor(resultadoExames.data.exame);
          setExames(resultadoExames.data.exame);
        }
      } catch (error) {
        enqueueSnackbar("Falha no carregamento de dados", { variant: "error" });
      }
      setEspera(false);
    }
    iniciar();
  }, [enqueueSnackbar, aba, atualizar]);

  const carregaImagem = async e => {
    e.preventDefault();
    if (e.target.files[0]) {
      setArquivoImagem(e.target.files[0]);
      setFotoAtual(URL.createObjectURL(e.target.files[0]));
    }
  };

  function cancelaUpload() {
    setArquivoImagem(null);
    setFotoAtual(null);
  }

  const upload = async id_unidade => {
    if (arquivoImagem) {
      setEspera(true);
      const formData = new FormData();
      formData.append("foto", arquivoImagem);

      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };

      try {
        const result = await axios.post(`${baseUrl}/api/uploadProxy`, formData, config);
        await api.put(`/v2/geral/uploadUnidade/${id_unidade}`, {
          foto: result.data,
        });
        SalvaLog(`Salvou foto da unidade de ID ${id_unidade}`, "Atualizações App");
        enqueueSnackbar("Foto salva com sucesso", { variant: "success" });
        cancelaUpload();
      } catch (error) {
        enqueueSnackbar("Falha no carregamento da foto", { variant: "error" });
      }
      setEspera(false);
    }
  };

  function zeraUnidade() {
    setUnidade("");
    setEndereco("");
    setPontoReferencia("");
    setHorarioAtendimento("");
    setHorarioColeta("");
    setTelefone("");
    setId("");
    setLongitude("");
    setLatitude("");
    setKids("Não possui");
    setFoto(null);
    setConexao(true);
    setExibe_no_app(true);
  }

  async function adicionaUnidade() {
    setEspera(true);
    try {
      const informacoes = await api.get(`v2/geral/unidade/${id}`);
      setUnidade(informacoes.data.unidade.nome);
      setEndereco(informacoes.data.unidade.endereco);
      setPontoReferencia(informacoes.data.unidade.pontoreferencia);
      setHorarioAtendimento(informacoes.data.unidade.atendimento);
      setHorarioColeta(informacoes.data.unidade.horariocoleta);
      setTelefone(informacoes.data.unidade.telefone);
      setLongitude(informacoes.data.unidade.longitude);
      setLatitude(informacoes.data.unidade.latitude);
      setKids(informacoes.data.unidade.kids);
      setConexao(informacoes.data.unidade.conexao);
      setExibe_no_app(informacoes.data.unidade.exibe_no_app);

      if (informacoes.data.unidade.foto) {
        setFoto(`${baseUrl}/${informacoes.data.unidade.foto}`);
      }
    } catch (error) {
      enqueueSnackbar("Falha no carregamento de dados", { variant: "error" });
    }
    setEspera(false);
  }

  async function salvaUnidade() {
    if (unidade) {
      try {
        const dados = {
          nome: unidade,
          endereco,
          pontoreferencia: pontoReferencia,
          atendimento: horarioAtendimento,
          horariocoleta: horarioColeta,
          telefone,
          longitude,
          latitude,
          kids,
          conexao,
          exibe_no_app
        };
        if (id) {

          await api.put(`v2/geral/unidade/${id}`, dados);
          upload(id);

          enqueueSnackbar("Unidade atualizada", { variant: "success" });
        } else {
          const result = await api.post(`v2/geral/unidade`, dados);

          upload(result.data.unidade.id);

          enqueueSnackbar("Unidade adicionada", { variant: "success" });
        }
        setAtualizar(prevState => !prevState);
      } catch (error) {
        enqueueSnackbar("Falha na operação", { variant: "error" });
      }
      cancelaUpload();
      zeraUnidade();
      setAbreUnidade(false);
    } else {
      enqueueSnackbar("Nome da unidade está vazio", { variant: "warning" });
    }
  }

  function zeraExame() {
    setExame("");
    setMaterial("");
    setInstrucoes("");
    setEntrega("");
    setId("");
  }

  const procuraExames = useCallback(
    async nome_filtro => {
      setNome(nome_filtro);
      const filtro = exames.filter(ex => ex.nome.includes(nome_filtro));
      setVetor(filtro);
    },
    [exames]
  );

  async function adicionaExame() {
    setEspera(true);
    try {
      const informacoes = await api.get(`v2/geral/exame/${id}`);

      setExame(informacoes.data.exame.nome);
      setMaterial(informacoes.data.exame.material);
      setInstrucoes(informacoes.data.exame.instrucoes);
      setEntrega(informacoes.data.exame.entrega);
    } catch (error) {
      enqueueSnackbar("Falha no carregamento de dados", { variant: "error" });
    }
    setEspera(false);
  }

  async function salvaExame() {
    if (exame) {
      try {
        if (id) {
          await api.put(`v2/geral/exame/${id}`, {
            nome: exame,
            material,
            instrucoes,
            entrega,
          });
          enqueueSnackbar("Exame atualizado", { variant: "success" });
        } else {
          await api.post(`v2/geral/exame`, {
            nome: exame,
            material,
            instrucoes,
            entrega,
          });
          enqueueSnackbar("Exame adicionado", { variant: "success" });
        }
        setAtualizar(prevState => !prevState);
      } catch (error) {
        enqueueSnackbar("Falha na operação", { variant: "error" });
      }

      zeraExame();
      setAbreExame(false);
    } else {
      enqueueSnackbar("O nome do exame está vazio", { variant: "warning" });
    }
  }

  function cancelaExclusao() {
    setId("");
    setConfirmaExcluir(false);
  }

  async function ativaExclusao() {
    setEspera(true);
    try {
      if (aba === "Unidades") {
        await api.delete(`v2/geral/unidade/${id}`);
      } else {
        await api.delete(`v2/geral/exame/${id}`);
      }
      setAtualizar(prevState => !prevState);
      enqueueSnackbar(`${aba === "Unidades" ? "Unidade excluída" : "Exame excluído"}`, {
        variant: "success",
      });
    } catch (error) {
      enqueueSnackbar(
        `Não foi possível excluir ${aba === "Unidades" ? " a unidade" : " o exame"}`,
        { variant: "error" }
      );
    }
    setEspera(false);
    setId("");
    setConfirmaExcluir(false);
  }

  return (
    <>
      <Header titulo="Exame Respiratório" />
      <Container>
        <div id="container-aba-ctrl-samples">
          <button
            type="button"
            id={aba === "Unidades" ? "button-aba-ctrl-samples-selected" : "Aba1"}
            className="button-aba-ctrl-samples"
            onClick={() => {
              setAba("Unidades");
            }}
          >
            Unidades
          </button>
          <button
            type="button"
            id={aba === "Exames" ? "button-aba-ctrl-samples-selected" : "Aba1"}
            className="button-aba-ctrl-samples"
            onClick={() => {
              setAba("Exames");
            }}
          >
            Exames
          </button>
        </div>
        <div id="content-form-ctrl-samples">
          <button
            className="btnAgendar"
            type="button"
            onClick={() => {
              if (checarPermissao("AAP002")) {
                if (aba === "Unidades") {
                  setAbreUnidade(true);
                } else {
                  setAbreExame(true);
                }
              } else {
                enqueueSnackbar(
                  `Você não possui autorização para criar ${
                    aba === "Unidades" ? " unidades" : " exames"
                  }`,
                  { variant: "error" }
                );
              }
            }}
          >
            Adicionar
          </button>
          {aba === "Exames" ? (
            <div className="pesquisa">
              <TextField
                label="Pesquisar"
                placeholder="Informe o nome do exame"
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                onChange={e => procuraExames(e.target.value.toUpperCase())}
                value={nome}
              />
            </div>
          ) : null}
        </div>

        <Tabela vetor={vetor} setId={setId} setAbreOpcoes={setAbreOpcoes} />

      <Waiter espera={espera} />

      <Dialog
        open={abreOpcoes}
        onClose={() => setAbreOpcoes(false)}
        aria-labelledby="simple-dialog-title"
      >
        <DialogTitle id="selecionaOpcao">Selecione uma opção</DialogTitle>
        <List className="opList">
          <ListItemButton
            key="101"
            onClick={() => {
              if (checarPermissao("AAP003")) {
                if (aba === "Unidades") {
                  adicionaUnidade();
                  setAbreUnidade(true);
                } else {
                  adicionaExame();
                  setAbreExame(true);
                }
              } else {
                enqueueSnackbar(
                  `Você não possui autorização para editar ${
                    aba === "Unidades" ? " unidades" : " exames"
                  }`,
                  { variant: "error" }
                );
              }

              setAbreOpcoes(false);
            }}
          >
            <Create color="primary" />
            <InputLabel id="itemLista"> Editar</InputLabel>
          </ListItemButton>

          <ListItemButton
            key="102"
            onClick={() => {
              setAbreOpcoes(false);
              if (checarPermissao("AAP004")) {
                setConfirmaExcluir(true);
              } else {
                enqueueSnackbar(
                  `Você não possui autorização para excluir ${
                    aba === "Unidades" ? " unidades" : " exames"
                  }`,
                  { variant: "error" }
                );
              }
            }}
          >
            <Clear color="error" />
            <InputLabel id="itemLista"> Excluir</InputLabel>
          </ListItemButton>

          <ListItemButton key="103" onClick={() => setAbreOpcoes(false)}>
            <Replay />
            <InputLabel id="itemLista"> Voltar</InputLabel>
          </ListItemButton>
        </List>
      </Dialog>

      <Dialog
        open={abreUnidade}
        fullWidth
        maxWidth="md"
        onClose={() => {
          setAbreUnidade(false);
          zeraUnidade();
        }}
      >
        <DialogTitle id="alert-dialog-title">
          {id ? "Informações da" : "Nova "} Unidade
        </DialogTitle>
        <div className="container-information-unit">
          <div className="content-information-unit-image">
            <img
              id="img-unidade-foto"
              className="imgUnidade"
              src={
                fotoAtual ||
                foto ||
                "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS0su1iftTQ4Q14MqUMUjR4Gl1jgnY04yIqnfJifb5ArKJltmgUJ7pzzNzbnw9krJU-7Ok&usqp=CAU"
              }
              alt="Unidade DNA Center"
            />
            {!fotoAtual ? (
              <label id="btn-unidade-foto" htmlFor="foto">
                Alterar
                <input
                  type="file"
                  id="foto"
                  name="foto"
                  accept="image/png, image/jpeg"
                  onChange={carregaImagem}
                />
              </label>
            ) : (
              <button
                type="button"
                id="btn-cancel-update-photo"
                onClick={() => cancelaUpload()}
              >
                Cancelar
              </button>
            )}
          </div>
          <div className="content-information-unit">
            <TextField
              fullWidth
              label="Unidade"
              value={unidade}
              onChange={e => {
                e.preventDefault();
                setUnidade(e.target.value);
              }}
            />
          </div>
          <div className="content-information-unit">
            <TextField
              fullWidth
              label="Endereço"
              value={endereco}
              onChange={e => {
                e.preventDefault();
                setEndereco(e.target.value);
              }}
            />
          </div>
          <div className="content-information-unit">
            <TextField
              fullWidth
              label="Ponto de Referência"
              value={pontoReferencia}
              onChange={e => {
                e.preventDefault();
                setPontoReferencia(e.target.value);
              }}
            />
          </div>
          <div className="content-information-unit">
            <TextField
              fullWidth
              label="Horário de Atendimento"
              value={horarioAtendimento}
              onChange={e => {
                e.preventDefault();
                setHorarioAtendimento(e.target.value);
              }}
            />
          </div>
          <div className="content-information-unit">
            <TextField
              fullWidth
              label="Horário de Coleta"
              value={horarioColeta}
              onChange={e => {
                e.preventDefault();
                setHorarioColeta(e.target.value);
              }}
            />
          </div>
          <div className="content-information-unit-short">
            <div className="content-information-unit">
              <TextField
                label="Telefone"
                value={telefone}
                onChange={e => {
                  e.preventDefault();
                  setTelefone(e.target.value);
                }}
              />
            </div>
            <div className="content-information-unit">
              <TextField
                label="Latitude"
                value={latitude}
                onChange={e => {
                  e.preventDefault();
                  setLatitude(e.target.value);
                }}
              />
            </div>
            <div className="content-information-unit">
              <TextField
                label="Longitude"
                value={longitude}
                onChange={e => {
                  e.preventDefault();
                  setLongitude(e.target.value);
                }}
              />
            </div>
          </div>
          <div className="content-information-unit-short">
            <div className="content-information-unit">
              <InputLabel id="demo-simple-select-label">Possui Kids?</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={kids}
                onChange={e => setKids(e.target.value)}
                style={{ width: 180 }}
              >
                <MenuItem value="Não possui">Não possui</MenuItem>
                <MenuItem value="Espaço Kids">Espaço Kids</MenuItem>
                <MenuItem value="Sala de Coleta Kids">Sala de Coleta Kids</MenuItem>
                <MenuItem value="Espaço Kids/Sala de Coleta Kids">
                  Espaço Kids/Sala de Coleta Kids
                </MenuItem>
              </Select>
            </div>
            <div className="content-information-unit">
              <input
                id="exibe_no_app"
                className="input-check-samples"
                type="checkbox"
                checked={exibe_no_app}
                onClick={() => {
                  setExibe_no_app(prevState => !prevState);
                }}
              />
              <span
                id="label-exibe_no_app"
                className="label-add-samples"
                htmlFor="exibe_no_app"
              >
                {" Exibe no App"}
              </span>
            </div>
            <div className="content-information-unit">
              <input
                id="conexao"
                className="input-check-samples"
                type="checkbox"
                checked={conexao}
                onClick={() => {
                  setConexao(prevState => !prevState);
                }}
              />
              <span
                id="label-Conexao"
                className="label-add-samples"
                htmlFor="Conexao"
              >
                {" Exibe no Conexão"}
              </span>
            </div>
          </div>

        </div>

        <DialogActions>
          <button type="button" className="btnDialogs" onClick={() => salvaUnidade()}>
            Salvar
          </button>
          <button
            type="button"
            className="btnDialogs"
            onClick={() => {
              setAbreUnidade(false);
              zeraUnidade();
            }}
          >
            Cancelar
          </button>
        </DialogActions>
      </Dialog>

      <Dialog // Exame
        open={abreExame}
        fullWidth
        maxWidth="md"
        onClose={() => {
          setAbreExame(false);
          zeraExame();
        }}
      >
        <DialogTitle id="alert-dialog-title">
          {id ? "Informações do" : "Novo "} Exame
        </DialogTitle>
        <div className="container-information-unit">
          <div className="content-information-unit">
            <TextField
              fullWidth
              label="Exame"
              value={exame}
              onChange={e => {
                e.preventDefault();
                setExame(e.target.value);
              }}
            />
          </div>
          <div className="content-information-unit">
            <TextField
              fullWidth
              label="Material"
              value={material}
              onChange={e => {
                e.preventDefault();
                setMaterial(e.target.value);
              }}
            />
          </div>
          <div className="content-information-unit">
            <InputLabel>Instruções</InputLabel>
            <TextareaAutosize
              id="textarea-information-unit"
              rowsMin={3}
              placeholder="Instruções sobre o exame"
              value={instrucoes}
              onChange={e => {
                e.preventDefault();
                setInstrucoes(e.target.value);
              }}
            />
          </div>
          <div className="content-information-unit">
            <TextField
              fullWidth
              label="Entrega"
              value={entrega}
              onChange={e => {
                e.preventDefault();
                setEntrega(e.target.value);
              }}
            />
          </div>
        </div>

        <DialogActions>
          <button type="button" className="btnDialogs" onClick={() => salvaExame()}>
            Salvar
          </button>
          <button
            type="button"
            className="btnDialogs"
            onClick={() => {
              setAbreExame(false);
              zeraExame();
            }}
          >
            Cancelar
          </button>
        </DialogActions>
      </Dialog>

      <Dialog // confirma Excluir
        id="dialog-confirm-exclusion"
        open={confirmaExcluir}
        onClose={() => cancelaExclusao()}
      >
        <DialogTitle id="alert-dialog-title">Você realmente deseja excluir?</DialogTitle>

        <div id="container-buttons-dialog-add-samples">
          <button
            type="button"
            className="button-save-dialog-add-samples"
            onClick={() => ativaExclusao()}
          >
            Confirmar
          </button>

          <button
            type="button"
            className="button-delete-dialog-add-samples"
            onClick={() => cancelaExclusao()}
          >
            Desistir
          </button>
        </div>
      </Dialog>
      </Container>
    </>
  );
}
