import React, { useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";

import { Button, Container, Grid, Paper, Modal } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import LockOpenIcon from "@mui/icons-material/LockOpen";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import SettingsIcon from '@mui/icons-material/Settings';

import AtualizaDadosModal from "../ModalAlterar/index";

import { Context as PermissoesContext } from "../../../Context/PermissoesContext";

export default function Config({ row }) {
  const [open, setOpen] = useState(false);
  const [abrir, setAbrir] = useState(false);
  const { checarPermissao } = useContext(PermissoesContext);
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  function ModalOption(props) {
    // eslint-disable-next-line
    const { cod_usu } = props.row;

    function abrirPermissoes() {
      if (!checarPermissao("CU004")) {
        enqueueSnackbar(
          "Você não possui permissão para gerenciar as permissões de usuários",
          {
            variant: "error",
          }
        );
      } else if (props.row.cod_usu) {
          history.push({
            pathname: "/controleAcesso",
            state: { username: props.row },
          });
        } else {
          enqueueSnackbar("Este colaborador não possui um nome de usuário inserido.", {
            variant: "error",
          });
        }
    }

    function abreModalEdicao() {
      if (!checarPermissao("CU003")) {
        enqueueSnackbar("Você não possui permissão para alterar os dados dos usuários", {
          variant: "error",
        });
      } else {
        setAbrir(true);
      }
    }

    return (
      <Container className="options-container" maxWidth="xs">
        <Paper className="options-paper" elevation={2}>
          <Grid
            container
            alignItems="center"
            justifyContent="center"
            style={{ padding: "1rem 1rem" }}
          >
            <Grid item sm={12}>
              <Button onClick={abreModalEdicao}>
                <div className="icon-modal-options">
                  <EditIcon />
                </div>
                Editar usuário
              </Button>
              <AtualizaDadosModal
                abrir={abrir}
                setAbrir={setAbrir}
                usuario={cod_usu}
              />
            </Grid>
            <Grid item sm={12}>
              <Button onClick={abrirPermissoes}>
                <div className="icon-modal-options">
                  <LockOpenIcon />
                </div>
                Definir Permissões
              </Button>
            </Grid>
            <Grid item sm={12}>
              <Button onClick={() => setOpen(false)}>
                <div className="icon-modal-options">
                  <KeyboardReturnIcon />
                </div>
                Voltar
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Container>
    );
  }

  return (
    <div>
      <Button
        onClick={() => {
          setOpen(true);
        }}
      >
        <SettingsIcon />
      </Button>
      <Modal open={open} onClose={() => setOpen(false)}>
        <ModalOption row={row} />
      </Modal>
    </div>
  );
}
