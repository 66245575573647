import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useSnackbar } from "notistack";
import SettingsIcon from '@mui/icons-material/Settings';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import EditIcon from "@mui/icons-material/Edit";
import { Button, Container, Grid, Modal, Paper } from "@mui/material";

import { Context as PermissoesContext } from "../../../../Context/PermissoesContext";

export default function Opcoes({ row }) {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { checarPermissao } = useContext(PermissoesContext);
  const [open, setOpen] = useState(false);

  function ModalOption(props) {
    const handleClickVisibility = () => {
      if (!checarPermissao("AL013")) {
        enqueueSnackbar("É necessário a permissão AL013", {
          variant: "warning",
        });
      } else {
        history.push({
          pathname: "/almoxarifado/transferencia/editar",
          state: { dados: props.row },
        });
      }
    };

    const handleClickEdit = () => {
      if (!checarPermissao("AL013")) {
        enqueueSnackbar("É necessário a permissão AL013", {
          variant: "warning",
        });
      } else {
        history.push({
          pathname: "/almoxarifado/transferencia/criar",
          state: { dados: props.row },
        });
      }
    };
    return (
      <Container className="options-container" maxWidth="xs">
        <Paper className="options-paper" elevation={2}>
          <Grid container style={{ padding: "1rem 1rem" }}>
            {row.status !== "Pendente" && (
              <Grid item sm={12}>
                <Button
                  onClick={() => handleClickVisibility()}
                  variant="standard"
                  startIcon={<VisibilityIcon />}
                >
                  Visualizar Transferência
                </Button>
              </Grid>
            )}
            {row.status === "Pendente" && (
              <Grid item sm={12}>
                <Button
                  onClick={() => handleClickEdit()}
                  variant="standard"
                  startIcon={<EditIcon />}
                >
                  Editar Transferência
                </Button>
              </Grid>
            )}
            <Grid item sm={12}>
              <Button
                onClick={() => setOpen(false)}
                variant="standard"
                startIcon={<ArrowBackIcon />}
              >
                Voltar
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Container>
    );
  }

  return (
    <>
      <Button style={{ color: "#000" }} onClick={() => setOpen(true)}>
        <SettingsIcon />
      </Button>
      <Modal open={open} onClose={() => setOpen(false)}>
        <ModalOption row={row} />
      </Modal>
    </>
  );
}
