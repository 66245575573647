import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";

import Tabela from "../Tabela";
import Header from "../../../components/Header";

import { api } from "../../../services/Api";

import "../styles.css";

export default function MapaCurvas() {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [mapas, setMapas] = useState(false);
  const [atualizarTabela, setAtualizarTabela] = useState(false);
  const data_inicio = new Date();
  const data_fim= new Date();
  const unidade = 40;
  const placeholderUnidadeButton = "Unidade Matriz";
  const situacao ="pendente";

  function compare(a, b) {
    if (a.coletas[0].hora < b.coletas[0].hora) {
      return -1;
    }
    if (a.coletas[0].hora > b.coletas[0].hora) {
      return 1;
    }
    return 0;
  }

  async function pegarMapas() {
    try {
      const {
        data: { mapa },
      } = await api.post("/v2/mapaCurvas/mapas", {
        situacao,
        data_inicio: data_inicio.setHours(0, 0, 0, 0),
        data_fim: data_fim.setHours(23, 59, 59, 59),
        id_unidade: Number(unidade),
      });
      mapa.sort(compare);
      setMapas(mapa);
    } catch (error) {
      enqueueSnackbar("Erro ao encontrar os mapas!", {
        variant: "error",
        autoHideDuration: 3000,
      });
      // console.log(error);
    }
  }


  useEffect(() => {
    pegarMapas();
    // eslint-disable-next-line
  }, [atualizarTabela]);


  return (
    <>
      <Header titulo="Mapa de Curvas" usuario="DNA Center" history={history} />

      <div>
        <div id="unidade">
          <h3 id="unidadeNome">{placeholderUnidadeButton}</h3>
        </div>
        <div>
          <Tabela
            setAtualizarTabela={setAtualizarTabela}
            visaoTelevisao
            mapas={mapas}
            unidade={unidade}
          />
        </div>
      </div>

    </>
  );
}
