import React, { useContext, useState } from "react";
import { Search, Clear, Replay, Create } from "@mui/icons-material";
import { format, parseISO } from "date-fns";
import { useSnackbar } from "notistack";
import {
  IconButton,
  Button,
  InputLabel,
  Select,
  MenuItem,
  Dialog,
  DialogActions,
  DialogTitle,
  List,
  ListItem,
  Grid,
  TextField,
  Tooltip,
  FormControl,
} from "@mui/material";

import SalvaLog from "../../../utils/SalvaLog";
import Waiter from "../../../components/Backdrop";
import InputData from "../../../components/InputData";
import DialogAviso from "../../../components/DialogAviso";

import { api } from "../../../services/Api";

import "./styles.css";

import { Context as PermissoesContext } from "../../../Context/PermissoesContext";

export default function Formulario(props) {
  const { enqueueSnackbar } = useSnackbar();

  const { checarPermissao } = useContext(PermissoesContext);

  const {
    id,
    usuario,
    opcao,
    setOpcao,
    novaData,
    vetorHora,
    setVetorHora,
    setNovaData,
    status,
    menuStatus,
    setMenuStatus,
    setExibeModalLimites,
    setAtualizaTabela,
  } = props;

  const [novaHora, setNovaHora] = useState("");

  const [codigoPedido, setCodigoPedido] = useState("");
  const [nome_cliente, setNome_cliente] = useState("");
  const [prioridade, setPrioridade] = useState("Não");

  const [aviso, setAviso] = useState(false);
  const [msg, setMsg] = useState("");

  const [espera, setEspera] = useState(false);
  const [confirmar, setConfirmar] = useState(false);
  const [reagendamento, setReagendamento] = useState(false);

  const [dadosCliente, setDadosCliente] = useState("");
  const [codigo_pedido, setCodigo_pedido] = useState(null);
  const [posto_pedido, setPosto_pedido] = useState("");

  async function selecionaData(data) {
    setNovaData(data);
  }

  async function buscarCliente() {
    setNome_cliente("");
    try {
      const codigoDividido = codigoPedido.trim().split("-");

      // SalvaLog(`Pesquisou o pedido ${codigoPedido}`, "Agendamentos");

      if (codigoDividido.length < 2) {
        setCodigoPedido("");
        enqueueSnackbar("Código do pedido inválido", { variant: "error" });
      } else {
        setEspera(true);

        const cliente = await api.post("/api/softlab/pedido", {
          codigo: parseInt(codigoDividido[0], 10),
          posto: codigoDividido[1],
        });
        console.log("entrou");
        setDadosCliente(cliente.data);
        setEspera(false);

        if (cliente.data.length === 0) {
          setCodigoPedido("");
          enqueueSnackbar("Nenhum cliente encontrado", { variant: "error" });
        } else {
          setCodigo_pedido(parseInt(codigoDividido[0], 10));
          setPosto_pedido(codigoDividido[1]);
          setNome_cliente(cliente.data.nome_cliente);
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setEspera(false);
    }
  }

  function verificarDataHora(data) {
    const dataAtual = format(new Date(), "yyyy-MM-dd");

    if (parseISO(data).getTime() < parseISO(dataAtual).getTime()) {
      enqueueSnackbar("A data selecionada não está mais disponível", {
        variant: "error",
      });
      return false;
    }
    return true;
  }

  function zera(data) {
    setNovaHora("");
    setVetorHora([""]);
    setNome_cliente("");

    setCodigoPedido("");
    setDadosCliente("");
    setPrioridade("Não");

    setCodigo_pedido(null);
    setPosto_pedido("");

    selecionaData(data);
  }

  async function agendar(data, hora) {
    if (!hora) {
      enqueueSnackbar("Horário não informado", { variant: "warning" });
    } else if (!codigo_pedido) {
      enqueueSnackbar("Código do pedido não informado", { variant: "warning" });
    } else if (!nome_cliente) {
      enqueueSnackbar("Cliente não selecionado ou não encontrado", {
        variant: "warning",
      });
    } else {
      const dataValida = verificarDataHora(data);

      if (dataValida) {
        const codigo_usuario = usuario;
        try {
          setEspera(true);
          await api.post("/api/agendamentos", {
            agenda: "Drive-Thru",
            nome_cliente,
            fone_cliente: dadosCliente.fone_cliente,
            celular_cliente: dadosCliente.celular_cliente,
            data,
            hora,
            codigo_pedido,
            posto_pedido,
            codigo_cliente: dadosCliente.codigo_cliente,
            posto_cliente: dadosCliente.posto_cliente,
            codigo_usuario,
            prioridade,
          });

          setAtualizaTabela((prevState) => !prevState);

          SalvaLog(
            `Agendou o pedido ${codigoPedido} para o dia ${data} as ${hora}`,
            "Agendamentos"
          );
          setEspera(false);
          enqueueSnackbar("Agendamento realizado com sucesso", {
            variant: "success",
          });
          zera(data);
        } catch (error) {
          SalvaLog(
            `Tentou agendar o pedido ${codigoPedido} para o dia ${data} as ${hora}`,
            "Agendamentos"
          );
          setAtualizaTabela((prevState) => !prevState);
          enqueueSnackbar("Falha na operação", { variant: "error" });
          setMsg(error.response.data.error);
          setAviso(true);
          setEspera(false);
          zera(data);
        }
      }
    }
  }

  async function atualizaStatus() {
    setMenuStatus(false);
    try {
      setEspera(true);
      await api.put("/api/agendamentos/status", {
        id,
        status,
      });
      setAtualizaTabela((prevState) => !prevState);
      enqueueSnackbar("Status alterado", { variant: "success" });
      SalvaLog(
        `Alterou o status do agendamento de ID ${id} para ${status}`,
        "Agendamentos"
      );
    } catch (error) {
      SalvaLog(
        `Tentou alterar o status do agendamento de ID ${id}`,
        "Agendamentos"
      );
      enqueueSnackbar("Não foi possível alterar o status", {
        variant: "error",
      });
    }
    setEspera(false);
  }

  async function cancelar() {
    try {
      setConfirmar(false);
      setOpcao(false);
      setEspera(true);
      const deletado = await api.delete(`/api/agendamentos/${id}`);
      SalvaLog(`Cancelou o pedido ${deletado.data.pedido}`, "Agendamentos");
      setAtualizaTabela((prevState) => !prevState);
      enqueueSnackbar("Agendamento cancelado com sucesso", {
        variant: "success",
      });
    } catch (error) {
      SalvaLog(`Tentou cancelar o agendamento de ID ${id}`, "Agendamentos");
      enqueueSnackbar("Não foi possível cancelar este agendamento", {
        variant: "error",
      });
    }
    setEspera(false);
  }

  function confirmaCancelamento() {
    setMsg("Você realmente deseja cancelar este agendamento?");
    setConfirmar(true);
  }

  async function reagendar() {
    if (novaHora === "") {
      enqueueSnackbar("Horário não selecionado", { variant: "warning" });
    } else {
      const dataValida = verificarDataHora(novaData);

      if (dataValida) {
        try {
          setReagendamento(false);
          setOpcao(false);
          setEspera(true);
          const reagendado = await api.put("/api/agendamentos/reagendamento", {
            id,
            hora: novaHora,
            data: novaData,
            usuario,
          });

          setNovaHora("");
          setAtualizaTabela((prevState) => !prevState);

          SalvaLog(
            `Reagendou o pedido ${reagendado.data.pedido} para o dia ${novaData} as ${novaHora}`,
            "Agendamentos"
          );
          enqueueSnackbar("Reagendamento realizado com sucesso", {
            variant: "success",
          });
        } catch (error) {
          SalvaLog(
            `Tentou reagendar o agendamento de ID ${id}`,
            "Agendamentos"
          );
          enqueueSnackbar("Falha no reagendamento", { variant: "error" });
        }
      }
      setEspera(false);
    }
  }

  return (
    <div className="container-formulario">
      <div className="aba-titulo">Drive-Thru</div>

      <div className="grid-container">
        <div className="grid-item-topo">
          <div className="grid-item-dataHora">
            <InputData
              labelText="Selecione a data"
              data={novaData}
              acao={selecionaData}
            />
            <FormControl variant="standard" sx={{ mx: 4, minWidth: 120 }}>
              <InputLabel style={{ fontSize: "12px", marginBottom: "2.5px" }}>
                Selecionar horário
              </InputLabel>
              <Select
                id="demo-simple-select-standard"
                style={{ width: "180px" }}
                value={novaHora}
                onChange={(e) => setNovaHora(e.target.value)}
              >
                {vetorHora.map((h) => (
                  <MenuItem key={h.hora} value={h.hora}>
                    {h.hora} (
                    {h.qtde_disponivel > 1
                      ? `${h.qtde_disponivel} disponíveis`
                      : `${h.qtde_disponivel} disponível`}
                    )
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className="grid-item-agendar">
            <button
              type="button"
              className="btnAgendar"
              onClick={() => agendar(novaData, novaHora)}
            >
              Agendar
            </button>
            <button
              hidden={!checarPermissao("AGE001")}
              type="button"
              className="btnAgendar"
              style={{ marginLeft: "15px", backgroundColor: "#088A29" }}
              onClick={() => setExibeModalLimites(true)}
            >
              Limites
            </button>
          </div>
        </div>

        <div className="grid-item-pedido">
          <TextField
            id="codPedido"
            variant="standard"
            label="Pedido"
            value={codigoPedido}
            onChange={(e) => setCodigoPedido(e.target.value)}
          />
          <Tooltip title="Validar código do pedido e buscar cliente">
            <IconButton onClick={() => buscarCliente()}>
              <Search color="primary" />
            </IconButton>
          </Tooltip>

          <div className="grid-item-cliente">
            <div className="grid-item-nome">
              <InputLabel id="nomeCliente">Cliente</InputLabel>
              <InputLabel id="nome">{nome_cliente}</InputLabel>
            </div>
            <FormControl variant="standard" sx={{ m: 1 }}>
              <InputLabel id="nomeHora">É médico?</InputLabel>
              <Select
                className="eMedico"
                value={prioridade}
                onChange={(e) => setPrioridade(e.target.value)}
              >
                <MenuItem key={900} value="Sim">
                  Sim
                </MenuItem>
                <MenuItem key={901} value="Não">
                  Não
                </MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
      </div>

      <Waiter espera={espera} />

      <DialogAviso
        aviso={aviso}
        setAviso={setAviso}
        mensagem={msg}
        setMensagem={setMsg}
      />

      <Dialog
        onClose={() => {
          setOpcao(false);
        }}
        aria-labelledby="simple-dialog-title"
        open={opcao}
      >
        <DialogTitle id="selecionaOpcao">Selecione a ação desejada</DialogTitle>
        <List>
          <ListItem
            autoFocus
            button
            onClick={() => setReagendamento(true)}
            key="100"
          >
            <Create color="primary" />
            <InputLabel id="itemLista"> Reagendar</InputLabel>
          </ListItem>

          <ListItem button onClick={() => confirmaCancelamento()} key="101">
            <Clear color="error" />
            <InputLabel id="itemLista"> Excluir</InputLabel>
          </ListItem>

          <ListItem
            button
            onClick={() => {
              setOpcao(false);
            }}
            key="102"
          >
            <Replay />
            <InputLabel id="itemLista"> Voltar</InputLabel>
          </ListItem>
        </List>
      </Dialog>

      <Dialog open={confirmar} onClose={() => setConfirmar(false)}>
        <DialogTitle id="alert-dialog-title">{msg}</DialogTitle>
        <DialogActions>
          <Button
            key="01"
            variant="contained"
            color="primary"
            onClick={() => cancelar()}
            autoFocus
          >
            Confirmar
          </Button>
          <Button
            key="02"
            variant="contained"
            color="primary"
            onClick={() => setConfirmar(false)}
          >
            Sair
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullWidth
        maxWidth="sm"
        open={reagendamento}
        onClose={() => setReagendamento(false)}
        aria-labelledby="simple-dialog-title"
      >
        <DialogTitle id="selecionaOpcao">Definir reagendamento</DialogTitle>
        <Grid item xs={12} container spacing={1}>
          <Grid id="gridData" item xs={6} md={3}>
            <InputData
              labelText="Selecione a data"
              data={novaData}
              acao={selecionaData}
            />
          </Grid>
          <Grid id="gridHora" item xs={6} md={3}>
            <FormControl variant="standard" sx={{ mx: 4, minWidth: 120 }}>
              <InputLabel style={{ fontSize: "12px", marginBottom: "2.5px" }}>
                Selecionar horário
              </InputLabel>
              <Select
                id="demo-simple-select-standard"
                style={{ width: "180px" }}
                value={novaHora}
                onChange={(e) => setNovaHora(e.target.value)}
              >
                {vetorHora.map((h) => (
                  <MenuItem key={h.hora} value={h.hora}>
                    {h.hora} (
                    {h.qtde_disponivel > 1
                      ? `${h.qtde_disponivel} disponíveis`
                      : `${h.qtde_disponivel} disponível`}
                    )
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={() => reagendar()}
            autoFocus
          >
            Confirmar
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setReagendamento(false)}
          >
            Sair
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={menuStatus} onClose={() => setMenuStatus(false)}>
        <DialogTitle id="alert-dialog-title">
          Você realmente deseja alterar o status?
        </DialogTitle>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={() => atualizaStatus()}
            autoFocus
          >
            Confirmar
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setMenuStatus(false)}
          >
            Desistir
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
