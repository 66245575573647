import React, { useState, useContext } from "react";
import Clientes from "./pages/Clientes";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";

import { Context } from "../../Context/PermissoesContext";
import Header from "../../components/Header";
import Container from "../../componentsv2/Container";

export default function AgendaCare() {
  const [aba, setAba] = useState(0);
  const history = useHistory();

  const { checarPermissao } = useContext(Context);

  useEffect(() => {
    if (!checarPermissao(`GDC001`)) {
      history.push("/home");
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
    <Header titulo="Gestão de Clientes"/>
    <Container>
      <div style={{ paddingBottom: "0" }} id="container-aba-ctrl">
        <button
          id={aba === 0 ? "button-aba-ctrl-samples-selected" : "aba0"}
          type="button"
          className="button-aba-ctrl"
          style={{ fontSize: "16px" }}
          onClick={() => {
            setAba(0);
          }}
        >
          Clientes
        </button>
      </div>
      {aba === 0 && <Clientes />}
      </Container>
      </>
  );
}
