import React, { useContext } from "react";
import {
  Button,
  Dialog,
  Paper,
  Tooltip
} from "@mui/material";
import { Add } from "@mui/icons-material";

import CorpoModal from "../../../Componentes/CorpoModal";
import TabelaRecebimentoManual from "../TabelaRecebimentoManual";

import { ModalRecebimentoContext } from "../../Context/ModalRecebimentoContext";
import { RetornoLoteContext } from "../../Context/RetornoLoteContext";

export default function ModalGlosasLotes() {
  const {
    modalGlosasLotes,
    fechaModalGlosasLotes,
  } = useContext(ModalRecebimentoContext);

  const { salvaGlosasLotes, setGlosas } = useContext(RetornoLoteContext);

  const novaLinha = {
    id: Math.floor(Date.now() * Math.random()).toString(36),
    situacao: "",
    motivo: "",
    valor_recebido: null,
  };

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={modalGlosasLotes}
      closeAfterTransition
    >
      <CorpoModal titulo="Tabela glosas do lote" fechaModal={fechaModalGlosasLotes}>
        <Paper style={{display: "flex", flexDirection: 'column', margin: "1rem", height: "40rem"}}>
          <div style={{display: "flex", flexDirection: 'row', justifyContent: "flex-end"}}>
            <Tooltip title="Salvar glosas">
              <Button
                autoFocus
                style={{margin: "1rem"}}
                variant="contained"
                color="primary"
                onClick={salvaGlosasLotes}
              >
                Salvar
              </Button>
            </Tooltip>
            <Tooltip title="Inserir nova linha na tabela">
              <Button
                style={{margin: "1rem"}}
                variant="outlined"
                color="success"
                onClick={() => setGlosas(prevState => [...prevState, novaLinha])}
              >
                <Add />
              </Button>
            </Tooltip>
          </div>
          <TabelaRecebimentoManual />
        </Paper>
      </CorpoModal>
    </Dialog>
  )
}

