import { useEffect, useState } from "react";
import GridContainer from "../../../componentsv2/GridContainer";
import GridItem from "../../../componentsv2/GridItem";
import Filtros from "./components/Filtros";
import ListaCards from "./components/ListaCards";
import { enqueueSnackbar } from "notistack";
import { api } from "../../../services/Api";
import useFiltros from "./hooks/useFiltros";
import useDadosContextEstoque from "../hooks/useDadosContextEstoque";

export default function Resumo() {
  const dataInicial = new Date();
  const dataFinal = new Date();
  const [produtosEstoques, setProdutosEstoques] = useState([]);
  const [estoques, setEstoques] = useState([]);
  const { localSelecionado } = useDadosContextEstoque();

  const filtrarEstoques = () => {
    const estoquesMap = produtosEstoques?.reduce((acc, produto) => {
      const {
        estoque_id,
        estoque,
        quantidade,
        produto: { valor, id, nome },
      } = produto;

      if (!acc[estoque_id]) {
        acc[estoque_id] = {
          estoque,
          quantidadeProdutos: 0,
          listaProdutos: {},
        };
      }

      acc[estoque_id].quantidadeProdutos += quantidade;

      if (!acc[estoque_id].listaProdutos[id]) {
        acc[estoque_id].listaProdutos[id] = {
          ...produto,
          contador: quantidade,
        };
      } else {
        acc[estoque_id].listaProdutos[id].contador += quantidade;
      }

      return acc;
    }, {});

    const resultado = Object.values(estoquesMap).map(
      ({ estoque, quantidadeProdutos, valorTotal, listaProdutos }) => ({
        ...estoque,
        quantidadeProdutos,
        listaProdutos: Object.values(listaProdutos),
      })
    );

    const estoqueFiltrado = resultado?.filter((estoque) => {
      if (localSelecionado) {
        return estoque.unidade.id == localSelecionado;
      }
      return estoque;
    });

    const adicionarValor = estoqueFiltrado?.map((estoque) => {
      const valorTotal = estoque.listaProdutos.reduce(
        (acc, produto) => acc + produto.produto.valor * produto.contador,
        0
      );

      return {
        ...estoque,
        valorTotal,
      };
    });

    setEstoques(adicionarValor);
  };

  const getProdutosEstoques = async () => {
    try {
      const resultTodosProdutos = await api.get(
        "/v2/almoxarifado/todosprodutos",
        {
          params: { dataInicial, dataFinal },
        }
      );

      setProdutosEstoques(resultTodosProdutos.data.produtos);
    } catch (e) {
      enqueueSnackbar("falha ao obter dados", {
        variant: "error",
      });
    }
  };

  useEffect(() => {
    getProdutosEstoques();
  }, []);

  useEffect(() => {
    filtrarEstoques();
  }, [produtosEstoques, localSelecionado]);

  return (
    <GridContainer gap={2} xs={12}>
      <GridItem xs={12}>
        <Filtros />
      </GridItem>

      <ListaCards arrayEstoques={estoques} />
    </GridContainer>
  );
}
