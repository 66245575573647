import GridContainer from "../../../../componentsv2/GridContainer";
import GridItem from "../../../../componentsv2/GridItem";
import Paper from "../../../../componentsv2/Paper";
import DatePicker from "../../../../components/DataPickerAtualizado";
import { useState } from "react";
import SelectLocal from "../../components/SelectLocal";
import useDadosContextEstoque from "../../hooks/useDadosContextEstoque";
import useFiltros from "../hooks/useFiltros";

export default function Filtros() {
  const [dataInicial, setDataInicial] = useState(new Date());
  const [dataFinal, setDataFinal] = useState(new Date());
  const { semAcessoUnidade, localSelecionado } = useDadosContextEstoque();
  const { handleLocal, listaUnidades } = useFiltros();

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Paper>
          <GridContainer spacing={1}>
            <GridItem xs={12} sm={4}>
              <SelectLocal
                label="Localização"
                value={localSelecionado}
                setValue={handleLocal}
                locais={listaUnidades}
                ativo={semAcessoUnidade}
              />
            </GridItem>
          </GridContainer>
        </Paper>
      </GridItem>
    </GridContainer>
  );
}
