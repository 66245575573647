import React from "react";
import { Grid } from "@mui/material";
import { HeaderSelectBaixa } from "./HeaderSelect";
import { PesquisaBaixas } from "./Search";
import { TabelaBaixas } from "./Table";
import DadosContext from "./DadosContext";

export default function HistoricoBaixaHome() {
  return (
    <Grid container>
      <Grid item xs={12} sm={12}>
        <HeaderSelectBaixa />
      </Grid>
      {/* <Grid sx={{ pt: "30px" }} item xs={12} sm={12}>
        <PesquisaBaixas />
      </Grid> */}
      <Grid sx={{ pt: "10px" }} item xs={12} sm={12}>
        <TabelaBaixas />
      </Grid>
    </Grid>
  );
}
