import React, { useEffect, useState } from "react";
import {  InputLabel,
          Tooltip,
          Accordion,
          AccordionSummary,
          AccordionDetails } from '@mui/material';
import {  ExpandMore,
          MonetizationOn,
          Error } from '@mui/icons-material';

import "./styles.css";

export default function Tabela(props){
  const { agendamentos } = props;

  const [expanded, setExpanded] = useState(false);
  const [tamanho, setTamanho ] = useState('');

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  function sizeOfScreen(){
    setTamanho(window.innerWidth)
  };

  useEffect(() =>{
    sizeOfScreen();
  }, []);

  window.addEventListener('resize', () => {
    sizeOfScreen();
  });

  function  abreVisualizaDados(cliente) {
    props.setCliente(cliente);
    props.setAbre_visualiza_dados(true);
  }

    return (
      <div>
        {(tamanho > 499)
            ?(
              <div id="container-table-drive">
                <table id="table-drive">
                  <thead>
                    <tr>
                      <th align="center">Usuário</th>
                      <th align="center">Hora</th>
                      <th align="center">CPF</th>
                      <th align="center">Nome</th>
                      <th align="center">Número</th>
                      <th align="center">Pedido</th>
                      <th align="center">Status</th>
                      <th align="center">Opções</th>
                    </tr>
                  </thead>
                  <tbody>
                    {agendamentos.map((row) => (
                      <tr key={row.id}>
                        <td align="center">
                          {row.usuario}
                        </td>
                        <td align="center">
                          { (typeof row.hora !== "undefined")
                                ? `${(row.hora > 9) ? row.hora : `0${  row.hora}`  }:${  (row.minuto > 9) ? row.minuto : `0${  row.minuto}`}`
                                : null}
                        </td>
                        <td align="center">
                          {row.cpf}
                        </td>
                        <td align="left">
                          {row.nome}
                        </td>
                        <td align="center">
                          <div id="container-status-table-drive">
                            {(row.tipo  === 'Particular')
                                ? <MonetizationOn id="icon-money" fontSize="small" />
                                : null}
                            {(row.prioridade  === 'Sim')
                                ? <Error id="icon-priority" fontSize="small" />
                                : null}
                            {row.placa}
                          </div>
                        </td>
                        <td align="center">
                          {row.codigo_pedido}
                        </td>
                        <td align="center">
                          <div id="container-status-atendimento-table-drive">
                            <i
                              id={(row.status_atendimento  === 'Coletado')
                                  ? 'icon-status-atendimento-table-drive-blue'
                                  : (row.status_atendimento  === 'Aguardando coleta')
                                    ? 'icon-status-atendimento-table-drive-green'
                                    : (row.status_atendimento  === 'Cancelado')
                                      ? 'icon-status-atendimento-table-drive-red'
                                      : (row.status_atendimento  === 'Desistiu')
                                        ? 'icon-status-atendimento-table-drive-gray'
                                        : 'icon-status-atendimento-table-drive-orange'}
                              className="fa fa-circle"
                            />
                            {row.status_atendimento}
                          </div>
                        </td>
                        <td align="center">
                          <Tooltip title="Abrir opções">
                            <button
                              id="button-option"
                              type="button"
                              onClick={() => { abreVisualizaDados(row) }}
                            >
                              <i className="fa fa-cog" />
                            </button>
                          </Tooltip>
                        </td>
                      </tr>
                ))}

                  </tbody>
                </table>
              </div>
              )
            :(
              <div>
                {agendamentos.map((row) => (
                  <Accordion expanded={expanded === `panel${row.id}`} onChange={handleChange(`panel${row.id}`)}>
                    <AccordionSummary
                      expandIcon={<ExpandMore />}
                      aria-controls="panel1a-content"
                      id={(row.status_atendimento  === 'Coletado')
                          ? 'table-row-mobile-drive-blue'
                          : (row.status_atendimento  === 'Aguardando coleta')
                            ? 'table-row-mobile-drive-green'
                            : (row.status_atendimento  === 'Cancelado')
                              ? 'table-row-mobile-drive-red'
                              : (row.status_atendimento  === 'Desistiu')
                                ? 'table-row-mobile-drive-gray'
                                : 'table-row-mobile-drive-orange'}
                    >
                      <div id="container-label-dialog-drive">
                        <InputLabel id="label-dialog-drive">{row.nome}</InputLabel>
                        <InputLabel id="label-dialog-drive">
                          <div id="container-status-table-drive">
                            {(row.tipo  === 'Particular')
                                ? <MonetizationOn id="icon-money" fontSize="small" />
                                : null}
                            {(row.prioridade  === 'Sim')
                                ? <Error id="icon-priority" fontSize="small" />
                                : null}
                            {row.placa} - {row.status_atendimento}
                          </div>
                        </InputLabel>
                      </div>
                    </AccordionSummary>
                    <AccordionDetails>

                      <div id="container-table-dialog">

                        <div>
                          <InputLabel id="label1-table-dialog-drive">Usuário</InputLabel>
                        </div>

                        <div>
                          <InputLabel id="label2-table-dialog-drive">{row.usuario}</InputLabel>
                        </div>

                        <div>
                          <InputLabel id="label1-table-dialog-drive">Hora</InputLabel>
                        </div>

                        <div>
                          <InputLabel id="label2-table-dialog-drive">
                            { (typeof row.hora !== "undefined")
                                ? `${(row.hora > 9) ? row.hora : `0${  row.hora}`  }:${  (row.minuto > 9) ? row.minuto : `0${  row.minuto}`}`
                                : null}
                          </InputLabel>
                        </div>

                        <div>
                          <InputLabel id="label1-table-dialog-drive">CPF</InputLabel>
                        </div>

                        <div>
                          <InputLabel id="label2-table-dialog-drive">{row.cpf}</InputLabel>
                        </div>

                        <div>
                          <InputLabel id="label1-table-dialog-drive">Pedido</InputLabel>
                        </div>

                        <div>
                          <InputLabel id="label2-table-dialog-drive">{row.codigo_pedido}</InputLabel>
                        </div>

                        <div>
                          <InputLabel id="label1-table-dialog-drive">Opções</InputLabel>
                        </div>

                        <div>
                          <button
                            id="button-option-small"
                            type="button"
                            onClick={() => { abreVisualizaDados(row) }}
                          >
                            <i className="fa fa-cog" />
                          </button>
                        </div>

                      </div>
                    </AccordionDetails>
                  </Accordion>
              )
              )}
              </div>
          )}
      </div>
    )
  }
