import React from "react";

import AddIcon from "@mui/icons-material/Add";

import "./styles.css";

export default function AddSectionButton({ onAdd }) {
  return (
    <div className="add-section-button" onClick={onAdd}>
      <AddIcon fontSize="large" style={{ color: "white" }} />
    </div>
  );
}
