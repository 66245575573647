import React, { useRef, useMemo } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useSnackbar } from "notistack";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import Input from "../../../../components/Input";
import AddSectionButton from "../AddSectionButton";

import { api, baseUrl } from "../../../../services/Api";

import "./styles.css";

export default function ArticleSection({
  section,
  onTitleChange,
  onBodyChange,
  onRemove,
  onAdd,
  addImage,
}) {
  const quillRef = useRef(null);
  const { enqueueSnackbar } = useSnackbar();

  const imageHandler = () => {
    const input = document.createElement("input");

    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    input.onchange = async () => {
      const file = input.files[0];
      const formData = new FormData();

      formData.append("tipo", "imagem");
      formData.append("file", file);

      try {
        const { data } = await api.post("/v2/portalMedico/midia", formData, {
          headers: {
            "content-type": "multipart/form-data",
          },
        });
        const range = quillRef.current.getEditorSelection();
        quillRef.current
          .getEditor()
          .insertEmbed(range.index, "image", `${baseUrl}${data.url}`);
        addImage(data);
      } catch (e) {
        enqueueSnackbar("Ocorreu um erro ao adicionar esta imagem.", {
          variant: "error",
        });
      }
    };
  };

  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          [{ header: [false] }],
          ["bold", "italic", "underline"],
          [{ list: "ordered" }, { list: "bullet" }],
          ["link", "image"],
        ],
        handlers: {
          image: imageHandler,
        },
      },
    }),
    // eslint-disable-next-line
    []
  );

  return (
    <>
      <div className="article-section" style={{ marginTop: 10 }}>
        <CloseIcon
          onClick={onRemove}
          fontSize="large"
          style={{
            marginLeft: "auto",
            marginBottom: ".5rem",
            cursor: "pointer",
            color: "rgba(0,0,0,0.6)",
          }}
        />
        <Input
          className="article-section-title"
          placeholder="O título da sua seção"
          inputStyles={{ fontWeight: 500, fontSize: "1.2rem" }}
          value={section.title}
          onChange={value => onTitleChange(value)}
        />
        <ReactQuill
          theme="snow"
          value={section.body}
          className="article-section-body"
          onChange={value => onBodyChange(value)}
          modules={modules}
          ref={quillRef}
        />
      </div>
      <AddSectionButton onAdd={onAdd} />
    </>
  );
}
