import React, { useEffect, useState } from "react";
import { ModalForm } from "../../../../../../componentsv2/ModalForm";
import { useSnackbar } from "notistack";
import { api } from "../../../../../../services/Api";
import { Input } from "../../../../../../componentsv2/Input";
import { Grid } from "@mui/material";
import formatarData from "../../../../../../utils/formatarData";

export default function Visualizar({ row, open, setOpen }) {
  const { enqueueSnackbar } = useSnackbar();
  const [sacData, setSacData] = useState("");
  const getSac = async () => {
    try {
      const {
        data: { data },
      } = await api.get(`v2/atendimentoAoCliente/Sac/${row.id}`);
      const sac = {...data,nomeSetor:data.setor.nome}
      setSacData(sac);
    } catch (error) {
      enqueueSnackbar("Erro ao tentar encontrar as perguntas do SAC", {
        variant: "error",
      });
    }
  };
  useEffect(() => {
    getSac();
    // eslint-disable-next-line
  }, [row]);

  return (
    <ModalForm
      onSubmit={() => {
        setOpen(false);
      }}
      title="Visualizar"
      open={open}
      onClose={() => {
        setOpen(false);
      }}
    >
      <Grid item xs={4}>
        <Input
          helperText="Nome"
          value={sacData.nome}
          variant="standard"
          InputProps={{ readOnly: true }}
        />
      </Grid>
      <Grid item xs={4}>
        <Input
          helperText="CPF"
          value={sacData.cpf}
          variant="standard"
          InputProps={{ readOnly: true }}
        />
      </Grid>
      <Grid item xs={4}>
        <Input
          helperText="Celular"
          value={sacData.celular}
          variant="standard"
          InputProps={{ readOnly: true }}
        />
      </Grid>
      <Grid item xs={4}>
        <Input
          helperText="Data Cadastro"
          value={formatarData(new Date(sacData.dataCadastro))}
          variant="standard"
          InputProps={{ readOnly: true }}
        />
      </Grid>
      <Grid item xs={4}>
        <Input
          helperText="Prazo"
          value={formatarData(new Date(sacData.prazo))}
          variant="standard"
          InputProps={{ readOnly: true }}
        />
      </Grid>
      <Grid item xs={4}>
        <Input
          helperText="Celular"
          value={sacData.celular}
          variant="standard"
          InputProps={{ readOnly: true }}
        />
      </Grid>
      <Grid item xs={4}>
        <Input
          helperText="Setor"
          value={sacData.nomeSetor}
          variant="standard"
          InputProps={{ readOnly: true }}
        />
      </Grid>
      <Grid item xs={4}>
        <Input
          helperText="Situação"
          value={sacData.situacao}
          variant="standard"
          InputProps={{ readOnly: true }}
        />
      </Grid>
      <Grid item xs={4}>
        <Input
          helperText="Tipo de manifestação"
          value={sacData.tipoManifestacao}
          variant="standard"
          InputProps={{ readOnly: true }}
        />
      </Grid>

      <Grid item xs={12}>
        <Input
          multiline
          maxRows={5}
          fullWidth
          helperText="Problematica"
          value={sacData.problematica}
          variant="standard"
          InputProps={{ readOnly: true }}
        />
      </Grid>
      <Grid item xs={12}>
        <Input
          fullWidth
          helperText="Solução"
          value={sacData.solucao}
          variant="standard"
          InputProps={{ readOnly: true }}
        />
      </Grid>
    </ModalForm>
  );
}
