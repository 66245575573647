import React, { useEffect, useRef, useState } from "react";
import Tabela from "../../../../componentsv2/Tabela";
import formatoMoeda from "../../../../utils/formatoMoeda";
import { enqueueSnackbar } from "notistack";
import { api } from "../../../../services/Api";
import { sum } from "lodash";
import GridContainer from "../../../../componentsv2/GridContainer";
import GridItem from "../../../../componentsv2/GridItem";
import DataPickerAtualizado from "../../../../components/DataPickerAtualizado";
import SelectEnfermeiro from "../../Components/SelectEnfermeiro";
import CardTotal from "../../../ColetaDomiciliar/Components/Financeiro/Totalizadores/CardTotal";
import { Paper } from "@mui/material";

export default function FinanceiroAgendaCare() {
  const [rows, setRows] = useState([]);
  const [enfermeiros, setEnfermeiros] = useState([]);
  const [valores, setValores] = useState([]);
  const [enfermeiroId, setEnfermeiroId] = useState("TODOS");
  const [dataInicio, setDataInicio] = useState(
    new Date(Date.now() - 1000 * 60 * 60 * 24 * 31)
  );
  const [dataFim, setDataFim] = useState(new Date());
  const [creditoVencimento, setCreditoVencimento] = useState([0, 0]);
  const [creditoParcelado, setCreditoParcelado] = useState([0, 0]);
  const [boleto, setBoleto] = useState([0, 0]);
  const [pix, setPix] = useState([0, 0]);
  const [debitoEspecie, setDebitoEspecie] = useState([0, 0]);
  const [transferenciaBancaria, setTransferenciaBancaria] = useState([0, 0]);
  const [total, setTotal] = useState([0, 0]);

  const ids = useRef([]);

  const getValorTotalById = (id) => {
    const valor = valores.filter((v) => v.id === id)[0];
    const valorComDesconto = valor?.totalComDesconto;
    return valorComDesconto;
  };

  const getEnfermeiroById = (id) => {
    const enfermeiro = enfermeiros.filter((e) => e.id === id)[0];
    return enfermeiro?.usuario?.funcionario?.nome_usu;
  };

  useEffect(() => {
    async function enfermeiros() {
      const {
        data: { data },
      } = await api.get("/v2/agendaCare/enfermeiros");

      setEnfermeiros(data);
    }

    async function agendamentosPorData() {
      try {
        const inicio = new Date(dataInicio).getTime() - 1000 * 60 * 60 * 25;
        const fim = new Date(dataFim).getTime();
        let { data } = await api.get(
          `/v2/agendaCare/agendamentodata/${inicio}/${fim}`
        );
        const { data: resultado } = data;
        const allIds = resultado.map((el) => el.id);
        ids.current = allIds;

        const clientesComCPF = resultado?.map((el) => ({
          ...el,
          cpf: el.cliente.cpf.replaceAll(" ", ""),
        }));

        setRows(clientesComCPF);
      } catch (error) {
        console.log(error);
        enqueueSnackbar("Falha para encontrar agendamentos", {
          variant: "error",
        });
      }
    }

    agendamentosPorData();
    enfermeiros();
  }, [dataInicio, dataFim]);

  useEffect(() => {
    async function getValoresById() {
      try {
        const { data: { data: agendamentos } } = await api.get(`/v2/agendaCare/agendamentos/todos`);
        let agendamentosTabela = agendamentos.filter((el) => ids.current.includes(el.id));
        if (enfermeiroId !== "TODOS" && enfermeiroId !== "") {
          const filtradoPorEnfermeiro = agendamentosTabela.filter(
            (el) => el.enfermeiro.id === enfermeiroId
          );
          agendamentosTabela = filtradoPorEnfermeiro;
        }
        setValores([])
        agendamentosTabela.forEach((resultado) => {
          const valorProc = resultado?.procedimentos.map(
            (procedimento) => procedimento.precoExame
          );
          const soma = sum(valorProc);
  
          let dataFiltro = new Date(resultado.data).getTime();
          let inicio = new Date(dataInicio).getTime() - 1000 * 60 * 60 * 24;
          let fim = new Date(dataFim).getTime();
  
          if (inicio <= dataFiltro && dataFiltro <= fim) {
            let newValor = {
              id: resultado.id,
              totalProcedimentos: soma,
              formaPagamento: resultado.formaPagamento,
              totalComDesconto: soma - resultado.desconto,
            };
  
            setValores((valores) => [...valores, newValor]);
          }
        });
      } catch (error) {
        enqueueSnackbar("Falha em encontrar procedimentos", {
          variant: "error",
        });
      }
    }
    getValoresById();
  }, [dataInicio, dataFim, enfermeiroId]);

  useEffect(() => {
    const filterByEnfermeiros = async () => {
      const dados = await refresh();
      if (enfermeiroId === "") {
        setRows(dados);
        return;
      }

      const filtradoPorEnfermeiro = dados.filter(
        (el) => el.enfermeiroId === enfermeiroId
      );

      setRows(filtradoPorEnfermeiro);
    };

    filterByEnfermeiros();
  }, [enfermeiroId]);

  const refresh = async () => {
    let { data } = await api.get(`/v2/agendaCare/agendamentos/financeiro`);
    const { data: resultado } = data;
    const allIds = resultado.map((el) => el.id);
    ids.current = allIds;

    const clientesComCPF = resultado?.map((el) => ({
      ...el,
      cpf: el.cliente.cpf.replaceAll(" ", ""),
    }));

    return clientesComCPF;
  };

  useEffect(() => {
    const filterByDatas = async () => {
      const dados = await refresh();

      const inicio = new Date(dataInicio).getTime();
      const fim = new Date(dataFim).getTime();

      const filterByTime = (dataASerFiltrada) => {
        const data = new Date(dataASerFiltrada.data).getTime();
        return data >= inicio - 1 && data <= fim + 1;
      };

      const filtradoByData = dados.filter(filterByTime);
      setRows(filtradoByData);
    };

    filterByDatas();
  }, [dataFim, dataInicio]);

  useEffect(() => {
    const preencherCardsTotalizadores = () => {
      let credParc = [0, 0];
      let credVenc = [0, 0];
      let bol = [0, 0];
      let pix = [0, 0];
      let debEsp = [0, 0];
      let transBanc = [0, 0];
      let total = [0, 0];

      for (let i = 0; i < valores.length; i++) {
        let elemento = valores[i];
        if (elemento.formaPagamento === "Crédito - Parcelado") {
          credParc[0] += elemento.totalComDesconto;
          credParc[1] += 1;
        }
        if (elemento.formaPagamento === "Debito - Espécie") {
          debEsp[0] += elemento.totalComDesconto;
          debEsp[1] += 1;
        }
        if (elemento.formaPagamento === "Boleto") {
          bol[0] += elemento.totalComDesconto;
          bol[1] += 1;
        }
        if (elemento.formaPagamento === "Crédito - Vencimento") {
          credVenc[0] += elemento.totalComDesconto;
          credVenc[1] += 1;
        }
        if (elemento.formaPagamento === "Pix") {
          pix[0] += elemento.totalComDesconto;
          pix[1] += 1;
        }
        if (elemento.formaPagamento === "Transferencia Bancária") {
          transBanc[0] += elemento.totalComDesconto;
          transBanc[1] += 1;
        }
      }

      total[0] =
        credParc[0] + credVenc[0] + bol[0] + pix[0] + debEsp[0] + transBanc[0];
      total[1] =
        credParc[1] + credVenc[1] + bol[1] + pix[1] + debEsp[1] + transBanc[1];

      setCreditoParcelado(credParc);
      setDebitoEspecie(debEsp);
      setBoleto(bol);
      setCreditoVencimento(credVenc);
      setPix(pix);
      setTransferenciaBancaria(transBanc);
      setTotal(total);
    };

    preencherCardsTotalizadores();
  }, [valores]);

  const columns = [
    {
      field: "data",
      headerName: "Data",
      headerAlign: "center",
      valueGetter: ({ value }) => value && new Date(value).toLocaleDateString(),

      align: "center",
      flex: 1,
    },
    {
      field: "enfermeiroId",
      headerName: "Enfermeiro",
      headerAlign: "center",
      valueGetter: ({ value }) => value && getEnfermeiroById(value),
      align: "center",
      flex: 1,
    },
    {
      field: "formaPagamento",
      headerName: "Forma de pagamento",
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "id",
      headerName: "Valor",
      valueGetter: ({ value }) => value && `R$ ${getValorTotalById(value)}`,
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "desconto",
      headerName: "Desconto",
      headerAlign: "center",
      valueGetter: ({ value }) => value && formatoMoeda(Number(value)),
      align: "center",
      flex: 1,
    },
    {
      field: "cliente",
      headerName: "Cliente",
      headerAlign: "center",
      valueGetter: ({ value }) => value && value.nome,
      align: "center",
      flex: 1,
    },
    {
      field: "cpf",
      headerName: "CPF",
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "distancia",
      headerName: "Distancia",
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "medicos_care",
      headerName: "Medico",
      headerAlign: "center",
      valueGetter: ({ value }) => value && value.nome ? value.nome : "Não informado",
      align: "center",
      flex: 1,
    },
  ];

  return (
    <>
      <div>
        <Paper className="container_totalizadores">
          <GridContainer sx={{ display: "flex" }}>
            <GridContainer sx={{ paddingLeft: 2 }} xs={12} md={12} spacing={2}>
              <GridItem md={4} xs={12}>
                <DataPickerAtualizado
                  data={dataInicio}
                  setData={setDataInicio}
                  variant="standard"
                  labelName="Data Inicio"
                />
              </GridItem>
              <GridItem md={4} xs={12}>
                <DataPickerAtualizado
                  data={dataFim}
                  setData={setDataFim}
                  variant="standard"
                  labelName="Data Fim"
                />
              </GridItem>

              <GridItem md={3} xs={12}>
                <SelectEnfermeiro
                  todos={true}
                  onChange={(e) => {
                    setEnfermeiroId(e.target.value);
                  }}
                  value={enfermeiroId}
                />
              </GridItem>
            </GridContainer>
          </GridContainer>
        </Paper>
        <Paper className="container_totalizadores">
          <CardTotal
            titulo="Crédito - Vencimento"
            valor={creditoVencimento[0]}
            quantidade={creditoVencimento[1]}
          />

          <CardTotal
            titulo="Crédito - Parcelado"
            valor={creditoParcelado[0]}
            quantidade={creditoVencimento[1]}
          />

          <CardTotal titulo="Boleto" valor={boleto[0]} quantidade={boleto[1]} />

          <CardTotal titulo="Pix" valor={pix[0]} quantidade={pix[1]} />

          <CardTotal
            titulo="Débito - Espécie"
            valor={debitoEspecie[0]}
            quantidade={debitoEspecie[1]}
          />
          <CardTotal
            titulo="Transferência bancária"
            valor={transferenciaBancaria[0]}
            quantidade={transferenciaBancaria[1]}
          />
          <CardTotal titulo="Total" valor={total[0]} quantidade={total[1]} />
        </Paper>
        <Tabela rows={rows} columns={columns} />
      </div>
    </>
  );
}
