import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

export default function AutocompleteMultiple({
  opcoes = [],
  value = [],
  setValue = () => {},
  label = "",
  optionLabel = "",
  variant = "standard",
}) {
  return (
    <Autocomplete
      multiple
      id="size-small-outlined"
      size="small"
      className="text-zinc-900 dark:text-white
      bg-gray-100 dark:bg-gray-700 rounded-sm"
      options={opcoes}
      value={value}
      onChange={(event, newValue) => setValue(newValue)}
      getOptionLabel={(option) => option[optionLabel]}
      defaultValue={[]}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            label={label}
            placeholder="Selecione..."
            variant={variant}
          />
        );
      }}
    />
  );
}
