import React, { useContext } from 'react';
import { Dialog, DialogContent, DialogTitle, InputLabel, List, ListItemButton } from '@mui/material';
import {
  Clear,
  // Create,
  Replay
} from '@mui/icons-material';

import { ColetaDomiciliarBloqueioContext } from '../../../Context/ColetaDomiciarBloqueioContext';

export default function OpcoesBloqueio() {
  const {
    modalOpcoesBloqueio,
    fechaModalOpcoesBloqueio,
    apagaBloqueio
  } = useContext(ColetaDomiciliarBloqueioContext);

  return (
    <Dialog
      open={modalOpcoesBloqueio}
      onClose={fechaModalOpcoesBloqueio}
    >
      <DialogTitle id="selecionaOpcao">
        Selecione uma opção
      </DialogTitle>

      <DialogContent>
        <List className="opList">
          {/* <ListItemButton
            autoFocus
            key="101"
            onClick={() => {}}
          >
            <Create color="primary" />
            <InputLabel id="itemLista"> Editar</InputLabel>
          </ListItemButton> */}

          <ListItemButton onClick={apagaBloqueio} key="102">
            <Clear color="error" />
            <InputLabel id="itemLista"> Excluir</InputLabel>
          </ListItemButton>

          <ListItemButton
            key="103"
            onClick={fechaModalOpcoesBloqueio}
          >
            <Replay />
            <InputLabel id="itemLista"> Voltar</InputLabel>
          </ListItemButton>
        </List>
      </DialogContent>

    </Dialog>
  )
}
