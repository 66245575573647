import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useSnackbar } from "notistack";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  Button,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
} from "@mui/material";
import SalvaLog from "../../../../utils/SalvaLog";
import Header from "../../../../components/Header";
import useUser from "../../../../hooks/useUser";

import { api } from "../../../../services/Api";
import useDadosContextEstoque from "../../hooks/useDadosContextEstoque";

export default function AdicionarEstoque() {
  const history = useHistory();
  const location = useLocation();
  const usuario = useUser();
  const { enqueueSnackbar } = useSnackbar();

  const [unidades, setUnidades] = useState([]);
  const [unidadeSelecionada, setUnidadeSelecionada] = useState("");
  const [quantidade, setQuantidade] = useState(0);
  const [quantidadePacotes, setQuantidadePacotes] = useState(0);
  // eslint-disable-next-line
  const [idProduto, setIdProduto] = useState(null);
  const [nomeProduto, setNomeProduto] = useState("");
  const [idProdutoEstoque, setIdProdutoEstoque] = useState(null);
  const [edit, setEdit] = useState(false);
  const [local, setLocal] = useState("");
  const [retiradaQuantidade, setRetiradaQuantidade] = useState(0);

  const { update, setUpdate } = useDadosContextEstoque();

  const edicaoEstoque = location.state;

  useEffect(() => {
    if (edicaoEstoque) {
      if (edicaoEstoque.edit !== true) {
        //adicionar estoque
        setIdProduto(edicaoEstoque.id_produto);
        setNomeProduto(edicaoEstoque.nome);
        setUnidadeSelecionada(edicaoEstoque.local_baixa);
        setIdProdutoEstoque(edicaoEstoque.id_produto_estoque);
      } else {
        setEdit(true);
        setNomeProduto(edicaoEstoque.nome);
        setQuantidade(edicaoEstoque.quantidade);
        setQuantidadePacotes(edicaoEstoque.quantidade);
        setLocal(edicaoEstoque.local);
        setIdProdutoEstoque(edicaoEstoque.id_produto_estoque);
        setUnidadeSelecionada(edicaoEstoque.local_baixa);
      }
    }

    async function obterUnidadesLocal() {
      try {
        const result = await api.get("/v2/geral/unidade");
        setUnidades(result.data.unidade);
      } catch (e) {
        enqueueSnackbar("Erro ao buscar unidades", { variant: "error" });
      }
    }
    obterUnidadesLocal();
  }, [enqueueSnackbar, edicaoEstoque]);

  async function handleCriaEstoque() {
    try {
      const adicaoQuantidadeConvertida = Number(quantidade);
      if (quantidade <= 0) {
        enqueueSnackbar(
          "Por favor selecione uma quantidade para adicionar ao estoque",
          {
            variant: "info",
          }
        );
        return;
      }

      await api.put(`/v2/almoxarifado/lote/editar/${idProdutoEstoque}`, {
        quantidade: adicaoQuantidadeConvertida,
      });

      await api.post("/v2/almoxarifado/movimentcaoProdutoEstoque/entrada", {
        id_produto_estoque: idProdutoEstoque,
        data: new Date(),
        tipo: "Entrada",
        origem_destino: unidadeSelecionada,
        quantidade: adicaoQuantidadeConvertida,
      });

      setUpdate(!update);
      SalvaLog(
        `Deu entrada no produto estoque`,
        "Almoxarifado",
        idProdutoEstoque
      );
      enqueueSnackbar("Estoque criado com sucesso", { variant: "success" });
      history.push("/almoxarifado/");
    } catch (e) {
      enqueueSnackbar("Erro ao criar estoque", { variant: "error" });
    }
  }

  async function handleEditEstoque() {
    try {
      const retiradaQuantidadeConvertida = Number(retiradaQuantidade);

      if (retiradaQuantidadeConvertida > quantidade) {
        enqueueSnackbar("Quantidade em estoque insuficiente!", {
          variant: "warning",
        });
        return;
      }

      if (retiradaQuantidade <= 0) {
        enqueueSnackbar("Por favor selecione uma quantidade para dar baixa", {
          variant: "info",
        });
        return;
      }

      await api.post("/v2/almoxarifado/movimentcaoProdutoEstoque/saida", {
        id_produto_estoque: idProdutoEstoque,
        data: new Date(),
        tipo: "Saida",
        origem_destino: unidadeSelecionada,
        quantidade: retiradaQuantidadeConvertida,
      });

      setUpdate(!update);
      SalvaLog(`Baixa no estoque efetuada`, "Almoxarifado", idProdutoEstoque);
      enqueueSnackbar("Baixa no estoque efetuada com sucesso", {
        variant: "success",
      });
      history.push("/almoxarifado/");
    } catch (e) {
      enqueueSnackbar("Erro ao criar estoque", { variant: "error" });
    }
  }

  return (
    <>
      <Header
        titulo={edit === true ? "Retirar estoque" : "Adicionar Estoque"}
        usuario={usuario}
        history={history}
      />
      <Container maxWidth="md" style={{ padding: "20px" }}>
        <Button onClick={() => history.push("/almoxarifado")}>
          <ArrowBackIcon style={{ color: "#003399" }} />
        </Button>

        <Paper style={{ padding: "40px", marginTop: "10px" }} elevation={2}>
          <Grid spacing={2} container>
            <Grid item xs={12} sm={12}>
              <h2>
                {edit === true ? "Retirar do estoque" : "Adicionar Estoque"}
              </h2>
            </Grid>
            <Grid
              style={{ marginTop: "10px" }}
              item
              xs={12}
              sm={edit === true ? 4 : 12}
            >
              <h3>
                Produto:
                <span style={{ fontWeight: "normal", marginLeft: "2px" }}>
                  {` ${nomeProduto}`}
                </span>
              </h3>
            </Grid>
            {edit === true && (
              <>
                <Grid style={{ marginTop: "10px" }} item xs={12} sm={4}>
                  <h3>
                    Quantidade disponível:
                    <span style={{ fontWeight: "normal" }}>{quantidade}</span>
                  </h3>
                </Grid>
                {/* <Grid style={{ marginTop: "10px" }} item xs={12} sm={3}>
                  <h3>
                    Quantidade disponível em pacotes:
                    <span style={{ fontWeight: "normal" }}>
                      {quantidadePacotes}
                    </span>
                  </h3>
                </Grid> */}
                <Grid style={{ marginTop: "10px" }} item xs={12} sm={4}>
                  <h3>
                    Local do estoque:
                    <span style={{ fontWeight: "normal" }}>{local}</span>
                  </h3>
                </Grid>
              </>
            )}
            {edit === true ? (
              <Grid style={{ marginTop: "20px" }} item xs={12} sm={12}>
                <TextField
                  label="Inserir quantidade (em unidades) para dar baixa"
                  variant="standard"
                  size="small"
                  type="number"
                  fullWidth
                  value={retiradaQuantidade}
                  onChange={(e) => setRetiradaQuantidade(e.target.value)}
                />
              </Grid>
            ) : (
              <>
                <Grid style={{ marginTop: "20px" }} item xs={6} sm={6}>
                  <TextField
                    label="Quantidade de pacotes"
                    variant="standard"
                    size="small"
                    type="number"
                    fullWidth
                    value={quantidade}
                    onChange={(e) => setQuantidade(e.target.value)}
                  />
                </Grid>
                <Grid style={{ marginTop: "20px" }} item xs={6} sm={6}>
                  <FormControl
                    defaultValue=" "
                    size="small"
                    variant="standard"
                    fullWidth
                  >
                    <InputLabel id="select-label">Localização</InputLabel>
                    <Select
                      value={unidadeSelecionada}
                      onChange={(e) => setUnidadeSelecionada(e.target.value)}
                      labelId="select-label"
                      id="select"
                      label="Ramal"
                      disabled
                    >
                      {unidades.map((el) => (
                        <MenuItem key={el.id} value={el.id}>
                          {el.nome}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </>
            )}

            <Grid
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
              item
              xs={12}
              sm={12}
            >
              {edit === true ? (
                <Button
                  onClick={() => handleEditEstoque()}
                  style={{ color: "#003399" }}
                >
                  Dar baixa
                </Button>
              ) : (
                <Button
                  onClick={() => handleCriaEstoque()}
                  style={{ color: "#003399" }}
                >
                  Adicionar
                </Button>
              )}
            </Grid>
          </Grid>
        </Paper>
      </Container>
    </>
  );
}
