import { Grid, TextField } from '@mui/material';
import React, { useContext } from "react";

import DadosContext from "../DadosContext";

export default function UsuarioPerfil() {
  const { nome, setNome, email, setEmail } = useContext(DadosContext);

  return (
    <Grid container style={{ padding: "1.5rem 1.5rem" }} spacing={2}>
      <Grid item sm={12}>
        <h3>Dados do usuário</h3>
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextField
          defaultValue=" "
          value={nome}
          onChange={e => setNome(e.target.value)}
          fullWidth
          size="small"
          label="Nome"
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          defaultValue=" "
          value={email}
          onChange={e => setEmail(e.target.value)}
          fullWidth
          size="small"
          label="E-mail"
          variant="outlined"
        />
      </Grid>
    </Grid>
  );
}
