import React from "react";
import { Box } from "@mui/material";

export default function Bi() {
  return (
    <Box>
      <iframe
        width="100%"
        height="700"
        title="BI"
        src="https://app.powerbi.com/view?r=eyJrIjoiZDMwZDUwMjktYWM3MC00YTRjLWI1MDItMzViYTZjNGVmYTYwIiwidCI6ImE1ZGEyY2QwLTgxNWYtNGFmNC1hYzYwLTZjNmI1OTNiNzk1NyJ9"
        frameBorder="0"
        allowFullScreen
      />
    </Box>
  );
}
