import React, { useRef } from "react";
import { TextField, Button } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { api } from "../../../services/Api";

export default function PesquisaUsuario({
  setLoading,
  setUsuarioSelecionado,
  enqueueSnackbar,
}) {
  const pesquisaUsuarioRef = useRef(null);

  const pesquisarUsuario = async e => {
    e.preventDefault();
    let usuario;
    const valorBusca = pesquisaUsuarioRef.current.value;
    setLoading(true);
    try {
      const { data } = await api.get(`/v2/controleAcesso/usuario?username=${(valorBusca).toUpperCase()}`);
      usuario = data.usuario;
      if (!usuario) {
        enqueueSnackbar("Usuário não encontrado.", {
          variant: "error",
          autoHideDuration: 3000,
        });
      }
    } catch (error) {
      enqueueSnackbar("Algo deu errado.", {
        variant: "error",
        autoHideDuration: 3000,
      });
      return setLoading(false);
    }
    setLoading(false);
    return setUsuarioSelecionado(usuario);
  };
  return (
    <form className="user-search-wrapper" onSubmit={pesquisarUsuario}>
      <TextField label="Usuário" style={{ width: "90%" }} inputRef={pesquisaUsuarioRef} />
      <Button
        style={{
          backgroundColor: "#003399",
          width: "8%",
          marginLeft: "8px",
        }}
        type="submit"
      >
        <SearchIcon style={{ color: "white" }} />
      </Button>
    </form>
  );
}
