import React from "react";
import { Paper, Grid } from '@mui/material';
import formatarData from "../../utils/formatarData";

export default function DadosPaciente({ paciente, dataISO, ...props }) {
  const dataFormatada = dataISO ? formatarData(new Date(paciente.data_nascimento)) : null;
  return (
    <Paper className="dados-paciente-wrapper" {...props}>
      <h2 style={{ marginBottom: "1.5rem" }}>Dados paciente</h2>
      <Grid
        container
        className="dados-paciente-inner"
        style={{
          width: "97%",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <Grid item xs={12} sm={4}>
          <p>
            <b>Nome</b>: {paciente.nome}
          </p>
        </Grid>
        <Grid item xs={12} sm={4}>
          <p>
            <b>Data nascimento</b>: {!dataFormatada && paciente.data_nascimento}
            {dataISO && dataFormatada}
          </p>
        </Grid>
        <Grid item xs={12} sm={4}>
          <p>
            <b>CPF</b>: {paciente.cpf}
          </p>
        </Grid>
        <Grid item xs={12} sm={4}>
          <p>
            <b>Sexo</b>: {paciente.sexo === "M" || paciente.sexo === "Masculino" ? "Masculino" : "Feminino"}
          </p>
        </Grid>
        <Grid item xs={12} sm={4}>
          <p>
            <b>Celular</b>: {paciente.celular || paciente.telefone}
          </p>
        </Grid>
        <Grid item xs={12} sm={4}>
          <p>
            <b>Cidade</b>: {paciente.cidade}
          </p>
        </Grid>
        <Grid item xs={12}>
          <p>
            <b>Endereço</b>: {paciente.endereco}
            {paciente.endereco_numero && `, ${paciente.endereco_numero}`}
            {paciente.bairro && ` - ${paciente.bairro}`}
            {paciente.cep && ` - ${paciente.cep}`}
          </p>
        </Grid>
      </Grid>
    </Paper>
  );
}
