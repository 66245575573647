import {
  Modal as MuiModal,
  Typography,
  Box,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  IconButton,
  Autocomplete,
  TextField,
} from "@mui/material";
import { Close, Search } from "@mui/icons-material";
import Button from "../../../../componentsv2/Button";
import Tabela from "../../../../componentsv2/Tabela";
import { Input } from "../../../../componentsv2/Input";
import { useContext, useEffect, useState } from "react";
import Paper from "../../../../componentsv2/Paper";
import { api } from "../../../../services/Api";
import { enqueueSnackbar } from "notistack";
import { Modal as ModalConfirmacao } from "../../../../componentsv2/Modal";
import { Context as PermissoesContext } from "../../../../Context/PermissoesContext";
import DataPickerAtualizado from "../../../../components/DataPickerAtualizado";
import dayjs from "dayjs";
import { RecebimentoContext } from "../../Recebimento/Context/RecebimentoContext";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const confirmModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  bgcolor: "background.paper",
  boxShadow: 24,
  maxWidth: 500,
  p: 4,
};

export default function ModalInformarFaturamento({
  open,
  onClose,
  listaConvenios,
}) {
  const [notaFiscal, setNotaFiscal] = useState("");
  const [convenio, setConvenio] = useState("");
  const [lotes, setLotes] = useState([]);

  const [totalValorLiquido, setTotalValorLiquido] = useState(0);
  const [totalValorBruto, setTotalValorBruto] = useState(0);

  const [buscarDados, setBuscarDados] = useState(false);
  const [listaValoresRecebidos, setListaValoresRecebidos] = useState([]);

  const [dataRecebimento, setDataRecebimento] = useState(new Date());

  const [openModalConfirmar, setOpenModalConfirmar] = useState(false);
  const [openModalConfirmarEstorno, setOpenModalConfirmarEstorno] =
    useState(false);

  const { checarPermissao } = useContext(PermissoesContext);
  const { setAtualizaTabela } = useContext(RecebimentoContext);

  const buscarLotesPorNota = async () => {
    try {
      const { data } = await api.get("/v2/faturamento/lotesPorNotaFiscal", {
        params: {
          nota_fiscal: notaFiscal,
          plano: convenio,
        },
      });
      setLotes(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    buscarLotesPorNota();
  }, [buscarDados]);

  useEffect(() => {
    const soma = lotes.reduce((acc, curr) => acc + curr.valor_liquido, 0);
    const somaBruto = lotes.reduce((acc, curr) => acc + curr.valor_recebido, 0);
    const listaIds = lotes.map((lote) => ({
      id: lote.id,
      valor_liquido: lote.valor_liquido,
    }));
    setListaValoresRecebidos(listaIds);
    setTotalValorLiquido(soma);
    setTotalValorBruto(somaBruto);
  }, [lotes]);

  const salvarRecebimento = async () => {
    if (!checarPermissao("FAT008")) {
      enqueueSnackbar("Você não tem permissão para realizar esta ação", {
        variant: "error",
      });
      return;
    }
    try {
      await api.put("/v2/faturamento/adicionaValoresRecebidos", {
        listaValoresRecebidos,
        dataRecebimento,
      });

      setNotaFiscal("");
      setConvenio("");
      setBuscarDados(!buscarDados);
      setOpenModalConfirmar(false);
      onClose(false);
      setAtualizaTabela((prevState) => !prevState);
      enqueueSnackbar("Valores recebidos salvos com sucesso", {
        variant: "success",
      });
    } catch (error) {
      console.log(error);
    }
  };

  const estornarRecebimento = async () => {
    if (!checarPermissao("FAT009")) {
      enqueueSnackbar("Você não tem permissão para realizar esta ação", {
        variant: "error",
      });
      return;
    }
    try {
      await api.put("/v2/faturamento/estornarValoresRecebidos", {
        listaValoresRecebidos,
      });

      setNotaFiscal("");
      setConvenio("");
      setBuscarDados(!buscarDados);
      setOpenModalConfirmarEstorno(false);
      onClose(false);
      setAtualizaTabela((prevState) => !prevState);
      enqueueSnackbar("Valores estornados com sucesso", {
        variant: "success",
      });
    } catch (error) {
      console.log(error);
    }
  };

  const columns = [
    {
      field: "id",
      headerName: "ID",
    },
    {
      field: "codigo",
      headerName: "Cod. Lote",
      flex: 1,
    },
    {
      field: "plano",
      headerName: "Convênio",
      flex: 1,
    },
    {
      field: "nota_fiscal",
      headerName: "Nota Fiscal",
      flex: 1,
    },
    {
      field: "valor_liquido",
      headerName: "Valor Líquido",
      type: "number",
      flex: 1,
      valueFormatter: ({ value }) =>
        value?.toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        }),
    },
    {
      field: "valor_recebido",
      headerName: "Valor Recebido",
      type: "number",
      flex: 1,
      valueFormatter: ({ value }) =>
        value
          ? value?.toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRL",
            })
          : "",
    },
  ];

  const buscarListaLotes = () => {
    if (!notaFiscal || !convenio) {
      enqueueSnackbar("Preencha os campos de Nota Fiscal e Convênio", {
        variant: "error",
      });
      return;
    }

    setBuscarDados(!buscarDados);
  };

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={open}
      onClose={onClose}
      scroll="paper"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle
        id="scroll-dialog-title"
        sx={{ justifyContent: "space-between" }}
      >
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography variant="h6">Informar Recebimento</Typography>
          </Grid>
          <Grid item>
            <IconButton onClick={() => onClose(false)}>
              <Close />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <MuiModal open={openModalConfirmar} onClose={setOpenModalConfirmar}>
        <Box sx={confirmModalStyle}>
          <Grid container spacing={6}>
            <Grid item>
              <Typography variant="body1" sx={{ color: "black" }}>
                Salvar Recebimento
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <DataPickerAtualizado
                labelName="Data do Recebimento"
                data={dayjs(dataRecebimento)}
                setData={setDataRecebimento}
                disableFuture
                sx={{ width: "100%" }}
              />
            </Grid>
            <Grid container justifyContent="flex-end" item spacing={2}>
              <Grid item>
                <Button
                  size="small"
                  color="gray"
                  variant="outlined"
                  onClick={() => setOpenModalConfirmar(false)}
                >
                  Cancelar
                </Button>
              </Grid>
              <Grid item>
                <Button
                  size="small"
                  variant="contained"
                  color="green"
                  onClick={() => {
                    if (lotes[0]?.data_recebimento) {
                      enqueueSnackbar("Os valores já foram recebidos", {
                        variant: "warning",
                      });
                    } else {
                      salvarRecebimento();
                    }
                  }}
                >
                  Confirmar
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </MuiModal>
      {/* <ModalConfirmacao
        confirmActionButtonColor="green"
        onClose={setOpenModalConfirmar}
        open={openModalConfirmar}
        onClick={salvarRecebimento}
      /> */}

      <ModalConfirmacao
        confirmActionButtonColor="green"
        onClose={setOpenModalConfirmarEstorno}
        open={openModalConfirmarEstorno}
        onClick={estornarRecebimento}
        modalText={"Tem certeza que deseja estornar os valores recebidos?"}
      />
      <DialogContent dividers>
        <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
          <Grid container spacing={2} direction="column">
            <Grid container item spacing={2}>
              <Grid item xs={5} md={3}>
                <Input
                  fullWidth
                  onChange={(e) => {
                    setNotaFiscal(e.target.value);
                  }}
                  value={notaFiscal}
                  label="Nota Fiscal"
                  type="text"
                />
              </Grid>
              <Grid item xs={5} md={3}>
                <Autocomplete
                  options={listaConvenios?.map((item) => item.convenio)}
                  value={convenio}
                  onChange={(event, value) => {
                    setConvenio(value);
                  }}
                  // sx={{ width: 300 }}
                  getOptionLabel={(option) => option}
                  renderInput={(params) => (
                    <TextField {...params} label="Convênio" />
                  )}
                />

                {/* <Input
                  fullWidth
                  onChange={(e) => {
                    setConvenio(e.target.value.toUpperCase());
                  }}
                  value={convenio}
                  label="Convênio"
                  type="text"
                /> */}
              </Grid>
              <Grid item xs={2} md={1}>
                <IconButton
                  onClick={buscarListaLotes}
                  sx={{
                    background: "#003399",
                    marginTop: 1,
                    ":hover": { background: "#003399" },
                  }}
                >
                  <Search sx={{ color: "white" }} />
                </IconButton>
              </Grid>
              <Grid sx={{ marginLeft: "auto" }} item xs={12} md={2}>
                <Paper>
                  <Typography
                    variant="body1"
                    sx={{ color: "black", textAlign: "center" }}
                  >
                    Total Bruto:{" "}
                    {totalValorBruto?.toLocaleString("pt-BR", {
                      style: "currency",
                      currency: "BRL",
                    })}
                  </Typography>
                </Paper>
              </Grid>
              <Grid sx={{ marginLeft: "auto" }} item xs={12} md={2}>
                <Paper>
                  <Typography
                    variant="body1"
                    sx={{ color: "black", textAlign: "center" }}
                  >
                    Total Líquido:{" "}
                    {totalValorLiquido?.toLocaleString("pt-BR", {
                      style: "currency",
                      currency: "BRL",
                    })}
                  </Typography>
                </Paper>
              </Grid>
            </Grid>
            {lotes[0]?.data_recebimento ? (
              <Grid item xs={12} sx={{ marginRight: "auto" }}>
                <Typography
                  variant="body1"
                  sx={{ color: "black", textAlign: "center" }}
                >
                  Data Faturamento:{" "}
                  {new Date(lotes[0]?.data_recebimento).toLocaleDateString()}
                </Typography>
              </Grid>
            ) : null}

            <Grid item xs={12}>
              <Box
                sx={{
                  height: 480,
                  width: "100%",
                  overflowX: "auto",
                }}
              >
                <Tabela
                  rows={lotes}
                  columns={columns}
                  pageSize={7}
                  rowsPerPageOptions={[7]}
                  initialState={{
                    columns: { columnVisibilityModel: { id: false } },
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ padding: "16px 24px" }}>
        <Grid container item justifyContent="space-between" spacing={2}>
          <Grid item>
            <Button
              color="gray"
              variant="outlined"
              onClick={() => onClose(false)}
            >
              Cancelar
            </Button>
          </Grid>
          <Grid item>
            <Button
              disabled={lotes.length === 0}
              variant="outlined"
              onClick={() => setOpenModalConfirmarEstorno(true)}
            >
              Estornar Recebimento
            </Button>
          </Grid>
          <Grid item>
            <Button
              disabled={lotes.length === 0}
              variant="contained"
              color={"green"}
              onClick={() => setOpenModalConfirmar(true)}
            >
              Salvar Recebimento
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
