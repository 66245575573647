import React, { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import {
  Autocomplete,
  Button,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  TextField,
} from '@mui/material';
import CloseIcon from "@mui/icons-material/Close";

import { validarCPF } from "../../../utils/validations";

import { api } from "../../../services/Api";

export default function AtualizaDadosModal(props) {
  const [idUsuario, setIdUsuario] = useState();
  const [idFuncionario, setIdFuncionario] = useState();

  const { abrir, setAbrir } = props;

  // const [senha, setSenha] = useState("");

  const [nome, setNome] = useState();
  const [email, setEmail] = useState();
  const [funcao, setFuncao] = useState([]);
  const [cpf, setCpf] = useState("");
  const [funcaoSelecionada, setFuncaoSelecionada] = useState("");
  const [funcaoSelecionadaSalvar, setFuncaoSelecionadaSalvar] = useState("");
  const [cpfValidado, setCpfValidado] = useState(false);
  const [helperCpf, setHelperCpf] = useState("");
  const [unidadeSelecionada, setUnidadeSeleciona] = useState("");
  const [unidades, setUnidades] = useState([]);
  const [setorSelecionado, setSetorSelecionado] = useState("");
  const [setores, setSetores] = useState([]);

  // const [confirmSenha, setConfirmSenha] = useState(false);
  // const [helper, setHelper] = useState("");
  const { enqueueSnackbar } = useSnackbar();

  // const validacaoSenha = value => {
  //   if (value !== senha) {
  //     setConfirmSenha(true);
  //     setHelper("Senhas diferentes");
  //   } else {
  //     setConfirmSenha(false);
  //     setHelper("");
  //   }
  // };

  const validacaoCPF = value => {
    const validaCPF = validarCPF(value);
    if (validaCPF !== true) {
      setCpfValidado(true);
      setHelperCpf("CPF não existe");
    } else {
      setCpfValidado(false);
      setHelperCpf("");
    }
  };

  useEffect(() => {
    async function chamafuncao() {
      try {
        // const resultFuncionario = await api.get("/v2/controleAcesso/funcionario", {
        //   params: { cod_usu: props.usuario },
        // });

        const { data } = await api.get("/v2/controleAcesso/usuario", {
          params: { username: props.usuario },
        });

        const getFuncoes = await api.get("/v2/arh/funcoes");
        const getSetores = await api.get("/v2/arh/setores");
        const getUnidades = await api.get("/v2/geral/unidades");

        const funcaoFuncionario = getFuncoes.data.funcoes.filter(
          funcao => funcao.codigo === data.usuario.funcionario.cod_funcao
        );

        setSetorSelecionado(data.usuario.funcionario.lotacao);
        setUnidades(getUnidades.data.unidade);
        setFuncao(getFuncoes.data.funcoes);
        setSetores(getSetores.data.setores);
        setCpf(data.usuario.funcionario.cpf);
        setFuncaoSelecionada(...funcaoFuncionario);
        setUnidadeSeleciona(data.usuario.funcionario.unidade_id);
        setCpf(data.usuario.funcionario.cpf);
        setIdFuncionario(data.usuario.funcionario.id);
        setIdUsuario(data.usuario.id);

        setNome(data.usuario.funcionario.nome_usu);
        setEmail(data.usuario.funcionario.email_usu);
      } catch (error) {
        enqueueSnackbar("Falha ao obter os dados", {
          variant: "error",
        });
      }
    }
    chamafuncao();
    // eslint-disable-next-line
  }, [enqueueSnackbar]);

  const formData = new FormData();

  formData.append("email_usu", email);
  formData.append("nome_usu", nome);
  // formData.append("password", senha);
  formData.append("funcionario_id", idFuncionario);
  formData.append("setor_id", setorSelecionado);
  formData.append("unidade_id", unidadeSelecionada);
  formData.append("cpf", cpf);
  formData.append("cod_funcao", funcaoSelecionadaSalvar);

  async function atualizaDados() {
    try {
      await api.put(`/v2/controleAcesso/usuario/${idUsuario}`, formData);
      enqueueSnackbar("Os dados foram atualizados com sucesso", {
        variant: "success",
      });

      props.setAbrir(false);
    } catch (error) {
      enqueueSnackbar("Falha ao atualizar os dados, tente novamente", {
        variant: "error",
      });
    }
  }

  function handleChange(event) {
    setUnidadeSeleciona(event.target.value);
  }

  function handleFuncao(e) {
    setFuncaoSelecionada(e);
    setFuncaoSelecionadaSalvar(e.codigo);
  }

  function handleSetor(e) {
    setSetorSelecionado(e);
  }

  const bodyModal = (
    <Container maxWidth="md" style={{ padding: "0" }}>
      <Paper className="paper-cadastro" elevation={2}>
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          style={{ padding: "1rem 1rem" }}
        >
          <Grid item xs={6} sm={6}>
            <h2>Cadastrar um usuário</h2>
          </Grid>
          <Grid id="botao-fechar" item xs={6} sm={6}>
            <Button type="button" onClick={() => props.setAbrir(false)}>
              <CloseIcon color="primary" fontSize="medium" />
            </Button>
          </Grid>
          <Grid className="input-text-modal" item xs={12} sm={6}>
            <TextField
              defaultValue=" "
              label="Nome completo"
              variant="outlined"
              size="small"
              fullWidth
              //  eslint-disable-next-line
              value={nome}
              onChange={e => setNome(e.target.value)}
            />
          </Grid>
          <Grid className="input-text-modal" item xs={12} sm={4}>
            <TextField
              label="CPF"
              variant="outlined"
              size="small"
              fullWidth
              type="text"
              value={cpf}
              onChange={event => {
                setCpf(event.target.value);
                validacaoCPF(event.target.value);
              }}
              error={cpfValidado}
              helperText={helperCpf}
            />
          </Grid>
          <Grid className="input-text-modal" item xs={12} sm={10}>
            <FormControl size="small" variant="outlined" fullWidth>
              <InputLabel id="select-label">Unidade</InputLabel>
              <Select
                value={unidadeSelecionada}
                onChange={e => handleChange(e)}
                labelId="select-label"
                id="select"
                label="Unidade"
              >
                {unidades.map(unidade => (
                  <MenuItem key={unidade.id} value={unidade.id}>
                    {unidade.nome}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid className="input-text-modal" item xs={12} sm={10}>
            <FormControl defaultValue=" " size="small" variant="outlined" fullWidth>
              <InputLabel id="select-label">Setor</InputLabel>
              <Select
                labelId="select-label"
                id="select"
                label="Ramal"
                value={setorSelecionado}
                onChange={e => handleSetor(e.target.value)}
              >
                {setores.map(setores => (
                  <MenuItem key={setores.id} value={setores.id}>
                    {setores.nome}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid className="input-text-modal" item xs={12} sm={10}>
            <Autocomplete
              options={funcao}
              getOptionLabel={option =>
                `${option.funcao} (${option.codigo})`
                  ? `${option.funcao} (${option.codigo})`
                  : ""
              }
              value={funcaoSelecionada}
              onChange={(e, value) => handleFuncao(value)}
              size="small"
              renderInput={params => (
                <TextField {...params} label="Função" variant="outlined" />
              )}
            />
          </Grid>
          <Grid className="input-text-modal" item xs={12} sm={4}>
            <TextField
              defaultValue=" "
              label="Usuário"
              variant="outlined"
              size="small"
              fullWidth
              //  eslint-disable-next-line
              value={props.usuario}
            />
          </Grid>
          <Grid className="input-text-modal" item xs={12} sm={6}>
            <TextField
              defaultValue=" "
              label="E-mail"
              variant="outlined"
              size="small"
              fullWidth
              //  eslint-disable-next-line
              value={email}
              onChange={e => setEmail(e.target.value)}
            />
          </Grid>

          {/* <Grid className="input-text-modal" item xs={12} sm={5}>
            <TextField
              type="password"
              label="Senha"
              variant="outlined"
              size="small"
              fullWidth
              onChange={e => setSenha(e.target.value)}
            />
          </Grid>
          <Grid className="input-text-modal" item xs={12} sm={5}>
            <TextField
              type="password"
              label="Repetir senha"
              variant="outlined"
              size="small"
              fullWidth
              onChange={event => validacaoSenha(event.target.value)}
              error={confirmSenha}
              helperText={helper}
            />
          </Grid> */}
          <Grid className="input-text-modal" id="botao-cadastrar" item xs={10}>
            <Button onClick={atualizaDados} variant="contained" color="primary">
              Salvar
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
  return (
    <Modal open={abrir} onClose={() => setAbrir(false)}>
      {bodyModal}
    </Modal>
  );
}
