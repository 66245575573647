/* eslint-disable react/jsx-curly-newline */
import React, {
  useState,
  useEffect,
  useMemo,
  useContext,
  useCallback,
} from "react";
import { useSnackbar } from "notistack";
import { format } from "date-fns";

import {
  FormControl,
  InputLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  TableCell,
  TableRow,
} from "@mui/material";

import SettingsIcon from "@mui/icons-material/Settings";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import EditIcon from "@mui/icons-material/Edit";

import Tabela from "../../../components/Tabela";
import Button from "../../../components/Button";
import Dialog from "../../../components/Dialog";
import Loading from "../../../components/Loading";
import Modal from "../../../components/Modal";

import formatarData from "../../../utils/formatarData";

import { Context } from "../../../Context/PermissoesContext";

import useDates from "../../../hooks/useDates";
import ExamDialog, { useDialog } from "../ExamDialog";

import { api } from "../../../services/Api";
import dayjs from "dayjs";

import DataPickerAtualizado from "../../../components/DataPickerAtualizado";

export default function Agendamento() {
  const { checarPermissaoComAcao, checarPermissao, checarPermissaoSemLog } =
    useContext(Context);

  // Zera a hora do dia atual para poder fazer o filtro na consulta
  const diaAtual = format(new Date(), "yyyy-MM-dd 00:00:00");
  // Converte o dia para o formato Date
  const hoje = new Date(diaAtual);
  const dataFimTresMeses = useMemo(() => {
    const today = new Date(diaAtual);
    const day = today.getDate();
    let month = today.getMonth();
    let year = today.getFullYear();
    for (let i = 0; i < 3; i += 1) {
      if (month === 11) {
        month = 0;
        year += 1;
      } else {
        month += 1;
      }
    }
    return new Date(year, month, day);
  }, [diaAtual]);

  const header = useMemo(() => {
    if (checarPermissao("AGP007", false)) {
      return [
        "Cliente",
        "Nº exame",
        "Tipo do exame",
        "Unidade",
        "Data/Horário",
        "Usuário",
        "Opções",
      ];
    }

    return ["Tipo do exame", "Unidade", "Data/Horário", "Usuário", "Opções"];
  }, [checarPermissao]);
  const [showOptionsDialog, setShowOptionsDialog] = useState(false);
  const { dataInicio, setDataFim, setDataInicio, dataFim } = useDates(
    hoje,
    dataFimTresMeses
  );
  const [unidade, setUnidade] = useState("todas");
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [exames, setExames] = useState([]);

  const [showModal, setShowModal] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const { data } = await api.get(`/v2/agendaPaternidade/exames`, {
          params: {
            dataInicio,
            dataFim,
            offset: page * 9,
            unidade,
          },
        });
        setExames(data.exames);
        setCount(data.count);
      } catch (e) {
        enqueueSnackbar("Algo deu errado. Por favor, tente novamente", {
          variant: "error",
          autoHideDuration: 3000,
        });
      }
      setLoading(false);
    })();
  }, [dataInicio, dataFim, page, enqueueSnackbar, unidade]);

  const {
    showDialog,
    setShowDialog,
    dialogValues,
    setDialogValues,
    modoDialog,
    setModoDialog,
    exameSelecionado,
    setExameSelecionado,
  } = useDialog();

  const nomeUnidade = useCallback((idUnidade) => {
    let unidadeTabela;
    switch (idUnidade) {
      case 41:
        unidadeTabela = "Major Laurentino";
        break;
      case 47:
        unidadeTabela = "Cidade da Esperança";
        break;
      case 49:
        unidadeTabela = "Shopping Estação";
        break;
      case 64:
        unidadeTabela = "Seaway";
        break;
      default:
    }
    return unidadeTabela;
  }, []);

  // abre o dialog de exame em modo visualizacao ou de edicao
  // com um exame sendo o selecionado
  function abrirDialogExameComAlvo(indiceAlvo, modo) {
    setModoDialog(modo);
    setDialogValues({
      ...exames[indiceAlvo],
      nome_cliente: exames[indiceAlvo].nome_cliente,
      cpf_cliente: exames[indiceAlvo].cpf_cliente,
      celular_cliente: exames[indiceAlvo].celular_cliente,
      autorizacao: exames[indiceAlvo].autorizacao,
      id: exames[indiceAlvo].id,
      identificador:
        exames[indiceAlvo].identificador === null
          ? ""
          : exames[indiceAlvo].identificador,
      numero_processo: exames[indiceAlvo].numero_processo,
      comarca: exames[indiceAlvo].comarca,
      id_horario: exames[indiceAlvo].horario.id,
      tipo_exame: exames[indiceAlvo].tipo,
      id_unidade: exames[indiceAlvo].horario.id_unidade,
    });
    setShowOptionsDialog(false);
    setShowDialog(true);
  }

  async function deletarExame() {
    checarPermissaoComAcao("AGP004", async () => {
      const { id } = exames[exameSelecionado];
      setLoading(true);
      try {
        await api.delete(`/v2/agendaPaternidade/exame/${id}`);
        enqueueSnackbar("Exame deletado!", {
          variant: "success",
          autoHideDuration: 3000,
        });

        const copiaExames = [...exames];
        copiaExames.splice(exameSelecionado, 1);
        setExames(copiaExames);
      } catch (e) {
        enqueueSnackbar("Não foi possível deletar este exame.", {
          variant: "error",
          autoHideDuration: 3000,
        });
      }
      setShowOptionsDialog(false);
      setExameSelecionado(-1);
      setLoading(false);
      setShowModal(false);
      return setCount((prevState) => prevState - 1);
    });
  }

  return (
    <>
      {loading && <Loading />}
      <Modal
        open={showModal}
        title="Excluir exame"
        text="Tem certeza de que deseja excluir este exame?"
        onConfirm={() => deletarExame()}
        onCancel={() => setShowModal(false)}
      />
      <ExamDialog
        open={showDialog}
        setOpen={setShowDialog}
        modo={modoDialog}
        dialogValues={dialogValues}
        setDialogValues={setDialogValues}
        exameSelecionado={exameSelecionado}
        setExameSelecionado={setExameSelecionado}
        setExames={setExames}
        exames={exames}
        dataInicio={dataInicio}
        dataFim={dataFim}
        setCount={setCount}
        pagina="agendamento"
      />
      <Dialog open={showOptionsDialog} title="Opções">
        <List>
          <ListItem
            onClick={() =>
              abrirDialogExameComAlvo(exameSelecionado, "visualizar")
            }
          >
            <ListItemIcon>
              <VisibilityIcon />
            </ListItemIcon>
            <ListItemText>Visualizar</ListItemText>
          </ListItem>
          <ListItem
            onClick={() =>
              checarPermissaoComAcao("AGP003", () =>
                abrirDialogExameComAlvo(exameSelecionado, "editar")
              )
            }
          >
            <ListItemIcon>
              <EditIcon />
            </ListItemIcon>
            <ListItemText>Editar</ListItemText>
          </ListItem>
          <ListItem onClick={() => setShowModal(true)}>
            <ListItemIcon>
              <DeleteIcon />
            </ListItemIcon>
            <ListItemText>Deletar</ListItemText>
          </ListItem>
          <ListItem
            onClick={() => {
              setShowOptionsDialog(false);
              setExameSelecionado(-1);
            }}
          >
            <ListItemIcon>
              <KeyboardBackspaceIcon />
            </ListItemIcon>
            <ListItemText>Voltar</ListItemText>
          </ListItem>
        </List>
      </Dialog>
      <Paper className="agenda-paternidade-topo">
        <div className="agenda-paternidade-datas">
          <DataPickerAtualizado
            data={dataInicio}
            setData={setDataInicio}
            variant="standard"
            labelName="Data"
          />
          {/* <DatePicker
            format="dd/MM/yyyy"
            style={{ marginRight: "1rem", width: "30%" }}
            label="Data inicial"
            value={dayjs(dataInicio)}
            onChange={setDataInicio}
          /> */}
          <DataPickerAtualizado
            data={dayjs(dataFim)}
            setData={setDataFim}
            variant="standard"
            labelName="Data"
          />
          {/* <DatePicker
            format="dd/MM/yyyy"
            label="Data final"
            style={{ marginRight: "1rem", width: "30%" }}
            value={dayjs(dataFim)}
            onChange={setDataFim}
          /> */}
          <FormControl style={{ width: "30%" }}>
            <InputLabel id="unidade-selecionada-label">Unidade</InputLabel>
            <Select
              labelId="unidade-selecionada-label"
              value={unidade}
              onChange={(e) => setUnidade(e.target.value)}
            >
              <MenuItem value="todas">Todas</MenuItem>
              <MenuItem value={47}>Cidade da Esperança</MenuItem>
              <MenuItem value={49}>Shopping Estação</MenuItem>
              <MenuItem value={41}>Major Laurentino</MenuItem>
              <MenuItem value={64}>Seaway</MenuItem>
            </Select>
          </FormControl>
        </div>
        <Button
          style={{ fontSize: "1.1rem" }}
          onClick={() =>
            checarPermissaoComAcao("AGP002", () => setShowDialog(true))
          }
        >
          AGENDAR
        </Button>
      </Paper>
      <Tabela header={header} setPage={setPage} page={page} count={count}>
        {exames.map((exame, index) => (
          <TableRow key={exame.id}>
            {checarPermissaoSemLog("AGP007", false) && (
              <>
                <TableCell align="center">{exame.nome_cliente}</TableCell>
                <TableCell align="center">
                  {exame.identificador || "-"}
                </TableCell>
              </>
            )}
            <TableCell align="center">{exame.tipo}</TableCell>
            <TableCell align="center">
              {nomeUnidade(exame.horario.id_unidade)}
            </TableCell>
            <TableCell align="center">
              {formatarData(new Date(exame.data), false)} - {exame.horario.hora}
              :{exame.horario.minutos === 0 && "0"}
              {exame.horario.minutos}
            </TableCell>
            <TableCell align="center">{exame.usuario}</TableCell>
            <TableCell align="center">
              <SettingsIcon
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setShowOptionsDialog(true);
                  setExameSelecionado(index);
                }}
              />
            </TableCell>
          </TableRow>
        ))}
      </Tabela>
    </>
  );
}
