import React, { createContext, useState, useEffect, useContext } from "react";
import { useSnackbar } from "notistack";

import Loading from "../components/Loading";

import { Context as AuthContext } from "./AuthContext";

import { api } from "../services/Api";

import SalvaLog from "../utils/SalvaLog";

const Context = createContext();

function PermissoesProvider({ children }) {
  const [permissoes, setPermissoes] = useState([]);
  const [todasPermissoes, setTodasPermissoes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { authenticated } = useContext(AuthContext);

  useEffect(() => {
    (async () => {
      if (authenticated) {
        const username = localStorage.getItem("@DNACenter:codigoUsuario");

        setLoading(true);
        try {
          const {
            data: {
              usuario: { id },
            },
          } = await api.get(`/v2/controleAcesso/usuario?username=${username}`);
          const {
            data: { permissoes: permissoesUsuario },
          } = await api.get(`/v2/controleAcesso/usuario/${id}/permissoes`);
          setPermissoes(permissoesUsuario);

          const {
            data: { modulos },
          } = await api.get(`/v2/controleAcesso/modulosEPermissoes`);
          setTodasPermissoes(modulos);
        } catch (e) {
          // descomentar quando implementar o controle de acesso de maneira mais geral
          /*
          enqueueSnackbar(
            "Não foi possível recuperar suas permissões. Suas ações serão limitadas.",
            {
              variant: "error",
              autoHideDuration: 3000,
            }
          );
          */
          console.log(e);
        }
        setLoading(false);
      } else {
        setPermissoes([]);
      }
    })();
  }, [authenticated, reload, enqueueSnackbar]);

  // checa permissao e se tiver, executa uma acao
  const checarPermissaoComAcao = (codigo, acao) => {
    const temPermissao = permissoes.find(permissao => permissao.codigo === codigo);
    if (temPermissao) {
      acao();
      return;
    }

    enqueueSnackbar(`Você não tem permissão para realizar esta ação. Código: ${codigo}`, {
      variant: "error",
      autoHideDuration: 3000,
    });
  };

  // somente checa uma permissao
  const checarPermissao = (codigo, log = true) => {
    const permissaoExiste =
      permissoes.find(permissao => {
        if (permissao.modulo && permissao.codigo === codigo) {
          if (log) {
            SalvaLog(permissao.nome, permissao.modulo.nome, `Tem permissão: ${codigo}`);
          }
          return permissao.codigo;
        }
        return null;
      }) !== undefined;

    if (permissaoExiste) {
      // SalvaLog("checarPermissao", `Usuário: ${usuario}`, `Tem permissão: ${codigo}`);
      return permissaoExiste;
    }
    if (log) {
      todasPermissoes.find(permissao => {
        if (permissao.modulo && permissao.codigo === codigo) {
          if (log) {
            SalvaLog(
              permissao.nome,
              permissao.modulo.nome,
              `Tentou usar permissão: ${codigo}`
            );
          }
          return permissao.codigo;
        }
        return null;
      });
    }
    return null;
  };

  const checarPermissaoSemLog = codigo => checarPermissao(codigo, false);

  const sobreescreverPermissoes = novasPermissoes => {
    setPermissoes(novasPermissoes);
  };

  const atualizarPermissoes = (permissoesModuloModificadas, idModulo) => {
    // todas as permissoes que nao sao desse modulo
    const permissoesOutrosModulos = permissoes.filter(
      permissao => parseInt(permissao.id_modulo, 10) !== parseInt(idModulo, 10)
    );
    setPermissoes([...permissoesOutrosModulos, ...permissoesModuloModificadas]);
  };

  return (
    <Context.Provider
      value={{
        checarPermissaoComAcao,
        permissoes,
        checarPermissao,
        sobreescreverPermissoes,
        atualizarPermissoes,
        checarPermissaoSemLog,
        setReload,
      }}
    >
      {loading && <Loading />}
      {children}
    </Context.Provider>
  );
}

export { Context, PermissoesProvider };
