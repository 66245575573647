import React, { useCallback } from 'react';
import { TextField } from '@mui/material';
import { format } from 'date-fns';

import "./styles.css";

export default function Pesquisa(props) {
  const { codigo_pedido, setCodigo_pedido, pesquisaAmostra, resultado_pesquisa_amostra } = props;

  const tempoRestante = useCallback(
    (data_inicio) => {
      let diferenca = 0;

      try {
        const dataTerminoH = format(new Date(), "HH");
        const dataTerminoM = format(new Date(), "mm");

        const dataInicioH = data_inicio.substr(-5, 2);
        const dataInicioM = data_inicio.substr(-2);

        if (dataInicioH > dataTerminoH) {
          return diferenca
        }

        const inicio = (Number(dataInicioH)*60) + Number(dataInicioM);
        const final = (Number(dataTerminoH)*60) + Number(dataTerminoM);

        diferenca = final - inicio;

        if ((90 - diferenca) > 0) {
          diferenca = 90 - diferenca;
        } else {
          diferenca = 0;
        }

      } catch (error) {
        diferenca = '';
      }

      return diferenca;
    }, []);



  return (
    <div>
      <div className="container-form-search-samples">
        <span className="label-search-samples">Informe o código do pedido ou o número da amostra</span>
        <div>
          <TextField
            autoFocus
            autoComplete="off"
            label="Pedido ou Nº Amostra"
            value={codigo_pedido}
            onChange={(e) => setCodigo_pedido(e.target.value.toUpperCase())}
            onKeyUpCapture={(e) => {
              if (e.key === "Enter") {
                pesquisaAmostra();
              }
            }}
            style={{fontSize: "2rem", width: "15rem"}}
          />
          <button
            type="button"
            id="button-search-samples"
            onClick={() => {
              pesquisaAmostra();
            }}
          >
            <i className="fa fa-search" aria-hidden="true" />
          </button>
        </div>

      </div>

      {resultado_pesquisa_amostra.map((row) => (

        <div key={row.id} className="container-form-search-samples">
          <span className="label-search-samples">INFORMAÇÕES DA AMOSTRA </span>

          <div className="container-result-search-samples">
            <span className="label-left-result-search-samples">Código do Pedido</span>
            <span className="label-right-result-search-samples">{row.pedido}</span>

            <span className="label-left-result-search-samples">Tipo da Amostra</span>
            <span className="label-right-result-search-samples">{row.tipo}</span>

            <span className="label-left-result-search-samples">Número</span>
            <span className="label-right-result-search-samples">{row.numero}</span>

            <span className="label-left-result-search-samples">Sigla</span>
            <span className="label-right-result-search-samples">{row.sigla}</span>

            <span className="label-left-result-search-samples">Nome do Cliente</span>
            <span className="label-right-result-search-samples">{row.nome_cliente}</span>

            <span className="label-left-result-search-samples">Triado em</span>
            <span className="label-right-result-search-samples">{row.data_hora_amostra}</span>

            <span className="label-left-result-search-samples">Id da Placa</span>
            <span className="label-right-result-search-samples">{row.id_placa}</span>

            <span className="label-left-result-search-samples">Status da Placa</span>
            <span className="label-right-result-search-samples">{row.status_placa}</span>

            <span className="label-left-result-search-samples">Id do Mapa</span>
            <span className="label-right-result-search-samples">{row.id_mapa}</span>

            <span className="label-left-result-search-samples">Nome do Mapa</span>
            <span className="label-right-result-search-samples">{row.nome_mapa}</span>

            <span className="label-left-result-search-samples">Status do Mapa</span>
            <span className="label-right-result-search-samples">
              { (row.status_mapa === 'Em processamento')
                  ? `${row.status_mapa} (Aprox. ${tempoRestante(row.hora_processamento) || 0} min restantes)`
                  : row.status_mapa}
            </span>

            <span className="label-left-result-search-samples">Sequencial</span>
            <span className="label-right-result-search-samples">{row.sequencial}</span>

            <span className="label-left-result-search-samples">Mapa criado em</span>
            <span className="label-right-result-search-samples">{row.data_hora_mapa}</span>
          </div>

        </div>

       ))}

    </div>
  )
}
