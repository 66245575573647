import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Button,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState, useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Header from "../../../../components/Header";
import useUser from "../../../../hooks/useUser";
import { api } from "../../../../services/Api";
import { colorTheme } from "../../../../theme";
import atualizaExibicaoData from "../../../../utils/atualizaExibicaoData";
import filtroPesquisa from "../../../../utils/filtroPesquisa";
import SalvaLog from "../../../../utils/SalvaLog";
import MyModal from "../../../../components/Modal";

import { Context as PermissoesContext } from "../../../../Context/PermissoesContext";
import Tabela from "../../../../componentsv2/Tabela";
import { GridCellEditStopReasons } from "@mui/x-data-grid";

import { Modal as ModalComponent } from "../../../../componentsv2/Modal";

import { Input } from "../../../../componentsv2/Input";
import { fontSize } from "@mui/system";
import SelectLocal from "../../components/SelectLocal";
import AutocompleteEstoque from "../../components/AutocompleteEstoque";
import AutocompleteEstoquesTransferencia from "./components/AutocompleteEstoquesTransferencia";
import useDadosContextEstoque from "../../hooks/useDadosContextEstoque";

export default function CriaTransferencia() {
  const history = useHistory();
  const usuario = useUser();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const { checarPermissao } = useContext(PermissoesContext);

  const theme = useTheme();
  const tamanho = useMediaQuery(theme.breakpoints.up("md"));

  const edicaoTransferencia = location.state;

  const [open, setOpen] = useState(false);
  const [unidades, setUnidades] = useState([]);
  const [unidadeInicial, setUnidadeInicial] = useState("");
  const [unidadeFinal, setUnidadeFinal] = useState("");

  const [codigoBarrasInserido, setCodigoBarrasInserido] = useState("");
  const [ativaTabela, setAtivaTabela] = useState(false);
  const [tabelaDados, setTabelaDados] = useState([]);
  const [produtosPorUnidade, setProdutosPorUnidade] = useState([]);
  const [produtoSelecionado, setProdutoSelecionado] = useState([]);
  const [idTransferencia, setIdTransferencia] = useState(null);
  const [requisicaoFortes, setRequisicaoFortes] = useState("");

  const [manterTabelaVisível, setManterTabelaVisivel] = useState(true);
  const [ativaBotaoAdicionar, setAtivaBotaoAdicionar] = useState(false);
  const [ativaBotaoExcluir, setAtivaBotaoExcluir] = useState(false);
  const [semAcessoUnidade, setSemAcessoUnidade] = useState(false);
  const [permissaoCancelar, setPermissaoCancelar] = useState(false);

  const [disableButtonEnviar, setDisableButtonEnviar] = useState(false);

  const [dadosEdicao, setDadosEdicao] = useState([]);

  const [openModal, setOpenModal] = useState(false);

  const [update, setUpdate] = useState(false);

  const [quantidadeCell, setQuantidadeCell] = useState();

  const [unidadeOrigem, setUnidadeOrigem] = useState("");
  const [estoqueOrigem, setEstoqueOrigem] = useState({ nome: "", id: "" });

  const [unidadeDestino, setUnidadeDestino] = useState("");
  const [estoqueDestino, setEstoqueDestino] = useState({
    nome: "",
    id: "",
  });

  const [transf, setTransf] = useState("");
  const [listaQuantidade, setListaQuantidade] = useState([]);

  const { update: updateTabelaEstoque, setUpdate: setUpdateTabelaEstoque } =
    useDadosContextEstoque();

  async function checaPermissaoCancelarTransferencia() {
    if (await checarPermissao("AL015")) {
      setPermissaoCancelar(true);
    }
  }

  const excluirProduto = async (id) => {
    try {
      await api.post(`/v2/almoxarifado/transferencia/produto/excluir/${id}`);
      const itens = tabelaDados.filter((item) => item.id !== id);
      setTabelaDados(itens);
      SalvaLog(`Produto removido`, "Almoxarifado", id);
      enqueueSnackbar("Produto removido com sucesso!", { variant: "success" });
    } catch (e) {
      enqueueSnackbar("Erro ao excluir o produto", { variant: "error" });
    }
  };

  const handleProdutoSelecionado = (e) => {
    setProdutoSelecionado(e);
  };

  const columnsProdutos = [
    {
      field: "id",
      headername: "id",
      headerAlign: "center",
      hide: true,
    },
    {
      field: "nome",
      headerName: "Nome",
      width: 290,
      headerAlign: "center",
      align: "left",
      valueGetter: ({ row }) => row?.produto?.nome,
    },
    {
      field: "codigo_produto",
      headerName: "Cod. de barras",
      width: 200,
      headerAlign: "center",
      align: "center",
      valueGetter: ({ row }) => row?.produto?.codigo_produto,
    },
    {
      field: "quantidade",
      headerName: "Quantidade",
      width: 180,
      headerAlign: "center",
      align: "center",
    },
  ];

  const columns = [
    {
      field: "id",
      headername: "id",
      headerAlign: "center",
      hide: true,
    },
    {
      field: "nome",
      headerName: "Nome",
      flex: 1,
      headerAlign: "center",
      align: "center",
      valueGetter: ({ row }) => row?.produtoEstoque?.produto?.nome,
    },
    // {
    //   field: "codigo_barra",
    //   headerName: "Código de barras",
    //   width: 200,
    //   headerAlign: "center",
    //   align: "center",
    //   // editable: true,
    // },

    {
      field: "quantidade_atual",
      headerName: "Em estoque",
      flex: 1,
      headerAlign: "center",
      align: "center",
      valueGetter: ({ row }) => row?.produtoEstoque?.quantidade,
    },
    // {
    //   field: "quantidade",
    //   headerName: "Quantidade",
    //   width: 180,
    //   headerAlign: "center",
    //   align: "center",
    //   editable: true,
    // },
    {
      field: "quantidade",
      headerName: "Quantidade",
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <div>
          <Input
            type="number"
            variant="standard"
            InputProps={{
              disableUnderline: true,
              inputProps: {
                style: {
                  textAlign: "center",
                  fontSize: "15px",
                  appearance: "none",
                },
              },
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.target.blur();
              }
            }}
            onBlur={(e) => {
              handleOnBlur(params, e.target.value);
            }}
          />
        </div>
      ),
    },
    {
      field: "remover",
      headerName: "Remover",
      width: 120,
      headerAlign: "center",
      align: "center",
      type: "number",
      editable: false,
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (params) => (
        <div
          style={{
            cursor: "pointer",
          }}
        >
          <EditIcon
            style={{ color: "#000", cursor: "pointer", marginRight: "15px" }}
            onClick={() => handleEditModal(params.row)}
            size="small"
            fontSize="small"
          />
          <DeleteIcon
            style={{ color: "#000", cursor: "pointer", marginRight: "15px" }}
            onClick={() => excluirProduto(params.row.id)}
            size="small"
            fontSize="small"
          />
        </div>
      ),
    },
  ];

  const handleOnBlur = async (params, eventValue) => {
    try {
      if (
        !eventValue ||
        eventValue <= 0 ||
        eventValue > params?.row?.produtoEstoque?.quantidade
      ) {
        enqueueSnackbar("Insira uma quantidade válida ", {
          variant: "warning",
        });
        return;
      }

      setListaQuantidade((prevState) => {
        const existingIndex = prevState.findIndex(
          (item) => item.id_produto_estoque === params?.row?.produto_estoque_id
        );

        if (existingIndex !== -1) {
          // Modificar a quantidade do item existente
          const updatedState = [...prevState];
          updatedState[existingIndex].quantidade = eventValue;
          return updatedState;
        } else {
          // Adicionar novo item
          return [
            ...prevState,
            {
              quantidade: eventValue,
              id_produto_estoque: params?.row?.produto_estoque_id,
            },
          ];
        }
      });

      // await api.put(
      //   `/v2/almoxarifado/transferencia/produto/editar/${idTransferencia}`,
      //   {
      //     id_produto_estoque: params?.row?.produto_estoque_id,
      //     quantidade: eventValue,
      //   }
      // );

      enqueueSnackbar("Quantidade alterada com sucesso", {
        variant: "success",
      });
    } catch (error) {
      enqueueSnackbar("Erro ao alterar a quantidade", { variant: "warning" });
      console.log("Erro em edição de quantidades -", error);
    }
  };

  const getProdutosPorEstoque = async () => {
    if (!estoqueOrigem.id) return;
    try {
      const result = await api.get(
        `/v2/almoxarifado/estoque/unidade/${estoqueOrigem.id}`
      );
      setProdutosPorUnidade(result.data.obtemProdutoUnidade);
    } catch (error) {
      enqueueSnackbar("Não há produtos nessa unidade", { variant: "warning" });
    }
  };

  useEffect(() => {
    getProdutosPorEstoque();
  }, [estoqueOrigem]);

  useEffect(() => {
    if (edicaoTransferencia) {
      setIdTransferencia(edicaoTransferencia.dados.id);
      //handleChangeInitLocal(edicaoTransferencia.dados.estoque_origem_id);
      setUnidadeFinal(edicaoTransferencia.dados.estoque_destino_id);
      setRequisicaoFortes(edicaoTransferencia.dados.requisicao_fortes);
      setManterTabelaVisivel(true);
      setAtivaTabela(true);
      setAtivaBotaoAdicionar(true);
      setAtivaBotaoExcluir(true);
    }

    async function obterUnidadesLocal() {
      try {
        const result = await api.get("/v2/almoxarifado/estoque/unidades");
        setUnidades(result.data.unidades);
        const { data } = await api.get(`v2/controleAcesso/funcionarioUnidade`, {
          params: { cod_usu: usuario },
        });

        //handleChangeInitLocal(data.funcionario.unidade_id);
        if (!(await checarPermissao("AL014"))) {
          setSemAcessoUnidade(true);
        }
      } catch (e) {
        enqueueSnackbar("Erro ao buscar unidades", { variant: "error" });
      }
    }

    obterUnidadesLocal();

    checaPermissaoCancelarTransferencia();

    // eslint-disable-next-line
  }, [enqueueSnackbar, update, idTransferencia, edicaoTransferencia]);

  async function obterProdutosTransferencia() {
    try {
      const result = await api.get(
        `/v2/almoxarifado/transferencia/produto/obter/${transf}`
      );

      setTabelaDados(
        atualizaExibicaoData(result.data.obtemProdutosTransferencia, "validade")
      );
    } catch (e) {
      enqueueSnackbar("Não foi possível obter as transferências cadastradas", {
        variant: "error",
      });
    }
  }

  useEffect(() => {
    if (transf) {
      obterProdutosTransferencia();
    }
  }, [transf]);

  const handleDadosInseridos = async () => {
    const todosProdutosSelecionados = produtoSelecionado?.map((produto) => {
      return {
        transferenciaId: idTransferencia,
        produtoEstoqueId: produto,
      };
    });

    try {
      await api.post("/v2/almoxarifado/transferencia/produto/criar", {
        produtos: todosProdutosSelecionados,
      });

      setUpdate(!update);
      setCodigoBarrasInserido("");
      setOpen(false);
      setManterTabelaVisivel(true);
      SalvaLog(
        `Produto adicionado à transfrência`,
        "Almoxarifado",
        idTransferencia
      );
      setTransf(idTransferencia);
      enqueueSnackbar("Produto adicionado à transfrência com sucesso", {
        variant: "success",
      });
    } catch (e) {
      enqueueSnackbar("Erro ao adicionar o produto", { variant: "error" });
    }
  };

  const criarTransferencia = async () => {
    try {
      const transferencia = await api.post(
        "/v2/almoxarifado/transferencia/criar",
        {
          id_estoque_origem: estoqueOrigem?.id,
          id_estoque_destino: estoqueDestino?.id,
          data_solicitacao: new Date(),
          requisicaoFortes,
        }
      );

      setIdTransferencia(transferencia.data.criaTransferencia.id);
      setAtivaTabela(true);
      setAtivaBotaoAdicionar(true);
      setOpen(true);
      SalvaLog(
        `Transferencia criada`,
        "Almoxarifado",
        transferencia.data.criaTransferencia.id
      );
      enqueueSnackbar("Transferencia criada com sucesso!", {
        variant: "success",
      });
    } catch (e) {
      enqueueSnackbar("Erro ao criar a transferência", { variant: "error" });
    }
  };

  const handleMovimentacao = async () => {
    setOpenModal(true);
  };

  const handleOpenModal = () => {
    if (produtosPorUnidade.length === 0) {
      enqueueSnackbar("Essa unidade não possui estoque para transferência", {
        variant: "warning",
      });
    } else {
      setOpen(true);
    }
  };

  const handleCancelaTransferencia = async () => {
    try {
      await api.post("/v2/almoxarifado/transferencia/cancelar", {
        id_transferencia: idTransferencia,
      });
      SalvaLog(`Transferência cancelada`, "Almoxarifado", idTransferencia);
      enqueueSnackbar("Transferência cancelada com sucesso", {
        variant: "success",
      });
      history.push({
        pathname: "/almoxarifado",
        state: { tab: true },
      });
    } catch (e) {
      enqueueSnackbar("Erro ao concluir a transferência", { variant: "error" });
    }
  };

  const handleAceitaConfirmacao = async () => {
    try {
      setDisableButtonEnviar(true);
      listaQuantidade?.map(async (elemento) => {
        await api.put(
          `/v2/almoxarifado/transferencia/produto/editar/${idTransferencia}`,
          {
            id_produto_estoque: elemento?.id_produto_estoque,
            quantidade: elemento?.quantidade,
          }
        );
      });

      await api.put(`/v2/almoxarifado/transferencia/enviar/${idTransferencia}`);
      enqueueSnackbar("Transferência enviada com sucesso.", {
        variant: "success",
      });
      history.push({
        pathname: "/almoxarifado",
        state: { tab: true },
      });
      setOpenModal(false);
      setUpdateTabelaEstoque(!updateTabelaEstoque);
    } catch (e) {
      enqueueSnackbar("Houve um erro ao tentar enviar a transferência", {
        variant: "error",
      });
      setOpenModal(false);
    } finally {
      setDisableButtonEnviar(false);
    }
  };

  const handleCancelaConfirmacao = () => {
    setOpenModal(false);
  };

  const corpoModal = (
    <Container maxWidth="lg" style={{ padding: "0" }}>
      <Paper className="paper-cadastro" elevation={2}>
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          style={{ padding: "1rem 1rem" }}
        >
          <Grid item xs={6} sm={6}>
            <h2>Adicionar produto</h2>
          </Grid>
          <Grid id="botao-fechar" item xs={6} sm={6}>
            <Button type="button" onClick={() => setOpen(false)}>
              <CloseIcon fontSize="medium" style={{ color: "#003399" }} />
            </Button>
          </Grid>

          {manterTabelaVisível && (
            <>
              {/* <Grid className="input-text-modal" item xs={12} sm={12}>
                <TextField
                  inputRef={
                    codigoBarrasInserido || produtoSelecionado
                      ? null
                      : (input) => input && input.focus()
                  }
                  value={codigoBarrasInserido}
                  onChange={(e) => setCodigoBarrasInserido(e.target.value)}
                  fullWidth
                  size="small"
                  type="text"
                  label="Pesquise pelo nome ou código de barras"
                />
              </Grid> */}
              <Grid item xs={12} sm={12}>
                <div style={{ height: 500, width: "100%", padding: "10px" }}>
                  <Tabela
                    rows={produtosPorUnidade}
                    columns={columnsProdutos}
                    autoPageSize
                    checkboxSelection
                    onRowSelectionModelChange={(e) =>
                      handleProdutoSelecionado(e)
                    }
                    initialState={{
                      columns: {
                        columnVisibilityModel: {
                          id: false,
                        },
                      },
                    }}
                  />
                </div>
              </Grid>
            </>
          )}
          <Grid
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
            }}
            item
            xs={12}
            sm={12}
          >
            <Button
              onClick={() => handleDadosInseridos()}
              style={{ color: "#003399" }}
            >
              Selecionar produtos
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );

  const handleEditModal = (dados) => {
    handleOpenModal();
    handleProdutoSelecionado(dados);
  };

  const handleEditQuantidade = async (coluna, event) => {
    try {
      setDisableButtonEnviar(true);
      if (coluna?.field === "quantidade") {
        const {
          row: { quantidade_atual },
        } = coluna;

        if (event > quantidade_atual) {
          return enqueueSnackbar("Quantidade em estoque insuficiente", {
            variant: "warning",
          });
        }

        if (event <= 0) {
          return enqueueSnackbar("Quantidade precisa ser positiva", {
            variant: "warning",
          });
        }

        await api.put(
          `/v2/almoxarifado/transferencia/produto/editar/${idTransferencia}`,
          {
            id_produto_estoque: dadosEdicao.id_produto_estoque,

            quantidade: event,
          }
        );
      }
      if (coluna?.field === "codigo_barra") {
        const retorno = await api.put(
          `/v2/almoxarifado/lote/editar/barras/${dadosEdicao.id_lote}`,
          {
            codigoBarras: event,
          }
        );

        if (retorno?.data.atualizaCodigoBarras?.message) {
          return enqueueSnackbar(
            "Código de barras já está cadastrado em outro produto",
            {
              variant: "error",
            }
          );
        }
      }

      setUpdate(!update);
      setCodigoBarrasInserido("");
      setOpen(false);
      setManterTabelaVisivel(true);

      SalvaLog(
        `Produto adicionado à transferência`,
        "Almoxarifado",
        idTransferencia
      );
      enqueueSnackbar(
        coluna?.field === "quantidade"
          ? "Quantidade inserida com sucesso!"
          : "Código de barras alterado com sucesso!",
        {
          variant: "success",
        }
      );
      setDisableButtonEnviar(false);
    } catch (e) {
      enqueueSnackbar("Erro ao adicionar o produto", { variant: "error" });
    }
  };

  return (
    <>
      <Header
        titulo="Criar Transferência"
        usuario={usuario}
        history={history}
      />
      <Container maxWidth="lg" style={{ padding: "20px" }}>
        <Button
          onClick={() =>
            history.push({
              pathname: "/almoxarifado",
              state: { tab: true },
            })
          }
        >
          <ArrowBackIcon style={{ color: "#003399" }} />
        </Button>

        <Paper style={{ padding: "40px", marginTop: "10px" }} elevation={2}>
          <Grid spacing={4} container>
            <Grid item xs={12} sm={12}>
              <h2>Selecione as unidades para a transferência</h2>
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                value={requisicaoFortes}
                onChange={(e) => setRequisicaoFortes(e.target.value)}
                variant="standard"
                size="small"
                label="Número da requisição"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <SelectLocal
                value={unidadeOrigem}
                setValue={setUnidadeOrigem}
                labelId="select-label"
                id="select"
                label="Unidade de origem"
                locais={unidades}
                ativo={semAcessoUnidade}
              />
              {unidadeOrigem !== "" ? (
                <AutocompleteEstoquesTransferencia
                  onChange={setEstoqueOrigem}
                  value={estoqueOrigem}
                  idUnidade={unidadeOrigem}
                />
              ) : null}
            </Grid>
            <Grid item xs={12} sm={6}>
              <SelectLocal
                value={unidadeDestino}
                setValue={setUnidadeDestino}
                labelId="select-label"
                id="select"
                label="Unidade de Destino"
                locais={unidades}
              />
              {unidadeDestino !== "" ? (
                <AutocompleteEstoquesTransferencia
                  onChange={setEstoqueDestino}
                  value={estoqueDestino}
                  idUnidade={unidadeDestino}
                />
              ) : null}
            </Grid>
            {estoqueOrigem && estoqueDestino && !ativaBotaoAdicionar ? (
              <Grid item xs={12} sm={12}>
                <Button
                  onClick={() => criarTransferencia()}
                  style={{ color: "#003399" }}
                  startIcon={<AddIcon />}
                  size={tamanho ? "medium" : "small"}
                >
                  Criar transferência
                </Button>
              </Grid>
            ) : null}
            {ativaBotaoAdicionar && (
              <Grid item xs={12} sm={12}>
                <Button
                  onClick={() => handleOpenModal()}
                  style={{ color: "#003399" }}
                  startIcon={<AddIcon />}
                >
                  Adicionar produtos
                </Button>
                <Modal open={open} close={() => setOpen(false)}>
                  {corpoModal}
                </Modal>
              </Grid>
            )}
            {ativaTabela === true && (
              <Grid item xs={12} sm={12}>
                <div style={{ height: 500, width: "100%" }}>
                  <Tabela
                    rows={tabelaDados}
                    columns={columns}
                    autoPageSize
                    // onCellEditStop={(params, event) => {
                    //   // console.log("oncelleditstop", params, event);
                    //   if (
                    //     params.reason === GridCellEditStopReasons.cellFocusOut
                    //   ) {
                    //     event.defaultMuiPrevented = true;
                    //     if (event?.target?.value === undefined) {
                    //       enqueueSnackbar(
                    //         "Por favor, edite a quantidade e aperte ENTER para confirmar",
                    //         { variant: "warning" }
                    //       );
                    //       setDisableButtonEnviar(true);
                    //       return;
                    //     }
                    //   }

                    //   if (event?.target?.value === undefined) {
                    //     enqueueSnackbar(
                    //       "Por favor, edite a quantidade e aperte ENTER para confirmar",
                    //       { variant: "warning" }
                    //     );
                    //     setDisableButtonEnviar(true);
                    //     return;
                    //   }

                    //   handleEditQuantidade(params, event?.target?.value);
                    // }}
                    // onCellEditStop={(coluna) => {
                    //   handleEditQuantidade(coluna);
                    // }}
                    // onCellEditStart={(e) => {
                    //   // console.log("celleditStart", e.row);
                    //   setDadosEdicao(e.row);
                    // }}
                    initialState={{
                      columns: {
                        columnVisibilityModel: {
                          id: false,
                        },
                      },
                    }}
                  />
                </div>
              </Grid>
            )}
            {ativaTabela === true && (
              <Grid
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: ativaBotaoExcluir ? "space-between" : "end",
                }}
                item
                xs={12}
                sm={12}
              >
                {ativaBotaoExcluir && permissaoCancelar && (
                  <Button
                    onClick={() => handleCancelaTransferencia()}
                    style={{ color: colorTheme.danger[500] }}
                  >
                    Cancelar Transferência
                  </Button>
                )}
                <Button onClick={() => handleMovimentacao()} outlined>
                  Enviar
                </Button>
                <ModalComponent
                  onClick={handleAceitaConfirmacao}
                  onClose={handleCancelaConfirmacao}
                  confirmActionButtonColor={colorTheme.primary[500]}
                  confirmActionButtonText="Confirmar"
                  modalText="Deseja realmente prosseguir? Após a confirmação não será possível editar a transferência."
                  open={openModal}
                  loading={disableButtonEnviar}
                />
                {/* <MyModal
                  open={openModal}
                  closeModal={setOpenModal}
                  text="Deseja realmente prosseguir? Após a confirmação não será possível editar a transferência."
                  confirmButtonLabel="Confirmar"
                  cancelButtonLabel="Cancelar"
                  cancelButtonColor="#27272a"
                /> */}
              </Grid>
            )}
          </Grid>
        </Paper>
      </Container>
    </>
  );
}
