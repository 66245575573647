import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import { Grid } from "@mui/material";

import { api } from "../../../services/Api";

import BotaoCadastrarTransferencia from "./Button";
import DadosContext from "./DadosContext";
import HeaderSelect from "./HeaderSelect/index";
import Table from "./Table";

import useUser from "../../../hooks/useUser";

import { Context as PermissoesContext } from "../../../Context/PermissoesContext";
import useDadosContextEstoque from "../hooks/useDadosContextEstoque";

export default function TransferenciasIndex() {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const usuario = useUser();
  const { checarPermissao } = useContext(PermissoesContext);

  const {
    setDataInicialTransferencias,
    setDataFinalTransferencias,

    setTodosOsLocais,
    todosOsLocais,
    status,
    setStatus,
    setLocalDestino,
    semAcessoUnidadeTransferencias,
    setSemAcessoUnidadeTransferencia,
    setIdUnidadeUsuario,
  } = useDadosContextEstoque();

  async function checaUnidadeFuncionario() {
    try {
      const { data } = await api.get(`v2/controleAcesso/funcionarioUnidade`, {
        params: { cod_usu: usuario },
      });

      if (!(await checarPermissao("AL014"))) {
        setSemAcessoUnidadeTransferencia(true);
        setLocalDestino(data.funcionario.unidade_id);
        setIdUnidadeUsuario(data.funcionario.unidade_id);
        // setStatus("Enviado");
      }
    } catch (error) {
      history.push("/home");
      enqueueSnackbar(
        "Erro ao encontrar unidade de origem, solicite o cadastramento da unidade do seu usuário!",
        {
          variant: "error",
        }
      );
    }
  }

  useEffect(() => {
    checaUnidadeFuncionario();
    const dataMenos30 = new Date().setDate(new Date().getDate() - 31);
    setDataFinalTransferencias(new Date());
    setDataInicialTransferencias(new Date(dataMenos30));
    // eslint-disable-next-line
  }, [enqueueSnackbar, checarPermissao, history, usuario]);

  return (
    <>
      <Grid container>
        <Grid item xs={12} sm={12}>
          <HeaderSelect />
        </Grid>
        <Grid style={{ paddingTop: "10px" }} item xs={12} sm={12}>
          <BotaoCadastrarTransferencia />
        </Grid>
        <Grid style={{ paddingTop: "10px" }} item xs={12} sm={12}>
          <Table />
        </Grid>
      </Grid>
    </>
  );
}
