import React from "react";
import { Button } from '@mui/material';
import clsx from "clsx";
import "./styles.css";

const MyButton = React.forwardRef(({ children, secondary, ...rest }, ref) => (
  <Button
    ref={ref}
    className={clsx(!secondary && "primary", secondary && "secondary", "my-button")}
    {...rest}
  >
    {children}
  </Button>
));

export default MyButton;
