import React, { createContext, useContext, useState } from 'react';

// Criação do contexto
const AgendaCareContext = createContext();

export const AgendaCareProvider = ({ children }) => {
  const [aba, setAba] = useState(0);

  return (
    <AgendaCareContext.Provider value={{ aba, setAba }}>
      {children}
    </AgendaCareContext.Provider>
  );
};

// Hook para acessar o contexto em outros componentes
export const useAgendaCareContext = () => useContext(AgendaCareContext);