import React from "react";
import { Paper, IconButton, Tooltip, Container } from '@mui/material';
import { styled } from '@mui/system';

const StyledContainer = styled(Container)({
  display: "flex",
  minWidth: "20rem",
  flexDirection: "column",
  padding: 0,
  backgroundColor: "#F2F2F2"
});

const StyledPaper = styled(Paper)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  backgroundColor: "#003399",
  color: "white",
  fontWeight: "bold",
  fontSize: "1.5rem",
  padding: "0.5rem 0"
});

export default function CorpoModal(props) {
  const { titulo, fechaModal, children } = props;

  return (
    <StyledContainer>
      <StyledPaper>
        <span />
        <span>{titulo}</span>
        <Tooltip title="Fechar">
          <IconButton
            onClick={fechaModal}
          >
            <i className="fa fa-close" style={{ color: "#FFFFFF", marginRight: "1rem" }} />
          </IconButton>
        </Tooltip>
      </StyledPaper>
      {children}
    </StyledContainer>
  )
}
