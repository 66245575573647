import React from "react";
import { Tab, Tabs, Paper } from "@mui/material";

export default function MyTabs({ handleChange, tabs, value }) {
  return (
    <Paper>
      <Tabs onChange={handleChange} value={value}>
        {tabs.map((t) => (
          <Tab
            key={t}
            label={t}
            style={{ minWidth: `${100 / tabs.length}%` }}
          />
        ))}
      </Tabs>
    </Paper>
  );
}
