import React from "react";

import { useHistory } from "react-router-dom";
import { HStack } from "../../../../componentsv2/Stack";
import Cabecalho from "./components/cabecalho";
import Tabela from "./components/Tabela";
import useTabelaAgendamentos from "./hooks/useTabelaAgendamentos";

export default function AgendaCare() {
  const history = useHistory();

  const openForm = () => {
    history.push("/agendaCare/formularioAgendamento");
  };
  const {
    atualizarTabela,
    rows,
    situacao,
    setSituacao,
    hora,
    setHora,
    enfermeiroId,
    setEnfermeiroId,
    data,
    setData,
  } = useTabelaAgendamentos();

  return (
    <HStack>
      <Cabecalho
        onClick={openForm}
        situacao={situacao}
        setSituacao={setSituacao}
        hora={hora}
        setHora={setHora}
        enfermeiroId={enfermeiroId}
        setEnfermeiroId={setEnfermeiroId}
        data={data}
        setData={setData}
      />

      <Tabela atualizarTabela={atualizarTabela} rows={rows} />
    </HStack>
  );
}
