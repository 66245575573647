import React from "react";
import clsx from "clsx";

import "./styles.css";
import Checkbox from "../Checkbox";

export default function PermissaoPapel({ nome, descricao, selected, onClick }) {
  return (
    <div className={clsx(["papel-wrapper", { selected }])} onClick={onClick}>
      <h3 className="papel-nome">{nome}</h3>
      <Checkbox className="papel-checkbox" checked={selected} />
      <p className="papel-descricao">{descricao}</p>
    </div>
  );
}
