import React, { useContext, useEffect, useState } from "react";

import { FormularioColetaDomiciliarContext } from "../../Context/FormularioColetaDomiciliarContext";

// import Tabela from "./Tabela";
import Totalizadores from "./Totalizadores";

import Tabela from "./../../../../componentsv2/Tabela";
import { FinanceiroContext } from "../../Context/FinanceiroContext";
// src\Context\PermissoesContext.js
import { Context as PermissoesContext } from "../../../../../src/Context/PermissoesContext";
// import { Context as PermissoesContext } from "../../../../../Context/PermissoesContext";
// import converteData from "../../../../utils/converteData";
import formatoMoeda from "../../../../utils/formatoMoeda";
import { IconButton, Tooltip } from "@mui/material";
import {
  CreditCard,
  LocalAtm,
  Pix,
  WorkspacePremium,
} from "@mui/icons-material";

import converteData from "../../../../utils/converteData";
import ModalEditarPagamento from "../Formulario/ModalEditarPagamento/ModalEditarPagamento";
import { enqueueSnackbar } from "notistack";
import { api } from "../../../../services/Api";
import SalvaLog from "../../../../utils/SalvaLog";
// import { ModaisColetaDomiciliarContext } from "../../Context/ModaisColetaDomiciarContext";

export default function Financeiro() {
  const { checarPermissao } = useContext(PermissoesContext);
  const [openModal, setOpenModal] = useState(false);
  const [nome, setNome] = useState("");
  const [codigoPedido, setCodigoPedido] = useState("");
  const [valorRecebido, setValorRecebido] = useState("");
  const [tipoPagamento, setTipoPagamento] = useState("");
  const [id, setId] = useState("");

  const { setTipo_cliente } = useContext(FormularioColetaDomiciliarContext);
  const { recebidosFiltrados, updateTableData } = useContext(FinanceiroContext);

  function checarPermissaoParaEdicao() {
    if (!checarPermissao("CDO011")) {
      enqueueSnackbar(
        `Para fazer a ação é necessário possuir a permissão CDO011`,
        {
          variant: "error",
        }
      );
      return false;
    }
    return true;
  }

  const updateValorTipo = async () => {
    try {
      await api.put(`/api/coletaDomiciliar/editarValor`, {
        valor: valorRecebido,
        tipo_pagamento: tipoPagamento,
        id: id,
      });

      const detalhe = JSON.stringify({
        valor: valorRecebido,
        tipo_pagamento: tipoPagamento,
        id: id,
      });

      SalvaLog(
        "Editou valor e/ou tipo de pagamento em Coleta Dom Financeiro",
        "Coleta Domiciliar",
        detalhe
      );

      updateTableData();

      enqueueSnackbar("Valor e tipo de pagamento atualizados.", {
        variant: "success",
      });
    } catch (error) {
      enqueueSnackbar("Não foi possível atualizar os valores", {
        variant: "error",
      });

      console.log(error);
    }
  };

  const iconePagamento = (tipo) => {
    if (tipo === "Pix") {
      return <Pix color="secondary" />;
    }

    if (tipo === "Cartão de crédito") {
      return <CreditCard color="error" />;
    }

    if (tipo === "Dinheiro") {
      return <LocalAtm color="success" />;
    }

    if (tipo === "") {
      return <WorkspacePremium color="warning" />;
    }

    return null;
  };

  const columns = [
    {
      field: "data",
      headerName: "Data",
      headerAlign: "center",
      valueGetter: ({ value }) => value && converteData(value),

      align: "center",
      width: 100,
    },
    {
      field: "coletador",
      headerName: "Coletador",
      headerAlign: "center",
      align: "center",
      width: 100,
    },
    {
      field: "codigo_pedido",
      headerName: "Pedido",
      headerAlign: "center",
      align: "center",
      width: 150,
    },
    {
      field: "posto_pedido",
      headerName: "Posto",
      headerAlign: "center",
      align: "center",
      width: 100,
    },
    {
      field: "tipo_pagamento",
      headerName: "Tipo",
      headerAlign: "center",
      renderCell: (params) => (
        <div style={{ cursor: "pointer" }}>
          <Tooltip
            title={`Pagamento via ${params.row.tipo_pagamento}. ${
              params.row.tipo_pagamento === "Cartão de crédito"
                ? params.row.numero_cartao
                : ""
            }`}
          >
            <IconButton>{iconePagamento(params.row.tipo_pagamento)}</IconButton>
          </Tooltip>
        </div>
      ),
      align: "center",
      width: 100,
    },
    {
      field: "nome",
      headerName: "Cliente",
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "codigo_usuario",
      headerName: "Usuário",
      headerAlign: "center",
      align: "center",
      width: 100,
    },
    {
      field: "valor_recebido",
      headerName: "Valor",
      headerAlign: "center",
      valueGetter: ({ value }) => value && formatoMoeda(Number(value)),
      align: "center",
      width: 100,
    },
    {
      field: "opcoes",
      headerName: "Editar",
      headerAlign: "center",
      renderCell: (params) => (
        <div style={{ cursor: "pointer" }}>
          <button
            type="button"
            className="btnOpcao"
            onClick={() => {
              if (!checarPermissaoParaEdicao()) {
                return;
              }

              const {
                id,
                codigo_pedido,
                nome,
                tipo_pagamento,
                valor_recebido,
              } = params.row;

              setCodigoPedido(codigo_pedido);
              setNome(nome);
              setTipoPagamento(tipo_pagamento);
              setValorRecebido(valor_recebido);
              setId(id);
              setOpenModal(true);
            }}
          >
            <i className="fa fa-cog" />
          </button>
        </div>
      ),
      align: "center",
      width: 100,
    },
  ];

  useEffect(() => {
    setTipo_cliente("");
  }, [setTipo_cliente]);
  return (
    <div>
      <Totalizadores />
      <Tabela rows={recebidosFiltrados} columns={columns} />
      <ModalEditarPagamento
        open={openModal}
        onClose={setOpenModal}
        codigoPedido={codigoPedido}
        nome={nome}
        tipoPagamento={tipoPagamento}
        onChangePagamento={setTipoPagamento}
        valorRecebido={valorRecebido}
        onChangeValor={setValorRecebido}
        submit={updateValorTipo}
        id={id}
      />
    </div>
  );
}
