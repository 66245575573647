import React from "react";
import { Grid } from '@mui/material';
import { Select } from "../../../../componentsv2/Select";
import formatDateBD from "../../../../utils/formatarDataBD";
import { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import { api } from "../../../../services/Api";

export default function SelectHorariosDisponiveis({
  onChange,
  value,
  disabled = false,
  required = false,
  todos = false,
  data,
  enfermeiroId,
}) {
  const [optionsHorarios, setOptionsHorarios] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    async function pegaHora() {
      try {
        const response = await api.get("/v2/agendaCare/horarioDisponiveis", {
          params: {
            enfermeiroId,
            data: formatDateBD(data),
          },
        });
        const horarios = response.data.data.map(hora => {
          return {
            value: hora.hora,
            text: hora.hora,
          };
        });
        todos &&
          horarios.unshift({
            value: "",
            text: "TODOS",
          });
        setOptionsHorarios(horarios);
      } catch (error) {
        enqueueSnackbar("Erro! Não foi possível buscar os horarios.", {
          variant: "error",
        });
      }
    }
    pegaHora();
  }, [enqueueSnackbar, data, enfermeiroId,todos]);

  return (
    <>
      <Grid container spacing={1} justify="center" alignItems="stretch">
        <Grid item xs={12}>
          <Select
            textAttribute="text"
            valueAttribute="value"
            required={required}
            disabled={disabled}
            onChange={onChange}
            value={value}
            label="Horário Disponiveis"
            variant="standard"
            arrayOptions={optionsHorarios}
          />
        </Grid>
      </Grid>
    </>
  );
}
