import React, { useEffect, useState, useContext } from "react";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { Grid, IconButton, Paper, Box, Typography } from "@mui/material";

import DataPickerAtualizado from "../../../components/DataPickerAtualizado";
import { api, baseUrl } from "../../../services/Api";
import DadosContextContrato from "./DadosContextContrato";

import Opcoes from "./opcoes";
import atualizaExibicaoData from "../../../utils/atualizaExibicaoData";

import { useHistory } from "react-router-dom";
import { Context as PermissoesContext } from "../../../Context/PermissoesContext";
import { useSnackbar } from "notistack";
import Tabela from "../../../componentsv2/Tabela";
import { Select } from "../../../componentsv2/Select";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ModalAnexo from "./ModalAnexo";
import TabelaAccordion from "./TabelaAccordionContrato";

export default function Contratos() {
  const { checarPermissao } = useContext(PermissoesContext);
  const { enqueueSnackbar } = useSnackbar();

  const [fornecedores, setFornecedores] = useState([]);
  const [contratos, setContratos] = useState([]);
  const [fornecedor, setFornecedor] = useState("");
  // eslint-disable-next-line

  const history = useHistory();
  const [update, setUpdate] = useState(false);

  const [dataInicial, setDataInicial] = useState(new Date());
  const [dataFinal, setDataFinal] = useState(new Date());
  const [status, setStatus] = useState("Todas");

  const [openModalAnexo, setOpenModalAnexo] = useState(false);
  const [urlAnexo, setUrlAnexo] = useState([]);

  const [idContrato, setIdContrato] = useState("");
  const estados = [
    "Todas",
    "Pendente",
    "Conferido",
    "Autorizado",
    "Não Autorizado",
  ];
  const [situacaoAtual, setSituacaoAtual] = useState("");
  const [observacaoAtual, setObservacaoAtual] = useState("");

  const [contratosFiltrados, setContratosFiltrados] = useState([]);
  const [valorTotal, setValorTotal] = useState(0);

  const [tamanho, setTamanho] = useState("");

  function sizeOfScreen() {
    setTamanho(window.innerWidth);
  }

  useEffect(() => {
    sizeOfScreen();
  }, []);

  window.addEventListener("resize", () => {
    sizeOfScreen();
  });

  const columns = [
    {
      field: "nome_fornecedor",
      headerName: "Empresa",
      type: "string",
      flex: 1,
    },
    {
      field: "descricao",
      headerName: "Descrição",
      headerAlign: "center",
      flex: 1,
      align: "center",
      type: "string",
    },
    {
      field: "criado_em",
      headerName: "Data",
      headerAlign: "center",
      align: "center",
      flex: 1,
      type: "date",
      valueGetter: ({ value }) => {
        if (value) {
          return new Date(value);
          // const date = new Date(value.split("/").reverse().join("-"));
          // date.setDate(date.getDate() + 2);
          // return date;
        }
        return null;
      },
    },
    {
      field: "data_vencimento",
      headerName: "Vencimento",
      headerAlign: "center",
      align: "center",
      flex: 1,
      type: "date",
      valueGetter: ({ value }) => {
        if (value) {
          const date = new Date(value.split("/").reverse().join("-"));
          date.setDate(date.getDate() + 2);
          return date;
        }
        return null;
      },
    },

    {
      field: "status",
      headerName: "Estado",
      headerAlign: "center",
      align: "center",
      flex: 1,
      type: "string",
    },
    {
      field: "valor",
      headerName: "Valor",
      headerAlign: "center",
      align: "right",
      flex: 1,
      type: "number",
      valueFormatter: ({ value }) =>
        value
          ? value.toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRL",
            })
          : 0,
    },
    {
      field: "visualizar",
      headerName: "Visualizar",
      headerAlign: "center",
      align: "center",

      width: 100,

      renderCell: (params) => (
        <div>
          {params.row.anexos?.length > 0 ? (
            <IconButton
              style={{ color: "#000" }}
              onClick={() => {
                if (!checarPermissao("GEC009")) {
                  enqueueSnackbar(
                    "Você não possui permissão para visualizar o documento!",
                    {
                      variant: "error",
                      autoHideDuration: 3000,
                    }
                  );
                  return;
                }

                setUrlAnexo([]);
                setSituacaoAtual("");
                setObservacaoAtual("");

                const urls = params.row?.anexos?.map(
                  (anexo) =>
                    `${baseUrl}/static/v2/private/contratos/${anexo}?token=${api.defaults.headers.Authorization}`
                );
                setIdContrato(params.row.id);
                setUrlAnexo(urls);
                setSituacaoAtual(params.row.status);
                setObservacaoAtual(
                  params.row.observacao ? params.row.observacao : ""
                );
                setOpenModalAnexo(true);
              }}
            >
              <VisibilityIcon />
            </IconButton>
          ) : (
            "Sem Anexo"
          )}

          {/* <Opcoes row={params.row} fornecedores={fornecedores} /> */}
        </div>
      ),
    },
    {
      field: "opcoes",
      headerName: "Opções",
      headerAlign: "center",
      align: "center",

      width: 100,

      renderCell: (params) => (
        <div style={{ cursor: "pointer" }}>
          <Opcoes row={params.row} fornecedores={fornecedores} />
        </div>
      ),
    },
  ];

  useEffect(() => {
    if (!checarPermissao("FIN001")) {
      history.push("/home");
    }
    async function getFornecedores() {
      const { data } = await api.get(`v2/gestaoContratos/fornecedores`);

      const fornecedoresFormatado = [{ id: "", nome: "Todas" }, ...data];

      setFornecedores(fornecedoresFormatado);
    }
    getFornecedores();
  }, [checarPermissao, history]);

  useEffect(() => {
    const filtroFornecedor = contratos?.filter((contrato) => {
      if (fornecedor === "") return contrato;
      return contrato.id_fornecedor === fornecedor;
    });

    const totalValor = filtroFornecedor.reduce(
      (acc, contrato) => acc + contrato.valor,
      0
    );
    setValorTotal(totalValor);

    setContratosFiltrados(filtroFornecedor);
  }, [fornecedor, contratos]);

  function dateIsValid(date) {
    if (date instanceof Date && !isNaN(date)) return true;
    return false;
  }

  useEffect(() => {
    async function getTodosContratos() {
      try {
        if (
          (dateIsValid(new Date(dataInicial)) && !dataFinal) ||
          (dateIsValid(new Date(dataFinal)) && !dataInicial) ||
          (dateIsValid(new Date(dataFinal)) &&
            dateIsValid(new Date(dataInicial))) ||
          (!dataFinal && !dataInicial)
        ) {
          const dataContratos = await api.get(
            `v2/gestaoContratos/todosContratos`,
            {
              params: { dataInicial, dataFinal, status },
            }
          );
          setContratos(
            atualizaExibicaoData(dataContratos.data, "data_vencimento")
          );
        }
      } catch (error) {
        console.log(error);
      }
    }
    getTodosContratos();
  }, [update, dataInicial, dataFinal, status]);

  return (
    <DadosContextContrato.Provider value={{ update, setUpdate }}>
      <ModalAnexo
        situacaoAtual={situacaoAtual}
        idContrato={idContrato}
        anexos={urlAnexo}
        onClose={setOpenModalAnexo}
        open={openModalAnexo}
        onCancel={() => setOpenModalAnexo(false)}
        title="Analisar documento"
        observacaoAtual={observacaoAtual}
      />
      <Grid container alignItems="center" spacing={2} direction="column">
        <Grid container xs={12} item>
          <Paper sx={{ width: "100%", padding: "10px" }}>
            <Grid
              item
              container
              sx={{ marginLeft: "5px" }}
              justifyContent="space-between"
            >
              <Grid xs={12} spacing={3} item container>
                <Grid item md={3} sm={6} xs={6}>
                  <DataPickerAtualizado
                    labelName="Data Inicial"
                    variant="standard"
                    data={dataInicial}
                    setData={setDataInicial}
                  />
                </Grid>
                <Grid item md={3} sm={6} xs={6}>
                  <DataPickerAtualizado
                    labelName="Data Final"
                    variant="standard"
                    data={dataFinal}
                    setData={setDataFinal}
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  <Select
                    value={fornecedor}
                    label="Empresa"
                    variant="standard"
                    arrayOptions={fornecedores}
                    onChange={(e) => {
                      setFornecedor(e.target.value);
                    }}
                    valueAttribute="id"
                    textAttribute="nome"
                  />
                </Grid>
                <Grid item md={3} lg={3} xs={9}>
                  <Select
                    value={status}
                    label="Situação"
                    variant="standard"
                    arrayOptions={estados}
                    onChange={(event) => {
                      event.preventDefault();
                      setStatus(event.target.value);
                    }}
                  />
                </Grid>
                <Grid xs={1} item>
                  <IconButton
                    color="success"
                    onClick={() => {
                      if (checarPermissao("GEC006")) {
                        history.push("/financeiro/contratos/criar");
                      } else {
                        enqueueSnackbar(
                          "Você não possui permissão para cadastrar contratos!",
                          {
                            variant: "error",
                            autoHideDuration: 3000,
                          }
                        );
                      }
                    }}
                    size="large"
                  >
                    <AddCircleOutlineIcon fontSize="large" />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        {/* <Grid xs={12} item>
          <Paper
            sx={{
              padding: "15px",
              width: "100%",
            }}
          >
            <Typography variant="body1" color="black">
              <b>Número total de contratos:</b> {contratosFiltrados?.length}{" "}
              <b>/</b> <b>Valor total dos contratos:</b> R${" "}
              {valorTotal.toFixed(2)}
            </Typography>
          </Paper>
        </Grid> */}
        <Grid item xs={12} container>
          <Grid xs={12} sm={12} item>
            <Grid spacing={2} container>
              <Grid
                style={{ paddingTop: "10px", marginTop: "10px" }}
                item
                xs={12}
                sm={12}
              >
                <Typography variant="body1" color="black">
                  Total de <b>{contratosFiltrados?.length}</b> contratos com
                  valor de
                  <b>
                    {" "}
                    {valorTotal.toLocaleString("pt-BR", {
                      style: "currency",
                      currency: "BRL",
                    })}
                  </b>
                </Typography>
                <Paper elevation={2}>
                  <Box
                    sx={{
                      height: 1,
                      width: "100%",
                      "& .vencido": { color: "#99250F" },
                      marginBottom: "10px",
                    }}
                  >
                    <div>
                      {tamanho > 499 ? (
                        <Tabela
                          rows={contratosFiltrados}
                          columns={columns}
                          autoHeight
                          linhaNaoSelecionavel={false}
                          getCellClassName={(params) => {
                            if (params.field === "data_vencimento") {
                              let currentDate = new Date();
                              let newDataAmericana = new Date(params.value);

                              return currentDate >= newDataAmericana
                                ? "vencido"
                                : "";
                            }

                            return "";
                          }}
                        />
                      ) : (
                        <TabelaAccordion
                          contratos={contratosFiltrados}
                          setUpdate={setUpdate}
                          update={update}
                          fornecedores={fornecedores}
                        />
                      )}
                    </div>
                  </Box>
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </DadosContextContrato.Provider>
  );
}
