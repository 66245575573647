import spermSVG from "../../../assets/sperms.svg";

import SUSLogo from "../../../assets/sus-logo.svg";
import portalMedicoSVG from "../../../assets/portal_medico.svg";

const todasFerramentas = [
  {
    id: "1",
    tipoFerramenta: "Agendas",
    ferramentas: [
      {
        id: "1",
        nome: "Paternidade",
        iconClassName: "fa fa-child",
        path: "/agendaPaternidade",
        permissao: "AGP001",
      },
      {
        id: "2",
        nome: "Agendamentos",
        iconClassName: "fa fa-calendar",
        path: "/agendamentos",
        permissao: 0,
      },
      {
        id: "3",
        nome: "Coleta Domiciliar",
        iconClassName: "fa fa-home",
        path: "/coletaDomiciliar",
        permissao: 0,
      },
      {
        id: "4",
        nome: "Espermograma",
        imgSrc: spermSVG,
        imgAlt: "Espermograma",
        path: "/espermograma",
        permissao: "ESP001",
      },
      {
        id: "5",
        nome: "Exame Respiratório",
        iconClassName: "fa fa-stethoscope",
        path: "/respiratorio",
        permissao: "RES001",
      },
      {
        id: "6",
        nome: "Agenda Care",
        iconClassName: "fa fa-heartbeat",
        path: "/agendaCare",
        permissao: 0,
      },
      {
        id: "7",
        nome: "Agenda GH",
        iconClassName: "fa fa-arrows-v",
        path: "/agendaGH",
        permissao: 0,
      },
    ],
  },
  {
    id: "2",
    tipoFerramenta: "Laboratório",
    ferramentas: [
      {
        id: "6",
        nome: "Controle Amostras",
        iconClassName: "fa fa-flask",
        path: "/amostras",
        permissao: "PCR001",
      },
      {
        id: "7",
        nome: "Mapas de Curvas",
        iconClassName: "fa fa-map",
        path: "/mapaCurvas",
        permissao: "MC001",
      },
      {
        id: "8",
        nome: "Notificações eSUS",
        imgSrc: SUSLogo,
        imgAlt: "Logo SUS",
        path: "/notificacoesCovid",
        permissao: "SUS001",
      },
      {
        id: "9",
        nome: "Procurar Exames",
        iconClassName: "fa fa-search",
        path: "/exames",
        permissao: 0,
      },
    ],
  },
  {
    id: "3",
    tipoFerramenta: "Financeiro",
    ferramentas: [
      {
        id: "10",
        nome: "Contratos",
        iconClassName: "fa fa-table",
        path: "/financeiro/contratos",
        permissao: "FIN001",
      },
      {
        id: "11",
        nome: "Faturamento",
        iconClassName: "fa fa-line-chart",
        path: "/faturamento",
        permissao: "FAT001",
      },
      {
        id: "12",
        nome: "Preços de Exames",
        iconClassName: "fa fa-usd",
        path: "/precosExames",
        permissao: "TPE001",
      },
    ],
  },
  {
    id: "4",
    tipoFerramenta: "Administrativo",
    ferramentas: [
      {
        id: "13",
        nome: "Conexão DNA",
        iconClassName: "fa fa-list-alt",
        path: "/ConexaoDNA",
        permissao: "CON001",
      },
      {
        id: "14",
        nome: "Cadastro de Usuários",
        iconClassName: "fa fa-user",
        path: "/cadastrausuario",
        permissao: "CU001",
      },
      {
        id: "15",
        nome: "Controle Acesso",
        iconClassName: "fa fa-lock",
        path: "/controleAcesso",
        permissao: "CA001",
      },
      {
        id: "16",
        nome: "Almoxarifado",
        iconClassName: "fa fa-archive",
        path: "/almoxarifado",
        permissao: "AL001",
      },
      {
        id: "17",
        nome: "Inventário",
        iconClassName: "fa fa-briefcase",
        path: "/inventario",
        permissao: "INV001",
      },
      {
        id: "22",
        nome: "Atendimento ao Consumidor",
        iconClassName: "fa fa-volume-control-phone",
        path: "/AtendimentoAoCliente",
        permissao: 0,
      },
      {
        id: "23",
        nome: "Central de BI's",
        iconClassName: "fa fa-pie-chart",
        path: "/centralDeBIs",
        permissao: "CTB001",
      },
      {
        id: "25",
        nome: "Gestão de Clientes",
        iconClassName: "fa fa-user-plus",
        path: "/GestaoDeClientes",
        permissao: "GDC001",
      },
    ],
  },
  {
    id: "5",
    tipoFerramenta: "Aplicativos",
    ferramentas: [
      {
        id: "18",
        nome: "Mensagens App",
        iconClassName: "fa fa-send",
        path: "/mensagensApp",
        permissao: "MAP001",
      },
      {
        id: "19",
        nome: "Portal do Médico",
        imgSrc: portalMedicoSVG,
        imgAlt: "Portal do médico",
        path: "/portalMedico",
        permissao: "PM001",
      },
      {
        id: "20",
        nome: "Atualizações App",
        iconClassName: "fa fa-refresh",
        path: "/atualizacoesApp",
        permissao: "AAP001",
      },
      {
        id: "26",
        nome: "Produtividade",
        iconClassName: "fa fa-trophy",
        permissao: 0,
        href: "https://intranetv2.dnacenter.com.br/produtividade",
      },
    ],
  },
];

todasFerramentas.map((el) => ({
  ...el,
  ferramentas: el.ferramentas.sort((elFerramentaA, elFerramentaB) => {
    if (elFerramentaA.nome > elFerramentaB.nome) {
      return 1;
    }
    if (elFerramentaA.nome < elFerramentaB.nome) {
      return -1;
    }

    return 0;
  }),
}));

export default todasFerramentas;
