import React, { useContext } from "react";
import { Dialog, InputLabel } from "@mui/material";
import { Close } from "@mui/icons-material";

import { ModaisColetaDomiciliarContext } from "../../../Context/ModaisColetaDomiciarContext";
import { FormularioColetaDomiciliarContext } from "../../../Context/FormularioColetaDomiciliarContext";

import converteData from "../../../../../utils/converteData";

export default function ModalPesquisa() {
  const { modalPesquisa, setModalPesquisa } = useContext(ModaisColetaDomiciliarContext);
  const {
    resultadoPesquisa,
    setResultadoPesquisa,
  } = useContext(FormularioColetaDomiciliarContext);

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={modalPesquisa}
      onClose={() => {
          setModalPesquisa(false);
          setResultadoPesquisa([]);
        }}
    >
      <div>
        <div id="header-information-client">
          <i id="icon-info-client" className="fa fa-user-circle" aria-hidden="true" />
          <span>Resultado da pesquisa</span>
          <button
            type="button"
            id="btn-close-info-client"
            onClick={() => {
                setModalPesquisa(false);
                setResultadoPesquisa([]);
              }}
          >
            <Close id="icon-back-info-client" />
          </button>
        </div>

        <div id="grid-container-cli-tittle" className="grid-container-Cli2">
          <div className="grid-item-Cli">
            <InputLabel id="nomeCliente">Cliente</InputLabel>
            <InputLabel id="nome">
              {resultadoPesquisa[0]
                  ? `${resultadoPesquisa[0].localizador} - ${resultadoPesquisa[0].nome}`
                  : ""}
            </InputLabel>
          </div>
        </div>

        {resultadoPesquisa.map(resultado => (
          <div className="grid-container-Cli2">
            <div className="grid-item-Cli">
              <InputLabel id="nomeCliente">Agendamento</InputLabel>
              <InputLabel id="nome">
                Dia {converteData(resultado.data)} as {resultado.hora} com{" "}
                {resultado.coletador}
              </InputLabel>
            </div>
          </div>
          ))}
      </div>
    </Dialog>
  )
}
