import React, { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { format } from "date-fns/esm";
import { styled } from "@mui/system";
import {
  Paper,
  Button,
  List,
  ListItem,
  ListItemText,
  TextField,
  Grid,
  ListItemIcon,
  IconButton,
  FormControlLabel,
  Dialog,
  ListItemButton,
} from "@mui/material";
import { Close, KeyboardBackspace, Visibility } from "@mui/icons-material";

import Waiter from "../../../../components/Backdrop";
import CorpoModal from "../../Componentes/CorpoModal";
import Tabela from "../../../../componentsv2/Tabela";

import { api } from "../../../../services/Api";

import "./styles.css";

const StyledPaper = styled(Paper)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  padding: "1rem",
  marginBottom: "1rem",
}));

export default function GerenciarGuias(props) {
  const { enqueueSnackbar } = useSnackbar();

  const [opcoes, setOpcoes] = useState(false);
  const [detalhes, setDetalhes] = useState(false);
  const [aguarda, setAguarda] = useState(false);

  const [dadosTabela, setDadosTabela] = useState([]);
  const [dadosGuia, setDadosGuia] = useState([]);
  const [dadosProcedimentos, setDadosProcedimentos] = useState([]);

  const { id_lote, plano, abertura, valor, protocolo, setTela, fechaOpcoes } =
    props;

  const Config = ({ row }) => {
    const handleEditClick = () => {
      setDadosGuia(row);
      setOpcoes(true);
    };

    return (
      <FormControlLabel
        control={
          <IconButton onClick={handleEditClick}>
            <i className="fa fa-cog" style={{ color: "#003399" }} />
          </IconButton>
        }
      />
    );
  };

  const colunas = [
    {
      field: "id",
      headerClassName: "super-app-theme--header",
      headerName: "ID",
      headerAlign: "center",
      width: 90,
      hide: true,
    },
    {
      field: "data_cadastro",
      headerClassName: "super-app-theme--header",
      headerName: "Data Criação",
      headerAlign: "center",
      width: 160,
      editable: false,
      valueFormatter: ({ value }) =>
        value ? `${format(new Date(value), "dd/MM/yyyy HH:mm")}` : null,
    },
    {
      field: "codigo_pedido",
      headerClassName: "super-app-theme--header",
      headerName: "Pedido",
      headerAlign: "center",
      type: "number",
      width: 110,
    },
    {
      field: "posto_pedido",
      headerClassName: "super-app-theme--header",
      headerName: "Posto",
      headerAlign: "center",
      width: 95,
    },
    {
      field: "n_guia",
      headerClassName: "super-app-theme--header",
      headerName: "N° da Guia",
      headerAlign: "center",
      width: 125,
    },
    {
      field: "posicao",
      headerClassName: "super-app-theme--header",
      headerName: "Posição",
      headerAlign: "center",
      type: "number",
      width: 110,
    },
    {
      field: "numero_carteira",
      headerClassName: "super-app-theme--header",
      headerName: "Carteira",
      headerAlign: "center",
      width: 120,
    },
    {
      field: "nome_cliente",
      headerClassName: "super-app-theme--header",
      headerName: "Beneficiário",
      headerAlign: "center",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "medico",
      headerClassName: "super-app-theme--header",
      headerName: "Médico",
      headerAlign: "center",
      flex: 1,
      minWidth: 120,
    },
    {
      field: "crm",
      headerClassName: "super-app-theme--header",
      headerName: "CRM",
      headerAlign: "center",
      width: 90,
    },
    {
      field: "opcoes",
      headerClassName: "super-app-theme--header",
      headerName: "Opções",
      headerAlign: "center",
      width: 100,
      type: "number",
      editable: false,
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (params) => (
        <div style={{ cursor: "pointer" }}>
          <Config row={params.row} />
        </div>
      ),
    },
  ];

  const colunas2 = [
    {
      field: "id",
      headerClassName: "super-app-theme--header",
      headerName: "ID",
      headerAlign: "center",
      width: 100,
    },
    {
      field: "codigo",
      headerClassName: "super-app-theme--header",
      headerName: "Código",
      headerAlign: "center",
      width: 110,
      editable: false,
    },
    {
      field: "descricao",
      headerClassName: "super-app-theme--header",
      headerName: "Descrição",
      headerAlign: "center",
      flex: 1,
      editable: false,
    },
    {
      field: "valor_total",
      headerClassName: "super-app-theme--header",
      headerName: "Valor",
      type: "number",
      headerAlign: "center",
      width: 110,
      valueFormatter: ({ value }) =>
        `${value.toLocaleString("pt-BR", {
          currency: "BRL",
        })}`,
    },
  ];

  function fecharOpces() {
    setOpcoes(false);
  }

  function fecharDetalhes() {
    fecharOpces();
    setDetalhes(false);
  }

  useEffect(() => {
    (async () => {
      setAguarda(true);
      try {
        const { data: results } = await api.get(
          `/v2/faturamento/guiaFaturamento`,
          {
            params: { id_lote },
          }
        );
        setDadosTabela(results);
      } catch (e) {
        enqueueSnackbar(
          "Não foi possível encontrar as guias desse lote. Por favor, tente novamente.",
          { variant: "error" }
        );
      }
      setAguarda(false);
    })();
  }, [enqueueSnackbar, id_lote]);

  useEffect(() => {
    (async () => {
      if (detalhes) {
        setAguarda(true);
        try {
          const { data: results } = await api.get(
            `/v2/faturamento/procedimentosFaturamento/?id_guia=${dadosGuia.id}`
          );
          setDadosProcedimentos(results);
        } catch (e) {
          enqueueSnackbar(
            "Não foi possível encontrar os procedimentos dessa guia. Por favor, tente novamente.",
            { variant: "error" }
          );
        }
        setAguarda(false);
      }
    })();
  }, [enqueueSnackbar, dadosGuia, detalhes]);

  const GuiaDetalhada = (
    <Paper
      style={{
        display: "flex",
        margin: "1rem",
        padding: "1rem",
        justifyContent: "center",
      }}
    >
      <div style={{ maxHeight: "600px" }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={3}>
            <TextField
              value={`${dadosGuia.codigo_pedido}-${dadosGuia.posto_pedido}`}
              label="Pedido: "
            />
          </Grid>
          <Grid item xs={10}>
            <div>
              <TextField
                value={dadosGuia.nome_cliente}
                label="Beneficiário"
                fullWidth
              />
            </div>
          </Grid>
          <Grid item xs={2}>
            <TextField value={dadosGuia.numero_carteira} label="Carteira" />
          </Grid>
          <Grid item xs={10}>
            <div>
              <TextField value={dadosGuia.medico} label="Medico" fullWidth />
            </div>
          </Grid>
          <Grid item xs={2}>
            <TextField value={dadosGuia.crm} label="CRM" />
          </Grid>
        </Grid>
        <div
          style={{
            display: "flex",
            marginTop: "1rem",
            flexDirection: "column",
            height: 400,
          }}
        >
          <Tabela
            disableColumnMenu={1}
            columns={colunas2}
            rows={dadosProcedimentos}
            pageSize={100}
            rowsPerPageOptions={[25]}
          />
        </div>
      </div>
    </Paper>
  );

  const opcoesModal = (
    <Paper
      style={{ display: "flex", margin: "1rem", justifyContent: "center" }}
    >
      <List component="nav" aria-label="main mailbox folders">
        <ListItemButton
          onClick={() => {
            setDetalhes(true);
          }}
        >
          <ListItemIcon>
            <Visibility color="primary" />
          </ListItemIcon>
          <ListItemText primary="visualizar" />
        </ListItemButton>
        <ListItem button onClick={fecharOpces}>
          <ListItemIcon>
            <Close color="secondary" />
          </ListItemIcon>
          <ListItemText primary="cancelar" />
        </ListItem>
      </List>
    </Paper>
  );

  const voltarParaFaturamento = () => {
    fechaOpcoes();
    setTela(0);
  };

  return (
    <>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div>
          <StyledPaper>
            <div style={{ display: "flex" }}>
              <Button
                variant="outlined"
                startIcon={<KeyboardBackspace />}
                onClick={voltarParaFaturamento}
              >
                Lotes
              </Button>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                flexWrap: "wrap",
              }}
            >
              <div className="cabecalho-caixa">
                <div className="cabecalho-titulo"> Lote</div>
                <div className="cabecalho-texto"> {id_lote} </div>
              </div>
              <div className="cabecalho-caixa">
                <div className="cabecalho-titulo"> Plano</div>
                <div className="cabecalho-texto"> {plano} </div>
              </div>
              <div className="cabecalho-caixa">
                <div className="cabecalho-titulo"> Data de criação</div>
                <div className="cabecalho-texto">
                  {" "}
                  {abertura
                    ? format(new Date(abertura), "dd/MM/yyyy HH:mm")
                    : null}{" "}
                </div>
              </div>
              <div className="cabecalho-caixa">
                <div className="cabecalho-titulo"> Valor</div>
                <div className="cabecalho-texto">
                  {" "}
                  {valor.toLocaleString("pt-BR", { currency: "BRL" })}{" "}
                </div>
              </div>
              <div className="cabecalho-caixa">
                <div className="cabecalho-titulo"> Protocolo</div>
                <div className="cabecalho-texto"> {protocolo} </div>
              </div>
              <div className="cabecalho-caixa">
                <div className="cabecalho-titulo"> Data de envio</div>
                <div className="cabecalho-texto">
                  {/* {envio ? format(new Date(envio), "dd/MM/yyyy HH:mm") : "Não enviado"}{" "} */}
                </div>
              </div>
            </div>
          </StyledPaper>
        </div>
        <div style={{ height: 700 }}>
          <Tabela
            disableColumnMenu={1}
            getRowClassName={(params) =>
              `super-app-theme--${params.row.id % 2 === 0 ? "par" : "impar"}`
            }
            columns={colunas}
            rows={dadosTabela}
            pageSize={100}
            rowsPerPageOptions={[25]}
          />
        </div>

        <Dialog
          maxWidth="sm"
          open={opcoes}
          onClose={fecharOpces}
          closeAfterTransition
        >
          <CorpoModal titulo="Opções" fechaModal={fecharOpces}>
            {opcoesModal}
          </CorpoModal>
        </Dialog>

        <Dialog
          fullWidth
          maxWidth="md"
          open={detalhes}
          onClose={fecharDetalhes}
          closeAfterTransition
        >
          <CorpoModal titulo="Guia Detalhada" fechaModal={fecharDetalhes}>
            {GuiaDetalhada}
          </CorpoModal>
        </Dialog>
      </div>
      <Waiter espera={aguarda} />
    </>
  );
}
