import { Box, IconButton, Typography } from "@mui/material";
import Modal from "@mui/material/Modal";
import GridContainer from "../../../../componentsv2/GridContainer";
import GridItem from "../../../../componentsv2/GridItem";
import { Close } from "@mui/icons-material";
import CardProduto from "./CardProduto";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  bgcolor: "background.paper",
  boxShadow: 24,
  maxWidth: 500,

  paddingBottom: 2,
  paddingLeft: 2,
  paddingRight: 2,
  borderRadius: 2,
  overflow: "auto",
  height: "90%",
};

export default function ModalVisualizarEstoque({ open, onClose, estoque }) {
  return (
    <Modal open={open} onClose={() => onClose(false)}>
      <Box sx={modalStyle}>
        <GridContainer gap={2}>
          <GridItem
            xs={12}
            sx={{
              top: 0,
              position: "sticky",
              backgroundColor: "white",
              width: "100%",
              paddingTop: 2,
            }}
          >
            <GridContainer alignItems="center">
              <GridItem xs={11}>
                <Typography variant="body1" sx={{ color: "black" }}>
                  Lista de produtos ({estoque?.nome})
                </Typography>
              </GridItem>
              <GridItem xs={1}>
                <IconButton onClick={() => onClose(false)}>
                  <Close />
                </IconButton>
              </GridItem>
            </GridContainer>
          </GridItem>

          {estoque?.listaProdutos.map((produto) => (
            <GridItem key={produto?.id} xs={12}>
              <CardProduto
                produto={{
                  ...produto.produto,
                  quantidade: produto.contador,
                  valorTotal: produto.produto.valor * produto.contador,
                }}
              />
            </GridItem>
          ))}
        </GridContainer>
      </Box>
    </Modal>
  );
}
