import React, { useState } from "react";

import {
  Button as MuiButton,
  Container,
  Grid,
  Modal as MuiModal,
  Paper,
  Box,
  Typography,
  IconButton,
  Divider,
} from "@mui/material";
import { Input } from "../../../../../componentsv2/Input";

import SettingsIcon from '@mui/icons-material/Settings';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Close } from "@mui/icons-material";
import Button from "../../../../../componentsv2/Button";
import { useSnackbar } from "notistack";
import { api } from "../../../../../services/Api";
import { useEffect } from "react";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",

  bgcolor: "background.paper",
  boxShadow: 24,
};

const dividerStyle = {
  width: "100%",
  backgroundColor: "#909099",
};

export default function OpcoesInventarioBem({ row, setUpdate, update }) {
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [openModal, setOpenModal] = useState(false);

  const [codTombador, setCodTombador] = useState("");

  const [modeloMarca, setModeloMarca] = useState("");
  const [unidade, setUnidade] = useState("");
  const [usuario, setUsuario] = useState("");
  const [setor, setSetor] = useState("");
  const [serial, setSerial] = useState("");
  const [estadoConservacao, setEstadoConservacao] = useState("");

  async function getBem() {
    try {
      const {
        data: { data },
      } = await api.get(`/v2/inventario/bem/${row.bem_id}`);

      setCodTombador(data.codTombador);

      setModeloMarca(data.material.modeloMarca);
      setUnidade(data.unidade.nome);
      setUsuario(data.usuario.username);
      setSetor(data.setor.nome);
      setSerial(data.serial);
      setEstadoConservacao(data.estadoConservacao);
    } catch (error) {
      enqueueSnackbar("Erro! Não foi possível buscar os inventários bens.", {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  }

  useEffect(() => {
    if (openModal) {
      getBem();
    }
    // eslint-disable-next-line
  }, [openModal]);

  function ModalOption(props) {
    return (
      <Container className="options-container" maxWidth="xs">
        <Paper className="options-paper" elevation={2}>
          <Grid container style={{ padding: "1rem 1rem" }}>
            <Grid item sm={12}>
              <Button
                onClick={() => setOpenModal(true)}
                variant="standard"
                startIcon={<VisibilityIcon />}
              >
                Visualizar bem
              </Button>
              <MuiModal open={openModal} onClose={setOpenModal}>
                <Box sx={modalStyle}>
                  <Grid container spacing={0}>
                    <Grid sx={{ padding: "15px" }} item container spacing={0}>
                      <Grid container justifyContent="space-between" item>
                        <Grid item>
                          <Typography variant="h6">{`${
                            codTombador ? codTombador + "-" : ""
                          }  ${modeloMarca} - ${serial}`}</Typography>
                        </Grid>
                        <Grid item>
                          <IconButton onClick={() => setOpenModal(false)}>
                            <Close />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Divider sx={dividerStyle} />
                    <Grid
                      sx={{ padding: "30px", marginBottom: "20px" }}
                      item
                      container
                      spacing={3}
                    >
                      <Grid item xs={6}>
                        <Input
                          label="Código Tombador"
                          variant="standard"
                          fullWidth
                          value={codTombador}
                          inputProps={{ readOnly: true }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Input
                          label="Produto"
                          variant="standard"
                          fullWidth
                          value={modeloMarca}
                          inputProps={{ readOnly: true }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Input
                          label="Unidade"
                          variant="standard"
                          fullWidth
                          value={unidade}
                          inputProps={{ readOnly: true }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Input
                          label="Setor"
                          variant="standard"
                          fullWidth
                          value={setor}
                          inputProps={{ readOnly: true }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Input
                          label="Usuário"
                          variant="standard"
                          fullWidth
                          value={usuario}
                          inputProps={{ readOnly: true }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Input
                          label="Estado de Conservação"
                          variant="standard"
                          fullWidth
                          value={
                            row.estado_conservacao_bem
                              ? row.estado_conservacao_bem
                              : estadoConservacao
                          }
                          inputProps={{ readOnly: true }}
                        />
                      </Grid>{" "}
                      <Grid item xs={12}>
                        <Input
                          label="Serial"
                          variant="standard"
                          fullWidth
                          value={serial}
                          inputProps={{ readOnly: true }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </MuiModal>
            </Grid>
            <Grid item sm={12}>
              <Button
                onClick={() => setOpen(false)}
                variant="standard"
                startIcon={<ArrowBackIcon />}
              >
                Voltar
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Container>
    );
  }

  return (
    <>
      <MuiButton style={{ color: "#000" }} onClick={() => setOpen(true)}>
        <SettingsIcon />
      </MuiButton>
      <MuiModal open={open} onClose={() => setOpen(false)}>
        <ModalOption row={row} />
      </MuiModal>
    </>
  );
}
