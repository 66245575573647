import React, { useContext } from "react";
import { Button, Grid, Paper, TextField } from "@mui/material";

import { ModalRecebimentoContext } from "../../Context/ModalRecebimentoContext";
// import { RetornoLoteContextProvider } from "../../Context/RetornoLoteContext";
import { RecebimentoContext } from "../../Context/RecebimentoContext";

import TabelaRetornoLote from "../TabelaRetornoLote";
import RecebimentoManual from "../RecebimentoManual";

import Waiter from "../../../../../components/Backdrop";

export default function TelaGerenciaLote() {
  const {
    informacoesLote,
    adicionaOuRetorna,
    adicionaRecebimento,
    retornarParaLotes,
    loading,
  } = useContext(ModalRecebimentoContext);

  const { setTela } = useContext(RecebimentoContext);

  const cabeçalhoLote = (
    <Paper sx={{ p: 2, mb: 2 }}>
      <Grid container spacing={2}>
        <Grid item lg={3} md={3} xs={6}>
          <TextField
            disabled
            id="protocolo"
            label="Protocolo"
            // value={informacoesLote.protocolo}
            defaultValue={informacoesLote.protocolo}
            fullWidth
            size="small"
          />
        </Grid>
        <Grid item lg={3} md={3} xs={6}>
          <TextField
            id="lote"
            disabled
            label="Lote"
            // value={informacoesLote.lote}
            defaultValue={informacoesLote.lote}
            fullWidth
            size="small"
          />
        </Grid>
        <Grid item lg={3} md={3} xs={6}>
          <TextField
            id="plano"
            label="Plano"
            disabled
            // value={informacoesLote.plano}
            defaultValue={informacoesLote.plano}
            fullWidth
            size="small"
          />
        </Grid>
        <Grid item lg={3} md={3} xs={6}>
          <TextField
            disabled
            id="valor"
            label="Enviado"
            defaultValue={
              informacoesLote.valor_enviado
                ? informacoesLote.valor_enviado.toLocaleString("pt-BR", {
                    currency: "BRL",
                  })
                : null
            }
            fullWidth
            size="small"
          />
        </Grid>
      </Grid>
    </Paper>
  );

  const botoes = (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        marginBottom: "1rem",
      }}
    >
      <Button
        variant="contained"
        color="primary"
        onClick={() => {
          setTela("Principal");
          retornarParaLotes();
        }}
        style={{
          width: "15rem",
          minWidth: "12rem",
          backgroundColor: "#003399",
        }}
      >
        Retorna para Lotes
      </Button>
      <Button
        variant="contained"
        color="success"
        onClick={() => adicionaOuRetorna("Criar")}
        style={{ width: "15rem", minWidth: "12rem" }}
      >
        Adicionar Notas
      </Button>
    </div>
  );

  return (
    <div
      className={{
        display: "flex",
        flexDirection: "column",
        height: "50rem",
        "& .MuiDataGrid-columnsContainer": {
          backgroundColor: "#FFF",
        },
      }}
    >
      {cabeçalhoLote}
      {!adicionaRecebimento && botoes}
      {adicionaRecebimento ? <RecebimentoManual /> : <TabelaRetornoLote />}
      <Waiter espera={loading} />
    </div>
  );
}
