import React, { useState,useContext } from "react";
import Cabecalho from "./components/cabecalho";
import Tabela from "./components/tabela";
import { HStack } from "../../../../componentsv2/Stack";
import { Context as PermissoesContext } from "../../../../Context/PermissoesContext";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";

export default function Sac() {
  const [setor, setSetor] = useState("");
  const [situacao, setSituacao] = useState("");
  const [tipoManifestacao, setTipoManifestacao] = useState("");
  const history = useHistory();
  const { checarPermissao } = useContext(PermissoesContext);
  const {enqueueSnackbar}=useSnackbar()
  const openForm = () => {
    if(checarPermissao("ATC006")){
      history.push("/atendimentoAoCliente/formularioOuvidoria");
    }else{
      enqueueSnackbar("Você não possui permissão para fazer um cadastro na Ouvidoria!",{variant:"error"})
    }
  };

  return (
    <HStack>
      <Cabecalho
        setor={setor}
        setSetor={setSetor}
        situacao={situacao}
        setSituacao={setSituacao}
        tipoManifestacao={tipoManifestacao}
        setTipoManifestacao={setTipoManifestacao}
        onClick={openForm}
      />
      <Tabela setor={setor} situacao={situacao} tipoManifestacao={tipoManifestacao} />
    </HStack>
  );
}
