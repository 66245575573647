import React, { useState } from "react";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Button, Modal, Box, Grid } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useSnackbar } from "notistack";

import { api } from "../../../services/Api";

import "./styles.css";
import dayjs from "dayjs";

function ModalEditar(props) {
  const { enqueueSnackbar } = useSnackbar();
  const { modalEditar, setModalEditar, coleta, permitidoColeta } = props;
  const [modalHoraAlterada, setModalHoraAlterada] = useState(false);
  const [modalHoraColetada, setModalHoraColetada] = useState(false);

  const [hora_coletado, set_hora_coletado] = useState(new Date());
  const [hora, set_hora] = useState(new Date());

  const fecharModal = () => {
    setModalEditar(false);
    setModalHoraColetada(false);
    setModalHoraAlterada(false);
    set_hora_coletado(new Date());
    set_hora(new Date());
  };

  async function alterarHora() {
    try {
      await api.put(`/v2/mapaCurvas/coletaCurvas/${coleta.id}`, {
        hora,
      });
      fecharModal();
      enqueueSnackbar("Horário alterado!", {
        variant: "success",
        autoHideDuration: 3000,
      });
    } catch (error) {
      enqueueSnackbar("Erro ao alterar o horário!", {
        variant: "error",
        autoHideDuration: 3000,
      });
      // console.log(error);
    }
  }

  async function marcarColetado(concluido) {
    try {
      await api.put(`/v2/mapaCurvas/coletaCurvas/${coleta.id}`, {
        hora_coletado,
        concluido,
      });
      fecharModal();
      enqueueSnackbar("Horário marcado como coletado!", {
        variant: "success",
        autoHideDuration: 3000,
      });
    } catch (error) {
      enqueueSnackbar("Erro ao marcar como coletado!", {
        variant: "error",
        autoHideDuration: 3000,
      });
      // console.log(error);
    }
  }

  return (
    <>
      <Modal
        open={modalEditar}
        onClose={fecharModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modal_editar">
          <Grid container spacing={1} justify="center" alignItems="stretch">
            <Grid item xs={12}>
              <h1 className="modal-title">
                Editar Exame
                <CloseIcon
                  id="fechar"
                  onClick={() => {
                    fecharModal();
                  }}
                />
              </h1>
            </Grid>
            {permitidoColeta && !coleta.concluido ? (
              <Grid item xs={12}>
                <Button
                  onClick={() => {
                    setModalHoraColetada(true);
                  }}
                  type="submit"
                  variant="contained"
                  color="primary"
                  margin="normal"
                  id="button"
                  fullWidth
                >
                  Marcar Como Coletado
                </Button>
              </Grid>
            ) : null}
            <Grid item xs={12}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                margin="normal"
                id="buttonIniciar"
                onClick={() => {
                  setModalHoraAlterada(true);
                }}
                fullWidth
              >
                Alterar Hora
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>

      <Modal
        open={modalHoraColetada}
        onClose={fecharModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modal_horaios">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Grid container spacing={1} justify="center" alignItems="stretch">
              <Grid item xs={12}>
                <h1 className="modal-title">
                  Hora da coleta
                  <CloseIcon
                    id="fechar"
                    onClick={() => {
                      fecharModal();
                    }}
                  />
                </h1>
              </Grid>
              <Grid item xs={12} className="grid_item_adicionar">
                <TimePicker
                  autoFocus
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      marcarColetado(true);
                    }
                  }}
                  style={{ marginBottom: "8px" }}
                  fullWidth
                  cancelLabel="Cancelar"
                  okLabel="salvar"
                  ampm={false}
                  label="Hora de início"
                  value={dayjs(hora_coletado)}
                  onChange={(e) => {
                    set_hora_coletado(e);
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <div className="buttons">
                  <div>
                    <Button
                      onClick={() => {
                        marcarColetado(true);
                      }}
                      variant="contained"
                      color="primary"
                    >
                      Salvar
                    </Button>
                  </div>
                </div>
              </Grid>
            </Grid>
          </LocalizationProvider>
        </Box>
      </Modal>

      <Modal
        open={modalHoraAlterada}
        onClose={fecharModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modal_horaios">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Grid container spacing={1} justify="center" alignItems="stretch">
              <Grid item xs={12}>
                <h1 className="modal-title">
                  Selecione o Horário
                  <CloseIcon
                    id="fechar"
                    onClick={() => {
                      fecharModal();
                    }}
                  />
                </h1>
              </Grid>
              <Grid item xs={12} className="grid_item_adicionar">
                <TimePicker
                  autoFocus
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      alterarHora();
                    }
                  }}
                  style={{ marginBottom: "8px" }}
                  fullWidth
                  cancelLabel="Cancelar"
                  okLabel="salvar"
                  ampm={false}
                  label="Hora de início"
                  value={dayjs(hora)}
                  onChange={(e) => {
                    set_hora(e);
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <div className="buttons">
                  <div>
                    <Button
                      onClick={() => {
                        alterarHora();
                      }}
                      variant="contained"
                      color="primary"
                    >
                      Salvar
                    </Button>
                  </div>
                </div>
              </Grid>
            </Grid>
          </LocalizationProvider>
        </Box>
      </Modal>
    </>
  );
}

export default ModalEditar;
