import React from "react";

import {
  Box,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import Button from "../../../componentsv2/Button";

export default function VisualizarObservacao({
  open,
  onClose,
  observacaoAtual,
}) {
  return (
    <React.Fragment>
      <Dialog
        open={open}
        fullWidth
        maxWidth="sm"
        onClose={() => onClose(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle sx={{ paddingBottom: "0px" }}>Observação</DialogTitle>

        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Grid xs={12} item>
              <Typography variant="h6" color="black">
                {observacaoAtual ? observacaoAtual : "..."}
              </Typography>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <Box
          sx={{
            display: "flex",
            margin: "20px",
            justifyContent: "end",
            marginLeft: "15px",
            marginRight: "15px",
          }}
        >
          <Button variant="contained" onClick={() => onClose(false)}>
            Ok
          </Button>
        </Box>
      </Dialog>
    </React.Fragment>
  );
}
