import React, { useRef, useState } from "react";
import { useSnackbar } from "notistack";
import {
  Grid,
  TextField,
  DialogActions,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import ImageIcon from "@mui/icons-material/Image";

import BaseDialog from "../../../../components/Dialog";
import Button from "../../../../components/Button";
import Loading from "../../../../components/Loading";
import Pill from "../../components/Pill";
import SearchModal from "../../components/SearchModal";

import { api, baseUrl } from "../../../../services/Api";

import "./styles.css";

const subtitles = {
  section: `Qualquer módulo pertencente a esta seção herdará essas especialidades
  automaticamente. Você pode editá-las manualmente depois, caso queira.`,
  module: `Qualquer artigo associado a este módulo receberá as especialidades associadas a este módulo
  automaticamente. Você pode editá-las manualmente depois, caso queira.
  `,
};

/*
  -> mode = "create" ou "edit"
  -> type = "section" ou "module"
  -> values = os valores do dialog, podem variar de acordo com o "type"
  -> open = autoexplicativo
*/

export default function Dialog({
  mode,
  type,
  values,
  open,
  onClose,
  updateValuesField,
  addSection,
  updateSection,
  addModule,
  updateModule,
  sections,
}) {
  const inputFileRef = useRef(null);
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showSpecialtiesModal, setShowSpecialtiesModal] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const onRemoveModuleIcon = () => {
    if (values && values.url_icone) {
      updateValuesField("url_icone", null);
    } else {
      setFile(null);
    }
  };

  const validate = (predicate, message) => {
    if (!predicate) {
      throw Error(message);
    }
  };

  const validateSection = () => {
    validate(values.nome !== "", "O nome da seção não pode ser vazio!");
  };

  const validateModule = () => {
    validate(values.nome !== "", "O nome do módulo não pode ser vazio!");
    validate(values.id_secao !== "", "Escolha uma seção para este módulo!");
    validate(values.url_icone || file, "O módulo precisa de um ícone!");
  };

  const addSpecialties = (specialties) => {
    setShowSpecialtiesModal(false);
    // retirando as repetidas
    const newSpecialties = specialties.filter(
      (specialty) => !values.especialidades.find((s) => s.id === specialty.id)
    );
    const updatedSpecialties = [...values.especialidades, ...newSpecialties];
    updateValuesField("especialidades", updatedSpecialties);
  };

  const removeSpecialty = (targetId) => {
    const targetIndex = values.especialidades.find(
      (specialty) => specialty.id === targetId
    );
    const copy = [...values.especialidades];
    copy.splice(targetIndex, 1);
    updateValuesField("especialidades", copy);
  };

  const editModule = async (nome, id, id_secao, especialidades) => {
    const formData = new FormData();
    formData.append("nome", nome);
    formData.append("id_secao", id_secao);
    formData.append("especialidades", JSON.stringify(especialidades));
    if (file) {
      formData.append("icon", file);
    }

    try {
      const { data } = await api.put(
        `/v2/portalMedico/modulo/${id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      updateModule(data);
      enqueueSnackbar("Módulo editado com sucesso!", { variant: "success" });
      onClose();
    } catch (e) {
      enqueueSnackbar("Um erro ocorreu ao tentar editar este módulo.", {
        variant: "error",
      });
    }
  };

  const createModule = async (nome, id_secao, especialidades) => {
    const formData = new FormData();
    formData.append("nome", nome);
    formData.append("id_secao", id_secao);
    formData.append("icon", file);
    formData.append("especialidades", JSON.stringify(especialidades));

    try {
      const { data } = await api.post("/v2/portalMedico/modulo", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      addModule(data);
      enqueueSnackbar("Módulo criado com sucesso!", { variant: "success" });
      onClose();
    } catch (e) {
      enqueueSnackbar("Um erro ocorreu ao tentar criar este módulo.", {
        variant: "error",
      });
    }
  };

  const onSubmitModule = async () => {
    try {
      validateModule();
    } catch (e) {
      return enqueueSnackbar(e.message, { variant: "error" });
    }
    setLoading(true);
    const { nome, id_secao, especialidades, id } = values;
    if (mode === "create") {
      await createModule(nome, id_secao, especialidades);
    } else {
      await editModule(nome, id, id_secao, especialidades);
    }
    setFile(null);
    return setLoading(false);
  };

  const createSection = async (nome, especialidades) => {
    try {
      const { data } = await api.post("/v2/portalMedico/secao", {
        nome,
        especialidades,
      });
      addSection(data);
      enqueueSnackbar("Seção criada com sucesso!", { variant: "success" });
      onClose();
    } catch (e) {
      enqueueSnackbar("Não foi possível criar esta seção.", {
        variant: "error",
      });
    }
  };

  const editSection = async (nome, id, especialidades) => {
    try {
      const { data } = await api.put(`/v2/portalMedico/secao/${id}`, {
        nome,
        especialidades,
      });
      updateSection(data);
      enqueueSnackbar("Seção editada com sucesso!", { variant: "success" });
      onClose();
    } catch (e) {
      enqueueSnackbar("Não foi editar criar esta seção.", { variant: "error" });
    }
  };

  const onSubmitSession = async () => {
    try {
      validateSection();
    } catch (e) {
      return enqueueSnackbar(e.message, { variant: "error" });
    }
    const { nome, especialidades, id } = values;
    setLoading(true);
    if (mode === "create") {
      await createSection(nome, especialidades);
    } else {
      await editSection(nome, id, especialidades);
    }

    return setLoading(false);
  };

  const onConfirm = async () => {
    if (type === "section") {
      await onSubmitSession();
    } else {
      await onSubmitModule();
    }
  };

  // funcao que lida com a mudanca de selecao na CRIACAO de um modulo.
  // Ao mudar a secao selecionado, iremos popular as especialidades do modulo
  // com as da tal secao selecionada
  const onSectionIdChange = (event) => {
    const {
      target: { value },
    } = event;
    if (mode === "create") {
      const target = sections.find((s) => s.id === value);
      updateValuesField("especialidades", target.especialidades);
    }

    updateValuesField("id_secao", value);
  };

  return (
    <>
      {loading && <Loading />}
      {showSpecialtiesModal && (
        <SearchModal
          open
          onClose={() => setShowSpecialtiesModal(false)}
          onConfirm={addSpecialties}
        />
      )}
      <BaseDialog
        open={open}
        title={`${mode === "create" ? "Criar" : "Editar"} ${
          type === "section" ? "Seção" : "Módulo"
        }`}
      >
        <CloseIcon
          onClick={onClose}
          style={{
            position: "absolute",
            top: "8px",
            right: "8px",
            cursor: "pointer",
          }}
        />
        <Grid container>
          {type === "section" && (
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Nome da seção"
                value={values.nome}
                onChange={(event) => {
                  // event.persist();
                  updateValuesField("nome", event.target.value);
                }}
              />
            </Grid>
          )}
          {type === "module" && (
            <>
              <Grid item xs={12} style={{ marginBottom: ".5rem" }}>
                <TextField
                  fullWidth
                  label="Nome do módulo"
                  value={values.nome}
                  onChange={(e) => {
                    // e.persist();
                    const {
                      target: { value },
                    } = e;
                    updateValuesField("nome", value);
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="label-secao-associada">Seção</InputLabel>
                  <Select
                    labelId="label-secao-associada"
                    value={values.id_secao}
                    onChange={onSectionIdChange}
                  >
                    {sections.map((s) => (
                      <MenuItem key={s.id} value={s.id}>
                        {s.nome}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} style={{ marginTop: "1.5rem" }}>
                <h2
                  style={{
                    marginBottom: ".75rem",
                    fontWeight: "500",
                    fontSize: "1.1rem",
                  }}
                >
                  Ícone do módulo
                </h2>
                {file === null && values.url_icone === null && (
                  <div
                    className="image-placeholder"
                    onClick={() => inputFileRef.current.click()}
                  >
                    <ImageIcon style={{ color: "white", fontSize: 40 }} />
                  </div>
                )}
                {(file || (values && values.url_icone)) && (
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <img
                      src={
                        values && values.url_icone
                          ? `${baseUrl}${values.url_icone}`
                          : URL.createObjectURL(file)
                      }
                      alt="selected input"
                      className="image-input"
                    />
                    <span
                      onClick={onRemoveModuleIcon}
                      style={{
                        color: "#003399",
                        fontWeight: 500,
                        marginTop: "10px",
                        cursor: "pointer",
                      }}
                    >
                      Remover
                    </span>
                  </div>
                )}
              </Grid>
            </>
          )}
          <div style={{ marginBottom: "1rem" }}>
            <h2 style={{ marginBottom: ".75rem", marginTop: "1.5rem" }}>
              Especialidades associadas
            </h2>
            <p
              style={{
                color: "black",
                fontSize: "1rem",
                marginBottom: ".3rem",
              }}
            >
              Gerencie quais especialidades serão associadas com{" "}
              {type === "section" ? "esta seção" : "este módulo"}.
            </p>
            <div
              style={{
                marginBottom: "1rem",
                marginTop: ".75rem",
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
              }}
            >
              {values &&
                values.especialidades.map((specialty) => (
                  <Pill
                    style={{ marginRight: ".3rem", marginBottom: ".3rem" }}
                    key={`specialty-${specialty.id}`}
                    onClose={() => removeSpecialty(specialty.id)}
                  >
                    {specialty.nome}
                  </Pill>
                ))}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={() => setShowSpecialtiesModal(true)}
              >
                <span
                  style={{
                    fontWeight: "500",
                    color: "#003399",
                    fontSize: "1rem",
                  }}
                >
                  Adicionar
                </span>
                <AddIcon style={{ color: "#003399", fontSize: "30px" }} />
              </div>
            </div>
            <span style={{ fontStyle: "italic" }}>{subtitles[type]}</span>
          </div>
        </Grid>
        <input
          type="file"
          style={{ display: "none" }}
          ref={inputFileRef}
          accept="image/*"
          onChange={(event) => setFile(event.target.files[0])}
        />
        <DialogActions style={{ marginTop: "2rem" }}>
          <Button secondary onClick={onConfirm}>
            Confirmar
          </Button>
        </DialogActions>
      </BaseDialog>
    </>
  );
}
