import React from "react";
import { Paper } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { styled } from "@mui/system";

import "./styles.css";

const StyledPaper = styled(Paper)(({ theme }) => ({
  "& .alerta": {
    backgroundColor: "#F5A9A9",
    fontWeight: "bold",
  },
  "& .alerta2": {
    backgroundColor: "#A9F5BC",
    fontWeight: "bold",
    border: "5px solid #F5A9A9",
  },
  "& .atual": {
    backgroundColor: "#A9D0F5",
    fontWeight: "bold",
  },
  "& .melhor": {
    backgroundColor: "#A9F5BC",
    fontWeight: "bold",
  },
  "& .menor": {
    backgroundColor: "#F3E2A9",
    fontWeight: "bold",
  },
}));

export default function Tabela(props) {
  const { exames } = props;

  const columns = [
    {
      field: "id",
      headerName: "ID",
      headerAlign: "center",
      width: 90,
      hide: true,
    },
    {
      field: "cod_tabela",
      headerName: "Tabela",
      headerAlign: "center",
      type: "number",
      width: 115,
    },
    {
      field: "convenio",
      headerName: "Convênio",
      headerAlign: "center",
      width: 150,
      editable: false,
      hide: true,
    },
    {
      field: "cod_exame",
      headerName: "Código",
      headerAlign: "center",
      width: 115,
    },
    {
      field: "nome_exame",
      headerName: "Nome",
      headerAlign: "center",
      width: 200,
      editable: false,
    },
    {
      field: "preco_exame",
      headerName: "Preço DNA",
      headerAlign: "center",
      type: "number",
      valueFormatter: ({ value }) =>
        `${value.toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        })}`,
      width: 140,
      editable: true,
    },
    {
      field: "quantidade_exame",
      headerName: "Quantidade",
      headerAlign: "center",
      type: "number",
      width: 120,
      editable: true,
    },
    {
      field: "cod_parceiro",
      headerName: "Parceiro",
      headerAlign: "center",
      width: 130,
      editable: true,
      hide: true,
    },
    {
      field: "ganho_atual",
      headerName: "Lucro Atual",
      headerAlign: "center",
      type: "number",
      valueFormatter: ({ value }) => {
        if (value) {
          return value.toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL",
          });
        }
        return 0;
      },
      width: 140,
      valueGetter: (params) => {
        const ganhoAtual =
          params.row.quantidade_exame * params.row.preco_exame -
          params.row.quantidade_exame * params.row.preco_exame_parceiro;
        return ganhoAtual || 0;
      },
    },
    {
      field: "cod_exame_db",
      headerName: "Código DB",
      headerAlign: "center",
      width: 130,
      hide: true,
    },
    {
      field: "nome_exame_db",
      headerName: "Nome DB",
      headerAlign: "center",
      width: 250,
      editable: false,
      hide: true,
    },
    {
      field: "preco_exame_db",
      headerName: "Preço DB",
      headerAlign: "center",
      type: "number",
      valueFormatter: ({ value }) => {
        if (value) {
          return value.toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL",
          });
        }
        return 0;
      },
      width: 140,
      editable: true,
    },
    {
      field: "cod_exame_dasa",
      headerName: "Código DASA",
      headerAlign: "center",
      width: 130,
      hide: true,
    },
    {
      field: "nome_exame_dasa",
      headerName: "Nome DASA",
      headerAlign: "center",
      width: 250,
      editable: false,
      hide: true,
    },
    {
      field: "preco_exame_dasa",
      headerName: "Preço DASA",
      headerAlign: "center",
      type: "number",
      valueFormatter: ({ value }) => {
        if (value) {
          return value.toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL",
          });
        }
        return 0;
      },
      width: 150,
      editable: true,
    },
    {
      field: "cod_exame_hp",
      headerName: "Código HP",
      headerAlign: "center",
      width: 130,
      hide: true,
    },
    {
      field: "nome_exame_hp",
      headerName: "Nome HP",
      headerAlign: "center",
      width: 250,
      editable: false,
      hide: true,
    },
    {
      field: "preco_exame_hp",
      headerName: "Preço HP",
      headerAlign: "center",
      type: "number",
      valueFormatter: ({ value }) => {
        if (value) {
          return value.toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL",
          });
        }
        return 0;
      },
      width: 140,
      editable: true,
    },
    {
      field: "menor_preco",
      headerName: "Melhor Preço",
      headerAlign: "center",
      type: "number",
      valueFormatter: ({ value }) =>
        `${value.toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        })}`,
      width: 150,
      valueGetter: (params) => {
        let melhorPreco = 0;
        if (
          params.row.preco_exame_dasa > 0
            ? params.row.preco_exame_db <= params.row.preco_exame_dasa
            : true && params.row.preco_exame_hp > 0
            ? params.row.preco_exame_db <= params.row.preco_exame_hp
            : true && params.row.preco_exame_db > 0
        ) {
          melhorPreco = params.row.preco_exame_db;
        }

        if (
          params.row.preco_exame_db > 0
            ? params.row.preco_exame_dasa <= params.row.preco_exame_db
            : true && params.row.preco_exame_hp > 0
            ? params.row.preco_exame_dasa <= params.row.preco_exame_hp
            : true && params.row.preco_exame_dasa > 0
        ) {
          melhorPreco = params.row.preco_exame_dasa;
        }

        if (
          params.row.preco_exame_dasa > 0
            ? params.row.preco_exame_hp <= params.row.preco_exame_dasa
            : true && params.row.preco_exame_db > 0
            ? params.row.preco_exame_hp <= params.row.preco_exame_db
            : true && params.row.preco_exame_hp > 0
        ) {
          melhorPreco = params.row.preco_exame_hp;
        }

        return melhorPreco || 0;
      },
    },
    {
      field: "melhor_ganho",
      headerName: "Melhor Lucro",
      headerAlign: "center",
      type: "number",
      valueFormatter: ({ value }) =>
        `${value.toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        })}`,
      width: 150,
      valueGetter: (params) => {
        const melhorGanho =
          params.row.quantidade_exame * params.row.preco_exame -
          params.row.quantidade_exame * params.row.menor_preco;

        return melhorGanho || 0;
      },
    },
    {
      field: "diferenca",
      headerName: "Potencial de Aumento",
      headerAlign: "center",
      type: "number",
      valueFormatter: ({ value }) =>
        `${value.toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        })}`,
      width: 140,
      valueGetter: (params) => {
        const diferenca = params.row.melhor_ganho - params.row.ganho_atual;

        return diferenca || 0;
      },
    },
    {
      field: "percentagem",
      headerName: "% de aumento",
      headerAlign: "center",
      type: "number",
      valueFormatter: ({ value }) =>
        `${value.toLocaleString("pt-BR", {
          style: "percent",
        })}`,
      width: 140,
      valueGetter: (params) => {
        let aux = 1;
        if (params.row.ganho_atual < 0) {
          aux = -1;
        }
        const percetagem_exame =
          params.row.melhor_ganho -
          (params.row.ganho_atual / params.row.ganho_atual) * aux;

        return percetagem_exame || 0;
      },
    },
  ];

  return (
    <StyledPaper
      style={{ backgroundColor: "White", height: 650, width: "100%" }}
    >
      <DataGrid
        rows={exames}
        columns={columns}
        pageSize={100}
        rowsPerPageOptions={[25]}
        disableSelectionOnClick
        getCellClassName={(params) => {
          if (params.field === "preco_exame") {
            switch (params.row.cod_parceiro) {
              case "APOIO DB":
                return Number(params.value) >= params.row.preco_exame_db
                  ? ""
                  : "alerta";
              case "APOIO ALVARO":
                return Number(params.value) >= params.row.preco_exame_dasa
                  ? ""
                  : "alerta";
              case "APOIO PARDINI":
                return Number(params.value) >= params.row.preco_exame_hp
                  ? ""
                  : "alerta";
              default:
                return "";
            }
          }
          if (params.field === "ganho_atual") {
            if (params.row.ganho_atual < 0) {
              return "alerta";
            }
          }
          if (params.field === "preco_exame_db") {
            if (params.row.cod_parceiro === "APOIO DB") {
              return "atual";
            }
          }
          if (params.field === "preco_exame_dasa") {
            if (params.row.cod_parceiro === "APOIO ALVARO") {
              return "atual";
            }
          }
          if (params.field === "preco_exame_hp") {
            if (params.row.cod_parceiro === "APOIO PARDINI") {
              return "atual";
            }
          }
          if (params.field === "diferenca") {
            if (params.row.menor_preco > params.row.preco_exame) {
              return "alerta";
            }
            if (params.row.diferenca > 0) {
              return "melhor";
            }
          }
          if (params.field === "melhor_ganho") {
            if (params.row.melhor_ganho > params.row.ganho_atual) {
              return "menor";
            }
          }
          return "";
        }}
      />
    </StyledPaper>
  );
}
