import { Radio } from "@mui/material";
import { styled } from "@mui/system";

const StyledRadio = styled(Radio)({
  root: {
    color: "#003399",
    "&$checked": {
      color: "#003399",
      "&:hover": {
        backgroundColor: "rgba(0,51,153,0.05)",
      },
    },
    "&:hover": {
      background: "rgba(0,51,153,0.05)",
    },
  },
  checked: {},
});

export default StyledRadio;
