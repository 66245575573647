import React, { useContext } from 'react';
import {
  Dialog,
  InputLabel,
} from '@mui/material';
import { Close } from '@mui/icons-material';

import { ColetaDomiciliarContext } from '../../../Context/ColetaDomiciarContext';
import { ModaisColetaDomiciliarContext } from '../../../Context/ModaisColetaDomiciarContext';
import { FormularioColetaDomiciliarContext } from '../../../Context/FormularioColetaDomiciliarContext';

export default function ModalVisualizaExames() {
  const { coleta } = useContext(ColetaDomiciliarContext);
  const { visualizaPedido, fechaVisualizaPedido } = useContext(ModaisColetaDomiciliarContext);
  const { exames } = useContext(FormularioColetaDomiciliarContext);

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={visualizaPedido}
      onClose={fechaVisualizaPedido}
    >
      <div>
        <div id="header-information-client">
          <i id="icon-info-client" className="fa fa-user-circle" aria-hidden="true" />
          <span>Informações do exame</span>
          <button
            type="button"
            id="btn-close-info-client"
            onClick={() => fechaVisualizaPedido()}
          >
            <Close id="icon-back-info-client" />
          </button>
        </div>
        <div className="grid-container-Cli">
          <div className="grid-item-Cli">
            <InputLabel id="nomeCliente">Cliente</InputLabel>
            <InputLabel id="nome">
              {coleta.localizador} - {coleta.nome}
            </InputLabel>
          </div>
        </div>
        {exames.map(resultado => (
          <div className="grid-container-Cli2">
            <div className="grid-item-Cli">
              <InputLabel id="nome">{resultado.DESC_EXAME}</InputLabel>
            </div>
          </div>
          ))}
      </div>
    </Dialog>
  )
}
