import React, { useEffect, useState, useContext, useMemo } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import Tabela from "../../../../../componentsv2/Tabela";
import {
  Grid,
  Typography,
  Paper,
  Box,
  Button as MuiButton,
  IconButton,
  Modal as MuiModal,
  Divider,
  Autocomplete,
} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Close } from "@mui/icons-material";

import { Input } from "../../../../../componentsv2/Input";
import Button from "../../../../../componentsv2/Button";
import { Select } from "../../../../../componentsv2/Select";

import SelectUnidade from "../../../components/SelectUnidade";

import { Context as PermissoesContext } from "../../../../../Context/PermissoesContext";
import useUser from "../../../../../hooks/useUser";

import OpcoesInventarioBem from "./opcoesInventarioBem";
import DarBaixa from "./darBaixa";
import InventariarBem from "./inventariarBem";

import { api } from "../../../../../services/Api";
import Container from "../../../../../componentsv2/Container";
import Header from "../../../../../components/Header";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",

  bgcolor: "background.paper",
  boxShadow: 24,
};

const dividerStyle = {
  width: "100%",
  backgroundColor: "#909099",
};

export default function InventarioBem() {
  const { search } = useLocation();
  const history = useHistory();

  const [situacao, setSituacao] = useState("");
  const [update, setUpdate] = useState(false);
  const [arrayDeBens, setArrayDeBens] = useState([]);
  const [bem, setBem] = useState(null);

  const [inventario, setInventario] = useState(null);

  const [openModalVincular, setOpenModalVincular] = useState(false);
  //const invId = new URLSearchParams(location.search).searchParams.get("inventarioId");
  // const params = new Proxy(new URLSearchParams(search), {
  //   get: (searchParams, prop) => searchParams.get(prop),
  // });
  // Get the value of "some_key" in eg "https://example.com/?some_key=some_value"
  // = params.inventarioId;
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const listaDeSituacoes = [
    { value: "", text: "Todos" },
    { value: "Inventariado", text: "Inventariado" },
    { value: "A Inventariar", text: "A Inventariar" },
    { value: "Pendente", text: "Pendente" },
  ];
  const { checarPermissao, checarPermissaoSemLog } = useContext(PermissoesContext);

  const { enqueueSnackbar } = useSnackbar();

  const [bensFiltrado, setBensFiltrado] = useState([]);
  const [unidadeUsuario, setUnidadeUsuario] = useState("");
  const [semAcessoTodasUnidades, setSemAcessoTodasUnidades] = useState(true);
  const usuario = useUser();
  const invId = searchParams.get("inventarioId");

  //const [bensId, setBensId] = useState([]);

  useEffect(() => {
    async function getArrays() {
      try {
        const {
          data: { data },
        } = await api.get(`/v2/inventario/bens`);

        const bensAtivos = data.filter(element => element.situacao === "Ativo");

        setArrayDeBens(bensAtivos);
      } catch (error) {
        enqueueSnackbar("Erro! Não foi possível buscar os bens.", {
          variant: "error",
        });
      }
    }
    getArrays();
  }, [enqueueSnackbar, unidadeUsuario]);

  async function getInventarioId() {
    try {
      const {
        data: { data },
      } = await api.get(`/v2/inventario/inventario/${invId}`);

      setInventario(data);
    } catch (error) {
      enqueueSnackbar("Erro! Não foi possível buscar os inventários bens.", {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  }

  async function checaUnidadeFuncionario() {
    try {
      const { data } = await api.get(`v2/controleAcesso/funcionarioUnidade`, {
        params: { cod_usu: usuario },
      });

      if (checarPermissao("INV030")) {
        setSemAcessoTodasUnidades(false);
      }

      setUnidadeUsuario(data.funcionario.unidade_id);
      if (!data.funcionario.unidade_id) {
        throw new Error("Sem Unidade!");
      }
    } catch (error) {
      history.push("/home");
      enqueueSnackbar(
        "Erro ao encontrar unidade de origem, solicite o cadastramento da unidade do seu usuário!",
        {
          variant: "error",
        }
      );
    }
  }

  async function getInventariosBens() {
    try {
      if (await checarPermissaoSemLog("INV026")) {
        const {
          data: { data },
        } = await api.get(`/v2/inventario/inventariosBens`, {
          params: {
            unidade: unidadeUsuario,
            situacao,
          },
        });

        setBensFiltrado(data);
      }
    } catch (error) {
      enqueueSnackbar("Erro! Não foi possível buscar os inventários bens.", {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  }

  // useEffect(() => {
  //   if (!dadosInventario) {
  //     history.push({ pathname: "/inventario", state: { abaInv: true } });
  //   }
  // }, [dadosInventario, history]);

  const vincularBemAoInventario = async e => {
    e.preventDefault();

    try {
      await api.post(`/v2/inventario/inventarioBem`, {
        bemId: bem.id,
        unidadeId: bem.unidadeId,
        inventarioId: invId,
      });
      setOpenModalVincular(false);
    } catch (error) {
      enqueueSnackbar("Erro ao tentar associar o bem ao inventário!", {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  useEffect(() => {
    getInventarioId();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getInventariosBens();

    // eslint-disable-next-line
  }, [update, unidadeUsuario, checarPermissaoSemLog, situacao, openModalVincular]);

  useEffect(() => {
    checaUnidadeFuncionario();
    // eslint-disable-next-line
  }, [checarPermissao]);

  const columns = [
    {
      field: "codTombador",
      headerName: "Código Tombador",
      width: 220,
      headerAlign: "left",
      align: "left",
      type: "number",
      valueGetter: params => params.row?.bem?.codTombador,
    },
    // {
    //   field: "data",
    //   headerName: "Data",
    //   width: 240,
    //   type: "dateTime",
    //   valueGetter: ({ value }) =>
    //     value &&
    //     new Date(value).toLocaleString({
    //       dateStyle: "full",
    //       timeStyle: "full",
    //     }),
    // },
    {
      field: "situacao",
      headerName: "Situação",
      width: 230,
      type: "text",
    },
    {
      field: "unidade",
      headerName: "Unidade",
      width: 220,
      type: "text",
      valueGetter: params => params.row?.unidade?.nome,
    },
    {
      field: "inventariar",
      headerName: "Inventariar",
      width: 130,
      renderCell: params => (
        <div style={{ cursor: "pointer" }}>
          <InventariarBem
            concluido={inventario?.situacao}
            inventarioId={inventario?.id}
            row={params.row}
            update={update}
            setUpdate={setUpdate}
          />
        </div>
      ),
    },
    {
      field: "darBaixa",
      headerName: "Dar baixa",

      width: 130,
      renderCell: params => (
        <div style={{ cursor: "pointer" }}>
          <DarBaixa
            concluido={inventario?.situacao}
            row={params.row}
            update={update}
            setUpdate={setUpdate}
          />
        </div>
      ),
    },
    {
      field: "opcoes",
      headerName: "Opções",

      width: 130,
      renderCell: params => (
        <div style={{ cursor: "pointer" }}>
          <OpcoesInventarioBem
            inventarioId={inventario?.id}
            row={params.row}
            update={update}
            setUpdate={setUpdate}
          />
        </div>
      ),
    },
  ];

  return (
    <>
    <Header titulo="Inventário" />
    <Container>
      <MuiButton onClick={() => {}}>
        <ArrowBackIcon
          style={{ color: "#003399" }}
          onClick={() =>
            history.push({ pathname: "/inventario", state: { abaInv: true } })
          }
        />
      </MuiButton>
      <Grid container alignItems="center" spacing={1} direction="column">
        <Grid item xs={12}>
          <Typography sx={{ marginTop: "40px", marginBottom: "15px" }} variant="h5">
            {inventario?.nome}
            {/* {dadosInventario && dadosInventario.row.nome} */}
          </Typography>
        </Grid>
      </Grid>
      <Grid container xs={12} item>
        <Paper sx={{ width: "100%", marginBottom: "30px", padding: "10px" }}>
          <Grid item container sx={{ marginLeft: "5px" }} justifyContent="space-between">
            <Grid xs={10} spacing={2} item container>
              <Grid item md={4} sm={12} lg={5} xs={12}>
                <SelectUnidade
                  disabled={semAcessoTodasUnidades}
                  value={unidadeUsuario}
                  onChange={e => setUnidadeUsuario(e.target.value)}
                />
              </Grid>

              <Grid item md={4} sm={6} lg={4} xs={6}>
                <Select
                  value={situacao}
                  valueAttribute="value"
                  textAttribute="text"
                  onChange={e => setSituacao(e.target.value)}
                  label="Situação"
                  arrayOptions={listaDeSituacoes}
                  variant="standard"
                />
              </Grid>
            </Grid>
            <Grid xs={2} sm={2} md={1} lg={1} item>
              <IconButton
                onClick={() => {
                  if (checarPermissao("INV027")) {
                    setOpenModalVincular(true);
                  } else {
                    enqueueSnackbar(
                      "Erro! Você não possui permissão de cadastrar um inventário bem.",
                      {
                        variant: "error",
                        autoHideDuration: 3000,
                      }
                    );
                  }
                }}
                color="success"
                size="large"
              >
                <AddCircleOutlineIcon fontSize="large" />
              </IconButton>
              <MuiModal open={openModalVincular} onClose={setOpenModalVincular}>
                <form onSubmit={vincularBemAoInventario}>
                  <Box sx={modalStyle}>
                    <Grid container spacing={0}>
                      <Grid sx={{ padding: "15px" }} item container spacing={0}>
                        <Grid container justifyContent="space-between" item>
                          <Grid item>
                            <Typography variant="h6">
                              Vincular bem ao inventário
                            </Typography>
                          </Grid>
                          <Grid item>
                            <IconButton onClick={() => setOpenModalVincular(false)}>
                              <Close />
                            </IconButton>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Divider sx={dividerStyle} />

                      <Grid
                        sx={{ padding: "30px", marginBottom: "20px" }}
                        item
                        container
                        spacing={3}
                      >
                        <Grid item xs={12}>
                          <Autocomplete
                            value={bem}
                            onChange={(event, value) => setBem(value)}
                            disablePortal
                            options={arrayDeBens}
                            getOptionLabel={option =>
                              `${option.codTombador ? option.codTombador + "-" : ""}${
                                option.material.modelo
                              }(${option.material.marca})-${option.serial}`
                            }
                            fullWidth
                            renderInput={params => (
                              <Input
                                {...params}
                                variant="standard"
                                label="Pesquisar bem"
                              />
                            )}
                          />
                        </Grid>
                        <Grid
                          sx={{ padding: "20px" }}
                          item
                          container
                          justifyContent="flex-end"
                        >
                          <Button type="submit" variant="contained" color="green">
                            Salvar
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Box>
                </form>
              </MuiModal>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={12} container>
        <Grid style={{ paddingTop: "10px" }} xs={12} sm={12} item>
          <Grid spacing={2} container>
            <Grid style={{ paddingTop: "10px" }} item xs={12} sm={12}>
              <Paper elevation={2}>
                <Box
                  sx={{
                    height: 1,
                    width: "100%",
                    "& .vencido": { color: "#99250F" },
                    marginBottom: "10px",
                  }}
                >
                  <div>
                    <Tabela
                      linhaNaoSelecionavel={false}
                      rows={bensFiltrado}
                      columns={columns}
                      autoHeight
                    />
                  </div>
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      </Container>
    </>
  );
}
