import React, { useState } from "react";
import { useSnackbar } from "notistack";
import {
  Autocomplete,
  Button,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { validarCPF } from "../../../utils/validations";

import { api } from "../../../services/Api";

export default function ModalCadastro(props) {
  // const [confirmSenha, setConfirmSenha] = useState(false);
  // const [helper, setHelper] = useState("");
  const [cpfValidado, setCpfValidado] = useState(false);
  const [helperCpf, setHelperCpf] = useState("");
  const [funcaoSelecionada, setFuncaoSelecionada] = useState();
  const [funcaoSelecionadaSalvar, setFuncaoSelecionadaSalvar] = useState("");
  const [unidadeSelecionada, setUnidadeSelecionada] = useState("");
  const [setorSelecionado, setSetorSelecionado] = useState("");

  const { enqueueSnackbar } = useSnackbar();

  // const validacaoSenha = value => {
  //   if (value !== props.senha) {
  //     setConfirmSenha(true);
  //     setHelper("Senhas diferentes");
  //   } else {
  //     setConfirmSenha(false);
  //     setHelper("");
  //   }
  // };

  const validacaoCPF = (value) => {
    const validaCPF = validarCPF(value);
    if (validaCPF !== true) {
      setCpfValidado(true);
      setHelperCpf("CPF não existe");
    } else {
      setCpfValidado(false);
      setHelperCpf("");
    }
  };

  async function criaUsuarioSubmit(e) {
    e.preventDefault();
    try {
      await api.post("/v2/controleAcesso/usuario", {
        username: props.user.toUpperCase(),
        email_usu: props.email,
        password: props.senha,
        nome_usu: props.nome.toUpperCase(),
        cpf: props.cpf,
        cod_funcao: funcaoSelecionadaSalvar,
        funcao: funcaoSelecionada.funcao,
        unidade_id: unidadeSelecionada,
        setor_id: setorSelecionado,
      });

      enqueueSnackbar("Usuário foi adicionado!", {
        variant: "success",
      });
      //  eslint-disable-next-line
      limparCampos();
      props.setOpen(false);
    } catch (error) {
      enqueueSnackbar("Não foi possível cadastrar o usuário, tente novamente", {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  }

  function limparCampos() {
    props.setUser("");
    props.setNome("");
    // props.setSenha("");
    props.setEmail("");
    props.setCpf("");
    setFuncaoSelecionada("");
    setFuncaoSelecionadaSalvar("");
    setSetorSelecionado("");
    setUnidadeSelecionada("");
  }

  function handleFuncao(e) {
    setFuncaoSelecionada(e);
    setFuncaoSelecionadaSalvar(e.codigo);
  }

  function handleSetor(e) {
    setSetorSelecionado(e);
  }

  function handleChange(event) {
    setUnidadeSelecionada(event.target.value);
  }

  const bodyModal = (
    <form onSubmit={criaUsuarioSubmit}>
      <Container maxWidth="md" style={{ padding: "0" }}>
        <Paper className="paper-cadastro" elevation={2}>
          <Grid
            container
            alignItems="center"
            justifyContent="center"
            style={{ padding: "1rem 1rem" }}
          >
            <Grid item xs={6} sm={6}>
              <h2>Cadastrar um usuário</h2>
            </Grid>
            <Grid id="botao-fechar" item xs={6} sm={6}>
              <Button type="button" onClick={() => props.setOpen(false)}>
                <CloseIcon color="primary" fontSize="medium" />
              </Button>
            </Grid>
            <Grid className="input-text-modal" item xs={12} sm={6}>
              <TextField
                label="Nome completo"
                variant="outlined"
                size="small"
                fullWidth
                //  eslint-disable-next-line
                value={props.nome}
                onChange={(e) => props.setNome(e.target.value)}
              />
            </Grid>
            <Grid className="input-text-modal" item xs={12} sm={4}>
              <TextField
                label="CPF"
                variant="outlined"
                size="small"
                fullWidth
                type="text"
                value={props.cpf}
                onChange={(event) => {
                  props.setCpf(event.target.value);
                  validacaoCPF(event.target.value);
                }}
                error={cpfValidado}
                helperText={helperCpf}
              />
            </Grid>
            <Grid className="input-text-modal" item xs={12} sm={10}>
              <FormControl size="small" variant="outlined" fullWidth>
                <InputLabel id="select-label">Unidade</InputLabel>
                <Select
                  value={unidadeSelecionada}
                  onChange={(e) => handleChange(e)}
                  labelId="select-label"
                  id="select"
                  label="Unidade"
                >
                  {props.unidades.map((unidade) => (
                    <MenuItem key={unidade.id} value={unidade.id}>
                      {unidade.nome}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid className="input-text-modal" item xs={12} sm={10}>
              <FormControl
                defaultValue=" "
                size="small"
                variant="outlined"
                fullWidth
              >
                <InputLabel id="select-label">Setor</InputLabel>
                <Select
                  labelId="select-label"
                  id="select"
                  label="Ramal"
                  value={setorSelecionado}
                  onChange={(e) => handleSetor(e.target.value)}
                >
                  {props.setores.map((setores) => (
                    <MenuItem key={setores.id} value={setores.id}>
                      {setores.nome}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid className="input-text-modal" item xs={12} sm={10}>
              <Autocomplete
                options={props?.funcao}
                getOptionLabel={(option) =>
                  `${option.funcao} (${option.codigo})`
                    ? `${option.funcao} (${option.codigo})`
                    : ""
                }
                value={funcaoSelecionada}
                onChange={(e, value) => handleFuncao(value)}
                size="small"
                renderInput={(params) => (
                  <TextField {...params} label="Função" variant="outlined" />
                )}
              />
            </Grid>
            <Grid className="input-text-modal" item xs={12} sm={6}>
              <TextField
                label="E-mail"
                variant="outlined"
                size="small"
                fullWidth
                //  eslint-disable-next-line
                value={props.email}
                onChange={(e) => props.setEmail(e.target.value)}
              />
            </Grid>
            <Grid className="input-text-modal" item xs={12} sm={4}>
              <TextField
                label="Usuário"
                variant="outlined"
                size="small"
                fullWidth
                //  eslint-disable-next-line
                value={props.user}
                onChange={(e) => props.setUser(e.target.value)}
              />
            </Grid>

            {/* <Grid className="input-text-modal" item xs={12} sm={5}>
              <TextField
                type="password"
                label="Senha"
                variant="outlined"
                size="small"
                fullWidth
                onChange={e => props.setSenha(e.target.value)}
              />
            </Grid>
            <Grid className="input-text-modal" item xs={12} sm={5}>
              <TextField
                type="password"
                label="Repetir senha"
                variant="outlined"
                size="small"
                fullWidth
                onChange={event => validacaoSenha(event.target.value)}
                error={confirmSenha}
                helperText={helper}
              />
            </Grid> */}
            <Grid
              className="input-text-modal"
              id="botao-cadastrar"
              item
              xs={10}
            >
              <Button type="submit" variant="contained" color="primary">
                Cadastrar
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Container>
    </form>
  );

  return (
    //  eslint-disable-next-line
    <Modal open={props.open} onClose={() => props.setOpen(false)}>
      {bodyModal}
    </Modal>
  );
}
