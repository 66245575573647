import { Box, Grid, Paper } from "@mui/material";
import React from "react";

import alvaroLogo from "../../assets/alvaro.png";
import hermes from "../../assets/hermes.png";
import dna from "../../assets/dna-nova-logo.png";
import dbdiagnostico from "../../assets/db-diagnostico.png";

import Header from "../../components/Header";
import Container from "../../componentsv2/Container";

// eslint-disable-next-line
const PaperExames = ({ link, img, nome }) => (
  <Box component="a" href={link} target="_blank">
    <Paper
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: 170,
        height: 120,
      }}
      elevation={2}
    >
      <img src={img} alt={nome} />
    </Paper>
  </Box>
);

export default function ProcurarExames() {
  return (
    <>
    <Header titulo="Controle Acesso"/>
      <Container >
      <Grid sx={{ pt: 10 }} container>
        <Grid item xs={12} sm={6} md={3}>
          <PaperExames
            nome="DNA Center exames"
            link="https://web.dnacenter.com.br/web#/"
            img={dna}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <PaperExames
            nome="Hermes Pardini"
            img={hermes}
            link="https://www.hermespardini.com.br/csp/HelpExamesPublico/HPardini.HelpExame.Pagina.ConsultaPubHelpExame.cls?CSPCHD=02u0020b0000Tj2ea5kQ36AWREDh9MI39ZtV4EU3y6jSi$spXV&"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <PaperExames
            nome="DB Diagnósticos"
            link="https://gde.diagnosticosdobrasil.com.br/GDE_Home/Main.aspx"
            img={dbdiagnostico}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <PaperExames
            nome="Álvaro"
            img={alvaroLogo}
            link="https://aol.alvaroapoio.com.br/exames/"
          />
        </Grid>
      </Grid>
    </Container>
    </>
  );
}
