import React, { useContext, useEffect, useRef, useState } from "react";

import { useSnackbar } from "notistack";
import { useHistory, useLocation } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import TabelaProcedimentos from "./Components/TabelaProcedimentos";
import SelectSituacao from "../../Components/SelectSituacao";
import Loading from "../../../../components/Loading";
import SelectFormaPagamento from "../../Components/SelectFormaPagamento";
import Header from "../../../../components/Header";

import AutoCompleteClientes from "../../../../componentsv2/AutoCompleteClientes";
import Button from "../../../../componentsv2/Button";
import Paper from "../../../../componentsv2/Paper";
import GridContainer from "../../../../componentsv2/GridContainer";
import GridItem from "../../../../componentsv2/GridItem";
import { Input } from "../../../../componentsv2/Input";
import SelectEstado from "../../../../componentsv2/SelectEstado";
import Container from "../../../../componentsv2/Container";

import { Context } from "../../../../Context/PermissoesContext";

import ModalProcedimento from "../ModalProcedimento";

import { api } from "../../../../services/Api";

export default function EditarAgendamento() {
  const [openModalForm, setOpenModalForm] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const [procedimentoSelecionado, setProcedimentoSelecionado] = useState([]);

  const [situacao, setSituacao] = useState("");
  const [cliente, setCliente] = useState(null);
  const [endereco, setEndereco] = useState("");
  const [cep, setCep] = useState("");
  const [bairro, setBairro] = useState("");
  const [cidade, setCidade] = useState("");
  const [estado, setEstado] = useState("");
  const [complemento, setComplemento] = useState("");
  const [observacao, setObservacao] = useState("");
  const [totalExames, setTotalExames] = useState(0);
  const [desconto, setDesconto] = useState(0);
  const [formaPagamento, setFormaPagamento] = useState("");
  const [nomeCliente, setNomeCliente] = useState("");
  const [cpfCliente, setCpfCliente] = useState("");
  const idAgendamento = useRef("");

  const [atualizaTabelaProcedimentos, setAtualizaTabelaProcedimentos] =
    useState(false);
  const [loading, setLoading] = useState(false);
  const { checarPermissao } = useContext(Context);
  const atualizarTabelaProcedimentos = () => {
    setAtualizaTabelaProcedimentos(!atualizaTabelaProcedimentos);
  };

  const { enqueueSnackbar } = useSnackbar();

  const enviarParaContasAReceber = async (dados) => {
    try {
      const buscaPorExistente = await api.get(
        `/v2/contasaReceber/contasAReceberOrigemId/${idAgendamento.current}`
      );
      const { data } = buscaPorExistente;
      if (data) {
        let { origem, origemId, cpfCliente, codigoPedido, valor } = dados;
        const dadosUpdateContas = {
          origem,
          cpfCliente,
          valor,
          codigoPedido,
          origemId,
        };
        await api.put(
          `/v2/contasaReceber/editarContasAReceber/${data.id}`,
          dadosUpdateContas
        );
        // enqueueSnackbar("Dados de Contas a Receber atualizados", {
        //   variant: "info",
        // });
        return;
      }

      await api.post(`/v2/contasaReceber/adicionaContasAReceber`, dados);
    } catch (error) {
      enqueueSnackbar(
        "Erro! Não foi possível enviar os dados para Contas a receber.",
        {
          variant: "error",
        }
      );
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const dados = {
        procedimentosId: procedimentoSelecionado,
        complemento,
        observacoes: observacao,
        situacao,
        cep,
        cidade,
        bairro,
        estado,
        endereco,
        clienteId: cliente.id,
        desconto: desconto ? desconto : 0,
        formaPagamento,
      };

      const dadosContasAReceber = {
        origem: "AGENDA CARE",
        origemId: idAgendamento.current,
        cpfCliente: cpfCliente,
        nome: nomeCliente,
        endereco,
        endereco_numero: "",
        complemento,
        bairro,
        cidade,
        estado,
        cep,
        celular: "",
        email: "",
        codigoPedido: "",
        valor: totalExames
          .replace("R$ ", "")
          .replace(",00", "")
          .replaceAll(".", ""),
      };
      if (situacao === "Realizado") {
        enviarParaContasAReceber(dadosContasAReceber);
      }

      await api.put(
        `v2/agendaCare/agendamento/${location.state.row.id}`,
        dados
      );
      history.push("/agendaCare");
      enqueueSnackbar("Alterações salvas com sucesso!", {
        variant: "success",
      });
    } catch (error) {
      enqueueSnackbar(
        "Erro! Não foi possível alterar os dados do agendamento.",
        {
          variant: "error",
        }
      );
    }
  };

  const pegarDadosAgendamento = async () => {
    setLoading(true);
    if (location.state?.row) {
      try {
        const {
          data: { data },
        } = await api.get(`v2/agendaCare/agendamento/${location.state.row.id}`);
        const arrayIdProcedimentos = data.procedimentos.map((el) => el.id);
        setProcedimentoSelecionado(arrayIdProcedimentos);
        setSituacao(data.situacao);
        setCliente(data.cliente);
        setEndereco(data.endereco);
        setCep(data.cep);
        setBairro(data.bairro);
        setCidade(data.cidade);
        setComplemento(data.complemento);
        setObservacao(data.observacoes);
        setEstado(data.estado);
        setDesconto(data.desconto);
        setFormaPagamento(data.formaPagamento);

        idAgendamento.current = location.state.row.id;

        setNomeCliente(data.cliente.nome);
        setCpfCliente(data.cliente.cpf.replaceAll(" ", ""));
      } catch (error) {
        enqueueSnackbar(
          "Erro! Não foi possível buscar os dados do agendamento.",
          {
            variant: "error",
          }
        );
      }
    } else {
      history.push("/agendaCare");
    }
    setLoading(false);
  };

  useEffect(() => {
    pegarDadosAgendamento();
    // eslint-disable-next-line
  }, [location.state?.row]);

  return (
    <>
      <Header titulo="Editar Agendamento" />
      <Container>
        <Button onClick={() => history.push({ pathname: "/agendaCare" })}>
          <ArrowBackIcon style={{ color: "#003399" }} />
        </Button>
        <Paper style={{ padding: "40px", marginTop: "10px" }} elevation={2}>
          <form onSubmit={handleSubmit}>
            <GridContainer spacing={2} xs={12}>
              <GridContainer item xs={6} spacing={2}>
                <GridItem xs={3}>
                  <SelectSituacao
                    onChange={(e) => {
                      setSituacao(e.target.value);
                    }}
                    value={situacao}
                    todas={false}
                    required
                  />
                </GridItem>

                <GridItem xs={9}>
                  <AutoCompleteClientes
                    value={cliente}
                    onChange={setCliente}
                    required
                  />
                </GridItem>

                <GridItem xs={8}>
                  <Input
                    required
                    fullWidth
                    onChange={(e) => {
                      setEndereco(e.target.value);
                    }}
                    value={endereco}
                    label="Endereço"
                    type="text"
                    variant="standard"
                  />
                </GridItem>
                <GridItem xs={4}>
                  <SelectEstado
                    onChange={(e) => {
                      setEstado(e.target.value);
                    }}
                    value={estado}
                  />
                </GridItem>
                <GridItem xs={4}>
                  <Input
                    onChange={(e) => {
                      setCep(e.target.value);
                    }}
                    value={cep}
                    required
                    fullWidth
                    label="CEP"
                    type="text"
                    variant="standard"
                  ></Input>
                </GridItem>
                <GridItem xs={4}>
                  <Input
                    onChange={(e) => {
                      setBairro(e.target.value);
                    }}
                    value={bairro}
                    required
                    fullWidth
                    label="Bairro"
                    type="text"
                    variant="standard"
                  ></Input>
                </GridItem>
                <GridItem xs={4}>
                  <Input
                    onChange={(e) => {
                      setCidade(e.target.value);
                    }}
                    value={cidade}
                    required
                    fullWidth
                    label="Cidade"
                    type="text"
                    variant="standard"
                  ></Input>
                </GridItem>

                <GridItem xs={12}>
                  <Input
                    onChange={(e) => {
                      setComplemento(e.target.value);
                    }}
                    value={complemento}
                    fullWidth
                    label="Complemento"
                    type="text"
                    variant="standard"
                  ></Input>
                </GridItem>

                <GridItem xs={12}>
                  <Input
                    onChange={(e) => {
                      setObservacao(e.target.value);
                    }}
                    value={observacao}
                    multiline
                    maxRows={5}
                    fullWidth
                    label="Observações"
                    type="text"
                    variant="standard"
                  />
                </GridItem>

                <GridItem sm={4} xs={12}>
                  <SelectFormaPagamento
                    onChange={(e) => {
                      setFormaPagamento(e.target.value);
                    }}
                    value={formaPagamento}
                    required
                  />
                </GridItem>
                {checarPermissao("AGC002") && (
                  <>
                    <GridItem sm={4} xs={12}>
                      <Input
                        onChange={(e) => {
                          setDesconto(e.target.value);
                        }}
                        value={desconto}
                        fullWidth
                        label="Desconto R$:"
                        type="number"
                        variant="standard"
                      />
                    </GridItem>

                    <GridItem sm={4} xs={12}>
                      <Input
                        value={totalExames}
                        fullWidth
                        label="Total"
                        type="text"
                        inputProps={{ readOnly: true }}
                        variant="standard"
                      />
                    </GridItem>
                  </>
                )}
              </GridContainer>

              <GridContainer item xs={6} spacing={1}>
                <GridItem xs={12}>
                  <TabelaProcedimentos
                    selectedRows={procedimentoSelecionado}
                    atualizaTabela={atualizaTabelaProcedimentos}
                    setSelectedRows={setProcedimentoSelecionado}
                    desconto={desconto}
                    setTotal={setTotalExames}
                  />
                </GridItem>
              </GridContainer>
            </GridContainer>

            {openModalForm && (
              <ModalProcedimento
                open={openModalForm}
                onClose={() => {
                  setOpenModalForm(false);
                  atualizarTabelaProcedimentos();
                }}
              />
            )}

            <GridContainer
              item
              sx={{ marginTop: "30px" }}
              justifyContent="flex-end"
              spacing={2}
            >
              <GridItem>
                <Button
                  onClick={() => history.push({ pathname: "/agendaCare" })}
                  color="red"
                  variant="outlined"
                >
                  Cancelar
                </Button>
              </GridItem>
              <GridItem>
                <Button
                  id="agendarButton"
                  type="submit"
                  color="green"
                  variant="contained"
                >
                  Salvar
                </Button>
              </GridItem>
            </GridContainer>
          </form>
        </Paper>
      </Container>
      {loading && <Loading />}
    </>
  );
}
