import React, { useState } from "react";

import Tabs from "../../components/Tabs";

import Topicos from "./pages/Topicos";
import Conteudo from "./pages/Conteudo";
import Organizacao from "./pages/Organizacao";

import "./styles.css";
import Header from "../../components/Header";
import Container from "../../componentsv2/Container";

export default function PortalmMedico() {
  const [tab, setTab] = useState(0);
  return (
    <>
      <Header titulo="Portal do Médico" />
      <Container>
        <main className="portal-medico">
          <div style={{ marginTop: "4rem", marginBottom: "2rem" }}>
            <Tabs
              tabs={["Conteúdo", "Organização", "Especialidades"]}
              value={tab}
              handleChange={(event, newValue) => setTab(newValue)}
            />
          </div>
          {tab === 0 && <Conteudo />}
          {tab === 1 && <Organizacao />}
          {tab === 2 && <Topicos />}
        </main>
        </Container>
    </>
  );
}
