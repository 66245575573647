import { Grid } from "@mui/material";
import React from "react";

import Buttons from "./Buttons";
import HeaderSelect from "./HeaderSelect/index";
import Tabela from "./Table";

export default function Estoque() {
  return (
    <>
      <Grid container>
        <Grid item xs={12} sm={12}>
          <HeaderSelect />
        </Grid>
        <Grid style={{ paddingTop: "10px" }} item xs={12} sm={12}>
          <Buttons />
        </Grid>
        <Grid style={{ paddingTop: "10px" }} item xs={12} sm={12}>
          <Tabela />
        </Grid>
      </Grid>
    </>
  );
}
