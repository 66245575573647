import React, { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Modal,
  Box,
  Grid,
} from "@mui/material";

import { api } from "../../../services/Api";

import "./styles.css";
import dayjs from "dayjs";

function ModalAdicionar({ modalAdicionar, setModalAdicionar, unidade }) {
  const { enqueueSnackbar } = useSnackbar();

  const [hora_inicio, setHora_inicio] = useState(new Date());

  const [id_exame, setId_exame] = useState(0);

  const [poltrona, setPoltrona] = useState("");
  const [nome_paciente, setNome_paciente] = useState("");
  const [configuracao_tempos, setConfiguracao_tempos] = useState("");
  const [usuario_coleta, setUsuario_coleta] = useState("");
  const [examesColeta, setExamesColeta] = useState("");

  const fecharModal = () => setModalAdicionar(false);

  async function postMapa() {
    try {
      await api.post("/v2/mapaCurvas/mapa", {
        id_exame,
        nome_paciente,
        situacao: "pendente",
        poltrona,
        usuario_coleta,
        hora_inicio,
        configuracao_tempos,
        id_unidade: unidade,
      });

      fecharModal();
      enqueueSnackbar("Mapa adicionado!", {
        variant: "success",
        autoHideDuration: 3000,
      });
    } catch (error) {
      enqueueSnackbar("Erro ao adicionar o cartão!", {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  }

  function horaSimulada() {
    const splits = configuracao_tempos.split(",");
    let minutos = new Date(hora_inicio).getHours() * 60;
    minutos += new Date(hora_inicio).getMinutes();
    let horaTmp;
    let minutosTmp;
    const arrayFinal = [];
    let stringTmp;
    splits.map((i) => {
      minutosTmp = minutos;
      minutosTmp += Number(i);
      horaTmp = Math.floor(minutosTmp / 60);
      minutosTmp -= horaTmp * 60;
      stringTmp = `${horaTmp}:${minutosTmp}`;
      if (horaTmp < 10) {
        stringTmp = `0${horaTmp}:${minutosTmp}`;
      }
      if (minutosTmp < 10) {
        stringTmp = `${horaTmp}:0${minutosTmp}`;
      }
      if (horaTmp < 10 && minutosTmp < 10) {
        stringTmp = `0${horaTmp}:0${minutosTmp}`;
      }
      // eslint-disable-next-line
      if (isNaN(horaTmp) || isNaN(minutosTmp)) {
        stringTmp = "";
      }
      arrayFinal.push(stringTmp);
      return null;
    });

    return arrayFinal;
  }

  useEffect(() => {
    setId_exame(0);
    setHora_inicio(new Date());
    setPoltrona("");
    setNome_paciente("");
    setConfiguracao_tempos("");
    setUsuario_coleta("");
    setExamesColeta("");
  }, [modalAdicionar]);

  function validar() {
    let isValid = true;
    const mensagemErro = [];
    let novo_configuracao_tempos = configuracao_tempos;

    mensagemErro.push("Erro no preenchimento do(s) campo(s):");

    if (configuracao_tempos[configuracao_tempos.length - 1] === ",") {
      novo_configuracao_tempos = configuracao_tempos.substring(
        0,
        configuracao_tempos.length - 1
      );
    }

    const validarIntervalo = /^[0-9, ]*$/.test(novo_configuracao_tempos);
    const validarPoltrona = true; // /^[0-9]*$/.test(poltrona);
    const validarPaciente = /^[A-zÀ-ú ]*$/.test(nome_paciente);
    const validarColetador = /^[A-Za-z ]*$/.test(usuario_coleta);

    if (id_exame === 0) {
      mensagemErro.push(" EXAMES,");
      isValid = false;
    }
    if (!validarIntervalo || novo_configuracao_tempos === "") {
      mensagemErro.push(" INTERVALO,");
      isValid = false;
    }
    if (!validarPoltrona || poltrona === "" || poltrona < 1) {
      mensagemErro.push(" POLTRONA,");
      isValid = false;
    }
    if (!validarPaciente || nome_paciente === "") {
      mensagemErro.push(" PACIENTE,");
      isValid = false;
    }
    if (!validarColetador || usuario_coleta === "") {
      mensagemErro.push(" COLETADOR");
      isValid = false;
    }
    if (novo_configuracao_tempos === "") {
      isValid = false;
    }
    if (poltrona === "") {
      isValid = false;
    }
    if (nome_paciente === "") {
      isValid = false;
    }
    if (usuario_coleta === "") {
      isValid = false;
    }

    const stringSemVirgula = mensagemErro[mensagemErro.length - 1].replace(
      ",",
      ""
    );
    mensagemErro.pop();
    mensagemErro.push(stringSemVirgula);

    if (isValid === false) {
      enqueueSnackbar(mensagemErro, {
        variant: "error",
        autoHideDuration: 3000,
      });
    } else {
      postMapa();
    }
  }

  function preencheIntervalo(exame) {
    examesColeta.map((i) => {
      if (exame === i.id) {
        setConfiguracao_tempos(i.configuracao_tempos);
      }
      return null;
    });
  }

  async function pegarExames() {
    try {
      const {
        data: { exames },
      } = await api.get("/v2/mapaCurvas/examesDetalhes");
      setExamesColeta(exames);
    } catch (error) {
      enqueueSnackbar("Erro ao procurar os exames!", {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  }

  useEffect(() => {
    pegarExames();
    // eslint-disable-next-line
  }, []);

  return (
    <Modal
      open={modalAdicionar}
      onClose={fecharModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className="modal_adicionar">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Grid container spacing={1} justify="center" alignItems="stretch">
            <Grid item xs={12}>
              <h1 className="modal-title">
                Adicionar Examinado
                <CloseIcon
                  id="fechar"
                  onClick={() => {
                    fecharModal();
                  }}
                />
              </h1>
            </Grid>
            <Grid item xs={7}>
              <TextField
                label="Paciente"
                onChange={(event) => {
                  setNome_paciente(event.target.value);
                }}
                margin="normal"
                variant="filled"
                id="input"
                fullWidth
              />
            </Grid>
            <Grid item xs={5}>
              <TextField
                label="Poltrona"
                onChange={(event) => {
                  setPoltrona(event.target.value);
                }}
                margin="normal"
                type="text"
                variant="filled"
                id="input"
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Coletador(a)"
                onChange={(event) => {
                  setUsuario_coleta(event.target.value);
                }}
                margin="normal"
                variant="filled"
                id="input"
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl variant="filled" fullWidth>
                <InputLabel>Exames</InputLabel>
                <Select
                  fullWidth
                  value={id_exame}
                  onChange={(event) => {
                    setId_exame(event.target.value);
                    preencheIntervalo(event.target.value);
                  }}
                >
                  <MenuItem value={0} key="exame-0">
                    Selecione o exame
                  </MenuItem>
                  {examesColeta.length > 0
                    ? examesColeta.map((exame) => (
                        <MenuItem value={exame.id} key={`exame-${exame.id}`}>
                          {exame.nome} - {exame.codigo}
                        </MenuItem>
                      ))
                    : null}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Intervalo"
                onChange={(event) => {
                  setConfiguracao_tempos(event.target.value);
                }}
                margin="normal"
                type="text"
                variant="filled"
                placeholder="Ex:0,30,60,90"
                id="input"
                value={configuracao_tempos}
                fullWidth
              />
            </Grid>
            <Grid item xs={6} className="grid_item_adicionar">
              <TimePicker
                style={{ marginTop: "16px", marginBottom: "8px" }}
                fullWidth
                cancelLabel="Cancelar"
                okLabel="salvar"
                ampm={false}
                label="Hora de início"
                value={dayjs(hora_inicio)}
                onChange={setHora_inicio}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Hora Simulada"
                margin="normal"
                type="text"
                disabled
                variant="filled"
                id="input"
                value={horaSimulada()}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <div className="buttons">
                <div>
                  <Button
                    onClick={() => {
                      validar();
                    }}
                    variant="contained"
                    color="primary"
                  >
                    Adicionar
                  </Button>
                </div>
              </div>
            </Grid>
          </Grid>
        </LocalizationProvider>
      </Box>
    </Modal>
  );
}

export default ModalAdicionar;
