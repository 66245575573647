import React, { useState } from "react";

import HeaderBar from "../../components/HeaderBar";
import { Container } from "@mui/material";
import { useEffect } from "react";

export function HeaderConteinerComSideBar({
  titulo,
  categoria,
  width,
  close = false,
  children,
}) {
  const [openWidth, setOpenWidth] = useState(false);

  useEffect(() => {
    if (close) setOpenWidth(true);
  }, [close]);
  return (
    <div style={{ display: "flex" }}>
      <HeaderBar
        title={titulo}
        categoria={categoria}
        open={openWidth}
        setOpen={setOpenWidth}
      />
      <Container
        maxWidth={width}
        sx={{
          flexGrow: 1,
          py: 10,
          width: { sm: `calc(100% - 218px)`, md: `calc(100% - 218px)` },
        }}
      >
        {children}
      </Container>
    </div>
  );
}
