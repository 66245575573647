import React from "react";
import { Grid } from '@mui/material';
import { Select } from "../../../../componentsv2/Select";

export default function SelectSetor({
  onChange,
  value,
  disabled = false,
  required = false,
  todos = true,
}) {
  const options = [ "Whatsapp", "E-mail", "Telefone", "Presencial" ];
  todos && options.unshift("Todos");
  return (
    <>
      <Grid container spacing={1} justifyContent="center" alignItems="stretch">
        <Grid item xs={12}>
          <Select
            required={required}
            disabled={disabled}
            onChange={onChange}
            value={value}
            label="Canal de contato"
            variant="standard"
            arrayOptions={options}
          />
        </Grid>
      </Grid>
    </>
  );
}
