import React, { memo, useContext, useEffect, useState } from "react";
import { Autocomplete, Input, TextField } from "@mui/material";

import { RetornoLoteContext } from "../../Context/RetornoLoteContext";
import { RecebimentoContext } from "../../Context/RecebimentoContext";

function InputMotivo({ row }) {
  const { setGlosas } = useContext(RetornoLoteContext);
  const { motivos } = useContext(RecebimentoContext);

  const { id, motivo } = row;
  const [motivoAtual, setMotivoAtual] = useState({
    id,
    descricao: motivo,
    codigo: 0,
    codigoDescricao: motivo ? motivo : "",
  });
  const [motivoInvalido, setMotivoInvalido] = useState(false);

  useEffect(() => {
    if (motivoAtual) {
      const v = Number(motivoAtual);

      const valido = motivos.includes(v);

      setMotivoInvalido(!valido);
    }
    // eslint-disable-next-line
  }, [motivoAtual]);

  function alteraMotivo(valor) {
    setGlosas((prevState) => {
      const novoMotivo = prevState;
      const resultado = novoMotivo.map((item) => {
        if (item.id === id) {
          return { ...item, motivo: valor };
        }
        return { ...item };
      });

      return resultado;
    });
  }

  return (
    <>
      <Autocomplete
        fullWidth
        options={motivos}
        getOptionLabel={(option) => option.codigoDescricao}
        onChange={(event, value) => setMotivoAtual(value)}
        value={motivoAtual}
        onBlur={(event) => {
          alteraMotivo(event.target.value);
        }}
        renderInput={(params) => (
          <TextField fullWidth {...params} variant="standard" />
        )}
      />
      {/* <Input
        id="motivo"
        value={motivoAtual === 0 ? "" : motivoAtual}
        onChange={(event) => setMotivoAtual(event.target.value)}
        onBlur={(event) => alteraMotivo(event.target.value)}
        // error={motivoInvalido}
      /> */}
    </>
  );
}

export default memo(InputMotivo);
