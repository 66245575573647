import React, { useState } from "react";
import clsx from "clsx";

import Checkbox from "../Checkbox";
import "./styles.css";

export default function PermissaoAcesso({
  nome,
  inserirPreMudanca,
  removerPreMudanca,
  codigo,
  interactive,
}) {
  const [checked, setChecked] = useState(false);
  return (
    <div
      className={clsx("card-acao", { selected: checked })}
      onClick={() => {
        if (interactive) {
          if (checked) {
            removerPreMudanca();
          } else {
            inserirPreMudanca();
          }
          setChecked(!checked);
        }
      }}
    >
      {interactive && <Checkbox checked={checked} />}
      <p>{nome}</p>
      <span className="card-codigo">{codigo}</span>
    </div>
  );
}
