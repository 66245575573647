import React, { useEffect, useState, useContext } from "react";
import { Grid, Paper, IconButton, Box } from "@mui/material";
import Tabela from "../../../../componentsv2/Tabela";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useHistory } from "react-router-dom";
import DataPickerAtualizado from "../../../../components/DataPickerAtualizado";
import { Select } from "../../../../componentsv2/Select";
import { Input } from "../../../../componentsv2/Input";
import OpcoesInventario from "../../opcoesInventario";
import filtroPesquisa from "../../../../utils/filtroPesquisa";
import { api } from "../../../../services/Api";
import { useSnackbar } from "notistack";
import { Context as PermissoesContext } from "../../../../Context/PermissoesContext";

export default function Inventarios() {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [arrayDeInventarios, setArrayDeInventarios] = useState([]);
  const [dataInicio, setDataInicio] = useState(null);
  const [dataFim, setDataFim] = useState(null);
  const [pesquisa, setPesquisa] = useState("");
  const [situacao, setSituacao] = useState("");
  const [update, setUpdate] = useState(false);
  const { checarPermissao } = useContext(PermissoesContext);

  const listaDeSituacoes = [
    { value: "", text: "Todos" },
    { value: "Aberto", text: "Aberto" },
    { value: "Concluído", text: "Concluído" },
  ];

  const inventariosFiltrados = filtroPesquisa(
    arrayDeInventarios,
    "nome",
    pesquisa
  );

  async function getInventarios() {
    try {
      const {
        data: { data },
      } = await api.get(`/v2/inventario/inventarios`, {
        params: { situacao, dataInicio, dataFim },
      });
      setArrayDeInventarios(data);
    } catch (error) {
      enqueueSnackbar(
        "Erro! Não foi possível buscar os inventários cadastrados.",
        {
          variant: "error",
          autoHideDuration: 3000,
        }
      );
    }
  }

  useEffect(() => {
    getInventarios();
    // eslint-disable-next-line
  }, [update, situacao, dataInicio, dataFim]);

  const columns = [
    {
      field: "nome",
      headerName: "Nome",
      width: 300,
      type: "text",
    },
    {
      field: "situacao",
      headerName: "Situação",
      width: 300,
      type: "text",
    },
    // {
    //   field: "dataInicio",
    //   headerName: "Data Abertura",
    //   width: 230,
    //   type: "dateTime",
    //   valueGetter: ({ value }) =>
    //     value &&
    //     new Date(value).toLocaleString({
    //       dateStyle: "full",
    //       timeStyle: "full",
    //     }),
    // },
    {
      field: "dataFim",
      headerName: "Data Fim",
      width: 300,
      type: "dateTime",
      valueGetter: ({ value }) => value && new Date(value),
    },

    {
      field: "opcoes",
      headerName: "Opções",

      width: 130,

      renderCell: (params) => (
        <div style={{ cursor: "pointer" }}>
          <OpcoesInventario
            setUpdate={setUpdate}
            update={update}
            row={params.row}
          />
        </div>
      ),
    },
  ];

  return (
    <Grid container alignItems="center" spacing={1} direction="column">
      <Grid container xs={12} item>
        <Paper sx={{ width: "100%", marginBottom: "30px", padding: "10px" }}>
          <Grid
            item
            container
            sx={{ marginLeft: "5px" }}
            justifyContent="space-between"
          >
            <Grid xs={8} lg={10} spacing={3} item container>
              <Grid item md={3} sm={6} lg={3} xs={6}>
                <Input
                  variant="standard"
                  fullWidth
                  value={pesquisa}
                  onChange={(e) => setPesquisa(e.target.value)}
                  label="Pesquise pelo nome"
                  type="text"
                />
              </Grid>
              <Grid item md={3} sm={6} lg={3} xs={6}>
                <DataPickerAtualizado
                  data={dataInicio}
                  setData={setDataInicio}
                  labelName="Data Inicial"
                  variant="standard"
                />
              </Grid>
              <Grid item md={3} sm={6} lg={3} xs={6}>
                <DataPickerAtualizado
                  data={dataFim}
                  setData={setDataFim}
                  labelName="Data Final"
                  variant="standard"
                />
              </Grid>
              <Grid item md={3} sm={12} lg={3} xs={12}>
                <Select
                  variant="standard"
                  value={situacao}
                  textAttribute="text"
                  valueAttribute="value"
                  onChange={(e) => setSituacao(e.target.value)}
                  arrayOptions={listaDeSituacoes}
                  label="Situação"
                />
              </Grid>
            </Grid>
            <Grid xs={2} sm={2} md={1} lg={1} item>
              <IconButton
                onClick={() => {
                  if (checarPermissao("INV003")) {
                    history.push("/inventario/criaInventario");
                  } else {
                    enqueueSnackbar(
                      "Você não possui permissão para cadastrar um inventário.",
                      {
                        variant: "error",
                        autoHideDuration: 3000,
                      }
                    );
                  }
                }}
                color="success"
                size="large"
              >
                <AddCircleOutlineIcon fontSize="large" />
              </IconButton>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={12} container>
        <Grid style={{ paddingTop: "10px" }} xs={12} sm={12} item>
          <Grid spacing={2} container>
            <Grid style={{ paddingTop: "10px" }} item xs={12} sm={12}>
              <Paper elevation={2}>
                <Box
                  sx={{
                    height: 1,
                    width: "100%",
                    "& .vencido": { color: "#99250F" },
                    marginBottom: "10px",
                  }}
                >
                  <div>
                    <Tabela
                      linhaNaoSelecionavel={false}
                      rows={inventariosFiltrados}
                      columns={columns}
                      autoHeight
                    />
                  </div>
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
