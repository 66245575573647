export default function validarCPF(valor) {
  let cpfValido = false;
  let soma = 0;
  let resto = 0;

  let cpf;
  if (valor.replaceAll) {
    cpf = valor.replaceAll("-", "").replaceAll(".", "").trim();
  } else {
    cpf = valor.split("-").join("").split(".").join("").trim();
  }

  const noveDigitos = cpf.substring(0, 9);
  const dezDigitos = cpf.substring(0, 10);
  const digitosVerificadores = cpf.substring(9);

  for (let i = 0; i < 9; i += 1) {
    soma += (11 - (i + 1)) * parseInt(noveDigitos[i], 10);
  }

  resto = (soma * 10) % 11;
  if (resto === 10 || resto === 11) {
    resto = 0;
  }

  if (resto !== parseInt(digitosVerificadores[0], 10)) {
    return false;
  }

  soma = 0;
  for (let i = 0; i < 10; i += 1) {
    soma += (12 - (i + 1)) * parseInt(dezDigitos[i], 10);
  }

  resto = (soma * 10) % 11;
  if (resto === 10 || resto === 11) {
    resto = 0;
  }

  if (resto !== parseInt(digitosVerificadores[1], 10)) {
    return false;
  }

  cpfValido = true;

  return cpf.match(/^\d{11}$/) && cpfValido;
}
