import React from "react";
import "./styles.css";
import { CircularProgress } from '@mui/material';

export default function Loading() {
  return (
    <div className="loading-wrapper">
      <CircularProgress style={{ width: "80px", height: "80px" }} />
    </div>
  );
}
