import React, { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import {
  Container,
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
} from "@mui/material";
import { Bar } from "react-chartjs-2";
import { format } from "date-fns";
import moment from "moment";

import "./styles.css";

import { api } from "../../../services/Api";
import dayjs from "dayjs";
import DataPickerAtualizado from "../../../components/DataPickerAtualizado";

export default function MapasBI() {
  const { enqueueSnackbar } = useSnackbar();

  const [novaData, setNovaData] = useState(
    new Date().setDate(new Date().getDate() - 5)
  );
  const [dataFinal, setDataFinal] = useState(new Date());

  const [todoUnidades, setTodoUnidades] = useState([]);
  const [filtroUnidade, setFiltroUnidade] = useState("Todas");

  const [qtdExamesTotal, setQtdExamesTotal] = useState(0);
  const [vetorDatas, setVetorDatas] = useState([]);
  const [vetorQtd, setVetorQtd] = useState([]);
  const [vetorExame, setVetorExame] = useState([]);
  const [vetorCodigo, setVetorCodigo] = useState([]);
  const [vetorQuantidade, setVetorQuantidade] = useState([]);
  const [vetorUnidades, setVetorUnidades] = useState([]);
  const [vetorQtUnidades, setVetorQtUnidades] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const {
          data: { unidade },
        } = await api.get(`/v2/mapaCurvas/bi/unidade`);

        const todas = [];
        const idTodas = [];

        for (const todes of unidade) {
          todas.push(todes.nome);
          idTodas.push(todes);
        }
        setTodoUnidades(todas.sort());

        const vetor1 = [];
        const vetor2 = [];

        const {
          data: { data },
        } = await api.get(
          `/v2/mapaCurvas/bi/periodo/${format(
            new Date(novaData),
            "yyyy-MM-dd"
          )}/${format(new Date(dataFinal), "yyyy-MM-dd")}`
        );

        const {
          data: { porData },
        } = await api.get(
          `/v2/mapaCurvas/bi/porData/${format(
            new Date(novaData),
            "yyyy-MM-dd"
          )}/${format(new Date(dataFinal), "yyyy-MM-dd")}`
        );

        let ordenado = [];
        let ordenado2 = [];

        function compare(a, b) {
          if (a.qtd < b.qtd) {
            return -1;
          }
          if (a.qtd > b.qtd) {
            return 1;
          }
          return 0;
        }

        if (filtroUnidade !== "Todas") {
          ordenado = data.sort(compare);

          for (let i = 0; i < idTodas.length; i++) {
            for (let j = 0; j < ordenado.length; j++) {
              if (
                String(filtroUnidade) === idTodas[i].nome &&
                idTodas[i].id === String(ordenado[j].id_unidade)
              ) {
                ordenado2.push(ordenado[j]);
              }
            }
          }
        } else {
          ordenado2 = porData.sort(compare);
        }

        for (const dia of ordenado2) {
          vetor1.push(dia.count);
          vetor2.push(moment(dia.qtd).format("ll"));
        }

        setVetorDatas(vetor2);
        setVetorQtd(vetor1);

        const {
          data: { soma },
        } = await api.get(
          `/v2/mapaCurvas/bi/qtd/${format(
            new Date(novaData),
            "yyyy-MM-dd"
          )}/${format(new Date(dataFinal), "yyyy-MM-dd")}`
        );

        const {
          data: { somaTabela },
        } = await api.get(
          `/v2/mapaCurvas/bi/tabela/${format(
            new Date(novaData),
            "yyyy-MM-dd"
          )}/${format(new Date(dataFinal), "yyyy-MM-dd")}`
        );

        const {
          data: { detalhe },
        } = await api.get(`/v2/mapaCurvas/bi/detalhe`);

        // console.log(detalhe);

        const vetorCod = [];
        const vetorExa = [];
        const vetorQt = [];
        let somaFiltrado = [];

        if (filtroUnidade !== "Todas") {
          for (let i = 0; i < idTodas.length; i++) {
            for (let j = 0; j < soma.length; j++) {
              if (
                String(filtroUnidade) === idTodas[i].nome &&
                idTodas[i].id === String(soma[j].id_unidade)
              ) {
                somaFiltrado.push(soma[j]);
              }
            }
          }
        } else {
          somaFiltrado = somaTabela;
        }

        for (const qtd of somaFiltrado) {
          for (const exame of detalhe) {
            if (exame.id === qtd.id_exame) {
              vetorCod.push(exame.codigo);
              vetorExa.push(exame.nome);
              vetorQt.push(qtd.count);
            }
          }
        }

        setVetorCodigo(vetorCod);
        setVetorExame(vetorExa);
        setVetorQuantidade(vetorQt);

        const {
          data: { somaUnidade },
        } = await api.get(
          `/v2/mapaCurvas/bi/totalUnidade/${format(
            new Date(novaData),
            "yyyy-MM-dd"
          )}/${format(new Date(dataFinal), "yyyy-MM-dd")}`
        );

        const vetorNome = [];
        const vetorSomas = [];

        for (const und of unidade) {
          for (const somatorio of somaUnidade) {
            if (und.id === somatorio.id_unidade) {
              vetorNome.push(und.nome);
              vetorSomas.push(somatorio.count);
            }
          }
        }

        setVetorUnidades(vetorNome);
        setVetorQtUnidades(vetorSomas);

        const unids = vetorNome;
        const unids2 = vetorSomas;
        const unids3 = [];

        for (let i = 0; i < unids.length; i += 1) {
          unids3[i] = {
            unidade: unids[i],
            quantidade: unids2[i],
          };
        }

        const {
          data: { quantidade },
        } = await api.get(
          `/v2/mapaCurvas/bi/${format(
            new Date(novaData),
            "yyyy-MM-dd"
          )}/${format(new Date(dataFinal), "yyyy-MM-dd")}`
        );

        if (filtroUnidade !== "Todas") {
          for (let i = 0; i < unids3.length; i++) {
            if (String(filtroUnidade) === unids3[i].unidade) {
              setQtdExamesTotal(unids3[i].quantidade);
            }
          }
        } else {
          setQtdExamesTotal(quantidade[0].count);
        }
      } catch (e) {
        enqueueSnackbar(
          "Não foi possível encontrar pedidos desta data. Por favor, tente novamente.",
          { variant: "error" }
        );
      }
    })();
  }, [enqueueSnackbar, novaData, dataFinal, filtroUnidade]);

  const datas = {
    labels: vetorDatas,
    datasets: [
      {
        label: "Exames",
        data: vetorQtd,
        backgroundColor: "#003399",
      },
    ],
  };

  const tableExame = [];

  for (let i = 0; i < vetorExame.length; i += 1) {
    tableExame[i] = {
      exame: vetorExame[i],
      codigo: vetorCodigo[i],
      quantidade: vetorQuantidade[i],
      id: i,
    };
  }

  let tableFinal = [];
  const tableUnidade = [];

  for (let i = 0; i < vetorUnidades.length; i += 1) {
    tableUnidade[i] = {
      unidade: vetorUnidades[i],
      quantidade: vetorQtUnidades[i],
    };
  }

  let tableUnidadeFinal = [];

  function compare(a, b) {
    if (parseInt(a.quantidade, 10) < parseInt(b.quantidade, 10)) {
      return 1;
    }
    if (parseInt(a.quantidade, 10) > parseInt(b.quantidade, 10)) {
      return -1;
    }
    return 0;
  }

  tableFinal = tableExame.sort(compare);
  tableUnidadeFinal = tableUnidade.sort(compare);

  const handleChange = (event) => {
    event.preventDefault();
    setFiltroUnidade(event.target.value);
  };

  return (
    <Container maxWidth="md">
      <div className="container">
        <div className="grid-container-Dom">
          <div className="grid-item" md="4">
            <FormControl style={{ minWidth: 150 }}>
              <InputLabel id="demo-simple-select-label">Unidade</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={filtroUnidade}
                label="unidade"
                onChange={handleChange}
              >
                <MenuItem value="Todas">Todas</MenuItem>
                {todoUnidades.map((row) => (
                  <MenuItem value={row} key={row}>
                    {row}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className="grid-item" md="4">
            <DataPickerAtualizado
              data={dayjs(novaData)}
              setData={setNovaData}
              variant="standard"
              labelName="Selecionar Data Inicial"
            />
            {/* <MyDatePicker
              style={{ marginTop: 0 }}
              disableToolbar
              variant="inline"
              format="dd/MM/yyyy"
              margin="normal"
              id="date-picker-inline"
              label="Selecionar Data Inicial"
              value={dayjs(novaData)}
              onChange={setNovaData}
              KeyboardButtonProps={{
                "aria-label": "Mudar",
              }}
            /> */}
          </div>
          <div className="grid-item" md="4">
            <DataPickerAtualizado
              data={dayjs(dataFinal)}
              setData={setDataFinal}
              variant="standard"
              labelName="Selecionar Data Inicial"
            />
            {/* <MyDatePicker
              style={{ marginTop: 0 }}
              disableToolbar
              variant="inline"
              format="dd/MM/yyyy"
              margin="normal"
              id="date-picker-inline"
              label="Selecionar Data Final"
              value={dayjs(dataFinal)}
              onChange={setDataFinal}
              KeyboardButtonProps={{
                "aria-label": "Mudar",
              }}
            /> */}
            {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                style={{ marginTop: 0 }}
                disableToolbar
                variant="inline"
                format="dd/MM/yyyy"
                margin="normal"
                id="date-picker-inline"
                label="Selecionar Data Final"
                value={dayjs(dataFinal)}
                onChange={(event, nexValue) => {
                  console.log(nexValue);
                  const [dia, mes, ano] = nexValue.split("/");
                  const dataAtual = new Date(
                    Number(ano),
                    Number(mes) - 1,
                    Number(dia) + 1
                  );
                  setDataFinal(format(new Date(dataAtual), "yyyy-MM-dd"));
                }}
                KeyboardButtonProps={{
                  "aria-label": "Mudar",
                }}
              />
            </LocalizationProvider> */}
          </div>
        </div>
        <div>
          <div
            className="MuiGrid-root MuiGrid-container MuiGrid-spacing-xs-2"
            style={{ width: "50%" }}
          >
            <div className="MuiPaper-root MuiCard-root MuiPaper-elevation1 MuiPaper-rounded">
              <div className="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-6 MuiGrid-grid-sm-6">
                <div className="MuiPaper-root MuiCard-root MuiPaper-elevation1 MuiPaper-rounded">
                  <Typography
                    variant="h5"
                    component="h1"
                    style={{ textAlign: "center" }}
                  >
                    {qtdExamesTotal}
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    component="h1"
                    style={{ textAlign: "center" }}
                  >
                    Quantidade de Exames Total
                  </Typography>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="BarAmostras" component={Paper}>
          <Paper className="PaperAmostras">
            <Bar
              className="Grafico"
              data={datas}
              options={{
                showAllTooltips: true,
                plugins: {
                  datalabels: {
                    display: "auto",
                    anchor: "end",
                    align: "end",
                    fontSize: 20,
                  },
                },
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                  yAxes: [
                    {
                      ticks: {
                        beginAtZero: true,
                        min: 0,
                        stepSize: 1,
                      },
                    },
                  ],
                },

                title: {
                  padding: 20,
                  display: true,
                  text: "Quantidade de Atendimentos Por Data",
                  fontSize: 15,
                },
                legend: {
                  display: false,
                  position: "bottom",
                },
              }}
            />
          </Paper>
        </div>
        <div className="container-table">
          <div className="tableBI">
            <TableContainer component={Paper}>
              <Table
                sx={{ minWidth: 650 }}
                size="small"
                aria-label="a dense table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell>Exame</TableCell>
                    <TableCell>Codigo</TableCell>
                    <TableCell>Quantidade</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tableFinal.map((row) => (
                    <TableRow
                      key={row.id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell>{row.exame}</TableCell>
                      <TableCell>{row.codigo}</TableCell>
                      <TableCell>{row.quantidade}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
        <div className="container-table-bottom">
          <div className="tableBI">
            <TableContainer component={Paper}>
              <Table
                sx={{ minWidth: 650 }}
                size="small"
                aria-label="a dense table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell>Unidade</TableCell>
                    <TableCell>Quantidade</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tableUnidadeFinal.map((row) => (
                    <TableRow
                      key={row.unidade}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell>{row.unidade}</TableCell>
                      <TableCell>{row.quantidade}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </div>
    </Container>
  );
}

// {tableFinal.map(row => (
//  <TableRow
//    key={row.exame}
//    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
//  >
//    <TableCell>{row.exame}</TableCell>
//    <TableCell>{row.codigo}</TableCell>
//    <TableCell>{row.quantidade}</TableCell>
// </TableRow>
// ))}
