import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Button, Container, Grid, Paper } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Header from "../../components/Header";
import useUser from "../../hooks/useUser";
import { api } from "../../services/Api";
import AdicionarProduto from "./AdicionarProduto";
import BarraDePesquisa from "./BarraDePesquisa";
import DadosContext from "./DadosContext";
import "./styles.css";
import TabelaProdutos from "./TabelaProdutos";

export default function HomeCadastro() {
  const history = useHistory();
  const usuario = useUser();
  const { enqueueSnackbar } = useSnackbar();

  const [pesquisado, setPesquisado] = useState("");
  const [categoriasObtidas, setCategoriasObtidas] = useState([]);
  const [update, setUpdate] = useState(false);

  useEffect(() => {
    async function carregaCategorias() {
      try {
        const {
          data: { data },
        } = await api.get("/v2/almoxarifado/categorias");
        setCategoriasObtidas(data);
      } catch (e) {
        enqueueSnackbar("Falha ao carregar as categorias", { variant: "error" });
      }
    }

    carregaCategorias();
  }, [enqueueSnackbar, update]);

  return (
    <DadosContext.Provider
      value={{
        pesquisado,
        setPesquisado,
        categoriasObtidas,
        setCategoriasObtidas,
        update,
        setUpdate,
      }}
    >
      <Header titulo="Cadastro de Categorias" usuario={usuario} history={history} />
      <Container maxWidth="lg" style={{ padding: "0" }}>
        <Grid container>
          <Button
            onClick={() => history.push("/almoxarifado")}
            style={{ color: "#003399" }}
          >
            <ArrowBackIcon />
          </Button>
          <Grid className="paper-categoria" item xs={12} sm={12}>
            <Paper id="header-categoria" elevation={2}>
              <Grid className="paper-categoria" item xs={12} sm={9}>
                <BarraDePesquisa />
              </Grid>
              <Grid className="paper-categoria" item xs={12} sm={3}>
                <AdicionarProduto />
              </Grid>
            </Paper>
          </Grid>
          <Grid className="paper-categoria" item xs={12} sm={12}>
            <TabelaProdutos />
          </Grid>
        </Grid>
      </Container>
    </DadosContext.Provider>
  );
}
