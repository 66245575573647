import React from 'react';
import { KeyboardBackspace } from '@mui/icons-material';

import "./styles.css";

export default function ContainerTitleDrive({ acao}){

  return (
    <div id="container-title">
      <button
        type="button"
        id="button-dialog-back"
        onClick={() => acao()}
      >
        <KeyboardBackspace id="icon-back" fontSize="large" />
      </button>

      <label id="content-label-title">
        ATENDIMENTO DRIVE
      </label>
    </div>
  )
}

