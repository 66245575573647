import React, { useEffect } from "react";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";
import { Container } from "@mui/material";

import Tabela from "./Tabela";
import Agendamento from "./Agendamento";

import useUser from "../../hooks/useUser";

import { FormularioContextProvider } from "./Context/FormularioContext";

import { api } from "../../services/Api";

import "./styles.css";

export default function AgendaCalendario(props) {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const usuario = useUser();

  const { agenda, unidade, exames, horarios, diasSemana, acesso } = props;

  useEffect(() => {
    async function inicio() {
      try {
        const {
          data: {
            permitido: {
              0: { id },
            },
          },
        } = await api.get(
          `/v2/controleAcesso/permissaoVisualizaModulo?username=${usuario}&&codigo=${acesso}001`
        );

        return id;
      } catch (e) {
        enqueueSnackbar("Você não possui permissão de acesso a esta agenda.", {
          variant: "error",
        });
        return history.push("/home");
      }
    }
    inicio();
  }, [enqueueSnackbar, history, usuario, acesso]);

  return (
    <Container maxWidth="xl">
      <Tabela
        agendaAtual={agenda}
        diasSemana={diasSemana}
        horarios={horarios}
        acesso={acesso}
      />
      <Agendamento
        agenda={agenda}
        unidade={unidade}
        exames={exames}
        horarios={horarios}
        acesso={acesso}
      />
    </Container>
  );
}
