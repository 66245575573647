import React from "react";

import Header from "../../components/Header";
import todasFerramentas from "./DadosFerramentas";
import Search from "./Aplicacoes/BarraDePesquisa/Search";

import "./styles.css";

export default function MensagensApp() {

  return (
    <>
      <Header titulo="Intranet" />
      <Search details={todasFerramentas} />
    </>
  );
}
