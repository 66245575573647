import { useState, useEffect, useMemo } from "react";
import { useSnackbar } from "notistack";

export default function useDates(inicio, fim) {
  const { enqueueSnackbar } = useSnackbar();
  const inicioSemana = useMemo(() => {
    const d = new Date();
    let date = d.getDate();
    while (d.getDay() !== 0) {
      d.setDate(date - 1);
      date -= 1;
    }

    return d;
  }, []);

  const fimSemana = useMemo(() => {
    const d = new Date();
    let date = d.getDate();
    while (d.getDay() !== 6) {
      d.setDate(date + 1);
      date += 1;
    }

    return d;
  }, []);
  const [dataInicio, setDataInicio] = useState(inicio || inicioSemana);
  const [dataFim, setDataFim] = useState(fim || fimSemana);

  useEffect(() => {
    if (dataFim < dataInicio) {
      enqueueSnackbar("A data de início não pode ocorrer após a data final.", {
        variant: "error",
        autoHideDuration: 3000,
      });
      setDataInicio(inicioSemana);
      setDataFim(fimSemana);
    }
  }, [dataInicio, dataFim, enqueueSnackbar, inicioSemana, fimSemana]);

  return { dataInicio, setDataInicio, dataFim, setDataFim };
}
