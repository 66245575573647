import React, { useContext, useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import {
  Autocomplete,
  Button,
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  Paper,
  TextField,
} from "@mui/material";

import { Cached, Search } from "@mui/icons-material";
import { IntervaloDataContext } from "../../Context/IntevervaloDataContext";
import { RecebimentoContext } from "../../Recebimento/Context/RecebimentoContext";
import Waiter from "../../../../components/Backdrop";

import MascaraDeCompetencia from "../MascaraDeCompetencia";
import debounce from "../../../../utils/debounce";

import GridContainer from "../../../../componentsv2/GridContainer";
import GridItem from "../../../../componentsv2/GridItem";
import { Input as CustomInput } from "../../../../componentsv2/Input";

import { api } from "../../../../services/Api";

import "./styles.css";
import DataPickerAtualizado from "../../../../components/DataPickerAtualizado";
import formatDateBD from "../../../../utils/formatarDataBD";
import AutocompleteMultiple from "../../../../componentsv2/AutocompleteMultiple";

export default function Formulario(props) {
  const { enqueueSnackbar } = useSnackbar();

  const {
    dataInicio,
    setDataInicio,
    dataFim,
    setDataFim,
    setCompetencia,
    notaFiscal,
    setNotaFiscal,
    competencia,
  } = useContext(IntervaloDataContext);

  const {
    aba,
    resultado,
    setDadosTotalizadores,
    setDadosTabela,
    atualizaFiltro,
    setAtualizaTabela,
    protocolo,
    setProtocolo,
    plano,
    setPlano,
    lote,
    setLote,
    listaConvenios,
    setAtualizaGeradoSoftlab,
    atualizaGeradoSoftlab,
    responsavelPlano,
    setResponsavelPlano,
    listaResponsaveisFiltrados,
  } = useContext(RecebimentoContext);

  const [aguarda, setAguarda] = useState(false);

  const {
    tabelaFaturamento,
    setDadosTabelaFaturamento,
    setAtualizaTabelaFaturamento,
    faturamento,
    recebimento,
    setOpenModalInformarFaturamento,
  } = props;

  // Filtros do formulário principal, com exceção da aba BI
  useEffect(() => {
    const dados = faturamento ? tabelaFaturamento : resultado;
    const nomesPlanos = plano.map((element) => element.convenio);
    const nomesResponsaveis = responsavelPlano?.map((el) => el.grupo);

    if (dados) {
      let filtro = dados.filter(
        (p) =>
          (p.protocolo ? p.protocolo.includes(protocolo) : true) &&
          (protocolo ? p.protocolo : true) &&
          (!nomesPlanos.includes("Todos")
            ? nomesPlanos.includes(p.plano)
            : true) &&
          p.lote.includes(lote) &&
          (!nomesResponsaveis.includes("Todos")
            ? nomesResponsaveis.includes(p.grupo)
            : true)
      );
      if (recebimento) {
        setDadosTotalizadores(filtro);

        // Filtro referente aos somatórios da aba de recebimento
        if (aba !== "Enviado") {
          filtro = filtro.filter(
            (p) =>
              (aba === "Recebido" || aba === "Líquido"
                ? p.valor_recuperado
                : true) &&
              (aba === "Glosado" ? p.valor_glosado : true) &&
              (aba === "Dif. Tabela" ? p.valor_diferenca_tabela : true) &&
              // (aba === "Por Recurso" ? p.valor_recurso : true) &&
              (aba === "Em recurso" ? p.em_analise : true)
          );
        }

        setDadosTabela(filtro);
      } else {
        setDadosTabelaFaturamento(filtro);
      }
    }
  }, [
    plano,
    protocolo,
    lote,
    setDadosTotalizadores,
    setDadosTabela,
    setDadosTabelaFaturamento,
    tabelaFaturamento,
    resultado,
    aba,
    faturamento,
    recebimento,
    atualizaFiltro,
    responsavelPlano,
  ]);

  async function sincronizaTabelasFaturamento() {
    setAguarda(true);

    const data_inicial = `${formatDateBD(dataInicio)} 00:00:00`;
    const data_final = `${formatDateBD(dataFim)} 23:59:59`;

    try {
      await api.post(
        `/ativa/faturamento/sincronizaTabelaLote/?dataInicio=${data_inicial}&dataFim=${data_final}&lote=${lote}`
      );

      setAtualizaTabelaFaturamento((prevState) => !prevState);
      enqueueSnackbar("Tabela de faturamento atualizada com sucesso.", {
        variant: "success",
      });
    } catch (e) {
      enqueueSnackbar(
        "Não foi possível atualizar a tabela de faturamento. Por favor, tente novamente.",
        { variant: "error" }
      );
    }
    setAguarda(false);
  }

  return (
    <>
      <Paper id="container-formulario-lotes-faturamento">
        <GridContainer
          spacing={1}
          justifyContent="space-evenly"
          alignItems="center"
        >
          <GridItem xs={6}>
            <GridContainer
              direction="row"
              justifyContent="space-evenly"
              alignItems="center"
              spacing={1}
              sx={{
                border: "0.1rem solid #CDCDCD",
                borderRadius: "0.2rem",
                padding: "1rem",
              }}
            >
              <GridItem xs={3}>
                <DataPickerAtualizado
                  data={dataInicio}
                  labelName="Data inicial"
                  setData={setDataInicio}
                />
              </GridItem>
              <GridItem xs={3}>
                <DataPickerAtualizado
                  data={dataFim}
                  labelName="Data final"
                  setData={setDataFim}
                />
              </GridItem>
              <GridItem xs={2}>
                <TextField
                  id="competencia"
                  label="Competência"
                  placeholder="mm/aaaa"
                  value={competencia}
                  style={{ marginRight: "1rem", width: "100%" }}
                  onChange={(event) => {
                    const comp = event.target.value;
                    if (comp.length === 7) {
                      setCompetencia(event.target.value);
                    } else if (comp.length === 0) {
                      setCompetencia("");
                    }
                  }}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    inputComponent: MascaraDeCompetencia,
                  }}
                />
              </GridItem>
              <GridItem xs={3}>
                <TextField
                  placeholder="Nota Fiscal"
                  value={notaFiscal}
                  onChange={(e) => setNotaFiscal(e.target.value)}
                />
              </GridItem>
              <GridItem xs={1}>
                <IconButton
                  style={{
                    margin: "auto",
                    height: "3rem",
                    background: "#003399",
                  }}
                  onClick={() => {
                    if (recebimento) {
                      setAtualizaTabela((prevState) => !prevState);
                    } else {
                      setAtualizaTabelaFaturamento((prevState) => !prevState);
                      setAtualizaGeradoSoftlab((prevState) => !prevState);
                    }
                  }}
                >
                  <Search style={{ color: "white" }} />
                </IconButton>
              </GridItem>
            </GridContainer>
          </GridItem>
          <GridItem xs={6}>
            {/* segundo Formulario */}
            <GridContainer
              justifyContent="space-evenly"
              alignItems="center"
              spacing={1}
            >
              <GridItem xs={2}>
                <FormControl fullWidth>
                  <InputLabel id="protocolo-selecionado-label">
                    Protocolo
                  </InputLabel>
                  <Input
                    endAdornment={
                      <InputAdornment position="end">
                        <Search style={{ color: "rgba(0,0,0,0.6)" }} />
                      </InputAdornment>
                    }
                    placeholder="Protocolo"
                    value={protocolo}
                    onChange={(e) =>
                      debounce(setProtocolo(e.target.value.toUpperCase()), 500)
                    }
                    id="search-prot"
                  />
                </FormControl>
              </GridItem>
              <GridItem xs={3}>
                <AutocompleteMultiple
                  opcoes={listaResponsaveisFiltrados}
                  setValue={setResponsavelPlano}
                  value={responsavelPlano}
                  optionLabel="grupo"
                  label="Grupos"
                />
              </GridItem>
              <GridItem xs={3}>
                <FormControl fullWidth>
                  <AutocompleteMultiple
                    opcoes={listaConvenios}
                    setValue={setPlano}
                    value={plano}
                    optionLabel="convenio"
                    label="Planos de saúde"
                  />
                </FormControl>
              </GridItem>
              <GridItem xs={2}>
                <FormControl fullWidth>
                  <InputLabel id="lote-selecionado-label">Lote</InputLabel>
                  <Input
                    endAdornment={
                      <InputAdornment position="end">
                        <Search style={{ color: "rgba(0,0,0,0.6)" }} />
                      </InputAdornment>
                    }
                    placeholder="Lote"
                    value={lote}
                    onChange={(e) =>
                      debounce(setLote(e.target.value.toUpperCase()), 500)
                    }
                    id="search-lote"
                  />
                </FormControl>
              </GridItem>
              {faturamento ? (
                <GridItem xs={2}>
                  <Button
                    fullWidth
                    variant="outlined"
                    startIcon={<Cached />}
                    onClick={sincronizaTabelasFaturamento}
                  >
                    Atualizar Tabelas
                  </Button>
                </GridItem>
              ) : (
                <GridItem xs={2}>
                  <Button
                    variant="contained"
                    onClick={() => setOpenModalInformarFaturamento(true)}
                  >
                    Informar Recebimento
                  </Button>
                </GridItem>
              )}
            </GridContainer>
          </GridItem>
        </GridContainer>
      </Paper>
      <Waiter espera={aguarda} />
    </>
  );
}
