import React, { useContext, useState } from "react";
import { useSnackbar } from "notistack";
import SendIcon from "@mui/icons-material/Send";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Box,
  Container,
  Grid,
  IconButton,
  Modal,
  Paper,
  Stack,
  TextField,
} from "@mui/material";

import { colorTheme } from "../../../../theme";
import Tabela from "../../../../componentsv2/Tabela";
import Button from "../../../../componentsv2/Button";
import useUser from "../../../../hooks/useUser";

import { api } from "../../../../services/Api";
import useDadosContextEstoque from "../../hooks/useDadosContextEstoque";
import { Input } from "../../../../componentsv2/Input";

export default function ModalBaixa({ open, setOpen, local }) {
  const { enqueueSnackbar } = useSnackbar();
  const usuario = useUser();

  const { update, setUpdate } = useDadosContextEstoque();

  const [codigoInserido, setCodigoInserido] = useState(null);
  const [produtos, setProdutos] = useState([]);
  const [botaoDesativado, setBotaoDesativado] = useState(true);

  const columns = [
    {
      field: "id",
      headername: "id",
      headerAlign: "center",
      hide: true,
      align: "center",
      justifyContent: "center",
    },
    {
      field: "nome",
      headerName: "Nome",
      width: 340,
      headerAlign: "center",
      type: "string",
      align: "center",
      justifyContent: "center",
    },
    {
      field: "codigoBarras",
      headerName: "Código de barras",
      width: 310,
      headerAlign: "center",
      align: "center",
      type: "number",
    },
    {
      field: "quantidadeTotalUnitaria",
      headerName: "Em estoque",
      width: 200,
      headerAlign: "center",
      align: "center",
      type: "number",
    },
    {
      field: "quantidade",
      headerName: "Quantidade",
      width: 100,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <div>
          <Input
            type="number"
            variant="standard"
            InputProps={{
              disableUnderline: true,
              inputProps: {
                style: {
                  textAlign: "center",
                  fontSize: "15px",
                  appearance: "none",
                  backgroundColor: "transparent",
                },
              },
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.target.blur();
              }
            }}
            onBlur={(e) => {
              editarProdutos(params.row.id, Number(e.target.value), params.row.quantidadeTotalUnitaria);
            }}
          />
        </div>
      ),
    },
    {
      field: "remover",
      headerName: "Remover",
      type: "actions",
      flex: 1,
      minWidth: 110,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <div>
          <Button onClick={() => excluirProduto(params.row.id)} label="Delete">
            <DeleteIcon />
          </Button>
          ,
        </div>
      ),
    },
  ];

  const editarProdutos = (id, value, quantidadeTotalUnitaria) => {
    if (value <= 0) {
      setBotaoDesativado(true);
      return enqueueSnackbar("Quantidade inválida", { variant: "error" });
    }
    if (value > quantidadeTotalUnitaria ) {
      setBotaoDesativado(true);
      return enqueueSnackbar("O valor fornecido é superior à quantidade em estoque", { variant: "error" });
    }
    const editados = produtos.map((el) => {
      if (el.id === id) {
        return {
          id: el.id,
          quantidadeTotalUnitaria: el.quantidadeTotalUnitaria,
          nome: el.nome,
          codigoBarras: el.codigoBarras,
          quantidade: value,
          id_produto_estoque: el.id_produto_estoque,
        };
      }

      return { ...el };
    });
    setProdutos(editados);
    setBotaoDesativado(false);
  };

  const excluirProduto = (id) => {
    const itens = Array.from(produtos);
    const excluido = itens.filter((produto) => produto.id !== id);
    setProdutos(excluido);
  };

  const adicionarProdutos = (quantidadeTotalUnitaria, nome, codigoBarras, id_produto_estoque) => {
    const itens = Array.from(produtos);
    const existente = itens.find((el) => el.codigoBarras === codigoBarras);
    if (existente) {
      return editarProdutos(existente.id, existente.quantidade + 1);
    }
    itens.push({
      id: produtos.length,
      quantidadeTotalUnitaria,
      nome,
      codigoBarras,
      quantidade: 1,
      id_produto_estoque,
    });
    setBotaoDesativado(false);
    setProdutos(itens);
    return null;
  };

  const obtemProduto = async () => {
    try {
      const { data } = await api.get(
        `/v2/almoxarifado/produtoEstoque/baixa/${codigoInserido}/${local}`
      );

      if (data.produtos.message) {
        return enqueueSnackbar(data.produtos.message, { variant: "error" });
      }

      adicionarProdutos(
        data.produtos.estoque.quantidadeTotalUnitaria,
        data.produtos.produto.nome,
        codigoInserido,
        data.produtos.estoque.id
      );
      setCodigoInserido("");
    } catch (e) {
      console.log("erro:", e);
    }
    return null;
  };

  const handleCodigoDeBarras = async (e) => {
    e.preventDefault();
    obtemProduto();
  };

  const enviarProdutos = async () => {
    produtos.map(async (produto) => {
      try {
        await api.post("/v2/almoxarifado/movimentcaoProdutoEstoque/saida", {
          id_produto_estoque: produto.id_produto_estoque,
          usuario,
          data: new Date(),
          quantidade: produto.quantidade,
          origem_destino: local,
          tipo: "Saída",
        });
      } catch (e) {
        enqueueSnackbar(`Erro ao confirmar o produto ${produto.nome}`, {
          variant: "error",
        });
      }
    });
    enqueueSnackbar("Baixas finalizadas!", { variant: "success" });
    setUpdate(!update);
    limpaCampos();
  };

  const limpaCampos = () => {
    setProdutos([]);
    setCodigoInserido("");
    setOpen(false);
    setBotaoDesativado(true);
  };

  const cancelarProdutos = () => {
    limpaCampos();
  };

  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <Container sx={{ mt: 5 }} maxWidth="lg">
        <Paper elevation={2}>
          <Grid container>
            <Grid item xs={12} sm={12}>
              <Stack
                component="form"
                onSubmit={handleCodigoDeBarras}
                sx={{ p: 2 }}
                direction="row"
                spacing={2}
              >
                <TextField
                  inputRef={
                    codigoInserido ? null : (input) => input && input.focus()
                  }
                  variant="standard"
                  fullWidth
                  type="text"
                  label="Escaneie o código de barras"
                  value={codigoInserido}
                  onChange={(e) => setCodigoInserido(e.target.value)}
                />
                <IconButton
                  component="button"
                  type="submit"
                  sx={{ color: colorTheme.primary[500] }}
                >
                  <SendIcon />
                </IconButton>
              </Stack>
            </Grid>
            <Grid item sx={{ p: 2 }} xs={12} sm={12}>
              <Box sx={{ height: 1, width: "100%" }}>
                <Tabela
                  rows={produtos}
                  columns={columns}
                  autoHeight
                  initialState={{
                    columns: {
                      columnVisibilityModel: {
                        id: false,
                      },
                    },
                  }}
                />
              </Box>
            </Grid>
            <Grid
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                p: 2,
              }}
              item
              xs={12}
              sm={12}
            >
              <Button
                onClick={cancelarProdutos}
                color="red"
                variant="text"
                disabled={botaoDesativado}
              >
                Cancelar
              </Button>
              <Button
                onClick={enviarProdutos}
                color="blue"
                variant="text"
                disabled={botaoDesativado}
              >
                Confirmar
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Container>
    </Modal>
  );
}
