import React, { useState, useEffect } from "react";

import { Grid } from '@mui/material';
import { useSnackbar } from "notistack";
import { api } from "../../../../services/Api";
import { Select } from "../../../../componentsv2/Select";

export default function SelectEnfermeiro({
  todos = false,
  onChange,
  value,
  disabled = false,
  required = false,
}) {
  const [optionEnfermeiros, setOptionEnfermeiros] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    async function pegaEnfermeirosId() {
      try {
        const {
          data: { data },
        } = await api.get("/v2/agendaCare/enfermeiros");
        const enfermeiros = data.map(enfermeiro => {
          const nomeCompleto = enfermeiro.usuario.funcionario.nome_usu.toUpperCase();
          const arrayNome = nomeCompleto.split(" ");
          return {
            id: enfermeiro.id,
            nome: arrayNome.length > 1 ? `${arrayNome[0]} ${arrayNome[1]}` : arrayNome[0],
          };
        });
        todos &&
          enfermeiros.unshift({
            id: "",
            nome: "TODOS",
          });
        setOptionEnfermeiros(enfermeiros);
      } catch (error) {
        enqueueSnackbar("Erro! Não foi possível buscar os Enfermeiros.", {
          variant: "error",
        });
      }
    }
    pegaEnfermeirosId();
  }, [enqueueSnackbar, todos]);

  return (
    <>
      <Grid container spacing={1} justify="center" alignItems="stretch">
        <Grid item xs={12}>
          <Select
            required={required}
            disabled={disabled}
            onChange={onChange}
            value={value}
            label="Enfermeiro"
            variant="standard"
            arrayOptions={optionEnfermeiros}
            textAttribute="nome"
            valueAttribute="id"
          />
        </Grid>
      </Grid>
    </>
  );
}
