import React, { useEffect, useMemo, useState } from "react";
import { Grid, InputLabel, Menu, MenuItem } from '@mui/material';
import { Error, InvertColors, Warning } from '@mui/icons-material';

import SelectResultado from "../SelectResultado";

import "./styles.css";

export default function TabelaMapa(props) {
  const [celula, setCelula] = useState(null);
  const [celulaVazia, setCelulaVazia] = useState(true);
  const [verificaBotao, setVerificaBotao] = useState(false);

  const {
    mapeado,
    setMapeado,
    status_pcr,
    cria_mapa,
    setIdAmostra,
    setNAmostra,
    setSeqAmostra,
    setRemove_sample_map,
    amostraAvulsaDisponivel,
    setAdd_sample_map,
    nomeMapa
   } = props;

  const letras = [ "-","A","B","C","D","E","F","G","H" ];
  const sequencia = [ 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12 ];

  const classe = useMemo(() => {
    let vetorClasse = [];
    let aux = 0;
    mapeado.forEach((row, i) => {
        const valor = i + 1;
          if (((valor+aux)%13) === 0) {
            aux += 1;
          }

          if ((valor+aux)%2 > 0) {
            vetorClasse.push('dark')
          } else {
            vetorClasse.push('light')
          }
        })
    return vetorClasse
  }, [mapeado]);

  useEffect(() => {
    if (
      (status_pcr === "Preparação" && cria_mapa) ||
      (status_pcr === "Concluído")
    ) {
      setVerificaBotao(true);
    } else {
      setVerificaBotao(false);
    }
  }, [status_pcr, cria_mapa])


  function selecionaCelula(e, numeroSelecionado, idSeqSelecionado, idSelecionado) {
    if (numeroSelecionado) {
      setIdAmostra(idSelecionado);
      setNAmostra(numeroSelecionado);
      setCelulaVazia(false);
    } else if (status_pcr === "Preparação") {
      setSeqAmostra(idSeqSelecionado);
      setCelulaVazia(true);
    }

    if (!numeroSelecionado && status_pcr === "Concluído") {
      return null;
    }

    setCelula(e.currentTarget);
    return null
  }

  function selecionaCelulaMapa(e, numeroSelecionado, idSeqSelecionado, idSelecionado) {
    if (
      (status_pcr === "Preparação" && cria_mapa) ||
      (status_pcr === "Concluído")
    ) {
      selecionaCelula(e, numeroSelecionado, idSeqSelecionado, idSelecionado);
    }
  }

  const fechaCelula = () => {
    setCelula(null);
  };

  function verificaCor(resultadoCelula) {
    if (resultadoCelula === "Indeterminado") {
      return "#F8BA58"
    }
    if (resultadoCelula === "Positivo") {
      return "#FA9A9A"
    }
    if (resultadoCelula === "Negativo") {
      return "#A9F5A9"
    }
    return "transparent"
  }

  return (
    <div id="container-map">
      <Grid container>
        <Grid item xs={1}>
          {letras.map(row => (
            <Grid id="grid-letter" key={row} item xs={1}>
              <div className="cell-label-letters-map">
                <span className="label-letters-map">
                  {row}
                </span>
              </div>
            </Grid>
          ))}
        </Grid>
        <Grid item xs={11}>
          <Grid container spacing={2}>
            {sequencia.map(row => (
              <Grid key={row} item xs={1}>
                <InputLabel id="inputlabel-numbers-map">
                  {row}
                </InputLabel>
              </Grid>
            ))}
          </Grid>
          <Grid container>
            {mapeado.map((row, i) => (
              <Grid key={row.id_seq} item xs={1}>
                {(row.sequencial !== "H01" && row.sequencial !== "H12")
                  ? (
                    <button
                      type="button"
                      className={verificaBotao ? `container-button-cell-map-${classe[i]}` : `container-button-cell-map-off-${classe[i]}`}
                      onClick={e => {
                        selecionaCelulaMapa(e, row.numero || "", row.id_seq || "", row.id || "")
                      }}
                    >
                      <div className="container-label-map-cell">
                        <div className="container-icon-urgency-map">
                          {(row.tipo === "Urgência")
                            ? <Error id="icon-urgency-map" />
                            : ""}
                          {(row.tipo === "Repetição")
                            ? <Warning id="icon-repetition-map" />
                            : ""}
                          {(row.material === "Saliva")
                            ? <InvertColors id="icon-spittle-map" />
                            : ""}
                          <span className="inplabel-number-map">
                            {row.numero}
                          </span>
                          <span className="inplabel-initials-map">
                            {row.sigla || row.sequencial}
                          </span>
                        </div>

                        <div className="container-results-code">
                          {row.resultado
                        ? (
                          <div className="content-results">
                            <span className="inplabel-code-map">
                              {(row.ci === 'Undetermined') ? 'U' : Number(row.ci).toFixed(0)}
                            </span>
                            <span className="inplabel-code-map">
                              {(row.gene_n === 'Undetermined') ? 'U' : Number(row.gene_n).toFixed(0)}
                            </span>
                            <span className="inplabel-code-map">
                              {(row.orf1ab === 'Undetermined') ? 'U' : Number(row.orf1ab).toFixed(0)}
                            </span>
                          </div>
                        )
                        : null }
                          <div className="content-results">
                            {(row.resultado && status_pcr === "Em análise")
                            ? (
                              <SelectResultado
                                nomeMapa={nomeMapa}
                                vetorResultado={row}
                                setMapeado={setMapeado}
                              />
                            )
                            : (
                              <span className="inplabel-result-map" style={{backgroundColor: verificaCor(row.resultado)}}>
                                {row.resultado === 'Indeterminado' ? 'Indet...' : row.resultado}
                              </span>
                              )}
                            <span className="inplabel-code-map">
                              {row.pedido}
                            </span>
                          </div>
                        </div>
                      </div>
                    </button>
                ) : (
                  <div className={row.sequencial === "H01" ? "container-button-cell-map-off-light" : "container-button-cell-map-off-dark"}>
                    <div className="container-label-map-cell">
                      <span className="label-code-cell-map">
                        {row.sequencial === "H01" ? 'CP' : 'CN'}
                      </span>
                    </div>
                  </div>
                )}
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
      <Menu id="simple-menu" anchorEl={celula} keepMounted open={Boolean(celula)} onClose={fechaCelula}>
        {celulaVazia ? (
          <MenuItem
            onClick={() => {
              fechaCelula();
              amostraAvulsaDisponivel();
              setAdd_sample_map(true);
            }}
          >
            Adicionar amostra
          </MenuItem>
        ) : (
          <MenuItem
            onClick={() => {
              fechaCelula();
              setRemove_sample_map(true);
            }}
          >
            {status_pcr === "Preparação" ? "Remover amostra" : "Repetir amostra"}
          </MenuItem>
        )}
      </Menu>
    </div>
  );
}
