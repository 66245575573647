import React, { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import Paper from "../../../../componentsv2/Paper";
import Button from "../../../../componentsv2/Button";
import { Input } from "../../../../componentsv2/Input";
import GridItem from "../../../../componentsv2/GridItem";
import GridContainer from "../../../../componentsv2/GridContainer";
import SelectDeGenero from "../../components/SelectDeGenero";
import SelectSimNao from "../../components/SelectSimNao";
import formatDateBD from "../../../../utils/formatarDataBD";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DataPickerAtualizado from "../../../../components/DataPickerAtualizado";
import useUser from "../../../../hooks/useUser";
import { api } from "../../../../services/Api";
import { useSnackbar } from "notistack";
import { Context } from "../../../../Context/PermissoesContext";
import SelectEstado from "../../../../componentsv2/SelectEstado";
import Container from "../../../../componentsv2/Container";
import Header from "../../../../components/Header";

export default function CadastroDeClientes() {
  const [nomeCliente, setNomeCliente] = useState("");
  const [rg, setRg] = useState("");
  const [cpf, setCpf] = useState("");
  const [dataExpedicao, setDataExpedicao] = useState(null);
  const [orgaoExpedidor, setOrgaoExpedidor] = useState("");
  const [genero, setGenero] = useState("");
  const [dataNascimento, setDataNascimento] = useState(null);
  const [peso, setPeso] = useState("");
  const [altura, setAltura] = useState("");
  const [gestante, setGestante] = useState("");
  const [telefone, setTelefone] = useState("");
  const [celular, setCelular] = useState("");
  const [email, setEmail] = useState("");
  const [nomeMae, setNomeMae] = useState("");
  const [medicacao, setMedicacao] = useState("");
  const [indicacao, setIndicacao] = useState("");
  const [endereco, setEndereco] = useState("");
  const [numeroEndereco, setNumeroEndereco] = useState("");
  const [cidade, setCidade] = useState("");
  const [bairro, setBairro] = useState("");
  const [estado, setEstado] = useState("");
  const [cep, setCep] = useState("");
  const [complemento, setComplemento] = useState("");

  const history = useHistory();
  const location = useLocation();
  const care = location?.state;
  const usuario = useUser();

  const { enqueueSnackbar } = useSnackbar();

  const { checarPermissao } = useContext(Context);

  useEffect(() => {
    if (!checarPermissao(`GDC002`)) {
      // if (care) {
      enqueueSnackbar("Erro! Você não tem permissão para cadastrar cliente!", {
        variant: "error",
      });
      return history.push("/agendaCare/formularioAgendamento");
      // }
      // history.push("/GestaoDeClientes");
      // enqueueSnackbar("Erro! Você não tem permissão para cadastrar cliente!", {
      // variant: "error",
      // });
    }

    // eslint-disable-next-line
  }, [care]);

  const handleNavigate = () => {
    if (care) {
      return history.push("/agendaCare/formularioAgendamento");
    }
    history.push("/GestaoDeClientes");
  };

  const handleSubmit = async () => {
    try {
      const data = {
        atendente: usuario,
        nome: nomeCliente,
        rg,
        cpf,
        mae: nomeMae,
        dataNascimento: formatDateBD(dataNascimento),
        sexo: genero,
        endereco,
        enderecoNumero: numeroEndereco,
        bairro,
        cidade,
        celular,
        telefone,
        peso,
        altura,
        gestante,
        medicacao,
        indicacao,
        cep,
        complemento,
        estado,
        dataExpedicao: formatDateBD(dataExpedicao),
        orgaoExpedidor,
      };
      await api.post("/v2/controleDna/cliente", data);
      handleNavigate();
      enqueueSnackbar("O cliente cadastrado com sucesso!.", {
        variant: "success",
      });
    } catch (error) {
      enqueueSnackbar("Erro! Não foi possível cadastrar o cliente.", {
        variant: "error",
      });
    }
  };

  return (
    <>
      <Header titulo="Cadastrar Cliente" />
      <Container>
        <Button onClick={handleNavigate}>
          <ArrowBackIcon style={{ color: "#003399" }} />
        </Button>
        <Paper style={{ padding: "40px", marginTop: "10px" }} elevation={2}>
          {/* <GridContainer
          xs={12}
          justifyContent="flex-end"
          sx={{ marginBottom: "20px" }}
          spacing={2}
        >
          <GridItem xs={12} sm={4}>
            <Input  fullWidth label="Buscar dados no softLab" type="text" />
          </GridItem>
        </GridContainer> */}

          <form onSubmit={handleSubmit}>
            <GridContainer xs={12} spacing={2}>
              <GridItem xs={6}>
                <Input
                  onChange={(e) => {
                    setNomeCliente(e.target.value);
                  }}
                  value={nomeCliente}
                  required
                  fullWidth
                  label="Nome"
                  type="text"
                  variant="standard"
                />
              </GridItem>

              <GridItem xs={3}>
                <Input
                  onChange={(e) => {
                    setRg(e.target.value);
                  }}
                  value={rg}
                  // required
                  fullWidth
                  label="RG"
                  type="text"
                  variant="standard"
                />
              </GridItem>

              <GridItem xs={3}>
                <Input
                  onChange={(e) => {
                    setCpf(e.target.value);
                  }}
                  value={cpf}
                  required
                  fullWidth
                  label="CPF"
                  type="text"
                  variant="standard"
                />
              </GridItem>

              <GridItem xs={2}>
                <DataPickerAtualizado
                  data={dataExpedicao}
                  setData={setDataExpedicao}
                  required
                  variant="standard"
                  labelName="Data de Expedição"
                />
              </GridItem>

              <GridItem xs={4}>
                <Input
                  onChange={(e) => {
                    setOrgaoExpedidor(e.target.value);
                  }}
                  value={orgaoExpedidor}
                  fullWidth
                  label="Orgão Expedidor"
                  type="text"
                  variant="standard"
                />
              </GridItem>

              <GridItem xs={3}>
                <SelectDeGenero
                  required
                  onChange={(e) => {
                    setGenero(e.target.value);
                  }}
                  value={genero}
                />
              </GridItem>

              <GridItem xs={3}>
                <DataPickerAtualizado
                  data={dataNascimento}
                  setData={setDataNascimento}
                  required
                  variant="standard"
                  labelName="Data de Nascimento"
                />
              </GridItem>

              <GridItem xs={2}>
                <Input
                  onChange={(e) => {
                    setPeso(e.target.value);
                  }}
                  value={peso}
                  fullWidth
                  label="Peso"
                  type="number"
                  variant="standard"
                />
              </GridItem>

              <GridItem xs={2}>
                <Input
                  onChange={(e) => {
                    setAltura(e.target.value);
                  }}
                  value={altura}
                  fullWidth
                  label="Altura"
                  type="number"
                  variant="standard"
                />
              </GridItem>

              <GridItem xs={2}>
                <SelectSimNao
                  required
                  onChange={(e) => {
                    setGestante(e.target.value);
                  }}
                  value={gestante}
                />
              </GridItem>

              <GridItem xs={3}>
                <Input
                  onChange={(e) => {
                    setTelefone(e.target.value);
                  }}
                  value={telefone}
                  fullWidth
                  label="Telefone"
                  type="text"
                  variant="standard"
                />
              </GridItem>

              <GridItem xs={3}>
                <Input
                  required
                  onChange={(e) => {
                    setCelular(e.target.value);
                  }}
                  value={celular}
                  fullWidth
                  label="Celular"
                  type="text"
                  variant="standard"
                />
              </GridItem>

              <GridItem xs={3}>
                <Input
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  value={email}
                  fullWidth
                  label="E-mail"
                  type="text"
                  variant="standard"
                />
              </GridItem>

              <GridItem xs={6}>
                <Input
                  onChange={(e) => {
                    setNomeMae(e.target.value);
                  }}
                  value={nomeMae}
                  fullWidth
                  label="Mãe"
                  type="text"
                  variant="standard"
                />
              </GridItem>

              <GridItem xs={3}>
                <Input
                  onChange={(e) => {
                    setMedicacao(e.target.value);
                  }}
                  value={medicacao}
                  fullWidth
                  label="Medicação"
                  type="text"
                  variant="standard"
                />
              </GridItem>

              <GridItem xs={4}>
                <Input
                  onChange={(e) => {
                    setIndicacao(e.target.value);
                  }}
                  value={indicacao}
                  fullWidth
                  label="Indicação"
                  type="text"
                  variant="standard"
                />
              </GridItem>

              <GridItem xs={8}>
                <Input
                  onChange={(e) => {
                    setEndereco(e.target.value);
                  }}
                  value={endereco}
                  required
                  fullWidth
                  label="Endereço"
                  type="text"
                  variant="standard"
                />
              </GridItem>

              <GridItem xs={4}>
                <Input
                  onChange={(e) => {
                    setNumeroEndereco(e.target.value);
                  }}
                  value={numeroEndereco}
                  fullWidth
                  required
                  label="Nº Endereço"
                  type="text"
                  variant="standard"
                />
              </GridItem>

              <GridItem xs={3}>
                <Input
                  onChange={(e) => {
                    setCidade(e.target.value);
                  }}
                  value={cidade}
                  fullWidth
                  required
                  label="Cidade"
                  type="text"
                  variant="standard"
                />
              </GridItem>

              <GridItem xs={3}>
                <Input
                  onChange={(e) => {
                    setBairro(e.target.value);
                  }}
                  value={bairro}
                  fullWidth
                  required
                  label="Bairro"
                  type="text"
                  variant="standard"
                />
              </GridItem>

              <GridItem xs={2}>
                <SelectEstado
                  onChange={(e) => {
                    setEstado(e.target.value);
                  }}
                  value={estado}
                />
              </GridItem>

              <GridItem xs={4}>
                <Input
                  required
                  onChange={(e) => {
                    setCep(e.target.value);
                  }}
                  value={cep}
                  fullWidth
                  label="CEP"
                  type="text"
                  variant="standard"
                />
              </GridItem>

              <GridItem xs={12}>
                <Input
                  onChange={(e) => {
                    setComplemento(e.target.value);
                  }}
                  value={complemento}
                  fullWidth
                  label="Complemento"
                  type="text"
                  variant="standard"
                />
              </GridItem>
            </GridContainer>
            <GridItem
              item
              sx={{ marginTop: "30px" }}
              container
              justifyContent="space-between"
              spacing={2}
            >
              <GridItem
                xs={12}
                sx={{ marginTop: "30px" }}
                container
                justifyContent="flex-end"
                spacing={2}
              >
                <GridItem>
                  <Button
                    onClick={handleNavigate}
                    color="red"
                    variant="outlined"
                  >
                    Cancelar
                  </Button>
                </GridItem>
                <GridItem>
                  <Button type="submit" color="green" variant="contained">
                    Cadastrar
                  </Button>
                </GridItem>
              </GridItem>
            </GridItem>
          </form>
        </Paper>
      </Container>
    </>
  );
}
