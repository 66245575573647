import React from "react";
import CustomRoute from "../../components/CustomRoute";
import routesNotificacoesCovid from "./pages/NotificacoesCovid/routes";

const TodasAsRotas = ({ routes }) => (
  <>
    {routes.map((el, index) => (
      <CustomRoute key={index} {...el.props}>
        {el.componente}
      </CustomRoute>
    ))}
  </>
);

export default function AdministrativoRotas() {
  const routes = [...routesNotificacoesCovid()];

  return <TodasAsRotas routes={routes} />;
}
