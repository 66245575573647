import React, { useState, useEffect, useMemo } from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  InputAdornment,
  TableRow,
  TableCell,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Tooltip,
  Paper,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import SettingsIcon from "@mui/icons-material/Settings";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import BookIcon from "@mui/icons-material/Book";
import ViewModuleIcon from "@mui/icons-material/ViewModule";

import { useSnackbar } from "notistack";

import Tabela from "../../../../components/Tabela";
import Button from "../../../../components/Button";
import Dialog from "../../../../components/Dialog";
import Loading from "../../../../components/Loading";
import BlueIcon from "../../../../components/BlueIcon";
import OptionsModal from "../../../../components/OptionsModal";
import ConfirmModal from "../../../../components/Modal";

import ActionDialog from "./Dialog";

import { api } from "../../../../services/Api";
import formatarData from "../../../../utils/formatarData";

export default function Organizacao() {
  const { enqueueSnackbar } = useSnackbar();

  const [search, setSearch] = useState("");
  const [sections, setSections] = useState([]);
  const [selectedSection, setSelectedSection] = useState("");
  const [modules, setModules] = useState([]);
  const [selectedModule, setSelectedModule] = useState(null);
  const [showDeleteSectionModal, setShowDeleteSectionModal] = useState(false);
  const [showDeleteModuleModal, setShowDeleteModuleModal] = useState(false);

  const [actionDialogProps, setActionDialogProps] = useState({
    type: "",
    mode: "",
    values: null,
    id: null,
  });

  const [showOptionsDialog, setShowOptionsDialog] = useState(false); // para editar e deletar modulos
  const [showActionDialog, setShowActionDialog] = useState(false); //  dialogo para editar modulos e secoes
  const [showOptionsModal, setShowOptionsModal] = useState(false); // dialogo para escolher se vai criar secao ou modulo
  const [loading, setLoading] = useState(true);

  // recuperando todas as secoes
  useEffect(() => {
    (async () => {
      try {
        const { data } = await api.get("/v2/portalMedico/secoes");
        setSections(data);
      } catch (e) {
        enqueueSnackbar(
          "Não foi possível recuperar as seções disponíveis. Por favor, recarregue a página",
          { variant: "error" }
        );
      }
      setLoading(false);
    })();
  }, [enqueueSnackbar]);

  // recuperando os modulos da secao selecionada
  useEffect(() => {
    (async () => {
      if (selectedSection) {
        try {
          const { data } = await api.get(
            `/v2/portalMedico/secao/${selectedSection}/modulos`
          );
          setModules(data);
        } catch (e) {
          enqueueSnackbar(
            "Não foi possível recuperar os módulos da seção escolhida. Por favor, recarregue a página",
            { variant: "error" }
          );
        }
      }
    })();
  }, [enqueueSnackbar, selectedSection]);

  const onCreateButtonClick = () => {
    setShowOptionsModal(true);
  };

  const openOptionsDialog = (id) => {
    setSelectedModule(id);
    setShowOptionsDialog(true);
  };

  const closeOptionsDialog = () => {
    setSelectedModule(null);
    setShowOptionsDialog(false);
  };

  const closeActionDialog = () => {
    setShowActionDialog(false);
  };

  const openActionDialogForCreating = (type) => {
    const values =
      type === "section"
        ? { nome: "", especialidades: [] }
        : { nome: "", especialidades: [], id_secao: "", url_icone: null };
    setShowOptionsDialog(false);
    setShowOptionsModal(false);
    setActionDialogProps((prevState) => ({
      ...prevState,
      type,
      mode: "create",
      values,
    }));
    setShowActionDialog(true);
  };

  const openActionDialogForEditing = (type, targetId) => {
    const source = type === "section" ? sections : modules;
    const target = source.find((element) => element.id === targetId);
    setShowOptionsDialog(false);
    setShowOptionsModal(false);
    setActionDialogProps((prevState) => ({
      ...prevState,
      type,
      mode: "edit",
      values: target,
    }));
    setShowActionDialog(true);
  };

  const updateValuesField = (field, value) => {
    setActionDialogProps((prevState) => ({
      ...prevState,
      values: { ...prevState.values, [field]: value },
    }));
  };

  const addModule = (newModule) => {
    if (selectedSection && selectedSection === newModule.id_secao) {
      setModules((prevState) => [...prevState, newModule]);
    }
  };

  const updateModule = (newModule) => {
    if (
      selectedSection &&
      selectedSection === parseInt(newModule.id_secao, 10)
    ) {
      const copy = [...modules];
      const targetIndex = copy.findIndex((el) => el.id === newModule.id);
      copy[targetIndex] = newModule;
      setModules(copy);
    }
  };

  // para atualizar o select quando criando uma nova secao
  const addSection = (newSection) => {
    setSections((prevState) => [...prevState, newSection]);
  };

  const updateSection = (newSection) => {
    const copy = [...sections];
    const targetIndex = copy.findIndex(
      (element) => element.id === newSection.id
    );
    copy[targetIndex] = newSection;
    setSections(copy);
  };

  const deleteSection = async () => {
    setShowDeleteSectionModal(false);
    setLoading(true);
    try {
      await api.delete(`/v2/portalMedico/secao/${selectedSection}`);
      const targetIndex = sections.findIndex((s) => s.id === selectedSection);
      const copy = [...sections];
      copy.splice(targetIndex, 1);
      setSections(copy);
      setSelectedSection(null);
      enqueueSnackbar("Seção deletada com sucesso!", { variant: "success" });
    } catch (e) {
      enqueueSnackbar("Não foi possível excluir esta seção.", {
        variant: "error",
      });
    }
    setLoading(false);
  };

  const deleteModule = async () => {
    setShowDeleteModuleModal(false);
    setShowOptionsDialog(false);
    setLoading(true);
    try {
      await api.delete(`/v2/portalMedico/modulo/${selectedModule}`);
      const targetIndex = modules.findIndex((s) => s.id === selectedModule);
      const copy = [...modules];
      copy.splice(targetIndex, 1);
      setModules(copy);
      setSelectedModule(null);
      enqueueSnackbar("Módulo deletado com sucesso!", { variant: "success" });
    } catch (e) {
      enqueueSnackbar("Não foi possível excluir este módulo.", {
        variant: "error",
      });
    }
    setLoading(false);
  };

  const searchFunction = (module) => {
    if (search === "") {
      return true;
    }

    const { nome } = module;
    return nome.toLowerCase().includes(search.toLowerCase());
  };

  // options for the OptionsModal
  const options = useMemo(
    () => [
      {
        title: "Seção",
        subtitle: "Uma coleção de módulos",
        onClick: () => openActionDialogForCreating("section"),
        icon: (
          <BookIcon style={{ color: "rgba(0,0,0,0.6)", fontSize: "40px" }} />
        ),
      },
      {
        title: "Módulo",
        subtitle: "Uma coleção de artigos técnicos",
        onClick: () => openActionDialogForCreating("module"),
        icon: (
          <ViewModuleIcon
            style={{ color: "rgba(0,0,0,0.6)", fontSize: "40px" }}
          />
        ),
      },
    ],
    []
  );

  if (loading) {
    return <Loading />;
  }
  return (
    <>
      <ConfirmModal
        open={showDeleteSectionModal}
        title="Excluir seção"
        text="Você tem certeza de que deseja excluir esta seção? Isso ocasionará a exclusão dos módulos dela também!"
        cancelButtonLabel="Não, quero voltar"
        confirmButtonLabel="Sim, desejo excluir"
        onCancel={() => setShowDeleteSectionModal(false)}
        onConfirm={deleteSection}
      />
      <ConfirmModal
        open={showDeleteModuleModal}
        title="Excluir módulo"
        text="Você tem certeza de que deseja excluir este módulo?"
        cancelButtonLabel="Não, quero voltar"
        confirmButtonLabel="Sim, desejo excluir"
        onCancel={() => setShowDeleteModuleModal(false)}
        onConfirm={deleteModule}
      />
      <OptionsModal
        open={showOptionsModal}
        onClose={() => setShowOptionsModal(false)}
        options={options}
      />
      <ActionDialog
        open={showActionDialog}
        onClose={closeActionDialog}
        updateValuesField={updateValuesField}
        addSection={addSection}
        updateSection={updateSection}
        addModule={addModule}
        updateModule={updateModule}
        sections={sections}
        {...actionDialogProps}
      />
      <Dialog open={showOptionsDialog} title="Opções">
        <List>
          <ListItem
            onClick={() => openActionDialogForEditing("module", selectedModule)}
          >
            <ListItemIcon>
              <EditIcon />
            </ListItemIcon>
            <ListItemText>Editar</ListItemText>
          </ListItem>
          <ListItem onClick={() => setShowDeleteModuleModal(true)}>
            <ListItemIcon>
              <DeleteIcon />
            </ListItemIcon>
            <ListItemText>Excluir</ListItemText>
          </ListItem>
          <ListItem onClick={closeOptionsDialog}>
            <ListItemIcon>
              <KeyboardBackspaceIcon />
            </ListItemIcon>
            <ListItemText>Voltar</ListItemText>
          </ListItem>
        </List>
      </Dialog>
      <Paper style={{ padding: "1.25rem", marginBottom: "3rem" }}>
        <div className="conteudo-top-container">
          <div style={{ display: "flex", alignItems: "flex-end" }}>
            <FormControl style={{ width: "200px" }}>
              <InputLabel id="tipo-conteudo-label">Seção</InputLabel>
              <Select
                labelId="tipo-conteudo-label"
                value={selectedSection}
                onChange={(e) => {
                  setSelectedSection(e.target.value);
                }}
              >
                {sections.map((section) => (
                  <MenuItem key={`section-${section.id}`} value={section.id}>
                    {section.nome}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {selectedSection && (
              <>
                <BlueIcon
                  style={{ marginLeft: "10px" }}
                  onClick={() =>
                    openActionDialogForEditing("section", selectedSection)
                  }
                >
                  <Tooltip title="Editar a seção selecionada">
                    <EditIcon style={{ color: "white", fontSize: "25px" }} />
                  </Tooltip>
                </BlueIcon>
                <BlueIcon
                  style={{ marginLeft: "5px" }}
                  onClick={() => setShowDeleteSectionModal(true)}
                >
                  <Tooltip title="Excluir a seção selecionada">
                    <DeleteIcon style={{ color: "white", fontSize: "25px" }} />
                  </Tooltip>
                </BlueIcon>
              </>
            )}
          </div>
          <div className="top-container-actions">
            <Button
              onClick={onCreateButtonClick}
              style={{
                fontSize: "1.1rem",
                padding: ".5rem .75rem",
                marginLeft: ".75rem",
              }}
            >
              Criar
            </Button>
          </div>
        </div>
      </Paper>
      {selectedSection && (
        <>
          <TextField
            value={search}
            onChange={(e) => {
              // e.persist();
              setSearch(e.target.value);
            }}
            placeholder="Busca"
            style={{ display: "inline-block", marginLeft: "auto" }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon
                    style={{ color: "rgba(0,0,0,0.6)", cursor: "pointer" }}
                  />
                </InputAdornment>
              ),
            }}
          />
          <Tabela
            rowsPerPage={9999}
            page={0}
            count={modules.length}
            containerStyles={{ marginTop: "1rem" }}
            header={["Nome", "Editado em", "Opções"]}
          >
            {modules.filter(searchFunction).map((module) => (
              <TableRow key={module.id}>
                <TableCell align="center">{module.nome}</TableCell>
                <TableCell align="center">
                  {formatarData(new Date(module.alterado_em))}
                </TableCell>
                <TableCell align="center">
                  <SettingsIcon
                    onClick={() => openOptionsDialog(module.id)}
                    style={{ cursor: "pointer" }}
                  />
                </TableCell>
              </TableRow>
            ))}
          </Tabela>
        </>
      )}
    </>
  );
}
