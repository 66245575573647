/* eslint-disable react/jsx-curly-newline */
import React, { useRef, useState, useEffect, useMemo, useContext } from "react";
import { useSnackbar } from "notistack";
import {
  DialogActions,
  TextField,
  FormControl,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  InputLabel,
  Select,
  MenuItem,
  Grid,
} from "@mui/material";
import dayjs from "dayjs";

import { Add as AddIcon, Close as CloseIcon } from "@mui/icons-material";

// import DatePicker from "../../components/DatePicker";
import Radio from "../../components/Radio";
import Button from "../../components/Button";
import Dialog from "../../components/Dialog";
import Loading from "../../components/Loading";
import DataPickerAtualizado from "../../components/DataPickerAtualizado";

import { validarCPF, validarNaoVazio } from "../../utils/validations";
import formatarData from "../../utils/formatarData";

import useUser from "../../hooks/useUser";

import { Context } from "../../Context/PermissoesContext";

import { api } from "../../services/Api";

export function useDialog() {
  const [showDialog, setShowDialog] = useState(false);
  const [dialogValues, setDialogValues] = useState({
    data: new Date(Date.now()),
    nome_cliente: "",
    cpf_cliente: "",
    celular_cliente: "",
    autorizacao: "cliente", // cliente ou 
    observacoes: "",
    autorizados: [],
    anexos: [],
    id: null,
    estado: "agendado",
    identificador: "",
    numero_processo: "",
    comarca: "",
    id_horario: "",
    tipo_exame: "paternidade",
    id_unidade: "",
  });

  /* editar ou visualizar. so eh relevante quando temos um exame selecionado.
   pois, neste caso, estariamos editando ou visualizando um exame ja criado.
   se um exame nao tiver sido selecionado, significa que estamos criando um novo exame. */

  const [modoDialog, setModoDialog] = useState("editar");
  const [exameSelecionado, setExameSelecionado] = useState(-1);

  return {
    showDialog,
    setShowDialog,
    dialogValues,
    setDialogValues,
    modoDialog,
    setModoDialog,
    exameSelecionado,
    setExameSelecionado,
  };
}

export default function ExamDialog({
  open,
  setOpen,
  modo,
  dialogValues,
  setDialogValues,
  exameSelecionado,
  setExameSelecionado,
  setExames,
  exames,
  dataInicio,
  dataFim,
  setCount,
  pagina,
}) {
  /*
    Serve para controlar o estado do dialog do exame. Se estiver em modo visualizar, entao
    os inputs ficarao desabilitados e nao permitiremos edicoes etc.
    Contudo, vale lembrar que tais estados - que sao guardados em modo(acima) - somente sao validos
    quando estamos editando ou visualizando um exame JA CRIADO. Isso eh determinado pela variavel
    `exameSelecionado` (acima), a qual representava, no inicio, o indice do exame selecionado, o qual
    ficava guardado num array de exames.
    Contudo, apos componentizar essa Dialog, ela vira apenas uma variavel de controle para determinar o modo.
    Mantive a variavel como um numero para ficar mais compativel com o codigo que ja havia sido escrito
    antes desse dialog virar um componente.

    Basicamente, se o exameSelecionado for igual a -1, entao estamos criando um exame novo. Senao, significa
    que estamos editando ou visualizando um exame. Ai que a variavel `modo` entra - para determinarmos com certeza
    o que estamos fazendo.
    */
  const { checarPermissaoComAcao, checarPermissaoSemLog } = useContext(Context);
  const modoVisualizacao = useMemo(
    () => exameSelecionado !== -1 && modo === "visualizar",
    [exameSelecionado, modo]
  );
  const fileInputRef = useRef(null);
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);
  const [unidades, setUnidades] = useState([]);
  const [horarios, setHorarios] = useState([]);
  const usuario = useUser();

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const { data } = await api.get(`/v2/agendaPaternidade/unidadesExame`);
        setUnidades(data);
      } catch (e) {
        enqueueSnackbar(
          "Não foi possível recuperar as unidades disponíveis. Por favor, recarregue a página",
          { variant: "error", autoHideDuration: 3000 }
        );
      }
      setLoading(false);
    })();
  }, [enqueueSnackbar]);

  useEffect(() => {
    if (dialogValues.id_unidade !== "") {
      (async () => {
        setLoading(true);
        try {
          const { data } = await api.get(
            `/v2/agendaPaternidade/horarios/${dialogValues.id_unidade}`,
            {
              params: { data: dialogValues.data },
            }
          );
          setHorarios(data);
        } catch (e) {
          enqueueSnackbar(
            "Não foi possível recuperar os horários da data selecionada. Por favor, recarregue a página",
            { variant: "error", autoHideDuration: 3000 }
          );
        }
        setLoading(false);
      })();
    }
  }, [dialogValues.data, enqueueSnackbar, dialogValues.id_unidade]);

  const adicionarAnexos = (e) => {
    // e.persist();
    setDialogValues((prevState) => ({
      ...prevState,
      anexos: [...prevState.anexos, ...Array.from(e.target.files)],
    }));
  };

  const removerAnexo = (index) => {
    checarPermissaoComAcao("AGP005", () => {
      const copia = [...dialogValues.anexos];
      copia.splice(index, 1);
      return setDialogValues((prevState) => ({
        ...prevState,
        anexos: copia,
      }));
    });
  };

  const onClickAnexo = async (anexo) => {
    // um anexo pode tanto ser um arquivo local quanto um JSON retornado pela API.
    // portanto, devemos lidar com os dois casos
    const tempLink = document.createElement("a");
    tempLink.style = "display:none;";
    tempLink.target = "_blank";
    if (anexo.caminho) {
      // se estamos aqui, eh um JSON enviado pela API representando um anexo
      tempLink.href = `${process.env.REACT_APP_BASEURL_WEB}${anexo.caminho}?token=${api.defaults.headers.Authorization}`;
    } else {
      // se estamos aqui, eh um arquivo
      const url = URL.createObjectURL(anexo);
      tempLink.href = url;
    }
    tempLink.click();
    tempLink.remove();
  };

  const removerAutorizado = (index) => {
    const novosAutorizados = [...dialogValues.autorizados];
    novosAutorizados.splice(index, 1);
    if (novosAutorizados.length === 0) {
      return setDialogValues((prevState) => ({
        ...prevState,
        autorizados: novosAutorizados,
        autorizado: "cliente",
      }));
    }
    return setDialogValues((prevState) => ({
      ...prevState,
      autorizados: novosAutorizados,
    }));
  };

  const atualizarAutorizado = (valor, index, campo) => {
    const novoAutorizado = { ...dialogValues.autorizados[index] };
    const novosAutorizados = [...dialogValues.autorizados];
    novoAutorizado[campo] = valor;
    novosAutorizados[index] = novoAutorizado;
    setDialogValues((prevState) => ({
      ...prevState,
      autorizados: novosAutorizados,
    }));
  };

  function fazerValidacao(valor, validador, mensagem) {
    if (!validador(valor)) {
      throw new Error(mensagem);
    }
  }

  function validarCliente() {
    fazerValidacao(
      dialogValues.nome_cliente,
      validarNaoVazio,
      "O nome do cliente não pode ser vazio."
    );
    if (dialogValues.cpf_cliente.length > 0) {
      fazerValidacao(dialogValues.cpf_cliente, validarCPF, "CPF inválido");
    }
  }

  function validarHorarioExame() {
    // o horario tem que possuir vagas restantes
    const target = horarios.find((h) => h.id === dialogValues.id_horario);

    function validarLotacao(horario) {
      // se o horario nao mudou, nao precisamos checar isso
      if (
        exameSelecionado !== -1 &&
        exames[exameSelecionado].horario.id === horario.id
      ) {
        return true;
      }
      return horario.lotacao_maxima - horario.quantidade_exames > 0;
    }

    // o horario tem que possibilitar o exame de reconstituicao genetica
    function validarTipoExame(horario) {
      if (dialogValues.tipo_exame === "reconstituição genética") {
        return horario.genetica;
      }

      return true;
    }

    /* caso o horário permita reconstituição genética e só exista uma vaga restante nesse horário,
    então tal vaga deve ser do exame de reconstituição.
    Note que, isso só é válido pois, por enquanto, só existe uma vaga por horario. Caso não existisse,
    poderia existir somente uma vaga e o exame de reconstituição já poderia ter sido marcado.

    Portanto, CASO o número de vagas seja expandido, isto tem que ser mudado */
    function validarVagasRestantes(horario) {
      const quantidadeVagas =
        horario.lotacao_maxima - horario.quantidade_exames;
      if (quantidadeVagas === 1 && horario.genetica) {
        return dialogValues.tipo_exame === "reconstituição genética";
      }

      return true;
    }

    fazerValidacao(target, () => target, "Selecione um horário para o exame");
    fazerValidacao(target, validarLotacao, "O horário já está cheio");
    fazerValidacao(
      target,
      validarTipoExame,
      "O exame de reconstituição não pode ser feito neste horário"
    );
    fazerValidacao(
      target,
      validarVagasRestantes,
      "A vaga restante desse horário é exclusiva para exames de reconstituição"
    );
  }

  function validarAutorizado(autorizado, index) {
    fazerValidacao(
      autorizado.nome,
      validarNaoVazio,
      `O nome do autorizado ${index + 1} está vazio.`
    );
    if (autorizado.cpf.length > 0) {
      fazerValidacao(
        autorizado.cpf,
        validarCPF,
        `O cpf do autorizado ${index + 1} está inválido.`
      );
    }
  }

  // funcao utilitaria
  const cleanup = () => {
    setOpen(false);
    setExameSelecionado(-1);
    setDialogValues({
      data: new Date(Date.now()),
      nome_cliente: "",
      cpf_cliente: "",
      celular_cliente: "",
      autorizacao: "cliente", // cliente ou outros
      observacoes: "",
      autorizados: [],
      anexos: [],
      estado: "agendado",
      identificador: "",
      comarca: "",
      numero_processo: "",
      id_horario: "",
      tipo: "",
      id_unidade: "",
    });
    setHorarios([]);
  };

  // funcao para atualizar a interface apos a criacao/edicao de um novo exame
  // modo pode ser 'criar' ou 'editar'
  function atualizarInterface(exame, modoAtualizacao) {
    const dataExame = new Date(exame.data);
    const dataNoIntervalo = dataExame >= dataInicio && dataExame <= dataFim;

    const copia = [...exames];
    const targetIndex = copia.findIndex((e) => e.id === exame.id);

    switch (pagina) {
      case "agendamento":
        if (dataNoIntervalo && modoAtualizacao === "criar") {
          if (exames.length === 9) {
            copia.splice(copia.length - 1, 1);
            setExames([{ ...exame }, ...copia]);
          } else {
            setExames((prevState) => [{ ...exame }, ...prevState]);
          }

          if (setCount) {
            setCount((prevState) => prevState + 1);
          }
        } else if (!dataNoIntervalo && modoAtualizacao === "editar") {
          copia.splice(targetIndex, 1);
          setExames(copia);
          if (setCount) {
            setCount((prevState) => prevState - 1);
          }
        } else if (dataNoIntervalo && modoAtualizacao === "editar") {
          copia[targetIndex] = exame;
          setExames(copia);
        }

        break;
      default:
        copia[targetIndex] = exame;
        setExames(copia);
        break;
    }
  }

  async function atualizarExame() {
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("nome_cliente", dialogValues.nome_cliente);
      formData.append("cpf_cliente", dialogValues.cpf_cliente);
      formData.append("celular_cliente", dialogValues.celular_cliente);
      formData.append("usuario", usuario);
      formData.append("data", dialogValues.data);
      formData.append("observacoes", dialogValues.observacoes);
      formData.append("autorizacao", dialogValues.autorizacao);
      formData.append("autorizados", JSON.stringify(dialogValues.autorizados));
      formData.append("estado", dialogValues.estado);
      formData.append("comarca", dialogValues.comarca);
      formData.append("numero_processo", dialogValues.numero_processo);
      formData.append("id_horario", dialogValues.id_horario);
      formData.append("tipo", dialogValues.tipo_exame);
      formData.append("id_unidade", dialogValues.id_unidade);

      if (dialogValues.identificador !== "") {
        formData.append("identificador", dialogValues.identificador);
      }
      formData.append(
        "anexos_preservados",
        JSON.stringify(dialogValues.anexos.filter((a) => !a.name)) // se nao possuem esses campos, entao nao sao arquivos
      );
      Array.from(dialogValues.anexos)
        .filter((a) => a.name)
        .forEach((f) => formData.append("novos_anexos", f));

      await api.put(
        `/v2/agendaPaternidade/exame/${dialogValues.id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      atualizarInterface(dialogValues, "editar");
      cleanup();

      enqueueSnackbar("Exame atualizado com sucesso!", {
        variant: "success",
        autoHideDuration: 3000,
      });
    } catch (e) {
      let mensagem =
        "Não foi possível atualizar os dados deste exame. Por favor, tente novamente.";
      if (e.response && e.response.data) {
        mensagem = e.response.data;
      }
      enqueueSnackbar(mensagem, { variant: "error", autoHideDuration: 3000 });
    }
  }

  async function criarExame() {
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("nome_cliente", dialogValues.nome_cliente);
      formData.append("cpf_cliente", dialogValues.cpf_cliente);
      formData.append("celular_cliente", dialogValues.celular_cliente);
      formData.append("usuario", usuario);
      formData.append("data", dialogValues.data);
      formData.append("observacoes", dialogValues.observacoes);
      formData.append("autorizacao", dialogValues.autorizacao);
      formData.append("autorizados", JSON.stringify(dialogValues.autorizados));
      formData.append("estado", dialogValues.estado);
      formData.append("comarca", dialogValues.comarca);
      formData.append("numero_processo", dialogValues.numero_processo);
      formData.append("tipo", dialogValues.tipo_exame);
      formData.append("id_horario", dialogValues.id_horario);
      formData.append("id_unidade", dialogValues.id_unidade);

      if (dialogValues.identificador !== "") {
        formData.append("identificador", dialogValues.identificador);
      }

      Array.from(dialogValues.anexos).forEach((f) =>
        formData.append("anexos", f)
      );

      const { data: novoExame } = await api.post(
        "/v2/agendaPaternidade/exame",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      // atualizando a interface se o exame novo possuir data marcada para o intervalo definido
      atualizarInterface(novoExame, "criar");
      cleanup();
      enqueueSnackbar("Exame agendado!", {
        variant: "success",
        autoHideDuration: 3000,
      });
    } catch (e) {
      let mensagem =
        "Não foi possível agendar este exame. Por favor, tente novamente.";
      if (e.response && e.response.data && e.response.data.mensagem) {
        mensagem = e.response.data.mensagem;
      }
      enqueueSnackbar(mensagem, { variant: "error", autoHideDuration: 3000 });
    }
  }

  // main button do dialog, so aparece nos modos de editar ou criar exame
  const onMainButtonClick = async () => {
    try {
      validarCliente();
      validarHorarioExame();
      dialogValues.autorizados.forEach((autorizado, index) =>
        validarAutorizado(autorizado, index)
      );
    } catch (e) {
      return enqueueSnackbar(e.message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    }

    // se o exame selecionado eh -1, entao estamos CRIANDO um exame novo
    if (exameSelecionado === -1) {
      await criarExame();
    } else {
      await atualizarExame();
    }
    return setLoading(false);
  };
  return (
    <>
      {loading && <Loading />}
      <Dialog open={open} title="Agendar exame">
        <CloseIcon
          fontSize="large"
          className="close-dialog-icon"
          onClick={() => cleanup()}
          style={{
            position: "absolute",
            top: "8px",
            right: "8px",
            cursor: "pointer",
          }}
        />
        <input
          type="file"
          style={{ display: "none" }}
          ref={fileInputRef}
          onChange={adicionarAnexos}
          multiple
          disabled={modoVisualizacao}
          accept="image/*, .pdf"
        />
        <DataPickerAtualizado
          data={dayjs(dialogValues.data)}
          setData={(value) =>
            setDialogValues((prevState) => ({ ...prevState, data: value }))
          }
          variant="inline"
          labelName={"Data do exame"}
        />
        {/* <DatePicker
          disabled={modoVisualizacao || dialogValues.estado !== "agendado"}
          format="dd/MM/yyyy"
          label="Data do exame"
          style={{ marginBottom: ".75rem" }}
          value={dayjs(dialogValues.data)}
          disablePast={exameSelecionado === -1}
          onChange={(value) =>
            setDialogValues((prevState) => ({ ...prevState, data: value }))
          }
        /> */}
        <Grid container spacing={2}>
          <Grid item xs={12} sm={7} md={4}>
            <FormControl style={{ width: "100%" }}>
              <InputLabel id="label-unidade-exame">Unidade</InputLabel>
              <Select
                disabled={
                  modoVisualizacao || dialogValues.estado !== "agendado"
                }
                labelId="label-unidade-exame"
                value={dialogValues.id_unidade}
                variant="standard"
                onChange={(e) => {
                  // e.persist();
                  setDialogValues((prevState) => ({
                    ...prevState,
                    id_unidade: e.target.value,
                  }));
                }}
              >
                <MenuItem value={0} />
                {unidades.map((u) => (
                  <MenuItem key={u.id} value={u.id}>
                    {u.nome}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={5} md={4}>
            <FormControl style={{ width: "100%" }}>
              <InputLabel id="label-horario-exame">Horário do exame</InputLabel>
              <Select
                variant="standard"
                disabled={
                  modoVisualizacao || dialogValues.estado !== "agendado"
                }
                labelId="label-horario-exame"
                value={dialogValues.id_horario}
                onChange={(e) => {
                  // e.persist();
                  setDialogValues((prevState) => ({
                    ...prevState,
                    id_horario: e.target.value,
                  }));
                }}
              >
                {horarios.map((h) => {
                  const quantidadeVagas =
                    h.lotacao_maxima - h.quantidade_exames;
                  return (
                    <MenuItem value={h.id} key={h.id}>
                      {h.hora}:{h.minutos === 0 && "0"}
                      {h.minutos} ({quantidadeVagas} vaga
                      {quantidadeVagas !== 1 && "s"})
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={8} md={4}>
            <FormControl style={{ width: "100%" }}>
              <InputLabel id="label-tipo-exame">Tipo do exame</InputLabel>
              <Select
                variant="standard"
                disabled={
                  modoVisualizacao || dialogValues.estado !== "agendado"
                }
                labelId="label-tipo-exame"
                value={dialogValues.tipo_exame}
                onChange={(e) => {
                  // e.persist();
                  setDialogValues((prevState) => ({
                    ...prevState,
                    tipo_exame: e.target.value,
                  }));
                }}
              >
                <MenuItem value="paternidade">Paternidade</MenuItem>
                {Number(dialogValues.id_unidade) === 41 && (
                  <MenuItem value="reconstituição genética">
                    Reconstituição Genética
                  </MenuItem>
                )}
              </Select>
            </FormControl>
          </Grid>
          {exameSelecionado !== -1 && (
            <Grid xs={12} sm={5} md={4} item>
              <FormControl style={{ width: "100%" }}>
                <InputLabel id="label-estado-exame">Estado</InputLabel>
                <Select
                  variant="standard"
                  disabled={modoVisualizacao}
                  labelId="label-estado-exame"
                  value={dialogValues.estado}
                  onChange={(e) => {
                    // e.persist();
                    setDialogValues((prevState) => ({
                      ...prevState,
                      estado: e.target.value,
                    }));
                  }}
                >
                  <MenuItem value="agendado">Agendado</MenuItem>
                  <MenuItem value="extração">Aguardando extração</MenuItem>
                  <MenuItem value="coletado">Coletado</MenuItem>
                  <MenuItem value="análise">Em análise</MenuItem>
                  <MenuItem value="conferência">Digitação/Conferência</MenuItem>
                  <MenuItem value="concluído">Concluído</MenuItem>
                  <MenuItem value="entregue">Entregue</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          )}
          {exameSelecionado !== -1 &&
            checarPermissaoSemLog("AGP007", false) && (
              <Grid item xs={12} sm={5} md={4}>
                <TextField
                  variant="standard"
                  label="Nº exame"
                  style={{ width: "100%" }}
                  value={dialogValues.identificador}
                  disabled={modoVisualizacao}
                  onChange={(e) => {
                    // e.persist();
                    setDialogValues((prevState) => ({
                      ...prevState,
                      identificador: e.target.value,
                    }));
                  }}
                />
              </Grid>
            )}
          {(checarPermissaoSemLog("AGP007", false) ||
            exameSelecionado === -1) && (
            <>
              <Grid item xs={12} md={5}>
                <TextField
                  variant="standard"
                  disabled={modoVisualizacao}
                  label="Nome do cliente"
                  style={{ width: "100%" }}
                  value={dialogValues.nome_cliente}
                  onChange={(event) => {
                    // event.persist();
                    setDialogValues((prevState) => ({
                      ...prevState,
                      nome_cliente: event.target.value,
                    }));
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  variant="standard"
                  disabled={modoVisualizacao}
                  style={{ width: "100%" }}
                  label="CPF do cliente"
                  value={dialogValues.cpf_cliente}
                  onChange={(event) => {
                    // event.persist();
                    setDialogValues((prevState) => ({
                      ...prevState,
                      cpf_cliente: event.target.value,
                    }));
                  }}
                />
              </Grid>
            </>
          )}
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              variant="standard"
              style={{ width: "100%" }}
              disabled={modoVisualizacao}
              label="Celular"
              value={dialogValues.celular_cliente}
              onChange={(event) => {
                // event.persist();
                setDialogValues((prevState) => ({
                  ...prevState,
                  celular_cliente: event.target.value,
                }));
              }}
            />
          </Grid>
          <Grid item xs={12} style={{ marginTop: ".5rem" }}>
            <FormControl>
              <FormLabel>Autorizado(s) para pegar o resultado</FormLabel>
              <RadioGroup
                value={dialogValues.autorizacao}
                onChange={(event) => {
                  if (event.target.value === "cliente") {
                    return setDialogValues((prevState) => ({
                      ...prevState,
                      autorizacao: event.target.value,
                      autorizados: [],
                    }));
                  }
                  return setDialogValues((prevState) => ({
                    ...prevState,
                    autorizacao: event.target.value,
                    autorizados: [{ nome: "", cpf: "", celular: "" }],
                  }));
                }}
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  flexDirection: "row",
                }}
              >
                <FormControlLabel
                  disabled={modoVisualizacao}
                  value="cliente"
                  label="Somente o cliente"
                  control={<Radio />}
                />
                <FormControlLabel
                  disabled={modoVisualizacao}
                  value="outros"
                  label="Outros"
                  control={<Radio />}
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          {dialogValues.autorizacao === "outros" && (
            <>
              {dialogValues.autorizados.map((autorizado, index) => (
                <Grid
                  item
                  container
                  alignItems="center"
                  spacing={2}
                  xs={12}
                  className="autorizado"
                  key={`autorizado-${index + 1}`}
                  style={{ position: "relative", marginTop: ".5rem" }}
                >
                  {!modoVisualizacao && (
                    <CloseIcon
                      title="Remover autorizado"
                      style={{
                        position: "absolute",
                        top: "8px",
                        right: 0,
                        cursor: "pointer",
                      }}
                      onClick={() => removerAutorizado(index)}
                    />
                  )}
                  <Grid item xs={12} style={{ padding: "0 8px" }}>
                    <h2 style={{ fontSize: "1.2rem", fontWeight: 500 }}>
                      Autorizado {index + 1}
                    </h2>
                  </Grid>
                  <Grid item xs={12} md={5}>
                    <TextField
                      disabled={modoVisualizacao}
                      style={{ width: "100%" }}
                      className="nome"
                      label={`Nome autorizado ${index + 1}`}
                      value={dialogValues.autorizados[index].nome}
                      onChange={(event) => {
                        // event.persist();
                        atualizarAutorizado(event.target.value, index, "nome");
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      disabled={modoVisualizacao}
                      label={`CPF autorizado ${index + 1}`}
                      className="cpf"
                      style={{ width: "100%" }}
                      value={dialogValues.autorizados[index].cpf}
                      onChange={(event) => {
                        // event.persist();
                        atualizarAutorizado(event.target.value, index, "cpf");
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      disabled={modoVisualizacao}
                      label={`Celular autorizado ${index + 1}`}
                      className="celular"
                      style={{ width: "100%" }}
                      value={dialogValues.autorizados[index].celular}
                      onChange={(event) => {
                        // event.persist();
                        atualizarAutorizado(
                          event.target.value,
                          index,
                          "celular"
                        );
                      }}
                    />
                  </Grid>
                </Grid>
              ))}
              {!modoVisualizacao && (
                <Grid item xs={12} style={{ padding: "8px 0" }}>
                  <div
                    className="dialog-add-button"
                    onClick={() =>
                      setDialogValues((prevState) => ({
                        ...prevState,
                        autorizados: [
                          ...prevState.autorizados,
                          { nome: "", cpf: "", celular: "" },
                        ],
                      }))
                    }
                  >
                    <AddIcon fontSize="large" />
                    <span>Adicionar autorizado</span>
                  </div>
                </Grid>
              )}
            </>
          )}
          <Grid item xs={12} sm={7} md={5}>
            <TextField
              variant="standard"
              disabled={modoVisualizacao}
              label="Nº do processo"
              style={{ width: "100%" }}
              value={dialogValues.numero_processo}
              onChange={(e) => {
                // e.persist();
                const {
                  target: { value },
                } = e;
                setDialogValues((prevState) => ({
                  ...prevState,
                  numero_processo: value,
                }));
              }}
            />
          </Grid>
          <Grid item xs={12} sm={5} md={3}>
            <TextField
              variant="standard"
              disabled={modoVisualizacao}
              label="Comarca"
              style={{ width: "100%" }}
              value={dialogValues.comarca}
              onChange={(e) => {
                // e.persist();
                const {
                  target: { value },
                } = e;
                setDialogValues((prevState) => ({
                  ...prevState,
                  comarca: value,
                }));
              }}
            />
          </Grid>

          <Grid item xs={12} md={11}>
            <textarea
              className="dialog-textarea"
              disabled={modoVisualizacao}
              style={{ width: "100%" }}
              rows={8}
              placeholder="Observações"
              value={dialogValues.observacoes}
              onChange={(e) => {
                // e.persist();
                const {
                  target: { value },
                } = e;
                setDialogValues((prevState) => ({
                  ...prevState,
                  observacoes: value,
                }));
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <h2 style={{ fontSize: "1.2rem", fontWeight: 500 }}>Anexos</h2>
          </Grid>
          <Grid item xs={12} md={10}>
            <div className="anexos-dialog">
              {Array.from(dialogValues.anexos).map((file, index) => {
                // o titulo pode vir de um arquivo local quanto de um objeto json da API
                const tituloAnexo = file.name || file.nome;
                return (
                  <div className="anexo" key={file.name || file.id}>
                    <span
                      className="filename"
                      onClick={() => onClickAnexo(file)}
                    >
                      {tituloAnexo}
                    </span>
                    {file.nome && (
                      <span className="time">
                        {formatarData(new Date(file.criado_em))}
                      </span>
                    )}
                    {!modoVisualizacao && (
                      <CloseIcon
                        style={{ cursor: "pointer", marginLeft: "1rem" }}
                        onClick={() => removerAnexo(index)}
                      />
                    )}
                  </div>
                );
              })}
            </div>
            {!modoVisualizacao && (
              <div
                className="dialog-add-button"
                onClick={() => fileInputRef.current.click()}
              >
                <AddIcon fontSize="large" />
                <span>Adicionar anexo(s)</span>
              </div>
            )}
          </Grid>
        </Grid>
        <DialogActions>
          <Button secondary style={{ fontSize: "1.1rem" }} onClick={cleanup}>
            {modoVisualizacao ? "FECHAR" : "CANCELAR"}
          </Button>
          {!modoVisualizacao && (
            <Button
              secondary
              style={{ fontSize: "1.1rem" }}
              onClick={onMainButtonClick}
            >
              {exameSelecionado === -1 ? "AGENDAR" : "SALVAR"}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
}
