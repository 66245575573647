import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";

import Header from "../../components/Header";
import Abas from "./Componentes/Abas";
import useUser from "../../hooks/useUser";

import Recebimento from "./Recebimento";
import FaturamentoTabela from "./FaturamentoTabela";
import BI from "./BI";

import { IntervaloDataContextProvider } from "./Context/IntevervaloDataContext";
import { Context as PermissoesContext } from "../../Context/PermissoesContext";

import { api } from "../../services/Api";

import "./styles.css";
import { RecebimentoContextProvider } from "./Recebimento/Context/RecebimentoContext";
import { ModalRecebimentoContextProvider } from "./Recebimento/Context/ModalRecebimentoContext";

export default function Faturamento() {
  const history = useHistory();
  const usuario = useUser();

  const { checarPermissaoComAcao } = useContext(PermissoesContext);

  useEffect(() => {
    async function inicio() {
      try {
        const {
          data: {
            permitido: {
              0: { id },
            },
          },
        } = await api.get(
          `/v2/controleAcesso/permissaoVisualizaModulo?username=${usuario}&&codigo=FAT001`
        );

        return id;
      } catch (e) {
        return history.push("/home");
      }
    }
    inicio();
  }, [history, usuario]);

  const [tab, setTab] = useState(3);

  const handleTabChange = (newValue) => {
    const n = newValue + 3;
    return checarPermissaoComAcao(`FAT00${n}`, () => setTab(n));
  };

  return (
    <>
      <Header titulo="Faturamento" />
      <IntervaloDataContextProvider>
        <ModalRecebimentoContextProvider>
          <RecebimentoContextProvider>
            <div className="container-central-faturamento">
              <main className="container-abas-faturamento">
                <Abas
                  tabs={["Faturamento", "Recebimento", "BI"]}
                  value={tab}
                  handleChange={(newValue) => handleTabChange(newValue)}
                />
              </main>
              {tab === 3 && <FaturamentoTabela />}
              {tab === 4 && <Recebimento />}
              {tab === 5 && <BI />}
            </div>
          </RecebimentoContextProvider>
        </ModalRecebimentoContextProvider>
      </IntervaloDataContextProvider>
    </>
  );
}
