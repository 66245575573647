import React from 'react';

import "./styles.css";

export default function Abas(props) {
  const { tabs, value, handleChange } = props;
  return (
    <div style={{display: "flex", flexDirection: "rows"}}>
      {tabs.map((t, i) => (
        <button
          key={t}
          type="button"
          className={(Number(value) === (Number(i)+3)) ? "aba-faturamento-on" : "aba-faturamento"}
          onClick={() => handleChange(i)}
        >
          {t}
        </button>
      ))}
    </div>
  )
}
