import { Grid as MuiGrid } from "@mui/material";
import React from "react";

export default function GridContainer({ children, ...rest }) {
  return (
    <MuiGrid {...rest} container>
      {children}
    </MuiGrid>
  );
}
