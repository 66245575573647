import React, { useState, useMemo, useEffect } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from '@mui/material';
import { useHistory, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import PublishIcon from "@mui/icons-material/Publish";
import AddIcon from "@mui/icons-material/Add";

import Header from "../../../../../components/Header";
import Input from "../../../../../components/Input";
import Button from "../../../../../components/Button";
import SearchModal from "../../../components/SearchModal";
import Galeria from "../../../components/Galeria";
import Video from "../../../components/Video";
import Loading from "../../../../../components/Loading";

import Pill from "../../../components/Pill";

import assert from "../../../../../utils/assert";

import { api } from "../../../../../services/Api";

export default function EditorPost() {
  const [values, setValues] = useState({
    especialidades: [],
    corpo: "",
    titulo: "",
    tipo_midia: "nenhum",
    video: null,
    capa_video: null,
    galeria: [],
  });
  const [mode, setMode] = useState("");
  const [loading, setLoading] = useState(true);
  const [showSpecialtiesModal, setShowSpecialtiesModal] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const { id } = useParams();

  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          [{ header: [false] }],
          ["bold", "italic", "underline"],
          [{ list: "ordered" }, { list: "bullet" }],
          ["link"],
        ],
      },
    }),
    []
  );

  const setField = (field, value) => {
    setValues(prev => ({
      ...prev,
      [field]: value,
    }));
  };

  useEffect(() => {
    (async () => {
      if (id) {
        setMode("edit");
        try {
          const { data } = await api.get(`/v2/portalMedico/post/${id}`);
          setValues({
            ...data,
            galeria: data.tipo_midia === "galeria" ? data.midias : [],
            video: data.tipo_midia === "video" ? data.midias[0] : null,
            capa_video: data.url_capa_video,
          });
        } catch (e) {
          enqueueSnackbar("Não foi possível recuperar os dados deste post.", {
            variant: "error",
          });
          return history.push("/portalMedico");
        }
      } else {
        setMode("create");
      }
      return setLoading(false);
    })();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (values.tipo_midia === "galeria") {
      setField("video", null);
    } else {
      setField("galeria", []);
    }
  }, [values.tipo_midia]);

  const addSpecialties = newSpecialties => {
    const actualNewOnes = newSpecialties.filter(
      el => !values.especialidades.find(e2 => e2.id === el.id)
    );
    const result = [...values.especialidades, ...actualNewOnes];
    setField("especialidades", result);
    setShowSpecialtiesModal(false);
  };

  const removeSpecialty = index => {
    const copy = [...values.especialidades];
    copy.splice(index, 1);
    setField("especialidades", copy);
  };

  const addImages = newImages => {
    setField("galeria", [...values.galeria, ...newImages]);
  };

  const removeImage = index => {
    const copy = [...values.galeria];
    copy.splice(index, 1);
    setField("galeria", copy);
  };

  const validatePost = () => {
    if (values.corpo === "" || values.corpo === "<p><br></p>") {
      assert(
        values.tipo_midia !== "nenhum" &&
          (values.galeria.length > 0 || values.video !== null),
        "Seu post não tem corpo nem mídia. Forneça pelo menos um dos dois."
      );
    }
    assert(
      (values.tipo_midia === "galeria" && values.galeria.length > 0) ||
        values.tipo_midia === "nenhum" ||
        (values.tipo_midia === "video" && values.video !== null),
      "Um tipo de mídia foi selecionado mas nenhum arquivo foi fornecido."
    );
    assert(
      values.especialidades.length > 0,
      "Escolha pelo menos uma especialidade para recomendar este post."
    );
  };

  const createPost = async () => {
    const formData = new FormData();
    if (values.titulo !== "") {
      formData.append("titulo", values.titulo);
    }
    formData.append("corpo", values.corpo === "<p><br/></p>" ? "" : values.corpo);
    formData.append("tipo_midia", values.tipo_midia);
    formData.append("estado", "publicado");
    formData.append("especialidades", JSON.stringify(values.especialidades));
    if (values.capa_video !== null) {
      formData.append("capa_video", values.capa_video);
    }
    formData.append("midias", values.video);
    for (let i = 0; i < values.galeria.length; i += 1) {
      formData.append("midias", values.galeria[i]);
    }

    try {
      await api.post("/v2/portalMedico/post", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      enqueueSnackbar("Post criado com sucesso!", { variant: "success" });
      history.push("/portalMedico");
    } catch (e) {
      enqueueSnackbar(
        "Não foi possível criar este post. Por favor, tente novamente mais tarde.",
        { variant: "error" }
      );
      setLoading(false);
    }

    return "";
  };

  const editPost = async () => {
    const formData = new FormData();
    formData.append("titulo", values.titulo);
    formData.append("corpo", values.corpo === "<p><br/></p>" ? "" : values.corpo);
    formData.append("tipo_midia", values.tipo_midia);
    formData.append("estado", "publicado");
    formData.append("especialidades", JSON.stringify(values.especialidades));

    const midias_preservadas = [];
    if (values.tipo_midia === "galeria") {
      /* Se estamos falando de uma galeria, entao basta iterar pelo array de imagens e separar as imagens
      novas das antigas */
      values.galeria.forEach(img => {
        if (img.url) {
          midias_preservadas.push(img);
        } else {
          formData.append("novas_midias", img);
        }
      });
    } else if (values.tipo_midia === "video") {
      /* Se estamos falando de um video, basta ver se eh um arquivo ou nao */
      if (values.video && values.video.url) {
        midias_preservadas.push(values.video);
      } else if (values.video && !values.video.url) {
        formData.append("novas_midias", values.video);
      }
    }
    /* Devemos fazer o mesmo para a capa do vídeo:
    Se o campo nova_capa for fornecido, o novo arquivo funcionará como a capa.
    Se o campo preservar_capa (boolean) for fornecido, nada será mudado.
    Se os dois campos forem fornecidos, o campo nova_capa terá precedência e a capa atual será substituída.
    Se nenhum dos dois campos forem fornecidos e o vídeo possuir um capa, a capa do vídeo será removida */

    if (typeof values.capa_video === "string") {
      formData.append("preservar_capa", true);
    } else if (values.capa_video !== null) {
      formData.append("nova_capa", values.capa_video);
    }
    formData.append("midias_preservadas", JSON.stringify(midias_preservadas));

    try {
      await api.put(`/v2/portalMedico/post/${id}`, formData, {
        headers: {
          "content-type": "multipart/form-data",
        },
      });
      enqueueSnackbar("Post editado!", { variant: "success" });
      history.push("/portalMedico");
    } catch (e) {
      enqueueSnackbar("Não foi possível editar este post. Por favor, tente novamente", {
        variant: "error",
      });
      setLoading(false);
    }
  };

  // eslint-disable-next-line consistent-return
  const onSubmit = async () => {
    try {
      validatePost();
    } catch (e) {
      return enqueueSnackbar(e.message, { variant: "error" });
    }

    setLoading(true);
    if (mode === "create") {
      await createPost();
    } else {
      await editPost();
    }
  };

  return (
    <>
      {loading && <Loading />}
      <Header titulo="Editar post" />
      <SearchModal
        open={showSpecialtiesModal}
        onClose={() => setShowSpecialtiesModal(false)}
        onConfirm={addSpecialties}
      />
      <main className="editor-portal-medico-container" style={{ marginBottom: "4rem" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: "1rem",
          }}
        >
          <ArrowBackIcon
            onClick={() => history.push("/portalMedico")}
            fontSize="large"
            style={{ color: "#003399", cursor: "pointer" }}
          />
          <Button style={{ fontSize: "1rem" }} onClick={onSubmit}>
            Publicar <PublishIcon style={{ marginLeft: "10px" }} />
          </Button>
        </div>
        <section className="editor-card shadow-md">
          <Input
            placeholder="Título para seu post (opcional)"
            inputStyles={{ fontSize: "1.5rem", fontWeight: "semibold" }}
            value={values.titulo}
            onChange={val => setField("titulo", val)}
          />
          <FormControl style={{ minWidth: "200px", marginTop: "1rem" }}>
            <InputLabel id="tipo-midia-label">Tipo de mídia</InputLabel>
            <Select
              labelId="tipom-midia-label"
              value={values.tipo_midia}
              onChange={e => {
                const {
                  target: { value },
                } = e;
                setField("tipo_midia", value);
              }}
            >
              <MenuItem value="nenhum">Nenhum</MenuItem>
              <MenuItem value="galeria">Galeria de imagens</MenuItem>
              <MenuItem value="video">Vídeo</MenuItem>
            </Select>
            <FormHelperText>
              Selecione um tipo para poder selecionar arquivos
            </FormHelperText>
          </FormControl>
          <div style={{ width: "100%" }}>
            {values.tipo_midia === "galeria" && (
              <Galeria
                addImages={addImages}
                images={values.galeria}
                onRemove={removeImage}
              />
            )}
            {values.tipo_midia === "video" && (
              <>
                <Video
                  video={values.video}
                  thumbnail={values.capa_video}
                  onVideoChange={vid => setField("video", vid)}
                  onVideoRemove={() => setField("video", null)}
                  onThumbnailChange={cover => setField("capa_video", cover)}
                  onThumbnailRemove={() => setField("capa_video", null)}
                />
              </>
            )}
          </div>

          <h2 className="areas-title" style={{ marginTop: "1.5rem" }}>
            Especialidades
          </h2>
          <div className="areas-container">
            {values.especialidades.map((specialty, index) => (
              <Pill
                key={specialty.id}
                style={{ marginRight: ".5rem", marginBottom: ".5rem" }}
                onClose={() => removeSpecialty(index)}
              >
                {specialty.nome}
              </Pill>
            ))}
            <div
              className="add-area-button"
              onClick={() => setShowSpecialtiesModal(true)}
            >
              <AddIcon style={{ color: "#003399" }} />
              <p>Adicionar especialidades</p>
            </div>
          </div>
        </section>

        <section className="editor-card shadow-md" style={{ marginTop: "1rem" }}>
          <ReactQuill
            modules={modules}
            value={values.corpo}
            onChange={val => setField("corpo", val)}
          />
        </section>
      </main>
    </>
  );
}
