import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Container, Paper, Button } from "@mui/material";
import { useSnackbar } from "notistack";

import Header from "../../components/Header";
import useUser from "../../hooks/useUser";
import ImagemUsuario from "./ImagemPerfil";
import UsuarioPerfil from "./UsuarioPerfil";

import SenhaPerfil from "./SenhaPerfil";
import DadosContext from "./DadosContext";

import { colorTheme } from "../../theme";
import DadosEmpresa from "./DadosEmpresa";

import { api } from "../../services/Api";

import "./styles.css";


export default function Perfil() {
  const history = useHistory();
  const usuario = useUser();

  const [idUsuario, setIdUsuario] = useState(null);
  const [idFuncionario, setIdFuncionario] = useState(null);
  const [preview, setPreview] = useState(null);
  const [image, setImage] = useState(null);

  const [senha, setSenha] = useState("");

  const [nome, setNome] = useState("");
  const [email, setEmail] = useState("");
  const [unidadeSelecionada, setUnidadeSeleciona] = useState("");
  const [ramalSelecionado, setRamalSelecionado] = useState("");
  const [alterarSenha, setAlterarSenha] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    async function chamaFuncao() {
      try {
        const resultFuncionario = await api.get("/v2/controleAcesso/funcionario", {
          params: { cod_usu: usuario },
        });

        const resultUsuario = await api.get("/v2/controleAcesso/usuario", {
          params: { username: usuario },
        });

        setUnidadeSeleciona(resultFuncionario.data.funcionario.unidade_id);
        setRamalSelecionado(resultFuncionario.data.funcionario.ramal);

        setIdFuncionario(resultFuncionario.data.funcionario.id);
        setIdUsuario(resultUsuario.data.usuario.id);

        setNome(resultFuncionario.data.funcionario.nome_usu);
        setEmail(resultFuncionario.data.funcionario.email_usu);

        const resultImage = await api.get("/v2/controleAcesso/avatar", {
          params: { username: usuario },
          responseType: "blob",
        });

        if (resultImage.status !== 202) {
          setPreview(URL.createObjectURL(resultImage.data));
        }
      } catch (error) {
        enqueueSnackbar("Falha ao obter os dados", {
          variant: "error",
        });
      }
    }
    chamaFuncao();
  }, [enqueueSnackbar, usuario]);

  async function atualizaDados() {
    try {
      const formData = new FormData();

      if (image) {
        formData.append("avatar_url", image, `${usuario}-avatar.jpg`);
      }
      formData.append("email_usu", email);
      formData.append("nome_usu", nome);
      formData.append("password", senha);
      formData.append("funcionario_id", idFuncionario);
      if (ramalSelecionado) {formData.append("ramal", ramalSelecionado);}

      if (senha) {
        formData.append("senha", senha);
      }

      await api.put(`/v2/controleAcesso/usuario/${idUsuario}`, formData);
      setAlterarSenha(false);
      enqueueSnackbar("Seus dados foram atualizados com sucesso", {
        variant: "success",
      });
    } catch (error) {
      enqueueSnackbar("Falha ao atualizar os dados, tente novamente", {
        variant: "error",
      });
    }
  }

  return (
    <>
      <Header titulo="Dados cadastrais" usuario={usuario} history={history} />
      <DadosContext.Provider
        value={{
          senha,
          setSenha,
          nome,
          setNome,
          email,
          setEmail,
          preview,
          setPreview,
          image,
          setImage,
          unidadeSelecionada,
          setUnidadeSeleciona,
          ramalSelecionado,
          setRamalSelecionado,
          alterarSenha,
          setAlterarSenha,
        }}
      >
        <form encType="multipart/form-data">
          <Container maxWidth="md" style={{ padding: "0" }}>
            <Paper id="paper" elevation={3}>
              <ImagemUsuario />
            </Paper>
            <Paper id="paper" elevation={3}>
              <DadosEmpresa />
            </Paper>
            <Paper id="paper" elevation={3}>
              <UsuarioPerfil />
            </Paper>
            <Paper id="paper" elevation={3}>
              <SenhaPerfil />
            </Paper>
            <Button
              sx={{ backgroundColor: colorTheme.primary[500] }}
              id="submit-button"
              variant="contained"
              component="span"
              type="submit"
              onClick={() => atualizaDados()}
            >
              Salvar alterações
            </Button>
          </Container>
        </form>
      </DadosContext.Provider>
    </>
  );
}
