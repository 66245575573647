import React, { useState, useEffect } from "react";

import { Grid } from '@mui/material';
import { useSnackbar } from "notistack";
import { api } from "../../../../services/Api";
import { Select } from "../../../../componentsv2/Select";

export default function SelectSetor({
  onChange,
  value,
  disabled = false,
  required = false,
}) {
  const [setores, setSetores] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    async function pegaSetores() {
      try {
        const getSetores = await api.get("/v2/arh/setores");
        setSetores(getSetores.data.setores);
      } catch (error) {
        enqueueSnackbar("Erro! Não foi possível buscar os setores.", {
          variant: "error",
        });
      }
    }
    pegaSetores();
  }, [enqueueSnackbar]);

  return (
    <>
      <Grid container spacing={1} justify="center" alignItems="stretch">
        <Grid item xs={12}>
          <Select
            required={required}
            disabled={disabled}
            onChange={onChange}
            value={value}
            label="Setor"
            variant="standard"
            arrayOptions={setores}
            textAttribute="nome"
            valueAttribute="id"
          />
        </Grid>
      </Grid>
    </>
  );
}
