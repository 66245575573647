import { Grid, Paper } from "@mui/material";

import TabelaComponent from "../../../../componentsv2/Tabela";
import { useSnackbar } from "notistack";
import React, { useContext, useEffect, useState } from "react";
import { api } from "../../../../services/Api";
import filtroPesquisa from "../../../../utils/filtroPesquisa";

import Opcoes from "./opcoes";
import useDadosContextEstoque from "../../hooks/useDadosContextEstoque";

export default function Table() {
  const { enqueueSnackbar } = useSnackbar();
  const {
    localOrigem,
    localDestino,
    status,
    dataInicialTransferencias,
    dataFinalTransferencias,
  } = useDadosContextEstoque();
  const [rows, setRows] = useState([]);

  useEffect(() => {
    async function obterTransacoes() {
      try {
        const inicialFormatada = new Date(dataInicialTransferencias);
        const finalFormatada = new Date(dataFinalTransferencias);

        const result = await api.get("/v2/almoxarifado/transferencias", {
          params: { dataInicial: inicialFormatada, dataFinal: finalFormatada },
        });

        setRows(result.data.todasAsTransferencias);
      } catch (error) {
        enqueueSnackbar("Falha ao obter dados", {
          variant: "error",
        });
      }
    }
    obterTransacoes();
  }, [enqueueSnackbar, dataInicialTransferencias, dataFinalTransferencias]);
  const columns = [
    {
      field: "id",
      headerName: "Nº",
      headerAlign: "center",
      align: "center",
      // width: 100,
      hide: true,
    },
    {
      field: "requisicao_fortes",
      headerName: "Nº",
      headerAlign: "center",
      align: "center",
      // width: 120,
      type: "number",
    },
    {
      field: "estoque_origem",
      headerName: "Remetente",
      headerAlign: "center",
      align: "center",
      flex: 1,
      // width: 180,
    },
    {
      field: "estoque_destino",
      headerName: "Destinatário",
      headerAlign: "center",
      align: "center",
      flex: 1,
      // width: 180,
    },
    {
      field: "status",
      headerName: "Status",
      headerAlign: "center",
      align: "center",
      width: 200,
    },
    {
      field: "data_envio",
      headerName: "Data Envio",
      headerAlign: "center",
      width: 150,
      align: "center",
      valueFormatter: ({ value }) =>
        value && new Date(value).toLocaleDateString(),
      // width: 220,
      type: "dateTime",
    },
    {
      field: "data_recebimento",
      headerName: "Data Recebimento",
      headerAlign: "center",
      align: "center",
      width: 200,
      // flex: 1,
      valueFormatter: ({ value }) =>
        value && new Date(value).toLocaleDateString(),
    },
    {
      field: "opcoes",
      headerName: "Opções",
      headerAlign: "center",
      align: "center",
      width: 100,
      // flex: 1,
      type: "number",
      editable: false,
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (params) => (
        <div style={{ cursor: "pointer" }}>
          <Opcoes row={params.row} />
        </div>
      ),
    },
  ];

  const origemFiltrado = filtroPesquisa(rows, "unidade_origem_id", localOrigem);
  const destinoFiltrado = filtroPesquisa(
    origemFiltrado,
    "unidade_destino_id",
    localDestino
  );
  const filtrado = filtroPesquisa(destinoFiltrado, "status", status);
  return (
    <Grid spacing={2} container>
      <Grid item xs={12} sm={12}>
        <Paper elevation={2}>
          <div style={{ height: 600, width: "100%" }}>
            <TabelaComponent
              rows={filtrado}
              columns={columns}
              autoPageSize
              linhaNaoSelecionavel={false}
              initialState={{
                columns: {
                  columnVisibilityModel: {
                    id: false,
                  },
                },
              }}
            />
          </div>
        </Paper>
      </Grid>
    </Grid>
  );
}
