import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  Button,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Header from "../../../../components/Header";
import useUser from "../../../../hooks/useUser";
import { api } from "../../../../services/Api";
import { colorTheme } from "../../../../theme";
import SalvaLog from "../../../../utils/SalvaLog";
import { Context as PermissoesContext } from "../../../../Context/PermissoesContext";
import Tabela from "../../../../componentsv2/Tabela";
import useDadosContextEstoque from "../../hooks/useDadosContextEstoque";

export default function EditaTransferencia() {
  const history = useHistory();
  const location = useLocation();
  const usuario = useUser();
  const { enqueueSnackbar } = useSnackbar();
  const { checarPermissao } = useContext(PermissoesContext);

  const theme = useTheme();
  const tamanho = useMediaQuery(theme.breakpoints.up("md"));

  const [numero, setNumero] = useState(null);
  const [rows, setRows] = useState([]);
  const [unidades, setUnidades] = useState([]);
  const [unidadeOrigem, setUnidadeOrigem] = useState("");
  const [unidadeDestino, setUnidadeDestino] = useState("");
  const [status, setStatus] = useState("");
  const [usuarioSolicitante, setUsuarioSolicitante] = useState("");
  const [usuarioRecebimento, setUsuarioRecebimento] = useState("");
  const [permissaoCancelar, setPermissaoCancelar] = useState(false);
  const [numeroFortes, setNumeroFortes] = useState(null);

  const [estoqueOrigem, setEstoqueOrigem] = useState("");
  const [estoqueDestino, setEstoqueDestino] = useState("");

  const [idEstoqueOrigem, setIdEstoqueOrigem] = useState("");
  const [idEstoqueDestino, setIdEstoqueDestino] = useState("");
  const { update, setUpdate } = useDadosContextEstoque();

  const edicaoTransferencia = location.state;

  async function checaPermissaoCancelarTransferencia() {
    if (await checarPermissao("AL015")) {
      setPermissaoCancelar(true);
    }
  }

  useEffect(() => {
    async function obterUnidadesLocal() {
      try {
        const result = await api.get("/v2/geral/unidade");
        setUnidades(result.data.unidade);
      } catch (e) {
        enqueueSnackbar("Erro ao buscar unidades", { variant: "error" });
      }
    }
    async function obterProdutos() {
      try {
        const result = await api.get(
          `/v2/almoxarifado/transferencia/produto/obter/${edicaoTransferencia.dados.id}`
        );

        setRows(result.data.obtemProdutosTransferencia);
      } catch (e) {
        enqueueSnackbar("erro ao obter dados dos produtos", {
          variant: "error",
        });
      }
    }
    obterUnidadesLocal();

    if (edicaoTransferencia) {
      setNumero(edicaoTransferencia.dados.id); //id_transferencia
      setUnidadeOrigem(edicaoTransferencia.dados.unidade_origem_id);
      setUnidadeDestino(edicaoTransferencia.dados.unidade_destino_id);
      setStatus(edicaoTransferencia.dados.status);
      setUsuarioSolicitante(edicaoTransferencia.dados.usuario_solicitante);
      setUsuarioRecebimento(edicaoTransferencia.dados.usuario_recebimento);
      setNumeroFortes(edicaoTransferencia.dados.requisicao_fortes);
      setEstoqueOrigem(edicaoTransferencia.dados.estoque_origem_nome);
      setEstoqueDestino(edicaoTransferencia.dados.estoque_destino_nome);
      setIdEstoqueOrigem(edicaoTransferencia.dados.estoque_origem_id);
      setIdEstoqueDestino(edicaoTransferencia.dados.estoque_destino_id);
      obterProdutos();
    }

    checaPermissaoCancelarTransferencia();

    // eslint-disable-next-line
  }, [enqueueSnackbar]);
  const columns = [
    {
      field: "id",
      headername: "id",
      headerAlign: "center",
      hide: true,
    },
    {
      field: "nome",
      headerName: "Nome",
      flex: 1,
      headerAlign: "center",
      align: "center",
      valueGetter: (params) => params.row?.produtoEstoque?.produto?.nome,
    },
    {
      field: "codigo_produto",
      headerName: "Cod. Produto",
      flex: 1,
      headerAlign: "center",
      align: "center",
      valueGetter: (params) =>
        params.row?.produtoEstoque?.produto?.codigo_produto,
    },
    {
      field: "quantidade",
      headerName: "Quantidade",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
  ];

  const handleSelect = async (e) => {
    setUnidadeDestino(e);

    try {
      await api.put(`/v2/almoxarifado/transacao/destinatario/${numero}`, {
        id_local_destinatario: e,
      });
      SalvaLog(`Unidade de destino alterada`, "Almoxarifado", numero);
      enqueueSnackbar("Unidade de destino alterada", { variant: "success" });
    } catch (error) {
      enqueueSnackbar("Unidade de destino não foi alterada, tente novamente", {
        variant: "error",
      });
    }
  };

  const finalizaTranferencia = async () => {
    try {
      await api.post("/v2/almoxarifado/transferencia/finalizar", {
        id_transferencia: numero,
        id_unidade_destino: idEstoqueDestino,
        id_unidade_origem: idEstoqueOrigem,
        todosOsProdutos: rows,
      });
      SalvaLog(`Transferência concluída`, "Almoxarifado", numero);
      enqueueSnackbar("Transferência concluída com sucesso", {
        variant: "success",
      });
      setUpdate(!update);
      history.push("/almoxarifado");
    } catch (e) {
      enqueueSnackbar("Erro ao concluir a transferência", { variant: "error" });
    }
  };

  const cancelaTransferencia = async () => {
    try {
      await api.post("/v2/almoxarifado/transferencia/cancelar", {
        id_transferencia: numero,
      });
      SalvaLog(`Transferência cancelada`, "Almoxarifado", numero);
      enqueueSnackbar("Transferência cancelada com sucesso", {
        variant: "success",
      });
      history.push({
        pathname: "/almoxarifado",
        state: { tab: true },
      });
    } catch (e) {
      enqueueSnackbar("Erro ao concluir a transferência", { variant: "error" });
    }
  };

  return (
    <>
      <Header
        titulo="Editar Transferência"
        usuario={usuario}
        history={history}
      />
      <Container maxWidth="lg" sx={{ px: 2 }}>
        <Button
          onClick={() =>
            history.push({
              pathname: "/almoxarifado",
              state: { tab: true },
            })
          }
        >
          <ArrowBackIcon style={{ color: "#003399" }} />
        </Button>
        <Paper style={{ padding: "40px", marginTop: "10px" }} elevation={2}>
          <Grid spacing={4} container>
            <Grid item xs={12} sm={12}>
              <h2>
                Transferência Nº{" "}
                <label style={{ fontWeight: "normal" }}>{numeroFortes}</label>
              </h2>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Estoque de origem"
                value={estoqueOrigem}
                variant="standard"
                disabled
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Estoque de destino"
                value={estoqueDestino}
                variant="standard"
                disabled
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl
                defaultValue=" "
                size="small"
                variant="standard"
                fullWidth
              >
                <InputLabel id="select-label">Unidade de origem</InputLabel>
                <Select
                  value={unidadeOrigem}
                  disabled
                  labelId="select-label"
                  id="select"
                  label="Ramal"
                >
                  {unidades.map((el) => (
                    <MenuItem key={el.id} value={el.id}>
                      {el.nome}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl
                defaultValue=" "
                size="small"
                variant="standard"
                fullWidth
              >
                <InputLabel id="select-label">Unidade de destino</InputLabel>
                <Select
                  value={unidadeDestino}
                  disabled
                  onChange={(e) => handleSelect(e.target.value)}
                  labelId="select-label"
                  id="select"
                  label="Ramal"
                >
                  {unidades.map((el) => (
                    <MenuItem key={el.id} value={el.id}>
                      {el.nome}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <h3>
                Usuário solicitante:{" "}
                <label style={{ fontWeight: "normal" }}>
                  {usuarioSolicitante}
                </label>
              </h3>
            </Grid>
            <Grid item xs={12} sm={6}>
              <h3>
                Usuário recebimento:{" "}
                <label style={{ fontWeight: "normal" }}>
                  {usuarioRecebimento}
                </label>
              </h3>
            </Grid>
            <Grid item xs={12} sm={12}>
              <h3>Produtos enviados:</h3>
            </Grid>
            <Grid item xs={12} sm={12}>
              <div style={{ height: 300, width: "100%" }}>
                <Tabela
                  rows={rows}
                  columns={columns}
                  autoPageSize
                  initialState={{
                    columns: {
                      columnVisibilityModel: {
                        id: false,
                      },
                    },
                  }}
                />
              </div>
            </Grid>
            <Grid
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
              item
              xs={12}
              sm={12}
            >
              {status === "Pendente" ||
                (status === "Enviado" && permissaoCancelar && (
                  <Button
                    onClick={cancelaTransferencia}
                    style={{ color: colorTheme.danger[500] }}
                    size={tamanho ? "medium" : "small"}
                  >
                    Cancelar transferência
                  </Button>
                ))}

              {status === "Enviado" && rows.length !== 0 && (
                <Button
                  onClick={finalizaTranferencia}
                  style={{ color: colorTheme.success[500] }}
                  size={tamanho ? "medium" : "small"}
                >
                  Concluir transferência
                </Button>
              )}
            </Grid>
          </Grid>
        </Paper>
      </Container>
    </>
  );
}
