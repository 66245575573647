import React, { createContext, useState, useEffect } from "react";

const ModalRecebimentoContext = createContext();

function ModalRecebimentoContextProvider({ children }) {
  const [modalGerenciaLote, setModalGerenciaLote] = useState(false);
  const [adicionaRecebimento, setAdicionaRecebimento] = useState(false);

  const [open, setOpen] = useState(false);

  const [id_lote, setId_lote] = useState("");
  const [protocolo, setProtocolo] = useState("");
  const [acao, setAcao] = useState("");
  const [id_retorno_lote, setId_retorno_lote] = useState("");
  const [informacoesLote, setInformacoesLote] = useState([]);
  const [notas, setNotas] = useState([]);

  const [opcoes, setOpcoes] = useState(false);
  const [opcoesGerenciaLote, setOpcoesGerenciaLote] = useState(false);
  const [modalGlosasLotes, setModalGlosasLotes] = useState(false);

  const [tituloModal, setTituloModal] = useState("Gerenciar Notas Fiscais");

  const [loading, setLoading] = useState(false);

  const [dadosRetornoLoteRow, setDadosRetornoLoteRow] = useState({});

  useEffect(() => {
    if (notas.length > 0) {
      setOpen(true);
    }
  }, [notas]);

  // Fecha modal de opções da tabela de recebimento
  function fechaModalOpcoes() {
    setOpcoes(false);
    setInformacoesLote([]);
    setId_lote("");
  }

  function retornarParaLotes() {
    setInformacoesLote([]);
    setId_lote("");
    setAdicionaRecebimento(false);
    setProtocolo("");
    setTituloModal("Gerenciar Notas Fiscais");
  }

  // Fecha tela do gerenciamento de lotes
  function fechaTelaGerenciaLotes() {
    setInformacoesLote([]);

    setAdicionaRecebimento(false);
    setProtocolo("");
    setTituloModal("Gerenciar Notas Fiscais");
  }

  // Fecha modal de opções do modal de gerenciamento lote
  function fechaModalOpcoesGerenciaLote() {
    setOpcoesGerenciaLote(false);
    setId_retorno_lote("");
    setDadosRetornoLoteRow({});
  }

  // Funçao de click do botão de adiciona ou retorna para gerenciamento de Notas Fiscais
  function adicionaOuRetorna(tipo) {
    setOpcoesGerenciaLote(false);
    setProtocolo(informacoesLote.protocolo);
    setAcao(tipo);
    setAdicionaRecebimento((prevState) => !prevState);
    setTituloModal((prevState) =>
      prevState === "Gerenciar Notas Fiscais"
        ? "Adicionar Notas Fiscais"
        : "Gerenciar Notas Fiscais"
    );
  }

  function fechaModalGlosasLotes() {
    setModalGlosasLotes(false);
    setId_lote("");
  }

  return (
    <ModalRecebimentoContext.Provider
      value={{
        id_lote,
        setId_lote,
        protocolo,
        id_retorno_lote,
        setId_retorno_lote,
        notas,
        setNotas,
        modalGerenciaLote,
        setModalGerenciaLote,
        adicionaRecebimento,
        setAdicionaRecebimento,
        opcoes,
        setOpcoes,
        opcoesGerenciaLote,
        setOpcoesGerenciaLote,
        informacoesLote,
        setInformacoesLote,
        tituloModal,
        setTituloModal,
        fechaModalOpcoes,
        fechaModalOpcoesGerenciaLote,
        fechaTelaGerenciaLotes,
        modalGlosasLotes,
        setModalGlosasLotes,
        fechaModalGlosasLotes,
        adicionaOuRetorna,
        loading,
        setLoading,
        open,
        setOpen,
        acao,
        setAcao,
        retornarParaLotes,
        setDadosRetornoLoteRow,
        dadosRetornoLoteRow,
      }}
    >
      {children}
    </ModalRecebimentoContext.Provider>
  );
}

export { ModalRecebimentoContext, ModalRecebimentoContextProvider };
