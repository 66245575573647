import React from "react";
import { HStack } from "../../../../componentsv2/Stack";
import Cabecalho from "./Components/Cabecalho";
import TabelaMedicos from "./Components/TabelaMedicos";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export default function MedicosCare() {
  const history = useHistory();
  return (
    <HStack>
      <Cabecalho
        onClick={() => history.push("/agendaCare/CadastrarMedico")}
      />
      <TabelaMedicos />
    </HStack>
  );
}
