/* eslint-disable react/jsx-curly-newline */

import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Tabs from "../../components/Tabs";

import Agendamento from "./Agendamento";
import Esteira from "./Esteira";

import { Context } from "../../Context/PermissoesContext";

import "./styles.css";
import Header from "../../components/Header";
import { Container } from "@mui/system";

export default function AgendaPaternidade() {
  const history = useHistory();

  const [tab, setTab] = useState(0);
  const { checarPermissao, checarPermissaoComAcao } = useContext(Context);

  useEffect(() => {
    function permissao() {
      if (!checarPermissao("AGP001")) {
        history.push("/home");
      }
    }
    permissao();
    // eslint-disable-next-line
  }, []);

  const handleTabChange = (newValue) => {
    if (newValue === 1) {
      return checarPermissaoComAcao("AGP006", () => setTab(newValue));
    }

    return setTab(newValue);
  };

  return (
    <>
      <Header titulo="Agenda da paternidade" />
      <Container>
        <main className="agenda-paternidade">
          <Tabs
            tabs={["Agendamento", "Esteira"]}
            value={tab}
            handleChange={(e, newValue) => handleTabChange(newValue)}
          />
          {tab === 0 ? <Agendamento /> : null}
        </main>
        {tab === 1 && <Esteira />}
      </Container>
    </>
  );
}
