import { useSnackbar } from "notistack";
import React, { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Header from "../../components/Header";
import { Context as PermissoesContext } from "../../Context/PermissoesContext";
import Bi from "./BI";
import Estoque from "./Estoque";
import HistoricoBaixaHome from "./HistoricoBaixas";
import TransferenciasIndex from "./Transferencias";

import { Container } from "@mui/system";
import { api } from "../../services/Api";
import useUser from "../../hooks/useUser";
import useDadosContextEstoque from "./hooks/useDadosContextEstoque";
import Resumo from "./Resumo";
import GridContainer from "../../componentsv2/GridContainer";
import GridItem from "../../componentsv2/GridItem";

export default function AlmoxarifadoHome() {
  const history = useHistory();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const { checarPermissao } = useContext(PermissoesContext);

  const [aba, setAba] = useState(1);

  const retornoTransferencia = location.state;
  const {
    setLocalSelecionado,
    setSemAcessoUnidade,
    setIdUnidadeUsuario,
    todosOsLocais,
    updateDadosFuncionario,
    setUpdateDadosFuncionario,
  } = useDadosContextEstoque();

  useEffect(() => {
    function inicio() {
      //setUpdateDadosFuncionario(!updateDadosFuncionario);
      if (retornoTransferencia) {
        return setAba(3);
      }

      if (!checarPermissao("AL001")) {
        return history.push("/home");
      }
      return null;
    }
    inicio();
  }, [checarPermissao, history, retornoTransferencia]);

  const handleAba2 = () => {
    const permissao = checarPermissao("AL017");

    if (!permissao) {
      enqueueSnackbar("É necessário a permissão AL017", {
        variant: "error",
      });
      history.push("/almoxarifado");
    } else {
      setAba(2);
    }
  };

  const handleAba3 = () => {
    const permissao = checarPermissao("AL011");

    if (!permissao) {
      enqueueSnackbar("É necessário a permissão AL011", {
        variant: "error",
      });
      history.push("/almoxarifado");
    } else {
      setAba(3);
    }
  };

  const handleAba4 = () => {
    const permissao = checarPermissao("AL016");

    if (!permissao) {
      enqueueSnackbar("É necessário a permissão AL016", {
        variant: "error",
      });
      history.push("/almoxarifado");
    } else {
      setAba(4);
    }
  };

  const handleAba5 = () => {
    const permissao = checarPermissao("AL016");

    if (!permissao) {
      enqueueSnackbar("É necessário a permissão AL016", {
        variant: "error",
      });
      history.push("/almoxarifado");
    } else {
      setAba(5);
    }
  };

  return (
    <>
      <Header titulo="Almoxarifado" />
      <Container fullWidth maxWidth="xl">
        <GridContainer sx={{ marginTop: 2 }}>
          <GridItem xs={6} md={3}>
            <button
              type="button"
              id={aba === 1 ? "button-aba-ctrl-samples-selected" : "Aba1"}
              className="button-aba-ctrl"
              style={{ fontSize: "16px" }}
              onClick={() => setAba(1)}
            >
              Estoque
            </button>
          </GridItem>
          <GridItem xs={6} md={3}>
            <button
              type="button"
              id={aba === 2 ? "button-aba-ctrl-samples-selected" : "Aba2"}
              className="button-aba-ctrl"
              style={{ fontSize: "16px" }}
              onClick={() => handleAba2()}
            >
              Movimentações
            </button>
          </GridItem>
          <GridItem xs={6} md={3}>
            <button
              type="button"
              id={aba === 3 ? "button-aba-ctrl-samples-selected" : "Aba3"}
              className="button-aba-ctrl"
              style={{ fontSize: "16px" }}
              onClick={() => handleAba3()}
            >
              Transferências
            </button>
          </GridItem>
          <GridItem xs={6} md={3}>
            <button
              type="button"
              id={aba === 5 ? "button-aba-ctrl-samples-selected" : "Aba3"}
              className="button-aba-ctrl"
              style={{ fontSize: "16px" }}
              onClick={() => handleAba5()}
            >
              Resumo
            </button>
          </GridItem>
          {/* <button
            type="button"
            id={aba === 4 ? "button-aba-ctrl-samples-selected" : "Aba4"}
            className="button-aba-ctrl"
            style={{ fontSize: "16px" }}
            onClick={() => handleAba4()}
          >
            BI
          </button> */}
        </GridContainer>

        {aba === 1 && <Estoque />}
        {aba === 2 && <HistoricoBaixaHome />}
        {aba === 3 && <TransferenciasIndex />}
        {aba === 4 && <Bi />}
        {aba === 5 && <Resumo />}
      </Container>
    </>
  );
}
