import React, { useState } from "react";
import Cabecalho from "./components/cabecalho";
import Tabela from "./components/tabela";

import { HStack } from "../../../../componentsv2/Stack";
import { useHistory } from "react-router-dom";

export default function Sac() {
  const [setor, setSetor] = useState("");
  const [situacao, setSituacao] = useState("");
  const [tipoManifestacao, setTipoManifestacao] = useState("");
  const history = useHistory();

  const openForm = () => {
    history.push("/atendimentoAoCliente/formularioSAC");
  };

  return (
    <HStack>
      <Cabecalho
        setor={setor}
        setSetor={setSetor}
        situacao={situacao}
        setSituacao={setSituacao}
        tipoManifestacao={tipoManifestacao}
        setTipoManifestacao={setTipoManifestacao}
        onClick={openForm}
      />
      <Tabela setor={setor} situacao={situacao} tipoManifestacao={tipoManifestacao} />
    </HStack>
  );
}
