import React, { memo, useContext, useState, useEffect } from "react";
import { Input } from "@mui/material";

import { RetornoLoteContext } from "../../Context/RetornoLoteContext";
import formatoMoeda from "../../../../../utils/formatoMoeda";
import { enqueueSnackbar } from "notistack";

function InputValorRecebido({ row }) {
  const {
    setGlosas,
    setAtualizaValoresRecebimentoManual,
    valorRecuperadoOriginal,
    valorOriginalEnviado,
    valor_recuperado,
  } = useContext(RetornoLoteContext);

  const { id, valor_recebido } = row;
  const [valorRecebido, setValorRecebido] = useState(valor_recebido || 0);

  useEffect(() => {
    if (valor_recebido) {
      setValorRecebido(formatoMoeda(valor_recebido, true));
    }
    // eslint-disable-next-line
  }, [row]);

  function alteraValorRecebido(valor) {
    setGlosas((prevState) => {
      const novoValorRecebido = prevState;
      const resultado = novoValorRecebido.map((item) => {
        if (item.id === id) {
          return { ...item, valor_recebido: valor };
        }
        return { ...item };
      });

      return resultado;
    });
    setAtualizaValoresRecebimentoManual((prevState) => !prevState);
  }

  return (
    <>
      <Input
        id="valor_recebido"
        value={valorRecebido}
        onChange={(event) => setValorRecebido(event.target.value)}
        onBlur={(event) => {
          const resultado = event.target.value.replace(",", ".");

          // if (parseFloat(resultado) > parseFloat(valorOriginalEnviado)) {
          //   setValorRecebido(0);
          //   enqueueSnackbar("Valor em recurso ultrapassou o limite.", {
          //     variant: "warning",
          //   });
          //   return;
          // }
          // eslint-disable-next-line
          if (!isNaN(resultado)) {
            alteraValorRecebido(Number(resultado));
          } else {
            setValorRecebido(0);
          }
        }}
      />
    </>
  );
}

export default memo(InputValorRecebido);
