import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box, Button, Container, Grid, Paper } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Header from "../../../../components/Header";
import Tabela from "../../../../componentsv2/Tabela";
import useUser from "../../../../hooks/useUser";
import { api } from "../../../../services/Api";

export default function ProdutoInfo() {
  const usuario = useUser();

  const { enqueueSnackbar } = useSnackbar();

  const location = useLocation();
  const history = useHistory();

  const [nomeProduto, setNomeProduto] = useState("");
  const [quantidadeDisponivel, setQuantidadeDisponivel] = useState("");
  const [quantidadeDisponivelUnidades, setQuantidadeDisponivelUnidades] =
    useState("");
  const [localDoProduto, setLocalDoproduto] = useState("");
  const [rows, setRows] = useState([]);

  const produto = location.state;

  useEffect(() => {
    async function obtemMovimentacoesProduto() {
      try {
        const result = await api.get(
          `/v2/almoxarifado/movimentacoesProdutoEstoque/exibir/${produto.dados.id}`
        );

        setRows(result.data.obterMovimentacoesProduto);
      } catch (e) {
        enqueueSnackbar("Erro ao buscar as movimentações deste produto", {
          variant: "error",
        });
      }
    }

    if (produto) {
      setNomeProduto(produto.dados?.nome);
      setQuantidadeDisponivel(produto.dados?.quantidade);
      setQuantidadeDisponivelUnidades(produto.dados?.quantidade_total_unitaria);
      setLocalDoproduto(produto.dados?.nomeLocal);
      obtemMovimentacoesProduto();
    }
  }, [enqueueSnackbar, produto]);

  const columns = [
    {
      field: "id",
      headerName: "id",
      headerAlign: "center",
      hide: true,
    },
    {
      field: "data",
      headerName: "Data - Hora",
      headerAlign: "center",
      width: 160,
      type: "date",
      valueFormatter: ({ value }) =>
        value &&
        new Date(value).toLocaleDateString("pt-BR", {
          hour: "numeric",
          minute: "numeric",
          second: "numeric",
        }),
    },
    {
      field: "username",
      headerName: "Usuário",
      headerAlign: "center",
      align: "center",
      width: 200,
    },
    {
      field: "quantidade",
      headerName: "Quantidade",
      headerAlign: "center",
      align: "center",
      width: 200,
      type: "number",
    },
    {
      field: "tipo",
      headerName: "Tipo",
      headerAlign: "center",
      align: "center",
      width: 200,
    },
    {
      field: "origem_destino",
      headerName: "Origem/Destino",
      headerAlign: "center",
      align: "center",
      width: 200,
    },
  ];

  // const rows = [
  //   {
  //     id: 1,
  //     data: "12/04/20 - 13:30",
  //     user: "eu",
  //     tipo: "Entrada",
  //     quantidade: 10,
  //   },
  // ];

  return (
    <>
      <Header titulo="Informações" usuario={usuario} history={history} />
      <Container maxWidth="lg" style={{ padding: "20px" }}>
        <Button onClick={() => history.push("/almoxarifado")}>
          <ArrowBackIcon style={{ color: "#003399" }} />
        </Button>
        <Paper style={{ padding: "40px", marginTop: "10px" }} elevation={2}>
          <Grid spacing={3} container>
            <Grid item xs={12} sm={12}>
              <h2>Informações</h2>
            </Grid>
            <Grid item xs={12} sm={6}>
              <h3>
                Produto:{" "}
                <span style={{ fontWeight: "normal" }}>{nomeProduto}</span>
              </h3>
            </Grid>
            <Grid item xs={12} sm={6}>
              <h3>
                Quantidade disponível:{" "}
                <span style={{ fontWeight: "normal" }}>
                  {quantidadeDisponivel}
                </span>
              </h3>
            </Grid>
            {/* <Grid item xs={12} sm={6}>
              <h3>
                Quantidade disponível(unidades):{" "}
                <span style={{ fontWeight: "normal" }}>
                  {quantidadeDisponivelUnidades}
                </span>
              </h3>
            </Grid> */}
            <Grid item xs={12} sm={6}>
              <h3>
                Local:{" "}
                <span style={{ fontWeight: "normal" }}>{localDoProduto}</span>
              </h3>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Box
                sx={{
                  height: 1,
                  width: "100%",
                  "& .entrada": { color: "#003399" },
                  "& .saida": { color: "#99250F" },
                }}
              >
                <Tabela
                  initialState={{
                    columns: {
                      columnVisibilityModel: {
                        id: false,
                      },
                    },
                  }}
                  rows={rows}
                  columns={columns}
                  autoHeight
                  getCellClassName={(params) => {
                    if (params.field === "quantidade") {
                      return params.value > 0 ? "entrada" : "saida";
                    }

                    return "";
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        </Paper>
      </Container>
    </>
  );
}
