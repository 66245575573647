import React, { useContext } from "react";
import { useSnackbar } from "notistack";

import { api } from "../../../../../services/Api";
import Tabela from "../../../../../componentsv2/Tabela";
import { useState } from "react";
import { useEffect } from "react";
import { Context } from "../../../../../Context/PermissoesContext";

export default function TabelaProcedimentos({
  setSelectedRows,
  atualizaTabela,
}) {
  const [listaProcedimentos, setListaProcedimentos] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const { checarPermissao } = useContext(Context);

  const columns = [
    {
      field: "nome",
      headerName: "Procedimento",
      headerAlign: "",
      align: "",
      width: 240,
    },
    {
      field: "precoExame",
      headerName: "Valor",
      headerAlign: "center",
      align: "center",
      hide: !checarPermissao("AGC002"),
      width: 100,
    },
    {
      field: "tempoEstimado",
      headerName: "Tempo Estimado",
      headerAlign: "center",
      align: "center",
      width: 100,
    },
  ];
  const dadosProcedimento = async () => {
    try {
      const response = await api.get("/v2/agendaCare/procedimentos");
      setListaProcedimentos(response.data.data);
    } catch (error) {
      enqueueSnackbar("Não foi possível encontrar os procedimentos", {
        variant: "error",
      });
    }
  };

  useEffect(() => {
    dadosProcedimento();
    // eslint-disable-next-line
  }, [atualizaTabela]);

  return (
    <div style={{ height: 460, width: "100%" }}>
      <Tabela
        ocultarToolbar
        dataSet="Commodity"
        rowLength={listaProcedimentos.Length}
        rows={listaProcedimentos}
        columns={columns}
        checkboxSelection
        // onSelectionModelChange={newSelectionModel => {
        //   setSelectedRows(newSelectionModel);
        // }}
        onRowSelectionModelChange={(ids) => {
          const selectedIDs = new Set(ids);
          const selectedRowData = listaProcedimentos.filter((row) =>
            selectedIDs.has(row.id.toString())
          );

          setSelectedRows(selectedRowData);
        }}
      />
    </div>
  );
}
