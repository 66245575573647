export default function atualizaExibicaoData(objeto, propriedade, hora = false) {
  const formatado = objeto.map(el => ({
    ...el,
    [propriedade]:
      el[propriedade] !== null
        ? new Date(`${el[propriedade]}${hora ? " 12:00:00" : ""}`).toLocaleDateString()
        : [propriedade],
  }));

  return formatado;
}
