import React from "react";

import { Grid } from '@mui/material';

import { Select } from "../../../../componentsv2/Select";

export default function SelectDeGenero({
  onChange,
  value,
  disabled = false,
  required = false,
}) {
  const options = ["Masculino", "Feminino", "Prefiro não Dizer"];
  return (
    <>
      <Grid container spacing={1} justify="center" alignItems="stretch">
        <Grid item xs={12}>
          <Select
            disabled={disabled}
            required={required}
            onChange={onChange}
            value={value}
            label="Gênero"
            arrayOptions={options}
            variant="standard"
          />
        </Grid>
      </Grid>
    </>
  );
}
