import React, { useEffect, useState } from "react";
import { TextField, InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useSnackbar } from "notistack";

import Button from "../../../../components/Button";
import TopicPill from "../../components/TopicPill";
import Loading from "../../../../components/Loading";
import Modal from "../../../../components/Modal";

import { api } from "../../../../services/Api";

import SpecialtyDialog from "./Dialog";
import "./styles.css";

export default function Topicos() {
  const [loading, setLoading] = useState(true);
  const [especialidades, setEspecialidades] = useState([]);
  const [search, setSearch] = useState("");
  const [dialogValues, setDialogValues] = useState({
    nome: "",
  });
  const [dialogMode, setDialogMode] = useState("create");
  const [showDialog, setShowDialog] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedSpecialty, setSelectedSpecialty] = useState(null);

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    (async () => {
      try {
        const { data } = await api.get("/v2/portalMedico/especialidades");
        setEspecialidades(data);
      } catch (e) {
        enqueueSnackbar(
          "Não foi possível recuperar as especialidades cadastradas.",
          {
            variant: "error",
          }
        );
      }
      setLoading(false);
    })();
  }, [enqueueSnackbar]);

  const searchFunction = (especialidade) => {
    if (search === "") {
      return true;
    }

    const { nome } = especialidade;
    return nome.toLowerCase().includes(search.toLowerCase());
  };

  const openDialogForCreating = () => {
    setDialogMode("create");
    setShowDialog(true);
  };

  const openDialogForEditing = (target) => {
    setDialogMode("edit");
    setDialogValues(target);
    setShowDialog(true);
  };

  const closeDialog = () => {
    setDialogValues({ nome: "" });
    setShowDialog(false);
  };

  const addSpecialty = (newSpecialty) => {
    setEspecialidades((prev) => [...prev, newSpecialty]);
  };

  const updateSpecialty = (newSpecialty) => {
    const copy = [...especialidades];
    const targetIndex = copy.findIndex((e) => e.id === newSpecialty.id);
    copy[targetIndex] = newSpecialty;
    setEspecialidades(copy);
  };

  const deleteSpecialty = async () => {
    const { id } = selectedSpecialty;
    setLoading(true);
    try {
      await api.delete(`/v2/portalMedico/especialidade/${id}`);
      const targetIndex = especialidades.findIndex((e) => e.id === id);
      const copy = [...especialidades];
      copy.splice(targetIndex, 1);
      setEspecialidades(copy);
      enqueueSnackbar("Especialidade excluída com sucesso!", {
        variant: "success",
      });
    } catch (e) {
      enqueueSnackbar("Não foi possível excluir esta especialidade.", {
        variant: "error",
      });
    }
    setShowDeleteModal(false);
    setLoading(false);
  };

  const openDeleteModal = (target) => {
    setSelectedSpecialty(target);
    setShowDeleteModal(true);
  };

  const closeDeleteModal = () => {
    setSelectedSpecialty(null);
    setShowDeleteModal(false);
  };

  return (
    <>
      {loading && <Loading />}
      <SpecialtyDialog
        open={showDialog}
        mode={dialogMode}
        initialValues={dialogValues}
        onClose={closeDialog}
        addSpecialty={addSpecialty}
        updateSpecialty={updateSpecialty}
        specialties={especialidades}
      />
      <Modal
        open={showDeleteModal}
        closeModal={closeDeleteModal}
        title="Deletar especialidade"
        text="Tem certeza disso? Isso excluirá associações com módulos, seções, artigos e notícias."
        cancelButtonLabel="Não, quero voltar"
        confirmButtonLabel="Sim, quero excluir"
        onCancel={closeDeleteModal}
        onConfirm={deleteSpecialty}
      />
      <div
        style={{
          display: "flex",
          alignItems: "baseline",
          marginTop: "4rem",
          flexWrap: "wrap",
        }}
      >
        <TextField
          placeholder="Busca"
          value={search}
          onChange={(e) => {
            // e.persist();
            const {
              target: { value },
            } = e;
            setSearch(value);
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon
                  style={{ color: "rgba(0,0,0,0.6)", cursor: "pointer" }}
                />
              </InputAdornment>
            ),
          }}
        />
        <Button
          onClick={openDialogForCreating}
          style={{ marginLeft: "1rem", fontSize: "1.1rem" }}
        >
          Adicionar Especialidade
        </Button>
      </div>
      <div className="topic-pills-container">
        {especialidades.filter(searchFunction).map((e) => (
          <TopicPill
            key={e.id}
            className={e.id}
            onClose={() => openDeleteModal(e)}
            onEdit={() => openDialogForEditing(e)}
          >
            {e.nome}
          </TopicPill>
        ))}
      </div>
    </>
  );
}
