import { Autocomplete, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { api } from "../../../../services/Api";

export default function AutocompleteEstoque({
  value,
  onChange,
  required = false,
  idUnidade = "",
  disabled = false,
  ...rest
}) {
  const [estoques, setEstoques] = useState([]);

  useEffect(() => {
    async function buscarTodosEstoques() {
      try {
        const unidade_id = idUnidade === "" ? "todos" : idUnidade;

        const { data } = await api.get(
          `/v2/almoxarifado/estoques/${unidade_id}`
        );

        const arrayComTodos = [{ nome: "Todos", id: "" }, ...data.estoques];
        setEstoques(arrayComTodos);
      } catch (e) {
        console.log(e);
      }
    }

    buscarTodosEstoques();
  }, [idUnidade]);

  return (
    <Autocomplete
      {...rest}
      value={value}
      onChange={(event, value) => onChange(value)}
      disablePortal
      disabled={disabled}
      disableClearable
      id="combo-box-demo"
      options={estoques}
      getOptionLabel={(option) => `${option.nome}`}
      fullWidth
      renderInput={(params) => (
        <TextField
          required={required}
          variant="standard"
          {...params}
          label="Estoques"
        />
      )}
    />
  );
}
