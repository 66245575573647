import React from "react";
import Cabecalho from "../../components/Cabecalho";
import Tabela from "../../components/TabelaClientes";
import { HStack } from "../../../../componentsv2/Stack";

export default function Clientes() {
  return (
    <HStack>
      <Cabecalho></Cabecalho>
      <Tabela></Tabela>
    </HStack>
  );
}
