import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import { Grid, Paper, IconButton, Box, Autocomplete } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

import Tabela from "../../../../componentsv2/Tabela";
import { Input } from "../../../../componentsv2/Input";
import { Select } from "../../../../componentsv2/Select";

import SelectUnidade from "../../components/SelectUnidade";
import OpcoesBem from "./opcoesBem";

import { Context as PermissoesContext } from "../../../../Context/PermissoesContext";
import DadosContext from "../../DadosContext";

import { api } from "../../../../services/Api";

export default function Bem() {
  const history = useHistory();
  const [arrayDeBens, setArrayDeBens] = useState([]);
  const [situacao, setSituacao] = useState("");
  const [codTombador, setCodTombador] = useState(null);
  const [marca, setMarca] = useState(null);
  const [modelo, setModelo] = useState(null);
  const { checarPermissao } = useContext(PermissoesContext);
  const [codigosTombadorArray, setCodigosTombadorArray] = useState([]);
  const [marcasArray, setMarcasArray] = useState([]);
  const [modelosArray, setModelosArray] = useState([]);

  const listaDeSituacoes = [
    { value: "", text: "Todos" },
    { value: "Ativo", text: "Ativo" },
    { value: "Baixado", text: "Baixado" },
  ];
  const { setUnidadeUsuario, unidadeUsuario, semAcessoTodasUnidades } =
    useContext(DadosContext);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    async function getBens() {
      try {
        const { data } = await api.get(`/v2/inventario/bens`, {
          params: {
            situacao,
            unidade: unidadeUsuario,
            codTombador: codTombador?.value,
            marca: marca?.value,
            modelo: modelo?.value,
          },
        });

        setArrayDeBens(data.data);
      } catch (error) {
        enqueueSnackbar("Erro! Não foi possível buscar os bens.", {
          variant: "error",
        });
      }
    }
    getBens();
  }, [
    enqueueSnackbar,
    marca,
    modelo,
    situacao,
    codTombador,
    checarPermissao,
    unidadeUsuario,
  ]);

  useEffect(() => {
    async function getArrays() {
      try {
        const {
          data: { data },
        } = await api.get(`/v2/inventario/opcoesPesquisaBens`);
        const arrayCodTombadorFiltrado = data.codTombador.filter(
          element => element.value !== null
        );
        setCodigosTombadorArray(arrayCodTombadorFiltrado);
        setMarcasArray(data.marca);
        setModelosArray(data.modelo);
      } catch (error) {
        enqueueSnackbar("Erro! Não foi possível buscar os bens.", {
          variant: "error",
        });
      }
    }
    getArrays();
  }, [enqueueSnackbar]);

  const columns = [
    {
      field: "codTombador",
      headerName: "Código Tombador",
      width: 220,
      headerAlign: "left",
      align: "left",
      type: "number",
    },
    {
      field: "produto",
      headerName: "Produto",
      width: 240,
      type: "text",
      valueGetter: params =>
        `${params.row?.material?.modelo}(${params.row?.material?.marca})`,
    },
    {
      field: "nomeProprietario",
      headerName: "Proprietário",
      width: 230,
      type: "text",
      valueGetter: params => params.row?.proprietario?.nome,
    },
    {
      field: "situacao",
      headerName: "Situação",
      width: 220,
      type: "text",
    },

    {
      field: "opcoes",
      headerName: "Opções",

      width: 130,
      renderCell: params => (
        <div style={{ cursor: "pointer" }}>
          <OpcoesBem row={params.row} />
        </div>
      ),
    },
  ];

  return (
    <Grid container alignItems="center" spacing={1} direction="column">
      <Grid container xs={12} item>
        <Paper sx={{ width: "100%", marginBottom: "30px", padding: "10px" }}>
          <Grid item container sx={{ marginLeft: "5px" }} justifyContent="space-between">
            <Grid xs={10} spacing={2} item container>
              <Grid item md={3} sm={12} lg={3} xs={12}>
                <SelectUnidade
                  disabled={semAcessoTodasUnidades}
                  value={unidadeUsuario}
                  onChange={e => setUnidadeUsuario(e.target.value)}
                />
              </Grid>
              <Grid item md={3} sm={6} lg={3} xs={6}>
                <Autocomplete
                  value={codTombador}
                  onChange={(event, value) => setCodTombador(value)}
                  disablePortal
                  options={codigosTombadorArray}
                  getOptionLabel={option => option.text}
                  fullWidth
                  renderInput={params => (
                    <Input {...params} variant="standard" label="Código Tombador" />
                  )}
                />
              </Grid>
              <Grid item md={3} sm={6} lg={2} xs={6}>
                <Autocomplete
                  value={marca}
                  onChange={(event, value) => setMarca(value)}
                  disablePortal
                  options={marcasArray}
                  getOptionLabel={option => option.text}
                  fullWidth
                  renderInput={params => (
                    <Input {...params} variant="standard" label="Marca" />
                  )}
                />
              </Grid>
              <Grid item md={3} sm={6} lg={2} xs={6}>
                <Autocomplete
                  value={modelo}
                  onChange={(event, value) => setModelo(value)}
                  disablePortal
                  options={modelosArray}
                  getOptionLabel={option => option.text}
                  fullWidth
                  renderInput={params => (
                    <Input {...params} variant="standard" label="Modelo" />
                  )}
                />
              </Grid>
              <Grid item md={3} sm={6} lg={2} xs={6}>
                <Select
                  value={situacao}
                  valueAttribute="value"
                  textAttribute="text"
                  onChange={e => setSituacao(e.target.value)}
                  label="Situação"
                  arrayOptions={listaDeSituacoes}
                  variant="standard"
                />
              </Grid>
            </Grid>
            <Grid xs={2} sm={2} md={1} lg={1} item>
              <IconButton
                onClick={() => {
                  if (checarPermissao("INV019")) {
                    history.push("/inventario/bem/cadastrarBem");
                  } else {
                    enqueueSnackbar("Você não possui permissão para cadastrar um bem!", {
                      variant: "error",
                      autoHideDuration: 3000,
                    });
                  }
                }}
                color="success"
                size="large"
              >
                <AddCircleOutlineIcon fontSize="large" />
              </IconButton>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={12} container>
        <Grid style={{ paddingTop: "10px" }} xs={12} sm={12} item>
          <Grid spacing={2} container>
            <Grid style={{ paddingTop: "10px" }} item xs={12} sm={12}>
              <Paper elevation={2}>
                <Box
                  sx={{
                    height: 1,
                    width: "100%",
                    marginBottom: "10px",
                  }}
                >
                  <div>
                    <Tabela rows={arrayDeBens} columns={columns} autoHeight />
                  </div>
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
