/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/jsx-no-comment-textnodes */
import React, { useRef, useState } from "react";
import { Modal, Paper } from '@mui/material';
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import { baseUrl } from "../../../../services/Api";

import "./styles.css";

export default function Galeria({ images, onRemove, addImages }) {
  const inputRef = useRef(null);
  const [selectedImage, setSelectedImage] = useState(null);

  const onImagesUpload = e => {
    const fileArray = Array.from(e.target.files);
    addImages(fileArray);
  };

  return (
    <>
      <Modal open={selectedImage !== null} onClose={() => setSelectedImage(null)}>
        <div className="modal-galeria">
          <Paper
            onClick={() => setSelectedImage(null)}
            style={{
              position: "absolute",
              top: "16px",
              right: "16px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
            }}
          >
            <CloseIcon
              style={{
                color: "#003399",
                fontSize: "35px",
              }}
            />
          </Paper>
          {selectedImage !== null && (
            <img
              src={
                selectedImage
                  ? selectedImage.url
                    ? `${baseUrl}${selectedImage.url}`
                    : URL.createObjectURL(selectedImage)
                  : ""
              }
              alt="Selected pic from gallery"
            />
          )}
        </div>
      </Modal>
      <h2 className="areas-title" style={{ marginTop: "1.5rem" }}>
        Galeria de imagens
      </h2>
      <input
        type="file"
        style={{ display: "none" }}
        ref={inputRef}
        multiple
        accept="image/*"
        onChange={onImagesUpload}
      />
      <div className="galeria-editor-post">
        {images.map((image, index) => (
          // lembre-se, isso pode ser tanto algo vindo banco de dados quanto um arquivo local
          <div
            className="galeria-editor-post-imagem"
            key={image.id || `${image.name}-${image.lastModified}`}
          >
            <div className="close-icon" onClick={() => onRemove(index)}>
              <CloseIcon style={{ color: "#003399" }} />
            </div>
            <img
              onClick={() => setSelectedImage(image)}
              src={image.url ? `${baseUrl}${image.url}` : URL.createObjectURL(image)}
              alt={image.id ? `Image ${index} of the gallery` : `${image.name}`}
            />
          </div>
        ))}
      </div>
      <div className="add-area-button" onClick={() => inputRef.current.click()}>
        <AddIcon style={{ color: "#003399" }} />
        <p>Adicionar imagens</p>
      </div>
    </>
  );
}
