import React, { useContext } from 'react';
import {
  Document,
  Page,
  PDFViewer,
  StyleSheet,
  Text,
  View
} from '@react-pdf/renderer';
import {Dialog, DialogContent } from '@mui/material';

import { FormularioColetaDomiciliarContext } from '../../../Context/FormularioColetaDomiciliarContext';
import { ModaisColetaDomiciliarContext } from '../../../Context/ModaisColetaDomiciarContext';
import formatoMoeda from '../../../../../utils/formatoMoeda';
import formatoCPF from '../../../../../utils/formatoCPF';
import { ColetaDomiciliarContext } from '../../../Context/ColetaDomiciarContext';

const styles = StyleSheet.create({
  page: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#F5F6CE',
    width: '50%'
  },
  content_title: {
    textAlign: 'center',
    marginTop: 10,
    fontSize: '11px',
  },
  content_body: {
    margin: "10px 20px",
    fontSize: '10px',
  },
  text: {
    padding: 2
  }
});

export default function Recibo() {
  const { recibo, setRecibo, fechaOpcoes } = useContext(ModaisColetaDomiciliarContext);
  const { coleta } = useContext(ColetaDomiciliarContext);
  const {
    valor_recebido,
    setValor_recebido,
    cpf_cliente,
    setCpf_cliente,
    nome,
    setNome
   } = useContext(FormularioColetaDomiciliarContext);

  const MyDocument = () => (
    <Document>
      <Page size="A7" style={styles.page}>

        <View style={styles.content_title}>
          <Text>RECIBO</Text>
        </View>

        <View style={styles.content_title}>
          <Text>VIP CENTER COLETA</Text>
          <Text>CNPJ: 48.939.259/0001-29</Text>
        </View>

        <View style={styles.content_body}>
          <Text style={styles.text}>RECEBEMOS DE: {nome}</Text>
          <Text style={styles.text}>CPF: {formatoCPF(cpf_cliente || '00000000000')}</Text>
          <Text style={styles.text}>SERVIÇO PRESTADO: COLETA DOMICILIAR</Text>
        </View>

        <View style={styles.content_body}>
          <Text>VALOR PAGO: {formatoMoeda(Number(coleta.valor_recebido) || Number(valor_recebido))}</Text>
        </View>

      </Page>
    </Document>
  );

  function zeraRecibo() {
    setNome('');
    setValor_recebido(null);
    setCpf_cliente('');
  }

  return (
    <Dialog
      open={recibo}
      onClose={() => {
        fechaOpcoes();
        zeraRecibo();
        setRecibo(false)
      }}
    >
      <DialogContent>
        <PDFViewer style={{display: 'flex'}} showToolbar width='100%' height='500px'>
          <MyDocument />
        </PDFViewer>
      </DialogContent>
    </Dialog>
  )
}
