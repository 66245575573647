import React from "react";
import { format, parseISO } from "date-fns";
import { Tooltip } from '@mui/material';
import { Error } from '@mui/icons-material';

import "./styles.css";

export default function Tabela(props){
  const {
    agendamentos,
    setId,
    setOpcao,
    setMenuStatus,
    setStatus,
  } = props;

  function abrirOpcoes(id) {
    setId(id);
    setOpcao(true);
  }

  function abrirMenuStatus(id, status) {
    setId(id);
    setStatus(status);
    setMenuStatus(true);
  }

    return (
      <div>
        <div className="table-container">
          <table className="table">
            <thead>
              <tr>
                <th align="center">Status</th>
                <th align="center">Usuário</th>
                <th align="center">Pedido</th>
                <th id="cliente" align="center">Cliente</th>
                <th align="center">Contato</th>
                <th align="center">Data</th>
                <th align="center">Hora</th>
                <th align="center">Opções</th>
              </tr>
            </thead>

            {agendamentos
            ?(
              <tbody>
                {agendamentos.map((row) => (
                  <tr key={row.id}>
                    <td align="center">
                      <div className="selectdiv">
                        <span>
                          <select
                            className="selectStatus"
                            value={row.status}
                            onChange={(e) => abrirMenuStatus(row.id, e.target.value)}
                          >
                            <option key="10" value="Agendado">Agendado</option>
                            <option key="20" value="Coletado">Coletado</option>
                          </select>
                        </span>
                      </div>
                    </td>
                    <td align="center">
                      {row.codigo_usuario}
                    </td>
                    <td align="center">
                      {row.codigo_pedido}-{row.posto_pedido}
                    </td>
                    <td id="cliente" align="left">
                      {(row.prioridade === 'Sim')
                        ? <div id="divPrioridade"><Error color="error" fontSize="small" />{ row.nome_cliente} </div>
                        : row.nome_cliente}
                    </td>
                    <td align="center">
                      {row.fone_cliente} {row.celular_cliente}
                    </td>
                    <td align="center">
                      {format(parseISO(row.data), "dd/MM/yyyy")}
                    </td>
                    <td align="center">{row.hora}</td>
                    <td align="center">
                      <Tooltip title="Abrir opções">
                        <button
                          type="button"
                          className="btnOpcao"
                          onClick={
                          () => abrirOpcoes(row.id)
                        }
                        >
                          <i className="fa fa-cog" />
                        </button>
                      </Tooltip>
                    </td>

                  </tr>
                ))}
              </tbody>
            )
            : null}

          </table>
        </div>

      </div>
    )
  }
