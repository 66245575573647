import React, { useContext, useState, useEffect, useRef } from "react";
import { useSnackbar } from "notistack";
import {
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Grid,
  Box,
  Alert,
  Stack,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  ScopedCssBaseline,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { Search, Help } from "@mui/icons-material";
import loading from "../../../../../assets/loading.gif";

import { ColetaDomiciliarContext } from "../../../Context/ColetaDomiciarContext";
import { FormularioColetaDomiciliarContext } from "../../../Context/FormularioColetaDomiciliarContext";
import { ModaisColetaDomiciliarContext } from "../../../Context/ModaisColetaDomiciarContext";

import DataPickerAtualizado from "../../../../../components/DataPickerAtualizado";
import Waiter from "../../../../../components/Backdrop";

import Tabela from "../../../../../componentsv2/Tabela";
import Button from "../../../../../componentsv2/Button";

import formatDateBD from "../../../../../utils/formatarDataBD";
import SalvaLog from "../../../../../utils/SalvaLog";

import { api } from "../../../../../services/Api";

function ModalEditarDados({ filtroDataColetas }) {
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();

  const { setColeta, usuario } = useContext(ColetaDomiciliarContext);

  const { dadosReagendamento, codigoPedido, setCodigoPedido, zeraCliente } =
    useContext(FormularioColetaDomiciliarContext);

  const { modalEditarDados, setModalEditarDados, setAbreDadosCliente } =
    useContext(ModaisColetaDomiciliarContext);

  const tamanho = useMediaQuery(theme.breakpoints.down("sm"));

  const [espera, setEspera] = useState("");

  const [localizacao, setLocalizacao] = useState("");
  const [rows, setRows] = useState([{ id: "", horario: "", distancia: "" }]);

  const [horario, setHorario] = useState("");
  const [horariosEVagas, setHorariosEVagas] = useState([]);
  const [coletador, setColetador] = useState("");
  const [textoMelhorHorario, setTextoMelhorHorario] = useState("");
  const [novaData, setNovaData] = useState(new Date());
  const [isEnderecoSelecionado, setIsEnderecoSelecionado] = useState(false);
  const [mostraTabela, setMostraTabela] = useState(false);
  const [loadingTable, setLoadingTable] = useState(false);
  const [pesquisaEndereco, setPesquisaEndereco] = useState("");
  const [opcoesEnderecos, setOpcoesEnderecos] = useState([]);
  const [codigoPedidoModal, setCodigoPedidoModal] = useState("");

  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [abrirOpcoesSelect, setAbrirOpcoesSelect] = useState(false);
  const [openDialogComoUsar, setOpenDialogComoUsar] = useState(false);
  const [openDialogBuscando, setOpenDialogBuscando] = useState(false);
  const [idColeta, setIdColeta] = useState("");
  const [pedidoEncontrado, setPedidoEncontrado] = useState(null);

  const dataValida = () => {
    try {
      const dataFormatada = new Date(novaData);
      dataFormatada.setHours(12);
      const data = formatDateBD(dataFormatada).split("-");
      if (data.length === 3) {
        if (
          !(parseInt(data[0], 10) > 999) ||
          !(parseInt(data[1], 10) > 0 && parseInt(data[1], 10) < 13) ||
          !(parseInt(data[2], 10) > 0 && parseInt(data[2], 10) < 32) ||
          !(data[0].length === 4) ||
          !(data[1].length === 2) ||
          !(data[2].length === 2)
        ) {
          return false;
        }
      }
    } catch (error) {
      return false;
    }
    return true;
  };
  async function getHorariosEVagas() {
    try {
      const dataFormatada = new Date(novaData);
      dataFormatada.setHours(12);
      const { data } = await api.get(
        `/api/coletaDomiciliar/vagas/${formatDateBD(dataFormatada)}`
      );
      setHorariosEVagas(data);
    } catch (error) {
      enqueueSnackbar("Erro ao procurar horários!", {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  }
  async function getRows(distancias) {
    const rowsFormatada = distancias.map((el) => ({
      id: el.nome,
      horario: el.ultimoHorario,
      jaSelecionado: el.jaSelecionado,
      disponibilidade: el.jaSelecionado ? "Indisponível" : "Disponível",
      distancia: el.text,
      distanciaValue: el.value,
    }));

    rowsFormatada.sort((a, b) => {
      if (parseInt(a.distanciaValue, 10) < parseInt(b.distanciaValue, 10)) {
        return -1;
      }
      if (parseInt(a.distanciaValue, 10) > parseInt(b.distanciaValue, 10)) {
        return 1;
      }
      // a must be equal to b
      return 0;
    });
    setRows(rowsFormatada);
  }
  const getDistance = async (coletadores) => {
    try {
      const dataFormatada = new Date(novaData);
      dataFormatada.setHours(12);

      const { data } = await api.post("/v2/coleta/distancia", {
        origins: [localizacao.formatted_address],
        coletadores,
        data: formatDateBD(dataFormatada),
        hora: horario,
      });

      const melhorHorario = await api.get("/v2/coleta/melhorHorario", {
        params: {
          latitude,
          longitude,
          data: formatDateBD(dataFormatada),
          hora: horario,
        },
      });

      if (melhorHorario.data) {
        setTextoMelhorHorario(
          `Sugestão de horário: coletador(a) ${melhorHorario.data.coletador} às ${melhorHorario.data.horario_disponivel}`
        );
      } else {
        setTextoMelhorHorario("");
      }

      getRows(data);
    } catch (err) {
      // console.log(`Falha em encontrar as distâncias, ${err}`);
    }
  };
  async function getColetadoresELocalizacao() {
    try {
      const dataFormatada = new Date(novaData);
      dataFormatada.setHours(12);
      const response = await api.get(
        `/api/coletaDomiciliar/agendaLocalizacoes/${formatDateBD(
          dataFormatada
        )}/${horario}`
      );

      getDistance(response.data);
    } catch (error) {
      enqueueSnackbar("Erro ao buscar coletadores!", {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  }
  const geraTabela = () => {
    // getHorariosEVagas();
    getColetadoresELocalizacao();
  };

  const checaSeReagendamento = () => {
    if (dadosReagendamento) {
      const localizacaoFormatada =
        dadosReagendamento.localizacao ||
        (dadosReagendamento.endereco && dadosReagendamento.endereco_numero
          ? `${dadosReagendamento.endereco}, ${dadosReagendamento.endereco_numero}`
          : dadosReagendamento.endereco && dadosReagendamento.endereco_numero);
      setPesquisaEndereco(localizacaoFormatada);
      setHorario(dadosReagendamento.hora);
      setIdColeta(dadosReagendamento.id);
      if (dadosReagendamento.codigo_pedido && dadosReagendamento.posto_pedido) {
        setCodigoPedidoModal(
          `${dadosReagendamento.codigo_pedido}-${dadosReagendamento.posto_pedido}`
        );
      }
    }
  };

  useEffect(() => {
    setCodigoPedido("");
    setColeta("");
    checaSeReagendamento();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setLoadingTable(true);
    if (dataValida()) {
      if (mostraTabela && isEnderecoSelecionado && localizacao) {
        geraTabela();
      } else {
        // getHorariosEVagas();
      }
    }
    setLoadingTable(false);
    // eslint-disable-next-line
  }, [novaData, horario, localizacao]);

  function selecionaData(data) {
    setNovaData(data);
  }

  function logAgendamento() {
    let opcaoEscolhida;
    rows.forEach((el, index) => {
      if (el.id === coletador) {
        opcaoEscolhida = index + 1;
      }
    });
    SalvaLog(
      "Seleção de Coletador",
      "Coleta Domiciliar",
      `Opção nº:${opcaoEscolhida} - ${coletador}`,
      `${idColeta}`
    );
  }

  const fecharModal = () => {
    setModalEditarDados(false);
    zeraCliente();
  };

  const reagendar = async (data) => {
    try {
      if (codigoPedido === "") {
        enqueueSnackbar("Preencha o campo de pedido", {
          variant: "warning",
        });
        return;
      }

      await api.put(`/v2/coleta/reagendar/${idColeta}`, {
        data,
        hora: horario,
        coletador,
        localizacao: localizacao.formatted_address,
        latitude,
        longitude,
        codigo_pedido:
          codigoPedido.split("-").length === 2
            ? codigoPedido.split("-")[0]
            : null,
        posto_pedido:
          codigoPedido.split("-").length === 2
            ? codigoPedido.split("-")[1]
            : null,
      });
      enqueueSnackbar("Coleta Reagendada!", {
        variant: "success",
      });
    } catch (error) {
      console.log("Erro em reagendar - ", error);

      enqueueSnackbar("Erro ao tentar reagendar!", {
        variant: "error",
      });
    }
  };

  function adicionaHoras(date, hours) {
    date.setTime(date.getTime() + hours * 60 * 60 * 1000);

    return date;
  }

  async function verificarPedidosPorData(dataPedido, codigo_pedido) {
    try {
      let codigo = codigo_pedido;
      let data_pedido = dataPedido;
      if (codigo.includes("-")) {
        codigo = codigo.split("-")[0];
      }

      if (Object.hasOwn(data_pedido, "$d")) {
        data_pedido = data_pedido.$d;
      }

      const { data } = await api.get(`/v2/coleta/coletaCodigoPedido/${codigo}`);

      const { coletaPorCodigoPedido } = data;

      const resultado = coletaPorCodigoPedido.filter((el) => {
        return (
          adicionaHoras(new Date(el.data), 3).toLocaleDateString() ===
          data_pedido.toLocaleDateString()
        );
      });

      if (resultado.length === 0) {
        return false;
      }
      return true;
    } catch (error) {
      console.error("Erro na verificação de data", error);
    }
  }

  async function agendar(data, codigo_pedido, posto_pedido) {
    const agendamento = {
      coletador,
      data,
      hora: horario,
      codigo_usuario: usuario,
      localizacao: localizacao.formatted_address,
      latitude,
      longitude,
      codigo_pedido,
      posto_pedido,
    };
    try {
      setEspera(true);
      const response = await api.post("/api/coletaDomiciliar", {
        coletador,
        data,
        hora: horario,
        codigo_usuario: usuario,
        localizacao: localizacao.formatted_address,
        latitude,
        longitude,
        codigo_pedido,
        posto_pedido,
      });
      setColeta(response.data);
      SalvaLog(
        `Reservou para ${coletador} o dia ${data} as ${horario}`,
        "Coleta Domiciliar",
        JSON.stringify(agendamento),
        `${idColeta}`
      );
      setEspera(false);
      zeraCliente();
      enqueueSnackbar("Horário reservado com sucesso", { variant: "success" });
      setAbreDadosCliente(true);
    } catch (error) {
      console.log("Erro em agendar - ", error);

      SalvaLog(
        `Tentou reservar para ${coletador} o dia ${data} as ${horario}`,
        "Coleta Domiciliar",
        JSON.stringify(agendamento),
        `${idColeta}`
      );
      enqueueSnackbar("Falha na operação", { variant: "error" });
      setEspera(false);
      zeraCliente();
    }
  }

  const handleAgendar = async () => {
    if (codigoPedido.trim() === "" || !pedidoEncontrado) {
      enqueueSnackbar("Preencha o campo de pedido", {
        variant: "warning",
      });

      return;
    }
    let textoErro = "";

    if (coletador === "") {
      textoErro = "Selecione um coletador!";
    }
    if (localizacao === "") {
      textoErro = "Pesquise e selecione um endereço válido!";
    }
    if (horario === "" || horario === "Selecione") {
      textoErro = "Selecione um Horário";
    }

    if (!dadosReagendamento) {
      const pedidosNaMesmaDataComMesmoCodigoPedido =
        await verificarPedidosPorData(novaData, codigoPedido);

      if (pedidosNaMesmaDataComMesmoCodigoPedido) {
        textoErro = "Já existe um pedido nessa mesma data!";
      }
    }

    if (textoErro) {
      enqueueSnackbar(textoErro, {
        variant: "error",
        autoHideDuration: 3000,
      });
    } else {
      const dataFormatada = new Date(novaData);
      dataFormatada.setHours(12);
      if (dadosReagendamento) {
        reagendar(formatDateBD(dataFormatada));
        logAgendamento();
        fecharModal();
        filtroDataColetas(dataFormatada);
      } else {
        agendar(
          formatDateBD(dataFormatada),
          codigoPedido.split("-").length === 2
            ? codigoPedido.split("-")[0]
            : null,
          codigoPedido.split("-").length === 2
            ? codigoPedido.split("-")[1]
            : null
        );
        logAgendamento();
        fecharModal();
        setAbreDadosCliente(true);
      }
    }
  };
  const columns = [
    { field: "id", headerName: "Coletador", width: 200 },
    { field: "horario", headerName: "Horário última coleta", width: 220 },
    { field: "distancia", headerName: "Distância ultima coleta", width: 200 },
    { field: "disponibilidade", headerName: "Disponibilidade", width: 150 },
  ];

  const pegaHorarios = async () => {
    await getHorariosEVagas();
  };

  useEffect(() => {
    if (dataValida()) {
      pegaHorarios();
    }
    // eslint-disable-next-line
  }, [novaData]);

  useEffect(() => {
    setLoadingTable(true);
    if (dataValida() && mostraTabela && isEnderecoSelecionado && localizacao) {
      geraTabela();
    }
    setLoadingTable(false);
    // eslint-disable-next-line
  }, [novaData, horario, localizacao]);

  async function buscar() {
    if (!isEnderecoSelecionado || !localizacao) {
      enqueueSnackbar(
        "Digite o endereço no campo endereço e selecione uma opção sugerida!",
        {
          variant: "error",
          autoHideDuration: 3000,
        }
      );
      return;
    }
    if (!horario) {
      enqueueSnackbar("Selecione um horário!", {
        variant: "error",
        autoHideDuration: 3000,
      });
      return;
    }
    getColetadoresELocalizacao();

    setMostraTabela(true);
  }

  const pegarPossibilidadesDeEnderecoes = async () => {
    setOpenDialogBuscando(true);
    try {
      setAbrirOpcoesSelect(false);
      const enderecosFormatado = await api.get("/v2/coleta/pesquisaEndereco", {
        params: {
          endereco: pesquisaEndereco,
        },
      });
      setOpcoesEnderecos(enderecosFormatado.data);
      setOpenDialogBuscando(false);
      setAbrirOpcoesSelect(true);
    } catch (error) {
      enqueueSnackbar("Erro ao pesquisar endereço!", {
        variant: "error",
        autoHideDuration: 3000,
      });
      setOpenDialogBuscando(false);
    }
    setOpenDialogBuscando(false);
  };

  async function buscarCliente() {
    setOpenDialogBuscando(true);
    setCodigoPedido(codigoPedidoModal);

    if (codigoPedidoModal === "") {
      enqueueSnackbar("Insira um código de pedido válido", {
        variant: "error",
      });
      setOpenDialogBuscando(false);
      return;
    }
    const codigoDividido = codigoPedidoModal.split("-");
    // SalvaLog(
    //   `Pesquisou o pedido ${codigoPedido}`,
    //   "Coleta Domiciliar",
    //   JSON.stringify(codigoPedido),
    //   `${idColeta}`
    // );

    if (codigoDividido.length < 2 || codigoDividido.length > 2) {
      setCodigoPedido("");
      setCodigoPedidoModal("");
      enqueueSnackbar("Código do pedido inválido", { variant: "error" });
      setOpenDialogBuscando(false);
    } else {
      // setEspera(true);

      try {
        const cliente = await api.post("/api/softlab/pedido", {
          codigo: parseInt(codigoDividido[0], 10),
          posto: codigoDividido[1],
        });

        if (cliente.data.length === 0) {
          setCodigoPedido("");
          enqueueSnackbar("Nenhum cliente encontrado", { variant: "error" });
        } else {
          setPedidoEncontrado(cliente.data);
          setPesquisaEndereco(
            `${
              cliente.data.endereco_cliente ? cliente.data.endereco_cliente : ""
            } ${
              cliente.data.endereco_numero_cliente
                ? cliente.data.endereco_numero_cliente
                : ""
            }`
          );
        }
        setOpenDialogBuscando(false);
      } catch (error) {
        enqueueSnackbar("Falha na operação", { variant: "error" });
        setOpenDialogBuscando(false);
      }
      // setEspera(false);
    }
    setOpenDialogBuscando(false);
  }

  const descriptionElementRef = useRef(null);

  useEffect(() => {
    if (modalEditarDados) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [modalEditarDados]);

  const sxBotoesNoFinal = { display: "flex", justifyContent: "flex-end" };

  return (
    <ScopedCssBaseline>
      <Dialog
        fullWidth
        maxWidth="md"
        open={modalEditarDados}
        onClose={() => {
          fecharModal();
        }}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">
          <Grid container spacing={1}>
            <Grid item xs={12} sx={sxBotoesNoFinal}>
              <Button
                variant="contained"
                color="blue"
                onClick={() => {
                  setOpenDialogComoUsar(true);
                }}
              >
                <Help />
              </Button>
            </Grid>
            <Grid item xs={12} sm={6}>
              <DataPickerAtualizado
                setData={selecionaData}
                data={novaData}
                variant="standard"
                labelName="Selecione a data.."
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel>Horário</InputLabel>
                <Select
                  fullWidth
                  variant="standard"
                  value={horario || "Selecione"}
                  label="Horário"
                  onChange={(event) => {
                    setHorario(event.target.value);
                  }}
                >
                  <MenuItem value="Selecione" key="Selecione" disabled>
                    Selecione
                  </MenuItem>
                  {horariosEVagas.map((h) => (
                    <MenuItem value={h.horario} key={h.horario}>
                      {h.horario} - Vaga(s): {h.vagas}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid
              item
              xs={12}
              sm={6}
              sx={{ display: "flex", alignItens: "center" }}
            >
              <Grid item xs={10} sx={{ pr: "1rem" }}>
                <TextField
                  variant="standard"
                  fullWidth
                  placeholder="Pesquisa endereço"
                  value={pesquisaEndereco}
                  // onKeyPress={({ key }) => {
                  //   if (key === "Enter") {
                  //     pegarPossibilidadesDeEnderecoes();
                  //   }
                  // }}
                  onChange={(e) => {
                    setPesquisaEndereco(e.target.value);
                    setIsEnderecoSelecionado(false);
                    setLocalizacao("");
                    setLatitude("");
                    setLongitude("");
                  }}
                />
              </Grid>
              <Grid item xs={2}>
                <Button
                  variant="contained"
                  color="blue"
                  onClick={() => {
                    pegarPossibilidadesDeEnderecoes();
                  }}
                >
                  <Search />
                </Button>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth variant="standard">
                <Select
                  fullWidth
                  variant="standard"
                  onClick={() => {
                    setAbrirOpcoesSelect(!abrirOpcoesSelect);
                  }}
                  open={abrirOpcoesSelect}
                  value={localizacao || "Selecione"}
                  label="Opções endereços"
                  onChange={(e) => {
                    e.preventDefault();
                    setLocalizacao(e.target.value);
                    setLatitude(e.target.value.geometry.location.lat);
                    setLongitude(e.target.value.geometry.location.lng);
                    setIsEnderecoSelecionado(true);
                    setAbrirOpcoesSelect(false);
                  }}
                >
                  <MenuItem value="Selecione" key="Selecione" disabled>
                    Selecione
                  </MenuItem>
                  {opcoesEnderecos.map((endereco) => (
                    <MenuItem value={endereco} key={endereco.formatted_address}>
                      {endereco.formatted_address}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              sx={{ display: "flex", alignItens: "center" }}
            >
              <Grid item xs={10} sx={{ pr: "1rem" }}>
                <TextField
                  variant="standard"
                  fullWidth
                  placeholder="Pedido"
                  value={codigoPedidoModal === "null-" ? "" : codigoPedidoModal}
                  onKeyPress={({ key }) => {
                    if (key === "Enter") {
                      buscarCliente();
                    }
                  }}
                  onChange={(e) => {
                    e.preventDefault();
                    setCodigoPedidoModal(e.target.value);
                    // setCodigoPedido(e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={2}>
                <Button
                  variant="contained"
                  color="blue"
                  type="button"
                  onClick={() => {
                    buscarCliente();
                  }}
                >
                  <Search />
                </Button>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6} sx={sxBotoesNoFinal}>
              <Button
                variant="contained"
                color="blue"
                fullWidth={tamanho}
                onClick={() => {
                  buscar();
                }}
              >
                Buscar
              </Button>
            </Grid>
            {textoMelhorHorario && (
              <Grid item xs={12}>
                <Stack sx={{ width: "100%" }} spacing={2}>
                  <Alert severity="success">{textoMelhorHorario}</Alert>
                </Stack>
              </Grid>
            )}
          </Grid>
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
            {mostraTabela ? (
              <Box
                sx={{
                  height: 480,
                  width: "100%",
                  "& .indisponivel": { color: "#99250F" },
                }}
              >
                <Tabela
                  semToolbar
                  rows={rows}
                  columns={columns}
                  pageSize={7}
                  rowsPerPageOptions={[7]}
                  radioSelection
                  loading={loadingTable}
                  getCellClassName={(params) => {
                    if (
                      params.field === "disponibilidade" &&
                      params.value === "Indisponível"
                    ) {
                      return "indisponivel";
                    }
                    return "";
                  }}
                  onCellClick={(e) => {
                    if (!e.row.jaSelecionado) {
                      setColetador(e.row.id);
                    } else {
                      setColetador("");
                      enqueueSnackbar(
                        "Esse coletador já possui uma coleta para esse horário!",
                        {
                          variant: "error",
                          autoHideDuration: 3000,
                        }
                      );
                    }
                  }}
                />
              </Box>
            ) : null}
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ padding: "16px 24px" }}>
          <Grid item xs={12} sx={sxBotoesNoFinal}>
            <Button
              fullWidth={tamanho}
              variant="contained"
              color="green"
              onClick={() => {
                handleAgendar();
              }}
            >
              Agendar
            </Button>
          </Grid>
        </DialogActions>
        <DialogComoUsar
          open={openDialogComoUsar}
          onClose={() => {
            setOpenDialogComoUsar(false);
          }}
        />
        <DialogBuscando
          open={openDialogBuscando}
          onClose={() => setOpenDialogBuscando(false)}
        />
      </Dialog>
      <Waiter espera={espera} />
    </ScopedCssBaseline>
  );
}

const DialogComoUsar = ({ open, onClose }) => (
  <Dialog
    open={open}
    onClose={onClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">Como usar a ferramenta:</DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description" sx={{ color: "black" }}>
        1. Selecione o dia da coleta
      </DialogContentText>
      <DialogContentText id="alert-dialog-description" sx={{ color: "black" }}>
        2. Selecione o horário
      </DialogContentText>
      <DialogContentText id="alert-dialog-description" sx={{ color: "black" }}>
        3. Digite no campo `&ldquo;`Pesquisa endereço`&rdquo;` {">"} Pressione
        enter {">"} Selecione o Endereço correto
      </DialogContentText>
      <DialogContentText id="alert-dialog-description" sx={{ color: "black" }}>
        4. Clique em buscar
      </DialogContentText>
      <DialogContentText id="alert-dialog-description" sx={{ color: "black" }}>
        5. Selecione o coletador
      </DialogContentText>
      <DialogContentText id="alert-dialog-description" sx={{ color: "black" }}>
        6. Clique em agendar
      </DialogContentText>
    </DialogContent>
  </Dialog>
);

const DialogBuscando = ({ open, onClose }) => (
  <Dialog
    open={open}
    onClose={onClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">Buscando informações...</DialogTitle>
    <Grid container spacing={2} minHeight={160}>
      <Grid xs display="flex" justifyContent="center" alignItems="center">
        <img
          src={loading}
          alt="spinning loading"
          style={{ height: 100, width: 100 }}
        />
      </Grid>
    </Grid>
  </Dialog>
);

export default ModalEditarDados;
