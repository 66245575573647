import CloseIcon from "@mui/icons-material/Close";
import { Button, Container, Grid, Modal, Paper, TextField } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { Context as PermissoesContext } from "../../../Context/PermissoesContext";
import { api } from "../../../services/Api";
import DadosContext from "../DadosContext";
import SalvaLog from "../../../utils/SalvaLog";

export default function AdicionarProduto() {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const { checarPermissao } = useContext(PermissoesContext);
  const [open, setOpen] = useState(false);
  const [categoriaSelecionada, setCategoriaSelecionada] = useState("");
  const [descricaoSelecionada, setDescricaoSelecionada] = useState("");
  const { update, setUpdate } = useContext(DadosContext);

  function limpaCampos() {
    setCategoriaSelecionada("");
    setDescricaoSelecionada("");
    setOpen(false);
  }

  async function criaCategoria(event) {
    event.preventDefault();
    if (!categoriaSelecionada) {
      return enqueueSnackbar(
        "Não é possível cadastrar uma categoria sem inserir uma categoria",
        {
          variant: "error",
          autoHideDuration: 3000,
        }
      );
    }
    try {
      await api.post("/v2/almoxarifado/categoria", {
        categoria: categoriaSelecionada,
        descricao: descricaoSelecionada,
      });
      SalvaLog(`Categoria cadastrada: ${categoriaSelecionada}`, "Almoxarifado");
      enqueueSnackbar("Categoria foi adicionada!", {
        variant: "success",
      });
      limpaCampos();
      setUpdate(!update);
    } catch (error) {
      enqueueSnackbar("Não foi possível cadastrar a categoria, tente novamente", {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  }

  const corpoModal = (
    <form onSubmit={criaCategoria}>
      <Container maxWidth="sm" style={{ padding: "0" }}>
        <Paper className="paper-cadastro" elevation={2}>
          <Grid
            container
            alignItems="center"
            justifyContent="center"
            style={{ padding: "1rem 1rem" }}
          >
            <Grid item xs={6} sm={6}>
              <h2>Cadastrar Categoria</h2>
            </Grid>
            <Grid id="botao-fechar" item xs={6} sm={6}>
              <Button type="button" onClick={() => setOpen(false)}>
                <CloseIcon fontSize="medium" style={{ color: "#003399" }} />
              </Button>
            </Grid>
            <Grid className="input-text-modal" item xs={12} sm={12}>
              <TextField
                value={categoriaSelecionada}
                onChange={e => setCategoriaSelecionada(e.target.value)}
                label="Categoria"
                variant="outlined"
                size="small"
                fullWidth
              />
            </Grid>
            <Grid className="input-text-modal" item xs={12} sm={12}>
              <TextField
                value={descricaoSelecionada}
                onChange={e => setDescricaoSelecionada(e.target.value)}
                label="Descrição"
                variant="outlined"
                size="small"
                fullWidth
              />
            </Grid>
            <Grid className="input-text-modal" id="botao-cadastrar" item xs={12}>
              <Button type="submit" variant="contained" disabled={!categoriaSelecionada}>
                Cadastrar
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Container>
    </form>
  );

  const handleClickModal = () => {
    if (!checarPermissao("AL007")) {
      enqueueSnackbar("É necessário a permissão AL007", {
        variant: "warning",
      });
      history.push("/cadastraCategoria");
    } else {
      setOpen(true);
    }
  };
  return (
    <>
      <Button
        fullWidth
        variant="contained"
        style={{ color: "#fff", backgroundColor: "#003399" }}
        onClick={() => handleClickModal()}
      >
        Adicionar Categoria
      </Button>
      <Modal open={open} onClose={() => setOpen(false)}>
        {corpoModal}
      </Modal>
    </>
  );
}
