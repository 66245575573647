import { Button as MuiButton, Grid, Paper } from "@mui/material";
import React, { useState, useContext } from "react";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useHistory, useLocation } from "react-router-dom";
import Button from "../../../../../componentsv2/Button";
import { Input } from "../../../../../componentsv2/Input";
import DataPickerAtualizado from "../../../../../components/DataPickerAtualizado";
import { Context as PermissoesContext } from "../../../../../Context/PermissoesContext";
import { api } from "../../../../../services/Api";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import Container from "../../../../../componentsv2/Container";
import Header from "../../../../../components/Header";

export default function CriaInventario() {
  const history = useHistory();
  const [nome, setNome] = useState("");
  const [dataFim, setDataFim] = useState(new Date());
  const [rowId, setRowId] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();
  const edicaoInventario = location.state;
  const { checarPermissaoSemLog } = useContext(PermissoesContext);
  const [permitirCriar, setPermitirCriar] = useState(false);

  async function getInventarios() {
    try {
      const {
        data: { data },
      } = await api.get(`/v2/inventario/inventarios`);

      const verificaSituacoes = data.find(inventario => inventario.situacao === "Aberto");

      if (!verificaSituacoes) {
        setPermitirCriar(true);
      } else {
        setPermitirCriar(false);
      }
    } catch (error) {
      enqueueSnackbar("Erro! Não foi possível buscar os inventários cadastrados.", {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  }

  // useEffect(() => {
  //   if (!checarPermissaoSemLog("INV003")) {
  //     history.push({ pathname: "/inventario", state: { abaInv: true } });
  //     enqueueSnackbar("Você não possui permissão para cadastrar um inventário.", {
  //       variant: "error",
  //       autoHideDuration: 3000,
  //     });
  //   }
  // }, [checarPermissaoSemLog, history, enqueueSnackbar]);

  useEffect(() => {
    async function getInventario() {
      try {
        if (edicaoInventario) {
          const {
            data: { data },
          } = await api.get(`/v2/inventario/inventario/${edicaoInventario.dados.id}`);

          setRowId(edicaoInventario.dados.id);
          setNome(data.nome);
          setDataFim(data.dataFim);
        }
      } catch (error) {
        enqueueSnackbar("Erro! Não foi possível editar o inventário.", {
          variant: "error",
        });
      }
    }
    getInventario();
  }, [edicaoInventario, enqueueSnackbar]);

  useEffect(() => {
    getInventarios();
    // eslint-disable-next-line
  }, []);

  const handleSubmit = async e => {
    e.preventDefault();
    try {
      if (await checarPermissaoSemLog("INV003")) {
        const data = {
          nome,
          dataInicio: new Date().toISOString(),
          dataFim: new Date(dataFim).toISOString(),
        };

        if (edicaoInventario) {
          await api.put(`/v2/inventario/inventario/${rowId}`, data);

          enqueueSnackbar("O inventário foi editado com sucesso!", {
            variant: "success",
            autoHideDuration: 3000,
          });
        } else {
          if (permitirCriar) {
            await api.post(`/v2/inventario/inventario`, data);

            enqueueSnackbar("O inventário foi cadastrado com sucesso!", {
              variant: "success",
              autoHideDuration: 3000,
            });
          } else {
            enqueueSnackbar(
              "Você não pode criar um novo inventário, ainda existe um inventário em aberto!",
              {
                variant: "warning",
                autoHideDuration: 7000,
              }
            );
          }
        }
      } else {
        enqueueSnackbar("Erro! Você não tem permissão para criar um inventário.", {
          variant: "error",
          autoHideDuration: 3000,
        });
      }

      history.push({ pathname: "/inventario", state: { abaInv: true } });
    } catch (error) {
      enqueueSnackbar("Erro! Não foi possível cadastrar o inventário.", {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  return (
    <>
    <Header titulo="Cadastrar Inventário" />
    <Container>
      <MuiButton onClick={() => {}}>
        <ArrowBackIcon
          style={{ color: "#003399" }}
          onClick={() =>
            history.push({ pathname: "/inventario", state: { abaInv: true } })
          }
        />
      </MuiButton>
      <Paper style={{ padding: "40px", marginTop: "10px" }} elevation={2}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <Input
                value={nome}
                onChange={e => setNome(e.target.value)}
                required
                fullWidth
                label="Nome"
                type="text"
                variant="standard"
              />
            </Grid>

            <Grid item xs={6}>
              <DataPickerAtualizado
                data={dataFim}
                setData={setDataFim}
                variant="standard"
                labelName="Data Fim"
              />
            </Grid>

            <Grid
              item
              sx={{ marginTop: "30px" }}
              container
              justifyContent="space-between"
              spacing={0}
            >
              <Grid item>
                <Button
                  onClick={() =>
                    history.push({ pathname: "/inventario", state: { abaInv: true } })
                  }
                  color="red"
                  variant="outlined"
                >
                  Cancelar
                </Button>
              </Grid>

              <Grid item>
                <Button type="submit" color="green" variant="contained">
                  Salvar
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Paper>
      </Container>
    </>
  );
}
