import React, { useContext } from 'react';
import { format } from 'date-fns';

import InputData from '../../../components/InputData';

import { Context } from "../../../Context/PermissoesContext";

import "./styles.css";

export default function Formulario(props) {
    const data = format(new Date(), "yyyy-MM-dd");
    const {
      tabela,
      status_atual,
      placa,
      novaData,
      setNovaData,
      status_pcr,
      fechaMostra_mapa,
      setResultado_pesquisa_amostra,
      setTabela,
      setMostra_mapa,
      setCodigo_pedido,
      montaPlaca,
      setAdiciona_amostra,
      setNomeia_mapa
     }= props;

     const { checarPermissao } = useContext(Context);

    function selecionaData(data1) {
      setNovaData(data1);
    }

    return (

      <div>

        <div id="container-aba-ctrl-samples">

          {(checarPermissao("PCR002", false)) &&
            (
              <button
                type="button"
                id={(tabela === 'bi') ? "button-aba-ctrl-samples-selected" : 'Aba1'}
                className="button-aba-ctrl-samples"
                onClick={() =>{
                 fechaMostra_mapa();
                 setResultado_pesquisa_amostra([]);
                 setTabela('bi');
                }}
              >
                BI
              </button>
              )}

          <button
            type="button"
            id={(tabela === 'placa') ? "button-aba-ctrl-samples-selected" : 'Aba1'}
            className="button-aba-ctrl-samples"
            onClick={() =>{
              fechaMostra_mapa();
              setResultado_pesquisa_amostra([]);
              setTabela('placa');
            }}
          >
            Placas
          </button>

          <button
            type="button"
            id={(tabela === 'mapa') ? "button-aba-ctrl-samples-selected" : 'Aba3'}
            className="button-aba-ctrl-samples"
            onClick={() => {
              setMostra_mapa(true);
              setResultado_pesquisa_amostra([]);
              setTabela('mapa');
            }}
          >
            Mapas
          </button>

          <button
            type="button"
            id={(tabela === 'pesquisa') ? "button-aba-ctrl-samples-selected" : 'Aba2'}
            className="button-aba-ctrl-samples"
            onClick={() => {
              setCodigo_pedido('');
              fechaMostra_mapa();
              setTabela('pesquisa');
            }}
          >
            Pesquisa
          </button>
        </div>

        {(tabela !== 'pesquisa') &&
         (
         <div id="content-form-ctrl-samples">

           <InputData
             labelText="Selecione a data"
             data={novaData}
             acao={selecionaData}
           />

           {((tabela === 'placa') && (status_atual === 'Triagem') && (placa.length < 1) && (data === novaData)) &&
            (
              <button
                type="button"
                className="button-form-samples"
                onClick={() => {
                  montaPlaca();
                  setAdiciona_amostra(true);
                }}
              >
                Adicionar
              </button>
            )}

           {((tabela === 'mapa') && (status_pcr === "Preparação") && (data === novaData)) &&
            (
              <button
                type="button"
                className="button-form-samples"
                onClick={() => {
                  setNomeia_mapa(true) ;
                }}
              >
                Criar Mapa
              </button>
            )}
         </div>
  )}

      </div>
    )
}
