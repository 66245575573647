import React, { useEffect, useState, useContext } from "react";
import { Grid, Paper, IconButton, Box } from "@mui/material";
import Tabela from "../../../../../componentsv2/Tabela";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { Input } from "../../../../../componentsv2/Input";
import { ModalForm } from "../../../../../componentsv2/ModalForm";
import OpcoesTipoMaterial from "./opcoesTipoMaterial";
import filtroPesquisa from "../../../../../utils/filtroPesquisa";
import { useSnackbar } from "notistack";
import { api } from "../../../../../services/Api";
import { Context as PermissoesContext } from "../../../../../Context/PermissoesContext";

export default function TipoMaterial() {
  const [tipoMaterialModal, setTipoMaterialModal] = useState(false);
  const [arrayDeTipos, setArrayDeTipos] = useState([]);
  const [pesquisa, setPesquisa] = useState("");
  const [nome, setNome] = useState("");
  const [update, setUpdate] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [id, setId] = useState("");
  const { checarPermissao } = useContext(PermissoesContext);

  const handleChangeSearch = e => {
    setPesquisa(e.target.value);
  };

  async function getTipoDeMaterialEdit() {
    try {
      const {
        data: { data },
      } = await api.get(`/v2/inventario/tipoMaterial/${id}`);
      setNome(data.nome);
    } catch (error) {
      enqueueSnackbar("Erro! Não foi possível buscar o tipo de material.", {
        variant: "error",
      });
    }
  }

  useEffect(() => {
    if (id) {
      getTipoDeMaterialEdit();
    }
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    async function getTiposDeMateriais() {
      try {
        const { data } = await api.get(`/v2/inventario/tiposMateriais`);
        setArrayDeTipos(data.data);
      } catch (error) {
        enqueueSnackbar("Erro! Não foi possível buscar os tipos de materiais.", {
          variant: "error",
        });
      }
    }

    getTiposDeMateriais();
  }, [enqueueSnackbar, nome, update, checarPermissao]);

  const clearFields = () => {
    setNome("");
  };

  const handleSubmit = async e => {
    e.preventDefault();
    try {
      const data = {
        nome,
      };

      clearFields();
      setTipoMaterialModal(false);
      if (id) {
        await api.put(`/v2/inventario/tipoMaterial/${id}`, data);
        setUpdate(!update);
        enqueueSnackbar("Tipo de material editado com sucesso!", {
          variant: "success",
          autoHideDuration: 3000,
        });
      } else {
        await api.post(`/v2/inventario/tipoMaterial`, data);
        setUpdate(!update);
        enqueueSnackbar("Tipo de material cadastrado com sucesso!", {
          variant: "success",
          autoHideDuration: 3000,
        });
      }
    } catch (error) {
      enqueueSnackbar("Erro ao tentar criar um tipo de material.", {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  const columns = [
    {
      field: "nome",
      headerName: "Nome",
      flex: 1,
      type: "text",
    },
    {
      field: "ativo",
      headerName: "Situação",
      flex: 1,
      type: "text",
      valueGetter: ({ value }) => (value === true ? "Ativo" : "Inativo"),
    },
    {
      field: "opcoes",
      headerName: "Opções",

      width: 130,

      renderCell: params => (
        <div style={{ cursor: "pointer" }}>
          <OpcoesTipoMaterial
            row={params.row}
            setId={setId}
            update={update}
            setUpdate={setUpdate}
            setTipoMaterialModal={setTipoMaterialModal}
          />
        </div>
      ),
    },
  ];
  const filtrarNomes = filtroPesquisa(arrayDeTipos, "nome", pesquisa);

  return (
    <Grid container alignItems="center" spacing={2} direction="column">
      <Grid container xs={12} item>
        <Paper sx={{ width: "100%", marginBottom: "30px", padding: "10px" }}>
          <Grid item container sx={{ marginLeft: "5px" }} justifyContent="space-between">
            <Grid item xs={6}>
              <Input
                onChange={e => handleChangeSearch(e)}
                label="Pesquise pelo nome"
                variant="standard"
                size="small"
                fullWidth
                type="text"
              />
            </Grid>
            <Grid xs={2} sm={2} md={1} lg={1} item>
              <IconButton
                onClick={() => {
                  if (checarPermissao("INV011")) {
                    setTipoMaterialModal(true);
                  } else {
                    enqueueSnackbar(
                      "Você não possui permissão para cadastrar um tipo de material!",
                      {
                        variant: "error",
                        autoHideDuration: 3000,
                      }
                    );
                  }
                }}
                color="success"
                size="large"
              >
                <AddCircleOutlineIcon fontSize="large" />
              </IconButton>
            </Grid>
            <ModalForm
              onSubmit={handleSubmit}
              title="Adicionar Tipo de Material"
              onClose={setTipoMaterialModal}
              open={tipoMaterialModal}
            >
              <Grid item xs={12} sm={12} lg={12}>
                <Input
                  value={nome}
                  onChange={e => setNome(e.target.value)}
                  required
                  fullWidth
                  variant="standard"
                  label="Nome"
                  type="text"
                />
              </Grid>
            </ModalForm>
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={12} container>
        <Grid style={{ paddingTop: "10px" }} xs={12} sm={12} item>
          <Grid spacing={2} container>
            <Grid style={{ paddingTop: "10px" }} item xs={12} sm={12}>
              <Paper elevation={2}>
                <Box
                  sx={{
                    height: 1,
                    width: "100%",
                    marginBottom: "10px",
                  }}
                >
                  <div>
                    <Tabela rows={filtrarNomes} columns={columns} autoHeight />
                  </div>
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
