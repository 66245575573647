const colunasTabela = [
  {
    field: "id",
    headerName: "Nº",
    headerAlign: "center",
    align: "center",
    type: "number",
    width: 150,
  },
  {
    field: "codigoPosto",
    headerName: "Codigo-Posto",
    headerAlign: "center",
    align: "center",
    type: "string",
    width: 150,
  },
  {
    field: "cpf",
    headerName: "CPF",
    headerAlign: "center",
    align: "center",
    type: "string",
    width: 150,
  },
  {
    field: "tipo",
    headerName: "Tipo",
    headerAlign: "center",
    align: "center",
    type: "string",
    width: 150,
  },
  {
    field: "notificado",
    headerName: "Notificado",
    headerAlign: "center",
    align: "center",
    type: "boolean",
    width: 150,
  },
  {
    field: "data_liberacao",
    headerName: "Data Liberacao",
    headerAlign: "center",
    align: "center",
    type: "dateTime",
    width: 150,
    valueGetter: ({ value }) => value && new Date(value),
  },
  {
    field: "erro",
    headerName: "Erro",
    headerAlign: "center",
    align: "center",
    type: "string",
    width: 150,
  },
];

export default colunasTabela;
