import React, { useState, useContext } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import {
  Box,
  Button,
  CssBaseline,
  TextField,
  Typography,
  Container } from "@mui/material";

import { Context as AuthContext } from "../../Context/AuthContext";

import pkgJson from "../../../package.json";

import LogoDNA from "../../assets/dna-nova-logo.png";

import "./styles.css";

export default function Login() {
  const history = useHistory();
  const location = useLocation();

  const url = location.pathname;

  const { login, authenticated } = useContext(AuthContext);

  const [usuario, setUsuario] = useState("");
  const [senha, setSenha] = useState("");

  const { enqueueSnackbar } = useSnackbar();

  async function autenticarUsuario(e) {
    e.preventDefault();
    try {
      await login(usuario, senha, url);
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error", autoHideDuration: 3000 });
      setSenha("");
    }
  }

  if (authenticated) {
    let modulo = "/home";
    if (url) {
      modulo = `${url}`;
    }
    return history.push(modulo);
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div style={{ marginTop: "30px", display: "flex", flexDirection: "column", alignItems: "center" }}>
        <img
          className="imgLogoLogin"
          src={LogoDNA}
          alt="DNA Center"
          style={{ marginBottom: "2rem" }}
        />

        <Typography className="labelAutenticacao" component="h1" variant="h5">
          Bem Vindo(a) à Intranet!
        </Typography>
        <form onSubmit={e => autenticarUsuario(e)} style={{ width: "100%", marginTop: "8px" }} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="usuario"
            label="Usuário"
            name="usuario"
            autoComplete="usuario"
            value={usuario}
            onChange={e => setUsuario(e.target.value.toUpperCase())}
            autoFocus
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="senha"
            label="Senha"
            type="password"
            id="senha"
            value={senha}
            autoComplete="current-password"
            onChange={e => setSenha(e.target.value)}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
          >
            Entrar
          </Button>
        </form>
      </div>
      <Box
        sx={{
          color: "#797979",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "60%",
        }}
      >
        v{pkgJson.version}
      </Box>
    </Container>
  );
}
