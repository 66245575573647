import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { Paper } from '@mui/material';
import { KeyboardBackspace } from '@mui/icons-material';

import Header from "../../../components/Header";
import DadosPaciente from "../../../components/DadosPaciente";
import Loading from "../../../components/Loading";

import useUser from "../../../hooks/useUser";

import { api } from "../../../services/Api";
import "./styles.css";

export default function RespostasFormulario() {
  const usuario = useUser();

  const history = useHistory();
  const params = useParams();
  const { enqueueSnackbar } = useSnackbar();

  const [formulario, setFormulario] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      const { id: formID } = params;
      let data;
      try {
        const response = await api.get(`/api/formularioCovid/${formID}`);
        data = response.data;
      } catch (e) {
        enqueueSnackbar("Houve um problema em recuperar as respostas desse formulário.", {
          variant: "error",
          autoHideDuration: 5000,
        });
      }
      setFormulario(data);
      setLoading(false);
    })();
  }, [enqueueSnackbar, params]);
  return (
    <>
      <Header
        titulo={`Respostas Formulário `}
        usuario={usuario}
        history={history}
      />
      {loading && <Loading />}
      <main className="respostas-wrapper">
        <KeyboardBackspace
          fontSize="large"
          style={{ marginTop: "2rem", cursor: "pointer" }}
          onClick={() => history.push("/formularioCovid")}
        />
        {formulario !== null && (
          <>
            <DadosPaciente
              paciente={{
                ...formulario,
                nome: formulario.nome_completo,
              }}
              dataISO
              style={{ marginBottom: "1.5rem" }}
            />

            <Paper
              className="sintomas-paciente"
              style={{
                padding: "1.5rem",
                marginBottom: "1.5rem",
              }}
            >
              <h2 style={{ marginBottom: "1.5rem" }}>Sintomas do paciente</h2>
              <p>{formulario.sintomas}</p>
              {formulario.outros_sintomas !== "" && <p>{formulario.outros_sintomas}</p>}
            </Paper>

            <Paper style={{ padding: "1.5rem" }} className="condicoes-paciente">
              <h2 style={{ marginBottom: "1.5rem" }}>Condições do paciente</h2>
              <p>
                {formulario.condicoes !== null
                  ? formulario.condicoes
                  : "O paciente não possui nenhuma comorbidade."}
              </p>
              <p>
                <b>Motivo para fazer o teste: </b>
                {formulario.motivo}
              </p>
            </Paper>
          </>
        )}
      </main>
    </>
  );
}
