import React, { useContext } from "react";
import { Paper } from "@mui/material";

import filtroPesquisa from "../../../utils/filtroPesquisa";
import DadosContext from "../DadosContext";
import Tabela from "../../../componentsv2/Tabela";

export default function TabelaProdutos() {
  const { pesquisado, categoriasObtidas } = useContext(DadosContext);

  const coluna = [
    {
      field: "id",
      headerName: "ID",
      headerAlign: "center",
      hide: true,
    },
    {
      field: "categoria",
      headerName: "Categoria",
      width: 230,
      headerAlign: "center",
    },
    {
      field: "descricao",
      headerName: "Descrição",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
  ];

  const filtrada = filtroPesquisa(categoriasObtidas, "categoria", pesquisado);

  return (
    <Paper elevation={2}>
      <div style={{ height: 400, width: "100%" }}>
        <Tabela
          initialState={{ columns: { columnVisibilityModel: { id: false } } }}
          rows={filtrada}
          columns={coluna}
          rowsPerPage={5}
          disableColumnMenu
        />
      </div>
    </Paper>
  );
}
