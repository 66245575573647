export default function converteData(data) {
  if (data.length === 10) {
    const data_conv =
        `${data.slice(8, 10)
        }/${
        data.slice(5, 7)
        }/${
        data.slice(0, 4)}`;

    return data_conv;
  }
  return null
}
