import React, { createContext, useContext, useEffect, useState } from "react";
import { useSnackbar } from "notistack";

import formatDateBD from "../../../utils/formatarDataBD";
import SalvaLog from "../../../utils/SalvaLog";

import { FormularioColetaDomiciliarContext } from "./FormularioColetaDomiciliarContext";

import useUser from "../../../hooks/useUser";

import { api } from "../../../services/Api";

const ColetaDomiciliarContext = createContext();

function ColetaDomiciliarContextProvider({ children }) {
  const { enqueueSnackbar } = useSnackbar();
  const usuario = useUser();

  const [novaData, setNovaData] = useState(new Date());
  const [dataFinal, setDataFinal] = useState(new Date());

  const [agendamentos, setAgendamentos] = useState([]);
  const [loadingAgendamentos, setLoadingAgendamentos] = useState(false);
  const [coletadores, setColetadores] = useState([]);
  const [vetorHora, setVetorHora] = useState([]);

  const [coletador, setColetador] = useState("");
  const [coleta, setColeta] = useState("");
  const [status, setStatus] = useState("");

  const [atualizaTabela, setAtualizaTabela] = useState(false);

  const {
    tipo_cliente,
    setTipo_cliente,
    valor_recebido,
    setValor_recebido,
    cpf_cliente,
    setCpf_cliente,
    nome,
    setNome,
    endereco,
    setEndereco,
    endereco_numero,
    setEndereco_numero,
    complemento,
    setComplemento,
    bairro,
    setBairro,
    cidade,
    setCidade,
    estado,
    setEstado,
    cep,
    setCep,
    celular,
    setCelular,
    email,
    setEmail,
    codigoPedido,
    tipo_pagamento,
    setTipo_pagamento,
    numero_cartao,
    setNumero_cartao,
  } = useContext(FormularioColetaDomiciliarContext);

  useEffect(() => {
    async function iniciar() {
      try {
        setLoadingAgendamentos(true);
        const dataConvertida = new Date(novaData);
        const result1 = await api.get(
          `/api/coletaDomiciliar/agenda/${formatDateBD(dataConvertida)}`
        );
        if (result1.data) {
          let filtroColetador = result1.data;
          if (coletador) {
            filtroColetador = filtroColetador.filter((item) =>
              coletador.includes(item.coletador)
            );
          }
          setAgendamentos(filtroColetador);
          setLoadingAgendamentos(false);
        }
      } catch (error) {
        setLoadingAgendamentos(false);
        enqueueSnackbar("Falha na operação", { variant: "error" });
      }
      setLoadingAgendamentos(false);
    }
    iniciar();
  }, [enqueueSnackbar, atualizaTabela, coletador, novaData]);

  useEffect(() => {
    async function iniciarColetadores() {
      try {
        const dataConvertida = new Date(novaData);
        const result1 = await api.get(
          `/api/coletaDomiciliar/agenda/${formatDateBD(dataConvertida)}`
        );
        const result2 = await api.get("/api/coletaDomiciliar/coletadores");
        if (result1.data) {
          setColetadores(result2.data);
        }
      } catch (error) {
        enqueueSnackbar("Coletadores não carregados", { variant: "error" });
        console.log("Erro em iniciarColetadores - ", error);
      }
    }
    iniciarColetadores();
  }, [enqueueSnackbar]);

  useEffect(() => {
    async function iniciar() {
      if (coletador && novaData) {
        try {
          const result = await api.get(
            `/api/ColetaDomiciliar/disponibilidade/${formatDateBD(
              novaData
            )}/${coletador}`
          );

          if (result.data) {
            setVetorHora(result.data);
          }
        } catch (error) {
          enqueueSnackbar("Horários não carregados", { variant: "error" });
        }
      }
    }
    iniciar();
  }, [coletador, novaData, enqueueSnackbar]);

  function zeraColetaRecebida() {
    setColeta("");
    setStatus("");
    setValor_recebido("0.00");
    setTipo_cliente("Normal");
    setCpf_cliente("");
    setTipo_pagamento("");
    setNumero_cartao("");
    setNome("");
    setEndereco("");
    setEndereco_numero("");
    setComplemento("");
    setBairro("");
    setCidade("");
    setEstado("");
    setCep("");
    setCelular("");
    setEmail("");
  }

  //zera dados do modal de pagamento
  function zeraDadosModal() {
    setValor_recebido("0.00");
    setTipo_cliente("Normal");
    setCpf_cliente("");
    setTipo_pagamento("");
    setNumero_cartao("");
    setNome("");
    setEmail("");
  }

  async function enviaParaContasaReceber() {
    try {
      const valorRecebidoConvertido = valor_recebido.replaceAll(",", ".");

      await api.post(`/v2/contasaReceber/adicionaContasAReceber`, {
        origem: "COLETA DOMICILIAR",
        origemId: coleta.id,
        cpfCliente: cpf_cliente,
        nome,
        endereco,
        endereco_numero,
        complemento,
        bairro,
        cidade,
        estado,
        cep,
        celular,
        email,
        codigoPedido,
        valor: valorRecebidoConvertido,
      });

      setAtualizaTabela((prevState) => !prevState);
      enqueueSnackbar("Conta a receber", { variant: "success" });
    } catch (error) {
      enqueueSnackbar("Falha ao salvar conta a receber", { variant: "error" });
    }
  }

  async function atualizaStatus() {
    const dadosLog = {
      origem: "COLETA DOMICILIAR",
      origemId: coleta.id,
      cpfCliente: cpf_cliente,
      nome,
      endereco,
      endereco_numero,
      complemento,
      bairro,
      cidade,
      estado,
      cep,
      celular,
      email,
      codigoPedido,
      valor: valor_recebido,
    };

    try {
      await api.put("/api/coletaDomiciliar/status", {
        id: coleta.id,
        status,
      });

      setAtualizaTabela((prev) => !prev);

      enqueueSnackbar("Status alterado", { variant: "success" });

      SalvaLog(
        `Alterou o status do cliente ${coleta.localizador} do agendamento de ID ${coleta.id} de ${coleta.status} para ${status}`,
        "Coleta Domiciliar",
        JSON.stringify(dadosLog),
        `${coleta.id}`
      );
    } catch (error) {
      SalvaLog(
        `Tentou alterar o status do cliente ${coleta.localizador} do agendamento de ID ${coleta.id} de ${coleta.status} para ${status}`,
        "Coleta Domiciliar",
        JSON.stringify(dadosLog),
        `${coleta.id}`
      );
      enqueueSnackbar("Não foi possível alterar o status", {
        variant: "error",
      });
    }
  }

  async function pagamento() {
    const valorRecebidoConvertido = valor_recebido.replaceAll(",", ".");
    const dadosPagamento = {
      id: coleta.id,
      valor_recebido: valorRecebidoConvertido
        ? parseFloat(valorRecebidoConvertido)
        : null,
      cpf: cpf_cliente,
      tipo_cliente,
      tipo_pagamento: tipo_pagamento || "",
      numero_cartao: numero_cartao || "",
    };

    try {
      await api.put("/api/coletaDomiciliar/recebimento", dadosPagamento);
      await api.put("/api/coletaDomiciliar/status", {
        id: coleta.id,
        status: "Coletado",
      });

      zeraColetaRecebida();

      SalvaLog(
        `Pagamento do cliente ${coleta.localizador} do agendamento de ID ${coleta.id}`,
        "Coleta Domiciliar",
        JSON.stringify(dadosPagamento),
        `${coleta.id}`
      );
      setAtualizaTabela((prev) => !prev);
    } catch (error) {
      SalvaLog(
        `Tentou fazer o pagamento do cliente ${coleta.localizador} do agendamento de ID ${coleta.id}`,
        "Coleta Domiciliar",
        JSON.stringify(dadosPagamento),
        `${coleta.id}`
      );
    }
  }

  function mudaValor(e, setValor) {
    e.preventDefault();
    setValor(e.target.value);
  }

  async function selecionaData(data) {
    setNovaData("");
    setNovaData(data);
    setAtualizaTabela((prev) => !prev);
  }

  function selecionaDataFinal(data) {
    setDataFinal(data);
  }

  async function selecionaColetador(e) {
    e.preventDefault();
    setColetador(e.target.value);
  }

  return (
    <ColetaDomiciliarContext.Provider
      value={{
        novaData,
        setNovaData,
        dataFinal,
        setDataFinal,
        agendamentos,
        setAgendamentos,
        loadingAgendamentos,
        coletadores,
        setColetadores,
        coleta,
        setColeta,
        status,
        setStatus,
        vetorHora,
        setVetorHora,
        coletador,
        setColetador,
        atualizaStatus,
        enviaParaContasaReceber,
        usuario,
        selecionaData,
        selecionaDataFinal,
        selecionaColetador,
        mudaValor,
        atualizaTabela,
        setAtualizaTabela,
        pagamento,
        zeraDadosModal,
      }}
    >
      {children}
    </ColetaDomiciliarContext.Provider>
  );
}

export { ColetaDomiciliarContext, ColetaDomiciliarContextProvider };
