/* eslint-disable jsx-a11y/media-has-caption */
import React, { useRef } from "react";
import VideoCallIcon from "@mui/icons-material/VideoCall";
import PanoramaIcon from "@mui/icons-material/Panorama";
import CloseIcon from "@mui/icons-material/Close";
import { baseUrl } from "../../../../services/Api";
import Button from "../../../../components/Button";

import "./styles.css";

export default function Video({
  video,
  thumbnail,
  onVideoChange,
  onVideoRemove,
  onThumbnailChange,
  onThumbnailRemove,
}) {
  const videoInputRef = useRef(null);
  const thumbnailInputRef = useRef(null);
  return (
    <div className="video-container">
      <input
        ref={videoInputRef}
        type="file"
        style={{ display: "none" }}
        accept="video/*"
        onChange={e => onVideoChange(e.target.files[0])}
      />
      <input
        type="file"
        ref={thumbnailInputRef}
        style={{ display: "none" }}
        accept="image/*"
        onChange={e => onThumbnailChange(e.target.files[0])}
      />
      {!thumbnail && (
        <Button
          endIcon={<PanoramaIcon />}
          style={{ marginBottom: ".5rem" }}
          onClick={() => thumbnailInputRef.current.click()}
        >
          Adicionar capa ao vídeo
        </Button>
      )}
      {!video && (
        <div className="video-placeholder" onClick={() => videoInputRef.current.click()}>
          <VideoCallIcon style={{ color: "white", fontSize: "80px" }} />
          <p>Clique para adicionar um vídeo</p>
        </div>
      )}
      {video && (
        <>
          <div
            className="video-close-icon"
            onClick={() => {
              if (onVideoRemove) {
                onVideoRemove();
                videoInputRef.current.value = null;
              }
            }}
          >
            <CloseIcon style={{ color: "white" }} />
          </div>
          <video controls autoPlay>
            <source
              src={video.url ? `${baseUrl}${video.url}` : URL.createObjectURL(video)}
              type="video/mp4"
            />
          </video>
        </>
      )}
      {thumbnail && (
        <div className="video-thumbnail">
          <div
            className="video-close-icon"
            style={{ top: "8px" }}
            onClick={() => {
              if (onThumbnailRemove) {
                onThumbnailRemove();
                thumbnailInputRef.current.value = null;
              }
            }}
          >
            <CloseIcon style={{ color: "white" }} />
          </div>
          <img
            src={
              typeof thumbnail === "string"
                ? `${baseUrl}${thumbnail}`
                : URL.createObjectURL(thumbnail)
            }
            alt="Capa do video"
          />
        </div>
      )}
    </div>
  );
}
