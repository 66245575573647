export const colorTheme = {
  text: {
    primary: "#27272A",
    secondary: "#A1A1AA",
  },
  primary: {
    300: "#598FE0",
    400: "#3065C1",
    500: "#003399",
    600: "#002783",
    700: "#001D6E",
  },
  secondary: {
    300: "#64A4E2",
    400: "#3C7CC5",
    500: "#0C4AA0",
    600: "#083989",
    700: "#062A73",
  },
  success: {
    300: "#8BDA72",
    400: "#5BB549",
    500: "#24841B",
    600: "#147113",
    700: "#0D5F13",
  },
  warning: {
    300: "#FBD964",
    400: "#F7C73D",
    500: "#F2AD00",
    600: "#D08E00",
    700: "#AE7200",
  },
  danger: {
    300: "#DF8969",
    400: "#C05941",
    500: "#962112",
    600: "#81110D",
    700: "#6C090D",
  },
};
