import React from "react";

import Button from "../../../../../../componentsv2/Button";
import Paper from "../../../../../../componentsv2/Paper";
import GridContainer from "../../../../../../componentsv2/GridContainer";
import GridItem from "../../../../../../componentsv2/GridItem";

export default function Cabecalho(props) {
  const {
    onClick,
  } = props;

  return (
    <Paper>
      <GridContainer sx={{ display: "flex" }}>
        <GridItem className="paper-categoria" md={2} xs={12}>
          <Button variant="contained" fullWidth onClick={onClick}>
            Cadastar Médico
          </Button>
        </GridItem>
      </GridContainer>
    </Paper>
  );
}
