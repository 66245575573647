import React, { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { Grid, IconButton, Paper, Box } from "@mui/material";

import { api } from "../../../services/Api";
import filtroPesquisa from "../../../utils/filtroPesquisa";
import DadosContextFornecedor from "./DadosContextFornecedor";

import Opcoes from "./opcoes";
import { Context as PermissoesContext } from "../../../Context/PermissoesContext";
import Tabela from "../../../componentsv2/Tabela";
import TabelaAccordionEmpresa from "./TabelaAccordionEmpresa";
import { Input } from "../../../componentsv2/Input";

export default function Fornecedores() {
  const history = useHistory();
  const { checarPermissao } = useContext(PermissoesContext);
  const { enqueueSnackbar } = useSnackbar();

  // eslint-disable-next-line
  const [open, setOpen] = useState(false);
  // eslint-disable-next-line
  const handleOpen = () => setOpen(true);
  // eslint-disable-next-line
  const handleClose = () => setOpen(false);

  // eslint-disable-next-line
  const [text, setText] = useState("");
  const [fornecedores, setFornecedores] = useState([]);
  const [update, setUpdate] = useState(false);

  const [tamanho, setTamanho] = useState("");

  function sizeOfScreen() {
    setTamanho(window.innerWidth);
  }

  useEffect(() => {
    sizeOfScreen();
  }, []);

  window.addEventListener("resize", () => {
    sizeOfScreen();
  });

  const columns = [
    { field: "nome", headerName: "Nome", width: 400 },
    {
      field: "cpf_cnpj",
      headerName: "CPF/CNPJ",
      width: 300,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "telefone",
      headerName: "Telefones",
      width: 300,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "opcoes",
      headerName: "Opções",
      headerAlign: "center",
      align: "center",
      flex: 1,

      renderCell: (params) => (
        <div style={{ cursor: "pointer" }}>
          <Opcoes row={params.row} />
        </div>
      ),
    },
  ];

  const pesquisado = filtroPesquisa(fornecedores, "nome", text);

  async function fetchData() {
    const { data } = await api.get(`v2/gestaoContratos/todosFornecedores`);
    setFornecedores(data);
  }

  useEffect(() => {
    if (!checarPermissao("FIN001")) {
      history.push("/home");
    }
    fetchData();
  }, [update, checarPermissao, history]);

  return (
    <>
      <DadosContextFornecedor.Provider value={{ update, setUpdate }}>
        <Grid container alignItems="center" spacing={2} direction="column">
          <Grid container item xs={12}>
            <Paper
              sx={{ width: "100%", marginBottom: "30px", padding: "10px" }}
            >
              <Grid
                item
                container
                sx={{ marginLeft: "5px" }}
                justifyContent="space-between"
                spacing={0}
              >
                <Grid item xs={8} md={4}>
                  <Input
                    onChange={(e) => setText(e.target.value)}
                    value={text}
                    label="Pesquise pelo nome"
                    variant="standard"
                    size="small"
                    fullWidth
                    type="text"
                  />
                </Grid>

                <Grid item xs={2} sm={2} md={1} lg={1}>
                  <IconButton
                    color="success"
                    onClick={async () => {
                      if (checarPermissao("GEC002")) {
                        history.push("/financeiro/fornecedor/criar");
                      } else {
                        enqueueSnackbar(
                          "Você não possui permissão para cadastrar fornecedores!",
                          {
                            variant: "error",
                            autoHideDuration: 3000,
                          }
                        );
                      }
                    }}
                    size="large"
                  >
                    <AddCircleOutlineIcon fontSize="large" />
                  </IconButton>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12} container>
            <Grid style={{ paddingTop: "10px" }} xs={12} sm={12} item>
              <Grid spacing={2} container>
                <Grid style={{ paddingTop: "10px" }} item xs={12} sm={12}>
                  <Paper elevation={2}>
                    <Box
                      sx={{ height: 1, width: "100%", marginBottom: "10px" }}
                    >
                      {tamanho > 499 ? (
                        <Tabela
                          rows={pesquisado}
                          columns={columns}
                          autoHeight
                        />
                      ) : (
                        <TabelaAccordionEmpresa empresas={pesquisado} />
                      )}
                    </Box>
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DadosContextFornecedor.Provider>
    </>
  );
}
