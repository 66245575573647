import React, { useContext, useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { Close, Search } from "@mui/icons-material";
import {
  Dialog,
  DialogActions,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";

import { ColetaDomiciliarContext } from "../../../Context/ColetaDomiciarContext";
import { FormularioColetaDomiciliarContext } from "../../../Context/FormularioColetaDomiciliarContext";
import { ModaisColetaDomiciliarContext } from "../../../Context/ModaisColetaDomiciarContext";

import buscaCep from "../../../../../utils/buscaCEP";
import SalvaLog from "../../../../../utils/SalvaLog";

import { api } from "../../../../../services/Api";
import Waiter from "../../../../../components/Backdrop";

export default function ModalDadosClientes() {
  const { enqueueSnackbar } = useSnackbar();

  const { usuario, coleta, mudaValor, setAtualizaTabela } = useContext(
    ColetaDomiciliarContext
  );

  const {
    AbreDadosCliente,
    setAbreDadosCliente,
    setOpcao,
    fechaOpcoes,
    setMsg,
    setAviso,
  } = useContext(ModaisColetaDomiciliarContext);
  const {
    tipo_cliente,
    setTipo_cliente,
    codigoPedido,
    setCodigoPedido,
    codigo_pedido,
    posto_pedido,
    nome,
    setNome,
    endereco,
    setEndereco,
    endereco_numero,
    setEndereco_numero,
    complemento,
    setComplemento,
    bairro,
    setBairro,
    cidade,
    setCidade,
    estado,
    setEstado,
    cep,
    setCep,
    fone,
    setFone,
    celular,
    setCelular,
    email,
    setEmail,
    observacoes,
    setObservacoes,
    ponto_referencia,
    setPonto_referencia,
    valor_recebido,
    setValor_recebido,
    localizador,
    setLocalizador,
    setCodigo_pedido,
    setPosto_pedido,
    coleta_dupla,
    setColeta_dupla,
    zeraCliente,
  } = useContext(FormularioColetaDomiciliarContext);

  const [espera, setEspera] = useState(false);

  async function buscarCliente(codigo, posto) {
    let codigoDividido = codigoPedido.split("-");
    if (codigo_pedido && posto_pedido) {
      codigoDividido = [codigo_pedido, posto_pedido];
    }
    if (codigo && posto) {
      codigoDividido = [codigo, posto];
    }
    // SalvaLog(
    //   `Pesquisou o pedido ${codigoPedido}`,
    //   "Coleta Domiciliar",
    //   ``,
    //   `${codigoPedido}`
    // );

    if (codigoDividido.length < 2 || codigoDividido.length > 2) {
      setCodigoPedido("");
      enqueueSnackbar("Código do pedido inválido 1", { variant: "error" });
    } else {
      setEspera(true);

      try {
        const cliente = await api.post("/api/softlab/pedido", {
          codigo: parseInt(codigoDividido[0], 10),
          posto: codigoDividido[1],
        });

        if (cliente.data.length === 0) {
          setCodigoPedido("");
          enqueueSnackbar("Nenhum cliente encontrado", { variant: "error" });
        } else {
          setCodigo_pedido(cliente.data.codigo_pedido);
          setPosto_pedido(cliente.data.posto_pedido);
          setLocalizador(`P${cliente.data.localizador}`);
          setNome(cliente.data.nome_cliente);
          setFone(fone || cliente.data.fone_cliente);
          setCelular(celular || cliente.data.celular_cliente);
          setEmail(email || cliente.data.email);
        }
      } catch (error) {
        enqueueSnackbar("Falha na operação", { variant: "error" });
      }
      setEspera(false);
    }
  }

  function fechaDadosCliente() {
    zeraCliente();
    setAbreDadosCliente(false);
  }
  function selecionaNovoCEP(novocep) {
    setCep(novocep);
  }

  async function capturaCep() {
    setEspera(true);

    const resultadoCep = await buscaCep(cep);

    if (resultadoCep) {
      setEndereco_numero("");
      setComplemento("");
      setPonto_referencia("");
      setEndereco(resultadoCep.street);
      setBairro(resultadoCep.neighborhood);
      setCidade(resultadoCep.city);
      setEstado(resultadoCep.state);
    } else {
      enqueueSnackbar("Endereço não encontrado ou CEP inválido", {
        variant: "error",
      });
    }

    setEspera(false);
  }

  async function salvaCliente(id, statusAtual) {
    const codigo_usuario = usuario;
    const dadosAgenda = {
      id,
      status: statusAtual,
      localizador,
      codigo_pedido,
      posto_pedido,
      codigo_usuario,
      endereco,
      ponto_referencia,
      celular,
      nome,
      fone,
      email,
      endereco_numero,
      complemento,
      bairro,
      cidade,
      estado,
      cep,
      tipo_cliente,
      observacoes,
      coleta_dupla,
      valor_recebido,
    };

    try {
      setEspera(true);

      await api.put("/api/coletaDomiciliar/agenda", {
        id,
        status: statusAtual,
        localizador,
        codigo_pedido,
        posto_pedido,
        codigo_usuario,
        endereco,
        ponto_referencia,
        celular,
        nome,
        fone,
        email,
        endereco_numero,
        complemento,
        bairro,
        cidade,
        estado,
        cep,
        tipo_cliente,
        observacoes,
        coleta_dupla,
        valor_recebido,
      });

      SalvaLog(
        `Adicionou ou alterou os dados do cliente ${localizador} na coleta externa de id: ${id}`,
        "Coleta Domiciliar",
        JSON.stringify(dadosAgenda),
        `${id}`
      );
      enqueueSnackbar("Operação realizada com sucesso", { variant: "success" });
    } catch (error) {
      SalvaLog(
        `Tentou cadastrar ou atualizar o cliente ${localizador} na coleta externa de id: ${id}`,
        "Coleta Domiciliar",
        JSON.stringify(dadosAgenda),
        `${id}`
      );
      enqueueSnackbar("Falha na operação", { variant: "error" });
      setMsg(error.response.data.mensagem);
      setAviso(true);
    }

    setAtualizaTabela((prev) => !prev);
    zeraCliente();
    setEspera(false);
    setAbreDadosCliente(false);
    setOpcao(false);
    fechaOpcoes();
  }

  function dataBrasil(data) {
    const [ano, mes, dia] = data.split("-");
    return `${dia}/${mes}/${ano}`;
  }

  useEffect(() => {
    if (coleta.codigo_pedido && coleta.posto_pedido) {
      buscarCliente(coleta.codigo_pedido, coleta.posto_pedido);
      setCodigoPedido(`${coleta.codigo_pedido}-${coleta.posto_pedido}`);
    }

    if (coleta.localizacao) {
      let cepAtual = "";
      let rua = "";
      let numero = "";
      let bairroAtual = "";
      let cidadeAtual = "";
      let estadoAtual = "";
      const local = coleta.localizacao.split(" - ");

      if (local.length === 3) {
        [rua, numero] = local[0].split(", ");
        [bairroAtual, cidadeAtual] = local[1].split(", ");
        [estadoAtual, cepAtual] = local[2].split(", ");
      } else {
        const local0 = local[0].split(", ");

        if (local0.length === 3) {
          [rua, numero, cidadeAtual] = local[0].split(", ");
        } else if (local0.length === 2) {
          [rua, cidadeAtual] = local[0].split(", ");
        } else {
          [cidadeAtual] = local[0].split(", ");
        }

        if (local.length > 1) {
          [estadoAtual, cepAtual] = local[1].split(", ");
        }
      }

      setCep(cepAtual);
      setEndereco(rua);
      setEndereco_numero(numero);
      setBairro(bairroAtual);
      setCidade(cidadeAtual);
      setEstado(estadoAtual);
    }
    // eslint-disable-next-line
  }, [coleta]);
  return (
    <>
      <Dialog
        fullWidth
        maxWidth="md"
        open={AbreDadosCliente}
        onClose={() => {
          fechaDadosCliente();
        }}
      >
        <div id="header-information-client">
          <i
            id="icon-info-client"
            className="fa fa-user-circle"
            aria-hidden="true"
          />
          <span>Editar dados do cliente</span>
          <button
            type="button"
            id="btn-close-info-client"
            onClick={() => fechaDadosCliente()}
          >
            <Close id="icon-back-info-client" />
          </button>
        </div>
        <div className="grid-container-Cli">
          {coleta.hora && (
            <div className="grid-item-Cli">
              <InputLabel id="nomeCliente">Hora</InputLabel>
              <InputLabel id="nome">{coleta.hora}</InputLabel>
            </div>
          )}
          {coleta.data && (
            <div className="grid-item-Cli">
              <InputLabel id="nomeCliente">Data</InputLabel>
              <InputLabel id="nome">{dataBrasil(coleta.data)}</InputLabel>
            </div>
          )}
          <div className="grid-item-Cli">
            <TextField
              variant="standard"
              className="codPedido"
              label="Pedido"
              value={
                (codigoPedido === "null-" ? "" : codigoPedido) ||
                `${coleta.codigo_pedido}-${coleta.posto_pedido}`
              }
              onChange={(e) => {
                mudaValor(e, setCodigoPedido);
              }}
            />
            <Tooltip title="Validar código do pedido e buscar cliente">
              <IconButton onClick={buscarCliente}>
                <Search color="primary" />
              </IconButton>
            </Tooltip>
          </div>

          <div className="grid-item-Cli">
            <InputLabel className="nomeHora">Tipo do cliente</InputLabel>
            <Select
              variant="standard"
              className="selectForm"
              value={tipo_cliente}
              onChange={(e) => {
                mudaValor(e, setTipo_cliente);
              }}
            >
              <MenuItem key="Normal" value="Normal">
                Normal
              </MenuItem>
              <MenuItem key="VIP" value="VIP">
                VIP
              </MenuItem>
              <MenuItem key="VIP PÓS" value="VIP PÓS">
                VIP PÓS
              </MenuItem>
              <MenuItem key="DNACOV" value="DNACOV">
                DNACOV
              </MenuItem>
            </Select>
          </div>

          <div className="grid-item-Cli">
            <TextField
              variant="standard"
              label="Localizador"
              value={localizador || ""}
              onChange={(e) => {
                mudaValor(e, setLocalizador);
              }}
            />
          </div>

          <div className="grid-item-Cli">
            <InputLabel className="nomeHora">Repetir coleta</InputLabel>
            <Select
              variant="standard"
              className="selectForm"
              value={coleta_dupla}
              onChange={(e) => {
                mudaValor(e, setColeta_dupla);
              }}
            >
              <MenuItem key={8000} value="Não">
                Não
              </MenuItem>
              <MenuItem key={8010} value="Sim">
                Sim
              </MenuItem>
            </Select>
          </div>

          <div className="grid-item-Cli-2">
            <TextField
              variant="standard"
              className="espacoso"
              label="Cliente"
              value={nome || ""}
              onChange={(e) => {
                mudaValor(e, setNome);
              }}
            />
          </div>

          {coleta.localizacao ? (
            <div className="grid-item-Cli-2">
              <TextField
                variant="standard"
                disabled
                className="espacoso"
                label="Endereço"
                value={coleta.localizacao || ""}
              />
            </div>
          ) : (
            <>
              <div className="grid-item-Cli">
                <TextField
                  variant="standard"
                  label="CEP"
                  value={cep || ""}
                  onChange={(e) => {
                    selecionaNovoCEP(e.target.value);
                  }}
                />
                <Tooltip title="Buscar endereço">
                  <IconButton
                    onClick={() => {
                      capturaCep();
                    }}
                  >
                    <Search color="primary" />
                  </IconButton>
                </Tooltip>
              </div>

              <div className="grid-item-Cli-2">
                <TextField
                  variant="standard"
                  className="espacoso"
                  label="Endereço"
                  value={endereco || ""}
                  onChange={(e) => {
                    mudaValor(e, setEndereco);
                  }}
                />
              </div>

              <div className="grid-item-Cli">
                <TextField
                  variant="standard"
                  label="Número"
                  value={endereco_numero || ""}
                  onChange={(e) => {
                    mudaValor(e, setEndereco_numero);
                  }}
                />
              </div>

              <div className="grid-item-Cli">
                <TextField
                  variant="standard"
                  label="Bairro"
                  value={bairro || ""}
                  onChange={(e) => {
                    mudaValor(e, setBairro);
                  }}
                />
              </div>

              <div className="grid-item-Cli-2">
                <TextField
                  variant="standard"
                  className="espacoso"
                  label="Complemento"
                  value={complemento || ""}
                  onChange={(e) => {
                    mudaValor(e, setComplemento);
                  }}
                />
              </div>
            </>
          )}

          <div className="grid-item-Cli-2">
            <TextField
              variant="standard"
              className="espacoso"
              label="Ponto de referência"
              value={ponto_referencia || ""}
              onChange={(e) => {
                mudaValor(e, setPonto_referencia);
              }}
            />
          </div>

          {!coleta.localizacao && (
            <>
              <div className="grid-item-Cli">
                <TextField
                  variant="standard"
                  label="Cidade"
                  value={cidade || ""}
                  onChange={(e) => {
                    mudaValor(e, setCidade);
                  }}
                />
              </div>

              <div className="grid-item-Cli">
                <TextField
                  variant="standard"
                  label="Estado"
                  value={estado || ""}
                  onChange={(e) => {
                    mudaValor(e, setEstado);
                  }}
                />
              </div>
            </>
          )}

          <div className="grid-item-Cli">
            <TextField
              variant="standard"
              label="Celular"
              value={celular || ""}
              onChange={(e) => {
                mudaValor(e, setCelular);
              }}
            />
          </div>

          <div className="grid-item-Cli">
            <TextField
              variant="standard"
              label="Telefone"
              value={fone || ""}
              onChange={(e) => {
                mudaValor(e, setFone);
              }}
            />
          </div>

          <div className="grid-item-Cli-2">
            <TextField
              variant="standard"
              className="espacoso"
              label="Email"
              value={email || ""}
              onChange={(e) => {
                mudaValor(e, setEmail);
              }}
            />
          </div>

          <div className="grid-item-Cli-2">
            <TextField
              variant="standard"
              className="espacoso"
              label="Observação"
              value={observacoes || ""}
              onChange={(e) => {
                mudaValor(e, setObservacoes);
              }}
            />
          </div>
          {tipo_cliente === "VIP" || tipo_cliente === "VIP PÓS" ? (
            <></>
          ) : (
            <>
              {" "}
              <div className="grid-item-Cli-2">
                <TextField
                  type="number"
                  variant="standard"
                  label="Valor R$"
                  fullWidth
                  value={valor_recebido}
                  onChange={(event, value) => {
                    setValor_recebido(event.target.value);
                  }}
                />
              </div>
            </>
          )}
        </div>

        <DialogActions>
          <button
            type="button"
            className="btnDialogs"
            onClick={() => {
              salvaCliente(coleta.id, coleta.status);
            }}
          >
            Salvar
          </button>
        </DialogActions>
      </Dialog>
      <Waiter espera={espera} />
    </>
  );
}
