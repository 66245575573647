import React, { useEffect, useState, useContext } from "react";
import Header from "../../../../../../components/Header"
import Container from "../../../../../../componentsv2/Container"
import { Button as MuiButton, Grid, Paper } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useHistory,useLocation } from"react-router-dom";
import { Input } from "../../../../../../componentsv2/Input";
import Button from "../../../../../../componentsv2/Button";
import DataPickerAtualizado from "../../../../../../components/DataPickerAtualizado";
import SelectSetor from "../../../../../../componentsv2/SelectSetor";
import { Select } from "../../../../../../componentsv2/Select";
import validarCPF from "../../../../../../utils/validations/cpf";
import { api } from "../../../../../../services/Api";
import { useSnackbar } from "notistack";
import { Context as PermissoesContext } from "../../../../../../Context/PermissoesContext";
import SelectManifestacao from "../../../../components/SelectManifestacao";

export default function Adicionar() {
  const history = useHistory();
  const location = useLocation();
  const [dataCadastro, setDataCadastro] = useState(new Date());
  const [prazo, setPrazo] = useState(new Date());
  const [celular, setCelular] = useState("");
  const [cpf, setCpf] = useState("");
  const [setor, setSetor] = useState("");
  const [situacao, setSituacao] = useState("");
  const [nome, setNome] = useState("");
  const [problematica, setProblematica] = useState("");
  const [tipoManifestacao, setTipoManifestacao] = useState("");

  const { checarPermissaoSemLog} = useContext(PermissoesContext);
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = async e => {
    try {
      e.preventDefault();
      if (validarCPF(cpf)||!cpf) {
        const data = {
          dataCadastro,
          prazo,
          celular,
          cpf,
          setorId: setor,
          situacao,
          nome,
          tipoManifestacao,
          problematica,
          ouvidoria:true
        };
        if (location.state?.row) {
          await api.put(`v2/atendimentoAoCliente/sac/${location.state?.row.id}`, data);
          history.push("/AtendimentoAoCliente");
          enqueueSnackbar("Formulario editado com sucesso!", { variant: "success" });
        } else {
          await api.post("v2/atendimentoAoCliente/sac", data);
          history.push("/AtendimentoAoCliente");
          enqueueSnackbar("Formulario cadastrado com sucesso!", { variant: "success" });
        }
      } else {
        enqueueSnackbar("CPF invalido!", { variant: "error" });
      }
    } catch (error) {
      if (location.state?.row) {
        enqueueSnackbar("Erro ao editar formulario!", { variant: "error" });
      } else {
        enqueueSnackbar("Erro ao cadastrar formulario!", { variant: "error" });
      }
      
    }
  };

  const pegarDadosSac = async ()=>{
    if(location.state?.row){
      try {
        const row = location.state?.row
        const {
          data: { data },
        } = await api.get(`v2/atendimentoAoCliente/Sac/${row.id}`);
          setDataCadastro(data.dataCadastro);
          setPrazo(data.prazo);
          setCelular(data.celular);
          setCpf(data.cpf);
          setSetor(data.setorId);
          setSituacao(data.situacao);
          setNome(data.nome);
          setProblematica(data.problematica);
          setTipoManifestacao(data.tipoManifestacao);
        
      } catch (error) {
        history.push("/AtendimentoAoCliente");
        enqueueSnackbar("Erro ao coletar dados para edição!", { variant: "error" });
      }
    }
  }
  const checarVisualizarTela =async ()=>{
    if (!(await checarPermissaoSemLog("ATC006"))&&!location.state?.row) {
      history.push({ pathname: "/atendimentoAoCliente" })
    }
    
  }
  useEffect(()=>{
    checarVisualizarTela()
    pegarDadosSac()
    // eslint-disable-next-line
  },[])
  return (
    <>
    <Header titulo={location.state?.row?"Formulario Editar":"Formulario Adicionar"} />
    <Container>
      <MuiButton onClick={() => {}}>
        <ArrowBackIcon
          style={{ color: "#003399" }}
          onClick={() => history.push({ pathname: "/atendimentoAoCliente" })}
        />
      </MuiButton>
      <Paper style={{ padding: "40px", marginTop: "10px" }} elevation={2}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <DataPickerAtualizado
                data={dataCadastro}
                setData={setDataCadastro}
                variant="standard"
                labelName="Data"
              />
            </Grid>
            <Grid item xs={6}>
              <DataPickerAtualizado
                data={prazo}
                setData={setPrazo}
                variant="standard"
                labelName="Prazo"
              />
            </Grid>
            <Grid item xs={6}>
              <Input
                onChange={e => setCelular(e.target.value)}
                value={celular}
                required
                fullWidth
                label="Celular"
                type="text"
                variant="standard"
              />
            </Grid>
            <Grid item xs={6}>
              <Input
                onChange={e => setCpf(e.target.value)}
                value={cpf}
                fullWidth
                label="CPF"
                type="text"
                variant="standard"
              />
            </Grid>
            <Grid item xs={6}>
              <SelectSetor
                required
                onChange={e => setSetor(e.target.value)}
                value={setor}
              />
            </Grid>
            <Grid item xs={6}>
              <Select
                required
                onChange={e => setSituacao(e.target.value)}
                value={situacao}
                label="Status"
                arrayOptions={["Em Aberto", "Em Andamento", "Finalizado"]}
                variant="standard"
              />
            </Grid>
            <Grid item xs={6}>
              <Input
                required
                onChange={e => setNome(e.target.value)}
                value={nome}
                fullWidth
                label="Nome"
                type="text"
                variant="standard"
              />
            </Grid>
            <Grid item xs={6}>
              <SelectManifestacao
                required
                onChange={e => setTipoManifestacao(e.target.value)}
                value={tipoManifestacao}
              />
            </Grid>
            <Grid item xs={12}>
              <Input
                onChange={e => setProblematica(e.target.value)}
                value={problematica}
                required
                multiline
                maxRows={5}
                fullWidth
                label="Problemática"
                type="text"
                variant="standard"
              />
            </Grid>
            <Grid
              item
              sx={{ marginTop: "30px" }}
              container
              justifyContent="space-between"
              spacing={0}
            >
              <Grid item>
                <Button
                  onClick={() => history.push({ pathname: "/atendimentoAoCliente" })}
                  color="red"
                  variant="outlined"
                >
                  Cancelar
                </Button>
              </Grid>
              <Grid item>
                <Button type="submit" color="green" variant="contained">
                  Salvar
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Paper>
      </Container>
    </>
  );
}
