import React, { useState, useEffect, useContext } from "react";
import { useSnackbar } from "notistack";
import { Search } from "@mui/icons-material";
import {
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";

import { ColetaDomiciliarContext } from "../../Context/ColetaDomiciarContext";
import { ModaisColetaDomiciliarContext } from "../../Context/ModaisColetaDomiciarContext";

import { FormularioColetaDomiciliarContext } from "../../Context/FormularioColetaDomiciliarContext";

import AbasColetaDomiciliar from "./AbasColetaDomiciliar";
import ModalVisualizaCliente from "./ModalVisualizaCliente";
import ModalDadosClientes from "./ModalDadosClientes";
import ModalValorRecebido from "./ModalValorRecebido";
import ModalAlteraStatus from "./ModalAlteraStatus";
import ModalVisualizaExames from "./ModalInformacoesExames";
import ModalOpcoes from "./ModalOpcoes";
import ModalDuplicar from "./ModalDuplicar";
import ModalConfirmar from "./ModalCofirmar";
import ModalPesquisa from "./ModalPesquisa";
import ModalEditarDados from "./ModalEditarDados/ModalEditarDados";
import ModalReservarHorario from "./ModalReservarHorario";
import Recibo from "./Recibo";

import Waiter from "../../../../components/Backdrop";
import DialogAviso from "../../../../components/DialogAviso";
import DataPickerAtualizado from "../../../../components/DataPickerAtualizado";

import SalvaLog from "../../../../utils/SalvaLog";

import { api } from "../../../../services/Api";

import "./styles.css";

export default function Formulario({ aba, setAba }) {
  const { enqueueSnackbar } = useSnackbar();

  const {
    novaData,
    dataFinal,
    coletadores,
    coleta,
    // vetorHora,
    coletador,
    selecionaData,
    selecionaDataFinal,
    selecionaColetador,
    // mudaValor,
  } = useContext(ColetaDomiciliarContext);

  const {
    // visualizaCliente,
    visualizaPedido,
    setModalPesquisa,
    modalEditarDados,
    setModalEditarDados,
    msg,
    setMsg,
    aviso,
    setAviso,
    setModalMotivoBloqueio,
  } = useContext(ModaisColetaDomiciliarContext);

  const {
    tipo_cliente,
    setTipo_cliente,
    // novaHora,
    setNovaHora,
    codigoPesquisa,
    setCodigoPesquisa,
    setResultadoPesquisa,
    setExames,
    // setInformacoesPedido,
  } = useContext(FormularioColetaDomiciliarContext);

  const [espera, setEspera] = useState(false);
  const [exibeIntervalo, setExibeIntervalo] = useState(false);
  const [exibeColetador, setExibeColetador] = useState(false);
  const [modalReservarHorario, setModalReservarHorario] = useState(false);

  useEffect(() => {
    async function detalhaPedido() {
      const codigo = Number(coleta.codigo_pedido);
      const posto = coleta.posto_pedido;
      try {
        setEspera(true);
        const { data: detalhe } = await api.post(
          "/api/softlab/pedido/detalhe",
          {
            codigo,
            posto,
          }
        );

        setExames(detalhe);

        SalvaLog(
          `Visualizou os exames do pedido ${coleta.codigo_pedido}-${coleta.posto_pedido}`,
          "Coleta Domiciliar",
          ``
        );
      } catch (error) {
        SalvaLog(
          `Tentou visualizar o pedido ${coleta.codigo_pedido}-${coleta.posto_pedido}`,
          "Coleta Domiciliar",
          ``
        );
        enqueueSnackbar("Falha na operação", { variant: "error" });
      }
      setEspera(false);
    }

    if (visualizaPedido) {
      detalhaPedido();
    }
  }, [
    visualizaPedido,
    enqueueSnackbar,
    setExames,
    coleta.codigo_pedido,
    coleta.posto_pedido,
  ]);

  useEffect(() => {
    if (aba === "BI" || aba === "Financeiro") {
      setExibeIntervalo(true);
    } else {
      setExibeIntervalo(false);
    }

    if (aba !== "BI") {
      setExibeColetador(true);
    } else {
      setExibeColetador(false);
    }
  }, [aba]);

  async function pesquisaPedido() {
    const [codigo_pesquisa, posto_pesquisa] = codigoPesquisa.split("-");
    // SalvaLog(
    //   `Pesquisou o pedido ${codigoPesquisa}`,
    //   "Coleta Domiciliar",
    //   "",
    //   `${coleta.id}`
    // );

    if (!codigo_pesquisa || !posto_pesquisa) {
      setCodigoPesquisa("");
      enqueueSnackbar("Código do pedido inválido ou vazio", {
        variant: "warning",
      });
    } else {
      setEspera(true);

      try {
        const cliente = await api.get(
          `/api/coletaDomiciliar/pesquisaPedido/${codigo_pesquisa}/${posto_pesquisa}`
        );

        if (cliente.data.length === 0) {
          enqueueSnackbar("Nenhum cliente encontrado", { variant: "error" });
        } else {
          setResultadoPesquisa(cliente.data);
          setModalPesquisa(true);
        }

        setCodigoPesquisa("");
      } catch (error) {
        enqueueSnackbar("Falha na operação", { variant: "error" });
      }
      setEspera(false);
    }
  }

  // const pegarInformacoesPedido = async () => {
  //   if (coleta.codigo_pedido && coleta.posto_pedido && visualizaCliente) {
  //     try {
  //       const { data } = await api.post("/api/softlab/pedido", {
  //         codigo: parseInt(coleta.codigo_pedido, 10),
  //         posto: coleta.posto_pedido,
  //       });

  //       const objetoInformacoesPedido = [
  //         {
  //           endereco: data.endereco_cliente
  //             ? `Endereço: ${data.endereco_cliente}`
  //             : "",
  //           numero: data.endereco_numero_cliente
  //             ? `Numero: ${data.endereco_numero_cliente}`
  //             : "",
  //           complemento: data.complemento_cliente
  //             ? `Complemento: ${data.complemento_cliente}`
  //             : "",
  //         },
  //       ];

  //       setInformacoesPedido(objetoInformacoesPedido);
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   }
  // };

  // useEffect(() => {
  //   pegarInformacoesPedido();
  //   // eslint-disable-next-line
  // }, [visualizaCliente]);
  const stylesItens = {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "center",
  };
  const stylesItensSelect = { margin: "0", padding: "0", width: "100%" };

  return (
    <div>
      <AbasColetaDomiciliar aba={aba} setAba={setAba} />

      <div className="grid-container-Dom">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={3} sx={stylesItens}>
            <DataPickerAtualizado
              setData={selecionaData}
              data={novaData}
              variant="filled"
              labelName={exibeIntervalo ? "Data Inicial" : "Selecione a data "}
            />
          </Grid>

          {exibeIntervalo && (
            <Grid item sx={stylesItens} xs={12} sm={3}>
              <DataPickerAtualizado
                setData={selecionaDataFinal}
                data={dataFinal}
                variant="standard"
                labelName="Data Final"
              />
            </Grid>
          )}

          {exibeColetador && (
            <Grid item sx={stylesItens} xs={12} sm={3}>
              <div style={stylesItensSelect}>
                <InputLabel className="nomeHora">Coletador</InputLabel>
                <Select
                  fullWidth
                  sx={{ m: "0" }}
                  variant="standard"
                  className="selectForm-coleta"
                  value={coletador}
                  onChange={(e) => {
                    selecionaColetador(e);
                    if (aba === "Controle") {
                      setNovaHora("Todos");
                    } else {
                      setNovaHora("");
                    }
                  }}
                >
                  <MenuItem key="0" value="">
                    Não selecionado
                  </MenuItem>
                  {coletadores.map((c) => (
                    <MenuItem key={c.id} value={c.nome}>
                      {c.nome}
                    </MenuItem>
                  ))}
                </Select>
              </div>
            </Grid>
          )}

          {aba === "Financeiro" && (
            <Grid item sx={stylesItens} xs={12} sm={3}>
              <div style={stylesItensSelect}>
                <InputLabel className="nomeHora">Tipo cliente</InputLabel>
                <Select
                  fullWidth
                  sx={{ m: "0" }}
                  variant="standard"
                  className="selectForm-coleta"
                  value={tipo_cliente}
                  onChange={(e) => {
                    setTipo_cliente(e.target.value);
                  }}
                >
                  <MenuItem key="0" value="">
                    Não selecionado
                  </MenuItem>
                  {["Normal", "VIP", "VIP PÓS", "DNACOV"].map((t) => (
                    <MenuItem key={t} value={t}>
                      {t}
                    </MenuItem>
                  ))}
                </Select>
              </div>
            </Grid>
          )}

          {!exibeIntervalo && (
            <>
              {/* <Grid item sx={stylesItens} xs={12} sm={3}>
                <div style={stylesItensSelect}>
                  <InputLabel className="nomeHora">
                    Selecionar horário
                  </InputLabel>
                  <Select
                    fullWidth
                    sx={{ m: "0" }}
                    variant="standard"
                    className="selectForm-coleta"
                    value={novaHora}
                    onChange={(e) => {
                      mudaValor(e, setNovaHora);
                    }}
                  >
                    {aba === "Controle" && (
                      <MenuItem value="Todos">Todos</MenuItem>
                    )}
                    {vetorHora.map((h) => (
                      <MenuItem key={h.hora} value={h.hora}>
                        {h.hora}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              </Grid> */}

              {aba === "Controle" && (
                <Grid item sx={stylesItens} xs={12} sm={3}>
                  <button
                    type="button"
                    className="btnAgendar"
                    onClick={() => {
                      if (coletador) {
                        setModalMotivoBloqueio(true);
                      } else {
                        enqueueSnackbar("Selecione um coletador", {
                          variant: "warning",
                        });
                      }
                    }}
                  >
                    Bloquear
                  </button>
                </Grid>
              )}

              {aba === "Agenda" && (
                <>
                  <Grid item sx={stylesItens} xs={12} sm={3}>
                    <TextField
                      fullWidth
                      variant="standard"
                      label="Pedido"
                      value={codigoPesquisa}
                      onChange={(e) => setCodigoPesquisa(e.target.value)}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Tooltip title="Procurar pedido">
                              <IconButton onClick={() => pesquisaPedido()}>
                                <Search color="primary" />
                              </IconButton>
                            </Tooltip>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <button
                        type="button"
                        className="btnAgendar"
                        onClick={() => {
                          setModalReservarHorario(true);
                        }}
                      >
                        Reservar horário
                      </button>

                      <button
                        type="button"
                        className="btnAgendar"
                        onClick={() => {
                          setModalEditarDados(true);
                        }}
                      >
                        Agendar
                      </button>
                    </div>
                  </Grid>
                </>
              )}

              {modalEditarDados && (
                <ModalEditarDados filtroDataColetas={selecionaData} />
              )}

              {modalReservarHorario && (
                <ModalReservarHorario
                  setOpen={setModalReservarHorario}
                  open={modalReservarHorario}
                />
              )}
            </>
          )}
        </Grid>
      </div>

      <ModalAlteraStatus />
      <ModalConfirmar />
      <ModalDadosClientes />
      <ModalDuplicar />
      <ModalOpcoes />
      <ModalPesquisa />
      <ModalValorRecebido />
      <ModalVisualizaCliente />
      <ModalVisualizaExames />
      <Recibo />

      <DialogAviso
        aviso={aviso}
        setAviso={setAviso}
        mensagem={msg}
        setMensagem={setMsg}
      />

      <Waiter espera={espera} />
    </div>
  );
}
