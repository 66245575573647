import { useEffect, useState } from "react";

const useNotificacoesDados = setCarregando => {
  // eslint-disable-next-line
  const [notificacoes, setNotificacoes] = useState([]);
  // eslint-disable-next-line
  const [totalNotificacoes, setTotalNotificacoes] = useState(0);
  const [atualizarNotificacoes, setAtualizarNotificacoes] = useState(false);
  // const {
  //   // filtro,
  //   // dataInicio,
  //   // dataFim,
  //   setCarregando,
  //   // tipo,
  //   // status,
  //   // quantidadePorPaginas,
  //   // pagina,
  // } = useContext(DadosContext);

  const atualizar = () => {
    setAtualizarNotificacoes(!atualizarNotificacoes);
  };

  async function carregarNotificacoes() {
    try {
      setCarregando(true);
      // const resultado = await api.get("v2/esus/notificacoes", {
      //   params: {
      //     filtro,
      //     pagina,
      //     quantidadePorPaginas,
      //     status,
      //     tipo,
      //     dataInicio,
      //     dataFim,
      //   },
      // });
      // setNotificacoes(resultado.data.notificacoes);
      // setTotalNotificacoes(resultado.data.totalNotificacoes);
    } catch (error) {
      console.log(error);
    } finally {
      setCarregando(false);
    }
  }

  useEffect(() => {
    carregarNotificacoes();
    // eslint-disable-next-line
  }, [atualizarNotificacoes]);

  return [notificacoes, atualizar, totalNotificacoes];
};

export default useNotificacoesDados;
