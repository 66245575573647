import React, { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import { Card, CardContent, Grid, Paper, Typography } from '@mui/material';
import { Bar } from "react-chartjs-2";
import "chartjs-plugin-datalabels";

import { api } from "../../../services/Api";

import "./styles.css";

export default function BI(props) {
  const { enqueueSnackbar } = useSnackbar();

  const { novaData, tabela } = props;

  const [qtdAmostras, setQtdAmostras] = useState(0);
  const [qtdMapas, setQtdMapas] = useState(0);

  const [preparacao, setPreparacao] = useState(0);
  const [emProcessamento, setEmProcessamento] = useState(0);
  const [emAnalise, setEmAnalise] = useState(0);
  const [concluido, setConcluido] = useState(0);

  const [urgencia, setUrgencia] = useState(0);
  const [repeticao, setRepeticao] = useState(0);
  const [normal, setNormal] = useState(0);

  const [triagem, setTriagem] = useState(0);
  const [PreExtracao, setPreExtracao] = useState(0);
  const [extracao, setExtracao] = useState(0);
  const [pcr, setPcr] = useState();
  const [processado, setProcessado] = useState(0);

  const [qtdPorTipo, setQtdPorTipo] = useState(0);
  const [casosPorPlanos, setQtdPorPlanos] = useState(0);
  const [top5planos, setTop5planos] = useState([]);

  function capturarStatusPorAmostra(amostrasStatus) {
    amostrasStatus.forEach(s => {
      if (s.status === "Triagem") {
        setTriagem(Number(s.qtd_amostra || 0));
      } else if (s.status === "Pré-Extração") {
        setPreExtracao(Number(s.qtd_amostra || 0));
      } else if (s.status === "Extração") {
        setExtracao(Number(s.qtd_amostra || 0));
      } else if (s.status === "PCR") {
        setPcr(Number(s.qtd_amostra || 0));
      } else if (s.status === "Processado") {
        setProcessado(Number(s.qtd_amostra || 0));
      }
    });
  }

  function capturaQtdMapaPorStatus(mapa) {
    mapa.forEach(m => {
      if (m.status === "Concluído") {
        setConcluido(Number(m.qtd_amostras || 0));
      } else if (m.status === "Preparação") {
        setPreparacao(Number(m.qtd_amostras || 0));
      } else if (m.status === "Em análise") {
        setEmAnalise(Number(m.qtd_amostras || 0));
      } else {
        setEmProcessamento(Number(m.qtd_amostras || 0));
      }
    });
  }

  function capturaQtdAmostrasPorTipo(amostra) {
    amostra.forEach(a => {
      if (a.tipo === "Urgência") {
        setUrgencia(Number(a.qtd_amostras || 0));
      } else if (a.tipo === "Repetição") {
        setRepeticao(Number(a.qtd_amostras || 0));
      } else {
        setNormal(Number(a.qtd_amostras || 0));
      }
    });
  }

  function capturarQtdPorTipo(casos){
    let naodDetectado=0
    let detectado=0

    for (let index = 0; index < casos.length; index += 1) {
      const c = casos[index];
      if (c.valor_resultado === "NÃO DETECTADO") {
        naodDetectado += c.qtde;
      } else if (c.valor_resultado === "UNDETECTED") {
        naodDetectado += c.qtde;
      } else if (c.valor_resultado === "Não Detectado") {
        naodDetectado += c.qtde;
      } else {
        detectado += c.qtde;
      }
    }
    const auxiliar = [detectado, naodDetectado]
    return auxiliar
  }

  function capturarTop5(tops){
    const planos=[0,0,0,0,0]
    for (let index = 0; index < tops.length; index += 1) {
      const t = tops[index];
      planos[index] = t.cod_guia
    }
    const auxiliar = planos
    return auxiliar
  }

  function capturarQtdTop5(tops){
    const qtd=[0,0,0,0,0]
    for (let index = 0; index < tops.length; index += 1) {
      const t = tops[index];
      qtd[index] = t.qtde;
    }
    const auxiliar = qtd;
    return auxiliar
  }

  function zeraDados() {
    setQtdAmostras(0);
    setQtdMapas(0);
    setConcluido(0);
    setPreparacao(0);
    setEmAnalise(0);
    setEmProcessamento(0);
    setNormal(0);
    setRepeticao(0);
    setUrgencia(0);
    setTriagem(0);
    setPreExtracao(0);
    setExtracao(0);
    setPcr(0);
    setProcessado(0);
    setQtdPorPlanos(0);
    setTop5planos(0)
    setQtdPorTipo(0)
  }

  useEffect(() => {
    async function carregarDados() {
      zeraDados();
      try {
        const responseQtdAmostras = await api.get(`/api/amostras/bi/quantidadeAmostras/${novaData}`);
        setQtdAmostras(responseQtdAmostras.data.qtd_amostras_por_dia);

        const responseQtdMapas = await api.get(`/api/amostras/bi/quantidadeMapas/${novaData}`);
        setQtdMapas(responseQtdMapas.data.qtd_mapas_por_dia);

        const responseMapa = await api.get(`/api/amostras/bi/mapasPorStatus/${novaData}`);
        capturaQtdMapaPorStatus(responseMapa.data);

        const responseAmostrasTipo = await api.get(`/api/amostras/bi/amostrasPorTipo/${novaData}`);
        capturaQtdAmostrasPorTipo(responseAmostrasTipo.data);

        const responseStatusAmostra = await api.get(`/api/amostras/bi/amostrasPorStatus/${novaData}`);
        capturarStatusPorAmostra(responseStatusAmostra.data);

        const responseQtdPorTipo= await api.get(`/api/amostras/bi/resultadosPorTipo/${novaData}`)
        setQtdPorTipo(capturarQtdPorTipo(responseQtdPorTipo.data))

        const responseCasosPorPlanos = await api.get(`/api/amostras/bi/resultadosPorPlano/${novaData}`)

        setQtdPorPlanos(capturarQtdTop5(responseCasosPorPlanos.data))
        setTop5planos(capturarTop5(responseCasosPorPlanos.data))


      } catch (error) {
        enqueueSnackbar("Falha na captura de dados do BI, por favor tente novamente.", { variant: "error" });
      }
    }
    if (tabela === 'bi') {
      carregarDados();
    }
  }, [enqueueSnackbar, novaData, tabela]);
  //-----------------------------------------------------------
  const pallet = [
    "#8a50ed",
    "#15f2a8",
    "#db8f02",
    "#a51738",
    "#167275",
    "#db9462",
    "#dd4b81",
    "#e0c164",
    "#bd00f2",
    "#23fc88",
    "#e80d6c",
    "#7D9FF5",
    "#FCD92B",
    "#74d604",
    "#3a419e",
  ];

  const GraficoAmostrasStatus = {
    labels: ["Triagem", "Pré-Extração", "Extração", "PCR", "Processado"],
    datasets: [
      {
        label: "AmostrasStatus",
        backgroundColor: ["#63F557", "#42A5F5", "#E516F2", "#FF7A43", "#EBE501"],
        hoverBackgroundColor: ["#07801D", "#4B4CF0", "#8D3BAD", "#E63901", "#ABA500"],
        data: [triagem, PreExtracao, extracao, pcr, processado],
      },
    ],
  };

  const mapas = {
    labels: [`Preparação`, `Processamento`, `Análise`, `Concluído`],
    datasets: [
      {
        label: "Mapas",
        backgroundColor: ["#42A5F5", "#FF7A43", "#63F557"],
        hoverBackgroundColor: ["#4B4CF0", "#FF4B00", "#07801D"],
        data: [preparacao, emProcessamento, emAnalise, concluido],
      },
    ],
  };

  const GraficoQTDamostras = {
    labels: [`Urgência`, `Repetição`, `Normal`],
    datasets: [
      {
        label: "Amostras",
        backgroundColor: ["#FF1A00", "#FFFC04", "#0913FF"],
        hoverBackgroundColor: ["#FFAE9C", "#FFF690", "#7E84FF"],
        data: [urgencia, repeticao, normal],
      },
    ],
  };

  const GraficoTipoPorCasos = {
    labels: [`Positivo`, `Negativo`],
    datasets: [
      {
        label: "Resultados",
        backgroundColor: ["#FF1A00", "#63F557"],
        hoverBackgroundColor: ["#FFAE9C", "#07801D"],
        data: qtdPorTipo,
      },
    ],
  };
  const GraficoPositivosPorPlanos = {
    labels: top5planos,
    datasets: [
      {
        label: "Resultados",
        backgroundColor: pallet,

        data: casosPorPlanos,
      },
    ],
  };

  return (
    <div>
      <div>
        <Grid container spacing={2}>
          <Grid item xs={6} sm={6}>
            <Card>
              <CardContent>
                <Typography variant="h5" component="h1" style={{ textAlign: "center" }}>
                  {qtdMapas || 0}
                </Typography>
                <Typography variant="subtitle1" component="h1" style={{ textAlign: "center" }}>
                  Mapas
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={6} sm={6}>
            <Card>
              <CardContent>
                <Typography variant="h5" component="h1" style={{ textAlign: "center" }}>
                  {qtdAmostras || 0}
                </Typography>
                <Typography variant="subtitle1" component="h1" style={{ textAlign: "center" }}>
                  Amostras
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>

      <div className="BarMapas">
        <Paper className="PaperMapas" style={{height: "20rem"}}>
          <Bar
            data={mapas}
            options={{
              showAllTooltips: true,
              plugins: {
                datalabels: { display: "auto", anchor: "end", align: "end", fontSize: 20 },
              },
              responsive: true,
              maintainAspectRatio: false,
              scales: {
                yAxes: [
                  {
                    ticks: {
                      beginAtZero: true,
                      min: 0,
                    },
                  },
                ],
              },

              title: {
                display: true,
                text: "Mapas por etapa",
                fontSize: 20,
                padding: 25,
              },
              legend: {
                display: false,
                position: "bottom",
              },
            }}
          />
        </Paper>
      </div>
      <div className="BarAmostras">
        <Paper className="PaperAmostras">
          <Bar
            data={GraficoQTDamostras}
            options={{
              showAllTooltips: true,
              plugins: {
                datalabels: { display: "auto", anchor: "end", align: "end", fontSize: 20 },
              },
              responsive: true,
              maintainAspectRatio: false,
              scales: {
                yAxes: [
                  {
                    ticks: {
                      beginAtZero: true,
                      min: 0,
                    },
                  },
                ],
              },

              title: {
                padding: 25,
                display: true,
                text: "Amostras por tipo",
                fontSize: 20,
              },
              legend: {
                display: false,
                position: "bottom",
              },
            }}
          />
        </Paper>
      </div>
      <div className="BarAmostraStatus">
        <Paper className="PaperAmostraStatus">
          <Bar
            data={GraficoAmostrasStatus}
            options={{
              showAllTooltips: true,
              plugins: {
                datalabels: { display: "auto", anchor: "end", align: "end" },
                responsive: true,
              },
              maintainAspectRatio: false,
              scales: {
                yAxes: [
                  {
                    ticks: {
                      beginAtZero: true,
                      min: 0,
                    },
                  },
                ],
              },

              title: {
                display: true,
                text: "Amostras por etapa",
                fontSize: 20,
                padding: 25,
              },
              legend: {
                display: false,
                position: "bottom",
              },
            }}
          />
        </Paper>
      </div>

      <div className="BarTipoPorCasos">
        <Paper className="PaperTipoPorCasos">
          <Bar
            data={GraficoTipoPorCasos}
            options={{
              showAllTooltips: true,
              plugins: {
                datalabels: { display: "auto", anchor: "end", align: "end" },
                responsive: true,
              },
              maintainAspectRatio: false,
              scales: {
                yAxes: [
                  {
                    ticks: {
                      beginAtZero: true,
                      min: 0,
                    },
                  },
                ],
              },

              title: {
                display: true,
                text: "Quantidade por tipo",
                fontSize: 20,
                padding: 25,
              },
              legend: {
                display: false,
                position: "bottom",
              },
            }}
          />
        </Paper>
      </div>
      <div className="BarPositivoPorPlano">
        <Paper className="PaperPositivoPorPlano">
          <Bar
            data={GraficoPositivosPorPlanos}
            options={{
              showAllTooltips: true,
              plugins: {
                datalabels: { display: "auto", anchor: "end", align: "end" },
                responsive: true,
              },
              maintainAspectRatio: false,
              scales: {
                yAxes: [
                  {
                    ticks: {
                      beginAtZero: true,
                      min: 0,
                    },
                  },
                ],
              },

              title: {
                display: true,
                text: "Casos positivos por plano",
                fontSize: 20,
                padding: 25,
              },
              legend: {
                display: false,
                position: "bottom",
              },
            }}
          />
        </Paper>
      </div>
    </div>
  );
}
