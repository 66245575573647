import React, { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Box,
  Grid,
  Modal,
} from '@mui/material';
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import "./styles.css";

import { api } from "../../../services/Api";

function ModalUnidade({
  isModalUnidadeOpen,
  handleCloseModalUnidade,
  unidade,
  setUnidade,
  setPlaceholderUnidadeButton,
}) {
  const [unidades, setUnidades] = useState(0);
  const { enqueueSnackbar } = useSnackbar();

  async function pegaUnidades() {
    api.get("/v2/geral/unidade").then(response => {
      setUnidades(response.data.unidade);
    });
  }

  useEffect(() => {
    pegaUnidades();
  }, [unidade]);

  function validar() {
    if (!localStorage.getItem("@DNACenter:UnidadeDNA")) {
      enqueueSnackbar("Selecione a unidade", {
        variant: "error",
        autoHideDuration: 3000,
      });
    } else {
      handleCloseModalUnidade();
    }
  }

  function passaNomeUnidade(id_unidade) {
    unidades.map(i => {
      if (parseInt(i.id) === id_unidade) {
        localStorage.setItem("@DNACenter:NomeUnidadeDNA", i.nome);
        setPlaceholderUnidadeButton(localStorage.getItem("@DNACenter:NomeUnidadeDNA"));
      }
      return null;
    });
  }

  return (
    <Modal
      disableBackdropClick
      open={isModalUnidadeOpen}
      onClose={handleCloseModalUnidade}
    >
      <Box className="modal_unidade">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Grid container spacing={1} justify="center" alignItems="stretch">
            <Grid item xs={12}>
              <h1 className="modal-title">
                Unidade DNA Center
                <CloseIcon
                  id="fechar"
                  onClick={() => {
                    validar();
                  }}
                />
              </h1>
            </Grid>

            <Grid item xs={12}>
              <FormControl variant="filled" fullWidth>
                <InputLabel>Unidades</InputLabel>
                <Select
                  fullWidth
                  value={unidade}
                  onChange={event => {
                    localStorage.setItem("@DNACenter:UnidadeDNA", event.target.value);
                    setUnidade(event.target.value);
                    passaNomeUnidade(event.target.value);
                  }}
                >
                  <MenuItem value={0} key="exame-0">
                    Selecione a unidade do DNA
                  </MenuItem>
                  {unidades.length > 0
                    ? unidades.map(u => (
                        <MenuItem value={Number(u.id)} key={`unidade-${u.id}`}>
                          {u.nome}
                        </MenuItem>
                      ))
                    : null}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <div className="buttons">
                <div>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      validar();
                    }}
                  >
                    Entrar
                  </Button>
                </div>
              </div>
            </Grid>
          </Grid>
        </LocalizationProvider>
      </Box>
    </Modal>
  );
}

export default ModalUnidade;
