import React, { useContext } from "react";
import { useSnackbar } from "notistack";
import { parseISO } from "date-fns";
import {
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";

import { ColetaDomiciliarContext } from "../../../Context/ColetaDomiciarContext";
import { ModaisColetaDomiciliarContext } from "../../../Context/ModaisColetaDomiciarContext";
import { FormularioColetaDomiciliarContext } from "../../../Context/FormularioColetaDomiciliarContext";

import SalvaLog from "../../../../../utils/SalvaLog";

import { api } from "../../../../../services/Api";

export default function ModalDuplicar() {
  const { enqueueSnackbar } = useSnackbar();

  const { coleta, vetorHora, usuario, setAtualizaTabela } = useContext(
    ColetaDomiciliarContext
  );
  const { duplicata, fechaDuplicata, fechaOpcoes, setMsg, setAviso } =
    useContext(ModaisColetaDomiciliarContext);
  const { novaHora, setNovaHora } = useContext(
    FormularioColetaDomiciliarContext
  );

  async function duplicaColeta() {
    function verificarDataHora(data) {
      const dataAtual = new Date();
      if (parseISO(data).getTime() < parseISO(dataAtual).getTime()) {
        enqueueSnackbar("A data selecionada é menor do que a data atual", {
          variant: "error",
        });
        return false;
      }
      return true;
    }

    const horarioValido = verificarDataHora(coleta.data, novaHora);

    if (horarioValido) {
      const dadosDuplica = {
        latitude: coleta.latitude,
        longitude: coleta.longitude,
        localizacao: coleta.localizacao,
        localizador: coleta.localizador,
        coletador: coleta.coletador,
        data: `${new Date(coleta.data).toISOString()}`.split("T")[0],
        hora: novaHora,
        status: coleta.status,
        codigo_usuario: usuario,
        codigo_pedido: coleta.codigo_pedido,
        posto_pedido: coleta.posto_pedido,
        endereco: coleta.endereco,
        ponto_referencia: coleta.ponto_referencia,
        tipo_cliente: coleta.tipo_cliente,
        celular: coleta.celular,
        nome: coleta.nome,
        fone: coleta.fone,
        email: coleta.email,
        endereco_numero: coleta.endereco_numero,
        complemento: coleta.complemento,
        bairro: coleta.bairro,
        cidade: coleta.cidade,
        estado: coleta.estado,
        cep: coleta.cep,
        observacoes: coleta.observacoes,
      };
      try {
        await api.post("/api/coletaDomiciliar/duplica", {
          latitude: coleta.latitude,
          longitude: coleta.longitude,
          localizacao: coleta.localizacao,
          localizador: coleta.localizador,
          coletador: coleta.coletador,
          data: `${new Date(coleta.data).toISOString()}`.split("T")[0],
          hora: novaHora,
          status: coleta.status,
          codigo_usuario: usuario,
          codigo_pedido: coleta.codigo_pedido,
          posto_pedido: coleta.posto_pedido,
          endereco: coleta.endereco,
          ponto_referencia: coleta.ponto_referencia,
          tipo_cliente: coleta.tipo_cliente,
          celular: coleta.celular,
          nome: coleta.nome,
          fone: coleta.fone,
          email: coleta.email,
          endereco_numero: coleta.endereco_numero,
          complemento: coleta.complemento,
          bairro: coleta.bairro,
          cidade: coleta.cidade,
          estado: coleta.estado,
          cep: coleta.cep,
          observacoes: coleta.observacoes,
        });

        SalvaLog(
          `Duplicou o agendamento do cliente ${coleta.localizador} feita pelo
            coletador ${coleta.coletador} do dia ${coleta.data} as ${coleta.hora} para as ${novaHora}`,
          "Coleta Domiciliar",
          JSON.stringify(dadosDuplica),
          `${coleta.id}`
        );
        setAtualizaTabela((prev) => !prev);
        enqueueSnackbar("Operação realizada com sucesso", {
          variant: "success",
        });
      } catch (error) {
        SalvaLog(
          `Tentou duplicar o agendamento do cliente ${coleta.localizador} feita pelo
            coletador ${coleta.coletador} do dia ${coleta.data} as ${coleta.hora} para as ${novaHora}`,
          "Coleta Domiciliar",
          JSON.stringify(dadosDuplica),
          `${coleta.id}`
        );
        enqueueSnackbar("Falha na operação", { variant: "error" });
        setMsg(error.response.data.mensagem);
        setAviso(true);
      }
    }
    fechaDuplicata();
    fechaOpcoes();
  }

  return (
    <Dialog
      open={duplicata}
      onClose={() => {
        fechaDuplicata();
      }}
    >
      <DialogTitle id="alert-dialog-title">Duplicar agendamento</DialogTitle>
      <Grid item xs={12} container spacing={2}>
        <Grid id="gridHora" item xs={6} md={3}>
          <InputLabel className="nomeHora">Selecionar horário</InputLabel>
          <Select
            variant="standard"
            className="selectForm-coleta"
            value={novaHora}
            onChange={(e) => setNovaHora(e.target.value)}
          >
            {vetorHora.map((h) => (
              <MenuItem key={h.hora} value={h.hora}>
                {h.hora}
              </MenuItem>
            ))}
          </Select>
        </Grid>
      </Grid>
      <DialogActions>
        <button
          type="button"
          className="btnDialogs"
          onClick={() => {
            duplicaColeta();
          }}
        >
          Confirmar
        </button>
        <button
          type="button"
          className="btnDialogs"
          onClick={() => {
            fechaDuplicata();
          }}
        >
          Desistir
        </button>
      </DialogActions>
    </Dialog>
  );
}
