import React, { useEffect, useState, useContext } from "react";

import { Button as MuiButton, Grid } from "@mui/material";

import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";

import { useSnackbar } from "notistack";
import { api } from "../../../../../services/Api";
import { Modal } from "../../../../../componentsv2/Modal";
import { ModalForm } from "../../../../../componentsv2/ModalForm";
import { Select } from "../../../../../componentsv2/Select";
import { Input } from "../../../../../componentsv2/Input";
import DoneOutlineIcon from "@mui/icons-material/DoneOutline";
import { colorTheme } from "../../../../../theme";
import { Context as PermissoesContext } from "../../../../../Context/PermissoesContext";

export default function InventariarBem({
  row,
  concluido,
  setUpdate,
  update,
  inventarioId,
}) {
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const [openConfirmacaoInventariar, setOpenConfirmacaoInventariar] = useState(false);
  const { checarPermissao } = useContext(PermissoesContext);
  const [observacaoInventariar, setObservacaoInventariar] = useState("");
  const [estado, setEstado] = useState("");
  const estadosConservacao = ["Excelente", "Ótimo", "Bom", "Regular", "Péssimo"];
  const [inventariado, setInventariado] = useState(false);

  useEffect(() => {
    if (row.situacao === "Inventariado") {
      setInventariado(true);
    }
  }, [row]);

  const inventariar = async e => {
    e.preventDefault();

    try {
      await api.put(`/v2/inventario/inventariarBem/${row.id}`, {
        inventarioId,
        estadoConservacaoBem: estado,
        observacao: observacaoInventariar,
      });
      setInventariado(true);
      setOpen(false);

      setOpenConfirmacaoInventariar(false);
      setUpdate(!update);
      enqueueSnackbar("Item inventariado com sucesso.", {
        variant: "success",
      });
    } catch (error) {
      enqueueSnackbar("Erro! Não foi possível inventariar o bem.", {
        variant: "error",
      });
    }
  };

  return (
    <>
      {concluido === "Concluído" ? (
        inventariado ? (
          <MuiButton disabled style={{ color: colorTheme.success[500] }}>
            <DoneOutlineIcon />
          </MuiButton>
        ) : (
          <MuiButton
            onClick={() =>
              enqueueSnackbar(
                "Você não pode inventariar um bem dentro de um inventário concluído.",
                {
                  variant: "error",
                  autoHideDuration: 3000,
                }
              )
            }
            style={{ color: "#000" }}
          >
            <ThumbUpOffAltIcon />
          </MuiButton>
        )
      ) : inventariado ? (
        <MuiButton disabled style={{ color: colorTheme.success[500] }}>
          <DoneOutlineIcon />
        </MuiButton>
      ) : (
        <MuiButton
          style={{ color: "#000" }}
          onClick={() => {
            if (checarPermissao("INV028")) {
              setOpen(true);
            } else {
              enqueueSnackbar("Você não possui permissão para inventariar um bem.", {
                variant: "error",
                autoHideDuration: 3000,
              });
            }
          }}
        >
          <ThumbUpOffAltIcon />
        </MuiButton>
      )}

      <ModalForm
        title="Inventariar Bem"
        onClose={setOpen}
        open={open}
        onSubmit={() => setOpenConfirmacaoInventariar(true)}
      >
        <Grid xs={6} item>
          <Select
            value={estado}
            onChange={e => setEstado(e.target.value)}
            label="Estado de Conservação"
            required
            variant="standard"
            arrayOptions={estadosConservacao}
          />
        </Grid>

        <Grid xs={6} item>
          <Input
            onChange={e => setObservacaoInventariar(e.target.value)}
            value={observacaoInventariar}
            multiline
            maxRows={5}
            fullWidth
            label="Observação"
            type="text"
            variant="standard"
          />
        </Grid>
      </ModalForm>
      <Modal
        modalText="Tem certeza que deseja inventariar este bem?"
        confirmActionButtonText="Confirmar"
        open={openConfirmacaoInventariar}
        onClose={setOpenConfirmacaoInventariar}
        onClick={inventariar}
        confirmActionButtonColor="green"
      />
    </>
  );
}
