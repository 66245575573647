import React, { useState, useEffect } from "react";

import { Grid } from '@mui/material';

import "./styles.css";

import { api } from "../../../../services/Api";
import { Select } from "../../../../componentsv2/Select";
import { useSnackbar } from "notistack";

export default function SelectUnidade({
  onChange,
  value,
  required = false,
  disabled = false,
}) {
  const [unidades, setUnidades] = useState([]);

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    async function pegaUnidades() {
      try {
        const arrayUnidades = await api.get("/v2/geral/unidade");
        const unidadesArray = arrayUnidades.data.unidade;
        unidadesArray.unshift({ id: "", nome: "Todos" });
        setUnidades(unidadesArray);
      } catch (error) {
        enqueueSnackbar("Erro! Não foi possível buscar as unidades.", {
          variant: "error",
        });
      }
    }

    pegaUnidades();
  }, [enqueueSnackbar]);

  return (
    <>
      <Grid container spacing={1} justify="center" alignItems="stretch">
        <Grid item xs={12}>
          <Select
            required={required}
            disabled={disabled}
            onChange={onChange}
            value={value}
            fullWidth
            variant="standard"
            label="Unidade"
            arrayOptions={unidades}
            textAttribute="nome"
            valueAttribute="id"
          />
        </Grid>
      </Grid>
    </>
  );
}
