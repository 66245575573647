import React, { useContext, useEffect, useState } from "react";

import Tabela from "./Tabela";
import TabelaMobile from "./TabelaMobile";

import { ColetaDomiciliarContext } from "../../Context/ColetaDomiciarContext";

import "./styles.css";

export default function Agenda() {
  const { agendamentos } = useContext(ColetaDomiciliarContext)

  const [tamanho, setTamanho] = useState("");

  function sizeOfScreen() {
    setTamanho(window.innerWidth);
  }

  useEffect(() => {
    sizeOfScreen();
  }, []);

  window.addEventListener("resize", () => {
    sizeOfScreen();
  });

  return (
    <div>
      <p id="qtdAgendamentosDisp">
        Total de agendamentos: <strong>{agendamentos.length}</strong>
      </p>
      {tamanho > 499 ? <Tabela /> : <TabelaMobile />}
    </div>
  );
}
