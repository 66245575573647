import { ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  InputLabel,
} from "@mui/material";
import { useState } from "react";
import ModalAnexo from "./ModalAnexo";
import { api, baseUrl } from "../../../services/Api";
import { useHistory } from "react-router-dom";

export default function TabelaAccordionContrato({
  contratos,
  setUpdate,
  update,
  fornecedores,
}) {
  const [expanded, setExpanded] = useState(false);
  const [openModalAnexo, setOpenModalAnexo] = useState(false);
  const [urlAnexo, setUrlAnexo] = useState([]);
  const [idContrato, setIdContrato] = useState("");
  const history = useHistory();
  const [situacaoAtual, setSituacaoAtual] = useState("");
  const [observacaoAtual, setObservacaoAtual] = useState("");

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const ajustarData = (data) => {
    if (data) {
      const date = new Date(data.split("/").reverse().join("-"));
      date.setDate(date.getDate() + 2);
      return date.toLocaleDateString();
    }
    return null;
  };

  return (
    <div>
      <ModalAnexo
        idContrato={idContrato}
        situacaoAtual={situacaoAtual}
        anexos={urlAnexo}
        onClose={setOpenModalAnexo}
        open={openModalAnexo}
        onCancel={() => setOpenModalAnexo(false)}
        title="Analisar documento"
        observacaoAtual={observacaoAtual}
      />
      {contratos?.map((contrato) => (
        <Accordion
          key={contrato.id}
          expanded={expanded === `panel${contrato.id}`}
          onChange={handleChange(`panel${contrato.id}`)}
          style={{
            borderRadius: "4px",
            marginBottom: "6px",
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="panel1a-content"
            id="container-cell-table"
            style={{
              borderRadius: "4px",
              backgroundColor:
                contrato.status === "Autorizado"
                  ? "#6684c1"
                  : contrato.status === "Não Autorizado"
                  ? "#fca5a5"
                  : contrato.status === "Conferido"
                  ? "#CEF6E3"
                  : "#FFF",
              color: contrato.status === "Autorizado" ? "white" : "black",
            }}
          >
            <div id="container-label-dialog-coleta">
              <span id="label-dialog-tittle-coleta">
                {contrato.nome_fornecedor}
              </span>
              <span id="label-dialog-coleta">{contrato.status}</span>
              <span
                id="label-dialog-coleta"
                // style={{
                //   color:
                //     contrato.status === "Autorizado" ? "#8BDA72" : "#0D5F13",
                // }}
              >
                {contrato.valor
                  ? contrato.valor.toLocaleString("pt-BR", {
                      style: "currency",
                      currency: "BRL",
                    })
                  : ""}
              </span>
              <span id="label-dialog-coleta">
                {ajustarData(contrato.data_vencimento)}
              </span>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div>
              <InputLabel id="label1-table-dialog-coleta">
                {contrato.descricao}
              </InputLabel>

              <div id="container-button-accordeon-coleta">
                {/* {row.pagou && (
          <div className="button-accordeon-coleta">
            <button
              id="btn-coleta-check"
              className="btn-coleta-check-default"
              type="button"
              onClick={() => {
                setValor_recebido(row.valor_recebido);
                setNome(row.nome);
                setCpf_cliente(row.cpf);
                setRecibo(true);
              }}
            >
              Recibo
            </button>
          </div>
        )} */}

                <div className="button-accordeon-coleta">
                  <button
                    id="btn-coleta-check"
                    className="btn-coleta-check-default"
                    type="button"
                    onClick={() => {
                      setUrlAnexo([]);
                      setSituacaoAtual("");

                      const urls = contrato.anexos?.map(
                        (anexo) =>
                          `${baseUrl}/static/v2/private/contratos/${anexo}?token=${api.defaults.headers.Authorization}`
                      );

                      setUrlAnexo(urls);
                      setIdContrato(contrato.id);

                      setSituacaoAtual(contrato.status);
                      setObservacaoAtual(
                        contrato.observacao ? contrato.observacao : ""
                      );
                      setOpenModalAnexo(true);
                    }}
                  >
                    Visualizar Documento
                  </button>
                </div>

                <div className="button-accordeon-coleta">
                  <button
                    id="btn-coleta-check"
                    className="btn-coleta-check-default"
                    type="button"
                    onClick={() =>
                      history.push({
                        pathname: "/financeiro/contratos/criar",
                        state: { dados: contrato },
                      })
                    }
                  >
                    Editar Contrato
                  </button>
                </div>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
}
