import React, { useContext } from "react";
import { Tooltip } from "@mui/material";
import { RequestPage } from "@mui/icons-material";

import { ColetaDomiciliarContext } from "../../../Context/ColetaDomiciarContext";
import { ModaisColetaDomiciliarContext } from "../../../Context/ModaisColetaDomiciarContext";
import { FormularioColetaDomiciliarContext } from "../../../Context/FormularioColetaDomiciliarContext";
import { WorkspacePremium } from "@mui/icons-material";
import Waiter from "../../../../../components/Backdrop";

export default function Tabela() {
  const { agendamentos, loadingAgendamentos } = useContext(
    ColetaDomiciliarContext
  );

  const { setValor_recebido, setCpf_cliente, setNome } = useContext(
    FormularioColetaDomiciliarContext
  );

  const {
    abreOpcoes,
    abrirMenuStatus,
    abrirDetalheCliente,
    abrirDetalheExame,
    setRecibo,
  } = useContext(ModaisColetaDomiciliarContext);

  return (
    <div id="container-table-drive">
      <Waiter espera={loadingAgendamentos} />
      <table id="table-drive">
        <thead>
          <tr>
            <th align="center"> </th>
            <th align="center">Status</th>
            <th align="center">Usuário</th>
            <th align="center">Coletador</th>
            <th align="center">Hora</th>
            <th align="center">Pedido</th>
            <th id="cliente" align="center">
              Cliente
            </th>
            <th align="center">Opções</th>
          </tr>
        </thead>
        <tbody>
          {agendamentos.map((row) => (
            <tr key={row.id}>
              <td align="center">
                {row.pagou &&
                (row.tipo_cliente === "Normal" ||
                  row.tipo_cliente === "DNACOV") ? (
                  <Tooltip title="Recibo do cliente">
                    <button
                      type="button"
                      className="btnOpcao"
                      onClick={() => {
                        setValor_recebido(row.valor_recebido);
                        setNome(row.nome);
                        setCpf_cliente(row.cpf);
                        setRecibo(true);
                      }}
                    >
                      <RequestPage color="success" />
                    </button>
                  </Tooltip>
                ) : row.pagou &&
                  (row.tipo_cliente !== "Normal" ||
                    row.tipo_cliente !== "DNACOV") ? (
                  <WorkspacePremium color="warning" />
                ) : (
                  <></>
                )}
              </td>
              <td align="center">
                <div className="selectdiv">
                  <label htmlFor="seleciona-status">
                    <select
                      id="seleciona-status"
                      className="selectStatus"
                      value={row.status}
                      onChange={(e) => abrirMenuStatus(row, e.target.value)}
                    >
                      <option key="10" value="Pendente">
                        Pendente
                      </option>
                      <option key="20" value="Agendado">
                        Agendado
                      </option>
                      <option key="30" value="Coletado">
                        Coletado
                      </option>
                    </select>
                  </label>
                </div>
              </td>
              <td align="center">{row.codigo_usuario}</td>
              <td align="center">{row.coletador}</td>
              <td align="center">{row.hora}</td>
              <td align="center">
                {row.codigo_pedido}-{row.posto_pedido}
              </td>
              <td id="cliente" align="left">
                {row.nome}
              </td>
              <td align="center">
                <div id="containerOpcoes">
                  <Tooltip title="Visualizar dados do cliente">
                    <button
                      type="button"
                      className="btnOpcao"
                      onClick={() => abrirDetalheCliente(row)}
                    >
                      <i className="fa fa-eye" />
                    </button>
                  </Tooltip>
                  <Tooltip title="Descrição dos exames">
                    <button
                      type="button"
                      className="btnOpcao"
                      onClick={() => abrirDetalheExame(row)}
                    >
                      <i className="fa fa-flask" />
                    </button>
                  </Tooltip>
                  <Tooltip title="Abrir opções">
                    <button
                      type="button"
                      className="btnOpcao"
                      onClick={() => abreOpcoes(row)}
                    >
                      <i className="fa fa-cog" />
                    </button>
                  </Tooltip>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
