import React, { useContext } from "react";
import { IconButton } from "@mui/material";

import Formulario from "./Formulario";
import Tabela from "../../../../../componentsv2/Tabela";
import ModalGlosasLotes from "../ModalGlosasLotes";

import { RecebimentoContext } from "../../Context/RecebimentoContext";
import { ModalRecebimentoContext } from "../../Context/ModalRecebimentoContext";
import { RetornoLoteContext } from "../../Context/RetornoLoteContext";

import formatoMoeda from "../../../../../utils/formatoMoeda";

export default function TelaAgrupaLotes() {
  const { retornoLoteAgrupado } = useContext(RecebimentoContext);
  const { setId_lote, setModalGlosasLotes } = useContext(
    ModalRecebimentoContext
  );
  const { setGlosas } = useContext(RetornoLoteContext);

  const ConfigGuia = ({ row }) => {
    const handleEditClick = () => {
      setModalGlosasLotes(true);
      if (row.glosas.length > 0) {
        setId_lote(row.id);
        setGlosas(row.glosas);
      }
    };

    return (
      <IconButton onClick={handleEditClick}>
        <i className="fa fa-list" style={{ color: "#003399" }} />
      </IconButton>
    );
  };

  const colunas = [
    {
      field: "id",
      headerName: "ID",
      headerAlign: "center",
      flex: 1,
      hide: true,
    },
    {
      field: "lote_plano",
      headerName: "Lote",
      headerAlign: "center",
      width: 130,
      editable: false,
    },
    {
      field: "valor_recuperado",
      headerName: "Faturado",
      type: "number",
      headerAlign: "center",
      flex: 1,
      minWidth: 120,
      valueFormatter: ({ value }) => {
        const valor = value ? formatoMoeda(value, true) : "0,00";
        return valor;
      },
    },
    {
      field: "valor_imposto",
      headerName: "Impostos",
      type: "number",
      headerAlign: "center",
      flex: 1,
      minWidth: 120,
      valueFormatter: ({ value }) => {
        const valor = value ? formatoMoeda(value, true) : "0,00";
        return valor;
      },
    },
    {
      field: "valor_desconto_unimed",
      headerName: "Desc. Unimed",
      type: "number",
      headerAlign: "center",
      flex: 1,
      minWidth: 120,
      valueFormatter: ({ value }) => {
        const valor = value ? formatoMoeda(value, true) : "0,00";
        return valor;
      },
    },
    {
      field: "valor_liquido",
      headerName: "Líquido",
      type: "number",
      headerAlign: "center",
      flex: 1,
      minWidth: 120,
      valueFormatter: ({ value }) => {
        const valor = value ? formatoMoeda(value, true) : "0,00";
        return valor;
      },
    },
    {
      field: "valor_recebido",
      headerName: "Valor Recebido",
      type: "number",
      headerAlign: "center",
      flex: 1,
      minWidth: 120,
      valueFormatter: ({ value }) => {
        const valor = value ? formatoMoeda(value, true) : "0,00";
        return valor;
      },
    },
    {
      field: "valor_glosado2",
      headerName: "Glosado",
      type: "number",
      headerAlign: "center",
      flex: 1,
      minWidth: 120,
      renderCell: (params) => (
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
            cursor: "pointer",
          }}
        >
          <ConfigGuia row={params.row} />
          <span style={{ paddingTop: "0.5rem" }}>
            {params.row.valor_glosado
              ? formatoMoeda(params.row.valor_glosado, true)
              : "0,00"}
          </span>
        </div>
      ),
    },
    {
      field: "valor_acatado",
      headerName: "Acatado",
      type: "number",
      headerAlign: "center",
      flex: 1,
      minWidth: 120,
      valueFormatter: ({ value }) => {
        const valor = value ? formatoMoeda(value, true) : "0,00";
        return valor;
      },
    },
    {
      field: "em_recurso",
      headerName: "Em recurso",
      type: "number",
      headerAlign: "center",
      flex: 1,
      minWidth: 120,
      valueFormatter: ({ value }) => {
        const valor = value ? formatoMoeda(value, true) : "0,00";
        return valor;
      },
    },
    {
      field: "valor_diferenca_tabela",
      headerName: "Diferença de Tabelas",
      type: "number",
      headerAlign: "center",
      flex: 1,
      minWidth: 120,
      valueFormatter: ({ value }) => {
        const valor = value ? formatoMoeda(value, true) : "0,00";
        return valor;
      },
    },
    {
      field: "valor_diferenca_unimed",
      headerName: "Diferença Unimed",
      type: "number",
      headerAlign: "center",
      flex: 1,
      minWidth: 120,
      valueFormatter: ({ value }) => {
        const valor = value ? formatoMoeda(value, true) : "0,00";
        return valor;
      },
    },
  ];

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        textAlign: "center",
        height: "45rem",
        marginTop: "2rem",
      }}
    >
      <Formulario />
      <Tabela
        disableColumnMenu
        columns={colunas}
        rows={retornoLoteAgrupado}
        pageSize={100}
        rowsPerPageOptions={[100, 200]}
      />
      <ModalGlosasLotes />
    </div>
  );
}
