import React, { useState, createContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import SalvaLog from "../utils/SalvaLog";

import { api } from "../services/Api";

const Context = createContext();

function AuthProvider({ children }) {
  const history = useHistory();
  const [authenticated, setAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      const token = localStorage.getItem("@DNACenter:token");

      if (token) {
        api.defaults.headers.Authorization = `Bearer ${token}`;
        try {
          await api.get("/api/verificaToken");
        } catch (e) {
          localStorage.clear();
          api.defaults.headers.Authorization = undefined;
          setLoading(false);
          return null;
        }

        api.defaults.headers.Authorization = `Bearer ${token}`;
        setAuthenticated(true);
      }

      setLoading(false);
      return null;
    })();
  }, []);

  async function login(usuario, senha, url) {
    if (usuario === "" || senha === "") {
      return new Promise((resolve, reject) =>
        reject(new Error("Credenciais inválidas."))
      );
    }

    let user;
    try {
      user = await api.post("/api/softlab/validaUsuario", {
        usuario,
        senha,
        url,
      });
    } catch (e) {
      return new Promise((resolve, reject) =>
        reject(new Error("Credenciais inválidas."))
      );
    }

    if (user.data.length === 0) {
      return new Promise((resolve, reject) =>
        reject(new Error("Credenciais inválidas."))
      );
    }

    localStorage.setItem(
      "@DNACenter:codigoUsuario",
      user.data.codigo_usuario.trim()
    );
    localStorage.setItem("@DNACenter:token", user.data.token);
    try {
      SalvaLog("Iniciou uma sessão", "Login");
    } catch (e) {
      // console.log(e);
    }
    api.defaults.headers.Authorization = `Bearer ${user.data.token}`;
    setAuthenticated(true);

    if (url) {
      const urlAjustado = url.replaceAll("/login", "");
      const urlFinal = urlAjustado.replaceAll("/intranet", "");

      history.push(`${urlFinal}`);
    } else {
      history.push("/home");
    }
    return new Promise((resolve) => resolve());
  }

  async function logout() {
    setAuthenticated(false);
    SalvaLog("Finalizou a sessão");
    // localStorage.clear();
    localStorage.removeItem("@DNACenter:codigoUsuario");
    localStorage.removeItem("@DNACenter:token");
    api.defaults.headers.Authorization = undefined;
    history.push("/login");
  }

  return (
    <Context.Provider value={{ authenticated, loading, login, logout }}>
      {children}
    </Context.Provider>
  );
}

export { Context, AuthProvider };
