import React from "react";
import {
  DataGrid,
  GridLogicOperator,
  GridToolbar,
  GridToolbarQuickFilter,
  ptBR,
} from "@mui/x-data-grid";

import { LinearProgress, Stack, useMediaQuery, useTheme } from "@mui/material";
import { Box } from "@mui/system";
import CustomPagination from "./customPagination";

export default function Tabela({
  rows,
  columns,
  linhaNaoSelecionavel = true,
  autoPageSize = false,
  ocultarToolbar = false,
  ...rest
}) {
  const theme = useTheme();
  const tamanho = useMediaQuery(theme.breakpoints.up("sm"));

  const SearchToolbarWithGridToolbar = () => (
    <Box sx={{ p: 1, pb: 0 }}>
      <Stack
        direction={{ sm: "column", md: "row" }}
        justifyContent={{ sm: "center", md: "space-between" }}
        alignItems={{ sm: "center", md: "normal" }}
        spacing={{ md: 3 }}
      >
        <GridToolbarQuickFilter
          fullWidth
          size="medium"
          quickFilterParser={(searchInput) =>
            searchInput
              .split(",")
              .map((value) => value.trim())
              .filter((value) => value !== "")
          }
        />
        <GridToolbar />
      </Stack>
    </Box>
  );

  const SearchToolbar = () => (
    <Box sx={{ p: 1, pb: 0 }}>
      <GridToolbarQuickFilter
        fullWidth
        size="small"
        quickFilterParser={(searchInput) =>
          searchInput
            .split(",")
            .map((value) => value.trim())
            .filter((value) => value !== "")
        }
      />
    </Box>
  );

  return (
    <DataGrid
      rows={rows}
      columns={columns}
      localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
      pagination
      // autoPageSize={autoPageSize}
      // rowsPerPageOptions={[5]}
      initialState={{
        pagination: { paginationModel: { pageSize: 15 } },
        filter: {
          filterModel: {
            items: [],
            quickFilterLogicOperator: GridLogicOperator.Or,
          },
        },
      }}
      components={
        tamanho && !ocultarToolbar
          ? {
              Toolbar: SearchToolbarWithGridToolbar,
              LoadingOverlay: LinearProgress,
              Pagination: CustomPagination,
            }
          : {
              Toolbar: SearchToolbar,
              LoadingOverlay: LinearProgress,
              Pagination: CustomPagination,
            }
      }
      // pageSizeOptions={[15, 30, 45]}
      isRowSelectable={() => linhaNaoSelecionavel}
      sx={{
        ".MuiDataGrid-row": {
          ":nth-of-type(odd)": {
            backgroundColor: "#FFF",
          },
        },
        "@media print": {
          ".MuiDataGrid-footerContainer": { display: "none" },
        },
      }}
      {...rest}
    />
  );
}
