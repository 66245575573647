import React, { useState, useContext } from "react";
import { useSnackbar } from "notistack";
import {
  Button as MuiButton,
  Container,
  Grid,
  Modal,
  Paper,
  Box,
  Typography,
  Divider,
  IconButton,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { Input } from "../../../../componentsv2/Input";
import SettingsIcon from '@mui/icons-material/Settings';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import EditIcon from "@mui/icons-material/Edit";
import { useHistory } from "react-router-dom";
import Button from "../../../../componentsv2/Button";
import { Context as PermissoesContext } from "../../../../Context/PermissoesContext";
import { useEffect } from "react";
import { api } from "../../../../services/Api";
import VisibilityIcon from "@mui/icons-material/Visibility";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",

  bgcolor: "background.paper",
  boxShadow: 24,
};

const dividerStyle = {
  width: "100%",
  backgroundColor: "#909099",
};

export default function OpcoesBem({ row }) {
  const [open, setOpen] = useState(false);
  const history = useHistory();
  const { checarPermissao } = useContext(PermissoesContext);
  const { enqueueSnackbar } = useSnackbar();

  const [motivoBaixa, setMotivoBaixa] = useState("");
  const [observacaoBaixa, setObservacaoBaixa] = useState("");
  const [openModalBaixa, setOpenModalBaixa] = useState(false);

  const [codTombador, setCodTombador] = useState("");
  const [produto, setProduto] = useState(null);
  const [proprietario, setProprietario] = useState("");
  const [situacao, setSituacao] = useState("");
  const [marca, setMarca] = useState("");
  const [modelo, setModelo] = useState("");

  function ModalOption(props) {
    async function getBem() {
      try {
        const {
          data: { data },
        } = await api.get(`/v2/inventario/bem/${props.row.id}`);
        setMotivoBaixa(data.motivoBaixa);
        setObservacaoBaixa(data.observacaoBaixa);
      } catch (error) {
        enqueueSnackbar("Erro! Não foi possível achar o bem.", {
          variant: "error",
          autoHideDuration: 3000,
        });
      }
    }

    useEffect(() => {
      setCodTombador(props.row.codTombador);
      setProduto(props.row.material);
      setProprietario(props.row.nomeProprietario);
      setSituacao(props.row.situacao);
      setMarca(props.row.material.marca);
      setModelo(props.row.material.modelo);
      getBem();
      // eslint-disable-next-line
    }, []);

    const handleClickEdit = () => {
      if (checarPermissao("INV020")) {
        if (props.row.situacao === "Baixado") {
          enqueueSnackbar("Erro! Não é possível editar um bem baixado.", {
            variant: "error",
            autoHideDuration: 3000,
          });
          return;
        }
        history.push({
          pathname: "/inventario/bem/cadastrarBem",
          state: { dados: props.row },
        });
      } else {
        enqueueSnackbar("Você não possui permissão para editar um bem!", {
          variant: "error",
          autoHideDuration: 3000,
        });
      }
    };

    return (
      <Container className="options-container" maxWidth="xs">
        <Paper className="options-paper" elevation={2}>
          <Grid container style={{ padding: "1rem 1rem" }}>
            {motivoBaixa ? (
              <Grid item sm={12}>
                <Button
                  onClick={() => setOpenModalBaixa(true)}
                  variant="standard"
                  startIcon={<VisibilityIcon />}
                >
                  Ver motivo da baixa
                </Button>
                <Modal open={openModalBaixa} onClose={setOpenModalBaixa}>
                  <Box sx={modalStyle}>
                    <Grid container spacing={0}>
                      <Grid sx={{ padding: "15px" }} item container spacing={0}>
                        <Grid container justifyContent="space-between" item>
                          <Grid item>
                            <Typography variant="h6">{`${
                              codTombador ? codTombador + " -" : ""
                            } ${produto.modelo}(${
                              produto.marca
                            }) - ${situacao}`}</Typography>
                          </Grid>
                          <Grid item>
                            <IconButton onClick={() => setOpenModalBaixa(false)}>
                              <Close />
                            </IconButton>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Divider sx={dividerStyle} />
                      <Grid
                        sx={{ padding: "30px", marginBottom: "20px" }}
                        item
                        container
                        spacing={3}
                      >
                        <Grid item xs={6}>
                          <Input
                            label="Código Tombador"
                            variant="standard"
                            fullWidth
                            value={codTombador}
                            inputProps={{ readOnly: true }}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <Input
                            label="Produto"
                            variant="standard"
                            fullWidth
                            value={`${produto.modelo}(${produto.marca})`}
                            inputProps={{ readOnly: true }}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <Input
                            label="Marca"
                            variant="standard"
                            fullWidth
                            value={marca}
                            inputProps={{ readOnly: true }}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <Input
                            label="Modelo"
                            variant="standard"
                            fullWidth
                            value={modelo}
                            inputProps={{ readOnly: true }}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <Input
                            label="Motivo da baixa"
                            variant="standard"
                            fullWidth
                            value={motivoBaixa}
                            inputProps={{ readOnly: true }}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <Input
                            label="Proprietário"
                            variant="standard"
                            fullWidth
                            value={proprietario}
                            inputProps={{ readOnly: true }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Input
                            label="Observação da baixa"
                            variant="standard"
                            fullWidth
                            value={observacaoBaixa}
                            inputProps={{ readOnly: true }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Box>
                </Modal>
              </Grid>
            ) : null}

            <Grid item sm={12}>
              <Button
                onClick={handleClickEdit}
                variant="standard"
                startIcon={<EditIcon />}
              >
                Editar
              </Button>
            </Grid>
            <Grid item sm={12}>
              <Button
                onClick={() => setOpen(false)}
                variant="standard"
                startIcon={<ArrowBackIcon />}
              >
                Voltar
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Container>
    );
  }

  return (
    <>
      <MuiButton style={{ color: "#000" }} onClick={() => setOpen(true)}>
        <SettingsIcon />
      </MuiButton>
      <Modal open={open} onClose={() => setOpen(false)}>
        <ModalOption row={row} />
      </Modal>
    </>
  );
}
