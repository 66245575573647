import React, { memo, useContext, useEffect, useState } from "react";
import { FormControl, MenuItem, Select } from "@mui/material";

import { RetornoLoteContext } from "../../Context/RetornoLoteContext";
// import { RetornoProcedimentoContext } from "../../Context/RetornoProcedimentoContext";

function SelectSituacao({ row }) {
  const { setGlosas, setAtualizaValoresRecebimentoManual } = useContext(RetornoLoteContext);
  // const { setRetornoProcedimento, setAtualizaTotal } = useContext(RetornoProcedimentoContext);

  const { id, situacao } = row;
  const [situacaoAtual, setSituacaoAtual] = useState('');

  useEffect(()=>{
    setSituacaoAtual(situacao);
  }, [ situacao ])

  function alteraSituacao(estado) {
    setGlosas(prevState => {
      const novaSituacao = prevState;
      const resultado = novaSituacao.map(item => {
        if (item.id === id) {
          return {
            ...item,
            situacao: estado
          };
        }
        return { ...item };
      });

      return resultado;
    });
    setSituacaoAtual(estado);
    setAtualizaValoresRecebimentoManual(prevState => !prevState);
  }

  return (
    <FormControl variant="standard">
      <Select
        labelId="demo-simple-select-standard-label"
        id="demo-situacao-simple-select-standard"
        value={situacaoAtual}
        onChange={e => alteraSituacao(e.target.value)}
        label="Situação"
        style={{ display: "flex", width: "100%", minWidth: "15rem" }}
        fullWidth
      >
        <MenuItem value="Analise">Em Recurso</MenuItem>
        <MenuItem value="Glosado">Glosado</MenuItem>
        <MenuItem value="Acatado">Glosado Acatado</MenuItem>
      </Select>
    </FormControl>
  );
}

export default memo(SelectSituacao);
