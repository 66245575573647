import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";

import "./styles.css";

import AdicionaUsuario from "./AdicionaUsuario";

import { api } from "../../services/Api";

import { Context as PermissoesContext } from "../../Context/PermissoesContext";
import Header from "../../components/Header";
import Container from "../../componentsv2/Container";

export default function CadastraUsuarioIndex() {
  const { enqueueSnackbar } = useSnackbar();
  const { checarPermissao } = useContext(PermissoesContext);

  const history = useHistory();

  const [dadosUsuarios, setDadosUsuarios] = useState([]);
  const [atualizar, setAtualizar] = useState(false);

  useEffect(() => {
    if (!checarPermissao("CU001")) {
      history.push("/home");
    }
    async function carregaUsuarios() {
      try {
        const resultado = await api.get("/v2/controleAcesso/usuarios");

        setDadosUsuarios(resultado.data.usuarios);
      } catch (error) {
        enqueueSnackbar(
          "Falha ao carregar usuários, por favor, tente novamente",
          {
            variant: "error",
          }
        );
      }
    }
    carregaUsuarios();
    // eslint-disable-next-line
  }, [enqueueSnackbar, atualizar]);

  return (
    <>
      <Header titulo="Cadastro de usuários" />
      <Container>
        <AdicionaUsuario
          setAtualizar={setAtualizar}
          atualizar={atualizar}
          details={dadosUsuarios}
        />
      </Container>
    </>
  );
}
