import React, { useState, useContext, useEffect } from "react";
import Material from "./pages/Material";
import TipoMaterial from "./pages/Material/TipoMaterial";
import Proprietario from "./pages/Proprietario";
import Bem from "./pages/Bem";
import Transferencia from "./pages/Bem/Transferencia";
import { useLocation, useHistory } from "react-router-dom";
import { Context as PermissoesContext } from "../../Context/PermissoesContext";
import { useSnackbar } from "notistack";
import DadosContext from "./DadosContext";
import { api } from "../../services/Api";
import useUser from "../../hooks/useUser";
import Inventarios from "./pages/Inventarios";
import Header from "../../components/Header";
import Container from "../../componentsv2/Container";

export default function Inventario() {
  const location = useLocation();
  const [aba, setAba] = useState(location.state?.dados ? location.state.dados : 4);
  const { checarPermissao, checarPermissaoSemLog } = useContext(PermissoesContext);
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const [unidadeUsuario, setUnidadeUsuario] = useState("");
  const [semAcessoTodasUnidades, setSemAcessoTodasUnidades] = useState(true);
  const usuario = useUser();

  const [abaInventario, setAbaInventario] = useState(
    location.state?.abaInv ? location.state.abaInv : false
  );

  async function checaUnidadeFuncionario() {
    try {
      const { data } = await api.get(`v2/controleAcesso/funcionarioUnidade`, {
        params: { cod_usu: usuario },
      });

      if (checarPermissao("INV030")) {
        setSemAcessoTodasUnidades(false);
      }

      setUnidadeUsuario(data.funcionario.unidade_id);
      if (!data.funcionario.unidade_id) {
        throw new Error("Sem Unidade!");
      }
    } catch (error) {
      history.push("/home");
      enqueueSnackbar(
        "Erro ao encontrar unidade de origem, solicite o cadastramento da unidade do seu usuário!",
        {
          variant: "error",
        }
      );
    }
  }

  useEffect(() => {
    checaUnidadeFuncionario();
    // eslint-disable-next-line
  }, [checarPermissao]);

  return (
    <>
    <DadosContext.Provider
      value={{ setUnidadeUsuario, unidadeUsuario, semAcessoTodasUnidades }}
    >
      <Header titulo="Inventários"/>
      <Container>
        <div style={{ paddingBottom: "0" }} id="container-aba-ctrl">
          <button
            type="button"
            id={!abaInventario ? "button-aba-ctrl-samples-selected" : "Aba6"}
            className="button-aba-ctrl"
            style={{ fontSize: "16px" }}
            onClick={() => setAbaInventario(false)}
          >
            Bens
          </button>
          <button
            type="button"
            id={abaInventario ? "button-aba-ctrl-samples-selected" : "Aba7"}
            className="button-aba-ctrl"
            style={{ fontSize: "16px" }}
            onClick={() => {
              if (checarPermissao("INV002")) {
                setAbaInventario(true);
              } else {
                enqueueSnackbar(
                  "Você não possui permissão para acessar os inventários.",
                  {
                    variant: "error",
                  }
                );
              }
            }}
          >
            Inventários
          </button>
        </div>
        {!abaInventario && (
          <div style={{ paddingBottom: "0" }} id="container-aba-ctrl">
            <button
              type="button"
              id={aba === 3 ? "button-aba-ctrl-samples-selected" : "Aba3"}
              className="button-aba-ctrl"
              style={{ fontSize: "16px" }}
              onClick={() => {
                if (checarPermissao("INV006")) {
                  setAba(3);
                } else {
                  enqueueSnackbar(
                    "Você não possui permissão para acessar os proprietários.",
                    {
                      variant: "error",
                    }
                  );
                }
              }}
            >
              Proprietários
            </button>
            <button
              type="button"
              id={aba === 2 ? "button-aba-ctrl-samples-selected" : "Aba2"}
              className="button-aba-ctrl"
              style={{ fontSize: "16px" }}
              onClick={() => {
                if (checarPermissao("INV010")) {
                  setAba(2);
                } else {
                  enqueueSnackbar(
                    "Você não possui permissão para acessar os tipos de materiais.",
                    {
                      variant: "error",
                    }
                  );
                }
              }}
            >
              Tipos Materiais
            </button>
            <button
              type="button"
              id={aba === 1 ? "button-aba-ctrl-samples-selected" : "Aba1"}
              className="button-aba-ctrl"
              style={{ fontSize: "16px" }}
              onClick={() => {
                if (checarPermissao("INV014")) {
                  setAba(1);
                } else {
                  enqueueSnackbar(
                    "Você não possui permissão para acessar os materiais.",
                    {
                      variant: "error",
                    }
                  );
                }
              }}
            >
              Materiais
            </button>

            <button
              type="button"
              id={aba === 4 ? "button-aba-ctrl-samples-selected" : "Aba4"}
              className="button-aba-ctrl"
              style={{ fontSize: "16px" }}
              onClick={() => {
                if (checarPermissao("INV018")) {
                  setAba(4);
                } else {
                  enqueueSnackbar("Você não possui permissão para acessar os bens.", {
                    variant: "error",
                  });
                }
              }}
            >
              Bem
            </button>
            <button
              type="button"
              id={aba === 5 ? "button-aba-ctrl-samples-selected" : "Aba5"}
              className="button-aba-ctrl"
              style={{ fontSize: "16px" }}
              onClick={() => {
                if (checarPermissao("INV022")) {
                  setAba(5);
                } else {
                  enqueueSnackbar(
                    "Você não possui permissão para acessar as transferências.",
                    {
                      variant: "error",
                    }
                  );
                }
              }}
            >
              Transferências
            </button>
          </div>
        )}

        {abaInventario ? (
          <Inventarios />
        ) : (
          (aba === 1 && checarPermissaoSemLog("INV014") && <Material />) ||
          (aba === 2 && checarPermissaoSemLog("INV010") && <TipoMaterial />) ||
          (aba === 3 && checarPermissaoSemLog("INV006") && <Proprietario />) ||
          (aba === 4 && checarPermissaoSemLog("INV018") && <Bem />) ||
          (aba === 5 && checarPermissaoSemLog("INV022") && <Transferencia />)
        )}
        </Container>
        
    </DadosContext.Provider>
  </>
  );
}
