import React from "react";
import { Paper } from '@mui/material';
import { styled } from '@mui/system';

import "./styles.css";

const StyledPaper = styled(Paper)(({ theme }) => ({
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(2),
      width: theme.spacing(35),
      height: theme.spacing(9),
    },
    justifyContent: 'center',
}));

export default function Cards(props) {
  const { somaQtdExamesPerdas, somaPerdas, somaGanho, somaMelhorGanho, somaAumento, percentAumento } = props;

    return (
      <StyledPaper>
        <Paper elevation={3} style={{backgroundColor: "#F5A9A9"}}>
          <span className="tittle-card-precos-exames">Prejuízo</span>
          <span className="value-card-precos-exames">
            {somaQtdExamesPerdas} x {(somaPerdas).toLocaleString("pt-BR", {style: "currency", currency: "BRL"})}
          </span>
        </Paper>
        <Paper elevation={3} style={{backgroundColor: "#A9D0F5"}}>
          <span className="tittle-card-precos-exames">Lucro atual</span>
          <span className="value-card-precos-exames">
            {(somaGanho).toLocaleString("pt-BR", {style: "currency", currency: "BRL"})}
          </span>
        </Paper>
        <Paper elevation={3} style={{backgroundColor: "#F3E2A9"}}>
          <span className="tittle-card-precos-exames">Melhor Lucro</span>
          <span className="value-card-precos-exames">
            {(somaMelhorGanho).toLocaleString("pt-BR", {style: "currency", currency: "BRL"})}
          </span>
        </Paper>
        <Paper elevation={3} style={{backgroundColor: "#A9F5BC"}}>
          <span className="tittle-card-precos-exames">Aumento</span>
          <span className="value-card-precos-exames">
            {(somaAumento).toLocaleString("pt-BR", {style: "currency", currency: "BRL"})} x {(percentAumento).toLocaleString("pt-BR", {style: "percent"})}
          </span>
        </Paper>
      </StyledPaper>
    )
}
