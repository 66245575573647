import React, { useState, useRef, useEffect, useMemo, useContext } from "react";
import {
  Paper,
  Tooltip,
  MenuItem,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  TableRow,
  TableCell,
} from "@mui/material";
import { useSnackbar } from "notistack";
import {
  Delete,
  Add,
  Help,
  Today,
  Alarm,
  Settings,
  Edit,
  KeyboardBackspace,
} from "@mui/icons-material";
import { Container } from "@mui/system";
import dayjs from "dayjs";

import Header from "../../components/Header";
import Button from "../../components/Button";
import Tabela from "../../components/Tabela";
import Loading from "../../components/Loading";
import Dialog from "../../components/Dialog";
import Modal from "../../components/Modal";
import DataPickerAtualizado from "../../components/DataPickerAtualizado";

import { api } from "../../services/Api";

import useUser from "../../hooks/useUser";

import {
  validarNaoVazio,
  validarSomenteNumeros,
} from "../../utils/validations";
import salvaLog from "../../utils/SalvaLog";

import { Context } from "../../Context/PermissoesContext";

import ExamDialog from "./Dialog";
import { formatarHorario } from "./util";

import "./styles.css";
import { ModalAviso } from "../../componentsv2/ModalAviso";

export default function Espermograma() {
  // dados especificos do modulo
  const [data, setData] = useState(new Date());
  const [horarios, setHorarios] = useState([]);
  const [dialogValues, setDialogValues] = useState({
    data: new Date(),
    horario: "",
    tipo_exame: "",
    nome_cliente: "",
    contato: "",
  });
  const [exameSelecionado, setExameSelecionado] = useState(null);

  // tabela
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [exames, setExames] = useState([]);

  // interface
  const [showDialog, setShowDialog] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [showOptionsDialog, setShowOptionsDialog] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [loading, setLoading] = useState(true);

  const [openModalAlerta, setOpenModalAlerta] = useState(false);

  const buttonRef = useRef(null);
  const buttonWidth = useMemo(() => {
    if (buttonRef.current) {
      const { width } = buttonRef.current.getBoundingClientRect();
      return width;
    }

    return "auto";
  }, []);

  const { enqueueSnackbar } = useSnackbar();

  const usuario = useUser();

  const { checarPermissaoComAcao, checarPermissao } = useContext(Context);

  const [horariosFiltrados, setHorariosFiltrados] = useState([]);
  const listaNovosHorarios = [17, 18,19, 20, 21, 22, 23, 24, 25];

  useEffect(() => {
    const dataLimite = new Date("2024-12-01");

    if (dialogValues.data >= dataLimite) {
      const novosHorarios = horarios?.filter((horario) => {
        return listaNovosHorarios.some(
          (novoHorario) => novoHorario === horario.id
        );
      });

      setHorariosFiltrados(novosHorarios);
    } else {
      const horariosAntigos = horarios?.filter((horario) => {
        return !listaNovosHorarios.some(
          (novoHorario) => novoHorario === horario.id
        );
      });
      setHorariosFiltrados(horariosAntigos);
    }
    //setHorariosFiltrados
  }, [horarios, dialogValues.data]);

  useEffect(() => {
    setOpenModalAlerta(true);
  }, []);

  // recuperando exames para povoar tabela
  useEffect(() => {
    setLoading(true);
    (async () => {
      try {
        const { data: results } = await api.get("/v2/espermograma/exames", {
          params: { data },
        });
        setExames(results);
        setCount(results.length);
        setDialogValues((prevState) => ({
          ...prevState,
          data,
        }));
      } catch (e) {
        enqueueSnackbar(
          "Não foi possível recuperar os exames desta data. Por favor, tente novamente.",
          { variant: "error" }
        );
      }
    })();
    setLoading(false);
  }, [enqueueSnackbar, data]);

  // recuperando os horarios da data selecionada no dialog
  useEffect(() => {
    const dataExame = dialogValues.data;
    setLoading(true);
    (async () => {
      try {
        const { data: result } = await api.get("/v2/espermograma/horarios", {
          params: { data: dataExame },
        });
        setHorarios(result);
      } catch (e) {
        enqueueSnackbar(
          "Não foi possível recuperar dados sobre os horários da data escolhida. Por favor, tente novamente.",
          { variant: "error" }
        );
      }
      setLoading(false);
    })();
  }, [enqueueSnackbar, dialogValues.data]);

  const closeDialog = () => {
    setShowDialog(false);
    setDialogValues({
      data,
      horario: "",
      tipo_exame: "",
      nome_cliente: "",
      contato: "",
    });
    setExameSelecionado(null);
  };

  const openDialog = () => {
    setShowDialog(true);
  };

  const openDialogForEdit = () => {
    setShowOptionsDialog(false);
    setDialogValues({
      data,
      horario: exameSelecionado.id_horario,
      tipo_exame: exameSelecionado.tipo,
      nome_cliente: exameSelecionado.nome_cliente,
      contato: exameSelecionado.celular_cliente,
      id: exameSelecionado.id,
    });
    setShowDialog(true);
  };

  const openOptionsDialog = (exame) => {
    setExameSelecionado(exame);
    setShowOptionsDialog(true);
  };

  const atualizarCampoDialog = (campo, valor) => {
    setDialogValues((prevState) => ({
      ...prevState,
      [campo]: valor,
    }));
  };

  const validarHorarioExame = (idHorario) => {
    const target = horariosFiltrados?.find((h) => h.id === idHorario);

    // se estamos editando um exame e nao mudamos o horario, esta tudo bem.
    if (
      exameSelecionado !== null &&
      idHorario === exameSelecionado.id_horario
    ) {
      return true;
    }

    // nao pode estar desabilitado e tem que ter vagas
    return (
      !target.desabilitado &&
      target.lotacao_maxima - target.quantidade_exames > 0
    );
  };

  const validarTipoExame = (tipo) => {
    const horario = horariosFiltrados?.find(
      (h) => h.id === dialogValues.horario
    );

    if (tipo === "capacitacao") {
      return horario.capacitacao;
    }

    return true;
  };

  const validarDiaDaSemana = (dataExame) => {
    const diaDaSemana = new Date(dataExame).getDay();
    return diaDaSemana !== 0 && diaDaSemana !== 6;
  };

  const validarLotacaoCapacitacao = (tipo) => {
    const horario = horariosFiltrados?.find(
      (h) => h.id === dialogValues.horario
    );
    const vagasRestantes = horario.lotacao_maxima - horario.quantidade_exames;

    // se estamos editando um exame e seu tipo nao mudou, podemos continuar sem fazer esta checagem.
    if (exameSelecionado && exameSelecionado.tipo === tipo) {
      return true;
    }

    // estamos tentando editar um exame de capacitacao
    // para um exame de espermograma num horario que ja esta cheio.
    if (
      exameSelecionado &&
      tipo === "espermograma" &&
      vagasRestantes === 0 &&
      horario.capacitacao
    ) {
      return false;
    }

    // Se o horario permite capacitacao, entao uma vaga tem de ser
    // exclusiva para tal tipo de exame
    if (
      horario.capacitacao &&
      vagasRestantes === 1 &&
      tipo !== "capacitacao" &&
      !exames.find((e) => e.tipo === "capacitacao")
    ) {
      return false;
    }

    return true;
  };

  const validarExame = () => {
    const validationsPerField = {
      data: [
        {
          func: validarDiaDaSemana,
          message: "Exames não podem ser feitos nos fins de semana",
        },
      ],
      horario: [
        {
          func: (horario) => horario,
          message: "Escolha um horário para o exame",
        },
        {
          func: validarHorarioExame,
          message: "Horário escolhido já está cheio ou desabilitado",
        },
      ],
      tipo_exame: [
        { func: validarNaoVazio, message: "Escolha um tipo para o exame" },
        {
          func: validarTipoExame,
          message: "O exame de capacitação não pode ser feito neste horário",
        },
        {
          func: validarLotacaoCapacitacao,
          message:
            "A vaga restante neste horário é exclusiva para exames de capacitação",
        },
      ],
      nome_cliente: [
        {
          func: validarNaoVazio,
          message: "Nome do cliente não pode ser vazio",
        },
      ],
      contato: [
        { func: validarNaoVazio, message: "Forneça um contato do cliente" },
        {
          func: validarSomenteNumeros,
          message: "Contato deve conter somente números",
        },
      ],
    };

    ["horario", "tipo_exame", "nome_cliente", "contato", "data"].forEach(
      (field) => {
        validationsPerField[field].forEach((validation) => {
          if (!validation.func(dialogValues[field])) {
            throw new Error(validation.message);
          }
        });
      }
    );
  };

  const decrementarVagasHorario = (idHorario) => {
    const targetIndex = horariosFiltrados?.findIndex((h) => h.id === idHorario);
    const copy = [...horariosFiltrados];
    copy[targetIndex].quantidade_exames += 1;
    setHorariosFiltrados(copy);
  };

  const incrementarVagasHorario = (idHorario) => {
    const targetIndex = horariosFiltrados?.findIndex((h) => h.id === idHorario);
    const copy = [...horariosFiltrados];
    copy[targetIndex].quantidade_exames -= 1;
    setHorariosFiltrados(copy);
  };

  const compararDataExame = (exame) => {
    const [exameAno, exameMes, exameDia] = exame.data
      .split("-")
      .map((v) => parseInt(v, 10));

    const dia = new Date(data).getDate();
    const mes = new Date(data).getMonth() + 1;
    const ano = new Date(data).getFullYear();

    return dia === exameDia && mes === exameMes && ano === exameAno;
  };
  // operation pode ser "agendar", "editar" ou "deletar"
  const atualizarInterface = (operation, exame) => {
    const copy = [...exames];
    const targetIndex = copy.findIndex((e) => e.id === exame.id);
    switch (operation) {
      case "agendar":
        if (compararDataExame(exame)) {
          setExames([exame, ...copy]);
          decrementarVagasHorario(exame.id_horario);
        }
        break;
      case "editar":
        if (exame.id_horario !== copy[targetIndex].id_horario) {
          decrementarVagasHorario(exame.id_horario);
          incrementarVagasHorario(copy[targetIndex].id_horario);
        }
        if (!compararDataExame(exame)) {
          copy.splice(targetIndex, 1);
          setExames(copy);
        } else {
          copy[targetIndex] = { ...exame };
          setExames(copy);
        }

        break;
      default:
        copy.splice(targetIndex, 1);
        setExames(copy);
        incrementarVagasHorario(exame.id_horario);
        break;
    }
  };

  const agendarExame = async () => {
    setLoading(true);
    try {
      validarExame();
      const { data: novoExame } = await api.post("/v2/espermograma/exame", {
        data: dialogValues.data,
        id_horario: dialogValues.horario,
        tipo: dialogValues.tipo_exame,
        nome_cliente: dialogValues.nome_cliente,
        celular_cliente: dialogValues.contato,
        usuario,
      });
      salvaLog(`Agendou o exame ${novoExame.id}`, "Espermograma");
      enqueueSnackbar("Exame agendado!", { variant: "success" });
      closeDialog();
      atualizarInterface("agendar", novoExame);
      setShowInfoModal(true);
    } catch (e) {
      let mensagem;
      if (e.response && e.response.data && !e.response.data.mensagem) {
        mensagem = e.response.data;
      } else if (e.response) {
        mensagem = e.response.data.mensagem;
      } else {
        mensagem = e.message;
      }
      enqueueSnackbar(mensagem, { variant: "error", autoHideDuration: 3000 });
    }
    setLoading(false);
  };

  const editarExame = async () => {
    setLoading(true);
    try {
      validarExame();
      const { data: exameEditado } = await api.put(
        `/v2/espermograma/exame/${exameSelecionado.id}`,
        {
          data: new Date(dialogValues.data),
          id_horario: dialogValues.horario,
          tipo: dialogValues.tipo_exame,
          nome_cliente: dialogValues.nome_cliente,
          celular_cliente: dialogValues.contato,
          usuario,
        }
      );
      salvaLog(`Editou o exame ${exameEditado.id}`, "Espermograma");
      enqueueSnackbar("Exame editado!", { variant: "success" });
      closeDialog();
      atualizarInterface("editar", exameEditado);
    } catch (e) {
      let mensagem;
      if (e.response && e.response.data && !e.response.data.mensagem) {
        mensagem = e.response.data;
      } else if (e.response) {
        mensagem = e.response.data.mensagem;
      } else {
        mensagem = e.message;
      }

      enqueueSnackbar(mensagem, { variant: "error", autoHideDuration: 3000 });
    }
    setLoading(false);
  };

  const excluirExame = async () => {
    try {
      await api.delete(`/v2/espermograma/exame/${exameSelecionado.id}`);
      enqueueSnackbar("Exame excluído", { variant: "success" });
      salvaLog(`Excluiu o exame ${exameSelecionado.id}`, "Espermograma");
      setShowDeleteModal(false);
      closeDialog();
      setShowOptionsDialog(false);
      atualizarInterface("excluir", exameSelecionado);
    } catch (e) {
      let mensagem;
      if (e.response && e.response.data && e.response.data.mensagem) {
        mensagem = e.response.data.mensagem;
      } else {
        mensagem = e.message;
      }

      enqueueSnackbar(mensagem, { variant: "error" });
    }
  };

  const onDialogConfirm = async () => {
    if (exameSelecionado !== null) {
      if (
        checarPermissao("ESP004") || // permissao para editar qqlr exame
        // pode editar exames proprios e este eh um exame proprio
        (checarPermissao("ESP003") && exameSelecionado.usuario === usuario)
      ) {
        await editarExame();
      } else {
        enqueueSnackbar("Você não tem autorização para editar este exame", {
          variant: "error",
        });
      }
    } else {
      checarPermissaoComAcao("ESP002", async () => {
        await agendarExame();
      });
    }
  };

  return (
    <>
      {loading && <Loading />}
      <Modal
        title="Importante!"
        text="Lembre de orientar o cliente sobre os preparativos para o exame!"
        open={showInfoModal}
        onCloseModal={() => setShowInfoModal(false)}
        onConfirm={() => setShowInfoModal(false)}
        notShowCancel
        confirmButtonLabel="ENTENDIDO"
      />

      <Modal
        title="Excluir exame"
        text="Tem certeza de que deseja excluir este exame?"
        open={showDeleteModal}
        closeModal={() => setShowDeleteModal(false)}
        onCancel={() => {
          setShowDeleteModal(false);
        }}
        onConfirm={excluirExame}
      />
      <ExamDialog
        showDialog={showDialog}
        dialogValues={dialogValues}
        atualizarCampoDialog={atualizarCampoDialog}
        horarios={horariosFiltrados}
        closeDialog={closeDialog}
        onConfirm={onDialogConfirm}
        modoEdicao={exameSelecionado !== null}
      />
      <Dialog title="Opções" open={showOptionsDialog}>
        <List>
          <ListItem onClick={openDialogForEdit}>
            <ListItemIcon>
              <Edit />
            </ListItemIcon>
            <ListItemText>Editar</ListItemText>
          </ListItem>
          <ListItem
            onClick={() => {
              checarPermissaoComAcao("ESP005", () => {
                setShowDeleteModal(true);
              });
            }}
          >
            <ListItemIcon>
              <Delete />
            </ListItemIcon>
            <ListItemText>Excluir</ListItemText>
          </ListItem>
          <ListItem
            onClick={() => {
              setShowOptionsDialog(false);
              setExameSelecionado(null);
            }}
          >
            <ListItemIcon>
              <KeyboardBackspace />
            </ListItemIcon>
            <ListItemText>Voltar</ListItemText>
          </ListItem>
        </List>
      </Dialog>
      <Header titulo="Espermograma" />
      <Container>
        <Menu
          open={showMenu}
          anchorEl={buttonRef.current}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          transformOrigin={{ vertical: "top", horizontal: "left" }}
          onClose={() => setShowMenu(false)}
        >
          <MenuItem
            style={{
              width: buttonWidth,
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            Horário{" "}
            <Alarm style={{ color: "rgba(0,0,0,0.6)", marginLeft: ".75rem" }} />
          </MenuItem>
          <MenuItem
            style={{
              width: buttonWidth,
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            Dia{" "}
            <Today style={{ color: "rgba(0,0,0,0.6)", marginLeft: ".75rem" }} />
          </MenuItem>
        </Menu>
        <main className="espermograma">
          <ModalAviso
            modalText={
              "A partir de 01/11/2023 não faz mais esse exame pela Unimed. Favor não realizar agendamento para a UNIMED."
            }
            open={openModalAlerta}
            onClose={setOpenModalAlerta}
          />
          <Paper className="espermograma-top-paper">
            <DataPickerAtualizado
              data={dayjs(data)}
              setData={setData}
              variant="standard"
              labelName="Data"
            />
            {/* <DatePicker
              label="Data"
              format="dd/MM/yyyy"
              value={dayjs(data)}
              onChange={setData}
            /> */}
            <div className="espermograma-btns">
              {/* }
                <Button onClick={() => setShowMenu(true)} ref={buttonRef}>
                  DESABILITAR <BlockIcon style={{ marginLeft: ".75rem" }} />
                </Button>
              { */}
              <Button onClick={openDialog}>
                AGENDAR <Add style={{ marginLeft: ".75rem" }} />
              </Button>
            </div>
          </Paper>

          <Tooltip title="Informações sobre o processo">
            <div className="espermograma-help">
              <Help
                style={{ color: "white" }}
                onClick={() => {
                  window.open(
                    `${process.env.REACT_APP_BASEURL_WEB}/static/v2/public/espermograma.pdf`
                  );
                }}
              />
            </div>
          </Tooltip>

          <Tabela
            page={page}
            count={count}
            setPage={setPage}
            header={[
              "Exame",
              "Hora",
              "Nome do cliente",
              "Contato",
              "Usuário",
              "Opções",
            ]}
            rowsPerPage={40}
          >
            {exames
              .sort((a, b) => {
                if (a.horario.hora < b.horario.hora) {
                  return -1;
                }
                if (a.horario.hora > b.horario.hora) {
                  return 1;
                }
                if (a.horario.minutos < b.horario.minutos) {
                  return -1;
                }
                if (a.horario.minutos > b.horario.minutos) {
                  return 1;
                }

                return 0;
              })
              .map((e) => (
                <TableRow key={e.id}>
                  <TableCell align="center">
                    {e.tipo === "espermograma" ? "Espermograma" : "Capacitação"}
                  </TableCell>
                  <TableCell align="center">
                    {formatarHorario(e.horario)}
                  </TableCell>
                  <TableCell align="center">{e.nome_cliente}</TableCell>
                  <TableCell align="center">{e.celular_cliente}</TableCell>
                  <TableCell align="center">{e.usuario}</TableCell>
                  <TableCell align="center">
                    <Settings
                      style={{ cursor: "pointer" }}
                      onClick={() => openOptionsDialog(e)}
                    />
                  </TableCell>
                </TableRow>
              ))}
          </Tabela>
        </main>
      </Container>
    </>
  );
}
