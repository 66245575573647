import { Modal as MuiModal } from "@mui/material";
import React from "react";

export default function ModalSettings({ children, open, onClose, ...rest }) {
  return (
    <MuiModal open={open} onClose={() => onClose(false)} {...rest}>
      {children}
    </MuiModal>
  );
}
