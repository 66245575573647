import { Box, Grid, Paper } from "@mui/material";

import TabelaComponent from "../../../../componentsv2/Tabela";
import { useSnackbar } from "notistack";
import React, { useContext, useEffect, useState } from "react";
import { api } from "../../../../services/Api";
import Opcoes from "./Opcoes";
import { colorTheme } from "../../../../theme";
import useDadosContextEstoque from "../../hooks/useDadosContextEstoque";

function agruparProdutosPorGeladeira(estoques) {
  const resultado = {};

  estoques.forEach((item) => {
    const geladeiraId = item.estoque_id;
    const produtoId = item.produto_id;
    const quantidade = item.quantidade;

    // Se a geladeira não existe no resultado, cria um novo objeto para ela
    if (!resultado[geladeiraId]) {
      resultado[geladeiraId] = {
        nomeEstoque: item.estoque.nome,
        produtos: {},
        nomeLocal: item.estoque.unidade.nome,
        idUnidade: item.estoque.unidade.id,
        idEstoque: item.estoque.id,
      };
    }

    // Se o produto não existe na geladeira, cria um novo objeto para ele
    if (!resultado[geladeiraId].produtos[produtoId]) {
      resultado[geladeiraId].produtos[produtoId] = {
        ...item.produto,
        id: item.id,
        quantidade: 0,
        nomeEstoque: item.estoque.nome,
        nomeLocal: item.estoque.unidade.nome,
        idUnidade: item.estoque.unidade.id,
        idEstoque: item.estoque.id,
      };
    }

    // Adiciona a quantidade ao produto na geladeira
    resultado[geladeiraId].produtos[produtoId].quantidade += quantidade;
  });

  // Converte o objeto resultado em um array de produtos
  return Object.values(resultado).flatMap((geladeira) =>
    Object.values(geladeira.produtos)
  );
}

export default function Tabela() {
  const { enqueueSnackbar } = useSnackbar();
  const [linhasFiltradas, setLinhasFiltradas] = useState([]);

  const [rows, setRows] = useState([]);

  const {
    localSelecionado,
    situacaoSelecionada,
    dataInicial,
    dataFinal,
    update,
    setUpdate,
    estoqueAtual,
  } = useDadosContextEstoque();

  useEffect(() => {
    const resultado = agruparProdutosPorGeladeira(rows);

    const dadosFiltrados = resultado?.filter((el) => {
      const localMatch =
        localSelecionado === "" ||
        el?.idUnidade
          ?.toString()
          ?.toLowerCase()
          ?.includes(localSelecionado?.toString().toLowerCase());

      const situacaoMatch =
        estoqueAtual.id === "" || el.idEstoque.includes(estoqueAtual.id);

      return localMatch && situacaoMatch;
    });

    setLinhasFiltradas(dadosFiltrados);
  }, [rows, localSelecionado, situacaoSelecionada, estoqueAtual]);

  useEffect(() => {
    async function obterDados() {
      try {
        const resultTodosProdutos = await api.get(
          "/v2/almoxarifado/todosprodutos",
          {
            params: { dataInicial, dataFinal },
          }
        );
        setUpdate(false);

        setRows(resultTodosProdutos.data.produtos);
      } catch (e) {
        enqueueSnackbar("falha ao obter dados", {
          variant: "error",
        });
      }
    }

    obterDados();
  }, [enqueueSnackbar, dataInicial, dataFinal, update, setUpdate]);

  const columns = [
    {
      field: "id",
      headername: "id",
      headerAlign: "center",
    },
    {
      field: "codigo_produto",
      headerName: "Cód.",
      flex: 1,
      headerAlign: "center",
      align: "center",
      type: "number",
    },
    {
      field: "nome",
      headerName: "Nome",
      headerAlign: "center",
      flex: 1,
      align: "center",
      type: "string",
    },

    {
      field: "nomeLocal",
      headerName: "Local",
      flex: 1,
      headerAlign: "center",
      align: "center",
      type: "string",
    },
    {
      field: "nomeEstoque",
      headerName: "Estoque",
      flex: 1,
      headerAlign: "center",
      align: "center",
      type: "string",
    },
    {
      field: "quantidade",
      headerName: "Quantidade",
      width: 130,
      headerAlign: "center",
      align: "center",
      type: "number",
    },

    {
      field: "opções",
      headerName: "Opções",
      width: 100,
      headerAlign: "center",
      type: "number",
      editable: false,
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (params) => (
        <div style={{ cursor: "pointer" }}>
          <Opcoes row={params.row} />
        </div>
      ),
    },
  ];

  return (
    <Grid spacing={2} container>
      <Grid item xs={12} sm={12}>
        <Paper elevation={2}>
          <Box
            sx={{
              height: 600,
              width: "100%",
              "& .critico": { color: colorTheme.danger[500] },
              "& .alerta": { color: colorTheme.warning[600] },
            }}
          >
            <TabelaComponent
              rows={linhasFiltradas}
              columns={columns}
              disableColumnMenu
              linhaNaoSelecionavel={false}
              autoPageSize
              getCellClassName={(params) => {
                if (params.field === "validade") {
                  return params.row.situacao;
                }
              }}
              initialState={{
                columns: {
                  columnVisibilityModel: {
                    id: false,
                  },
                },
              }}
            />
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );
}
