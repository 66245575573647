import React from 'react';

import "./styles.css"

export default function InputClienteDrive(props){

    return (
        <>
            <label className="label-client-drive-basic">{props.text}</label>
            <input 
                className={`input-basic-client-drive`}
                placeholder={props.text}
                type={props.type}
                value={props.value}
                onChange={(e)=>{ 
                    e.preventDefault();
                    props.acao((e.target.value).toUpperCase());
                }}
            />
        </>
        )
}