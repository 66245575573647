import React from "react";

import SelectSituacao from "../../../../Components/SelectSituacao";
import SelectEnfermeiro from "../../../../Components/SelectEnfermeiro";
import Button from "../../../../../../componentsv2/Button";
import Paper from "../../../../../../componentsv2/Paper";
import GridContainer from "../../../../../../componentsv2/GridContainer";
import GridItem from "../../../../../../componentsv2/GridItem";
import DataPickerAtualizado from "../../../../../../components/DataPickerAtualizado";

export default function Cabecalho(props) {
  const {
    onClick,
    situacao,
    setSituacao,
    data,
    setData,
    enfermeiroId,
    setEnfermeiroId,
  } = props;

  return (
    <Paper>
      <GridContainer sx={{ display: "flex" }}>
        <GridContainer sx={{ paddingLeft: 2 }} xs={12} md={8} spacing={2}>
          <GridItem md={3} xs={12}>
            <DataPickerAtualizado
              data={data}
              setData={setData}
              variant="standard"
              labelName="Data"
            />
          </GridItem>

          <GridItem md={3} xs={12}>
            <SelectSituacao
              onChange={(e) => {
                setSituacao(e.target.value);
              }}
              value={situacao}
            />
          </GridItem>

          <GridItem md={3} xs={12}>
            <SelectEnfermeiro
              todos={true}
              onChange={(e) => {
                setEnfermeiroId(e.target.value);
              }}
              value={enfermeiroId}
            />
          </GridItem>
        </GridContainer>

        <GridItem className="paper-categoria" md={2} xs={12}>
          <Button variant="contained" fullWidth onClick={onClick}>
            Adicionar
          </Button>
        </GridItem>
      </GridContainer>
    </Paper>
  );
}
