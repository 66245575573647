import React, { useState, useContext } from "react";
import {
  Button as MuiButton,
  Container,
  Grid,
  Modal as MuiModal,
  Paper,
} from "@mui/material";
import SettingsIcon from '@mui/icons-material/Settings';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Button from "../../../../componentsv2/Button";
import { api } from "../../../../services/Api";
import { useSnackbar } from "notistack";
import { Modal } from "../../../../componentsv2/Modal";
import { Context as PermissoesContext } from "../../../../Context/PermissoesContext";

export default function OpcoesProprietario({
  row,
  setId,
  setProprietarioModal,
  setUpdate,
  update,
}) {
  const [open, setOpen] = useState(false);
  const [openModalConfirmacao, setOpenModalConfirmacao] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { checarPermissao } = useContext(PermissoesContext);

  function ModalOption(props) {
    const handleDelete = async () => {
      if (props.row.id) {
        try {
          await api.delete(`/v2/inventario/proprietario/${props.row.id}`);
          setOpen(false);
          setUpdate(!update);
          enqueueSnackbar("Proprietário excluído com sucesso!", {
            variant: "success",
            autoHideDuration: 3000,
          });
        } catch (error) {
          enqueueSnackbar("Erro ao tentar excluir o proprietário!", {
            variant: "error",
            autoHideDuration: 3000,
          });
        }
      }
    };

    const handleClickEdit = () => {
      if (checarPermissao("INV008")) {
        setId(props.row.id);
        setProprietarioModal(true);
      } else {
        enqueueSnackbar("Você não possui permissão para editar um proprietário!", {
          variant: "error",
          autoHideDuration: 3000,
        });
      }
    };
    return (
      <Container className="options-container" maxWidth="xs">
        <Paper className="options-paper" elevation={2}>
          <Grid container style={{ padding: "1rem 1rem" }}>
            <Grid item sm={12}>
              <Button
                onClick={handleClickEdit}
                variant="standard"
                startIcon={<EditIcon />}
              >
                Editar
              </Button>
            </Grid>
            <Grid item sm={12}>
              <Button
                onClick={() => {
                  if (checarPermissao("INV009")) {
                    setOpenModalConfirmacao(true);
                  } else {
                    enqueueSnackbar(
                      "Você não possui permissão para excluir um tipo de material!",
                      {
                        variant: "error",
                        autoHideDuration: 3000,
                      }
                    );
                  }
                }}
                variant="standard"
                startIcon={<DeleteIcon />}
              >
                Excluir
              </Button>
              <Modal
                open={openModalConfirmacao}
                confirmActionButtonText="Remover"
                onClick={handleDelete}
                confirmActionButtonColor="red"
                onClose={setOpenModalConfirmacao}
                modalText="Tem certeza que deseja remover este proprietário?"
              />
            </Grid>
            <Grid item sm={12}>
              <Button
                onClick={() => setOpen(false)}
                variant="standard"
                startIcon={<ArrowBackIcon />}
              >
                Voltar
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Container>
    );
  }

  return (
    <>
      <MuiButton style={{ color: "#000" }} onClick={() => setOpen(true)}>
        <SettingsIcon />
      </MuiButton>
      <MuiModal open={open} onClose={() => setOpen(false)}>
        <ModalOption row={row} />
      </MuiModal>
    </>
  );
}
