import React, { useEffect, useState } from "react";
import {
  FormControl,
  MenuItem,
  Select } from '@mui/material';

import Waiter from "../../../components/Backdrop";

import { api } from "../../../services/Api";

export default function SelectResultado(props) {
  const { nomeMapa, vetorResultado: vetor } = props;

  const resultadoAtual = vetor.resultado;
  const [ resultado, setResultado ] = useState(resultadoAtual);

  const [espera, setEspera] = useState(false);

  useEffect(()=>{
    setResultado(resultadoAtual);
  }, [resultadoAtual])

  async function mudaResultado(novoResultado) {
    try {
      setEspera(true);
      let vetorResultado = vetor;
      vetorResultado = {...vetorResultado, "resultado": novoResultado}

      await api.put(`/api/amostras/salvaResultado`, { nomeMapa, vetorResultado });

      setResultado(novoResultado);

    } catch (error) {
      console.log(error);
    }
    setEspera(false);
  }

  function verificaCor() {
    if (resultado === "Indeterminado") {
      return "#F8BA58"
    }
    if (resultado === "Positivo") {
      return "#FA9A9A"
    }
    return "#A9F5A9"
  }

  return (
    <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
      <Select
        id="select-result-pcr-map"
        value={resultado}
        onChange={(e) => mudaResultado(e.target.value)}
        label="Situação"
        style={
          { textAlign: "center",
            fontSize: "10px",
            fontWeight: "bold",
            width: "4rem",
            height: "1.25rem",
            margin: "2px 0",
            paddingLeft: "0.5rem",
            backgroundColor: verificaCor()
          }
        }
      >
        <MenuItem value="Negativo">NEGATIVO</MenuItem>
        <MenuItem value="Positivo">POSITIVO</MenuItem>
        <MenuItem value="Indeterminado">INDETERMINADO</MenuItem>
      </Select>
      <Waiter espera={espera} />
    </FormControl>
  )
}
