import React, { useState } from "react";
import { Button, TableContainer, IconButton, Paper, Snackbar } from '@mui/material';
import { ArrowForward, Cancel } from '@mui/icons-material';

import TabelaComponenetv2 from "../../../componentsv2/Tabela";

import formataNumeracaoAmostra from "../utils/formataNumecaoAmostra";

import "./styles.css";

export default function Tabela(props) {
  const {
    tabela,
    placa,
    status,
    statusFuturo,
    setAmostra,
    setOpcoes,
    atualizaStatusPlaca,
    adicionaHistorico,
  } = props;

  const [listaPlaca, setListaPlaca] = useState([]);
  const [mostraSnackbar, setMostraSnackbar] = useState(false);

  async function avancaPlacas() {
    await atualizaStatusPlaca(listaPlaca, statusFuturo);
    const historico = listaPlaca.map(async l =>
      adicionaHistorico(l, `Avançou a placa para ${statusFuturo}`)
    );
    await Promise.all(historico);
  }

  const Config = ({ row }) => {
    const handleEditClick = () => {
      setAmostra(row);
      setOpcoes(true);
    };

    return (
      <IconButton onClick={handleEditClick}>
        <i className="fa fa-cog" style={{ color: "#003399" }} />
      </IconButton>
    );
  };

  const colunas = [
    {
      field: "id",
      headerName: "ID",
      headerAlign: "center",
      width: 90,
    },
    {
      field: "data_hora",
      headerName: "Data/Hora",
      headerAlign: "center",
      width: 160,
      editable: false,
    },
    {
      field: "nome_mapa",
      headerName: "Mapa",
      headerAlign: "center",
      width: 160,
      editable: false,
      hide: tabela !== "mapa",
    },
    {
      field: "menor_numero",
      headerName: "Amostra Inicial",
      headerAlign: "center",
      align: "center",
      flex: 1,
      editable: false,
      valueFormatter: ({ value }) => (value ? `${formataNumeracaoAmostra(value)}` : "-"),
    },
    {
      field: "maior_numero",
      headerName: "Amostra Final",
      headerAlign: "center",
      align: "center",
      flex: 1,
      editable: false,
      valueFormatter: ({ value }) => (value ? `${formataNumeracaoAmostra(value)}` : "-"),
    },
    {
      field: "qtd_pedido",
      headerName: "Qtd. Amostras",
      headerAlign: "center",
      align: "center",
      flex: 1,
      editable: false,
    },
    {
      field: "opcoes",
      headerName: "Opções",
      headerAlign: "center",
      align: "center",
      width: 110,
      editable: false,
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: params => (
        <div style={{ display: "flex", justifyContent: "center", cursor: "pointer" }}>
          <Config row={params.row} />
        </div>
      ),
    },
  ];

  return (
    <TableContainer component={Paper}>
      <TabelaComponenetv2
        columns={colunas}
        rows={placa}
        checkboxSelection={
          tabela === "placa" && status !== "PCR" && status !== "Processado"
        }
        autoHeight
        disableColumnFilter
        disableColumnMenu
        disableSelectionOnClick
        pageSize={100}
        rowsPerPageOptions={[50]}
        selectionModel={listaPlaca}
        onRowSelectionModelChange={model => {
          if (model.length === 0) {
            setMostraSnackbar(false);
          } else if (tabela === "placa") {
            setMostraSnackbar(true);
          }
          setListaPlaca(model);
        }}
      />
      <Snackbar
        open={mostraSnackbar}
        message={`${listaPlaca.length} ${
          listaPlaca.length > 1 ? `itens` : `item`
        } selecionado${listaPlaca.length > 1 ? "s" : ""}`}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        action={(
          <div>
            <Button
              onClick={() => {
                setMostraSnackbar(false);
                avancaPlacas();
              }}
              startIcon={<ArrowForward />}
              style={{ color: "#FFF" }}
            >
              Avançar
            </Button>

            <Button
              onClick={() => {
                setMostraSnackbar(false);
                setListaPlaca([]);
              }}
              startIcon={<Cancel />}
              style={{ color: "#FFF" }}
            >
              Cancelar
            </Button>
          </div>
        )}
      />
    </TableContainer>
  );
}
