import React from "react";
import { Grid } from "@mui/material";
import { Select } from "../../../../componentsv2/Select";

export default function SelectSituacao({
  onChange,
  value,
  disabled = false,
  required = false,
  todas = true,
}) {
  const options = [
    // { value: "Coletado", text: "Coletado" },
    { value: "Pendente", text: "Pendente" },
    { value: "Realizado", text: "Realizado" },
    { value: "Não Realizado", text: "Não Realizado" },
  ];
  todas && options.unshift({ value: "", text: "Todas" });
  return (
    <>
      <Grid container spacing={1} justify="center" alignItems="stretch">
        <Grid item xs={12}>
          <Select
            textAttribute="text"
            valueAttribute="value"
            required={required}
            disabled={disabled}
            onChange={onChange}
            value={value}
            label="Situação"
            variant="standard"
            arrayOptions={options}
          />
        </Grid>
      </Grid>
    </>
  );
}
