import { Grid, TextField } from '@mui/material';
import { Button } from "@mui/material";
import React, { useState } from "react";
import { useContext } from "react";
import useUser from "../../../hooks/useUser";
import dadosContext from "../DadosContext";

export default function SenhaPerfil() {
  const usuario = useUser();
  const { senha, setSenha, alterarSenha, setAlterarSenha } = useContext(dadosContext);
  const [confirmSenha, setConfirmSenha] = useState(false);
  const [helper, setHelper] = useState("");

  const validacaoSenha = value => {
    if (value !== senha) {
      setConfirmSenha(true);
      setHelper("Senhas diferentes");
    } else {
      setConfirmSenha(false);
      setHelper("");
    }
  };
  return (
    <Grid container style={{ padding: "1.5rem 1.5rem" }} spacing={2}>
      <Grid item xs={12}>
        <h3>Dados de acesso</h3>
      </Grid>
      <Grid item xs={12} sm={12}>
        <TextField
          className="input-user"
          value={usuario}
          size="small"
          label="Usuário"
          variant="outlined"
          fullWidth
          disabled={true}
        />
      </Grid>
      {!alterarSenha ? (
        <Grid item xs={12} sm={4}>
          <Button
            onClick={() => setAlterarSenha(!alterarSenha)}
            variant="outlined"
            fullWidth
          >
            Alterar senha
          </Button>
        </Grid>
      ) : (
        <>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              type="password"
              size="small"
              label="Senha"
              variant="outlined"
              onChange={e => setSenha(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              type="password"
              size="small"
              label="Repetir senha"
              variant="outlined"
              onChange={event => validacaoSenha(event.target.value)}
              error={confirmSenha}
              helperText={helper}
            />
          </Grid>
        </>
      )}
    </Grid>
  );
}
