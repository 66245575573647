import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import { FormControl, Paper, InputLabel, Grid } from '@mui/material';
import { MenuItem, Select } from "@mui/material";
import Waiter from "../../components/Backdrop";

import { api, baseUrl } from "../../services/Api";

import { Context as PermissoesContext } from "../../Context/PermissoesContext";

import "./styles.css";
import Header from "../../components/Header";
import Container from "../../componentsv2/Container";

export default function ConexaoDNA() {
  const history = useHistory();

  const { enqueueSnackbar } = useSnackbar();
  const [aguarda, setAguarda] = useState(false);

  const url = `${baseUrl}/static/v2/private/conexao_dna`;
  const [unidade, setUnidade] = useState("0");
  const [imagens, setImagens] = useState([]);
  const [imagem, setImagem] = useState(false);
  const [referencia, setReferencia] = useState("");
  const [referencias, setReferencias] = useState([]);
  const { checarPermissao } = useContext(PermissoesContext);

  if (!checarPermissao("CON001")) {
    history.push("/home");
  }

  useEffect(() => {
    (async () => {
      setAguarda(true);
      try {
        const { data: results } = await api.get(`/v2/conexaodna/referencia`);
        if (results) {
          setReferencias(results);
          setReferencia(results[0]);
        }
      } catch (e) {
        enqueueSnackbar("Erro ao definir referências. Por favor, tente novamente.", {
          variant: "error",
        });
      }
      setAguarda(false);
    })();
  }, [enqueueSnackbar]);

  useEffect(() => {
    (async () => {
      setAguarda(true);
      try {
        if (referencia) {
          const { data: results } = await api.get(`/v2/conexaodna/imagens/${referencia}`);
          if (results) {
            setImagens(results);
            const unidadePadrao = results.filter(i => {
              const [nome] = i.split(".");
              if (nome === "DNA CENTER") {
                return true;
              }
              return false;
            });

            if (unidadePadrao.length > 0) {
              setUnidade(unidadePadrao);
              setImagem(
                `${url}/${referencia}/${unidadePadrao}?token=${api.defaults.headers.Authorization}`
              );
            } else {
              setUnidade("0");
              setImagem(false);
            }
          } else {
            enqueueSnackbar("Nenhuma unidade encontrada.", { variant: "info" });
          }
        }
      } catch (e) {
        enqueueSnackbar("Erro ao definir unidades. Por favor, tente novamente.", {
          variant: "error",
        });
      }
      setAguarda(false);
    })();
  }, [enqueueSnackbar, url, referencia]);

  const handleChangeUnity = async event => {
    const unidadeAtual = event.target.value;
    setUnidade(unidadeAtual);
    if (unidadeAtual !== "0") {
      setImagem(
        `${url}/${referencia}/${unidadeAtual}?token=${api.defaults.headers.Authorization}`
      );
    } else {
      setImagem(false);
    }
  };

  const handleChangeReference = async event => {
    const referenciaAtual = event.target.value;
    setReferencia(referenciaAtual);
  };

  return (
    <>
      <Header titulo="Conexão DNA"/>
      <Container>
        <div className="div-container-DOM">
          <Grid item xs={3} style={{ marginRight: "0.5rem", minWidth: "7rem" }}>
            <InputLabel>Referência</InputLabel>
            <FormControl defaultValue=" " fullWidth>
              <Select value={referencia} onChange={handleChangeReference}>
                {referencias.map(ref => {
                  const ref_convertido = `${ref.slice(5, 7)}/${ref.slice(0, 4)}`;
                  return (
                    <MenuItem key={ref} value={ref}>
                      {ref_convertido}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={8}>
            <InputLabel>Unidade</InputLabel>
            <FormControl defaultValue=" " fullWidth>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={unidade}
                onChange={handleChangeUnity}
              >
                <MenuItem key="001" value="0">
                  Selecione uma unidade
                </MenuItem>
                {imagens.map(jpg => {
                  const [nomeUnidade] = jpg.split(".");
                  return (
                    <MenuItem key={jpg} value={jpg}>
                      {nomeUnidade}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
        </div>

        {imagem && (
          <Grid item xs={12}>
            <Paper>
              <div className="div-container-DOM">
                <img src={imagem} alt="conexaoIMG" />
              </div>
            </Paper>
          </Grid>
        )}
        <Waiter espera={aguarda} />
      </Container>
    </>
  );
}
