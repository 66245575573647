import React, { useContext, useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { format } from "date-fns";
import {
  Button,
  Dialog,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
} from "@mui/material";

import useUser from "../../../hooks/useUser";
import Waiter from "../../../components/Backdrop";
import converteData from "../../../utils/converteData";

import { Context as PermissoesContext } from "../../../Context/PermissoesContext";
import { FormularioContext } from "../Context/FormularioContext";

import { api } from "../../../services/Api";

import "./styles.css";

export default function Agendamento(props) {
  const { enqueueSnackbar } = useSnackbar();
  const usuario = useUser();

  const { checarPermissaoComAcao } = useContext(PermissoesContext);

  const { agenda, unidade, exames, horarios, acesso } = props;

  const {
    dia,
    setDia,
    mes,
    ano,
    titulo,
    setTitulo,
    abreAgendamento,
    setAbreAgendamento,
    dadosAgendamento,
    setDadosAgendamento,
    setAtualizaTabela,
    horaSelecionada,
    setHoraSelecionada,
    setUnidade,
    setAgenda,
  } = useContext(FormularioContext);

  const checaDiaEHorarioAgendaGH = () => {
    const hoje = new Date();
    if (hoje.getDay() === 4) {
      return false;
    }
    if (
      tipo &&
      tipo === "Curva de GH com estímulo de Clonidina" &&
      hoje.getHours() >= 16
    ) {
      if (dia - hoje.getDate() === 1) {
        return true;
      } else {
        if (hoje.getDate() === 31 && dia === 1) {
          return true;
        }
      }
    }
    return false;
  };

  useEffect(() => {
    (() => {
      setUnidade(unidade);
      setAgenda(agenda);
    })();
  }, [setAgenda, setUnidade, unidade, agenda]);

  const [id, setId] = useState("");
  const [data, setData] = useState("");
  const [hora, setHora] = useState(horaSelecionada || horarios[0]);
  const [tipo, setTipo] = useState(exames[0]);
  const [nome, setNome] = useState("");
  const [telefone, setTelefone] = useState("");
  const [telefone2, setTelefone2] = useState("");

  const [agendadoPor, setAgendadoPor] = useState("");
  const [editadoPor, setEditadoPor] = useState("");

  const [confirmaExclusao, setConfirmaExclusao] = useState(false);
  const [espera, setEspera] = useState(false);

  useEffect(() => {
    if (dadosAgendamento && abreAgendamento) {
      setId(dadosAgendamento.id);
      setData(dadosAgendamento.data);
      setHora(dadosAgendamento.hora || horaSelecionada || horarios[0]);
      setTipo(dadosAgendamento.tipo || exames[0]);
      setNome(dadosAgendamento.nome);
      setTelefone(dadosAgendamento.telefone);
      setTelefone2(dadosAgendamento.telefone2);
      setAgendadoPor(dadosAgendamento.atore?.agendadoPor);
      setEditadoPor(dadosAgendamento.atore?.atualizadoPor);
      console.log("dadosAgendamento", dadosAgendamento);
    }
    // eslint-disable-next-line
  }, [dadosAgendamento, abreAgendamento]);

  useEffect(() => {
    (() => {
      if (dadosAgendamento.length < 1 && abreAgendamento) {
        if (dia) {
          return setData(
            `${ano}-${String(mes + 1).padStart(2, "0")}-${String(dia).padStart(
              2,
              "0"
            )}`
          );
        }
        return setData(format(new Date(), "yyyy-MM-dd"));
      }
      return dia;
    })();
  }, [dadosAgendamento, dia, mes, ano, abreAgendamento]);

  function zeraAgendamento() {
    setId("");
    setNome("");
    setTelefone("");
    setTelefone2("");
    setTipo(exames[0]);
    setHora(horarios[0]);
    setAgendadoPor("");
    setEditadoPor("");
    setDadosAgendamento([]);
    setHoraSelecionada("");
  }

  function fechaAgendamento() {
    setAbreAgendamento(false);
    zeraAgendamento();
    setDia("");
  }

  async function fazAgendamento() {
    try {
      if (checaDiaEHorarioAgendaGH()) {
        enqueueSnackbar(
          "Não é permitido fazer agendamento nesse horário para dia seguinte!",
          {
            variant: "warning",
          }
        );
        return;
      }

      if (nome) {
        if (telefone) {
          setEspera(true);
          await api.post("/v2/agendaRespiratorio/agendamentos", {
            id_unidade: unidade,
            nome,
            telefone,
            telefone2,
            tipo,
            hora,
            data,
            agenda,
            usuario,
          });

          setAtualizaTabela((prevState) => !prevState);

          setEspera(false);
          fechaAgendamento();
          enqueueSnackbar("Agendamento realizado com sucesso", {
            variant: "success",
          });
        } else {
          document
            .getElementById("telefone-cliente-agendamento-respiratorio")
            .focus();
          enqueueSnackbar("Adicione um telefone ao agendamento", {
            variant: "warning",
          });
        }
      } else {
        document
          .getElementById("nome-cliente-agendamento-respiratorio")
          .focus();
        enqueueSnackbar("Adicione um nome ao agendamento", {
          variant: "warning",
        });
      }
    } catch (error) {
      enqueueSnackbar("Falha na operação", { variant: "error" });
      // setMsg(error.response.data.error);
    }
    setEspera(false);
  }

  async function editaAgendamento() {
    try {
      setEspera(true);
      await api.put("/v2/agendaRespiratorio/agendamentos", {
        id,
        nome,
        telefone,
        telefone2,
        tipo,
        hora,
        agenda,
        usuario,
      });

      setAtualizaTabela((prevState) => !prevState);
      setEditadoPor(usuario);

      setEspera(false);
      enqueueSnackbar("Agendamento editado com sucesso", {
        variant: "success",
      });
    } catch (error) {
      enqueueSnackbar("Falha na operação", { variant: "error" });
      // setMsg(error.response.data.error);
    }
    setEspera(false);
  }

  async function excluiAgendamento() {
    try {
      setEspera(true);
      await api.delete(`/v2/agendaRespiratorio/agendamentos/${usuario}/${id}`);

      setAtualizaTabela((prevState) => !prevState);

      setEspera(false);
      setConfirmaExclusao(false);
      fechaAgendamento();
      enqueueSnackbar("Agendamento excluido com sucesso", {
        variant: "success",
      });
    } catch (error) {
      enqueueSnackbar("Falha na operação", { variant: "error" });
      // setMsg(error.response.data.error);
    }
    setEspera(false);
  }

  function cancelaAgendamento() {
    fechaAgendamento();
  }

  function selecionaAcao(n) {
    if (n === 1) {
      switch (titulo) {
        case "Informações do agendamento":
          checarPermissaoComAcao(`${acesso}003`, () => {
            document
              .getElementById("data-cliente-agendamento-respiratorio")
              .focus();
            setTitulo("Editando agendamento");
          });
          break;
        case "Editando agendamento":
          editaAgendamento();
          setTitulo("Informações do agendamento");
          break;
        default:
          fazAgendamento();
      }
    } else if (n === 2) {
      switch (titulo) {
        case "Informações do agendamento":
          checarPermissaoComAcao(`${acesso}004`, () => {
            setConfirmaExclusao(true);
          });
          break;
        case "Editando agendamento":
          setTitulo("Informações do agendamento");
          break;
        default:
          cancelaAgendamento();
      }
    } else if (titulo === "Editando agendamento") {
      setTitulo("Informações do agendamento");
    } else {
      cancelaAgendamento();
    }
  }

  return (
    <>
      <Dialog
        fullWidth
        maxWidth="md"
        open={abreAgendamento}
        onClose={() => selecionaAcao(3)}
        closeAfterTransition
      >
        <Paper
          sx={{ display: "flex", flexDirection: "column", padding: "1.0rem" }}
        >
          <div id="container-titulo-formulario-agendamento">
            <DialogTitle
              sx={{ display: "flex", width: "90%", justifyContent: "center" }}
            >
              {titulo}
            </DialogTitle>
            <IconButton
              sx={{ height: "2rem", alignSelf: "center" }}
              onClick={() => selecionaAcao(3)}
            >
              <i className="fa fa-close" />
            </IconButton>
          </div>
          <Paper
            sx={{
              display: "flex",
              width: "100%",
              flexDirection: "column",
              padding: "1rem",
              marginBottom: "0.5rem",
            }}
            elevation={3}
          >
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="flex-start"
              spacing={2}
            >
              <Grid item xs={7} sm={3}>
                <TextField
                  autoFocus
                  id="data-cliente-agendamento-respiratorio"
                  sx={{ display: "flex", width: "100%" }}
                  label="Data"
                  error={false}
                  value={converteData(data)}
                  disabled={titulo === "Informações do agendamento"}
                />
              </Grid>

              <Grid item xs={5} sm={2}>
                <TextField
                  id="data-cliente-agendamento-respiratorio"
                  sx={{ display: "flex", width: "100%" }}
                  label="Hora"
                  error={false}
                  value={hora}
                  disabled={titulo === "Informações do agendamento"}
                />
              </Grid>

              <Grid item xs={12} sm={7}>
                <FormControl fullWidth>
                  <InputLabel>Tipo</InputLabel>
                  <Select
                    sx={{ display: "flex", width: "100%", color: "black" }}
                    label="Tipo"
                    value={tipo}
                    onChange={(e) => setTipo(e.target.value)}
                    disabled={titulo === "Informações do agendamento"}
                  >
                    {exames.map((v) => (
                      <MenuItem key={v} value={v}>
                        {v}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  id="nome-cliente-agendamento-respiratorio"
                  sx={{ display: "flex", width: "100%" }}
                  label="Nome"
                  error={false}
                  value={nome}
                  onChange={(e) => setNome(e.target.value)}
                  disabled={titulo === "Informações do agendamento"}
                />
              </Grid>

              <Grid item xs={12} sm={3}>
                <TextField
                  id="telefone-cliente-agendamento-respiratorio"
                  sx={{ display: "flex", width: "100%" }}
                  label="Telefone 1"
                  error={false}
                  value={telefone}
                  onChange={(e) => setTelefone(e.target.value)}
                  disabled={titulo === "Informações do agendamento"}
                />
              </Grid>

              <Grid item xs={12} sm={3}>
                <TextField
                  id="telefone-cliente-agendamento-respiratorio"
                  sx={{ display: "flex", width: "100%" }}
                  label="Telefone 2"
                  error={false}
                  value={telefone2}
                  onChange={(e) => setTelefone2(e.target.value)}
                  disabled={titulo === "Informações do agendamento"}
                />
              </Grid>

              <Grid item xs={6} sm={2.5}>
                <Button
                  fullWidth
                  sx={{ width: "100%", height: "2rem", alignSelf: "flex-end" }}
                  variant="contained"
                  color="success"
                  onClick={() => selecionaAcao(1)}
                >
                  {titulo !== "Informações do agendamento"
                    ? "Salvar"
                    : "Editar"}
                </Button>
              </Grid>

              <Grid item xs={6} sm={2.5}>
                <Button
                  fullWidth
                  sx={{ width: "100%", height: "2rem", alignSelf: "flex-end" }}
                  variant="outlined"
                  color="error"
                  onClick={() => selecionaAcao(2)}
                >
                  {titulo !== "Informações do agendamento"
                    ? "Cancelar"
                    : "Excluir"}
                </Button>
              </Grid>
            </Grid>
          </Paper>

          <Paper
            sx={{
              display: "flex",
              width: "100%",
              flexDirection: "row",
              justifyContent: "space-between",
              padding: "1.0rem",
            }}
            elevation={3}
          >
            <span>
              {"Agendado por: "}
              <strong>{agendadoPor}</strong>
            </span>
            <span>
              {editadoPor ? "Editado por: " : ""}
              <strong>{editadoPor}</strong>
            </span>
          </Paper>
        </Paper>
        <Waiter espera={espera} />
      </Dialog>

      <Dialog
        open={confirmaExclusao}
        onClose={() => setConfirmaExclusao(false)}
      >
        <DialogTitle id="alert-dialog-title">
          Confirma a exclusão desse agendamento?
        </DialogTitle>

        <Grid
          container
          direction="row"
          justifyContent="center"
          spacing={2}
          style={{ padding: "1rem" }}
        >
          <Grid item xs={6} sm={4}>
            <Button
              fullWidth
              variant="contained"
              color="success"
              onClick={excluiAgendamento}
            >
              Confirmar
            </Button>
          </Grid>
          <Grid item xs={6} sm={4}>
            <Button
              fullWidth
              variant="outlined"
              color="error"
              onClick={() => setConfirmaExclusao(false)}
            >
              Desistir
            </Button>
          </Grid>
        </Grid>
      </Dialog>
    </>
  );
}
