import React, { useContext, useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { Button, Dialog, DialogActions, DialogTitle, Grid, Paper, TextField } from "@mui/material";

import DataPickerAtualizado from "../../../../../components/DataPickerAtualizado";
import { RecebimentoContext } from "../../Context/RecebimentoContext";

import { api } from "../../../../../services/Api";

export default function Formulario() {
  const { enqueueSnackbar } = useSnackbar();

  const {
    setTela,
    retornoLoteAgrupado,
    setRetornoLoteAgrupado,
    setAtualizaTabela
  } = useContext(RecebimentoContext);

  const [data_recebimento, setData_recebimento ] = useState(() => {
    const valueDateR = new Date();
      valueDateR.setHours(valueDateR.getHours() - 3);
    return valueDateR
  });

  const [ nota_fiscal, setNota_fiscal ] = useState('')
  const [ imposto, setImposto ] = useState(0)
  const [ desconto_unimed, setDesconto_unimed ] = useState(0)

  const [ atualizaValores, setAtualizaValores ] = useState(false);
  const [ dialogoConfirmar, setDialogoConfirmar ] = useState(false);

  useEffect(() => {
    setRetornoLoteAgrupado((prevState) => {
      const vetor = prevState;
      const valorUnimedDesconto = desconto_unimed ? desconto_unimed.replace(",", ".") : 0;
      const valorImposto = imposto ? imposto.replace(",", "."): 0;

      const novoVetor = vetor.map((v) => {
        const valorDescontoUnimed = v.valor_recuperado * (1 - (valorUnimedDesconto / 100))
        const valorLiquidoAtual = valorDescontoUnimed * (1 - (valorImposto / 100));

        return ({
          ...v,
          valor_liquido: valorLiquidoAtual,
          valor_desconto_unimed: v.valor_recuperado - valorDescontoUnimed,
          valor_imposto: valorDescontoUnimed - valorLiquidoAtual
        })
      })
      return novoVetor
    })
    // eslint-disable-next-line
  }, [atualizaValores]);

  async function salvaRetornoGlosasLotes() {
      try {
        await api.post(`/v2/recebimento/retornoGlosasLotes`, {
          retornoLoteAgrupado,
          nota_fiscal,
          data_recebimento,
          imposto,
          desconto_unimed,
          protocolo: ''
        });

        setAtualizaTabela(prevState => !prevState);
        setTela("Principal");
        setRetornoLoteAgrupado([]);
        enqueueSnackbar('Valores salvos', { variant: "success" });
      } catch (error) {
        enqueueSnackbar(
          "Não foi possível salvar o recebimento. Por favor, tente novamente.",
          { variant: "error" }
        );
      }
  }

  return (
    <Paper sx={{ p: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={6} sm={4} lg={2}>
          <DataPickerAtualizado
            size="small"
            labelName="Data do pagamento"
            data={data_recebimento}
            setData={setData_recebimento}
          />
        </Grid>

        <Grid item xs={6} sm={4} lg={2}>
          <TextField
            autoFocus
            autoComplete="off"
            id="nota-fiscal"
            label="Nota Fiscal"
            value={nota_fiscal}
            onChange={event => setNota_fiscal(event.target.value)}
            size="small"
            fullWidth
          />
        </Grid>

        <Grid item xs={6} sm={4} lg={1}>
          <TextField
            id="imposto_recebimento"
            label="Imposto %"
            value={imposto}
            onChange={event => setImposto(event.target.value)}
            onBlur={() => setAtualizaValores(prevState => !prevState)}
            size="small"
            fullWidth
          />
        </Grid>

        <Grid item xs={6} sm={4} lg={1}>
          <TextField
            id="desconto_unimed2"
            label="Desconto Unimed %"
            value={desconto_unimed}
            onChange={event => setDesconto_unimed(event.target.value)}
            onBlur={() => setAtualizaValores(prevState => !prevState)}
            size="small"
            fullWidth
          />
        </Grid>
        <Grid item xs={6} sm={4} lg={1}>
          <Button
            fullWidth
            variant="contained"
            color="success"
            onClick={() => {
              if (nota_fiscal) {
                return setDialogoConfirmar(true)
              }
              return enqueueSnackbar("Campo nota fiscal em branco.", { variant: "warning" })
            }}
          >
            Salvar
          </Button>
        </Grid>
        <Grid item xs={6} sm={4} lg={1}>
          <Button
            fullWidth
            variant="outlined"
            color="error"
            onClick={() => {
                setTela("Principal");
                setRetornoLoteAgrupado([]);
              }}
          >
            Cancelar
          </Button>
        </Grid>
      </Grid>
      <Dialog
        open={dialogoConfirmar}
        onClose={() => setDialogoConfirmar(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialogo">Todos os valores estão corretos?</DialogTitle>
        <DialogActions>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => setDialogoConfirmar(false)}
          >
            Cancelar
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={salvaRetornoGlosasLotes}
            autoFocus
          >
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  )
}
