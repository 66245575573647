import React from "react";

import Header from "../../components/Header";
import AgendaCalendario from "../../componentsv2/AgendaCalendario";

export default function GH() {
  const exames = ["Curva de GH com estímulo de Clonidina"];
  const horarios = ["07:29", "07:30"];
  const diasSemana = [2, 4];
  const acesso = "AGH";

  return (
    <>
      <Header titulo="Agenda GH" />
      <AgendaCalendario
        agenda="gh"
        unidade={41}
        exames={exames}
        horarios={horarios}
        diasSemana={diasSemana}
        acesso={acesso}
      />
    </>
  );
}
