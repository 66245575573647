import React, { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import {
  Modal,
  Paper,
  TextField,
  InputAdornment,
  DialogActions,
} from "@mui/material";
import { styled } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";

import Loading from "../../../../components/Loading";
import Button from "../../../../components/Button";
import Checkbox from "../../../../components/Checkbox";

import { api } from "../../../../services/Api";

import "./styles.css";

/* Esse componente originalmente começou como um modal só para especialidades (SpecialtiesModal),
e eu planejava fazer
outro componente para os módulos do Portal Médico. Contudo, eu percebi que eu ia basicamente escrever o mesmo
código de novo, então adaptei este componente para atender aos dois casos. */

const CustomPaper = styled(Paper)({
  root: {
    width: "95%",
    marginLeft: "auto",
    marginRight: "auto",
    maxWidth: "400px",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    padding: "1.5rem",
    maxHeight: "95vh",
    overflow: "auto",
  },
});

export default function SpecialtiesModal({ open, onClose, onConfirm, mode }) {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [search, setSearch] = useState("");
  const { enqueueSnackbar } = useSnackbar();

  const filterFunction = (dataInstance) => {
    if (search === "") {
      return true;
    }

    return dataInstance.nome.toLowerCase().includes(search.toLowerCase());
  };

  const onCheckboxChange = (event, dataInstance) => {
    const {
      target: { checked },
    } = event;
    if (checked) {
      return setSelectedData((prevState) => [...prevState, dataInstance]);
    }

    const targetIndex = selectedData.findIndex((s) => s.id === dataInstance.id);
    const copy = [...selectedData];
    copy.splice(targetIndex, 1);
    return setSelectedData(copy);
  };

  const onSelectAll = (event) => {
    const {
      target: { checked },
    } = event;
    if (checked) {
      return setSelectedData(data);
    }
    return null;
  };

  const onSubmit = () => {
    onConfirm(selectedData);
    setSelectedData([]);
  };

  // recuperando todas as especialidades
  useEffect(() => {
    (async () => {
      const url =
        mode === "modules"
          ? `/v2/portalMedico/modulos`
          : "/v2/portalMedico/especialidades";
      try {
        const { data: result } = await api.get(url);
        setData(result);
      } catch (e) {
        enqueueSnackbar(
          `Não foi possível recuperar ${
            mode === "modules" ? "os módulos" : "as especialidades"
          } existentes`,
          {
            variant: "error",
          }
        );
      }
      setLoading(false);
    })();
    // eslint-disable-next-line
  }, [enqueueSnackbar]);

  return (
    <>
      {loading && <Loading />}
      <Modal open={open} onClose={onClose}>
        <CustomPaper>
          <h2 className="search-modal-title">
            Selecione {mode === "modules" ? "módulos" : "especialidades"}
          </h2>
          <CloseIcon
            onClick={() => {
              onClose();
              setSelectedData([]);
            }}
            style={{
              position: "absolute",
              top: "8px",
              right: "8px",
              cursor: "pointer",
              fontSize: "30px",
            }}
          />
          <TextField
            value={search}
            onChange={(e) => {
              // e.persist();
              setSearch(e.target.value);
            }}
            label="Busca"
            placeholder={`Busque por ${
              mode === "modules" ? "módulos" : "especialidades"
            }`}
            fullWidth
            style={{ marginBottom: "1.5rem", fontSize: "1.2rem" }}
            InputProps={{
              endAdornment: (
                <InputAdornment>
                  <SearchIcon style={{ color: "rgba(0,0,0,0.6)" }} />
                </InputAdornment>
              ),
            }}
          />
          <div className="data-container">
            <div className="data-instance">
              <Checkbox onChange={onSelectAll} />
              <p style={{ fontWeight: "500" }}>
                Selecionar {mode === "modules" ? "todos" : "todas"}
              </p>
            </div>
            {data.filter(filterFunction).map((specialty, index, array) => (
              <div key={specialty.id}>
                <div className="data-instance">
                  <Checkbox
                    onChange={(e) => onCheckboxChange(e, specialty)}
                    checked={
                      selectedData.findIndex((d) => d.id === specialty.id) !==
                      -1
                    }
                  />
                  <p style={{ fontSize: "1rem" }}>{specialty.nome}</p>
                </div>
                {index !== array.length - 1 && (
                  <hr key={`hr-${specialty.id}`} />
                )}
              </div>
            ))}
          </div>
          <DialogActions style={{ marginTop: "2.5rem" }}>
            <Button secondary onClick={onSubmit}>
              Confirmar
            </Button>
          </DialogActions>
        </CustomPaper>
      </Modal>
    </>
  );
}
