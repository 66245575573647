import React, { useState } from "react";
import { FormControl, Input, InputAdornment, Paper } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

import CardTrilha from "../CardTrilha";

import "./styles.css";

export default function Trilha({
  titulo,
  exames,
  abrirDialog,
  deletarExame,
  moveRight,
  moveLeft,
  moveUp,
  moveDown,
}) {
  const [search, setSearch] = useState("");

  function filterFunction(exame) {
    if (search === "") {
      return true;
    }

    const filtroIdentificador = exame.identificador
      ? exame.identificador.includes(search)
      : false;
    return (
      exame.cpf_cliente.includes(search) ||
      exame.nome_cliente.includes(search) ||
      exame.celular_cliente.includes(search) ||
      filtroIdentificador
    );
  }

  return (
    <section className="trilha">
      <Paper className="trilha-conteudo">
        <h2 className="trilha-titulo">
          {titulo === "CONFERÊNCIA" ? "DIGITAÇÃO/CONFERÊNCIA" : titulo}
        </h2>
        <FormControl className="trilha-pesquisa">
          <Input
            endAdornment={
              // eslint-disable-next-line react/jsx-wrap-multilines
              <InputAdornment position="end">
                <SearchIcon style={{ color: "rgba(0,0,0,0.6)" }} />
              </InputAdornment>
            }
            placeholder="Pesquisa"
            value={search}
            onChange={e => setSearch(e.target.value)}
            id={`search-${titulo}`}
          />
        </FormControl>
        <div className="cards">
          {exames
            .filter(e => filterFunction(e))
            .map(exame => (
              <CardTrilha
                key={exame.id}
                exame={exame}
                abrirDialog={abrirDialog}
                deletarExame={deletarExame}
                moveRight={moveRight}
                moveLeft={moveLeft}
                moveUp={moveUp}
                moveDown={moveDown}
              />
            ))}
        </div>
      </Paper>
    </section>
  );
}
