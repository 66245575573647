import React from "react";
import usePermissao from "../../../hooks/usePermissao";
import { Box } from "@mui/material";

export default function BI() {
  const verificaPermissao = usePermissao("CA004");
  return (
    <Box>
      {verificaPermissao && (
        <iframe
          width="100%"
          height="700"
          title="BI"
          src="https://app.powerbi.com/view?r=eyJrIjoiZDUwN2VkMDMtNzkwMy00ZjIyLWE4MDMtZjgxNjNlMzZhNzA0IiwidCI6ImE1ZGEyY2QwLTgxNWYtNGFmNC1hYzYwLTZjNmI1OTNiNzk1NyJ9"
          frameBorder="0"
          allowFullScreen
        />
      )}
    </Box>
  );
}
