import React, { useState, useContext } from "react";

import { Button as MuiButton, Grid } from "@mui/material";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";

import { useSnackbar } from "notistack";
import { api } from "../../../../../services/Api";
import { Modal } from "../../../../../componentsv2/Modal";
import { ModalForm } from "../../../../../componentsv2/ModalForm";
import { Select } from "../../../../../componentsv2/Select";
import { Input } from "../../../../../componentsv2/Input";
import { Context as PermissoesContext } from "../../../../../Context/PermissoesContext.js";

export default function DarBaixa({ row, concluido, setUpdate, update }) {
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [motivoBaixa, setMotivoBaixa] = useState("");
  const [observacaoBaixa, setObservacaoBaixa] = useState("");
  const { checarPermissao } = useContext(PermissoesContext);
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);

  const arrayDeMotivos = [
    "Vendido",
    "Extraviado",
    "Devolvido",
    "Expurgado",
    "Danificado",
    "Obsoleto",
    "Outros",
  ];

  const darBaixa = async e => {
    e.preventDefault();
    try {
      const data = {
        motivoBaixa,
        observacaoBaixa,
      };

      if (motivoBaixa === "Outros" && !observacaoBaixa) {
        enqueueSnackbar(
          "Erro! Observação da baixa necessária para o motivo selecionado.",
          {
            variant: "error",
          }
        );
        return;
      }
      await api.put(`/v2/inventario/darBaixaBem/${row.bem_id}`, data);
      enqueueSnackbar("Baixa cadastrada com sucesso.", {
        variant: "success",
      });

      setConfirmDeleteModal(false);

      setUpdate(!update);
    } catch (error) {
      enqueueSnackbar("Erro! Não foi possível dar baixa no bem.", {
        variant: "error",
      });
    }
  };

  //   <MuiModal open={open} onClose={() => setOpen(false)}>
  //     <ModalOption row={row} />
  //   </MuiModal>
  return (
    <>
      {concluido === "Concluído" ? (
        <MuiButton
          style={{ color: "#000" }}
          onClick={() =>
            enqueueSnackbar(
              "Você não pode dar baixa em um bem pertencente à um inventário concluído.",
              {
                variant: "error",
                autoHideDuration: 4000,
              }
            )
          }
        >
          <RemoveCircleOutlineIcon />
        </MuiButton>
      ) : (
        <MuiButton
          style={{ color: "#000" }}
          onClick={() => {
            if (checarPermissao("INV029") && checarPermissao("INV020")) {
              setOpen(true);
            } else {
              enqueueSnackbar("Você não possui permissão para dar baixa em um bem.", {
                variant: "error",
                autoHideDuration: 3000,
              });
            }
          }}
        >
          <RemoveCircleOutlineIcon />
        </MuiButton>
      )}

      <ModalForm
        title="Dar baixa no bem"
        onClose={setOpen}
        open={open}
        onSubmit={() => setConfirmDeleteModal(true)}
      >
        <Grid xs={6} item>
          <Select
            value={motivoBaixa}
            onChange={e => setMotivoBaixa(e.target.value)}
            label="Motivo da baixa"
            required
            variant="standard"
            arrayOptions={arrayDeMotivos}
          />
        </Grid>

        <Grid xs={6} item>
          <Input
            onChange={e => setObservacaoBaixa(e.target.value)}
            value={observacaoBaixa}
            multiline
            maxRows={5}
            fullWidth
            label="Observação da baixa"
            type="text"
            variant="standard"
          />
        </Grid>
      </ModalForm>
      <Modal
        onClick={darBaixa}
        open={confirmDeleteModal}
        modalText="Tem certeza que deseja dar baixa neste bem?"
        onClose={setConfirmDeleteModal}
        confirmActionButtonColor="green"
        confirmActionButtonText="Confirmar"
      />
    </>
  );
}
