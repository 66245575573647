import React, { useState } from "react";

import Formulario from "./Components/Formulario";
import Agenda from "./Components/Agenda";
import BI from "./Components/BI";
import Controle from "./Components/Controle";
import Financeiro from "./Components/Financeiro";

import Header from "../../components/Header";

import { ColetaDomiciliarContextProvider } from "./Context/ColetaDomiciarContext";
import { ModaisColetaDomiciliarContextProvider } from "./Context/ModaisColetaDomiciarContext";
import { FormularioColetaDomiciliarContextProvider } from "./Context/FormularioColetaDomiciliarContext";
import { FinanceiroContextProvider } from "./Context/FinanceiroContext";
import { ColetaDomiciliarBloqueioContextProvider } from "./Context/ColetaDomiciarBloqueioContext";
import Container from "../../componentsv2/Container";

export default function ColetaDomicilar() {
  const [aba, setAba] = useState("Agenda");

  return (
    <FormularioColetaDomiciliarContextProvider>
      <ColetaDomiciliarContextProvider>
        <ModaisColetaDomiciliarContextProvider>
          <>
            <Header titulo="Coleta Domiciliar"/>
            <Container>
              <Formulario aba={aba} setAba={setAba} />
              { aba === "Agenda" && <Agenda />}
              { aba === "BI" && <BI />}
              { aba === "Controle" && (
                <ColetaDomiciliarBloqueioContextProvider>
                  <Controle />
                </ColetaDomiciliarBloqueioContextProvider>
              )}
              { aba === "Financeiro" && (
                <FinanceiroContextProvider>
                  <Financeiro />
                </FinanceiroContextProvider>
              )}
            </Container>
          </>
        </ModaisColetaDomiciliarContextProvider>
      </ColetaDomiciliarContextProvider>
    </FormularioColetaDomiciliarContextProvider>
  );
}
