import { Typography } from "@mui/material";
import GridContainer from "../../../../componentsv2/GridContainer";
import GridItem from "../../../../componentsv2/GridItem";
import Paper from "../../../../componentsv2/Paper";
import { IconButton } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ModalVisualizarEstoque from "./ModalVisualizarEstoque";
import { useState } from "react";

export default function Card({ estoque }) {
  const [openModalVisualizarEstoque, setOpenModalVisualizarEstoque] =
    useState(false);

  return (
    <Paper
      sx={{
        padding: 2,
        borderRadius: 2,
      }}
    >
      {openModalVisualizarEstoque ? (
        <ModalVisualizarEstoque
          estoque={estoque}
          onClose={setOpenModalVisualizarEstoque}
          open={openModalVisualizarEstoque}
        />
      ) : null}

      <GridContainer>
        <GridContainer
          item
          xs={12}
          justifyContent="space-between"
          alignItems="center"
        >
          <GridItem>
            <Typography variant="subtitle1">{estoque?.nome}</Typography>
          </GridItem>
          <GridItem>
            <Typography variant="body2" color="black">
              {estoque?.valorTotal?.toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
              })}
            </Typography>
          </GridItem>
        </GridContainer>
        <GridContainer
          item
          xs={12}
          alignItems="center"
          justifyContent="space-between"
        >
          <GridItem>
            <Typography variant="subtitle2">
              Reagentes: {estoque?.quantidadeProdutos}
            </Typography>
          </GridItem>
          <GridItem>
            <IconButton
              size="small"
              onClick={() => setOpenModalVisualizarEstoque(true)}
            >
              <VisibilityIcon />
            </IconButton>
          </GridItem>
        </GridContainer>
      </GridContainer>
    </Paper>
  );
}
