import React, { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import { Grid, Paper, IconButton, Box, Autocomplete } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

import Tabela from "../../../../../componentsv2/Tabela";
import DataPickerAtualizado from "../../../../../components/DataPickerAtualizado";
import OpcoesTransferencia from "./opcoesTransferencia";

import { Context as PermissoesContext } from "../../../../../Context/PermissoesContext";
import { Input } from "../../../../../componentsv2/Input";
import { Select } from "../../../../../componentsv2/Select";
import DadosContext from "./DadosContext";
import UnidadesContext from "../../../DadosContext";
import SelectUnidade from "../../../components/SelectUnidade";

import { api } from "../../../../../services/Api";

export default function Transferencia() {
  const history = useHistory();
  const { checarPermissao } = useContext(PermissoesContext);
  const { enqueueSnackbar } = useSnackbar();
  const [arrayDeTransferencias, setArrayDeTransferencias] = useState([]);
  const [codigosTombadorArray, setCodigosTombadorArray] = useState([]);

  const [serial, setSerial] = useState(null);

  const situacoes = ["Todas", "Aceita", "Pendente"];
  const [dataInicio, setDataInicio] = useState(null);
  const [dataFim, setDataFim] = useState(null);
  const [situacao, setSituacao] = useState("Todas");
  const [update, setUpdate] = useState(false);

  const { setUnidadeUsuario, unidadeUsuario, semAcessoTodasUnidades } =
    useContext(UnidadesContext);

  useEffect(() => {
    async function getTransferencias() {
      try {
        const { data } = await api.get(`/v2/inventario/transferenciasBens`, {
          params: {
            dataInicio,
            dataFim,
            serial: serial?.bem?.serial,
            unidade: unidadeUsuario,
            situacao,
          },
        });

        const arrayTransferencias = data.data.map((element) => ({
          id: element.id,
          codTombador: element.bem.codTombador,
          serial: element.bem.serial,
          origemUnidade: element.origemUnidade.nome,
          destinoUnidade: element.destinoUnidade.nome,

          dataSolicitacao: element.dataSolicitacao,
          situacao:
            element.usuarioAceite && element.dataAceite ? "Aceita" : "Pendente",
        }));
        setArrayDeTransferencias(arrayTransferencias);
      } catch (error) {
        enqueueSnackbar("Erro! Não foi possível buscar as transferências.", {
          variant: "error",
        });
      }
    }
    getTransferencias();
  }, [
    enqueueSnackbar,

    serial,
    dataInicio,
    dataFim,
    checarPermissao,
    situacao,
    update,
    unidadeUsuario,
  ]);

  useEffect(() => {
    async function getArrays() {
      try {
        const { data } = await api.get(
          `/v2/inventario/opcoesCodTombadorTransferencias`,
          {
            params: { unidade: unidadeUsuario },
          }
        );

        setCodigosTombadorArray(data.data.bem);
      } catch (error) {
        enqueueSnackbar("Erro! Não foi possível buscar os bens.", {
          variant: "error",
        });
      }
    }
    getArrays();
  }, [enqueueSnackbar, unidadeUsuario]);

  const columns = [
    {
      field: "codTombador",
      headerName: "Cod. Tombador",
      width: 150,
      type: "text",
    },

    {
      field: "origemUnidade",
      headerName: "Unidade Origem",
      width: 230,
      type: "text",
    },
    {
      field: "destinoUnidade",
      headerName: "Unidade Destino",
      width: 230,
      type: "text",
    },

    {
      field: "situacao",
      headerName: "Situação",
      width: 120,
      type: "text",
    },
    {
      field: "dataSolicitacao",
      headerName: "Data Solicitacão",
      width: 200,
      type: "dateTime",
      valueGetter: ({ value }) => value && new Date(value),
    },
    {
      field: "opcoes",
      headerName: "Opções",

      width: 130,

      renderCell: (params) => (
        <div style={{ cursor: "pointer" }}>
          <OpcoesTransferencia row={params.row} />
        </div>
      ),
    },
  ];

  return (
    <DadosContext.Provider value={{ setUpdate, update }}>
      <Grid container alignItems="center" spacing={1} direction="column">
        <Grid container xs={12} item>
          <Paper sx={{ width: "100%", marginBottom: "30px", padding: "10px" }}>
            <Grid
              item
              container
              sx={{ marginLeft: "5px" }}
              justifyContent="space-between"
            >
              <Grid xs={10} spacing={3} item container>
                <Grid item md={3} sm={6} lg={2} xs={6}>
                  <DataPickerAtualizado
                    data={dataInicio}
                    setData={setDataInicio}
                    labelName="Data Inicial"
                    variant="standard"
                  />
                </Grid>
                <Grid item md={3} sm={6} lg={2} xs={6}>
                  <DataPickerAtualizado
                    data={dataFim}
                    setData={setDataFim}
                    labelName="Data Final"
                    variant="standard"
                  />
                </Grid>
                <Grid item md={3} sm={6} lg={2} xs={6}>
                  <Select
                    variant="standard"
                    label="Situação"
                    value={situacao}
                    onChange={(e) => setSituacao(e.target.value)}
                    arrayOptions={situacoes}
                  />
                </Grid>
                <Grid item md={3} sm={6} lg={3} xs={6}>
                  <Autocomplete
                    value={serial}
                    onChange={(event, value) => setSerial(value)}
                    disablePortal
                    options={codigosTombadorArray}
                    getOptionLabel={(option) =>
                      `${
                        option.bem.codTombador
                          ? option.bem.codTombador + "-"
                          : ""
                      }${option.bem.material.modelo}(${
                        option.bem.material.marca
                      })-${option.bem.serial}`
                    }
                    fullWidth
                    renderInput={(params) => (
                      <Input
                        {...params}
                        variant="standard"
                        label="Pesquisar bem"
                      />
                    )}
                  />
                </Grid>
                <Grid item md={3} sm={6} lg={3} xs={6}>
                  <SelectUnidade
                    disabled={semAcessoTodasUnidades}
                    value={unidadeUsuario}
                    onChange={(e) => setUnidadeUsuario(e.target.value)}
                  />
                </Grid>
              </Grid>
              <Grid xs={2} sm={2} md={1} lg={1} item>
                <IconButton
                  onClick={() => {
                    if (checarPermissao("INV023")) {
                      history.push("/inventario/bem/criaTransferencia");
                    } else {
                      enqueueSnackbar(
                        "Você não possui permissão para realizar uma transferência!",
                        {
                          variant: "error",
                          autoHideDuration: 3000,
                        }
                      );
                    }
                  }}
                  color="success"
                  size="large"
                >
                  <AddCircleOutlineIcon fontSize="large" />
                </IconButton>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12} container>
          <Grid style={{ paddingTop: "10px" }} xs={12} sm={12} item>
            <Grid spacing={2} container>
              <Grid style={{ paddingTop: "10px" }} item xs={12} sm={12}>
                <Paper elevation={2}>
                  <Box
                    sx={{
                      height: 1,
                      width: "100%",
                      marginBottom: "10px",
                    }}
                  >
                    <div>
                      <Tabela
                        rows={arrayDeTransferencias}
                        columns={columns}
                        autoHeight
                      />
                    </div>
                  </Box>
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </DadosContext.Provider>
  );
}
