import React, { createContext, useState } from "react";

const FormularioColetaDomiciliarContext = createContext();

function FormularioColetaDomiciliarContextProvider({ children }) {
  const [ novaHora, setNovaHora ] = useState("");
  const [ tipo_cliente, setTipo_cliente ] = useState("Normal");
  const [ codigoPesquisa, setCodigoPesquisa ] = useState("");
  const [ codigoPedido, setCodigoPedido ] = useState("");
  const [ codigo_pedido, setCodigo_pedido ] = useState(null);
  const [ posto_pedido, setPosto_pedido ] = useState("");
  const [ nome, setNome ] = useState("");
  const [ endereco, setEndereco ] = useState("");
  const [ endereco_numero, setEndereco_numero ] = useState("");
  const [ complemento, setComplemento ] = useState("");
  const [ bairro, setBairro ] = useState("");
  const [ cidade, setCidade ] = useState("");
  const [ estado, setEstado ] = useState("");
  const [ cep, setCep ] = useState("");
  const [ fone, setFone ] = useState("");
  const [ celular, setCelular ] = useState("");
  const [ email, setEmail ] = useState("");
  const [ observacoes, setObservacoes ] = useState("");
  const [ ponto_referencia, setPonto_referencia ] = useState("");
  const [ cpf_cliente, setCpf_cliente ] = useState("");
  const [ tipo_pagamento, setTipo_pagamento ] = useState("");
  const [ numero_cartao, setNumero_cartao ] = useState("");
  const [ valor_recebido, setValor_recebido ] = useState("0.00");
  const [ resultadoPesquisa, setResultadoPesquisa ] = useState([]);
  const [ exames, setExames ] = useState([]);
  const [ localizador, setLocalizador ] = useState("");
  const [ dadosReagendamento, setDadosReagendamento ] = useState("");
  const [ informacoesPedido, setInformacoesPedido ] = useState("");
  const [ coleta_dupla, setColeta_dupla ] = useState("Não");

  function zeraCliente() {
    setDadosReagendamento("");
    setCodigoPedido("");
    setCodigo_pedido(null);
    setPosto_pedido("");
    setLocalizador("");
    setNome("");
    setEndereco("");
    setEndereco_numero("");
    setComplemento("");
    setBairro("");
    setCidade("");
    setEstado("");
    setCep("");
    setFone("");
    setCelular("");
    setEmail("");
    setPonto_referencia("");
    setObservacoes("");
    setColeta_dupla("Não");
    setCpf_cliente("");
    setValor_recebido("0.00");
    setTipo_pagamento("");
    setNumero_cartao("");
  }

  return (
    <FormularioColetaDomiciliarContext.Provider
      value={{
        novaHora,
        setNovaHora,
        tipo_cliente,
        setTipo_cliente,
        codigoPesquisa,
        setCodigoPesquisa,
        codigoPedido,
        setCodigoPedido,
        codigo_pedido,
        setCodigo_pedido,
        posto_pedido,
        setPosto_pedido,
        nome,
        setNome,
        endereco,
        setEndereco,
        endereco_numero,
        setEndereco_numero,
        complemento,
        setComplemento,
        bairro,
        setBairro,
        cidade,
        setCidade,
        estado,
        setEstado,
        cep,
        setCep,
        fone,
        setFone,
        celular,
        setCelular,
        email,
        setEmail,
        observacoes,
        setObservacoes,
        ponto_referencia,
        setPonto_referencia,
        cpf_cliente,
        setCpf_cliente,
        valor_recebido,
        setValor_recebido,
        tipo_pagamento,
        setTipo_pagamento,
        numero_cartao,
        setNumero_cartao,
        resultadoPesquisa,
        setResultadoPesquisa,
        exames,
        setExames,
        localizador,
        setLocalizador,
        dadosReagendamento,
        setDadosReagendamento,
        informacoesPedido,
        setInformacoesPedido,
        coleta_dupla,
        setColeta_dupla,
        zeraCliente
      }}
    >
      {children}
    </FormularioColetaDomiciliarContext.Provider>
  );
}

export { FormularioColetaDomiciliarContext, FormularioColetaDomiciliarContextProvider };
