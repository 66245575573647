import React from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

export default function SelectTipo({ label, value, setValue, tipos, ativo = false }) {
  return (
    <FormControl defaultValue=" " size="small" variant="standard" fullWidth>
      <InputLabel id="select-label">{label}</InputLabel>
      <Select
        value={value}
        onChange={e => setValue(e.target.value)}
        labelId="select-label"
        id="select"
        label="Ramal"
        disabled={ativo}
      >
        <MenuItem value="">Todas</MenuItem>
        {tipos.map(el => (
          <MenuItem key={el.id} value={el.id}>
            {el.tipo}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
