import React, { useContext, useRef, useState } from "react";
import { useSnackbar } from "notistack";
import { Box, Button, Stack } from "@mui/material";
import { Download, Upload } from "@mui/icons-material";
import X2JS from "x2js";

import { RecebimentoContext } from "../../Context/RecebimentoContext";
import { RetornoLoteContext } from "../../Context/RetornoLoteContext";
import Waiter from "../../../../../components/Backdrop";

import { api } from "../../../../../services/Api";
import { ModalRecebimentoContext } from "../../Context/ModalRecebimentoContext";

export default function BotaoImportarAquivo({ nome, lotes }) {
  const { enqueueSnackbar } = useSnackbar();

  const { setTela, setRetornoLoteAgrupado, lote } =
    useContext(RecebimentoContext);
  const { setGlosas, setAtualizaValoresRecebimentoManual, glosas } =
    useContext(RetornoLoteContext);
  const { informacoesLote } = useContext(ModalRecebimentoContext);
  const [aguarda, setAguarda] = useState(false);

  const fileInputRef = useRef();

  function verificaDiferencaTabela(dadosGuias) {
    const dados = !Array.isArray(dadosGuias) ? Array(dadosGuias) : dadosGuias;
    let valor = 0;

    dados.map((g) => {
      const detalhes = !Array.isArray(g.detalhesGuia)
        ? Array(g.detalhesGuia)
        : g.detalhesGuia;

      try {
        detalhes.map((d) => {
          function pegaLinha(linha) {
            if (typeof linha.relacaoGlosa !== "undefined") {
              if (String(linha.relacaoGlosa.tipoGlosa) === "1714") {
                valor += Number(linha.relacaoGlosa.valorGlosa);
              }
            }
          }

          if (typeof d.length !== "undefined") {
            d.forEach((l) => pegaLinha(l));
          } else {
            pegaLinha(d);
          }
          return null;
        });
      } catch (error) {
        // console.log(error);
      }
      return null;
    });

    return valor;
  }

  function agrupaMotivos(arrayGlosas) {
    const novasLinhas = arrayGlosas.reduce((obj, atual) => {
      const vetorObj = obj;
      let existe = false;

      if (typeof atual.motivo !== "undefined") {
        vetorObj.map((v, i) => {
          if (v.motivo === atual.motivo) {
            vetorObj[i].valor_recebido += Number(atual.valor_recebido);
            existe = true;
          }
          return null;
        });

        if (!existe) {
          vetorObj.push({
            id: Math.floor(Date.now() * Math.random()).toString(36),
            ...atual,
          });
        }
      }

      return vetorObj;
    }, []);

    return novasLinhas;
  }

  function capturaGuias(dadosProtocolo) {
    const listaGuias = [];
    dadosProtocolo.relacaoGuias.map((g) => {
      const glosas = [];
      const diferencaUnimed =
        Number(g.valorLiberadoGuia) - Number(g.valorInformadoGuia);
      const valor_diferenca_tabela = verificaDiferencaTabela(g);

      if (typeof g.motivoGlosaGuia !== "undefined") {
        glosas.push({
          situacao: "Glosado",
          motivo: String(g.motivoGlosaGuia.codigoGlosa),
          valor_recebido: Number(g.valorGlosaGuia),
        });
      }

      const numero_guia_operadora = g.numeroGuiaOperadora;
      const valor_recuperado = g.valorLiberadoGuia;
      const valor_liquido = g.valorLiberadoGuia;
      const valor_diferenca_unimed = diferencaUnimed <= 0 ? 0 : diferencaUnimed;

      const valor_glosado = g.valorGlosaGuia - valor_diferenca_tabela;

      listaGuias.push({
        numero_guia_operadora,
        valor_recuperado,
        valor_liquido,
        valor_diferenca_tabela,
        valor_diferenca_unimed,
        valor_glosado,
        glosas,
      });

      return null;
    });
    return listaGuias;
  }

  async function capturaLotes(guias) {
    const lotesGuias = [];
    const esperaLotes = guias.map(async (g) => {
      try {
        const { data: resultadoLote } = await api.get(
          `/v2/recebimento/confereLote/${g.numero_guia_operadora}`
        );
        const id = resultadoLote[0]?.id_lote;
        const lote_plano = resultadoLote[0]?.lote.codigo; // Math.round(Math.random()*10+1);
        lotesGuias.push({
          id,
          ...g,
          lote_plano,
        });
        return null;
      } catch (error) {
        return null;
      }
    });
    await Promise.all(esperaLotes);

    return lotesGuias;
  }

  async function arquivoXml(e) {
    try {
      setAguarda(true);
      const reader = new FileReader();
      reader.readAsText(e.target.files[0]);
      reader.onloadend = async (event) => {
        const x2js = new X2JS();
        const resultado = event.target.result;
        const xml = resultado.replace(/ans:/g, "");
        const json = x2js.xml2js(xml);

        if (
          typeof json.mensagemTISS.operadoraParaPrestador
            ?.demonstrativosRetorno === "undefined"
        ) {
          return enqueueSnackbar("Importação não realizada.", {
            variant: "error",
          });
        }

        const { dadosProtocolo } =
          json.mensagemTISS.operadoraParaPrestador.demonstrativosRetorno
            .demonstrativoAnaliseConta.dadosConta;

        if (lotes) {
          setTela("agrupaLotes");
          const guias = capturaGuias(dadosProtocolo);
          const lotesEncontrados = await capturaLotes(guias);

          const agrupaLotesEncontrados = lotesEncontrados.reduce(
            (obj, atual) => {
              const vetorObj = obj;
              let existe = false;

              // if (typeof atual.lote !== 'undefined') {
              vetorObj.map((v, i) => {
                if (v.lote_plano === atual.lote_plano && v.id === atual.id) {
                  const arrayGlosas = [...vetorObj[i].glosas, ...atual.glosas];
                  vetorObj[i].valor_recuperado =
                    Number(vetorObj[i].valor_recuperado) +
                    Number(atual.valor_recuperado);
                  vetorObj[i].valor_liquido = vetorObj[i].valor_recuperado;
                  vetorObj[i].valor_glosado =
                    Number(vetorObj[i].valor_glosado) +
                    Number(atual.valor_glosado);
                  vetorObj[i].valor_diferenca_tabela =
                    Number(vetorObj[i].valor_diferenca_tabela) +
                    Number(atual.valor_diferenca_tabela);
                  vetorObj[i].valor_diferenca_unimed =
                    Number(vetorObj[i].valor_diferenca_unimed) +
                    Number(atual.valor_diferenca_unimed);
                  vetorObj[i].glosas = agrupaMotivos(arrayGlosas);
                  existe = true;
                }
                return null;
              });

              if (!existe) {
                if (atual.glosas.length > 0) {
                  // eslint-disable-next-line
                  atual.glosas = agrupaMotivos(atual.glosas);
                }

                vetorObj.push({
                  ...atual,
                  valor_imposto: 0,
                  valor_desconto_unimed: 0,
                  valor_acatado: 0,
                  em_recurso: 0,
                });
              }
              // }

              return vetorObj;
            },
            []
          );
          setRetornoLoteAgrupado(agrupaLotesEncontrados);
        } else {
          if (!dadosProtocolo?.numeroLotePrestador) {
            enqueueSnackbar("Lote não encontrado no XML.", {
              variant: "warning",
            });
            return;
          }
          if (dadosProtocolo?.numeroLotePrestador !== informacoesLote?.lote) {
            enqueueSnackbar(
              `Lote do XML (${dadosProtocolo?.numeroLotePrestador}) diferente do lote selecionado (${informacoesLote?.lote}).`,
              {
                variant: "warning",
                autoHideDuration: 5000,
              }
            );
            return;
          }
          // const arrayGlosas = verificaDiferencaTabela(
          //   dadosProtocolo.relacaoGuias
          // );
          // const novasLinhas = agrupaMotivos(arrayGlosas);
          const novaLinha = {
            id: Math.floor(Date.now() * Math.random()).toString(36),
            situacao: "Glosado",
            motivo: "",
            valor_recebido: dadosProtocolo?.valorGlosaProtocolo
              ? Number(dadosProtocolo?.valorGlosaProtocolo)
              : 0,
          };

          setGlosas((prevState) => [...prevState, novaLinha]);

          setAtualizaValoresRecebimentoManual((prevState) => !prevState);
          enqueueSnackbar("Importação finalizada.", { variant: "success" });
        }
        return null;
      };
      setAguarda(false);
    } catch (error) {
      setAguarda(false);
      enqueueSnackbar("Importação não realizada.", { variant: "error" });
    }
  }

  function verificaTipoArquivo(e) {
    if (e.target.files[0]) {
      if (
        e.target.files[0].type === "text/xml" ||
        e.target.files[0].type === "application/xml"
      ) {
        return arquivoXml(e);
      }
    }
    return enqueueSnackbar("Arquivo selecionado não é um XML válido.", {
      variant: "warning",
    });
  }

  return (
    <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
      <Stack direction="row" spacing={2}>
        <input
          id="contained-button-file"
          ref={fileInputRef}
          name="resultado"
          style={{ display: "none" }}
          type="file"
          accept="application/xml, text/xml"
          onChange={verificaTipoArquivo}
        />
        <Button
          style={{ margin: "0 1rem 1rem 0" }}
          variant="contained"
          onClick={(event) => {
            event.preventDefault();
            fileInputRef.current.click();
          }}
        >
          <Download />
          {nome}
        </Button>
      </Stack>
      <Waiter espera={aguarda} />
    </Box>
  );
}
