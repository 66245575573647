import React, { useEffect, useState, useContext } from "react";
import { useSnackbar } from "notistack";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Tooltip,
  Modal,
  Box,
  Button,
} from '@mui/material';
import { Add, Close, Menu } from '@mui/icons-material';

import { api } from "../../../services/Api";

import Horarios from "../Horarios";
import ModalAdicionar from "../ModalAdicionar";

import { Context as PermissoesContext } from "../../../Context/PermissoesContext";

import "./styles.css";

export default function Tabela(props) {
  const { mapas, setAtualizarTabela, visaoTelevisao, unidade } = props;

  const [modalAdicionar, setModalAdicionar] = useState(false);
  const [modalEditarMapa, setModalEditarMapa] = useState(false);
  const [mapaSelecionado, setMapaSelecionado] = useState({});
  const [tempoComparativo, setTempoComparativo] = useState(new Date());

  const [modalConfirmacao, setModalConfirmacao] = useState(false);

  const { checarPermissao } = useContext(PermissoesContext);
  const { enqueueSnackbar } = useSnackbar();
  const fecharModal = () => {
    setModalEditarMapa(false);
    setModalConfirmacao(false);
    setMapaSelecionado({});
  };

  const fecharModalConfirmacao = () => {
    setModalConfirmacao(false);
  };
  useEffect(() => {
    setAtualizarTabela(prev => !prev);
    // eslint-disable-next-line
  }, [modalAdicionar, modalEditarMapa, mapaSelecionado, tempoComparativo]);

  async function editaMapa(situacao) {
    try {
      await api.put(`/v2/mapaCurvas/mapa/${mapaSelecionado.id}`, { situacao });
      fecharModal();
      enqueueSnackbar(`Mapa ${situacao === "excluido" ? "excluído" : "concluído"}!`, {
        variant: "success",
        autoHideDuration: 3000,
      });
    } catch (error) {
      enqueueSnackbar("Erro ao editar o mapa!", {
        variant: "error",
        autoHideDuration: 3000,
      });
      console.log(error);
    }
  }
  useEffect(() => {
    const interval = setInterval(() => {
      setTempoComparativo(new Date());
    }, 5000);
    return () => clearInterval(interval);
  }, []);
  function excluirMapa() {
    setModalConfirmacao(true);
  }

  function corCard(mapa) {
    const proximaColeta = mapa.coletas.filter(el => !el.concluido);
    let dataColeta;
    let prazo;
    if (proximaColeta.length !== 0) {
      dataColeta = new Date(proximaColeta[0].hora);
      prazo = "";
    }
    if (proximaColeta.length === 0) {
      prazo = "ok";
    } else if ((dataColeta - tempoComparativo) / 60000 >= 5) {
      prazo = "ok";
    } else if (
      (dataColeta - tempoComparativo) / 60000 < 5 &&
      (dataColeta - tempoComparativo) / 60000 > 0
    ) {
      prazo = "proximo";
    } else if ((dataColeta - tempoComparativo) / 60000 <= 0) {
      prazo = "atrasado";
    }

    if (mapa.situacao === "concluido") {
      return "#aaffaa";
    } if (mapa.situacao === "pendente" && prazo === "proximo") {
      return "#ffe38f";
    } if (mapa.situacao === "pendente" && prazo === "atrasado") {
      return "#ff7b71";
    }
      return "white";

  }
  return (
    <>
      <Grid container spacing={1}>
        {!visaoTelevisao ? (
          <Grid
            item
            lg={2}
            md={3}
            sm={4}
            xs={12}
            key="adicionar_coleta"
            className="conteiner"
          >
            <Card
              className="adicionar_coleta"
              onClick={() => {
                if (checarPermissao("MC002") && !visaoTelevisao) {
                  setModalAdicionar(true);
                } else {
                  enqueueSnackbar("Você não possui a permissão para adicionar Mapas!", {
                    variant: "error",
                    autoHideDuration: 3000,
                  });
                }
              }}
            >
              <CardContent className="conteudo">
                <Typography className="card_adicionar">
                  <b>Adicionar Coleta</b>
                </Typography>
              </CardContent>
              <CardContent className="conteudo">
                <Add
                  className="botao_adicionar"
                  style={{ width: "1.5em", height: "1.5em" }}
                />
              </CardContent>
            </Card>
          </Grid>
        ) : null}

        {mapas.length > 0
          && mapas.map(mapa => (
            <Grid item lg={2} md={3} sm={4} xs={12} key={mapa.id} className="conteiner">
              <Card
                className="card"
                style={{
                    backgroundColor: corCard(mapa),
                  }}
              >
                <CardContent className="conteudo">
                  <Grid
                    container
                    spacing={1}
                    justify="space-between"
                    alignItems="center"
                    className="topo_card"
                  >
                    <Grid item xs={2} className="editar_mapa">
                      <Tooltip
                        title="Opções"
                        placement="top"
                        onClick={() => {
                            if (checarPermissao("MC003") && !visaoTelevisao) {
                              setMapaSelecionado(mapa);
                              setModalEditarMapa(true);
                            } else {
                              enqueueSnackbar(
                                "Você não possui a permissão para editar Mapas!",
                                {
                                  variant: "error",
                                  autoHideDuration: 3000,
                                }
                              );
                            }
                          }}
                      >
                        <Menu style={{ cursor: "pointer", marginRight: "5px" }} />
                      </Tooltip>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography className="poltrona_card">{mapa.poltrona}</Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    spacing={1}
                    justify-content="flex-start"
                    style={{
                        marginTop: "2px",
                        paddingLeft: "2px",
                        paddingRight: "2px",
                      }}
                  >
                    <Grid item xs={12}>
                      <Typography className="nome_paciente">
                        {mapa.nome_paciente}
                      </Typography>
                    </Grid>
                    {!visaoTelevisao && (
                      <Grid item xs={12}>
                        <Typography className="nome_exame_card">
                          {mapa.examesDetalhe.nome}
                        </Typography>
                      </Grid>
                      )}
                  </Grid>
                  <div className="horarios">
                    <Horarios
                      visaoTelevisao={visaoTelevisao}
                      setAtualizarTabela={setAtualizarTabela}
                      horarios={mapa.coletas}
                    />
                  </div>
                </CardContent>
              </Card>
            </Grid>
            ))}
      </Grid>

      {modalAdicionar && (
        <ModalAdicionar
          modalAdicionar={modalAdicionar}
          setModalAdicionar={setModalAdicionar}
          unidade={unidade}
        />
      )}

      <Modal
        open={modalEditarMapa}
        onClose={fecharModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modal_editar">
          <Grid container spacing={1} justify="center" alignItems="stretch">
            <Grid item xs={12}>
              <h1 className="modal-title">
                Editar Exame
                <Close
                  id="fechar"
                  onClick={() => {
                    fecharModal();
                  }}
                />
              </h1>
            </Grid>
            {mapaSelecionado.situacao !== "concluido" && (
              <Grid item xs={12}>
                <Button
                  onClick={() => {
                    const coletasPendentes = mapaSelecionado.coletas.filter(
                      el => !el.concluido
                    );
                    if (coletasPendentes.length === 0) {
                      editaMapa("concluido");
                    } else {
                      enqueueSnackbar("Ainda existem coletas pendentes!", {
                        variant: "error",
                        autoHideDuration: 3000,
                      });
                    }
                  }}
                  type="submit"
                  variant="contained"
                  color="primary"
                  margin="normal"
                  id="button"
                  fullWidth
                >
                  Marcar como concluído
                </Button>
              </Grid>
            )}
            <Grid item xs={12}>
              <Button
                type="submit"
                variant="contained"
                color="secondary"
                margin="normal"
                id="buttonIniciar"
                onClick={() => {
                  if (checarPermissao("MC005") && !visaoTelevisao) {
                    excluirMapa();
                  } else {
                    enqueueSnackbar("Você não possui a permissão para excluir Mapas!", {
                      variant: "error",
                      autoHideDuration: 3000,
                    });
                  }
                }}
                fullWidth
              >
                Excluir
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>

      <Modal
        open={modalConfirmacao}
        onClose={fecharModalConfirmacao}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modal_editar">
          <Grid container spacing={1} justify="center" alignItems="stretch">
            <Grid item xs={12}>
              <h1 className="modal-title">
                Deseja realmente Excluír esse cartão?
                <Close
                  id="fechar"
                  onClick={() => {
                    fecharModalConfirmacao();
                  }}
                />
              </h1>
            </Grid>
            <Grid item xs={12}>
              <div className="buttons">
                <Button
                  onClick={() => {
                    editaMapa("excluido");
                  }}
                  variant="contained"
                  color="primary"
                >
                  Sim
                </Button>
              </div>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
}
