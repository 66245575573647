import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Popover } from '@mui/material';
import ExitToAppIcon from "@mui/icons-material/ExitToApp";

import useUser from "../../hooks/useUser";

import { Context as AuthContext } from "../../Context/AuthContext";
import { api } from "../../services/Api";

import LogoDNA from "../../assets/logo_branca_dna.png";

import "./styles.css";
import { Box } from "@mui/material";

export default function Header({ titulo, sidebar }) {
  const usuario = useUser();
  const history = useHistory();

  const [userIconAnchor, setUserIconAnchor] = useState(null);
  const [preview, setPreview] = useState();

  const { logout } = useContext(AuthContext);

  useEffect(() => {
    async function chamaFuncao() {
      try {
        const resultImage = await api.get("/v2/controleAcesso/avatar", {
          params: { username: usuario },
          responseType: "blob",
        });

        if (resultImage.status !== 202) {
          setPreview(URL.createObjectURL(resultImage.data));
        }
      } catch (error) {
        console.log(error);
      }
    }
    chamaFuncao();
    // eslint-disable-next-line
  }, []);

  return (
    <Box
      component="header"
      sx={{
        position: "sticky",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        zIndex: 2,
      }}
    >
      <div className="cabecalho">
        <div style={{ display: "flex", alignItems: "center" }}>
          {sidebar || (
            <div
              onClick={() => history.push("/home")}
              id="logo"
              className="cabecalho-icones"
            >
              <img className="imgLogo" src={LogoDNA} alt="DNA Center" />
            </div>
          )}

          <div id="titulo">
            <span>{titulo}</span>
          </div>
        </div>

        <div className="cabecalho-icones">
          <div
            className="icone-usuario"
            onClick={e => setUserIconAnchor(e.currentTarget)}
          >
            {preview ? (
              <img className="imagem-usuario" src={preview} alt="icone do usuario" />
            ) : (
              usuario?.charAt(0) || '0'
            )}
          </div>
        </div>

        <Popover
          open={Boolean(userIconAnchor)}
          anchorEl={userIconAnchor}
          onClose={() => setUserIconAnchor(null)}
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        >
          <div className="acessos-header" onClick={() => history.push("/home")}>
            <span> Página inicial </span>
          </div>
          <div className="acessos-header" onClick={() => history.push("/perfil")}>
            <span> Perfil </span>
          </div>
          <div className="user-icon-row" onClick={() => logout()}>
            <p>Sair</p>
            <ExitToAppIcon />
          </div>
        </Popover>
      </div>
    </Box>
  );
}
