import React, { useState, useEffect } from "react";
import { useSnackbar } from "notistack";

import OpcoesTabela from "../OpcoesTabela";
import TabelaV2 from "../../../../../../componentsv2/Tabela";
import Box from "../../../../../../componentsv2/Box";

import DadosContext from "../../context/DadosContext";

import { api } from "../../../../../../services/Api";

const handleChangeCheckBox = () => {};

const columns = [
  {
    field: "cpf",
    headerName: "CPF",
    headerAlign: "center",
    align: "center",
    width: 200,
  },
  {
    field: "setor",
    headerName: "Setor",
    headerAlign: "center",
    valueGetter: ({ value }) => value.nome,
    align: "center",
    width: 150,
  },
  {
    field: "prazo",
    headerName: "Prazo",
    headerAlign: "center",
    align: "center",
    valueGetter: ({ value }) => value && new Date(value),
    width: 150,
    type: "dateTime",
  },
  {
    field: "clienteContatado",
    headerName: "Cliente Contatado",
    headerAlign: "center",
    align: "center",
    flex: 1,
    width: 100,
    type: "number",
    editable: false,
    sortable: false,
    disableClickEventBubbling: true,
    renderCell: (params) => (
      <input
        onChange={handleChangeCheckBox}
        type="checkbox"
        checked={params.row.clienteContatado}
      />
    ),
  },
  {
    field: "usuarioCadastro",
    headerName: "Usuário",
    valueGetter: ({ value }) => value.username,
    headerAlign: "center",
    align: "center",
    width: 100,
  },
  {
    field: "situacao",
    headerName: "Situação",
    headerAlign: "center",
    align: "center",
    width: 150,
  },
  {
    field: "tipoManifestacao",
    headerName: "Manifestação",
    headerAlign: "center",
    align: "center",
    width: 150,
  },
  {
    field: "opcoes",
    headerName: "Opções",
    headerAlign: "center",
    align: "center",
    flex: 1,
    width: 100,
    type: "number",
    editable: false,
    sortable: false,
    disableClickEventBubbling: true,
    renderCell: (params) => (
      <div style={{ cursor: "pointer" }}>
        <OpcoesTabela row={params.row} />
      </div>
    ),
  },
];

export default function Tabela({ setor, situacao, tipoManifestacao }) {
  const { enqueueSnackbar } = useSnackbar();

  const [rows, setRows] = useState([]);
  const [update, setUpdate] = useState(false);

  const atualizaTabela = () => {
    setUpdate(!update);
  };
  console.log(rows);
  const getRows = async () => {
    try {
      const {
        data: { data },
      } = await api.get("v2/atendimentoAoCliente/SAC", {
        params: { setorId: setor, situacao, tipoManifestacao },
      });
      setRows(data);
    } catch (error) {
      enqueueSnackbar("Erro ao tentar encontrar as perguntas do SAC", {
        variant: "error",
      });
    }
  };

  useEffect(() => {
    getRows();
    // eslint-disable-next-line
  }, [situacao, setor, update, tipoManifestacao]);

  return (
    <DadosContext.Provider value={{ atualizaTabela }}>
      <Box sx={{ height: 480, width: "100%" }}>
        <TabelaV2 columns={columns} rows={rows} autoPageSize />
      </Box>
    </DadosContext.Provider>
  );
}
