/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useContext } from "react";
import { Paper, Popover } from '@mui/material';
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import AttachFileIcon from "@mui/icons-material/AttachFile";

import { Context } from "../../Context/PermissoesContext";

import formatarData from "../../utils/formatarData";

import "./styles.css";

export default function CardTrilha({
  exame,
  abrirDialog,
  deletarExame,
  moveRight,
  moveLeft,
  moveUp,
  moveDown,
}) {
  const { checarPermissaoComAcao } = useContext(Context);
  const [popoverAnchor, setPopoverAnchor] = useState(null);
  return (
    <Paper className="card-trilha">
      <div className="card-trilha-texto">
        <p className="identifier">{exame.identificador || "-"}</p>
        <p style={{ fontStyle: "italic", fontSize: ".8rem" }}>
          {formatarData(new Date(exame.criado_em), false)} - {exame.horario.hora}:
          {exame.horario.minutos === 0 && "0"}
          {exame.horario.minutos}
        </p>
        <p>{exame.nome_cliente}</p>
        <p>{exame.celular_cliente}</p>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginTop: "7px",
            marginBottom: "12px",
          }}
        >
          <MoreHorizIcon
            onClick={e => setPopoverAnchor(e.target)}
            style={{ cursor: "pointer" }}
          />
          {exame.anexos.length > 0 && (
            <AttachFileIcon
              fontSize="small"
              title="Este exame possui anexos"
              style={{ marginLeft: "8px" }}
            />
          )}
        </div>

        <Popover
          open={Boolean(popoverAnchor)}
          onClose={() => setPopoverAnchor(null)}
          anchorEl={popoverAnchor}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <ul className="popover-more">
            <li
              style={{ marginBottom: "8px" }}
              onClick={() => abrirDialog(exame.id, "visualizar")}
            >
              1. Visualizar
            </li>
            <li
              style={{ marginBottom: "8px" }}
              onClick={() => checarPermissaoComAcao("AGP003", () => abrirDialog(exame.id, "editar"))}
            >
              2. Editar
            </li>
            <li
              onClick={() => checarPermissaoComAcao("AGP004", () => deletarExame(exame.id))}
            >
              3. Excluir
            </li>
          </ul>
        </Popover>
      </div>
      <div className="card-trilha-botoes">
        <ArrowRightAltIcon
          style={{ transform: "rotate(-90deg)" }}
          onClick={() => moveUp(exame.id)}
        />
        <ArrowRightAltIcon
          style={{ transform: "rotate(90deg)" }}
          onClick={() => moveDown(exame.id)}
        />
        <ArrowForwardIcon
          onClick={() => moveLeft(exame.id)}
          style={{ transform: "rotate(-180deg)" }}
        />
        <ArrowForwardIcon onClick={() => moveRight(exame.id)} />
      </div>
    </Paper>
  );
}
