import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import { format } from "date-fns";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  InputLabel,
  List,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Select,
  Tooltip,
  Container,
  ListItemButton,
} from "@mui/material";
import {
  Check,
  Visibility,
  Replay,
  Close,
  GridOn,
  ViewList,
  ArrowForward,
  ArrowBack,
  Save,
  Redo,
  Error,
  Download,
  Upload,
} from "@mui/icons-material";

import { Context } from "../../Context/PermissoesContext";

import Header from "../../components/Header";
import Waiter from "../../components/Backdrop";

import SalvaLog from "../../utils/SalvaLog";
import formataNumeracaoAmostra from "./utils/formataNumecaoAmostra";

import Tabela from "./Tabela";
import Formulario from "./Formulario";
import TabelaDialog from "./TabelaDialog";
import BI from "./BI";
import TabelaMapa from "./TabelaMapa";
import Abas from "./Abas";
import Pesquisa from "./Pesquisa";

import useUser from "../../hooks/useUser";

import "./styles.css";

import { api, baseUrl } from "../../services/Api";

export default function Amostras() {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const usuario = useUser();

  const { checarPermissao } = useContext(Context);

  useEffect(() => {
    async function inicio() {
      try {
        const {
          data: {
            permitido: {
              0: { id },
            },
          },
        } = await api.get(
          `/v2/controleAcesso/permissaoVisualizaModulo?username=${usuario}&&codigo=PCR001`
        );

        return id;
      } catch (e) {
        return history.push("/home");
      }
    }
    inicio();
  }, [history, usuario]);

  const status_amostra = [
    "Triagem",
    "Pré-Extração",
    "Extração",
    "PCR",
    "Processado",
  ];

  const [novaData, setNovaData] = useState(format(new Date(), "yyyy-MM-dd"));
  const [n, setN] = useState(0);

  const [tabela, setTabela] = useState(() =>
    checarPermissao("PCR002", false) ? "bi" : "placa"
  );
  const [status_atual, setStatus_atual] = useState("Triagem");
  const [status_pcr, setStatus_pcr] = useState("Preparação");

  const [adiciona_amostra, setAdiciona_amostra] = useState(false);
  const [mostra_mapa, setMostra_mapa] = useState(false);
  const [cria_mapa, setCria_mapa] = useState(false);
  const [mostra_historico, setMostra_historico] = useState(false);
  const [aba_historico, setAba_historico] = useState(false);

  const [codigo_amostra, setCodigo_amostra] = useState("");
  const [codigo_pedido, setCodigo_pedido] = useState("");
  const [resultado_pesquisa_amostra, setResultado_pesquisa_amostra] = useState(
    []
  );
  const [mapeado, setMapeado] = useState([]);
  const [mapa_pronto, setMapa_pronto] = useState(false);

  const [urgencia, setUrgencia] = useState(false);
  const [repeticao, setRepeticao] = useState(false);
  const [saliva, setSaliva] = useState(false);

  const [content, setContent] = useState([]);
  const [header_tittle, setHeader_tittle] = useState([
    "Nr Amostra",
    "Pedido",
    "Nome do Cliente",
  ]);

  const [placa, setPlaca] = useState([]);
  const [vetor_placa, setVetor_placa] = useState([]);
  const [placa_selecionada, setPlaca_selecionada] = useState("0-0");
  const [id_placa_selecionada, setId_placa_selecionada] = useState(0);
  const [qtd_amostra_selecionada, setQtd_amostra_selecionada] = useState(0);
  const [amostra, setAmostra] = useState([]);

  const [nova_placa, setNova_placa] = useState([]);
  const [novo_mapa, setNovo_mapa] = useState([]);
  const [nome_mapa, setNome_mapa] = useState("");
  const [nome_mapa_atual, setNome_mapa_atual] = useState("");
  const [nomeia_mapa, setNomeia_mapa] = useState(false);

  const [espera, setEspera] = useState(false);
  const [opcoes, setOpcoes] = useState(false);
  const [confirma_exclusao, setConfirma_exclusao] = useState(false);
  const [confirma_proxima_etapa, setConfirma_proxima_etapa] = useState(false);
  const [confirma_proxima_ou_regressao, setConfirma_proxima_ou_regressao] =
    useState(false);

  const [atualiza_placa, setAtualiza_placa] = useState(true);
  const [add_sample_map, setAdd_sample_map] = useState(false);
  const [remove_sample_map, setRemove_sample_map] = useState(false);

  const [contaAmostra, setContaAmostra] = useState(0);
  const [idAmostra, setIdAmostra] = useState(null);
  const [nAmostra, setNAmostra] = useState(null);
  const [seqAmostra, setSeqAmostra] = useState("");
  const [acao, setAcao] = useState("");
  const [vetorAdicionaAmostraAvulsa, setVetorAdicionaAmostraAvulsa] = useState(
    []
  );
  const [amostraAvulsa, setAmostraAvulsa] = useState([]);
  const [opcoesAmostraAvulsa, setOpcoesAmostraAvulsa] = useState(false);
  const [confirmaDividirPlaca, setConfirmaDividirPlaca] = useState(false);
  const [mudouNome, setMudouNome] = useState(false);

  const [amostras_separadas, setAmostras_separadas] = useState([]);

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // Funçao que carrega as tabelas da aba de placa ou mapa
  useEffect(() => {
    async function capturaDadosPlacas() {
      try {
        // Informaçoes das placa de acordo com o estado atual
        if (tabela === "placa") {
          const result1 = await api.get(
            `/api/amostras/placa/${novaData}/${status_atual}`
          );
          setPlaca(result1.data);
          // Informaçoes dos mapas de acordo com o estado atual
        } else if (tabela === "mapa") {
          const result2 = await api.get(
            `/api/amostras/mapa/${novaData}/${status_pcr}`
          );
          setPlaca(result2.data);
        } else {
          setPlaca([]);
        }
      } catch (error) {
        setPlaca([]);
        enqueueSnackbar("Falha na operação", {
          variant: "error",
          autoHideDuration: 3000,
        });
      }
    }

    capturaDadosPlacas();
  }, [
    enqueueSnackbar,
    tabela,
    novaData,
    atualiza_placa,
    status_atual,
    status_pcr,
  ]);

  // Temporizador que dispara a função de capturaDadosPlacas() a cada 30 segundos
  useEffect(() => {
    const intervalo = setInterval(() => {
      setAtualiza_placa((prevState) => !prevState);
    }, 30000);

    return () => clearInterval(intervalo);
  }, []);

  // Adiciona cada etapa que a placa passou
  async function adicionaHistorico(id, evento) {
    try {
      setEspera(true);
      await api.post(`/api/amostras/historico`, {
        id,
        usuario,
        evento,
      });

      setAtualiza_placa((prevState) => !prevState);
      SalvaLog(
        `Salvou no histórico o evento "${evento}" relacionado ao ID ${id}`,
        "Controle Amostras"
      );
    } catch (error) {
      SalvaLog(
        `Tentou salvar no histórico o evento "${evento}" relacionado ao ID ${id}`,
        "Controle Amostras"
      );
      enqueueSnackbar("Falha no histórico", {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
    setEspera(false);
  }

  // Prepara uma nova placa para a triagem
  async function montaPlaca() {
    try {
      setEspera(true);

      const { data } = await api.post(`/api/amostras/novaPlaca/${usuario}`);

      setNova_placa(data);
      adicionaHistorico(data.id, "Criou nova placa na triagem");

      setHeader_tittle(["Nr Amostra", "Pedido", "Nome do Cliente"]);
      SalvaLog(`Criou nova placa de ID ${data.id}`, "Controle Amostras");
      enqueueSnackbar("Placa preparada", {
        variant: "success",
        autoHideDuration: 1500,
      });
    } catch (error) {
      setNova_placa([]);
      SalvaLog(`Tentou criar nova placa`, "Controle Amostras");
      enqueueSnackbar("Falha na operação", {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
    setEspera(false);
  }

  // Abre placa que já foi criada e exibe todas as suas amostras na triagem
  async function editaPlaca() {
    try {
      setEspera(true);

      setNova_placa({ id: amostra.id, data_hora: amostra.data_hora });

      const { data } = await api.get(
        `/api/amostras/amostras_placa/${amostra.id}`
      );

      setContaAmostra(data.length || 0);
      setContent(data.reverse());

      setHeader_tittle(["Nr Amostra", "Pedido", "Nome do Cliente"]);
      enqueueSnackbar("Pronto", { variant: "success", autoHideDuration: 1500 });
    } catch (error) {
      setNova_placa([]);
      enqueueSnackbar("Falha na operação", {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
    setEspera(false);
  }

  // Pesquisa uma amostra pelo código do pedido ou pela numeração gerada na triagem
  async function pesquisaAmostra() {
    setEspera(true);

    try {
      const resultado = await api.get(
        `/api/amostras/pesquisaAmostra/${codigo_pedido}`
      );

      if (resultado.data.length > 0) {
        setResultado_pesquisa_amostra(resultado.data);
        enqueueSnackbar("Amostra encontrada", {
          variant: "success",
          autoHideDuration: 3000,
        });
      } else {
        setResultado_pesquisa_amostra([]);
        enqueueSnackbar("Amostra não encontrada", {
          variant: "warning",
          autoHideDuration: 3000,
        });
      }

      SalvaLog(
        `Pesquisou uma amostra com o código ${codigo_pedido}`,
        "Controle Amostras"
      );
      setCodigo_pedido("");
    } catch (error) {
      setEspera(false);
      setResultado_pesquisa_amostra([]);
      SalvaLog(
        `Tentou pesquisar uma amostra com o código ${codigo_pedido}`,
        "Controle Amostras"
      );

      if (codigo_pedido) {
        enqueueSnackbar("Falha na operação", {
          variant: "error",
          autoHideDuration: 3000,
        });
      } else {
        enqueueSnackbar("Campo de pesquisa vazio", {
          variant: "warning",
          autoHideDuration: 3000,
        });
      }
    }

    setEspera(false);
  }

  // Cria nova amostra
  async function criaAmostra() {
    try {
      setEspera(true);
      let tipo = "Normal";
      let material = "Swab";

      if (urgencia) {
        tipo = "Urgência";
      } else if (repeticao) {
        tipo = "Repetição";
      }

      if (saliva) {
        material = "Saliva";
      }

      const dados_cliente = await api.get(
        `/api/softlab/codigoAmostra/${codigo_amostra}?usuario=${usuario}`
      );

      const { data } = await api.post(
        `/api/amostras/novaAmostra/${nova_placa.id}`,
        {
          nome_cliente: dados_cliente.data.nome_cliente,
          codigo_pedido: dados_cliente.data.codigo_pedido,
          posto_pedido: dados_cliente.data.posto_pedido,
          codigo_tubo: dados_cliente.data.codigo_tubo,
          tipo,
          material,
          data: novaData,
          usuario,
        }
      );

      setContent(data);
      setCodigo_amostra("");

      setContaAmostra((prevState) => prevState + 1);

      SalvaLog(
        `Adicionou amostra de ID ${data.id} na placa de ID ${nova_placa.id}`,
        "Controle Amostras"
      );
      enqueueSnackbar("Amostra adicionada", {
        variant: "success",
        autoHideDuration: 1500,
      });
    } catch (error) {
      // eslint-disable-next-line
      const msg = error.response.data.mensagem
        ? error.response.data.mensagem
        : "Falha na operação";
      setCodigo_amostra("");
      SalvaLog(
        `Tentou adicionar uma amostra na placa de ID ${nova_placa.id}`,
        "Controle Amostras"
      );
      enqueueSnackbar(`${msg}`, { variant: "error", autoHideDuration: 3000 });
    }
    setEspera(false);
  }

  async function mostraAmostrasNoMapa(id_mp) {
    try {
      setEspera(true);
      const results = await api.get(`/api/amostras/sequencialMapa/${id_mp}`);
      setMapeado(results.data);
    } catch (error) {
      setMapeado([]);
      enqueueSnackbar("Falha na operação", {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
    setEspera(false);
  }

  const carregaArquivo = async (e) => {
    try {
      setEspera(true);
      const { id, nome_mapa: nome } = amostra;
      const fileObj = e.target.files[0];
      const formData = new FormData();
      formData.append("nomemapa", `${id}-${nome}`);
      formData.append("resultado", fileObj);

      await api.post("/api/uploadAmostras/resultadopcr", formData, {
        headers: {
          "content-type": "multipart/form-data",
        },
      });

      await api.put("/api/amostras/atualizaResultadosAmostras", {
        id,
        nome_mapa: nome,
        mapeado,
      });

      await mostraAmostrasNoMapa(id);
    } catch (error) {
      setEspera(false);
      enqueueSnackbar("Falha no carregamento do arquivo", { variant: "error" });
    }
    return setAnchorEl(false);
  };

  async function removeUltimaAmostra(id_amostra) {
    try {
      setEspera(true);

      if (id_amostra) {
        const { data } = await api.delete(
          `/api/amostras/deleta/${id_amostra}/?id_placa=${nova_placa.id}`
        );

        setContent(data);
        setCodigo_amostra("");

        setContaAmostra((prevState) => prevState - 1);

        document.getElementById("input-code-sample").focus();

        SalvaLog(
          `Removeu amostra de ID ${id_amostra} na placa de ID ${nova_placa.id}`,
          "Controle Amostras"
        );
        enqueueSnackbar("Amostra removida", {
          variant: "success",
          autoHideDuration: 1500,
        });
      } else {
        enqueueSnackbar("Placa vazia", {
          variant: "warning",
          autoHideDuration: 3000,
        });
      }
    } catch (error) {
      setCodigo_amostra("");
      SalvaLog(
        `Tentou remover amostra de ID ${id_amostra} na placa de ID ${nova_placa.id}`,
        "Controle Amostras"
      );
      enqueueSnackbar("Falha na operação", {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
    setEspera(false);
  }

  async function montaMapa() {
    try {
      setEspera(true);
      const result = await api.post(`/api/amostras/novoMapa/${usuario}`, {
        nome_mapa,
      });
      setNovo_mapa(result.data);
      setNome_mapa_atual(result.data.nome_mapa);

      const results = await api.post(
        `/api/amostras/sequencialMapa/${result.data.id}`
      );
      setMapeado(results.data);

      const vetor = await api.get(`/api/amostras/placa_pcr_disponivel`);
      setVetor_placa(vetor.data);

      SalvaLog(`Montou o mapa de ID ${result.data.id}`, "Controle Amostras");
      enqueueSnackbar("Mapa preparado", {
        variant: "success",
        autoHideDuration: 1500,
      });
    } catch (error) {
      setNovo_mapa([]);
      SalvaLog(`Tentou montar um mapa`, "Controle Amostras");
      enqueueSnackbar("Falha na operação", {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  }

  async function preparaMapa() {
    if (nome_mapa) {
      setEspera(true);
      await montaMapa();
      setCria_mapa(true);
      setNomeia_mapa(false);
      setEspera(false);
    } else {
      enqueueSnackbar("Nome vazio", {
        variant: "warning",
        autoHideDuration: 3000,
      });
    }
  }

  async function editaMapa() {
    try {
      setNovo_mapa({
        id: amostra.id,
        nome_mapa: amostra.nome_mapa,
        data_hora: amostra.data_hora,
      });
      setNome_mapa_atual(amostra.nome_mapa);

      const results = await api.get(
        `/api/amostras/sequencialMapa/${amostra.id}`
      );
      setMapeado(results.data);

      const vetor = await api.get(`/api/amostras/placa_pcr_disponivel`);
      setVetor_placa(vetor.data);

      SalvaLog(`Editou o mapa de ID ${amostra.id}`, "Controle Amostras");
      enqueueSnackbar("Mapa preparado", {
        variant: "success",
        autoHideDuration: 3000,
      });
    } catch (error) {
      setNovo_mapa([]);
      SalvaLog(`Tentou editar um mapa`, "Controle Amostras");
      enqueueSnackbar("Falha na operação", {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  }

  async function alteraNomeMapa() {
    setEspera(true);

    try {
      await api.put(`/api/amostras/alteraNomeMapa/${novo_mapa.id}`, {
        nome: nome_mapa_atual,
      });

      setAtualiza_placa((prevState) => !prevState);
      setMudouNome(false);

      SalvaLog(
        `Alterou o nome do mapa de ID ${novo_mapa.id}`,
        "Controle Amostras"
      );
      enqueueSnackbar("Nome do mapa alterado", {
        variant: "success",
        autoHideDuration: 3000,
      });
    } catch (error) {
      SalvaLog(`Tentou alterar o nome do mapa de ID`, "Controle Amostras");
      enqueueSnackbar("Falha na operação", {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
    setEspera(false);
  }

  async function fechaAdiciona_amostra() {
    setAtualiza_placa((prevState) => !prevState);
    await setAdiciona_amostra(false);
    setCodigo_amostra("");
    setContent([]);
    setNova_placa([]);
    setContaAmostra(0);
    setUrgencia(false);
  }

  async function atualizaStatusPlaca(id, statusPlaca) {
    try {
      setEspera(true);
      await api.put(`/api/amostras/novaPlaca/`, {
        id,
        status: statusPlaca,
      });

      setAtualiza_placa((prevState) => !prevState);
      SalvaLog(
        `Atualizou o status da(s) placa(s) de ID(s) ${id} para ${statusPlaca}`,
        "Controle Amostras"
      );
      enqueueSnackbar("Placa atualizada", {
        variant: "success",
        autoHideDuration: 3000,
      });
    } catch (error) {
      SalvaLog(
        `Tentou atualizar o status da(s) placa(s) de ID(s) ${id} para ${statusPlaca}`,
        "Controle Amostras"
      );
      enqueueSnackbar("Falha na operação", {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
    setEspera(false);
  }

  // Essa função recebe o ID do mapa, verifica as placas que possuem vínculo
  // E em seguida envia essas placas de PCR para Processado
  // E envia o mapa de Preparação para Em processamento
  async function enviaParaProcessamentoMapa(id) {
    try {
      setEspera(true);
      await api.put(`/api/amostras/processamentoMapa/${id}`, {
        usuario,
      });

      await api.put(`/api/amostras/salvaDataProcessamento/${id}`);

      setAtualiza_placa((prevState) => !prevState);
      SalvaLog(
        `Atualizou o status do mapa de ID ${id} para o status de: Em processamento`,
        "Controle Amostras"
      );
      enqueueSnackbar("Mapa atualizado", {
        variant: "success",
        autoHideDuration: 3000,
      });
    } catch (error) {
      SalvaLog(
        `Tentou atualizar o status do mapa de ID ${id} para o status de: Em processamento`,
        "Controle Amostras"
      );
      enqueueSnackbar("Falha na operação", {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
    setEspera(false);
  }

  async function alteraEstadoDeMapa(id, novoStatus) {
    try {
      setEspera(true);
      await api.put(`/api/amostras/novoMapa`, {
        id,
        status: novoStatus,
      });

      setAtualiza_placa((prevState) => !prevState);
      SalvaLog(
        `Atualizou o status do mapa de ID ${id} para Concluído`,
        "Controle Amostras"
      );
      enqueueSnackbar("Mapa atualizado", {
        variant: "success",
        autoHideDuration: 3000,
      });
    } catch (error) {
      SalvaLog(
        `Tentou atualizar o status do mapa de ID ${id} para Concluído`,
        "Controle Amostras"
      );
      enqueueSnackbar("Falha na operação", {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
    setEspera(false);
  }

  function fechaMostra_mapa() {
    setMostra_mapa(false);
    setStatus_pcr("Preparação");

    setAtualiza_placa((prevState) => !prevState);
  }

  async function abreHistorico() {
    setEspera(true);
    setOpcoes(false);
    setContent([]);

    try {
      const result = await api.get(
        `/api/amostras/amostras_placa/${amostra.id}`
      );

      setContent(result.data);

      setMostra_historico(true);
      setEspera(false);
    } catch (error) {
      setEspera(false);
      enqueueSnackbar("Falha na operação", {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  }

  function fechaMostra_historico() {
    setMostra_historico(false);
    setAmostra([]);
    setContent([]);
    setAmostras_separadas([]);
    setHeader_tittle(["Nr Amostra", "Pedido", "Nome do Cliente"]);
    setAba_historico(false);
  }

  async function exibeHistorico() {
    setEspera(true);
    setContent([]);

    try {
      const result = await api.get(`/api/amostras/historico/${amostra.id}`);

      setContent(result.data);

      setEspera(false);
    } catch (error) {
      setEspera(false);
      enqueueSnackbar("Falha na operação", {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  }

  async function adicionaPlacaNoMapa() {
    try {
      await api.put(`/api/amostras/adicionaPlaca/${novo_mapa.id}`, {
        id_placa: id_placa_selecionada,
        qtd: qtd_amostra_selecionada,
        usuario,
      });

      setPlaca_selecionada("0-0");
      setId_placa_selecionada("");
      setQtd_amostra_selecionada("");

      const vetor = await api.get(`/api/amostras/placa_pcr_disponivel`);
      setVetor_placa(vetor.data);

      await mostraAmostrasNoMapa(novo_mapa.id);

      SalvaLog(
        `Adicionou a placa de Id "${id_placa_selecionada}" ao mapa de ID ${novo_mapa.id}`,
        "Controle Amostras"
      );
      enqueueSnackbar("Placa adicionada no mapa", {
        variant: "success",
        autoHideDuration: 3000,
      });
    } catch (error) {
      SalvaLog(
        `Tentou adicionar a placa de Id "${id_placa_selecionada}" ao mapa de ID ${novo_mapa.id}`,
        "Controle Amostras"
      );
      enqueueSnackbar("Falha na operação", {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  }

  async function amostraAvulsaDisponivel() {
    try {
      setEspera(true);
      const disponivel = await api.get(`/api/amostras/amostras_pcr_disponivel`);

      if (disponivel.data.length < 1) {
        enqueueSnackbar("Nenhuma amostra disponível", {
          variant: "warning",
          autoHideDuration: 3000,
        });
      } else {
        setVetorAdicionaAmostraAvulsa(disponivel.data);
      }
    } catch (error) {
      enqueueSnackbar("Falha na operação", {
        variant: "error",
        autoHideDuration: 3000,
      });
    }

    setEspera(false);
  }

  function mapaPronto() {
    setMapa_pronto(true);
    mostraAmostrasNoMapa(amostra.id);
  }

  async function adicionaAmostraAvulsa() {
    try {
      setEspera(true);
      await api.patch(`/api/amostras/adicionaAmostraAvulsa/${idAmostra}`, {
        id_mapa: novo_mapa.id,
        sequencial: seqAmostra,
        usuario,
      });

      const vetor = await api.get(`/api/amostras/placa_pcr_disponivel`);
      setVetor_placa(vetor.data);

      mostraAmostrasNoMapa(novo_mapa.id);
      setAtualiza_placa((prevState) => !prevState);
      setSeqAmostra("");
      setIdAmostra("");

      enqueueSnackbar("Amostra adicionada", {
        variant: "success",
        autoHideDuration: 3000,
      });
    } catch (error) {
      setSeqAmostra("");
      setIdAmostra("");
      enqueueSnackbar("Falha na operação", {
        variant: "error",
        autoHideDuration: 3000,
      });
    }

    setAdd_sample_map(false);
    setNAmostra("");
    setSeqAmostra("");
    setEspera(false);
  }

  async function removeAmostraAvulsa() {
    try {
      setEspera(true);
      await api.patch(`/api/amostras/removeAmostraAvulsa/${idAmostra}`, {
        usuario,
        data: novaData,
      });

      const vetor = await api.get(`/api/amostras/placa_pcr_disponivel`);
      setVetor_placa(vetor.data);

      mostraAmostrasNoMapa(novo_mapa.id);
      setAtualiza_placa((prevState) => !prevState);

      enqueueSnackbar("Amostra removida", {
        variant: "success",
        autoHideDuration: 3000,
      });
    } catch (error) {
      enqueueSnackbar("Falha na operação", {
        variant: "error",
        autoHideDuration: 3000,
      });
    }

    setRemove_sample_map(false);
    setIdAmostra(null);
    setNAmostra(null);
    setEspera(false);
  }

  async function adiantaAmostra(id_Amostra) {
    try {
      setEspera(true);

      const result = await api.post(
        `/api/amostras/adiantaAmostra/${id_Amostra}`,
        {
          usuario,
          status: status_amostra[n + 1],
          idPlacaAtual: amostra.id,
        }
      );

      setContent(result.data);

      setAtualiza_placa((prevState) => !prevState);

      enqueueSnackbar("Amostra adiantada para próxima etapa", {
        variant: "success",
        autoHideDuration: 3000,
      });
    } catch (error) {
      enqueueSnackbar("Falha na operação", {
        variant: "error",
        autoHideDuration: 3000,
      });
    }

    setAmostraAvulsa([]);
    setOpcoesAmostraAvulsa(false);
    setEspera(false);
  }

  async function defineAmostraUrgente(id_Amostra) {
    try {
      setEspera(true);

      const result = await api.put(
        `/api/amostras/alteraTipoAmostra/${id_Amostra}`,
        {
          tipo: "Urgência",
          id_placa: amostra.id,
        }
      );

      setContent(result.data);

      setAtualiza_placa((prevState) => !prevState);

      enqueueSnackbar("Amostra definida como urgente", {
        variant: "success",
        autoHideDuration: 3000,
      });
    } catch (error) {
      enqueueSnackbar("Falha na operação", {
        variant: "error",
        autoHideDuration: 3000,
      });
    }

    setAmostraAvulsa([]);
    setOpcoesAmostraAvulsa(false);
    setEspera(false);
  }

  async function repeteAmostra() {
    try {
      setEspera(true);

      const dataAtual = format(new Date(), "yyyy-MM-dd");

      await api.post(`/api/amostras/repeteAmostra/${idAmostra}`, {
        usuario,
        data: dataAtual,
      });

      enqueueSnackbar("Amostra preparada para repetição", {
        variant: "success",
        autoHideDuration: 3000,
      });
    } catch (error) {
      const msg = error.response.data.mensagem
        ? error.response.data.mensagem
        : "Falha na operação";
      enqueueSnackbar(msg, { variant: "error", autoHideDuration: 3000 });
    }

    setRemove_sample_map(false);
    setIdAmostra("");
    setNAmostra("");
    setEspera(false);
  }

  function fechaCria_mapa() {
    setAtualiza_placa((prevState) => !prevState);
    setCria_mapa(false);
    setVetor_placa([]);
    setPlaca_selecionada("0-0");
    setId_placa_selecionada("");
    setQtd_amostra_selecionada("");
    setMapeado([]);
    setNome_mapa("");
  }

  async function removePlaca() {
    setEspera(true);

    try {
      await api.delete(`/api/amostras/removePlaca/${nova_placa.id}`);

      setConfirma_exclusao(false);
      fechaAdiciona_amostra();

      SalvaLog(
        `Removeu a placa de ID ${nova_placa.id} e suas amostras`,
        "Controle Amostras"
      );
      enqueueSnackbar("Placa excluída", {
        variant: "success",
        autoHideDuration: 3000,
      });
    } catch (error) {
      SalvaLog(
        `Tentou remover a placa de ID ${nova_placa.id}`,
        "Controle Amostras"
      );
      enqueueSnackbar("Falha na operação", {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
    setEspera(false);
  }

  async function removeMapa() {
    setEspera(true);

    try {
      await api.delete(`/api/amostras/removeMapa/${novo_mapa.id}`);

      setAtualiza_placa((prevState) => !prevState);

      setConfirma_exclusao(false);
      setMapa_pronto(false);
      fechaCria_mapa();

      SalvaLog(
        `Removeu o mapa de ID ${novo_mapa.id} e suas placas`,
        "Controle Amostras"
      );
      enqueueSnackbar("Mapa excluído", {
        variant: "success",
        autoHideDuration: 3000,
      });
    } catch (error) {
      SalvaLog(
        `Tentou remover o mapa de ID ${novo_mapa.id}`,
        "Controle Amostras"
      );
      enqueueSnackbar("Falha na operação", {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
    setEspera(false);
  }

  function pegaVetorIdAmostras() {
    const vetor_id = [];

    amostras_separadas.forEach((a) => {
      vetor_id.push(a.id);
    });

    return vetor_id;
  }

  async function dividePlaca() {
    setEspera(true);

    const vetor_id = pegaVetorIdAmostras();

    try {
      const result = await api.post(`/api/amostras/dividePlaca/${amostra.id}`, {
        usuario,
        status: status_atual,
        vetor_id,
      });

      setContent(result.data);
      setAtualiza_placa((prevState) => !prevState);
      setAmostras_separadas([]);
      setConfirmaDividirPlaca(false);

      SalvaLog(`Dividiu a placa de ID ${amostra.id}`, "Controle Amostras");
      enqueueSnackbar("Amostras separadas", {
        variant: "success",
        autoHideDuration: 3000,
      });
    } catch (error) {
      const msg = error.response.data.mensagem
        ? error.response.data.mensagem
        : "Falha na operação";
      enqueueSnackbar(msg, { variant: "error", autoHideDuration: 3000 });
      SalvaLog(
        `Tentou dividir a placa de ID ${amostra.id}`,
        "Controle Amostras"
      );
    }

    setEspera(false);
  }

  async function salvaResultadosExcel(dados) {
    setEspera(true);
    try {
      await api.put(`/api/amostras/salvaResultadoExcel/${amostra.id}`, {
        dados,
      });

      SalvaLog(
        `Adicionou o resultado das amostras do mapa de ID ${amostra.id}`,
        "Controle Amostras"
      );
    } catch (error) {
      SalvaLog(
        `Tentou adicionar o resultado das amostras do mapa de ID ${amostra.id}`,
        "Controle Amostras"
      );
    }
    setEspera(false);
  }

  async function concluiAnalise() {
    const resultadosExcel = mapeado;
    await salvaResultadosExcel(resultadosExcel);
    alteraEstadoDeMapa(amostra.id, "Concluído");
    setMapa_pronto(false);
    setMapeado([]);
  }

  function selecionaComponente() {
    if (tabela === "pesquisa") {
      return (
        <Pesquisa
          codigo_pedido={codigo_pedido}
          setCodigo_pedido={setCodigo_pedido}
          pesquisaAmostra={pesquisaAmostra}
          resultado_pesquisa_amostra={resultado_pesquisa_amostra}
        />
      );
    }
    if (tabela === "bi") {
      return <BI novaData={novaData} tabela={tabela} />;
    }

    return (
      <>
        <Abas
          tabela={tabela}
          setN={setN}
          status_atual={status_atual}
          setStatus_atual={setStatus_atual}
          status_pcr={status_pcr}
          setStatus_pcr={setStatus_pcr}
        />

        <Tabela
          tabela={tabela}
          status={status_amostra[n]}
          statusFuturo={status_amostra[n + 1]}
          placa={placa}
          setOpcoes={setOpcoes}
          setAmostra={setAmostra}
          mostra_mapa={mostra_mapa}
          atualizaStatusPlaca={atualizaStatusPlaca}
          adicionaHistorico={adicionaHistorico}
        />
      </>
    );
  }

  return (
    <>
      <Header titulo="Controle Amostras" />
        <div>
          <Container maxWidth="xl">
            <Formulario
              setNovaData={setNovaData}
              novaData={novaData}
              status_atual={status_atual}
              setAdiciona_amostra={setAdiciona_amostra}
              setCodigo_pedido={setCodigo_pedido}
              setMostra_mapa={setMostra_mapa}
              setNomeia_mapa={setNomeia_mapa}
              status_pcr={status_pcr}
              montaPlaca={montaPlaca}
              fechaMostra_mapa={fechaMostra_mapa}
              setResultado_pesquisa_amostra={setResultado_pesquisa_amostra}
              setTabela={setTabela}
              tabela={tabela}
              placa={placa}
            />

            {selecionaComponente()}
          </Container>
        </div>

        <Waiter espera={espera} />

        <Dialog
          id="menu-opcoes-table-samples"
          open={opcoes}
          onClose={() => {
            setAmostra([]);
            setOpcoes(false);
          }}
        >
          <DialogTitle id="selecionaOpcao">Selecione uma opção</DialogTitle>
          <List className="opList">
            {!mostra_mapa ? (
              <ListItemButton
                onClick={() => {
                  abreHistorico();
                }}
                key="11001"
              >
                <Visibility color="primary" />
                <InputLabel id="itemLista"> Visualizar</InputLabel>
              </ListItemButton>
            ) : null}

            {mostra_mapa ? (
              <ListItemButton
                onClick={() => {
                  mapaPronto();
                  setOpcoes(false);
                }}
                key="11021"
              >
                <GridOn color="primary" />
                <InputLabel id="itemLista"> Visualizar</InputLabel>
              </ListItemButton>
            ) : null}

            {(!mostra_mapa && status_atual === "Triagem") ||
            (mostra_mapa && status_pcr === "Preparação") ? (
              <ListItemButton
                onClick={async () => {
                  setEspera(true);
                  if (!mostra_mapa) {
                    await editaPlaca();
                    setAdiciona_amostra(true);
                  } else {
                    await editaMapa();
                    setCria_mapa(true);
                  }
                  setOpcoes(false);
                  setEspera(false);
                }}
                key="11011"
              >
                <ViewList color="primary" />
                <InputLabel id="itemLista">Editar</InputLabel>
              </ListItemButton>
            ) : null}

            {status_atual !== "Processado" &&
            status_atual !== "PCR" &&
            !mostra_mapa &&
            amostra.qtd_pedido > 0 ? (
              <ListItemButton
                onClick={() => {
                  setAcao("avançar");
                  setConfirma_proxima_ou_regressao(true);
                  setOpcoes(false);
                }}
                key="11041"
              >
                <ArrowForward color="primary" />
                <InputLabel id="itemLista"> Próxima Etapa</InputLabel>
              </ListItemButton>
            ) : null}

            {status_atual !== "Processado" &&
            status_atual !== "Triagem" &&
            !mostra_mapa ? (
              <ListItemButton
                onClick={() => {
                  setAcao("regredir");
                  setConfirma_proxima_ou_regressao(true);
                  setOpcoes(false);
                }}
                key="11061"
              >
                <ArrowBack color="primary" />
                <InputLabel id="itemLista"> Regredir Etapa</InputLabel>
              </ListItemButton>
            ) : null}

            <ListItemButton
              onClick={() => {
                setAmostra([]);
                setOpcoes(false);
              }}
              key="11051"
            >
              <Replay />
              <InputLabel id="itemLista"> Fechar</InputLabel>
            </ListItemButton>
          </List>
        </Dialog>

        <Dialog
          id="menu-opcoes-dialog-samples"
          open={opcoesAmostraAvulsa}
          onClose={() => {
            setAmostraAvulsa([]);
            setOpcoesAmostraAvulsa(false);
          }}
        >
          <DialogTitle id="selecionaOpcao">Selecione uma opção</DialogTitle>
          <List className="opList">
            {status_atual !== "PCR" &&
            status_atual !== "Processado" &&
            !aba_historico ? (
              <ListItemButton
                onClick={() => {
                  adiantaAmostra(amostraAvulsa.id);
                }}
                key="32101"
              >
                <Redo color="primary" />
                <InputLabel id="itemLista"> Adiantar Amostra</InputLabel>
              </ListItemButton>
            ) : (
              ""
            )}

            {status_atual !== "PCR" &&
            status_atual !== "Processado" &&
            amostraAvulsa.tipo === "Normal" ? (
              <ListItemButton
                onClick={() => {
                  defineAmostraUrgente(amostraAvulsa.id);
                }}
                key="32101"
              >
                <Error color="error" />
                <InputLabel id="itemLista"> Urgência</InputLabel>
              </ListItemButton>
            ) : (
              ""
            )}

            <ListItemButton
              onClick={() => {
                setAmostraAvulsa([]);
                setOpcoesAmostraAvulsa(false);
              }}
              key="32101"
            >
              <Replay />
              <InputLabel id="itemLista"> Fechar</InputLabel>
            </ListItemButton>
          </List>
        </Dialog>

        <Dialog
          id="container-add-samples"
          fullScreen
          fullWidth
          maxWidth="xl"
          open={adiciona_amostra}
          onClose={() => {
            fechaAdiciona_amostra();
          }}
        >
          <div className="background-add-samples">
            <div id="container-title-information-drive">
              <p />
              <span id="content-label-title">Preparar Placa - Triagem</span>
              <button
                type="button"
                id="button-dialog-back"
                onClick={() => {
                  fechaAdiciona_amostra();
                }}
              >
                <Close id="icon-back" fontSize="large" />
              </button>
            </div>

            <Container maxWidth="md">
              <div className="container-form-dialog-add-samples">
                <span className="content-form-dialog-add-samples">
                  <strong>Código: </strong>
                  {nova_placa.id}
                </span>
                <span className="content-form-dialog-add-samples">
                  <strong>Data/Hora: </strong>
                  {nova_placa.data_hora}
                </span>
              </div>

              <div id="container-input-code-sample">
                <input
                  id="input-code-sample"
                  // eslint-disable-next-line
                  autoFocus
                  autoComplete="off"
                  placeholder="Informe código da amostra"
                  type="text"
                  value={codigo_amostra}
                  onChange={(e) => {
                    setCodigo_amostra(e.target.value);
                  }}
                  onKeyUpCapture={(e) => {
                    if (e.keyCode === 13) {
                      criaAmostra();
                    }
                  }}
                />

                <div id="container-urgency-repetition">
                  <div className="content-check-samples">
                    <input
                      id="urgencia"
                      className="input-check-samples"
                      type="checkbox"
                      checked={urgencia}
                      onClick={() => {
                        setRepeticao(false);
                        setUrgencia((prevState) => !prevState);
                        document.getElementById("input-code-sample").focus();
                      }}
                    />

                    <span
                      id="label-urgencia"
                      className="label-add-samples"
                      htmlFor="urgencia"
                    >
                      {" "}
                      Urgência
                    </span>
                  </div>

                  <div className="content-check-samples">
                    <input
                      id="repeticao"
                      className="input-check-samples"
                      type="checkbox"
                      checked={repeticao}
                      onClick={() => {
                        setUrgencia(false);
                        setRepeticao((prevState) => !prevState);
                        document.getElementById("input-code-sample").focus();
                      }}
                    />

                    <span
                      id="label-repeticao"
                      className="label-add-samples"
                      htmlFor="repeticao"
                    >
                      {" "}
                      Repetição
                    </span>

                    <div className="content-check-samples">
                      <input
                        id="saliva"
                        className="input-check-samples"
                        type="checkbox"
                        checked={saliva}
                        onClick={() => {
                          setSaliva((prevState) => !prevState);
                          document.getElementById("input-code-sample").focus();
                        }}
                      />

                      <span
                        id="label-saliva"
                        className="label-add-samples"
                        htmlFor="saliva"
                      >
                        {" "}
                        Saliva
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div id="container-inputlabel-add-samples">
                <InputLabel id="label-number-add-samples">
                  <strong>Qtd. de Amostras: </strong> {contaAmostra}
                </InputLabel>
              </div>

              <TabelaDialog
                content={content}
                header_tittle={header_tittle}
                removeUltimaAmostra={removeUltimaAmostra}
                mostra_historico={mostra_historico}
              />

              <div id="container-buttons-dialog-add-samples">
                <Tooltip title="Remove a placa permanentemente">
                  <button
                    type="button"
                    id="button-remove-board"
                    onClick={() => {
                      setConfirma_exclusao(true);
                    }}
                  >
                    Remover a placa
                  </button>
                </Tooltip>
              </div>
            </Container>
          </div>
        </Dialog>

        <Dialog
          id="container-viewer-historic"
          fullWidth
          maxWidth="md"
          open={mostra_historico}
          onClose={() => fechaMostra_historico()}
        >
          <div className="background-add-samples">
            <div id="container-title-information-drive">
              <p />
              <span id="content-label-title">Visualiza Amostras e Eventos</span>
              <button
                type="button"
                id="button-dialog-back"
                onClick={() => fechaMostra_historico()}
              >
                <Close id="icon-back" fontSize="large" />
              </button>
            </div>

            <DialogContent>
              <div className="container-form-dialog-add-samples">
                <span className="content-form-dialog-add-samples">
                  <strong>Código: </strong>
                  {amostra.id}
                </span>
                <span className="content-form-dialog-add-samples">
                  <strong>Data/Hora: </strong>
                  {amostra.data_hora}
                </span>
              </div>

              <span>
                <button
                  type="button"
                  className={
                    !aba_historico
                      ? "button-menu-samples-on"
                      : "button-menu-samples"
                  }
                  onClick={async () => {
                    if (aba_historico) {
                      setAba_historico(false);
                      setHeader_tittle([
                        "Nr Amostra",
                        "Pedido",
                        "Nome do Cliente",
                      ]);
                      abreHistorico();
                    }
                  }}
                >
                  Amostras
                </button>

                <button
                  type="button"
                  className={
                    aba_historico
                      ? "button-menu-samples-on"
                      : "button-menu-samples"
                  }
                  onClick={async () => {
                    if (!aba_historico) {
                      setAba_historico(true);
                      setAmostras_separadas([]);
                      setHeader_tittle(["Data/Hora", "Usuário", "Evento"]);
                      exibeHistorico();
                    }
                  }}
                >
                  Histórico
                </button>
              </span>

              {status_atual === "PCR" ||
              status_atual === "Processado" ||
              aba_historico ? null : (
                <span>
                  <button
                    type="button"
                    className="button-menu-divide-samples"
                    onClick={async () => {
                      setConfirmaDividirPlaca(true);
                    }}
                  >
                    Dividir Placa
                  </button>
                </span>
              )}
            </DialogContent>

            <TabelaDialog
              content={content}
              header_tittle={header_tittle}
              mostra_historico={mostra_historico}
              setAmostraAvulsa={setAmostraAvulsa}
              setOpcoesAmostraAvulsa={setOpcoesAmostraAvulsa}
              setAmostras_separadas={setAmostras_separadas}
              amostras_separadas={amostras_separadas}
            />
          </div>
        </Dialog>

        <Dialog
          id="container-add-name-map"
          fullWidth
          maxWidth="xs"
          open={nomeia_mapa}
          onClose={() => {
            setNomeia_mapa(false);
            setNome_mapa("");
          }}
        >
          <div className="container-dialog-sample-map">
            <span className="label-tittle-dialog-sample-map">
              Cadastre um nome para o mapa
            </span>

            <input
              id="input-dialog-add-name-map"
              // eslint-disable-next-line
              autoFocus
              autoComplete="off"
              className="input-tool-control-samples"
              type="text"
              value={nome_mapa}
              placeholder="Nome do mapa"
              onChange={(e) => {
                setNome_mapa(e.target.value.toUpperCase());
              }}
            />

            <div className="container-buttons-dialog-sample-map">
              <button
                type="button"
                className="button-save-dialog-add-samples"
                onClick={() => {
                  preparaMapa();
                }}
              >
                Salvar
              </button>

              <button
                type="button"
                className="button-delete-dialog-add-samples"
                onClick={() => {
                  setNomeia_mapa(false);
                  setNome_mapa("");
                }}
              >
                Cancelar
              </button>
            </div>
          </div>
        </Dialog>

        <Dialog
          id="container-create-map"
          fullScreen
          fullWidth
          maxWidth="xl"
          open={cria_mapa}
          onClose={() => fechaCria_mapa()}
        >
          <div className="container-background-map-pcr">
            <div id="container-title-information-drive">
              <p />
              <span id="content-label-title">Preparar Mapa PCR</span>
              <button
                type="button"
                id="button-dialog-back"
                onClick={() => fechaCria_mapa()}
              >
                <Close id="icon-back" fontSize="large" />
              </button>
            </div>

            <Container maxWidth="lg">
              <div className="container-form-dialog-add-samples">
                <div id="container-edit-name-map">
                  <span className="content-form-dialog-add-samples">
                    <strong>Nome: </strong>
                  </span>
                  <input
                    id="input-edit-name-map"
                    style={{ color: mudouNome ? "red" : "black" }}
                    type="text"
                    autoComplete="off"
                    value={nome_mapa_atual}
                    onChange={(e) => {
                      if (
                        e.target.value.toUpperCase() !== novo_mapa.nome_mapa
                      ) {
                        setMudouNome(true);
                      } else {
                        setMudouNome(false);
                      }
                      setNome_mapa_atual(e.target.value.toUpperCase());
                    }}
                  />
                  <button
                    type="button"
                    id="button-edit-name-map"
                    onClick={() => {
                      if (mudouNome) {
                        alteraNomeMapa();
                      } else {
                        enqueueSnackbar(
                          "Nenhuma mudança no nome do mapa foi encontrada",
                          {
                            variant: "info",
                            autoHideDuration: 3000,
                          }
                        );
                      }
                    }}
                  >
                    <Save color="primary" />
                  </button>
                </div>
                <span className="content-form-dialog-add-samples">
                  <strong>Data/Hora: </strong>
                  {novo_mapa.data_hora}
                </span>
              </div>

              <div id="container-input-samples-map">
                <InputLabel id="inputlabel-select-placa">
                  Selecione as placas
                </InputLabel>
                <div id="container-select-map">
                  <Select
                    id="select-map"
                    value={placa_selecionada}
                    onChange={(e) => {
                      e.preventDefault();

                      const result = e.target.value.split("-");

                      setPlaca_selecionada(e.target.value);
                      setId_placa_selecionada(result[0]);
                      setQtd_amostra_selecionada(result[1]);
                    }}
                  >
                    <MenuItem key="0" value="0-0">
                      Nenhum selecionado
                    </MenuItem>
                    {vetor_placa.map((c) =>
                      c.qtd_pedido > 0 ? (
                        <MenuItem key={c.id} value={`${c.id}-${c.qtd_pedido}`}>
                          {`Placa ${c.id}  -  De ${formataNumeracaoAmostra(
                            c.menor_numero
                          )} a ${formataNumeracaoAmostra(c.maior_numero)}  -  ${
                            c.qtd_pedido
                          } Amostras  -  ${c.data_hora}`}
                        </MenuItem>
                      ) : null
                    )}
                  </Select>
                  <button
                    type="button"
                    id="button-map-add-samples"
                    onClick={async () => {
                      setEspera(true);
                      await adicionaPlacaNoMapa();
                      setEspera(false);
                    }}
                  >
                    <i
                      id="icon-button-map-add-samples"
                      className="fa fa-plus fa-2x"
                    />
                  </button>
                </div>
              </div>

              <TabelaMapa
                cria_mapa={cria_mapa}
                mostra_mapa={mostra_mapa}
                status_pcr={status_pcr}
                mapeado={mapeado}
                setMapeado={setMapeado}
                setAdd_sample_map={setAdd_sample_map}
                setRemove_sample_map={setRemove_sample_map}
                setIdAmostra={setIdAmostra}
                setNAmostra={setNAmostra}
                setSeqAmostra={setSeqAmostra}
                amostraAvulsaDisponivel={amostraAvulsaDisponivel}
              />

              {status_pcr === "Preparação" ? (
                <div id="container-buttons-dialog-add-samples">
                  <Tooltip title="Remove o mapa permanentemente">
                    <button
                      type="button"
                      id="button-remove-board"
                      onClick={() => {
                        setConfirma_exclusao(true);
                      }}
                    >
                      Remover o mapa
                    </button>
                  </Tooltip>
                </div>
              ) : (
                ""
              )}
            </Container>
          </div>
        </Dialog>

        <Dialog
          id="container-viewer-map"
          fullScreen
          fullWidth
          maxWidth="xl"
          open={mapa_pronto}
          onClose={() => {
            setMapa_pronto(false);
            setMapeado([]);
          }}
        >
          <div className="container-background-map-pcr">
            <div id="container-title-information-drive">
              <p />
              <span id="content-label-title">
                Visualiza Mapa PCR - {status_pcr}
              </span>
              <button
                type="button"
                id="button-dialog-back"
                onClick={() => {
                  setMapa_pronto(false);
                  setMapeado([]);
                }}
              >
                <Close id="icon-back" fontSize="large" />
              </button>
            </div>

            <Container maxWidth="lg">
              <div className="container-form-dialog-add-samples">
                <div>
                  <span className="content-form-dialog-add-samples">
                    <strong>Nome: </strong>
                    {amostra.nome_mapa}
                  </span>
                  <span className="content-form-dialog-add-samples">
                    <strong>Data/Hora: </strong>
                    {amostra.data_hora}
                  </span>
                </div>
                {mostra_mapa && status_pcr === "Preparação" ? (
                  <Button
                    variant="contained"
                    color="primary"
                    component="span"
                    style={{
                      backgroundColor: "#01DFA5",
                      marginTop: "0",
                      marginLeft: "5px",
                      width: "160px",
                      height: "40px",
                      border: "1px solid black",
                    }}
                    onClick={async () => setConfirma_proxima_etapa(true)}
                  >
                    Processar
                  </Button>
                ) : (
                  ""
                )}
                {mostra_mapa && status_pcr === "Em processamento" ? (
                  <Button
                    variant="contained"
                    color="primary"
                    component="span"
                    style={{
                      backgroundColor: "#01DFA5",
                      marginTop: "0",
                      marginLeft: "5px",
                      width: "160px",
                      height: "40px",
                      border: "1px solid black",
                    }}
                    onClick={async () => {
                      alteraEstadoDeMapa(amostra.id, "Em análise");
                      setMapa_pronto(false);
                      setMapeado([]);
                    }}
                  >
                    Analisar
                  </Button>
                ) : (
                  ""
                )}
                {mostra_mapa && status_pcr === "Em análise" ? (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      component="span"
                      style={{
                        backgroundColor: "#01DFA5",
                        marginTop: "0",
                        marginLeft: "5px",
                        width: "160px",
                        height: "40px",
                        border: "1px solid black",
                      }}
                      onClick={async () => concluiAnalise()}
                    >
                      <Check />
                      Finalizar
                    </Button>
                    <div>
                      <IconButton onClick={handleClick}>
                        <i className="fa fa-cog" style={{ color: "#003399" }} />
                      </IconButton>
                      <Menu
                        id="customized-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                      >
                        <MenuItem>
                          <label
                            htmlFor="contained-button-file"
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                          >
                            <input
                              id="contained-button-file"
                              name="resultado"
                              style={{ display: "none" }}
                              type="file"
                              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                              onChange={carregaArquivo}
                            />
                            <ListItemIcon>
                              <Upload fontSize="small" />
                            </ListItemIcon>
                            <ListItemText primary="Importar" />
                          </label>
                        </MenuItem>
                        <MenuItem>
                          <a
                            href={`${baseUrl}/api/amostrasDownload/downloadResultado/${amostra.id}-${amostra.nome_mapa}.xlsx`}
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              textDecoration: "none",
                            }}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <ListItemIcon>
                              <Download fontSize="small" />
                            </ListItemIcon>
                            <ListItemText primary="Exportar" />
                          </a>
                        </MenuItem>
                      </Menu>
                    </div>
                  </div>
                ) : (
                  ""
                )}

                <span
                  id="device-name-process-samples"
                  className="content-list-result-samples"
                >
                  APARELHO: ________________________________
                </span>
              </div>

              <TabelaMapa
                cria_mapa={cria_mapa}
                mostra_mapa={mostra_mapa}
                status_pcr={status_pcr}
                mapeado={mapeado}
                setIdAmostra={setIdAmostra}
                setNAmostra={setNAmostra}
                setRemove_sample_map={setRemove_sample_map}
                nomeMapa={`${amostra.id}-${amostra.nome_mapa}`}
              />

              <div id="container-list-result-samples">
                <span className="content-list-result-samples">
                  PLAQUEADO POR: _____________________________
                </span>
                <span className="content-list-result-samples">
                  ANALISADO POR: _____________________________
                </span>
                <span className="content-list-result-samples">
                  QUANTIDADE DE POSITIVOS: ___________________
                </span>
              </div>
            </Container>
          </div>
        </Dialog>

        <Dialog
          id="dialog-add-sample-map"
          fullWidth
          maxWidth="xs"
          open={add_sample_map}
          onClose={() => {
            setAdd_sample_map(false);
            setIdAmostra("");
            setVetorAdicionaAmostraAvulsa([]);
          }}
        >
          <div className="container-dialog-sample-map">
            <span className="label-tittle-dialog-sample-map">
              Qual o Nº da amostra que será adicionada?
            </span>

            <div id="container-select-add-sample-map">
              <Select
                id="select-add-sample-map"
                value={idAmostra}
                onChange={(e) => {
                  e.preventDefault();
                  setIdAmostra(e.target.value);
                }}
              >
                <MenuItem key="0" value="">
                  Selecione uma amostra
                </MenuItem>
                {vetorAdicionaAmostraAvulsa.map((a) => (
                  <MenuItem
                    id={
                      a.tipo === "Urgência"
                        ? "menuitem-sample-urgency"
                        : a.id_amostra
                    }
                    key={a.id_amostra}
                    value={a.id_amostra}
                  >
                    {`Amostra Nº ${a.numero} - ${a.sigla} - ${a.pedido}`}
                  </MenuItem>
                ))}
              </Select>
            </div>

            <div className="container-buttons-dialog-sample-map">
              <button
                type="button"
                className="button-save-dialog-add-samples"
                onClick={() => adicionaAmostraAvulsa()}
              >
                Adicionar
              </button>

              <button
                type="button"
                className="button-delete-dialog-add-samples"
                onClick={() => {
                  setAdd_sample_map(false);
                  setIdAmostra("");
                  setVetorAdicionaAmostraAvulsa([]);
                }}
              >
                Cancelar
              </button>
            </div>
          </div>
        </Dialog>

        <Dialog
          id="dialog-remove-sample-map"
          fullWidth
          maxWidth="xs"
          open={remove_sample_map}
          onClose={() => {
            setRemove_sample_map(false);
            setIdAmostra("");
            setNAmostra("");
          }}
        >
          <div className="container-dialog-sample-map">
            <span className="label-tittle-dialog-sample-map">
              {`Você deseja ${
                status_pcr === "Concluído" ? "repetir" : "remover"
              } a amostra de Nº ${nAmostra}?`}
            </span>
            <div className="container-buttons-dialog-sample-map">
              <button
                type="button"
                className="button-save-dialog-add-samples"
                onClick={() => {
                  if (status_pcr === "Preparação") {
                    removeAmostraAvulsa();
                  } else {
                    repeteAmostra();
                  }
                }}
              >
                Confirmar
              </button>

              <button
                type="button"
                className="button-delete-dialog-add-samples"
                onClick={() => {
                  setRemove_sample_map(false);
                  setIdAmostra("");
                  setNAmostra("");
                }}
              >
                Cancelar
              </button>
            </div>
          </div>
        </Dialog>

        <Dialog
          id="dialog-confirm-exclusion"
          open={confirma_exclusao}
          onClose={() => {
            setConfirma_exclusao(false);
          }}
        >
          <DialogTitle id="alert-dialog-title">
            {`Você deseja remover ${
              tabela === "placa" ? "esta placa" : "este mapa"
            }?`}
          </DialogTitle>

          <div id="container-buttons-dialog-add-samples">
            <button
              type="button"
              className="button-save-dialog-add-samples"
              onClick={() => {
                if (tabela === "placa") {
                  removePlaca();
                } else {
                  removeMapa();
                }
              }}
            >
              Confirmar
            </button>

            <button
              type="button"
              className="button-delete-dialog-add-samples"
              onClick={() => {
                setConfirma_exclusao(false);
              }}
            >
              Desistir
            </button>
          </div>
        </Dialog>

        <Dialog
          id="dialog-confirm-next-map"
          open={confirma_proxima_etapa}
          onClose={() => {
            setConfirma_proxima_etapa(false);
          }}
        >
          <DialogTitle id="alert-dialog-title">
            Confirma o envio deste mapa para processamento?
          </DialogTitle>

          <div id="container-buttons-dialog-add-samples">
            <button
              type="button"
              className="button-save-dialog-add-samples"
              onClick={() => {
                enviaParaProcessamentoMapa(amostra.id);
                setMapa_pronto(false);
                setMapeado([]);
                setConfirma_proxima_etapa(false);
              }}
            >
              Confirmar
            </button>

            <button
              type="button"
              className="button-delete-dialog-add-samples"
              onClick={() => {
                setConfirma_proxima_etapa(false);
              }}
            >
              Desistir
            </button>
          </div>
        </Dialog>

        <Dialog
          id="dialog-confirm-next-stage"
          open={confirma_proxima_ou_regressao}
          onClose={() => {
            setConfirma_proxima_ou_regressao(false);
          }}
        >
          <DialogTitle id="alert-dialog-title">
            {`Deseja realmente ${acao} etapa?`}
          </DialogTitle>

          <div id="container-buttons-dialog-add-samples">
            <button
              type="button"
              className="button-save-dialog-add-samples"
              onClick={() => {
                if (acao === "avançar") {
                  atualizaStatusPlaca(amostra.id, status_amostra[n + 1]);
                  adicionaHistorico(
                    amostra.id,
                    `Avançou a placa para ${status_amostra[n + 1]}`
                  );
                } else {
                  atualizaStatusPlaca(amostra.id, status_amostra[n - 1]);
                  adicionaHistorico(
                    amostra.id,
                    `Retornou a placa para ${status_amostra[n - 1]}`
                  );
                }
                setAmostra([]);
                setConfirma_proxima_ou_regressao(false);
              }}
            >
              Confirmar
            </button>

            <button
              type="button"
              className="button-delete-dialog-add-samples"
              onClick={() => {
                setConfirma_proxima_ou_regressao(false);
              }}
            >
              Desistir
            </button>
          </div>
        </Dialog>

        <Dialog
          id="dialog-confirm-divide-board"
          open={confirmaDividirPlaca}
          onClose={() => {
            setConfirmaDividirPlaca(false);
          }}
        >
          <DialogTitle id="alert-dialog-title">
            Deseja colocar as amostras abaixo em uma nova placa?
          </DialogTitle>

          <ul className="container-list-number-sample-divide">
            {amostras_separadas.map((a) => (
              <li className="list-number-sample-divide" key={a.id}>
                {a.numero}
              </li>
            ))}
          </ul>

          <div id="container-buttons-dialog-add-samples">
            <button
              type="button"
              className="button-save-dialog-add-samples"
              onClick={() => {
                dividePlaca();
              }}
            >
              Confirmar
            </button>

            <button
              type="button"
              className="button-delete-dialog-add-samples"
              onClick={() => {
                setConfirmaDividirPlaca(false);
              }}
            >
              Desistir
            </button>
          </div>
        </Dialog>
    </>
  );
}
