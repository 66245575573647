import React, { createContext, useState, useContext } from "react";

import { ColetaDomiciliarContext } from "./ColetaDomiciarContext";
import { FormularioColetaDomiciliarContext } from "./FormularioColetaDomiciliarContext";

const ModaisColetaDomiciliarContext = createContext();

function ModaisColetaDomiciliarContextProvider({ children }) {
  const [AbreDadosCliente, setAbreDadosCliente] = useState(false);
  const [menuStatus, setMenuStatus] = useState(false);
  const [visualizaCliente, setVisualizaCliente] = useState(false);
  const [visualizaPedido, setVisualizaPedido] = useState(false);
  const [opcao, setOpcao] = useState(false);
  const [modalPesquisa, setModalPesquisa] = useState(false);
  const [modalEditarDados, setModalEditarDados] = useState(false);
  const [modalValor_recebido, setModalValor_recebido] = useState(false);
  const [duplicata, setDuplicata] = useState(false);
  const [confirmar, setConfirmar] = useState(false);
  const [aviso, setAviso] = useState(false);
  const [recibo, setRecibo] = useState(false);
  const [modalMotivoBloqueio, setModalMotivoBloqueio] = useState(false);

  const [msg, setMsg] = useState("");

  const {
    coleta,
    setColeta,
    setColetador,
    setStatus,
    atualizaStatus,
    pagamento,
    enviaParaContasaReceber,
  } = useContext(ColetaDomiciliarContext);

  const {
    setExames,
    setCpf_cliente,
    setNome,
    setEmail,
    setValor_recebido,
    setTipo_pagamento,
    setNumero_cartao,
    setCodigoPedido,
    zeraCliente,
  } = useContext(FormularioColetaDomiciliarContext);

  function abreOpcoes(dados) {
    zeraCliente();
    setColeta(dados);
    setOpcao(true);
  }

  function abrirMenuStatus(novaColeta, novoStatus) {
    setColeta(novaColeta);
    setStatus(novoStatus);
    setMenuStatus(true);
  }

  function fecharMenuStatus() {
    setColeta("");
    setStatus("");
    setTipo_pagamento("");
    setNumero_cartao("");
    setValor_recebido("0.00");
    setCpf_cliente("");
    setNome("");
    setEmail("");
    setMenuStatus(false);
    setModalValor_recebido(false);
    setCodigoPedido("");
  }

  function abrirDetalheCliente(dados) {
    setColeta(dados);
    setVisualizaCliente(true);
  }

  function fechaVisualizaPedido() {
    setVisualizaPedido(false);
    setColeta("");
    setExames([]);
  }

  function abrirDetalheExame(dados) {
    setColeta(dados);
    setVisualizaPedido(true);
  }

  function fechaOpcoes() {
    setColeta("");
    setOpcao(false);
    fecharMenuStatus();
  }

  function fechaDuplicata() {
    setColetador("");
    setDuplicata(false);
  }

  function abreDuplicata() {
    setColetador(coleta.coletador);
    setDuplicata(true);
  }

  async function fazPagamento() {
    pagamento();
    setModalValor_recebido(false);
    if (
      !coleta?.pagou &&
      (coleta?.tipo_cliente === "Normal" || coleta?.tipo_cliente === "DNACOV")
    ) {
      await enviaParaContasaReceber();
    }
    fechaOpcoes();
  }

  function ativaAtualizaStatus() {
    atualizaStatus();
    setMenuStatus(false);
  }

  function fechaModalMotivoBloqueio() {
    setModalMotivoBloqueio(false);
  }

  return (
    <ModaisColetaDomiciliarContext.Provider
      value={{
        AbreDadosCliente,
        setAbreDadosCliente,
        confirmar,
        setConfirmar,
        menuStatus,
        setMenuStatus,
        visualizaCliente,
        setVisualizaCliente,
        visualizaPedido,
        setVisualizaPedido,
        opcao,
        setOpcao,
        abreOpcoes,
        fechaOpcoes,
        abrirMenuStatus,
        fecharMenuStatus,
        ativaAtualizaStatus,
        abrirDetalheCliente,
        abrirDetalheExame,
        fechaVisualizaPedido,
        modalPesquisa,
        setModalPesquisa,
        modalEditarDados,
        setModalEditarDados,
        modalValor_recebido,
        setModalValor_recebido,
        duplicata,
        setDuplicata,
        fechaDuplicata,
        abreDuplicata,
        msg,
        setMsg,
        aviso,
        setAviso,
        recibo,
        setRecibo,
        fazPagamento,
        modalMotivoBloqueio,
        setModalMotivoBloqueio,
        fechaModalMotivoBloqueio,
      }}
    >
      {children}
    </ModaisColetaDomiciliarContext.Provider>
  );
}

export { ModaisColetaDomiciliarContext, ModaisColetaDomiciliarContextProvider };
