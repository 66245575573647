import CloseIcon from "@mui/icons-material/Close";
import { Button as MuiButton, Container, Grid, Paper } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import React, { useContext, useEffect, useRef, useState } from "react";
import DataPickerAtualizado from "../../../../components/DataPickerAtualizado";
import { api, baseUrl } from "../../../../services/Api";
import { Select } from "../../../../componentsv2/Select";
import "../styles.css";
import AddIcon from "@mui/icons-material/Add";
import { useSnackbar } from "notistack";
import { Context as PermissoesContext } from "../../../../Context/PermissoesContext";
import { useHistory, useLocation } from "react-router-dom";
import Header from "../../../../components/Header";
import Button from "../../../../componentsv2/Button";
import { Modal } from "../../../../componentsv2/Modal";
import { Input } from "../../../../componentsv2/Input";
import SalvaLog from "../../../../utils/SalvaLog";

export default function FormContrato() {
  const history = useHistory();
  const location = useLocation();
  const edicaoContrato = location.state;
  // eslint-disable-next-line

  const [dataInit, setDataInit] = useState(new Date());

  const [estado, setEstado] = useState("");
  const [fornecedor, setFornecedor] = useState("");
  const [descricao, setDescricao] = useState("");
  const fileInputRef = useRef();
  const [fornecedores, setFornecedores] = useState([]);
  // const [anexo, setAnexo] = useState(null);
  // const [anexoRow, setAnexoRow] = useState("");

  const [openDialog, setOpenDialog] = useState(false);

  const [existeAnexoPrevio, setExisteAnexoPrevio] = useState([]);
  const [rowId, setRowId] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const { checarPermissao } = useContext(PermissoesContext);
  const [observacao, setObservacao] = useState("");
  const [loading, setLoading] = useState(false);
  const [listaAnexos, setListaAnexos] = useState([]);
  const [listaNomesDocumentos, setListaNomesDocumentos] = useState([]);
  const [valor, setValor] = useState(0);

  const [listaDocumentosEdicao, setListaDocumentosEdicao] = useState([]);

  const [existeContratoHoje, setExisteContratoHoje] = useState(false);

  const getUltimoContrato = async () => {
    try {
      const { data } = await api.get("v2/gestaoContratos/ultimoContrato", {
        params: {
          id_fornecedor: fornecedor,
        },
      });

      if (data?.id && !edicaoContrato) {
        setExisteContratoHoje(true);
        return;
      }

      setExisteContratoHoje(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!fornecedor) return;
    getUltimoContrato();
  }, [fornecedor]);

  const listaDeEstados = [
    "Pendente",
    "Conferido",
    "Autorizado",
    "Não Autorizado",
  ];

  useEffect(() => {
    if (edicaoContrato) {
      const [dia, mes, ano] = edicaoContrato.dados.data_vencimento.split("/");
      const dataFormatada = new Date(`${ano}-${mes}-${dia} 12:00:00`);
      const result = dataFormatada.setDate(dataFormatada.getDate() + 1);

      setDataInit(result);
      setEstado(edicaoContrato.dados.status);
      setFornecedor(edicaoContrato.dados.id_fornecedor);
      setDescricao(edicaoContrato.dados.descricao);
      setObservacao(edicaoContrato.dados.observacao);
      setValor(edicaoContrato.dados.valor ? edicaoContrato.dados.valor : 0);
      if (edicaoContrato.dados.anexos) {
        setListaNomesDocumentos(edicaoContrato.dados.anexos);

        setExisteAnexoPrevio(edicaoContrato.dados.anexos);
      }

      setRowId(edicaoContrato.dados.id);
    }
  }, [edicaoContrato]);

  const getUrls = async () => {
    try {
      if (edicaoContrato) {
        const result = await Promise.all(
          listaNomesDocumentos?.map(async (item) => {
            const response = await fetch(
              `${baseUrl}/static/v2/private/contratos/${item}?token=${api.defaults.headers.Authorization}`
            );
            const blob = await response.blob();

            return new File([blob], item, { type: "application/pdf" });
          })
        );

        setListaDocumentosEdicao(result);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getUrls();
    // eslint-disable-next-line
  }, [listaNomesDocumentos]);

  useEffect(() => {
    async function getFornecedores() {
      const { data } = await api.get(`v2/gestaoContratos/fornecedores`);
      setFornecedores(data);
    }
    getFornecedores();
  }, []);

  const clearFields = () => {
    setDataInit("");
    setEstado("");
    setFornecedor("");
    setDescricao("");
    setListaAnexos([]);

    //  setAnexo(null);
    //  setAnexoRow("");
    //setExisteAnexoPrevio([]);
  };
  const handleDelete = async () => {
    if (edicaoContrato) {
      try {
        await api.delete(
          `v2/gestaoContratos/contrato/${edicaoContrato.dados.id}`
        );
        //setUpdate(!update);
        enqueueSnackbar("Contrato excluído com sucesso!", {
          variant: "success",
          autoHideDuration: 3000,
        });
        history.push("/financeiro/contratos");
      } catch (error) {
        enqueueSnackbar("Erro ao tentar excluir!", {
          variant: "error",
          autoHideDuration: 3000,
        });
      }
    }
  };

  function contarCaracteresRepetidos(frase, caracter) {
    let contador = 0;
    for (let i = 0; i < frase.length; i++) {
      if (frase[i] === caracter) {
        contador++;
      }
    }
    return contador;
  }

  function verificarUIDnoNomeArquivo(nomeArquivo) {
    return /^[a-f\d]{8}-[a-f\d]{4}-[a-f\d]{4}-[a-f\d]{4}-[a-f\d]{12}_/.test(
      nomeArquivo
    );
  }

  function removerUIDdoNomeArquivo(nomeArquivo) {
    return nomeArquivo.replace(
      /^[a-f\d]{8}-[a-f\d]{4}-[a-f\d]{4}-[a-f\d]{4}-[a-f\d]{12}_/,
      ""
    );
  }

  const submit = async (e) => {
    e.preventDefault();
    const dataAtual = new Date();
    const dataConvertida = new Date(dataInit);
    dataAtual.setHours(0, 0, 0, 0);
    dataConvertida.setHours(0, 0, 0, 0);

    if (existeContratoHoje) {
      enqueueSnackbar("Já foi cadastrado um contrato para esta empresa hoje.", {
        variant: "warning",
        autoHideDuration: 3000,
      });

      return;
    }

    if (dataConvertida < dataAtual) {
      enqueueSnackbar(
        "A data da movimentação não pode ser menor que a data atual",
        {
          variant: "error",
          autoHideDuration: 3000,
        }
      );
      return;
    }
    try {
      setLoading(true);
      const formAnexo = new FormData();

      const valorConvertido = parseFloat(valor);

      let data = {};

      if (
        edicaoContrato &&
        (estado === "Não Autorizado" || estado === "Conferido")
      ) {
        data = {
          id_fornecedor: fornecedor,
          descricao,
          status: "Pendente",
          data_vencimento: dataInit,
          observacao,
          valor: valorConvertido,
        };
      } else {
        data = {
          id_fornecedor: fornecedor,
          descricao,
          status: edicaoContrato ? estado : "Pendente",
          data_vencimento: dataInit,
          observacao,
          valor: valorConvertido,
        };
      }

      const detalhe = JSON.stringify(data);

      if (edicaoContrato) {
        await api.put(`v2/gestaoContratos/contrato/${rowId}`, data);

        if (listaDocumentosEdicao.length > 0) {
          for (let i = 0; i < listaDocumentosEdicao.length; i++) {
            formAnexo.append(
              "anexos",

              listaDocumentosEdicao[i],

              verificarUIDnoNomeArquivo(listaDocumentosEdicao[i].name)
                ? `${listaDocumentosEdicao[i].name}`
                : `${rowId}_${listaDocumentosEdicao[i].name}`
            );
          }

          await api.put(`v2/gestaoContratos/contratoAnexo/${rowId}`, formAnexo);
        }

        SalvaLog("Editou um contrato", "Gestão de Contratos", detalhe);

        enqueueSnackbar("Contrato editado com sucesso!", {
          variant: "success",
          autoHideDuration: 3000,
        });
      } else {
        const result = await api.post(
          `v2/gestaoContratos/contrato/${fornecedor}`,
          data
        );

        if (listaAnexos.length > 0) {
          for (let i = 0; i < listaAnexos.length; i++) {
            formAnexo.append(
              "anexos",
              listaAnexos[i],
              `${result.data.id}_${listaAnexos[i].name}`
            );
          }

          await api.put(
            `v2/gestaoContratos/contratoAnexo/${result.data.id}`,
            formAnexo
          );
        }
        SalvaLog("Cadastrou um contrato", "Gestão de Contratos", detalhe);

        enqueueSnackbar("Contrato cadastrado com sucesso!", {
          variant: "success",
          autoHideDuration: 3000,
        });
      }

      clearFields();
      history.push("/financeiro/contratos");
    } catch (error) {
      enqueueSnackbar("Erro ao tentar cadastrar ou editar um contrato!", {
        variant: "error",
        autoHideDuration: 3000,
      });
    } finally {
      setLoading(false);
    }
  };

  const onClickAnexo = async (anexo, anexoObject) => {
    // um anexo pode tanto ser um arquivo local quanto um JSON retornado pela API.
    // portanto, devemos lidar com os dois casos
    const tempLink = document.createElement("a");
    tempLink.style = "display:none;";
    tempLink.target = "_blank";
    if (existeAnexoPrevio.includes(anexo)) {
      // se estamos aqui, eh um JSON enviado pela API representando um anexo
      tempLink.href = `${baseUrl}/static/v2/private/contratos/${anexo}?token=${api.defaults.headers.Authorization}`;
    } else {
      // se estamos aqui, eh um arquivo

      const url = URL.createObjectURL(anexoObject);

      tempLink.href = url;
    }
    tempLink.click();
    tempLink.remove();
  };

  const removerAnexo = async (indexARemover) => {
    if (
      edicaoContrato?.dados?.status === "Autorizado" ||
      edicaoContrato?.dados?.status === "Conferido"
    ) {
      return;
    }
    if (edicaoContrato) {
      const anexoRemovido = listaDocumentosEdicao?.filter(
        (element, index) => index !== indexARemover
      );

      setListaDocumentosEdicao(anexoRemovido);
    } else {
      const anexoRemovido = listaAnexos?.filter(
        (element, index) => index !== indexARemover
      );

      setListaAnexos(anexoRemovido);
    }
  };

  return (
    <>
      <Header titulo="Financeiro" />
      <Container maxWidth="md">
        <MuiButton onClick={() => history.push("/financeiro/contratos")}>
          <ArrowBackIcon style={{ color: "#003399" }} />
        </MuiButton>
        <Paper style={{ padding: "40px", marginTop: "10px" }} elevation={2}>
          <form onSubmit={submit}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} lg={12}>
                <Select
                  disabled={
                    edicaoContrato?.dados?.status === "Autorizado" ||
                    edicaoContrato?.dados?.status === "Conferido"
                  }
                  required
                  value={fornecedor}
                  label="Empresa"
                  variant="standard"
                  arrayOptions={fornecedores}
                  onChange={(e) => {
                    setFornecedor(e.target.value);
                  }}
                  valueAttribute="id"
                  textAttribute="nome"
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                {/* <Input
                  sx={{ marginTop: "5px" }}
                  value={descricao}
                  variant="standard"
                  onChange={(e) => setDescricao(e.target.value)}
                  maxRows={4}
                  multiline
                  type="text"
                  fullWidth
                  label="Descrição"
                /> */}
                <Select
                  disabled={
                    edicaoContrato?.dados?.status === "Autorizado" ||
                    edicaoContrato?.dados?.status === "Conferido"
                  }
                  sx={{ marginTop: "5px" }}
                  required
                  value={descricao}
                  label="Descrição"
                  variant="standard"
                  arrayOptions={["Contas a pagar", "Resumo do Financeiro"]}
                  onChange={(e) => setDescricao(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                {/* <CurrencyInput
                  prefix="$"
                  placeholder="Please enter a number"
                  decimalsLimit={2}
                  onValueChange={(value, name, values) =>
                    console.log(value, name, values)
                  }
                  //customInput={() => <Input variant="standard" label="Valor" />}
                /> */}
                <Input
                  type="number"
                  disabled={
                    edicaoContrato?.dados?.status === "Autorizado" ||
                    edicaoContrato?.dados?.status === "Conferido"
                  }
                  variant="standard"
                  label="Valor R$"
                  value={valor}
                  InputProps={{ inputProps: { step: 0.01 } }}
                  onChange={(event) =>
                    event.target.value < 0
                      ? (event.target.value = 0)
                      : setValor(event.target.value)
                  }
                />
              </Grid>
              <Grid item sx={{ marginTop: "10px" }} xs={12} sm={6} lg={6}>
                <DataPickerAtualizado
                  disabled={
                    edicaoContrato?.dados?.status === "Autorizado" ||
                    edicaoContrato?.dados?.status === "Conferido"
                  }
                  labelName="Movimento"
                  variant="standard"
                  data={dataInit}
                  setData={setDataInit}
                />
              </Grid>
              {edicaoContrato ? (
                <Grid item sx={{ marginTop: "10px" }} xs={12} sm={6} lg={6}>
                  <Select
                    required
                    value={estado}
                    label="Situação"
                    disabled={edicaoContrato}
                    variant="standard"
                    arrayOptions={listaDeEstados}
                    onChange={(e) => setEstado(e.target.value)}
                  />
                </Grid>
              ) : null}

              <Grid item xs={12}>
                <div className="anexos-dialog">
                  {edicaoContrato
                    ? listaDocumentosEdicao?.map((anexo, index) => {
                        return (
                          <div className="anexo" key={anexo.name}>
                            <span
                              className="filename"
                              onClick={() => {
                                if (!checarPermissao("GEC009")) {
                                  enqueueSnackbar(
                                    "Você não possui permissão para visualizar o documento!",
                                    {
                                      variant: "error",
                                      autoHideDuration: 3000,
                                    }
                                  );
                                  return;
                                }
                                onClickAnexo(anexo.name, anexo);
                              }}
                            >
                              {removerUIDdoNomeArquivo(anexo.name)}
                            </span>
                            <CloseIcon
                              style={{
                                cursor: "pointer",
                                marginLeft: "1rem",
                              }}
                              onClick={() => {
                                if (!checarPermissao("GEC009")) {
                                  enqueueSnackbar(
                                    "Você não possui permissão para visualizar o documento!",
                                    {
                                      variant: "error",
                                      autoHideDuration: 3000,
                                    }
                                  );
                                  return;
                                }
                                removerAnexo(index);
                              }}
                            />
                          </div>
                        );
                      })
                    : listaAnexos?.map((anexo, index) => (
                        <div className="anexo" key={anexo.name}>
                          <span
                            className="filename"
                            onClick={() => {
                              if (!checarPermissao("GEC009")) {
                                enqueueSnackbar(
                                  "Você não possui permissão para visualizar o documento!",
                                  {
                                    variant: "error",
                                    autoHideDuration: 3000,
                                  }
                                );
                                return;
                              }
                              onClickAnexo(anexo.name, anexo);
                            }}
                          >
                            {removerUIDdoNomeArquivo(anexo.name)}
                          </span>
                          <CloseIcon
                            style={{ cursor: "pointer", marginLeft: "1rem" }}
                            onClick={() => {
                              if (!checarPermissao("GEC009")) {
                                enqueueSnackbar(
                                  "Você não possui permissão para visualizar o documento!",
                                  {
                                    variant: "error",
                                    autoHideDuration: 3000,
                                  }
                                );
                                return;
                              }
                              removerAnexo(index);
                            }}
                          />
                        </div>
                      ))}

                  {/* {anexoRow ? (
                    
                  ) : null} */}

                  <input
                    disabled={
                      edicaoContrato?.dados?.status === "Autorizado" ||
                      edicaoContrato?.dados?.status === "Conferido"
                    }
                    accept="application/pdf"
                    ref={fileInputRef}
                    onChange={(event) => {
                      event.preventDefault();
                      const files = event.target.files;
                      if (files.length > 0) {
                        if (edicaoContrato) {
                          const newAnexos = [...listaDocumentosEdicao];
                          for (let i = 0; i < files.length; i++) {
                            newAnexos.push(files[i]);
                          }
                          setListaDocumentosEdicao(newAnexos);
                        } else {
                          const newAnexos = [...listaAnexos];
                          for (let i = 0; i < files.length; i++) {
                            newAnexos.push(files[i]);
                          }
                          setListaAnexos(newAnexos);
                        }
                      }
                    }}
                    className="input-image"
                    id="image-upload"
                    multiple // Permitir a seleção de múltiplos arquivos
                    type="file"
                    name="avatar_url"
                  />
                  <div
                    className="dialog-add-button"
                    disabled
                    onClick={(event) => {
                      event.preventDefault();
                      fileInputRef.current.click();
                    }}
                  >
                    <AddIcon fontSize="large" />
                    <span>Adicionar anexo(s)</span>
                  </div>
                </div>
              </Grid>
              {edicaoContrato ? (
                <Grid xs={12} item>
                  <Input
                    multiline
                    onChange={(e) => setObservacao(e.target.value)}
                    value={observacao}
                    fullWidth
                    label="Observação"
                    type="text"
                    variant="standard"
                    disabled
                  />
                </Grid>
              ) : null}

              {edicaoContrato?.dados?.status === "Autorizado" ||
              edicaoContrato?.dados?.status === "Conferido" ? null : (
                <Grid item container justifyContent="space-between" spacing={0}>
                  <Grid item>
                    {edicaoContrato ? (
                      <Button
                        onClick={() => {
                          if (checarPermissao("GEC008")) {
                            setOpenDialog(true);
                          } else {
                            enqueueSnackbar(
                              "Você não possui permissão para excluir um contrato!",
                              {
                                variant: "error",
                                autoHideDuration: 3000,
                              }
                            );
                          }
                        }}
                        color="red"
                        variant="outlined"
                      >
                        Excluir
                      </Button>
                    ) : (
                      <Button
                        onClick={() => history.push("/financeiro/contratos")}
                        color="red"
                        variant="outlined"
                      >
                        Cancelar
                      </Button>
                    )}
                  </Grid>
                  <Modal
                    open={openDialog}
                    onClose={setOpenDialog}
                    modalText="Tem certeza que deseja remover este contrato?"
                    onClick={handleDelete}
                    confirmActionButtonColor="red"
                    confirmActionButtonText="Remover"
                  />

                  <Grid item>
                    <Button
                      type="submit"
                      disabled={loading}
                      color="green"
                      variant="contained"
                    >
                      Salvar
                    </Button>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </form>
        </Paper>
      </Container>
    </>
  );
}