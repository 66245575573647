import React, { useContext } from "react";
import { TextField } from "@mui/material";
import "./styles.css";
import DadosContext from "../DadosContext";

export default function BarraDePesquisa() {
  const { setPesquisado } = useContext(DadosContext);

  const handleChangeSearch = e => {
    setPesquisado(e.target.value);
  };
  return (
    <TextField
      id="outlined"
      className="pesquisa"
      label="Pesquise por categoria"
      variant="outlined"
      size="small"
      onChange={e => {
        handleChangeSearch(e);
      }}
    />
  );
}
