import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { Context } from "../../../../Context/PermissoesContext";

import Paper from "../../../../componentsv2/Paper";
import GridContainer from "../../../../componentsv2/GridContainer";
import GridItem from "../../../../componentsv2/GridItem";
import Button from "../../../../componentsv2/Button";
import { useSnackbar } from "notistack";

export default function Cabecalho() {
  const history = useHistory();
  const { checarPermissao } = useContext(Context);
  const { enqueueSnackbar } = useSnackbar();

  const handleCadastrar = async () => {
    try {
      if (!checarPermissao("GDC002")) {
        return enqueueSnackbar("Erro! Você não tem permissão para cadastrar cliente!", {
          variant: "error",
        });
      }
      history.push({
        pathname: "/GestaoDeClientes/CadastroDeClientes",
      });
    } catch (error) {}
  };

  return (
    <Paper>
      <GridContainer sx={{ display: "flex", justifyContent: "flex-end" }}>
        <GridContainer sx={{ paddingLeft: 2 }} xs={12} md={8} spacing={2}></GridContainer>
        <GridItem className="paper-categoria" sm={2} xs={12}>
          <Button variant="contained" fullWidth onClick={handleCadastrar}>
            Cadastrar Cliente
          </Button>
        </GridItem>
      </GridContainer>
    </Paper>
  );
}
