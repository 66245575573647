import React, { useContext } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextareaAutosize } from '@mui/material';

import { ColetaDomiciliarBloqueioContext } from '../../../Context/ColetaDomiciarBloqueioContext';
import { ModaisColetaDomiciliarContext } from '../../../Context/ModaisColetaDomiciarContext';

export default function MotivoBloqueio() {
  const { setMotivo, criaBloqueio } = useContext(ColetaDomiciliarBloqueioContext);
  const { modalMotivoBloqueio, fechaModalMotivoBloqueio } = useContext(ModaisColetaDomiciliarContext);

  return (
    <Dialog
      open={modalMotivoBloqueio}
      onClose={fechaModalMotivoBloqueio}
      aria-labelledby="simple-dialog-title"
    >
      <DialogTitle id="selecionaOpcao">
        Informe o motivo do bloqueio
      </DialogTitle>

      <DialogContent>
        <Grid item xs={12} container spacing={2}>
          <Grid item xs={12}>
            <TextareaAutosize
              placeholder="Motivo"
              fullWidth
              minRows={2}
              maxRows={4}
              onChange={(e) => setMotivo(e.target.value)}
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions style={{ padding: "0.9rem" }}>
        <button
          type="button"
          className="btnDialogs"
          onClick={criaBloqueio}
        >
          Confirmar
        </button>
        <button
          type="button"
          className="btnDialogs"
          onClick={fechaModalMotivoBloqueio}
        >
          Desistir
        </button>
      </DialogActions>
    </Dialog>
  )
}
