import React, { useState, useContext } from "react";

import {
  Button as MuiButton,
  Container,
  Grid,
  Modal as MuiModal,
  Paper,
} from "@mui/material";
import SettingsIcon from '@mui/icons-material/Settings';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useSnackbar } from "notistack";
import Button from "../../../../../componentsv2/Button";
import { api } from "../../../../../services/Api";
import { Modal } from "../../../../../componentsv2/Modal";
import { Context as PermissoesContext } from "../../../../../Context/PermissoesContext";

export default function OpcoesTipoMaterial({
  row,
  setId,
  setTipoMaterialModal,
  setUpdate,
  update,
}) {
  const [open, setOpen] = useState(false);
  const [openModalConfirmacao, setOpenModalConfirmacao] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { checarPermissao } = useContext(PermissoesContext);

  function ModalOption(props) {
    const handleDelete = async () => {
      if (props.row.id) {
        try {
          await api.delete(`/v2/inventario/tipoMaterial/${props.row.id}`);
          setOpen(false);
          setUpdate(!update);
          enqueueSnackbar("Tipo de material excluído com sucesso!", {
            variant: "success",
            autoHideDuration: 3000,
          });
        } catch (error) {
          enqueueSnackbar("Erro ao tentar excluir o tipo de material!", {
            variant: "error",
            autoHideDuration: 3000,
          });
        }
      }
    };

    const handleClickEdit = () => {
      if (checarPermissao("INV012")) {
        setId(props.row.id);
        setTipoMaterialModal(true);
      } else {
        enqueueSnackbar("Você não possui permissão para editar um tipo de material!", {
          variant: "error",
          autoHideDuration: 3000,
        });
      }
    };
    return (
      <Container className="options-container" maxWidth="xs">
        <Paper className="options-paper" elevation={2}>
          <Grid container style={{ padding: "1rem 1rem" }}>
            <Grid item sm={12}>
              <Button
                onClick={handleClickEdit}
                variant="standard"
                startIcon={<EditIcon />}
              >
                Editar
              </Button>
            </Grid>
            <Grid item sm={12}>
              {props.row.ativo === true ? (
                <>
                  <Button
                    onClick={() => {
                      if (checarPermissao("INV013")) {
                        setOpenModalConfirmacao(true);
                      } else {
                        enqueueSnackbar(
                          "Você não possui permissão para excluir um tipo de material!",
                          {
                            variant: "error",
                            autoHideDuration: 3000,
                          }
                        );
                      }
                    }}
                    variant="standard"
                    startIcon={<DeleteIcon />}
                  >
                    Excluir
                  </Button>
                  <Modal
                    open={openModalConfirmacao}
                    confirmActionButtonText="Remover"
                    onClick={handleDelete}
                    confirmActionButtonColor="red"
                    onClose={setOpenModalConfirmacao}
                    modalText="Tem certeza que deseja remover este material?"
                  />
                </>
              ) : null}
            </Grid>
            <Grid item sm={12}>
              <Button
                onClick={() => setOpen(false)}
                variant="standard"
                startIcon={<ArrowBackIcon />}
              >
                Voltar
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Container>
    );
  }

  return (
    <>
      <MuiButton style={{ color: "#000" }} onClick={() => setOpen(true)}>
        <SettingsIcon />
      </MuiButton>
      <MuiModal open={open} onClose={() => setOpen(false)}>
        <ModalOption row={row} />
      </MuiModal>
    </>
  );
}
