import React, { useState, useEffect, useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Fornecedores from "./Fornecedores";
import Contratos from "./Contratos";

import { Context as PermissoesContext } from "../../Context/PermissoesContext";
import Header from "../../components/Header";
import Container from "../../componentsv2/Container";
import { enqueueSnackbar } from "notistack";

export default function GestaoDeContratos() {
  const [comp, setComp] = useState("ct");

  const history = useHistory();
  const { checarPermissao } = useContext(PermissoesContext);

  const location = useLocation();
  const stateComp = location.state;

  useEffect(() => {
    if (stateComp) {
      setComp(stateComp.tab);
    }
    if (!checarPermissao("FIN001")) {
      history.push("/home");
    }
    // eslint-disable-next-line
  }, [checarPermissao]);

  return (
    <>
      <Header titulo="Contratos" />
      <Container>
        <div style={{ paddingBottom: "0" }} id="container-aba-ctrl">
          <button
            type="button"
            id={comp === "ct" ? "button-aba-ctrl-samples-selected" : "Aba6"}
            className="button-aba-ctrl"
            style={{ fontSize: "16px" }}
            onClick={() => setComp("ct")}
          >
            Contratos
          </button>
          <button
            type="button"
            id={comp === "fr" ? "button-aba-ctrl-samples-selected" : "Aba6"}
            className="button-aba-ctrl"
            style={{ fontSize: "16px" }}
            onClick={() => {
              if (checarPermissao("GEC001")) {
                setComp("fr");
              } else {
                enqueueSnackbar(
                  "Você não possui permissão para acessar os inventários.",
                  {
                    variant: "error",
                  }
                );
              }
            }}
          >
            Empresas
          </button>
        </div>
        {comp === "fr" && <Fornecedores />}
        {comp === "ct" && <Contratos />}
      </Container>
    </>
  );
}
