import React, { useEffect } from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import useDadosContextEstoque from "../../hooks/useDadosContextEstoque";

export default function SelectLocal({
  label,
  value,
  setValue,
  locais,
  ativo = false,
}) {
  const { listaUnidades } = useDadosContextEstoque();

  const locaisUnicos = listaUnidades.reduce((acc, current) => {
    const x = acc.find((item) => item.unidade_id === current.unidade_id);
    if (!x) {
      return acc.concat([current]);
    } else {
      return acc;
    }
  }, []);

  return (
    <FormControl defaultValue=" " size="small" variant="standard" fullWidth>
      <InputLabel id="select-label">{label}</InputLabel>
      <Select
        value={value}
        onChange={(e) => setValue(e.target.value)}
        labelId="select-label"
        id="select"
        label="Ramal"
        disabled={ativo}
      >
        <MenuItem value="">Todas</MenuItem>
        {locaisUnicos.map((el) => (
          <MenuItem key={el.id} value={el.unidade_id}>
            {el.unidade}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
