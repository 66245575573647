import React from "react";
import { Box, Dialog, DialogTitle } from "@mui/material";
import Button from "../../../componentsv2/Button";

export default function ModalConfirmarConferirDocumento({
  open,
  onClose,
  onSubmit,
}) {
  return (
    <React.Fragment>
      <Dialog
        open={open}
        fullWidth
        maxWidth="sm"
        onClose={() => onClose(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle sx={{ paddingBottom: "0px", margin: 0, padding: 0 }}>
          Você confirma que o documento foi conferido corretamente?
        </DialogTitle>
        <form onSubmit={onSubmit}>
          <Box
            sx={{
              display: "flex",
              margin: "20px",
              justifyContent: "space-between",
              marginLeft: "15px",
              marginRight: "15px",
            }}
          >
            <Button
              variant="outlined"
              onClick={() => onClose(false)}
              type="button"
            >
              Cancelar
            </Button>
            <Button variant="contained" type="submit">
              Confirmar
            </Button>
          </Box>
        </form>
      </Dialog>
    </React.Fragment>
  );
}
