import React, { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import { DialogActions, TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import Dialog from "../../../../components/Dialog";
import Button from "../../../../components/Button";
import Loading from "../../../../components/Loading";

import { api } from "../../../../services/Api";

export default function SpecialtyDialog({
  initialValues,
  open,
  onClose,
  mode,
  addSpecialty,
  updateSpecialty,
  specialties,
}) {
  const [values, setValues] = useState(initialValues);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setValues(initialValues);
  }, [initialValues]);

  const closeDialog = () => {
    setValues({ nome: "" });
    onClose();
  };

  const validateSpecialty = () => {
    if (values.nome === "") {
      throw Error("Forneça um nome para a especialidade!");
    }
  };

  const createSpecialty = async () => {
    if (specialties.findIndex((s) => s.nome === values.nome) !== -1) {
      return enqueueSnackbar("Já existe uma especialidade com esse nome.", {
        variant: "error",
      });
    }
    try {
      const { data } = await api.post("/v2/portalMedico/especialidade", {
        nome: values.nome,
      });
      onClose();
      enqueueSnackbar("Especialidade criada com sucesso!", {
        variant: "success",
      });
      return addSpecialty(data);
    } catch (e) {
      return enqueueSnackbar("Não foi possível criar esta especialidade.", {
        variant: "error",
      });
    }
  };

  const editSpecialty = async () => {
    const { id, nome } = values;
    try {
      const { data } = await api.put(`/v2/portalMedico/especialidade/${id}`, {
        nome,
      });
      onClose();
      enqueueSnackbar("Especialidade editada com sucesso!", {
        variant: "success",
      });
      updateSpecialty(data);
    } catch (e) {
      enqueueSnackbar("Não foi possível editar esta especialidade.", {
        variant: "error",
      });
    }
  };

  const onSubmit = async () => {
    try {
      validateSpecialty();
    } catch (e) {
      return enqueueSnackbar(e.message, { variant: "error" });
    }

    setLoading(true);
    if (mode === "create") {
      await createSpecialty();
    } else {
      await editSpecialty();
    }
    return setLoading(false);
  };

  return (
    <>
      {loading && <Loading />}
      <Dialog
        title={`${mode === "create" ? "Criar" : "Editar"} especialidade`}
        open={open}
      >
        <CloseIcon
          style={{
            position: "absolute",
            top: "8px",
            right: "8px",
            cursor: "pointer",
          }}
          onClick={onClose}
        />
        <div style={{ marginBottom: "2rem" }}>
          <TextField
            fullWidth
            label="Nome da especialidade"
            value={values.nome}
            helperText="Escolha o nome convencional! Usuários pesquisarão por esse nome."
            onChange={(e) => {
              // e.persist();
              const {
                target: { value },
              } = e;
              setValues((prev) => ({ ...prev, nome: value }));
            }}
          />
        </div>
        <DialogActions>
          <Button secondary onClick={closeDialog}>
            Cancelar
          </Button>
          <Button secondary onClick={onSubmit}>
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
