import React, { useEffect, useState } from "react";

import TabelaV2 from "../../../../../../componentsv2/Tabela";
import Box from "../../../../../../componentsv2/Box";
import { api } from "../../../../../../services/Api";
import { enqueueSnackbar } from "notistack";
import OpcoesTabela from "../OpcoesTabela";


export default function TabelaMedicos() {
  const columns = [
    {
      field: "nome",
      headerName: "Médico",
      headerAlign: "center",
      align: "center",
      width: 500,
    },
    {
      field: "crm",
      headerName: "CRM",
      headerAlign: "center",
      align: "center",
      width: 200,
    },
    {
      field: "estado",
      headerName: "Estado",
      headerAlign: "center",
      align: "center",
      width: 100,
    },
    {
      field: "opcoes",
      headerName: "Opções",
      headerAlign: "center",
      align: "center",
      flex: 1,
      width: 20,
      type: "number",
      editable: false,
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (params) => (
        <div style={{ cursor: "pointer" }}>
          <OpcoesTabela atualizarTabela={atualizarTabela} row={params.row} />
        </div>
      ),
    },
  ];

  const [rows, setRows] = useState([]);
  const [atualizaTabela, setAtualizaTabela] = useState(false);

  const atualizarTabela = () => {
    setAtualizaTabela(!atualizaTabela);
  };

  const getMedicos = async () => {
    try {
      const {
        data: { data },
      } = await api.get("v2/agendaCare/medicos_care");
      setRows(data);
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Erro ao tentar encontrar médicos!", { variant: "error" });
    }
  };

  useEffect(() => {
    getMedicos();
  }, [atualizaTabela]);

  return (
    <Box sx={{ height: "600px", width: "100%", paddingTop: "15px" }}>
      <TabelaV2 ocultarToolbar columns={columns} rows={rows} autoPageSize />
    </Box>
  );
}
