import React from "react";
import { createRoot } from "react-dom/client";
import { SnackbarProvider } from "notistack";

import App from "./App";

import "./global.css";

const root = createRoot(document.getElementById("root"));

root.render(
  <SnackbarProvider
    maxSnack={3}
    anchorOrigin={{ horizontal: "bottom", vertical: "left" }}
    autoHideDuration={2500}
  >
    <App />
  </SnackbarProvider>
);
