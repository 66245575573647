import React, { useEffect, useState, useRef, useCallback } from "react";
import axios from "axios";
import { TextField, Button } from '@mui/material';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { useHistory, useLocation } from "react-router-dom";
import { useSnackbar } from "notistack";
import "./styles.css";

import useUser from "../../../hooks/useUser";

import Header from "../../../components/Header";
import Loading from "../../../components/Loading";
import DadosPaciente from "../../../components/DadosPaciente";

import { api } from "../../../services/Api";

export default function CadastroFormulario() {
  const usuario = useUser();

  const history = useHistory();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();

  const [valorInput, setValorInput] = useState("");
  const [pacienteSelecionado, setPacienteSelecionado] = useState(null);
  const [loading, setLoading] = useState(false);
  const [statusFormulario, setStatusFormulario] = useState("");
  const [formulario, setFormulario] = useState(null);
  const [modoVisualizacao, setModoVisualizacao] = useState(false);
  const [notificacaoID, setNotificacaoID] = useState(null);
  const [mostrarInput, setMostrarInput] = useState(false);

  const formRef = useRef(null);
  const inputRef = useRef(null);

  const pesquisarCliente = useCallback(
    async cpf => {
      /*
      Busca os dados do cliente e checa também se existe um formulário que não foi respondido
    */
      setMostrarInput(false);
      setLoading(true);
      let cliente;
      try {
        const { data } = await axios.get(
          `https://api.dnacenter.com.br/api/softlab/cliente/${cpf}`
        );
        cliente = data;
      } catch (e) {
        enqueueSnackbar("Um erro ocorreu ao procurar por esse paciente.", {
          variant: "error",
          autoHideDuration: 2000,
        });
        setPacienteSelecionado(null);
        setLoading(false);
        return;
      }

      let results;
      if (cliente !== "") {
        try {
          const { data } = await api.get(`/api/estadoFormulario/${cliente.cpf}`);
          results = data.results;
        } catch (e) {
          enqueueSnackbar("Erro de servidor.", {
            variant: "error",
            autoHideDuration: 2000,
          });
          setPacienteSelecionado(null);
          setLoading(false);
          return;
        }
        // nesse caso, não há formulário que ainda não foi preenchido. Ou seja, podemos cadastrar um novo.
        if (results.length === 0) {
          setStatusFormulario("cadastrar");
        } else {
          // existe um formulário que ainda não foi respondido
          setStatusFormulario("responder");
          setFormulario(results[0]);
        }
        setPacienteSelecionado({ ...cliente, nome_completo: cliente.nome });
      } else {
        setPacienteSelecionado(null);
        enqueueSnackbar("Não foi encontrado nenhum paciente com esse CPF.", {
          variant: "error",
          autoHideDuration: 3000,
        });
      }
      setLoading(false);
    },
    [enqueueSnackbar]
  );

  useEffect(() => {
    // caso tenhamos um query parameter do cpf, podems ja pesquisar o cliente
    const queryParams = new URLSearchParams(location.search);
    const cpf = queryParams.get("cpf");
    const visualizar = queryParams.get("visualizar");
    const notificacao = queryParams.get("notificacao");
    if (visualizar) {
      setModoVisualizacao(true);
    }
    if (cpf) {
      setValorInput(cpf);
      pesquisarCliente(cpf);
    }

    if (notificacao) {
      setNotificacaoID(notificacao);
    }
  }, [location.search, pesquisarCliente]);

  const cadastrarFormulario = async () => {
    let idFormulario;
    // const [dia, mes, ano] = pacienteSelecionado.data_nascimento.split("/");
    try {
      const {
        data: { id },
      } = await api.post("/api/formularioCovid", {
        cliente: {
          ...pacienteSelecionado,
          // data_nascimento: new Date(ano, mes - 1, dia),
        },
        usuario,
      });
      idFormulario = id;
      setFormulario({ id });
    } catch (e) {
      enqueueSnackbar(e.response.data.message, {
        variant: "error",
        autoHideDuration: 3000,
      });
      inputRef.current.focus();
      return;
    }

    // se temos uma notificacao atrelada, precisamos atualiza-la tambem
    if (notificacaoID) {
      try {
        await api.patch(
          `/api/esus/notificacoes/${notificacaoID}?formularioID=${idFormulario}`
        );
      } catch (error) {
        enqueueSnackbar(
          "Não foi possível atrelar esse formulário a notificação desejada. Por favor, procure suporte.",
          { variant: "error", autoHideDuration: 3000 }
        );
        return;
      }
    }

    enqueueSnackbar("Formulário cadastrado!", {
      variant: "success",
      autoHideDuration: 2000,
    });
    setStatusFormulario("responder");
  };

  useEffect(() => {
    // caso tenhamos um query parameter do cpf, podems ja pesquisar o cliente
    const queryParams = new URLSearchParams(location.search);
    const cpf = queryParams.get("cpf");
    const visualizar = queryParams.get("visualizar");
    if (visualizar) {
      setModoVisualizacao(true);
    }
    if (cpf) {
      setValorInput(cpf);
      pesquisarCliente(cpf);
    }
  }, [location.search, pesquisarCliente]);

  return (
    <>
      {loading && <Loading />}
      <Header
        titulo="Cadastro Formulário Covid"
        usuario={usuario}
        history={history}
      />
      <main className="cadastro-formulario-wrapper">
        <KeyboardBackspaceIcon
          fontSize="large"
          style={{ marginBottom: "1rem", cursor: "pointer" }}
          onClick={() => history.push("/formularioCovid")}
        />
        <form
          onSubmit={event => {
            event.preventDefault();
            pesquisarCliente(valorInput);
          }}
          ref={formRef}
        >
          {!modoVisualizacao && (
            <TextField
              inputRef={inputRef}
              autoFocus
              label="CPF do cliente"
              style={{ width: "100%" }}
              value={valorInput}
              onChange={event => {
                setValorInput(event.target.value);
              }}
            />
          )}
        </form>
        {pacienteSelecionado !== null && (
          <>
            {statusFormulario === "cadastrar" && (
              <Button
                onClick={cadastrarFormulario}
                style={{
                  backgroundColor: "#033399",
                  fontSize: "20px",
                  alignSelf: "flex-end",
                  marginTop: "2rem",
                }}
              >
                CADASTRAR
              </Button>
            )}
            {statusFormulario === "responder" && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "2rem",
                  justifyContent: mostrarInput ? "space-between" : "flex-end",
                  flexWrap: "wrap",
                }}
              >
                {mostrarInput && (
                  <TextField
                    value={`https://dnacenter.github.io/web/#/formularioCovid/${formulario.id}`}
                    readOnly
                    className="link-copy"
                  />
                )}
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                  }}
                >
                  <div
                    className="red-span"
                    onClick={async () => {
                      try {
                        await navigator.clipboard.writeText(
                          `https://dnacenter.github.io/web/#/formularioCovid/${formulario.id}`
                        );
                      } catch (e) {
                        setMostrarInput(true);
                        return;
                      }
                      enqueueSnackbar("Link copiado!", {
                        variant: "success",
                        autoHideDuration: 2000,
                      });
                    }}
                  >
                    Copiar link
                  </div>
                  <Button
                    onClick={() =>
                     ( window.open(
                        `https://dnacenter.github.io/web/#/formularioCovid/${formulario.id}?usuario_resposta=${usuario}`
                      ))}
                    style={{
                      backgroundColor: "#033399",
                      fontSize: "18px",
                      alignSelf: "flex-end",
                      marginLeft: "16px",
                    }}
                  >
                    Responder
                  </Button>
                </div>
              </div>
            )}
            <DadosPaciente
              paciente={pacienteSelecionado}
              className="dados-paciente-wrapper"
            />
          </>
        )}
      </main>
    </>
  );
}
