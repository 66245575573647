import * as React from "react";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

export default function CheckBoxWithLabel({
  checked,
  setChecked,
  label,
  labelPlacement,
}) {
  const handleChange = event => {
    setChecked(event.target.checked);
  };

  return (
    <FormGroup>
      <FormControlLabel
        control={<Checkbox checked={checked} onChange={handleChange} />}
        label={label}
        labelPlacement={labelPlacement}
      />
    </FormGroup>
  );
}
