import React, { useState, useContext } from "react";
import SettingsIcon from '@mui/icons-material/Settings';
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import EditIcon from "@mui/icons-material/Edit";
import  RemoveIcon from "@mui/icons-material/Delete";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";

import Button from "../../../../../../componentsv2/Button";
import GridItem from "../../../../../../componentsv2/GridItem";
import GridContainer from "../../../../../../componentsv2/GridContainer";
import Paper from "../../../../../../componentsv2/Paper";
import Container from "../../../../../../componentsv2/Container";
import ModalSettings from "../../../../../../componentsv2/ModalSettings";
import CheckBoxWithLabel from "../../../../../../componentsv2/CheckBoxWithLabel";
import { ModalForm } from "../../../../../../componentsv2/ModalForm";
import { Input } from "../../../../../../componentsv2/Input";
import { api } from "../../../../../../services/Api";
import DadosContext from "../../context/DadosContext";
import SelectSituacao from "../../../../components/SelectSituacao";
import SelectCanal from "../../../../components/SelectCanal";
import Visualizar from "../../pages/Visualizar";
import { Context as PermissoesContext } from "../../../../../../Context/PermissoesContext";

export default function OpcoesTabela({ row }) {
  const [open, setOpen] = useState(false);
  const [openSituacao, setOpenSituacao] = useState(false);
  const [openVisualizar, setOpenVisualizar] = useState(false);
  const [situacao, setSituacao] = useState(row.situacao);
  const [solucao, setSolucao] = useState(row.solucao);
  const [clienteContatado, setClienteContatado] = useState(row.clienteContatado);
  const [canal, setCanal] = useState(row.canal);
  const history = useHistory();
  const { atualizaTabela } = useContext(DadosContext);
  const { checarPermissao } = useContext(PermissoesContext);
  const { enqueueSnackbar } = useSnackbar();
  
  const openFormEditar = () => {
    if(checarPermissao("ATC007")){
      history.push({
        pathname: "/atendimentoAoCliente/formularioOuvidoria",
        state: { row },
      });
    }else{
      enqueueSnackbar("Você não possui permissão para editar!", { variant: "error" });
    }
  };

  const handleMudarSituacao = async () => {
    try {
      await api.put(`v2/atendimentoAoCliente/situacaoSac/${row.id}`, {
        situacao,
        solucao,
        clienteContatado,
        canal
      });
      enqueueSnackbar("Situação alterada com sucesso!", { variant: "success" });
    } catch (error) {
      enqueueSnackbar("Erro ao alterar situação!", { variant: "error" });
    } finally {
      atualizaTabela();
      setOpenSituacao(false);
      setOpen(false);
    }
  };

    const handleExcluir = async () => {
    try {
      await api.delete(`v2/atendimentoAoCliente/sac/${row.id}`);
      enqueueSnackbar("Exclusão realizada com sucesso!", { variant: "success" });
    } catch (error) {
      enqueueSnackbar("Erro ao tentar excluir!", { variant: "error" });
    } finally {
      atualizaTabela();
      setOpen(false);
    }
  };
  
  return (
    <>
      <Button style={{ color: "#000" }} onClick={() => setOpen(true)}>
        <SettingsIcon />
      </Button>
      <ModalSettings open={open} onClose={setOpen}>
        <Container className="options-container" maxWidth="xs">
          <Paper className="options-paper" elevation={2}>
            <GridContainer style={{ padding: "1rem 1rem" }}>
              <GridItem xs={12}>
                {openVisualizar && (
                  <Visualizar
                    row={row}
                    open={openVisualizar}
                    setOpen={setOpenVisualizar}
                  />
                )}

                <Button
                  onClick={() => {
                    setOpenVisualizar(true);
                  }}
                  variant="standard"
                  startIcon={<RemoveRedEyeIcon />}
                >
                  Visualizar
                </Button>
              </GridItem>
              <GridItem xs={12}>
                <Button variant="standard" onClick={()=>{openFormEditar()}} startIcon={<EditIcon />}>
                  Editar
                </Button>
              </GridItem>
              <GridItem xs={12}>
                <Button
                  onClick={() => {
                    if(checarPermissao("ATC007")){
                      setOpenSituacao(true)
                    }else{
                      enqueueSnackbar("Você não possui permissão para alterar a situação!", { variant: "error" });
                    }
                  }}
                  variant="standard"
                  startIcon={<ChangeCircleIcon />}
                >
                  Alterar Situação
                </Button>
              </GridItem>
              <GridItem xs={12}>
                <Button
                  onClick={() => {
                    if(checarPermissao("ATC009")){
                      handleExcluir()
                    }else{
                      enqueueSnackbar("Você não possui permissão para excluír!", { variant: "error" });
                    }
                  }}
                  variant="standard"
                  startIcon={<RemoveIcon />}
                >
                  Excluir
                </Button>
              </GridItem>
              <GridItem xs={12}>
                <Button
                  variant="standard"
                  startIcon={<ArrowBackIcon />}
                  onClick={() => setOpen(false)}
                >
                  Voltar
                </Button>
              </GridItem>
            </GridContainer>
          </Paper>
        
          <ModalForm
            onSubmit={handleMudarSituacao}
            open={openSituacao}
            onClose={setOpenSituacao}
            title={"Alterar situação"}
          >
            <GridItem xs={12} sm={4}>
              <SelectSituacao
                todas={false}
                value={situacao}
                onChange={e => {
                  setSituacao(e.target.value);
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={4}>
              <SelectCanal
                todos={false}
                value={canal}
                onChange={e => {
                  setCanal(e.target.value);
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={4}>
              <CheckBoxWithLabel
                label="O cliente foi contatado?"
                checked={clienteContatado}
                setChecked={setClienteContatado}
                labelPlacement="start"
              />
            </GridItem>
            <GridItem xs={12}>
              <Input
                  fullWidth 
                  value={solucao}
                  onChange={e => {
                    setSolucao(e.target.value);
                  }} 
                  label="Solução" 
                  placeholder="Digite a solução aqui..." 
                  multiline
                  rows={4}
                />
            </GridItem>
          </ModalForm>
        </Container>
      </ModalSettings>
    </>
  );
}
