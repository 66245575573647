import { Button } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { Context as PermissoesContext } from "../../../../Context/PermissoesContext";

export default function AdicionarProduto() {
  const history = useHistory();
  const { checarPermissao } = useContext(PermissoesContext);

  const { enqueueSnackbar } = useSnackbar();

  async function abrirCadastroProduto() {
    if (await !checarPermissao("AL004")) {
      enqueueSnackbar("É necessário a permissão AL004", {
        variant: "warning",
      });
      history.push("/almoxarifado/produtos");
    } else {
      history.push("/almoxarifado/produtos/CadastraProduto");
    }
  }

  return (
    <>
      <Button
        fullWidth
        variant="contained"
        style={{ color: "#fff", backgroundColor: "#003399" }}
        onClick={() => abrirCadastroProduto()}
      >
        Cadastrar Produto
      </Button>
    </>
  );
}
