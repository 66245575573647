import React, { useEffect, useState } from "react";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Button from "../../../../componentsv2/Button";
import Paper from "../../../../componentsv2/Paper";

import GridContainer from "../../../../componentsv2/GridContainer";
import GridItem from "../../../../componentsv2/GridItem";
import Header from "../../../../components/Header";
import Container from "../../../../componentsv2/Container";
import { Input } from "../../../../componentsv2/Input";
import SelectEstado from "../../../../componentsv2/SelectEstado";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import { enqueueSnackbar } from "notistack";
import { api } from "../../../../services/Api";

export default function EditarMedico() {
  const history = useHistory();
  const location = useLocation();
  const care = location?.state;

  const [nome, setNome] = useState("");
  const [crm, setCrm] = useState("");
  const [estado, setEstado] = useState("");
  const [especialidade, setEspecialidade] = useState("");

  const handleNavigate = () => {
    if (care) {
      return history.push("/agendaCare");
    }
    history.push("/agendaCare");
  };

  const pegarDadosAgendamento = async () => {
    //setLoading(true)
    if (location.state?.row) {
      try {
        const {
          data: { data },
        } = await api.get(`v2/agendaCare/medico_care/${location.state.row.id}`);
        setNome(data.nome);
        setCrm(data.crm);
        setEstado(data.estado);
        setEspecialidade(data.especialidade);
      } catch (error) {
        enqueueSnackbar("Erro ao tentar encontrar os dados.", {
          variant: "error",
        });
      }
    } else {
      history.push("/agendaCare");
    }
    //setLoading(false)
  };

  useEffect(() => {
    pegarDadosAgendamento();
    // eslint-disable-next-line
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const data = {
        nome,
        crm,
        estado,
      };
      await api.put(
        `/v2/agendaCare/editar_medico_care/${location.state.row.id}`,
        data
      );
      handleNavigate();
      enqueueSnackbar("Médico atualizado com sucesso!.", {
        variant: "success",
      });
    } catch (error) {
      enqueueSnackbar("Erro! Não foi possível editar os dados.", {
        variant: "error",
      });
    }
  };

  return (
    <>
      <Header titulo={"Cadastrar Médico"} />
      <Container>
        <Paper
          style={{
            padding: "1rem",
            alignItems: "center",
            display: "flex",
            justfyContent: "center",
            marginTop: "10px",
          }}
          elevation={2}
        >
          <form
            onSubmit={handleSubmit}
            style={{ width: "99%", display: "flex", flexDirection: "column" }}
          >
            <GridContainer>
              <Button>
                <ArrowBackIcon
                  style={{ color: "#003399" }}
                  onClick={() => history.push({ pathname: "/agendaCare" })}
                />
              </Button>
            </GridContainer>
            <GridContainer sx={{ margin: "1px" }} spacing={2} xs={12}>
              <GridItem xs={12} md={3}>
                <Input
                  required
                  fullWidth
                  label="Nome"
                  type="text"
                  variant="standard"
                  onChange={(e) => setNome(e.target.value)}
                  value={nome}
                />
              </GridItem>
              <GridItem xs={12} md={3}>
                <Input
                  required
                  fullWidth
                  label="Especialidade"
                  type="text"
                  variant="standard"
                  onChange={(e) => setEspecialidade(e.target.value)}
                  value={especialidade}
                />
              </GridItem>
              <GridItem xs={12} md={3}>
                <Input
                  required
                  fullWidth
                  label="CRM"
                  type="text"
                  variant="standard"
                  onChange={(e) => setCrm(e.target.value)}
                  value={crm}
                />
              </GridItem>
              <GridItem xs={12} md={3}>
                <SelectEstado
                  required
                  onChange={(e) => setEstado(e.target.value)}
                  value={estado}
                />
              </GridItem>
            </GridContainer>
            <GridContainer
              item
              sx={{ marginTop: "30px" }}
              justifyContent="flex-end"
              spacing={2}
            >
              <GridItem>
                <Button
                  color="red"
                  variant="outlined"
                  onClick={() => history.push({ pathname: "/agendaCare" })}
                >
                  Cancelar
                </Button>
              </GridItem>
              <GridItem>
                <Button
                  id="agendarButton"
                  type="submit"
                  color="green"
                  variant="contained"
                >
                  Salvar
                </Button>
              </GridItem>
            </GridContainer>
          </form>
        </Paper>
      </Container>
    </>
  );
}
