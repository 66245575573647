import React from "react";
import {
  LocalizationProvider,
  DatePicker,
  DateTimePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/pt-br";

export default function MyDatePicker({ time, ...props }) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
      {!time ? (
        <DatePicker {...props} okLabel="Confirmar" cancelLabel="Cancelar" />
      ) : (
        <DateTimePicker
          {...props}
          ampm={false}
          okLabel="Confirmar"
          cancelLabel="Cancelar"
        />
      )}
    </LocalizationProvider>
  );
}
