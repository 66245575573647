import React, { useState, useEffect, useContext } from "react";
import { useSnackbar } from "notistack";
import { Grid, Tooltip } from '@mui/material';

import ModalEditar from "../ModalEditar";

import "./styles.css";

import { Context as PermissoesContext } from "../../../Context/PermissoesContext";

export default function Horarios(props) {
  const { enqueueSnackbar } = useSnackbar();
  const { checarPermissao } = useContext(PermissoesContext);

  const { horarios, setAtualizarTabela, visaoTelevisao } = props;
  const [coletas, setColetas] = useState([]);
  const [modalEditar, setModalEditar] = useState(false);
  const [coletaSelecionada, setColetaSelecionada] = useState({});

  useEffect(() => {
    setAtualizarTabela(prev => !prev);
    // eslint-disable-next-line
  }, [modalEditar]);

  const permitidoColeta = () => {
    const index = coletas.map(e => e.id).indexOf(coletaSelecionada.id);

    if ((coletas[index - 1] && coletas[index - 1].concluido) || !coletas[index - 1]) {
      return true;
    }
    return false;
  };

  const ultimaColeta = () => {
    const index = coletas.map(e => e.id).indexOf(coletaSelecionada.id);
    if (index === coletas.length - 1) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    function ajustaHorarios() {
      const horariosAjustados = horarios.map(horario => {
        const hora = new Date(horario.hora);
        return {
          id: horario.id,
          id_mapa_curvas: horario.id_mapa_curvas,
          hora: `${
            hora.getHours() / 10 < 1
              ? `0${hora.getHours().toString()}`
              : hora.getHours().toString()
          }:${
            hora.getMinutes() / 10 < 1
              ? `0${hora.getMinutes().toString()}`
              : hora.getMinutes().toString()
          }`,
          hora_coletado: horario.hora_coletado,
          usuario_coleta: horario.usuario_coleta,
          concluido: horario.concluido,
        };
      });
      setColetas(horariosAjustados);
    }
    ajustaHorarios();
  }, [horarios]);

  return (
    <Grid container spacing={1}>
      {horarios.length > 0
        ? coletas.map(coleta => (
          <Grid
            item
            xs={4}
            key={`coleta-${coleta.id}`}
            style={{
                paddingBottom: "0",
              }}
            className="conteiner_horarios"
          >
            <Tooltip title="Editar">
              <button
                type="button"
                className={coleta.concluido ? "coleta_concluido" : "coleta_pendente"}
                onClick={() => {
                    if (!coleta.concluido) {
                      if (checarPermissao("MC004") && !visaoTelevisao) {
                        setColetaSelecionada(coleta);
                        setModalEditar(true);
                      } else {
                        enqueueSnackbar(
                          "Você não possui a permissão para editar os Horários!",
                          {
                            variant: "error",
                          }
                        );
                      }
                    } else {
                      enqueueSnackbar("Essa coleta já foi feita!", {
                        variant: "error",
                      });
                    }
                  }}
              >
                {coleta.hora}
              </button>
            </Tooltip>
          </Grid>
          ))
        : null}
      {modalEditar ? (
        <ModalEditar
          modalEditar={modalEditar}
          setModalEditar={setModalEditar}
          coleta={coletaSelecionada}
          permitidoColeta={permitidoColeta()}
          ultimaColeta={ultimaColeta()}
        />
      ) : null}
    </Grid>
  );
}
