import React from "react";

import usePermissao from "../../../../../hooks/usePermissao";
import { Box } from "@mui/material";

export default function BI() {
  const verificaPermissao = usePermissao("SUS003");
  return (
    <Box>
      {verificaPermissao && (
        <iframe
          width="100%"
          height="700"
          title="BI"
          src="https://app.powerbi.com/view?r=eyJrIjoiODU5MzlhYjctN2YxYS00ODk2LWJhMjQtYjI5MWZmOTBiMGY2IiwidCI6ImE1ZGEyY2QwLTgxNWYtNGFmNC1hYzYwLTZjNmI1OTNiNzk1NyJ9&pageName=ReportSection"
          frameBorder="0"
          allowFullScreen
        />
      )}
    </Box>
  );
}
