import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { Grid } from "@mui/material";

import AppPopoverButton from "../../../../components/AppPopoverButton";
import SalvaLog from "../../../../utils/SalvaLog";
import { Context as PermissoesContext } from "../../../../Context/PermissoesContext";

export default function Ferramentas({ ferramentas }) {
  const history = useHistory();

  const { checarPermissaoComAcao } = useContext(PermissoesContext);

  const verify = (caminho, perm, nome) => {
    if (nome === "Procurar Exames") {
      SalvaLog(`Acessar link para procurar exames`, nome);
    }
    if (perm !== 0 && perm !== 1) {
      checarPermissaoComAcao(perm, () => history.push(caminho));
    } else {
      history.push(caminho);
    }
  };

  return (
    <>
      <Grid container id="gridContainer" spacing={2}>
        {ferramentas.map(element => (
          <Grid key={element.id} item xs={6} md={3} lg={2} xl={2} sm={3}>
            <AppPopoverButton
              key={element.nome}
              nome={element.nome}
              iconClassName={element.iconClassName}
              imgSrc={element.imgSrc}
              imgAlt={element.imgAlt}
              href={element.href}
              path={element.path}
              onClick={() => {
                verify(element.path, element.permissao, element.nome);
              }}
            />
          </Grid>
        ))}
      </Grid>
    </>
  );
}
