import React from "react";
import { Paper } from "@mui/material";

import CardTotal from "../../../Componentes/CardTotal";
import BotaoImportarAquivo from "../BotaoImportarArquivo";

export default function TotalizadoresRecebimento(props) {
  const { valores, aba, setAba } = props;

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-around",
        flexWrap: "wrap",
        gap: "0.5rem",
      }}
    >
      {valores.map((v) => (
        <div
          key={v.titulo}
          style={{
            height: "100%",
            cursor: "Pointer",
          }}
          onClick={() => setAba(v.titulo)}
        >
          <CardTotal
            key={v.titulo}
            aba={aba}
            titulo={v.titulo}
            valor={v.valor}
          />
        </div>
      ))}
      <BotaoImportarAquivo nome="Importar XML" lotes />
    </div>
  );
}
