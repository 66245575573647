import React from "react";
import { Modal as MuiModal, Typography, Box, Grid } from "@mui/material";
import Button from "../Button";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  maxWidth: 500,
  margin: "auto",
};

export function ModalAviso({ open, onClose, title, modalText }) {
  return (
    <MuiModal open={open} onClose={() => onClose(false)}>
      <Box sx={modalStyle}>
        <Grid container direction="column" spacing={3}>
          <Grid item>
            <Typography variant="h5" sx={{ color: "black" }}>
              {title ? title : "Aviso"}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="h6" sx={{ color: "black" }}>
              {modalText ? modalText : ""}
            </Typography>
          </Grid>
          <Grid container justifyContent="flex-end" item>
            <Grid item>
              <Button variant="contained" onClick={() => onClose(false)}>
                Ok
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </MuiModal>
  );
}
