import React, { useContext } from "react";
import Header from "../../components/Header";
import Agenda from "./pages/Agenda";
import MedicosCare from "./pages/MedicosCare";
import Container from "../../componentsv2/Container";
import FinanceiroAgendaCare from "./pages/Financeiro";
import { useAgendaCareContext } from "../../Context/AgendaCareContext";
import { Context } from "../../Context/PermissoesContext";

export default function AgendaCare() {
  const { aba, setAba } = useAgendaCareContext();
  const { checarPermissaoComAcao } = useContext(Context);

  return (
    <>
      <Header titulo="Agenda Care" />
      <Container>
        <div style={{ paddingBottom: "0" }} id="container-aba-ctrl">
          <button
            id={aba === 0 ? "button-aba-ctrl-samples-selected" : "aba0"}
            type="button"
            className="button-aba-ctrl"
            style={{ fontSize: "16px" }}
            onClick={() => {
              setAba(0);
            }}
          >
            Agenda
          </button>

          <button
            id={aba === 2 ? "button-aba-ctrl-samples-selected" : "aba2"}
            type="button"
            className="button-aba-ctrl"
            style={{ fontSize: "16px" }}
            onClick={() => {
              setAba(2);
            }}
          >
            Medicos Care
          </button>

          <button
            id={aba === 1 ? "button-aba-ctrl-samples-selected" : "aba1"}
            type="button"
            className="button-aba-ctrl"
            style={{ fontSize: "16px" }}
            onClick={() => {
              checarPermissaoComAcao("AGC003", () => setAba(1));
            }}
          >
            Financeiro
          </button>
        </div>
        {aba === 0 && <Agenda />}
        {aba === 1 && <FinanceiroAgendaCare />}
        {aba === 2 && <MedicosCare />}
      </Container>
    </>
  );
}
