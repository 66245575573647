import React from "react";
import { Grid, Typography, Divider } from "@mui/material";
import Ferramentas from "../Ferramentas";

export default function SecoesHome({ filteredPopover }) {
  return (
    <>
      <Grid container id="gridContainer" direction="column" spacing={0}>
        {filteredPopover.map(
          element =>
            element.ferramentas.length > 0 && (
              <div key={element.id}>
                <Grid item>
                  <Typography sx={{ color: "#003399" }} variant="h6">
                    {element.tipoFerramenta}
                  </Typography>
                  <Divider />
                </Grid>
                <Grid item>
                  <Ferramentas ferramentas={element.ferramentas} />
                </Grid>
              </div>
            )
        )}
      </Grid>
    </>
  );
}
