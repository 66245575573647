import React from "react";

import { Grid } from '@mui/material';

import { Select } from "../../../../componentsv2/Select";

export default function SelectSimNao({
  onChange,
  value,
  disabled = false,
  required = false,
}) {
  const options = ["Sim", "Não"];
  return (
    <>
      <Grid container spacing={1} justify="center" alignItems="stretch">
        <Grid item xs={12}>
          <Select
            disabled={disabled}
            required={required}
            onChange={onChange}
            value={value}
            label="Gestante"
            arrayOptions={options}
            variant="standard"
          />
        </Grid>
      </Grid>
    </>
  );
}
