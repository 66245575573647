import React, { createContext, useState } from "react";

const SelectClientesContext = createContext({});

export function SelectClientesProvider({ children }) {
  const [cliente, setCliente] = useState(null);
  const [medico, setMedico] = useState(null);

  return (
    <SelectClientesContext.Provider
      value={{ cliente, setCliente, medico, setMedico }}
    >
      {children}
    </SelectClientesContext.Provider>
  );
}
export default SelectClientesContext;
