import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { Container } from "@mui/material";
import DehazeIcon from "@mui/icons-material/Dehaze";
import { useSnackbar } from "notistack";
import { Context } from "../../Context/PermissoesContext";

import Tabela from "./Tabela";
import Header from "../../components/Header";
import SelecionarVisualizacao from "./SelecionarVisualizacao";
import ModalUnidade from "./ModalUnidade";

import MapasBI from "./MapasBI";

import useUser from "../../hooks/useUser";

import { api } from "../../services/Api";

import "./styles.css";
import AdicionarEditarExame from "./TelaAddExame";

export default function MapaCurvas() {
  const usuario = useUser();
  const history = useHistory();

  const { enqueueSnackbar } = useSnackbar();

  const [mapas, setMapas] = useState(false);
  const [atualizarTabela, setAtualizarTabela] = useState(false);
  const [visaoTelevisao, setVisaoTelevisao] = useState(false);
  const [situacao, setSituacao] = useState("pendente");
  const [data_fim, setData_fim] = useState(new Date());
  const [unidade, setUnidade] = useState(0);
  const [placeholderUnidadeButton, setPlaceholderUnidadeButton] =
    useState("Unidade");
  const [aba, setAba] = useState("Mapas");
  const { checarPermissao } = useContext(Context);

  function televisao() {
    window.open(`${window.location.href}/tv`);
  }

  const [isModalUnidadeOpen, setIsModalUnidadeOpen] = useState(false);

  function handleOpenModalUnidade() {
    setIsModalUnidadeOpen(true);
  }

  useEffect(() => {
    if (!localStorage.getItem("@DNACenter:UnidadeDNA")) {
      setUnidade(0);
      handleOpenModalUnidade();
    } else {
      setUnidade(localStorage.getItem("@DNACenter:UnidadeDNA"));
    }
  }, []);

  useEffect(() => {
    if (localStorage.getItem("@DNACenter:NomeUnidadeDNA") !== null) {
      setPlaceholderUnidadeButton(
        localStorage.getItem("@DNACenter:NomeUnidadeDNA")
      );
    } else {
      setPlaceholderUnidadeButton("Selecione a Unidade");
    }
  }, [placeholderUnidadeButton]);

  useEffect(() => {
    async function inicio() {
      if (window.location.href.split("/").pop() === "tv") {
        return setVisaoTelevisao(true);
      }
      try {
        const {
          data: {
            permitido: {
              0: { id },
            },
          },
        } = await api.get(
          `/v2/controleAcesso/permissaoVisualizaModulo?username=${usuario}&&codigo=MC001`
        );

        return id;
      } catch (e) {
        return history.push("/home");
      }
    }
    inicio();
  }, [history, usuario]);

  function compare(a, b) {
    if (a.coletas[0].hora < b.coletas[0].hora) {
      return -1;
    }
    if (a.coletas[0].hora > b.coletas[0].hora) {
      return 1;
    }
    return 0;
  }

  async function pegarMapas() {
    const dataInicio = new Date(data_fim);
    console.log(dataInicio);
    console.log(data_fim);
    try {
      const {
        data: { mapa },
      } = await api.post("/v2/mapaCurvas/mapas", {
        situacao,
        data_inicio: dataInicio.setHours(0, 0, 0, 0),
        data_fim: data_fim.setHours(23, 59, 59, 59),
        id_unidade: Number(unidade),
      });
      mapa.sort(compare);
      setMapas(mapa);
    } catch (error) {
      enqueueSnackbar("Erro ao encontrar os mapas!", {
        variant: "error",
        autoHideDuration: 3000,
      });
      // console.log(error);
    }
  }

  function handleCloseModalUnidade() {
    setIsModalUnidadeOpen(false);
  }

  useEffect(() => {
    pegarMapas();
    // eslint-disable-next-line
  }, [atualizarTabela, data_fim, situacao]);

  const setUnidadeLocalStorage = () => {
    handleCloseModalUnidade();
  };

  return (
    <>
      <Header titulo="Mapa de Curvas" usuario={usuario} history={history} />

      {visaoTelevisao ? null : (
        <Container maxWidth="md">
          <div id="container-aba-ctrl">
            <button
              type="button"
              id={aba === "Mapas" ? "button-aba-ctrl-samples-selected" : "Aba1"}
              className="button-aba-ctrl"
              onClick={() => {
                setAba("Mapas");
              }}
            >
              Mapas
            </button>
            <button
              type="button"
              className="button-aba-ctrl"
              id={aba === "BI" ? "button-aba-ctrl-samples-selected" : "Aba2"}
              onClick={() => {
                setAba("BI");
              }}
            >
              BI
            </button>

            {checarPermissao("MC006") &&
              <>
              <button
              type="button"
              className="button-aba-ctrl"
              id={
                aba === "Exames" ? "button-aba-ctrl-samples-selected" : "Aba3"
              }
              onClick={() => {
                setAba("Exames");
              }}
            >
              Exames
            </button>
              </>
            }
            
          </div>
        </Container>
      )}

      {
        // eslint-disable-next-line no-nested-ternary
        aba === "BI" ? (
          <MapasBI />
        ) : aba === "Exames" ? (
          <AdicionarEditarExame />
        ) : (
          <div>
            {visaoTelevisao ? null : (
              <div className="selecionar_visao">
                <SelecionarVisualizacao
                  televisao={televisao}
                  setSituacao={setSituacao}
                  situacao={situacao}
                  data_fim={data_fim}
                  setData_fim={setData_fim}
                />
              </div>
            )}
            {unidade !== "" ? null : setUnidadeLocalStorage}
            <ModalUnidade
              isModalUnidadeOpen={isModalUnidadeOpen}
              handleCloseModalUnidade={handleCloseModalUnidade}
              unidade={unidade}
              setUnidade={setUnidade}
              setPlaceholderUnidadeButton={setPlaceholderUnidadeButton}
            />

            <div id="unidade">
              <h3 id="unidadeNome">{placeholderUnidadeButton}</h3>
              {visaoTelevisao === true ? null : (
                <DehazeIcon id="unidadeOpen" onClick={handleOpenModalUnidade} />
              )}
            </div>
            <div>
              <Tabela
                setAtualizarTabela={setAtualizarTabela}
                visaoTelevisao={visaoTelevisao}
                mapas={mapas}
                unidade={unidade}
              />
            </div>
          </div>
        )
      }
    </>
  );
}
