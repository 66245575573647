import React from "react";
import TabelaV2 from "../../../../../../componentsv2/Tabela";
import Box from "../../../../../../componentsv2/Box";

import OpcoesTabela from "../../../../Components/OpcoesTabela";

export default function Tabela({ rows, atualizarTabela }) {
  const columns = [
    {
      field: "situacao",
      headerName: "Situação",
      headerAlign: "center",
      align: "center",
      width: 120,
    },

    {
      field: "username",
      valueGetter: ({ row }) => row?.usuario?.username,
      headerName: "Usuário",
      headerAlign: "center",
      align: "center",
      width: 100,
    },

    {
      field: "nome_usu",
      valueGetter: ({ row }) =>
        row?.enfermeiro?.usuario?.funcionario?.nome_usu
          .split(" ")[0]
          .toUpperCase(),
      headerName: "Enfermeiro",
      headerAlign: "center",
      align: "center",
      width: 140,
    },

    {
      field: "hora",
      headerName: "Hora",
      headerAlign: "center",
      align: "center",
      width: 100,
    },

    {
      field: "cliente",
      valueGetter: ({ row }) => row?.cliente?.nome,
      headerName: "Cliente",
      headerAlign: "center",
      align: "center",
      width: 320,
    },

    {
      field: "cpf",
      valueGetter: ({ row }) => row?.cliente?.cpf,
      headerName: "CPF/CNPj",
      headerAlign: "center",
      align: "center",
      width: 200,
    },

    {
      field: "opcoes",
      headerName: "Opções",
      headerAlign: "center",
      align: "center",
      flex: 1,
      width: 20,
      type: "number",
      editable: false,
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (params) => (
        <div style={{ cursor: "pointer" }}>
          <OpcoesTabela atualizarTabela={atualizarTabela} row={params.row} />
        </div>
      ),
    },
  ];
  return (
    <Box sx={{ height: 480, width: "100%" }}>
      <TabelaV2 columns={columns} rows={rows} autoPageSize ocultarToolbar />
    </Box>
  );
}
