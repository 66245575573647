import React, { useContext } from "react";
import { Edit } from "@mui/icons-material";
import {
  Dialog,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper
} from "@mui/material";

import CorpoModal from "../../../Componentes/CorpoModal";

import { ModalRecebimentoContext } from "../../Context/ModalRecebimentoContext";
import { RecebimentoContext } from "../../Context/RecebimentoContext";

export default function ModalOpcoes() {
  const {
    opcoes,
    setOpcoes,
    fechaModalOpcoes,
   } = useContext(ModalRecebimentoContext);

   const { setTela } = useContext(RecebimentoContext);

  return (
    // Modal de opções de gerenciamento de lotes
    <Dialog
      maxWidth="sm"
      open={opcoes}
      onClose={fechaModalOpcoes}
      closeAfterTransition
    >
      <CorpoModal titulo="Opções" fechaModal={fechaModalOpcoes}>
        <Paper style={{display: "flex", margin: "1rem"}}>
          <List component="nav" style={{display: "flex", flexDirection: "column", width: "100%", padding: 0}} aria-label="main mailbox folders">

            <ListItemButton
              onClick={() => {
                setTela('Lote')
                setOpcoes(false)
              }}
            >
              <ListItemIcon>
                <Edit color="success" />
              </ListItemIcon>
              <ListItemText primary="Gerenciar valores" />
            </ListItemButton>

          </List>
        </Paper>
      </CorpoModal>
    </Dialog>
  )
}

