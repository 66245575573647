import React from "react";
import { Route } from "react-router-dom";
import NotificacoesCovid from "./index";

export default function Routes() {
  return (
    <Route path="/notificacoesCovid" element={<NotificacoesCovid />} />
  );
}




