import React, { useState, useContext } from "react";
import SAC from "./pages/Sac";
import Ouvidoria from "./pages/Ouvidoria";
import BI from "./pages/BI";
import { Context as PermissoesContext } from "../../Context/PermissoesContext";

import { useSnackbar } from "notistack";
import Header from "../../components/Header";
import Container from "../../componentsv2/Container";

export default function AtendimentoAoCliente() {
  const [aba, setAba] = useState(0);
  const { checarPermissao } = useContext(PermissoesContext);
  const { enqueueSnackbar } = useSnackbar();

  return (
    <>
    <Header titulo="Atendimento ao Consumidor"/>
    <Container>
      <div style={{ paddingBottom: "0" }} id="container-aba-ctrl">
        <button
          type="button"
          id={aba === 0 ? "button-aba-ctrl-samples-selected" : "aba0"}
          className="button-aba-ctrl"
          style={{ fontSize: "16px" }}
          onClick={() => {
            setAba(0);
          }}
        >
          SAC
        </button>

        <button
          type="button"
          id={aba === 1 ? "button-aba-ctrl-samples-selected" : "aba1"}
          className="button-aba-ctrl"
          style={{ fontSize: "16px" }}
          onClick={() => {
            if (!checarPermissao("ATC005")) {
              enqueueSnackbar("Você não possui permissão para acessar a Ouvidoria!", {
                variant: "error",
              });
            } else {
              setAba(1);
            }
          }}
        >
          Ouvidoria
        </button>
        <button
          type="button"
          id={aba === 2 ? "button-aba-ctrl-samples-selected" : "aba2"}
          className="button-aba-ctrl"
          style={{ fontSize: "16px" }}
          onClick={() => {
            setAba(2);
          }}
        >
          BI
        </button>
      </div>
      {aba === 0 && <SAC />}
      {aba === 1 && checarPermissao("ATC005") && <Ouvidoria />}
      {aba === 2 && <BI />}
      </Container>
      </>
  );
}
