import React, { useState, useEffect, useContext } from "react";

import {
  Button as MuiButton,
  Container,
  Grid,
  Modal as MuiModal,
  Paper,
} from "@mui/material";
import SettingsIcon from '@mui/icons-material/Settings';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import EditIcon from "@mui/icons-material/Edit";
import { useHistory } from "react-router-dom";
import Button from "../../componentsv2/Button";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import DoneOutlineIcon from "@mui/icons-material/DoneOutline";
import { Modal } from "../../componentsv2/Modal";
import { api } from "../../services/Api";
import { useSnackbar } from "notistack";
import { colorTheme } from "../../theme";
import DateRangeIcon from "@mui/icons-material/DateRange";
import { Context as PermissoesContext } from "../../Context/PermissoesContext";

export default function OpcoesInventario({ row, setUpdate, update }) {
  const [open, setOpen] = useState(false);
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [situacao, setSituacao] = useState("");
  const { checarPermissao } = useContext(PermissoesContext);
  const [openModalConfirmar, setOpenModalConfirmar] = useState(false);

  useEffect(() => {
    if (row) {
      setSituacao(row.situacao);
    }
  }, [row]);

  function ModalOption(props) {
    const handleClickEdit = () => {
      history.push({
        pathname: "/inventario/editaInventario",
        state: { dados: props.row },
      });
    };

    const concluirInventario = async e => {
      e.preventDefault();

      try {
        await api.put(`/v2/inventario/concluirInventario/${row.id}`);

        setOpenModalConfirmar(false);
        setUpdate(!update);
        enqueueSnackbar("Inventário concluído com sucesso.", {
          variant: "success",
        });
      } catch (error) {
        enqueueSnackbar("Erro! Não foi possível concluir o inventário.", {
          variant: "error",
        });
      }
    };

    return (
      <Container className="options-container" maxWidth="xs">
        <Paper className="options-paper" elevation={2}>
          <Grid container style={{ padding: "1rem 1rem" }}>
            <Grid item sm={12}>
              <Button
                disabled
                style={{ color: colorTheme.primary[500] }}
                variant="standard"
                startIcon={<DateRangeIcon />}
              >
                Abertura:{" "}
                {new Date(row.dataInicio).toLocaleString({
                  dateStyle: "full",
                  timeStyle: "full",
                })}
              </Button>
            </Grid>
            <Grid item sm={12}>
              <Button
                onClick={() => {
                  if (checarPermissao("INV026")) {
                    history.push({
                      pathname: `/inventario/inventarioBem`,
                      search: `?inventarioId=${props.row.id}`,
                    });
                  } else {
                    enqueueSnackbar(
                      "Você não possui permissão para abrir um inventário (Acessar os bens do mesmo).",
                      {
                        variant: "error",
                        autoHideDuration: 3000,
                      }
                    );
                  }
                }}
                variant="standard"
                startIcon={<VisibilityIcon />}
              >
                Abrir
              </Button>
            </Grid>
            <Grid item sm={12}>
              {situacao === "Aberto" ? (
                <>
                  <Button
                    onClick={() => {
                      if (checarPermissao("INV005")) {
                        setOpenModalConfirmar(true);
                      } else {
                        enqueueSnackbar(
                          "Você não possui permissão para concluir um inventário.",
                          {
                            variant: "error",
                            autoHideDuration: 3000,
                          }
                        );
                      }
                    }}
                    variant="standard"
                    startIcon={<DoneOutlineIcon />}
                  >
                    Concluir Inventário
                  </Button>
                  <Modal
                    modalText="Tem certeza que deseja concluir este inventário?"
                    confirmActionButtonText="Confirmar"
                    open={openModalConfirmar}
                    onClose={setOpenModalConfirmar}
                    onClick={concluirInventario}
                    confirmActionButtonColor="green"
                  />{" "}
                </>
              ) : (
                <Button
                  onClick={() => setOpenModalConfirmar(true)}
                  variant="standard"
                  disabled
                  style={{ color: colorTheme.success[500] }}
                  startIcon={<DoneAllIcon />}
                >
                  Inventário Concluído
                </Button>
              )}
            </Grid>
            <Grid item sm={12}>
              {situacao === "Aberto" && (
                <Button
                  onClick={() => {
                    if (checarPermissao("INV004")) {
                      handleClickEdit();
                    } else {
                      enqueueSnackbar(
                        "Você não possui permissão para editar um inventário.",
                        {
                          variant: "error",
                          autoHideDuration: 3000,
                        }
                      );
                    }
                  }}
                  variant="standard"
                  startIcon={<EditIcon />}
                >
                  Editar
                </Button>
              )}
            </Grid>
            <Grid item sm={12}>
              <Button
                onClick={() => setOpen(false)}
                variant="standard"
                startIcon={<ArrowBackIcon />}
              >
                Voltar
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Container>
    );
  }

  return (
    <>
      <MuiButton style={{ color: "#000" }} onClick={() => setOpen(true)}>
        <SettingsIcon />
      </MuiButton>

      <MuiModal open={open} onClose={() => setOpen(false)}>
        <ModalOption row={row} />
      </MuiModal>
    </>
  );
}
