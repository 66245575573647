import React, { createContext, useContext, useEffect, useState } from "react";
import { useSnackbar } from "notistack";

import { ModalRecebimentoContext } from "./ModalRecebimentoContext";

import { api } from "../../../../services/Api";
import { RecebimentoContext } from "./RecebimentoContext";

const RetornoLoteContext = createContext();

function RetornoLoteContextProvider({ children }) {
  const { enqueueSnackbar } = useSnackbar();

  const [nota_fiscal, setNota_fiscal] = useState("");
  const [protocolo, setProtocolo] = useState("");
  const [data_recebimento, setData_recebimento] = useState(() => {
    const valueDateR = new Date();
    valueDateR.setHours(valueDateR.getHours() - 3);
    return valueDateR;
  });
  const [protocoloRecurso, setProtocoloRecurso] = useState("");
  const [glosas, setGlosas] = useState([]);
  const [loading, setLoading] = useState(false);

  const [valor_recuperado, setValor_recuperado] = useState(0);
  const [imposto, setImposto] = useState(0);
  const [desconto_unimed, setDesconto_unimed] = useState(0);
  const [valor_liquido, setValor_liquido] = useState(0);
  const [valor_glosado, setValor_glosado] = useState(0);
  const [valor_acatado, setValor_acatado] = useState(0);
  const [emAnalise, setEmAnalise] = useState(0);
  const [valor_diferenca_tabela, setValor_diferenca_tabela] = useState(0);
  const [valor_diferenca_unimed, setValor_diferenca_unimed] = useState(0);
  const [valorImposto, setValorImposto] = useState(0);
  const [valorDesconto, setValorDesconto] = useState(0);
  const [observacoes, setObservacoes] = useState("");
  const [situacao, setSituacao] = useState("");
  const [motivo, setMotivo] = useState("");
  const [valor_recebido, setValor_recebido] = useState("");
  const [existeEmRecurso, setExisteEmRecurso] = useState(false);

  const [valorRecuperadoOriginal, setValorRecuperadoOriginal] = useState(0);

  const [valorOriginalEnviado, setValorOriginalEnviado] = useState(0);

  const {
    acao,
    id_lote,
    id_retorno_lote,
    setId_retorno_lote,
    informacoesLote,
    fechaTelaGerenciaLotes,
    fechaModalGlosasLotes,
    adicionaRecebimento,
  } = useContext(ModalRecebimentoContext);

  const {
    retornoLote,
    setRetornoLoteAgrupado,
    setAtualizaRetornoLote,
    setAtualizaTabela,
  } = useContext(RecebimentoContext);

  const { valor_enviado } = informacoesLote;

  const [
    atualizaValoresRecebimentoManual,
    setAtualizaValoresRecebimentoManual,
  ] = useState(true);

  function valorTotalGlosas(situacaoGlosa) {
    const valorTotal = glosas.reduce((soma, atual) => {
      if (atual.situacao === situacaoGlosa) {
        return soma + Number(atual.valor_recebido);
      }
      return soma;
    }, 0);
    return valorTotal;
  }

  useEffect(() => {
    const valorGlosado = valorTotalGlosas("Glosado");
    const valorAcatado = valorTotalGlosas("Acatado");
    const valorRecurso = valorTotalGlosas("Analise");

    setValor_glosado(valorGlosado);
    setValor_acatado(valorAcatado);
    setEmAnalise(valorRecurso);

    const valorSubtraido =
      valorGlosado +
      valorAcatado +
      valorRecurso +
      Number(valor_diferenca_tabela);

    const valorRecuperadoAtual =
      (retornoLote.length > 0 ? valorRecuperadoOriginal : valor_enviado) -
      valorSubtraido;

    setValor_recuperado(valorRecuperadoAtual);
    // eslint-disable-next-line
  }, [atualizaValoresRecebimentoManual, valorRecuperadoOriginal]);

  useEffect(() => {
    const valorDescontoUnimed =
      valor_recuperado * (1 - Number(desconto_unimed) / 100);
    const valorLiquidoAtual = valorDescontoUnimed * (1 - Number(imposto) / 100);

    setValor_liquido(valorLiquidoAtual);
    setValorDesconto(valor_recuperado - valorDescontoUnimed);
    setValorImposto(valorDescontoUnimed - valorLiquidoAtual);
    // eslint-disable-next-line
  }, [imposto, desconto_unimed, valor_recuperado]);

  useEffect(() => {
    (async () => {
      if (id_lote && adicionaRecebimento) {
        try {
          setLoading(true);
          const { data: resultadoLote } = await api.get(
            `/v2/recebimento/retornoLote`,
            {
              params: { id_lote, id_retorno_lote },
            }
          );

          const { lote, observacao, glosados, todosGlosados } = resultadoLote;

          const loteAtual = retornoLote.find((l) => l.id === id_retorno_lote);

          // if (acao === "Editar") {
          //   setValor_recuperado(loteAtual?.valor_recuperado);
          // }

          if (retornoLote.length > 0 && acao === "Criar") {
            setValor_recuperado(lote[0].em_recurso);
            setValorRecuperadoOriginal(lote[0].em_recurso);
            setExisteEmRecurso(
              todosGlosados?.some((glosa) => glosa?.situacao === "Analise")
            );
          } else if (lote.length > 0) {
            // setId_retorno_lote(loteAtual?.id);

            setProtocoloRecurso(loteAtual?.protocolo_recurso);
            setNota_fiscal(loteAtual?.nota_fiscal);
            setData_recebimento(loteAtual?.data_recebimento);
            setImposto(loteAtual?.imposto);
            setDesconto_unimed(loteAtual?.desconto_unimed);
            setValor_diferenca_tabela(loteAtual?.valor_diferenca_tabela);
            setValor_diferenca_unimed(loteAtual?.valor_diferenca_unimed);
            setObservacoes(observacao[0].observacoes);

            setExisteEmRecurso(
              loteAtual?.nota_pos_recurso ? loteAtual?.nota_pos_recurso : false
            );

            setGlosas(glosados);
            setAtualizaValoresRecebimentoManual((prevState) => !prevState);
            setValor_recuperado(loteAtual?.valor_recuperado);
            setValorRecuperadoOriginal(
              loteAtual?.valor_recuperado +
                loteAtual?.em_recurso +
                loteAtual?.valor_glosado +
                loteAtual?.valor_acatado
            );
          } else {
            setValor_recuperado(Number(valor_enviado));
          }
          setValorOriginalEnviado(Number(valor_enviado));
        } catch (error) {
          enqueueSnackbar(
            "Não foi possível procurar por dados do lote. Por favor, tente novamente.",
            { variant: "error" }
          );
        }
        setLoading(false);
      }
    })();
    // eslint-disable-next-line
  }, [id_lote, adicionaRecebimento, id_retorno_lote]);

  function zeraValores() {
    setValor_recuperado(0);
    setImposto(0);
    setDesconto_unimed(0);
    setValorImposto(0);
    setValorDesconto(0);
    setValor_liquido(0);
    setValor_glosado(0);
    setValor_acatado(0);
    setValor_diferenca_tabela(0);
    setValor_diferenca_unimed(0);
    setEmAnalise(0);
    setNota_fiscal("");
    setProtocolo("");
    setData_recebimento(() => {
      const valueDateR2 = new Date();
      valueDateR2.setHours(valueDateR2.getHours() - 3);
      return valueDateR2;
    });
    setObservacoes("");
    setId_retorno_lote("");
    setGlosas([]);
    setProtocoloRecurso("");
    setExisteEmRecurso(false);
  }

  function salvaGlosasLotes() {
    setRetornoLoteAgrupado((prevState) => {
      const arrayLotes = prevState;
      const novoArray = arrayLotes.map((lote) => {
        if (lote.id === id_lote) {
          const novoLote = lote;
          novoLote.valor_recuperado +=
            novoLote.valor_glosado +
            novoLote.valor_acatado +
            novoLote.em_recurso;
          novoLote.valor_glosado = valorTotalGlosas("Glosado");
          novoLote.valor_acatado = valorTotalGlosas("Acatado");
          novoLote.em_recurso = valorTotalGlosas("Analise");
          novoLote.valor_recuperado -=
            novoLote.valor_glosado -
            novoLote.valor_acatado -
            novoLote.em_recurso;
          novoLote.glosas = glosas;
          return novoLote;
        }
        return lote;
      });
      return novoArray;
    });
    fechaModalGlosasLotes();
    enqueueSnackbar("Atualização salva.", { variant: "success" });
  }

  // console.log("emAnalise", emAnalise);
  // console.log("valor_diferenca_tabela", valor_diferenca_tabela);
  // console.log("valor_recuperado", valor_recuperado);

  // funcão que cria novo recebimento
  async function salvaRetornoLote() {
    setLoading(true);
    try {
      await api.post(`/v2/recebimento/retornoLoteManual`, {
        id_lote,
        id_retorno_lote,
        protocolo,
        valor_recuperado,
        nota_fiscal,
        data_recebimento,
        imposto,
        desconto_unimed,
        valor_liquido,
        valor_glosado,
        valor_acatado,
        em_recurso: emAnalise,
        valor_diferenca_tabela,
        valor_diferenca_unimed,
        observacoes,
        glosas,
        protocolo_recurso: protocoloRecurso,
        nota_pos_recurso: existeEmRecurso,
      });

      setAtualizaRetornoLote((prevState) => !prevState);
      setAtualizaTabela((prevState) => !prevState);
      fechaTelaGerenciaLotes();
      zeraValores();

      enqueueSnackbar("Valores salvos", { variant: "success" });
    } catch (error) {
      enqueueSnackbar(
        "Não foi possível salvar o recebimento. Por favor, tente novamente.",
        { variant: "error" }
      );
    }
    setLoading(false);
  }

  return (
    <RetornoLoteContext.Provider
      value={{
        glosas,
        setGlosas,
        nota_fiscal,
        setNota_fiscal,
        data_recebimento,
        setData_recebimento,
        protocolo,
        setProtocolo,
        valor_recuperado,
        setValor_recuperado,
        imposto,
        setImposto,
        desconto_unimed,
        setDesconto_unimed,
        valor_liquido,
        setValor_liquido,
        valor_glosado,
        setValor_glosado,
        valor_acatado,
        setValor_acatado,
        emAnalise,
        setEmAnalise,
        valor_diferenca_tabela,
        setValor_diferenca_tabela,
        valor_diferenca_unimed,
        setValor_diferenca_unimed,
        valorImposto,
        setValorImposto,
        valorDesconto,
        setValorDesconto,
        observacoes,
        setObservacoes,
        situacao,
        setSituacao,
        motivo,
        setMotivo,
        valor_recebido,
        setValor_recebido,
        salvaRetornoLote,
        salvaGlosasLotes,
        setAtualizaValoresRecebimentoManual,
        zeraValores,
        loading,
        protocoloRecurso,
        setProtocoloRecurso,
        valorRecuperadoOriginal,
        valorOriginalEnviado,
      }}
    >
      {children}
    </RetornoLoteContext.Provider>
  );
}

export { RetornoLoteContext, RetornoLoteContextProvider };
