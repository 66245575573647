import React, { useContext } from "react";
import { useSnackbar } from "notistack";
import {
  Dialog,
  DialogTitle,
  InputLabel,
  List,
  ListItemButton,
} from "@mui/material";
import {
  Clear,
  Create,
  MonetizationOn,
  Person,
  Replay,
  Sync,
} from "@mui/icons-material";

import { Context as PermissoesContext } from "../../../../../Context/PermissoesContext";
import { ColetaDomiciliarContext } from "../../../Context/ColetaDomiciarContext";
import { ModaisColetaDomiciliarContext } from "../../../Context/ModaisColetaDomiciarContext";
import { FormularioColetaDomiciliarContext } from "../../../Context/FormularioColetaDomiciliarContext";

import SalvaLog from "../../../../../utils/SalvaLog";

export default function ModalOpcoes() {
  const { enqueueSnackbar } = useSnackbar();
  const { checarPermissao } = useContext(PermissoesContext);
  const { coleta } = useContext(ColetaDomiciliarContext);

  const {
    setModalEditarDados,
    setAbreDadosCliente,
    setModalValor_recebido,
    opcao,
    fechaOpcoes,
    abreDuplicata,
    setConfirmar,
    setMsg,
  } = useContext(ModaisColetaDomiciliarContext);

  const {
    setTipo_cliente,
    setCodigoPedido,
    setCodigo_pedido,
    setPosto_pedido,
    setNome,
    setEndereco,
    setEndereco_numero,
    setComplemento,
    setBairro,
    setCidade,
    setEstado,
    setCep,
    setFone,
    setCelular,
    setObservacoes,
    setPonto_referencia,
    setValor_recebido,
    setLocalizador,
    setColeta_dupla,
    setDadosReagendamento,
  } = useContext(FormularioColetaDomiciliarContext);

  function preencheCliente(cliente) {
    setCodigoPedido(
      `${String(cliente.codigo_pedido)}-${cliente.posto_pedido}` || ""
    );
    setCodigo_pedido(cliente.codigo_pedido || null);
    setPosto_pedido(cliente.posto_pedido);
    setNome(cliente.nome);
    setLocalizador(cliente.localizador || "");
    setTipo_cliente(cliente.tipo_cliente || "Normal");
    setEndereco(cliente.endereco);
    setEndereco_numero(cliente.endereco_numero);
    setComplemento(cliente.complemento);
    setBairro(cliente.bairro);
    setCidade(cliente.cidade);
    setEstado(cliente.estado);
    setCep(cliente.cep);
    setFone(cliente.fone);
    setCelular(cliente.celular);
    setPonto_referencia(cliente.ponto_referencia);
    setObservacoes(cliente.observacoes);
    setColeta_dupla(cliente.coleta_dupla || "Não");
    setValor_recebido(
      cliente.valor_recebido !== null
        ? cliente.valor_recebido.toString()
        : "0.00"
    );
  }

  async function abreCadastro() {
    if (coleta) {
      preencheCliente(coleta);
      SalvaLog(
        `Abriu o cadastro do cliente ${coleta.localizador} da coleta domiciliar de ID ${coleta.id}`,
        "Coleta Domiciliar",
        JSON.stringify(coleta),
        `${coleta.id}`
      );
    }
    setAbreDadosCliente(true);
  }

  async function reagendar() {
    setDadosReagendamento(coleta);
    fechaOpcoes();
    setModalEditarDados(true);
  }

  function confirmaCancelamento() {
    if (!checarPermissao("CDO008")) {
      enqueueSnackbar(
        `Para fazer a ação é necessário possuir a permissão CDO008`,
        {
          variant: "error",
        }
      );
      return;
    }
    setMsg("Você realmente deseja cancelar este agendamento?");
    setConfirmar(true);
  }

  return (
    <Dialog
      open={opcao}
      onClose={() => {
        fechaOpcoes();
      }}
      aria-labelledby="simple-dialog-title"
    >
      <DialogTitle id="selecionaOpcao">Selecione uma opção</DialogTitle>
      <List className="opList">
        <ListItemButton
          autoFocus
          onClick={() => {
            abreCadastro();
          }}
          key="100"
        >
          <Person color="primary" />
          <InputLabel id="itemLista"> Editar</InputLabel>
        </ListItemButton>

        <ListItemButton
          onClick={() => {
            reagendar();
          }}
          key="101"
        >
          <Create color="primary" />
          <InputLabel id="itemLista"> Reagendar</InputLabel>
        </ListItemButton>

        <ListItemButton
          button
          onClick={() => {
            abreDuplicata();
          }}
          key="102"
        >
          <Sync color="primary" />
          <InputLabel id="itemLista"> Duplicar</InputLabel>
        </ListItemButton>

        <ListItemButton onClick={() => setModalValor_recebido(true)} key="104">
          <MonetizationOn color="success" />
          <InputLabel id="itemLista"> Pagamento</InputLabel>
        </ListItemButton>

        <ListItemButton onClick={() => confirmaCancelamento()} key="105">
          <Clear color="error" />
          <InputLabel id="itemLista"> Excluir</InputLabel>
        </ListItemButton>

        <ListItemButton onClick={fechaOpcoes} key="106">
          <Replay />
          <InputLabel id="itemLista"> Voltar</InputLabel>
        </ListItemButton>
      </List>
    </Dialog>
  );
}
