import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@mui/material';

const nomePorPermissao = {
  ca_001: "Acessar o módulo",
  ca_002: "Conceder permissões",
  ca_003: "Remover permissões",
  ca_004: "Conceder cargos",
  ca_005: "Remover cargos",
};

function compararAcoes(originais, modificadas) {
  const autorizadasModificadas = modificadas.autorizadas;
  const naoAutorizadasModificadas = modificadas.nao_autorizadas;

  const autorizadasOriginais = originais.autorizadas;
  const naoAutorizadasOriginais = originais.nao_autorizadas;

  return (
    autorizadasModificadas.every(
      acao => autorizadasOriginais.find(acao2 => acao2.id === acao.id) !== undefined
    ) &&
    naoAutorizadasModificadas.every(
      acao => naoAutorizadasOriginais.find(acao2 => acao2.id === acao.id) !== undefined
    )
  );
}

function compararDadosUsuario(dadosUsuario, modo) {
  const { originais, modificados } = dadosUsuario;
  const papelIgual = originais.papel === modificados.papel;
  const acoesIguais = compararAcoes(originais.acoes, modificados.acoes);
  return (modo === "cargos" && papelIgual) || (modo === "permissoes" && acoesIguais);
}

function CustomDialog({ title, text, open, continuar, voltar }) {
  return (
    <Dialog open={open}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{text}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button className="secondary" onClick={voltar}>
          VOLTAR
        </Button>
        <Button onClick={continuar}>CONTINUAR</Button>
      </DialogActions>
    </Dialog>
  );
}

export { nomePorPermissao, compararDadosUsuario, CustomDialog };
