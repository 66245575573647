import React from "react";
import { Dialog, DialogActions, InputLabel, TextField } from "@mui/material";
import { Close } from "@mui/icons-material";

import { Select } from "../../../../../componentsv2/Select";

export default function ModalEditarPagamento({
  open,
  onClose,
  codigoPedido,
  tipoPagamento,
  onChangePagamento,
  valorRecebido,
  onChangeValor,
  nome,
  id,
  submit,
}) {
  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="simple-dialog-title">
      <div id="header-information-client">
        <i
          id="icon-info-client"
          className="fa fa-user-circle"
          aria-hidden="true"
        />
        <span style={{ marginLeft: "0.5rem" }}>Editar dados do cliente</span>

        <button
          type="button"
          id="btn-close-info-client"
          onClick={() => {
            onClose();
          }}
        >
          <Close id="icon-back-info-client" />
        </button>
      </div>
      <div className="grid-container-Cli">
        <div className="grid-item-Cli">
          <TextField
            InputProps={{
              readOnly: true,
            }}
            variant="standard"
            className="codPedido"
            label="Pedido"
            id="filled-disabled"
            value={codigoPedido}
          />
        </div>
        <br></br>

        <div className="grid-item-Cli">
          <InputLabel className="nomeHora">Tipo do Pagamento</InputLabel>
          <Select
            fullWidth
            variant="standard"
            className="selectForm"
            value={tipoPagamento}
            onChange={(e) => {
              onChangePagamento(e.target.value);
            }}
            arrayOptions={["Cartao", "Dinheiro", "Pix"]}
          />
        </div>
        <br></br>
        <div className="grid-item-Cli">
          <TextField
            variant="standard"
            label="Valor Recebido"
            value={valorRecebido}
            onChange={(e) => {
              onChangeValor(e.target.value);
            }}
          />
        </div>

        <div className="grid-item-Cli-2">
          <TextField
            InputProps={{
              readOnly: true,
            }}
            id="filled-disabled"
            variant="standard"
            className="espacoso"
            label="Cliente"
            value={nome}
            onChange={(e) => {}}
          />
        </div>
      </div>

      <DialogActions>
        <button
          type="button"
          className="btnDialogs"
          onClick={() => {
            onClose();
          }}
        >
          Cancelar
        </button>
        <button
          type="button"
          className="btnDialogs"
          onClick={() => {
            submit();
            onClose();
          }}
        >
          Salvar
        </button>
      </DialogActions>
    </Dialog>
  );
}
