import React,{useContext} from "react";
import { Paper,Grid, TextField} from "@mui/material";

import DataPickerAtualizado from "../../../../../components/DataPickerAtualizado";
import DadosContext from "../Context/DadosContext"
import "./Cabecalho.css"
export function Cabecalho() {
    const {
        filtro,
        setFiltro,
        atualizar,
        dataInicio,
        setDataInicial,
        dataFim,
        setDataFinal
      } = useContext(DadosContext);
      
    return(
        <Paper className="cabecalho-paper">
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <TextField
                        fullWidth
                        id="filtro"
                        label="Pesquisar CPF, Codigo pedido ou posto Pedido"
                        value={filtro}
                        onChange={(e) => setFiltro(e.target.value)}
                        onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                            e.preventDefault();
                            atualizar()
                        }
                        }}
                    />
                </Grid>
                <Grid item xs={4}>
                    <DataPickerAtualizado data={dataInicio} setData={setDataInicial} labelName="Data Inicial"/>
                </Grid>
                <Grid item xs={4}>
                    <DataPickerAtualizado data={dataFim} setData={setDataFinal} labelName="Data Final"/>
                </Grid>
            </Grid>
        </Paper>
    )
}
