import React from "react";

import TelasRecebimento from "./Components/TelasRecebimento";

import { RetornoLoteContextProvider } from "./Context/RetornoLoteContext";

export default function Recebimento() {
  return (
    <RetornoLoteContextProvider>
      <TelasRecebimento />
    </RetornoLoteContextProvider>
  );
}
