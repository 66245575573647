import React, { useState, useEffect, useRef } from "react";
import { Grid } from "@mui/material";
import { useSnackbar } from "notistack";
import { useHistory, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import PublishIcon from "@mui/icons-material/Publish";
import AddIcon from "@mui/icons-material/Add";

import Header from "../../../../../components/Header";
import Button from "../../../../../components/Button";
import Input from "../../../../../components/Input";
import DatePicker from "../../../../../components/DatePicker";
import Pill from "../../../components/Pill";
import Loading from "../../../../../components/Loading";

import AddSectionButton from "../../../components/AddSectionButton";
import ArticleSection from "../../../components/ArticleSection";
// eslint-disable-next-line
import SpecialtiesModal from "../../../components/SearchModal";
// eslint-disable-next-line
import ModulesModal from "../../../components/SearchModal";

import assert from "../../../../../utils/assert";

import { api, baseUrl } from "../../../../../services/Api";

import "../editorStyles.css";
import "./styles.css";
import dayjs from "dayjs";

export default function EditorArtigo() {
  const history = useHistory();
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const fileInputRef = useRef(null);
  const [values, setValues] = useState({
    title: "",
    subtitle: "",
    date: new Date(),
    specialties: [],
    modules: [],
    sections: [],
    images: [],
    state: "",
    pdfs: [],
  });
  const [loading, setLoading] = useState(true);
  const [mode, setMode] = useState(""); // create or edit

  const [showSpecialtiesModal, setShowSpecialtiesModal] = useState(false);
  const [showModulesModal, setShowModulesModal] = useState(false);

  useEffect(() => {
    (async () => {
      if (id) {
        setMode("edit");
        // fetch data...
        try {
          const { data } = await api.get(`/v2/portalMedico/artigo/${id}`);
          setValues({
            title: data.titulo,
            subtitle: data.subtitulo,
            date: new Date(),
            specialties: data.especialidades,
            modules: data.modulos,
            sections: data.secoes.map((s) => ({
              title: s.titulo,
              body: s.corpo,
            })),
            images: data.midias.filter((m) => m.tipo !== "pdf"),
            state: data.estado,
            id: data.id,
            pdfs: data.midias.filter((m) => m.tipo === "pdf"),
          });
        } catch (e) {
          enqueueSnackbar(
            "Não foi possível recuperar os dados do artigo desejado. Por favor, recarregue a página.",
            { variant: "error" }
          );
        }
      } else {
        setMode("create");
      }
      setLoading(false);
    })();
    // eslint-disable-next-line
  }, []);

  const setField = (field, value) =>
    setValues((prev) => ({
      ...prev,
      [field]: value,
    }));

  const removeSection = (index) => {
    const copy = [...values.sections];
    copy.splice(index, 1);
    setField("sections", copy);
  };

  const addSection = (index) => {
    const copy = [...values.sections];
    copy.splice(index, 0, {
      title: "",
      body: "",
      id: Date.now(),
    });
    setField("sections", copy);
  };

  const filterNewElements = (newElements, filterFunction) => {
    const actualNewOnes = newElements.filter((el) => filterFunction(el));
    return actualNewOnes;
  };

  const addSpecialties = (newSpecialties) => {
    const actualNewOnes = filterNewElements(
      newSpecialties,
      (el) => !values.specialties.find((e2) => e2.id === el.id)
    );
    const result = [...values.specialties, ...actualNewOnes];
    setField("specialties", result);
    setShowSpecialtiesModal(false);
  };

  const addModules = (newModules) => {
    const actualNewOnes = filterNewElements(
      newModules,
      (m) => !values.modules.find((m2) => m2.id === m.id)
    );
    const result = [...values.modules, ...actualNewOnes];
    const newSpecialties = actualNewOnes
      .map((module) => module.especialidades) // pegando os arrays de especialidades
      .flat() // transformando [[especialidades1], [especialidades2]] em [especialidades1, especialidades2]
      .filter(
        // recuperando elementos unicos
        (specialty, index, specialties) =>
          specialties.findIndex((s) => s.id === specialty.id) === index
      );
    addSpecialties(newSpecialties);
    setField("modules", result);
    setShowModulesModal(false);
  };

  const removeSpecialty = (index) => {
    const copy = [...values.specialties];
    copy.splice(index, 1);
    setField("specialties", copy);
  };

  const removeModule = (index) => {
    const copy = [...values.modules];
    copy.splice(index, 1);
    setField("modules", copy);
  };

  const addImage = (image) => {
    const copy = [...values.images];
    copy.push(image);
    setField("images", copy);
  };

  const addPdfs = (pdfs) => {
    const array = Array.from(pdfs);
    const validPdfs = array.filter((pdf) => {
      const filenameWithoutExtension = pdf.name.slice(
        0,
        pdf.name.lastIndexOf(".")
      );
      return filenameWithoutExtension.length <= 85;
    });
    const newPdfs = [...values.pdfs, ...validPdfs];
    setField("pdfs", newPdfs);
    if (validPdfs.length < array.length) {
      enqueueSnackbar(
        "Alguns arquivos não puderam ser anexados pois seus nomes eram grandes demais!",
        { variant: "warning", autoHideDuration: 4000 }
      );
      enqueueSnackbar("O limite para o tamanho do nome é de 35 caracteres!", {
        variant: "warning",
        autoHideDuration: 4000,
      });
    }
  };

  const removePdf = (index) => {
    const copy = [...values.pdfs];
    if (copy.length === 1) {
      fileInputRef.current.value = null;
    }
    copy.splice(index, 1);
    setField("pdfs", copy);
  };

  const onPdfClick = (pdf) => {
    // if is not a local file
    if (pdf.id) {
      window.open(`${baseUrl}${pdf.url}`);
    } else {
      window.open(URL.createObjectURL(pdf));
    }
  };

  const onSectionBodyChange = (index, value) => {
    const copy = [...values.sections];
    copy[index].body = value;
    setField("sections", copy);
  };

  const onSectionTitleChange = (index, value) => {
    const copy = [...values.sections];
    copy[index].title = value;
    setField("sections", copy);
  };

  const validateData = () => {
    // assert(values.title !== "", "O título do artigo não pode ser vazio!");
    // assert(values.subtitle !== "", "O subtítulo do artigo não pode ser vazio!");
    assert(
      values.modules.length > 0,
      "Escolha pelo menos um módulo para este artigo!"
    );
    // assert(values.sections.length > 0, "O artigo deve ter pelo menos uma seção!");
    assert(
      values.specialties.length > 0,
      "Escolha pelo menos uma especialidade para este artigo!"
    );
    assert(
      values.sections.every((section) => section.title !== ""),
      "Todas as seções devem possuir título!"
    );
  };

  const createArticle = async () => {
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("data", values.date);
      formData.append("midias", JSON.stringify(values.images));
      formData.append("modulos", JSON.stringify(values.modules));
      formData.append(
        "secoes",
        JSON.stringify(
          values.sections.map((s) => ({
            titulo: s.title,
            corpo: s.body,
          }))
        )
      );
      formData.append("especialidades", JSON.stringify(values.specialties));
      formData.append("estado", values.state);
      formData.append("subtitulo", values.subtitle);
      formData.append("titulo", values.title);
      for (let i = 0; i < values.pdfs.length; i += 1) {
        formData.append("pdfs", values.pdfs[i]);
      }
      await api.post("/v2/portalMedico/artigo", formData, {
        headers: {
          "content-type": "multipart/form-data",
        },
      });
      enqueueSnackbar("Artigo criado com sucesso!", { variant: "success" });
      history.push("/portalMedico");
    } catch (e) {
      enqueueSnackbar("Não foi possível criar este artigo.", {
        variant: "error",
      });
    }
  };

  const editArticle = async () => {
    setLoading(true);
    try {
      const formData = new FormData();
      const oldPdfs = values.pdfs.filter((pdf) => pdf.id !== undefined);
      formData.append("midias", JSON.stringify([...values.images, ...oldPdfs]));
      formData.append("modulos", JSON.stringify(values.modules));
      formData.append(
        "secoes",
        JSON.stringify(
          values.sections.map((s) => ({
            titulo: s.title,
            corpo: s.body,
          }))
        )
      );
      formData.append("especialidades", JSON.stringify(values.specialties));
      formData.append("estado", values.state);
      formData.append("subtitulo", values.subtitle);
      formData.append("titulo", values.title);
      for (let i = 0; i < values.pdfs.length; i += 1) {
        if (!values.pdfs[i].id) {
          formData.append("pdfs", values.pdfs[i]);
        }
      }
      await api.put(`/v2/portalMedico/artigo/${values.id}`, formData, {
        headers: {
          "content-type": "multipart/form-data",
        },
      });
      /*
      await api.put(`/v2/portalMedico/artigo/${values.id}`, {
        imagens: values.images,
        modulos: values.modules,
        secoes: values.sections.map(s => ({
          titulo: s.title,
          corpo: s.body,
        })),
        especialidades: values.specialties,
        estado: values.state,
        subtitulo: values.subtitle,
        titulo: values.title,
        pdfs: {
          novos: values.pdfs.filter(pdf => !pdf.id),
          antigos: values.pdfs.filter(pdf => pdf.id),
        },
      });
      */
      enqueueSnackbar("Artigo editado com sucesso!", { variant: "success" });
      history.push("/portalMedico");
    } catch (e) {
      enqueueSnackbar("Não foi possível editar este artigo.", {
        variant: "error",
      });
    }
  };
  // eslint-disable-next-line
  const onSubmit = async () => {
    try {
      validateData();
    } catch (e) {
      return enqueueSnackbar(e.message, { variant: "error" });
    }

    setLoading(true);
    if (mode === "create") {
      // create ...
      await createArticle();
    } else {
      await editArticle();
    }
  };

  return (
    <>
      {loading && <Loading />}
      <SpecialtiesModal
        onClose={() => setShowSpecialtiesModal(false)}
        open={showSpecialtiesModal}
        onConfirm={addSpecialties}
      />
      <ModulesModal
        onClose={() => setShowModulesModal(false)}
        open={showModulesModal}
        onConfirm={addModules}
        mode="modules"
      />
      <Header titulo="Editor artigo" />
      <main className="editor-portal-medico-container">
        <input
          type="file"
          style={{ display: "none" }}
          multiple
          accept=".pdf"
          ref={fileInputRef}
          onChange={(e) => addPdfs(e.target.files)}
        />
        <section className="editor-top-container">
          <ArrowBackIcon
            onClick={() => history.push("/portalMedico")}
            fontSize="large"
            style={{ color: "#003399", cursor: "pointer" }}
          />
          <div className="editor-top-container-buttons">
            <Button
              style={{ marginRight: "1rem", fontSize: "1rem" }}
              onClick={onSubmit}
            >
              Publicar <PublishIcon style={{ marginLeft: "10px" }} />
            </Button>
            {/* }
            <Button style={{ backgroundColor: "#22c55e", fontSize: "1rem" }}>
              Salvar <SaveIcon style={{ marginLeft: "10px" }} />
            </Button>
            { */}
          </div>
        </section>
        <section className="editor-card shadow-md">
          <Input
            placeholder="O título do seu artigo"
            inputStyles={{
              fontSize: "1.8rem",
              fontWeight: "bold",
              width: "100%",
            }}
            value={values.title}
            onChange={(val) => setField("title", val)}
          />
          <Input
            value={values.subtitle}
            onChange={(val) => setField("subtitle", val)}
            placeholder="O subtítulo do seu artigo"
            inputStyles={{
              fontSize: "1.4rem",
              fontWeight: "500",
              width: "100%",
              marginTop: ".75rem",
            }}
          />

          <Grid container style={{ margin: "2rem 0" }}>
            <Grid item xs={12}>
              <DatePicker
                format="dd/MM/yyyy"
                label="Data do artigo"
                value={dayjs(values.date)}
                onChange={(val) => setField("date", val)}
              />
            </Grid>
          </Grid>
          <h2 className="areas-title">Módulos</h2>
          <div className="areas-container" style={{ marginBottom: "1.5rem" }}>
            {values.modules.map((module, index) => (
              <Pill
                onClose={() => removeModule(index)}
                style={{ marginRight: ".5rem", marginBottom: ".5rem" }}
                key={module.id}
              >
                {module.nome}
              </Pill>
            ))}
            <div
              className="add-area-button"
              onClick={() => setShowModulesModal(true)}
            >
              <AddIcon style={{ color: "#003399" }} />
              <p>Adicionar</p>
            </div>
          </div>
          <h2 className="areas-title">Especialidades</h2>
          <div className="areas-container" style={{ marginBottom: "1.5rem" }}>
            {values.specialties.map((specialty, index) => (
              <Pill
                onClose={() => removeSpecialty(index)}
                key={specialty.id}
                style={{ marginRight: ".5rem", marginBottom: ".5rem" }}
              >
                {specialty.nome}
              </Pill>
            ))}
            <div
              className="add-area-button"
              onClick={() => setShowSpecialtiesModal(true)}
            >
              <AddIcon style={{ color: "#003399" }} />
              <p>Adicionar</p>
            </div>
          </div>
          <h2 className="areas-title">Anexos (PDFs)</h2>
          <div className="areas-container">
            {values.pdfs.map((pdf, index) => (
              <Pill
                key={
                  pdf.id ? `pdf-${pdf.id}` : `${pdf.name}-${pdf.lastModified}`
                }
                onClose={() => removePdf(index)}
                onClick={() => onPdfClick(pdf)}
                style={{ marginRight: ".5rem", marginBottom: ".5rem" }}
              >
                {pdf.nome_arquivo || pdf.name}
              </Pill>
            ))}
            <div
              className="add-area-button"
              onClick={() => fileInputRef.current.click()}
            >
              <AddIcon style={{ color: "#003399" }} />
              <p>Adicionar</p>
            </div>
          </div>
        </section>
        <section className="article-sections">
          <AddSectionButton onAdd={() => addSection(0)} />
          {values.sections.map((section, index) => (
            <ArticleSection
              key={section.id}
              section={section}
              onTitleChange={(value) => onSectionTitleChange(index, value)}
              onBodyChange={(value) => onSectionBodyChange(index, value)}
              onRemove={() => removeSection(index)}
              onAdd={() => addSection(index + 1)}
              addImage={addImage}
            />
          ))}
        </section>
      </main>
    </>
  );
}
