import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import { format } from "date-fns";

import { Context } from "../../Context/PermissoesContext";

import Loading from "../../components/Loading";

import Formulario from "./Formulario";
import Cards from "./Cards";
import Tabela from "./Tabela";

import { api } from "../../services/Api";

import "./styles.css";
import Header from "../../components/Header";
import { Container } from "@mui/system";

export default function PrecosExames() {
  const history = useHistory();

  const { checarPermissao } = useContext(Context);
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(true);
  const [exames, setExames] = useState([]);

  const [convenio, setConvenio] = useState("TODAS");
  const [cod_exame, setCod_exame] = useState("TODOS");
  const [tabelas, setTabelas] = useState([]);
  const [vetorExames, setVetorExames] = useState([]);

  const [somaQtdExamesPerdas, setSomaQtdExamesPerdas] = useState(0);
  const [somaPerdas, setSomaPerdas] = useState(0);
  const [somaGanho, setSomaGanho] = useState(0);
  const [somaMelhorGanho, setSomaMelhorGanho] = useState(0);
  const [somaAumento, setSomaAumento] = useState(0);
  const [percentAumento, setPercentAumento] = useState(0);

  const [dataInicial, setDataInicial] = useState(new Date());
  const [dataFinal, setDataFinal] = useState(new Date());

  useEffect(() => {
    (() => {
      if (!checarPermissao("TPE001")) {
        history.push("/home");
      }
      return null;
    })();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const results = await api.get("/v2/precosExames/tabelasConvenios");
        const lista1 = [{ cod_tabela: 0, convenio: "TODAS" }];
        const lista2 = results.data;
        const listaConvenio = lista1.concat(lista2);
        setTabelas(listaConvenio);
        const results2 = await api.get("/v2/precosExames/vetorExames");
        const lista3 = [{ cod_exame: "TODOS" }];
        const lista4 = results2.data;
        const listaExame = lista3.concat(lista4);
        setVetorExames(listaExame);
      } catch (error) {
        enqueueSnackbar(
          "Ocorreu um erro ao carregar as tabelas dos convênios. Tente novamente.",
          { variant: "error" }
        );
      }
    })();
  }, [enqueueSnackbar]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const data_inicial = format(
          new Date(dataInicial),
          "yyyy-MM-dd 00:00:00"
        );
        const data_final = format(new Date(dataFinal), "yyyy-MM-dd 00:00:00");

        const resultado = await api.get(
          `/v2/precosExames/comparaPrecos/${convenio}/${cod_exame}/${data_inicial}/${data_final}`
        );
        const valores = resultado.data;

        setExames(valores);

        const somaExaperdas = valores.reduce((total, elemento) => {
          let valor = total;
          const ganhoDNA = elemento.preco_exame * elemento.quantidade_exame;
          const pagamento =
            elemento.preco_exame_parceiro * elemento.quantidade_exame;
          const caixa = ganhoDNA - pagamento;

          if (caixa < 0) {
            valor += caixa;
          }
          return valor;
        }, 0);

        const somaQtdExaperdas = valores.reduce((total, elemento) => {
          let valor = total;
          const ganhoDNA = elemento.preco_exame * elemento.quantidade_exame;
          const pagamento =
            elemento.preco_exame_parceiro * elemento.quantidade_exame;
          const caixa = ganhoDNA - pagamento;

          if (caixa < 0) {
            valor += 1;
          }
          return valor;
        }, 0);

        const somaAtGanho = valores.reduce((total, elemento) => {
          let valor = total;
          const ganhoDNA = elemento.preco_exame * elemento.quantidade_exame;
          const pagamento =
            elemento.preco_exame_parceiro * elemento.quantidade_exame;
          const caixa = ganhoDNA - pagamento;
          valor += caixa;
          return valor;
        }, 0);

        const somaMGanho = valores.reduce((total, elemento) => {
          let valor = total;
          const preco_db = elemento.preco_exame_db
            ? elemento.preco_exame_db
            : 100000000;
          const preco_dasa = elemento.preco_exame_dasa
            ? elemento.preco_exame_dasa
            : 100000000;
          const preco_hp = elemento.preco_hp
            ? elemento.preco_exame_hp
            : 100000000;

          let menor = Math.min(preco_db, preco_dasa, preco_hp);

          if (menor === 100000000) {
            menor = 0;
          }

          const ganhoDNA = elemento.preco_exame * elemento.quantidade_exame;
          const melhorGanho = menor * elemento.quantidade_exame;
          const caixa = ganhoDNA - melhorGanho;

          valor += caixa;
          return valor;
        }, 0);

        setSomaQtdExamesPerdas(somaQtdExaperdas);
        setSomaPerdas(somaExaperdas);
        setSomaGanho(somaAtGanho);
        setSomaMelhorGanho(somaMGanho);
        setSomaAumento(somaMGanho - somaAtGanho);
        setPercentAumento((somaMGanho - somaAtGanho) / somaAtGanho || 0);
      } catch (error) {
        enqueueSnackbar("Ocorreu um erro. Tente novamente.", {
          variant: "error",
        });
        setLoading(false);
        return;
      }
      setLoading(false);
    })();
  }, [enqueueSnackbar, convenio, cod_exame, dataInicial, dataFinal]);

  return (
    <>
      {loading && <Loading />}
      <Header titulo="Preços de Exames" />
      <Container fullWidth maxWidth="xl">
        <Formulario
          tabelas={tabelas}
          vetorExames={vetorExames}
          setConvenio={setConvenio}
          setCod_exame={setCod_exame}
          dataInicial={dataInicial}
          setDataInicial={setDataInicial}
          dataFinal={dataFinal}
          setDataFinal={setDataFinal}
        />
        <Cards
          somaGanho={somaGanho}
          somaMelhorGanho={somaMelhorGanho}
          somaAumento={somaAumento}
          somaQtdExamesPerdas={somaQtdExamesPerdas}
          somaPerdas={somaPerdas}
          percentAumento={percentAumento}
        />
        <Tabela exames={exames} />
      </Container>
    </>
  );
}
