import React from "react";
import { Button as MuiButton } from "@mui/material";
import { colorTheme } from "../../theme";

const blueButton = {
  contained: {
    backgroundColor: colorTheme.primary[500],
    color: "white",
    "&:hover": {
      backgroundColor: colorTheme.primary[700],
      color: "white",
    },
    "&:disabled": {
      color: "rgba(255, 255, 255, 0.6)",
      backgroundColor: "#a0a3a9",
    },
  },
  outlined: {
    color: colorTheme.primary[500],
    backgroundColor: "white",
    borderColor: colorTheme.primary[500],
    "&:hover": {
      color: colorTheme.primary[700],
      borderColor: colorTheme.primary[700],
    },
  },
  standard: {
    color: colorTheme.primary[500],
    "&:hover": {
      color: colorTheme.primary[700],
    },
  },
};

const greenButton = {
  contained: {
    color: "white",
    backgroundColor: colorTheme.success[500],
    "&:hover": {
      backgroundColor: colorTheme.success[700],
    },
  },
  outlined: {
    color: colorTheme.success[500],
    backgroundColor: "white",
    borderColor: colorTheme.success[500],
    "&:hover": {
      color: colorTheme.success[700],
      borderColor: colorTheme.success[700],
    },
  },
  standard: {
    color: colorTheme.success[500],
    "&:hover": {
      color: colorTheme.success[700],
    },
  },
};

const redButton = {
  contained: {
    color: "white",
    backgroundColor: colorTheme.danger[500],
    "&:hover": {
      backgroundColor: colorTheme.danger[700],
    },
  },
  outlined: {
    color: colorTheme.danger[500],
    backgroundColor: "white",
    borderColor: colorTheme.danger[500],
    "&:hover": {
      color: colorTheme.danger[700],
      borderColor: colorTheme.danger[700],
    },
  },
  standard: {
    color: colorTheme.danger[500],
    "&:hover": {
      color: colorTheme.danger[700],
    },
  },
};

const grayColor = {
  contained: {
    color: "white",
    backgroundColor: "#686868",
    "&:hover": {
      backgroundColor: "#3b3b3b",
    },
  },
  outlined: {
    color: "#686868",
    backgroundColor: "white",
    borderColor: "#686868",
    "&:hover": {
      color: "#3b3b3b",
      borderColor: "#3b3b3b",
    },
  },
  standard: {
    color: "#686868",
    "&:hover": {
      color: "#3b3b3b",
    },
  },
};

const verifyColor = (color, variant) => {
  let buttonColor = {};

  if (!variant) {
    variant = "standard";
  }

  switch (color) {
    case "blue":
      buttonColor = blueButton[variant];
      break;
    case "green":
      buttonColor = greenButton[variant];
      break;
    case "red":
      buttonColor = redButton[variant];
      break;
    case "gray":
      buttonColor = grayColor[variant];
      break;
    default:
      buttonColor = blueButton[variant];
      break;
  }

  return buttonColor;
};

export default function Button({ children, variant, color, ...rest }) {
  return (
    <MuiButton sx={verifyColor(color, variant)} variant={variant} {...rest}>
      {children}
    </MuiButton>
  );
}
