import React, { useState, useContext } from "react";

import { ArrowBack, Edit, RemoveRedEye, Settings } from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";

import Button from "../../../../componentsv2/Button";
import GridItem from "../../../../componentsv2/GridItem";
import GridContainer from "../../../../componentsv2/GridContainer";
import Paper from "../../../../componentsv2/Paper";
import Container from "../../../../componentsv2/Container";
import ModalSettings from "../../../../componentsv2/ModalSettings";

import { useHistory } from "react-router-dom";
import { Context } from "../../../../Context/PermissoesContext";
import { api } from "../../../../services/Api";
import { useSnackbar } from "notistack";

export default function OpcoesTabela({ row, atualizarTabela }) {
  const [open, setOpen] = useState(false);
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const { checarPermissao } = useContext(Context);

  const handleDelete = async () => {
    try {
      if (!checarPermissao("GDC004")) {
        return enqueueSnackbar("Erro! Você não tem premissão para excluir!", {
          variant: "error",
        });
      }
      await api.delete(`v2/controleDna/cliente/${row.id}`);
      atualizarTabela();
    } catch (error) {}
  };

  const handleEditar = async () => {
    try {
      if (!checarPermissao("GDC003")) {
        return enqueueSnackbar("Erro! Você não tem premissão para editar!", {
          variant: "error",
        });
      }
      history.push({
        pathname: "/gestaoDeClientes/editarCliente",
        state: { row },
      });
    } catch (error) {}
  };

  return (
    <>
      <Button style={{ color: "#000" }} onClick={() => setOpen(true)}>
        <Settings />
      </Button>
      <ModalSettings open={open} onClose={setOpen}>
        <Container className="options-container" maxWidth="xs">
          <Paper className="options-paper" elevation={2}>
            <GridContainer style={{ padding: "1rem 1rem" }}>
              <GridItem xs={12}>
                <Button
                  variant="standard"
                  startIcon={<RemoveRedEye />}
                  onClick={() => {
                    history.push({
                      pathname: "/gestaoDeClientes/visualizarCliente",
                      state: { row },
                    });
                  }}
                >
                  Visualizar
                </Button>
              </GridItem>
              <GridItem xs={12}>
                <Button variant="standard" startIcon={<Edit />} onClick={handleEditar}>
                  Editar
                </Button>
              </GridItem>

              <GridItem xs={12}>
                <Button
                  onClick={handleDelete}
                  variant="standard"
                  startIcon={<DeleteIcon />}
                >
                  Excluir
                </Button>
              </GridItem>

              <GridItem xs={12}>
                <Button
                  variant="standard"
                  startIcon={<ArrowBack />}
                  onClick={() => setOpen(false)}
                >
                  Voltar
                </Button>
              </GridItem>
            </GridContainer>
          </Paper>
        </Container>
      </ModalSettings>
    </>
  );
}
