import React, { useState, useContext } from "react";

import {
  Button as MuiButton,
  Container,
  Grid,
  Modal as MuiModal,
  Paper,
  Box,
  Typography,
  IconButton,
  Divider,
} from "@mui/material";
import SettingsIcon from '@mui/icons-material/Settings';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import EditIcon from "@mui/icons-material/Edit";
import { useHistory } from "react-router-dom";
import Button from "../../../../../componentsv2/Button";
import { Context as PermissoesContext } from "../../../../../Context/PermissoesContext";
import { useSnackbar } from "notistack";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import DadosContext from "./DadosContext";
import { api } from "../../../../../services/Api";
import { Modal } from "../../../../../componentsv2/Modal";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useEffect } from "react";
import { Input } from "../../../../../componentsv2/Input";
import { Close } from "@mui/icons-material";
import DataPickerAtualizado from "../../../../../components/DataPickerAtualizado";
import { colorTheme } from "../../../../../theme";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",

  bgcolor: "background.paper",
  boxShadow: 24,
};

const dividerStyle = {
  width: "100%",
  backgroundColor: "#909099",
};

export default function OpcoesTransferencia({ row }) {
  const [open, setOpen] = useState(false);
  const [modalAceitarTransferencia, setModalAceitarTransferencia] = useState(false);
  const history = useHistory();
  const [codTombador, setCodTombador] = useState("");
  const [marca, setMarca] = useState("");
  const [modelo, setModelo] = useState("");
  const [serial, setSerial] = useState("");
  const { update, setUpdate } = useContext(DadosContext);
  const { checarPermissao } = useContext(PermissoesContext);
  const { enqueueSnackbar } = useSnackbar();
  const [openModalVisualizar, setOpenModalVisualizar] = useState(false);

  const [unidadeOrigem, setUnidadeOrigem] = useState("");
  const [unidadeDestino, setUnidadeDestino] = useState("");
  const [dataAceite1, setDataAceite1] = useState(null);
  const [usuarioAceite, setUsuarioAceite] = useState("");
  const [dataSolicitacao, setDataSolicitacao] = useState(null);
  const [origemSetor, setOrigemSetor] = useState("");
  const [destinoSetor, setDestinoSetor] = useState("");

  const [origemUsuario, setOrigemUsuario] = useState("");
  const [destinoUsuario, setDestinoUsuario] = useState("");

  function ModalOption(props) {
    async function getTransferencia() {
      try {
        const {
          data: { data },
        } = await api.get(`/v2/inventario/transferenciaBem/${props.row.id}`);

        setOrigemUsuario(data.origemUsuario.username);
        setDestinoUsuario(data.destinoUsuario.username);

        setUnidadeOrigem(data.origemUnidade.nome);
        setUnidadeDestino(data.destinoUnidade.nome);
        setUsuarioAceite(data.usuarioAceite1?.username);
        setDataAceite1(data.dataAceite);
        setDataSolicitacao(data.dataSolicitacao);
        setOrigemSetor(data.origemSetor.nome);
        setDestinoSetor(data.destinoSetor.nome);
        setCodTombador(data.bem.codTombador);
        setMarca(data.bem.material.marca);
        setModelo(data.bem.material.modelo);
        setSerial(data.bem.serial);
      } catch (error) {
        enqueueSnackbar("Erro! Não foi possível achar a transferência.", {
          variant: "error",
          autoHideDuration: 3000,
        });
      }
    }

    useEffect(() => {
      getTransferencia();
      // eslint-disable-next-line
    }, []);

    const handleSubmitAceite = async () => {
      try {
        await api.put(`/v2/inventario/aceitaTransferenciaBem/${props.row.id}`);

        setModalAceitarTransferencia(false);
        setUpdate(!update);
        enqueueSnackbar("Transferência aceita com sucesso!", {
          variant: "success",
          autoHideDuration: 3000,
        });
      } catch (error) {
        enqueueSnackbar("Erro! Não foi possível aceitar a transferência.", {
          variant: "error",
          autoHideDuration: 3000,
        });
      }
    };

    const handleClickEdit = () => {
      if (checarPermissao("INV024")) {
        history.push({
          pathname: "/inventario/bem/criaTransferencia",
          state: { dados: props.row },
        });
      } else {
        enqueueSnackbar("Você não possui permissão para editar uma transferência!", {
          variant: "error",
          autoHideDuration: 3000,
        });
      }
    };

    return (
      <Container className="options-container" maxWidth="xs">
        <Paper className="options-paper" elevation={2}>
          <Grid container style={{ padding: "1rem 1rem" }}>
            <Grid item sm={12}>
              {dataAceite1 ? (
                <Button
                  variant="standard"
                  disabled
                  startIcon={<ThumbUpOffAltIcon />}
                  style={{ color: colorTheme.success[500] }}
                >
                  Transferência Aceita!
                </Button>
              ) : (
                <>
                  <Button
                    onClick={() => setModalAceitarTransferencia(true)}
                    variant="standard"
                    startIcon={<ThumbUpOffAltIcon />}
                  >
                    Aceitar Transferência
                  </Button>

                  <Modal
                    modalText="Você confirma a aceitação da transferência selecionada?"
                    open={modalAceitarTransferencia}
                    confirmActionButtonColor="green"
                    onClose={setModalAceitarTransferencia}
                    onClick={handleSubmitAceite}
                    confirmActionButtonText="Confirmar"
                  />
                </>
              )}
            </Grid>
            <Grid item sm={12}>
              <Button
                variant="standard"
                onClick={() => setOpenModalVisualizar(true)}
                startIcon={<VisibilityIcon />}
              >
                Visualizar
              </Button>
              <MuiModal open={openModalVisualizar} onClose={setOpenModalVisualizar}>
                <Box sx={modalStyle}>
                  <Grid container spacing={0}>
                    <Grid sx={{ padding: "15px" }} item container spacing={0}>
                      <Grid container justifyContent="space-between" item>
                        <Grid item>
                          <Typography variant="h6">
                            {`Bem: ${
                              codTombador ? codTombador + " -" : ""
                            } ${modelo}(${marca}) - ${serial}`}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <IconButton onClick={() => setOpenModalVisualizar(false)}>
                            <Close />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Divider sx={dividerStyle} />
                    <Grid
                      sx={{ padding: "30px", marginBottom: "20px" }}
                      item
                      container
                      spacing={3}
                    >
                      <Grid item xs={6}>
                        <Input
                          label="Unidade Origem"
                          variant="standard"
                          fullWidth
                          onChange={e => setUnidadeOrigem(e.target.value)}
                          value={unidadeOrigem}
                          inputProps={{ readOnly: true }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Input
                          label="Unidade Destino"
                          variant="standard"
                          fullWidth
                          onChange={e => setUnidadeDestino(e.target.value)}
                          value={unidadeDestino}
                          inputProps={{ readOnly: true }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Input
                          label="Setor Origem"
                          variant="standard"
                          fullWidth
                          onChange={e => setOrigemSetor(e.target.value)}
                          value={origemSetor}
                          inputProps={{ readOnly: true }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Input
                          label="Setor Destino"
                          variant="standard"
                          fullWidth
                          onChange={e => setDestinoSetor(e.target.value)}
                          value={destinoSetor}
                          inputProps={{ readOnly: true }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Input
                          label="Usuário Origem"
                          variant="standard"
                          fullWidth
                          onChange={e => setOrigemUsuario(e.target.value)}
                          value={origemUsuario}
                          inputProps={{ readOnly: true }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Input
                          label="Usuário Destino"
                          variant="standard"
                          fullWidth
                          onChange={e => setDestinoUsuario(e.target.value)}
                          value={destinoUsuario}
                          inputProps={{ readOnly: true }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <DataPickerAtualizado
                          disabled={true}
                          data={dataSolicitacao}
                          setData={setDataSolicitacao}
                          labelName="Data Solicitação"
                          variant="standard"
                        />
                      </Grid>
                      {usuarioAceite && dataAceite1 ? (
                        <>
                          <Grid item xs={6}>
                            <DataPickerAtualizado
                              disabled={true}
                              data={dataAceite1}
                              setData={setDataAceite1}
                              labelName="Data Aceite"
                              variant="standard"
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <Input
                              label="Usuário Aceite"
                              variant="standard"
                              fullWidth
                              onChange={e => setUsuarioAceite(e.target.value)}
                              value={usuarioAceite}
                              inputProps={{ readOnly: true }}
                            />
                          </Grid>
                        </>
                      ) : null}
                    </Grid>
                  </Grid>
                </Box>
              </MuiModal>
            </Grid>
            <Grid item sm={12}>
              <Button
                onClick={handleClickEdit}
                variant="standard"
                startIcon={<EditIcon />}
              >
                Editar
              </Button>
            </Grid>
            <Grid item sm={12}>
              <Button
                onClick={() => setOpen(false)}
                variant="standard"
                startIcon={<ArrowBackIcon />}
              >
                Voltar
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Container>
    );
  }

  return (
    <>
      <MuiButton style={{ color: "#000" }} onClick={() => setOpen(true)}>
        <SettingsIcon />
      </MuiButton>
      <MuiModal open={open} onClose={() => setOpen(false)}>
        <ModalOption row={row} />
      </MuiModal>
    </>
  );
}
