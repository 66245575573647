import Axios from "axios";

export default async function buscaCep(cep) {
  try {
    const { data } = await Axios.get(`https://api.pagar.me/1/zipcodes/${cep}`);

    return data

    } catch(error) {
      console.log(error)
    }
  return null
}
