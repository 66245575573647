/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from "react";
import CloseIcon from "@mui/icons-material/Close";

import "./styles.css";

export default function Pill({ children, onClose, style, onClick }) {
  return (
    <div className="pill" style={style}>
      <p
        onClick={() => {
          if (onClick) {
            onClick();
          }
        }}
        style={{ cursor: onClick ? "pointer" : "default" }}
      >
        {children}
      </p>
      <CloseIcon
        style={{ color: "white", cursor: "pointer" }}
        onClick={() => {
          if (onClose) {
            onClose();
          }
        }}
      />
    </div>
  );
}
