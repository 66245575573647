import React from "react";
import { Input } from "../../../componentsv2/Input";
import {
  Box,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
} from "@mui/material";
import Button from "../../../componentsv2/Button";

export default function ModalConfirmarNaoAprovacao({
  open,
  onClose,
  observacao,
  setObservacao,
  onSubmit,
}) {
  return (
    <React.Fragment>
      <Dialog
        open={open}
        fullWidth
        maxWidth="sm"
        onClose={() => onClose(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle sx={{ paddingBottom: "0px", margin: 0, padding: 0 }}>
          Tem certeza que deseja <b>não</b> autorizar este documento?
        </DialogTitle>
        <form onSubmit={onSubmit}>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Grid xs={12} item>
                <Input
                  multiline
                  onChange={(e) => setObservacao(e.target.value)}
                  value={observacao}
                  fullWidth
                  label="Observação"
                  type="text"
                  variant="standard"
                />
              </Grid>
            </DialogContentText>
          </DialogContent>
          <Box
            sx={{
              display: "flex",
              margin: "20px",
              justifyContent: "space-between",
              marginLeft: "15px",
              marginRight: "15px",
            }}
          >
            <Button
              variant="outlined"
              onClick={() => onClose(false)}
              type="button"
            >
              Cancelar
            </Button>
            <Button variant="contained" type="submit">
              Confirmar
            </Button>
          </Box>
        </form>
      </Dialog>
    </React.Fragment>
  );
}
