import React from "react";

import { Grid } from '@mui/material';

import { Select } from "../Select";

export default function SelectEstado({
  onChange,
  value,
  disabled = false,
  required = false,
}) {
  const options = [
    "AC",
    "AL",
    "AP",
    "AM",
    "BA",
    "CE",
    "DF",
    "ES",
    "GO",
    "MA",
    "MT",
    "MS",
    "MG",
    "PA",
    "PB",
    "PR",
    "PE",
    "PI",
    "RJ",
    "RN",
    "RS",
    "RO",
    "RR",
    "SC",
    "SP",
    "SE",
    "TO",
  ];
  return (
    <>
      <Grid container spacing={1} justify="center" alignItems="stretch">
        <Grid item xs={12}>
          <Select
            disabled={disabled}
            required={required}
            onChange={onChange}
            value={value}
            label="Estado"
            arrayOptions={options}
            variant="standard"
          />
        </Grid>
      </Grid>
    </>
  );
}
