import { createTheme } from "@mui/material";
import { colorTheme } from "../theme";

export const globalTheme = createTheme({
  typography: {
    fontFamily: ["Roboto", "sans-serif"],
    button: {
      fontWeight: 700,
      textTransform: "unset",
    },
  },
  palette: {
    text: {
      primary: colorTheme.text.primary,
      secondary: colorTheme.text.secondary,
    },
    primary: {
      main: colorTheme.primary[500],
      light: colorTheme.primary[500],
    },
    secondary: {
      main: colorTheme.secondary[500],
      light: colorTheme.secondary[500],
    },
    success: {
      main: colorTheme.success[500],
      light: colorTheme.success[500],
    },
    warning: {
      main: colorTheme.warning[500],
      light: colorTheme.warning[500],
    },
    error: {
      main: colorTheme.danger[500],
      light: colorTheme.danger[500],
    },
  },
});
