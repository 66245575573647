import React, { useState, useEffect, useMemo, useContext } from "react";
import { useSnackbar } from "notistack";

import Trilha from "../../../components/Trilha";
import Loading from "../../../components/Loading";
import Modal from "../../../components/Modal";

import ExamDialog, { useDialog } from "../ExamDialog";

import { Context } from "../../../Context/PermissoesContext";

import capitalize from "../../../utils/capitalize";

import "./styles.css";

import { api } from "../../../services/Api";

export default function Esteira() {
  const [exames, setExames] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [deleteTargetId, setDeleteTargetId] = useState(-1);

  const { enqueueSnackbar } = useSnackbar();

  const { checarPermissaoComAcao } = useContext(Context);

  const estados = useMemo(
    () => [
      "agendado",
      "coletado",
      "extração",
      "conferência",
      "concluído",
      "entregue",
    ],
    []
  );

  const {
    showDialog,
    setShowDialog,
    dialogValues,
    setDialogValues,
    modoDialog,
    setModoDialog,
    exameSelecionado,
    setExameSelecionado,
  } = useDialog();

  useEffect(() => {
    (async () => {
      try {
        const { data } = await api.get(`/v2/agendaPaternidade/exames/todos`);
        setExames(data);
      } catch (e) {
        enqueueSnackbar(
          "Não foi possível recuperar os exames. Por favor, tente novamente.",
          { variant: "error", autoHideDuration: 3000 }
        );
      }
      setLoading(false);
    })();
  }, [enqueueSnackbar]);

  function abrirDialog(id, modo) {
    const indexTarget = exames.findIndex(e => e.id === id);
    setDialogValues({
      ...exames[indexTarget],
      id_horario: exames[indexTarget].horario.id,
      tipo_exame: exames[indexTarget].tipo,
      id_unidade: exames[indexTarget].horario.id_unidade,
    });
    setExameSelecionado(indexTarget);
    setModoDialog(modo);
    setShowDialog(true);
  }

  /*
  function fecharDialog() {
    setExameSelecionado(-1);
    setDialogValues({
      data: new Date(Date.now()),
      nome_cliente: "",
      cpf_cliente: "",
      celular_cliente: "",
      autorizacao: "cliente", // cliente ou outros
      observacoes: "",
      autorizados: [],
      anexos: [],
      id: null,
    });
  }
  */

  function deletarExame(id) {
    if (!confirmDelete) {
      setDeleteTargetId(id);
      setConfirmDelete(true);
      setShowModal(true);
      return;
    }
    checarPermissaoComAcao("AGP004", async () => {
      setShowModal(false);
      const index = exames.findIndex(e => e.id === id);
      setLoading(true);
      try {
        await api.delete(`/v2/agendaPaternidade/exame/${id}`);
        enqueueSnackbar("Exame deletado!", {
          variant: "success",
          autoHideDuration: 3000,
        });
        const copiaExames = [...exames];
        copiaExames.splice(index, 1);
        setExames(copiaExames);
        setDeleteTargetId(-1);
        setConfirmDelete(false);
      } catch (e) {
        enqueueSnackbar(
          "Não foi possível deletar este exame. Por favor, tente novamente.",
          { variant: "error", autoHideDuration: 3000 }
        );
      }
      return setLoading(false);
    });
  }

  async function moveHorizontal(target, novoEstado, index) {
    checarPermissaoComAcao("AGP003", async () => {
      try {
        setLoading(true);
        // atualizando no backend
        await api.patch(`/v2/agendaPaternidade/exame/${target.id}`, {
          ...target,
          estado: novoEstado,
        });
        // atualizando locamente
        const copiaExames = [...exames];
        copiaExames[index].estado = novoEstado;
        setExames(copiaExames);
      } catch (e) {
        enqueueSnackbar(
          "Não foi possível realizar esta ação. Por favor, tente novamente.",
          { variant: "error", autoHideDuration: 3000 }
        );
      }
      setLoading(false);
    });
  }
  async function moveLeft(id) {
    checarPermissaoComAcao("AGP008", () => {
      const index = exames.findIndex(e => e.id === id);
      const target = exames[index];
      const estadoIndex = estados.findIndex(e => e === target.estado);
      if (target.estado !== "agendado") {
        moveHorizontal(target, estados[estadoIndex - 1], index);
      }
    });
  }

  async function moveRight(id) {
    checarPermissaoComAcao("AGP008", () => {
      const index = exames.findIndex(e => e.id === id);
      const target = exames[index];
      const estadoIndex = estados.findIndex(e => e === target.estado);
      if (target.estado !== "entregue") {
        moveHorizontal(target, estados[estadoIndex + 1], index);
      }
    });
  }

  async function moveVertical(target, targetIndex, replacement, replacementIndex) {
    const copia = [...exames];
    const aux = target;

    copia[targetIndex] = replacement;
    copia[replacementIndex] = aux;
    setExames(copia);
  }

  async function moveUp(id) {
    const targetIndex = exames.findIndex(e => e.id === id);
    const target = exames[targetIndex];
    let prevIndex;

    for (let i = targetIndex - 1; i >= 0; i -= 1) {
      if (exames[i].estado === target.estado) {
        prevIndex = i;
        break;
      }
    }

    if (prevIndex === undefined) {
      return;
    }

    const prev = exames[prevIndex];

    moveVertical(target, targetIndex, prev, prevIndex);
  }

  async function moveDown(id) {
    const targetIndex = exames.findIndex(e => e.id === id);
    const target = exames[targetIndex];
    let nextIndex;

    for (let i = targetIndex + 1; i < exames.length; i += 1) {
      if (exames[i].estado === target.estado) {
        nextIndex = i;
        break;
      }
    }

    if (nextIndex === undefined) {
      return;
    }

    const next = exames[nextIndex];

    moveVertical(target, targetIndex, next, nextIndex);
  }

  return (
    <>
      {loading && <Loading />}
      <Modal
        open={showModal}
        title="Excluir exame"
        text="Tem certeza de que deseja excluir este exame?"
        onConfirm={() => deletarExame(deleteTargetId)}
        onCancel={() => {
          setConfirmDelete(false);
          setDeleteTargetId(-1);
          setShowModal(false);
        }}
      />
      <ExamDialog
        open={showDialog}
        setOpen={setShowDialog}
        modo={modoDialog}
        dialogValues={dialogValues}
        setDialogValues={setDialogValues}
        exameSelecionado={exameSelecionado}
        setExameSelecionado={setExameSelecionado}
        setExames={setExames}
        exames={exames}
        pagina="esteira"
      />
      <div className="esteira">
        {estados.map(estado => (
          <Trilha
            titulo={capitalize(estado)}
            key={estado}
            exames={exames.filter(exame => exame.estado === estado)}
            abrirDialog={abrirDialog}
            deletarExame={deletarExame}
            moveRight={moveRight}
            moveLeft={moveLeft}
            moveUp={moveUp}
            moveDown={moveDown}
          />
        ))}
      </div>
    </>
  );
}
