import React, { useContext, useEffect } from "react";
import { useSnackbar } from "notistack";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Input,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";

import { ColetaDomiciliarContext } from "../../../Context/ColetaDomiciarContext";
import { FormularioColetaDomiciliarContext } from "../../../Context/FormularioColetaDomiciliarContext";
import { ModaisColetaDomiciliarContext } from "../../../Context/ModaisColetaDomiciarContext";

import SalvaLog from "../../../../../utils/SalvaLog";

import { api } from "../../../../../services/Api";

export default function ModalValorRecebido() {
  const { enqueueSnackbar } = useSnackbar();

  const { fazPagamento, modalValor_recebido, setModalValor_recebido } =
    useContext(ModaisColetaDomiciliarContext);

  const {
    tipo_cliente,
    codigoPedido,
    setTipo_cliente,
    cpf_cliente,
    setCpf_cliente,
    nome,
    setNome,
    email,
    setEmail,
    setEndereco,
    setEndereco_numero,
    setComplemento,
    setBairro,
    setCidade,
    setEstado,
    setCep,
    setCelular,
    valor_recebido,
    setValor_recebido,
    tipo_pagamento,
    setTipo_pagamento,
    numero_cartao,
    setNumero_cartao,
  } = useContext(FormularioColetaDomiciliarContext);

  const { coleta } = useContext(ColetaDomiciliarContext);

  useEffect(() => {
    (async () => {
      //setando dados de coleta que foi paga ou tem codigo do pedido
      if (coleta?.pagou) {
        setTipo_cliente(coleta.tipo_cliente || "Normal");
        setCpf_cliente(coleta.cpf);
        setTipo_pagamento(coleta.tipo_pagamento);
        setNumero_cartao(coleta.numero_cartao);
        setValor_recebido(coleta.valor_recebido);
        setNome(coleta.nome);
        setEmail(coleta.email);
      } else if (codigoPedido) {
        try {
          const [codigo, posto] = codigoPedido.split("-");
          const { data: resultado } = await api.post("/api/softlab/pedido", {
            codigo,
            posto,
          });
          setCpf_cliente(resultado.cpf);

          setEndereco((prevState) => resultado.endereco_cliente || prevState);
          setEndereco_numero(
            (prevState) => resultado.endereco_numero_cliente || prevState
          );
          setComplemento(
            (prevState) => resultado.complemento_cliente || prevState
          );
          setBairro((prevState) => resultado.bairro_cliente || prevState);
          setCidade((prevState) => resultado.cidade_cliente || prevState);
          setEstado((prevState) => resultado.estado_cliente || prevState);
          setCep((prevState) => resultado.cep_cliente || prevState);
          setCelular((prevState) => resultado.celular_cliente || prevState);

          const novoEmail =
            resultado.email === ""
              ? coleta.email
              : resultado.email === ""
              ? ""
              : resultado.email;
          // setEmail(coleta.email);
          setTipo_cliente(coleta.tipo_cliente || "Normal");
          setEmail(novoEmail);
          setValor_recebido(coleta.valor_recebido);

          // SalvaLog(
          //   `Buscou pelo cpf do codigo do pedido ${codigoPedido}`,
          //   "Coleta Domiciliar",
          //   JSON.stringify(coleta),
          //   `${coleta.id}`
          // );
        } catch (error) {
          console.log("Erro setando dados de coleta - ", error);
          // SalvaLog(
          //   `Tentou buscar pelo cpf do codigo do pedido ${codigoPedido}`,
          //   "Coleta Domiciliar",
          //   JSON.stringify(coleta),
          //   `${coleta.id}`
          // );
        }
      }
    })();
    // eslint-disable-next-line
  }, [coleta, codigoPedido, modalValor_recebido]);

  function mudaValor(e, setValor) {
    e.preventDefault();
    setValor(e.target.value);
  }

  function alteraCpf(cpfDigitado) {
    const cpfSemPontuacao = cpfDigitado
      ? cpfDigitado.replace(/[^0-9]/g, "")
      : "";
    setCpf_cliente(cpfSemPontuacao);
  }

  return (
    <Dialog
      open={modalValor_recebido}
      onClose={() => setModalValor_recebido(false)}
    >
      <DialogTitle id="alert-dialog-title" style={{ margin: "0" }}>
        Informações sobre a coleta
      </DialogTitle>
      <DialogContent>
        <Grid item xs={12} container spacing={2}>
          <Grid item xs={6}>
            <InputLabel className="nomeHora">Tipo de cliente</InputLabel>
            <Select
              fullWidth
              variant="standard"
              className="selectForm"
              value={tipo_cliente}
              onChange={(e) => {
                mudaValor(e, setTipo_cliente);
              }}
            >
              <MenuItem key="Normal" value="Normal">
                Normal
              </MenuItem>
              <MenuItem key="VIP" value="VIP">
                VIP
              </MenuItem>
              <MenuItem key="VIP PÓS" value="VIP PÓS">
                VIP PÓS
              </MenuItem>
              <MenuItem key="DNACOV" value="DNACOV">
                DNACOV
              </MenuItem>
            </Select>
          </Grid>
          <Grid item xs={6}>
            <InputLabel className="nomeHora">Cpf do cliente</InputLabel>
            <Input
              autoFocus
              fullWidth
              value={cpf_cliente}
              placeholder="cpf"
              onChange={(event) => alteraCpf(event.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <InputLabel className="nomeHora">Nome do cliente</InputLabel>
            <Input
              autoFocus
              fullWidth
              value={nome}
              placeholder="nome"
              onChange={(event) => setNome(event.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <InputLabel className="nomeHora">Email do cliente</InputLabel>
            <Input
              autoFocus
              fullWidth
              value={email}
              placeholder="email"
              onChange={(event) => setEmail(event.target.value)}
            />
          </Grid>
          {tipo_cliente === "VIP" || tipo_cliente === "VIP PÓS" ? (
            <>
              <Grid item xs={6}>
                <InputLabel className="nomeHora">Tipo de pagamento</InputLabel>
                <Select
                  disabled
                  fullWidth
                  variant="standard"
                  className="selectForm"
                  value={tipo_pagamento}
                  onChange={(e) => {
                    mudaValor(e, setTipo_pagamento);
                  }}
                >
                  <MenuItem key="Pix" value="Pix">
                    Pix
                  </MenuItem>
                </Select>
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={6}>
                <InputLabel className="nomeHora">Tipo de pagamento</InputLabel>
                <Select
                  fullWidth
                  variant="standard"
                  className="selectForm"
                  value={tipo_pagamento}
                  onChange={(e) => {
                    mudaValor(e, setTipo_pagamento);
                  }}
                >
                  <MenuItem key="Cartao" value="Cartão de crédito">
                    Cartão de crédito
                  </MenuItem>
                  <MenuItem key="Dinheiro" value="Dinheiro">
                    Dinheiro
                  </MenuItem>
                  <MenuItem key="Pix" value="Pix">
                    Pix
                  </MenuItem>
                </Select>
              </Grid>
            </>
          )}

          {tipo_pagamento === "Cartão de crédito" && (
            <Grid item xs={6}>
              <InputLabel className="nomeHora">Últimos 4 dígitos</InputLabel>
              <Input
                fullWidth
                value={numero_cartao}
                placeholder="4 digitos"
                onChange={(event) => setNumero_cartao(event.target.value)}
              />
            </Grid>
          )}

          {tipo_cliente === "VIP" || tipo_cliente === "VIP PÓS" ? (
            <>
              <Grid item xs={6}>
                <InputLabel className="nomeHora">Valor recebido</InputLabel>
                <Input
                  disabled
                  fullWidth
                  value={valor_recebido}
                  placeholder="digite valor"
                  onChange={(event, value) =>
                    setValor_recebido(event.target.value)
                  }
                />
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={6}>
                <InputLabel className="nomeHora">Valor recebido</InputLabel>
                <Input
                  fullWidth
                  value={valor_recebido}
                  placeholder="digite valor"
                  onChange={(event, value) =>
                    setValor_recebido(event.target.value)
                  }
                />
              </Grid>
            </>
          )}
        </Grid>
      </DialogContent>
      <DialogActions style={{ padding: "0.9rem" }}>
        {tipo_cliente === "VIP" || tipo_cliente === "VIP PÓS" ? (
          <>
            <button
              type="button"
              className="btnDialogs"
              onClick={() => {
                if (cpf_cliente.length >= 11) {
                  fazPagamento();
                } else {
                  enqueueSnackbar("CPF inválido ou não informado", {
                    variant: "warning",
                  });
                }
              }}
            >
              Confirmar
            </button>
          </>
        ) : (
          <>
            <button
              type="button"
              className="btnDialogs"
              onClick={() => {
                const valorRecebidoConvertido = valor_recebido.replaceAll(
                  ",",
                  "."
                );
                if (valorRecebidoConvertido > 0 && cpf_cliente.length >= 11) {
                  fazPagamento();
                } else {
                  enqueueSnackbar(
                    "CPF inválido/não informado ou valor recebido não informado",
                    {
                      variant: "warning",
                    }
                  );
                }
              }}
            >
              Confirmar
            </button>
          </>
        )}
        <button
          type="button"
          className="btnDialogs"
          onClick={() => setModalValor_recebido(false)}
        >
          Desistir
        </button>
      </DialogActions>
    </Dialog>
  );
}
