import React, { useState, useContext } from "react";

import { ArrowBack, Edit, RemoveRedEye, Settings } from "@mui/icons-material";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import DeleteIcon from "@mui/icons-material/Delete";

import Button from "../../../../componentsv2/Button";
import GridItem from "../../../../componentsv2/GridItem";
import GridContainer from "../../../../componentsv2/GridContainer";
import Paper from "../../../../componentsv2/Paper";
import Container from "../../../../componentsv2/Container";
import ModalSettings from "../../../../componentsv2/ModalSettings";
import ModalReagendar from "../../Components/ModalReagendar";
import { Context } from "../../../../Context/PermissoesContext";

import { useHistory } from "react-router-dom";
import { api } from "../../../../services/Api";
import { useSnackbar } from "notistack";

export default function OpcoesTabela({ row, atualizarTabela }) {
  const [open, setOpen] = useState(false);
  const history = useHistory();

  const { checarPermissao } = useContext(Context);

  const [openModalReagendar, setOpenModalReagendar] = useState(false);

  const { enqueueSnackbar } = useSnackbar()

  const handleDelete = async () => {
    try {
      if (!checarPermissao("AGC001")) {
        return;
      }
      await api.delete(`/v2/agendaCare/agendamento/${row.id}`);
      atualizarTabela();
      enqueueSnackbar("Exclusão feito com sucesso!", {
        variant: "success",
      });
    } catch (error) {
      enqueueSnackbar("Erro ao tentar excluir agendamento!", {
        variant: "error",
      })
    }
  };
 const onCloseModalReagendar = ()=>{
  setOpenModalReagendar(false)
  atualizarTabela()
  setOpen(false)
}
  return (
    <>
      <Button style={{ color: "#000" }} onClick={() => setOpen(true)}>
        <Settings />
      </Button>
      <ModalSettings open={open} onClose={setOpen}>
        <Container className="options-container" maxWidth="xs">
          <Paper className="options-paper" elevation={2}>
            <GridContainer style={{ padding: "1rem 1rem" }}>
              <GridItem xs={12}>
                <Button
                  variant="standard"
                  startIcon={<RemoveRedEye />}
                  onClick={() => {
                    history.push({
                      pathname: "/agendaCare/visualizarAgendamento",
                      state: { row },
                    });
                  }}
                >
                  Visualizar
                </Button>
              </GridItem>
              <GridItem xs={12}>
                <Button
                  variant="standard"
                  startIcon={<Edit />}
                  onClick={() => {
                    history.push({
                      pathname: "/agendaCare/editarAgendamento",
                      state: { row },
                    });
                  }}
                >
                  Editar
                </Button>
              </GridItem>

              <GridItem xs={12}>
                <Button
                  variant="standard"
                  startIcon={<ChangeCircleIcon />}
                  onClick={() => setOpenModalReagendar(true)}
                >
                  Reagendar
                </Button>
              </GridItem>

              <GridItem xs={12}>
                <Button
                  onClick={handleDelete}
                  variant="standard"
                  startIcon={<DeleteIcon />}
                >
                  Excluir
                </Button>
              </GridItem>

              <GridItem xs={12}>
                <Button
                  variant="standard"
                  startIcon={<ArrowBack />}
                  onClick={() => setOpen(false)}
                >
                  Voltar
                </Button>
              </GridItem>
            </GridContainer>
          </Paper>
        </Container>
      </ModalSettings>
      <ModalReagendar
        row={row}
        open={openModalReagendar}
        onClose={onCloseModalReagendar}
      />
    </>
  );
}
