import { useSnackbar } from "notistack";
import React, { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import Loading from "../../components/Loading";
import Header from "../../components/Header";
import Container from "../../componentsv2/Container";
import { Context as PermissoesContext } from "../../Context/PermissoesContext";
import BI from "./BI";

import PainelControle from "./PainelControle";
import PesquisaUsuario from "./PesquisaUsuario";
import "./styles.css";

export default function ControleAcesso() {
  const history = useHistory();
  const location = useLocation();
  const usuario = location.state;
  const [aba, setAba] = useState(1);
  const { enqueueSnackbar } = useSnackbar();

  const [usuarioSelecionado, setUsuarioSelecionado] = useState(null);
  const [loading, setLoading] = useState(false);

  const { checarPermissao } = useContext(PermissoesContext);

  if (!checarPermissao("CA001")) {
    history.push("/home");
  }

  useEffect(() => {
    if (usuario) {
      const usuarioOb = {
        usuario: {
          id: usuario.username.id,
          username: usuario.username.cod_usu,
          id_papel: null,
          controle: true,
        },
      };

      setUsuarioSelecionado(usuarioOb.usuario);
    }
  }, [usuario]);

  return (
    <>
      <Header titulo="Controle Acesso"/>
      <Container >
        {loading && <Loading />}

        <div style={{ paddingBottom: "0" }} id="container-aba-ctrl">
          <button
            type="button"
            id={aba === 1 ? "button-aba-ctrl-samples-selected" : "Aba1"}
            className="button-aba-ctrl"
            style={{ fontSize: "16px" }}
            onClick={() => setAba(1)}
          >
            Controle
          </button>

          <button
            type="button"
            id={aba === 2 ? "button-aba-ctrl-samples-selected" : "Aba2"}
            className="button-aba-ctrl"
            style={{ fontSize: "16px" }}
            onClick={() => setAba(2)}
          >
            BI
          </button>
        </div>
        {aba === 1 && usuarioSelecionado && (
          <PainelControle
            usuarioSelecionado={usuarioSelecionado}
            setUsuarioSelecionado={setUsuarioSelecionado}
            setLoading={setLoading}
          />
        )}
        {aba === 1 && !usuarioSelecionado && (
          <PesquisaUsuario
            setLoading={setLoading}
            setUsuarioSelecionado={setUsuarioSelecionado}
            enqueueSnackbar={enqueueSnackbar}
          />
        )}
        {aba === 2 && <BI />}
      </Container>
    </>
  );
}
