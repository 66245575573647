import React from "react";
import { Autocomplete, TextField } from '@mui/material';

import DataPickerAtualizado from "../../../components/DataPickerAtualizado";

import "./styles.css";

export default function Formulario(props) {
  const {
    tabelas,
    vetorExames,
    setConvenio,
    setCod_exame,
    dataInicial,
    setDataInicial,
    dataFinal,
    setDataFinal 
  } = props;

  return (
    <div className="container-formulario-mapeamento-exames">
      <div className="container-filtro-mapeamento-exames">
        <DataPickerAtualizado
          labelName="Data inicial"
          data={dataInicial}
          setData={setDataInicial}
        />
        <DataPickerAtualizado
          labelName="Data final"
          data={dataFinal}
          setData={setDataFinal}
        />
      </div>
      <div className="container-select-filtros">
        <Autocomplete
          id="select-exames-dna"
          onChange={(e, newValue) => {
            if (newValue) {
              setCod_exame(newValue.cod_exame);
            }
          }}
          options={vetorExames}
          getOptionLabel={(option) => option.cod_exame}
          style={{ margin: "0 1rem 1rem 1rem", width: 250 }}
          renderInput={(params) => <TextField {...params} label="Exames" variant="outlined" />}
        />
        <Autocomplete
          id="select-tables-dna"
          onChange={(e, newValue) => {
            if (newValue) {
              setConvenio(newValue.convenio);
            }
          }}
          options={tabelas}
          getOptionLabel={(option) => option.convenio}
          style={{ marginLeft: "1rem", width: 500, color: "black" }}
          renderInput={(params) => <TextField {...params} label="Tabelas" variant="outlined" />}
        />
      </div>
    </div>
  )
}
