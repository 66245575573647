import { ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  InputLabel,
} from "@mui/material";
import { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { Context as PermissoesContext } from "../../../Context/PermissoesContext";
import { enqueueSnackbar } from "notistack";

export default function TabelaAccordionEmpresa({ empresas }) {
  const [expanded, setExpanded] = useState(false);
  const history = useHistory();
  const { checarPermissao } = useContext(PermissoesContext);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleClickEdit = (empresa) => {
    if (!checarPermissao("GEC003")) {
      enqueueSnackbar("É necessário a permissão GEC003", {
        variant: "error",
      });
    } else {
      history.push({
        pathname: "/financeiro/fornecedor/criar",
        state: { dados: empresa },
      });
    }
  };

  console.log(empresas);
  return (
    <div>
      {empresas?.map((empresa) => (
        <Accordion
          key={empresa.id}
          expanded={expanded === `panel${empresa.id}`}
          onChange={handleChange(`panel${empresa.id}`)}
          style={{
            borderRadius: "4px",
            marginBottom: "6px",
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="panel1a-content"
            id="container-cell-table"
            style={{
              borderRadius: "4px",
              backgroundColor:
                empresa.status === "Aprovado" ? "#CEF6E3" : "#FFF",
            }}
          >
            <div id="container-label-dialog-coleta">
              <span id="label-dialog-tittle-coleta">{empresa.nome}</span>
              <span id="label-dialog-coleta">{empresa.cpf_cnpj}</span>
              <span id="label-dialog-coleta">{empresa.telefone}</span>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div>
              <InputLabel id="label1-table-dialog-coleta">
                {empresa.descricao}
              </InputLabel>

              <div id="container-button-accordeon-coleta">
                <div className="button-accordeon-coleta">
                  <button
                    id="btn-coleta-check"
                    className="btn-coleta-check-default"
                    type="button"
                    onClick={() => {
                      if (checarPermissao("GEC003")) {
                        handleClickEdit(empresa);
                      } else {
                        enqueueSnackbar(
                          "Você não possui permissão para editar fornecedores!",
                          {
                            variant: "error",
                            autoHideDuration: 3000,
                          }
                        );
                      }
                    }}
                  >
                    Editar Empresa
                  </button>
                </div>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
}
