import React, { useEffect, useRef, useContext } from "react";
import { useSnackbar } from "notistack";

import { Button, Grid } from '@mui/material';

import unknown from "../../../assets/unknown-image.jpg";

import "./styles.css";
import DadosContext from "../DadosContext";

export default function ImagemUsuario() {
  const { enqueueSnackbar } = useSnackbar();
  const { preview, setPreview, image, setImage } = useContext(DadosContext);
  const fileInputRef = useRef();

  useEffect(() => {
    if (image) {
      if (image.size <= 13631488) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setPreview(reader.result);
        };
        reader.readAsDataURL(image);
      } else {
        enqueueSnackbar("Somente é possível enviar arquivos de até 13MB", {
          variant: "error",
        });
      }
    } else {
      setPreview(null);
    }
    // eslint-disable-next-line
  }, [enqueueSnackbar, image]);

  return (
    <Grid
      container
      style={{ padding: "1.5rem 1.5rem" }}
      alignItems="center"
      justifyContent="center"
    >
      <Grid item style={{ textAlign: "center" }} xs={12} sm={3}>
        <div className="imagem-do-perfil">
          <h3>Imagem do usuário</h3>
        </div>
        {preview ? (
          <img id="preview-image" src={preview} alt="Foto do usuário" />
        ) : (
          <img id="preview-image" src={unknown} alt="Foto padrão" />
        )}
      </Grid>
      <Grid id="grid-upload" item xs={12} sm={9}>
        <label>Você pode inserir uma imagem para o seu perfil</label>
        <br />
        <div className="button-upload">
          <input
            accept="image/*"
            ref={fileInputRef}
            onChange={event => {
              const file = event.target.files[0];
              if (file && file.type.substring(0, 5) === "image") {
                setImage(file);
              } else {
                setImage(null);
              }
            }}
            className="input-image"
            id="image-upload"
            multiple
            type="file"
            name="avatar_url"
          />

          <label htmlFor="image-upload">
            <Button
              onClick={event => {
                event.preventDefault();
                fileInputRef.current.click();
              }}
              variant="outlined"
              color="primary"
              component="span"
            >
              Enviar imagem
            </Button>
          </label>
        </div>
      </Grid>
    </Grid>
  );
}
