import { Typography } from "@mui/material";
import GridContainer from "../../../../componentsv2/GridContainer";
import GridItem from "../../../../componentsv2/GridItem";
import Paper from "../../../../componentsv2/Paper";

export default function CardProduto({ produto }) {
  return (
    <Paper
      sx={{
        padding: 2,
        borderRadius: 2,
      }}
    >
      <GridContainer>
        <GridContainer item xs={12} justifyContent="space-between">
          <GridItem>
            <Typography variant="subtitle1">{produto?.nome}</Typography>
          </GridItem>
          <GridItem>
            <Typography variant="body2" color="black">
              {produto?.valorTotal
                ? produto?.valorTotal?.toLocaleString("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                  })
                : 0?.toLocaleString("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                  })}
            </Typography>
          </GridItem>
        </GridContainer>
        <GridContainer item xs={12} alignItems="center">
          <GridItem>
            <Typography variant="subtitle2">
              Quantidade: {produto?.quantidade}
            </Typography>
          </GridItem>
          {/* <GridItem xs={1}>
            <IconButton onClick={() => setOpenModalVisualizarEstoque(true)}>
              <VisibilityIcon />
            </IconButton>
          </GridItem> */}
        </GridContainer>
      </GridContainer>
    </Paper>
  );
}
