import React from "react";
import {
  Paper,
  InputLabel
} from '@mui/material';
import { styled } from '@mui/system';
import formatoMoeda from "../../../../../utils/formatoMoeda";

const PaperStyled = styled(Paper)(() => ({
  width: "8rem",
  background: "#F2F2F2",
  textAlign: "center",
  padding: "0.3rem",
  fontWeight: "bold",
  marginBottom: "0.3rem",
  cursor: "Pointer",
  "@media (max-width: 500px)": {
    width: "6rem",
    height: "2rem",
  }
}));

const InputTitleStyled = styled(InputLabel)(() => ({
  fontSize: "0.7rem",
  cursor: "Pointer",
  "@media (max-width: 500px)": {
      fontSize: "0.5rem",
  },
}));

const InputValueStyled = styled(InputLabel)(() => ({
  padding: "0.3rem",
  color: "black",
  fontSize: "0.9rem",
  fontWeight: "bold",
  cursor: "Pointer",
  "@media (max-width: 500px)": {
    fontSize: "0.7rem",
    fontWeight: "bold",
  }
}));

export default function CardTotal(props) {
  const { titulo, valor, quantidade, aba } = props;
  const ativado = (aba === titulo ? true : null);
  return (
    <PaperStyled
      style={ativado && {backgroundColor: '#003399'}}
    >
      <InputTitleStyled
        style={ativado && {color: '#FFF'}}
      >
        {titulo}
      </InputTitleStyled>
      <InputValueStyled
        style={ativado && {color: '#FFF'}}
      >
        {valor ? formatoMoeda(valor, true) : "0,00"} - {quantidade}
      </InputValueStyled>
    </PaperStyled>
  )
}
