import React, { useEffect, useState } from "react";

import TabelaV2 from "../../../../componentsv2/Tabela";
import Box from "../../../../componentsv2/Box";

import OpcoesTabela from "../OpcoesTabela";
import { api } from "../../../../services/Api";

export default function TabelaClientes() {
  const columns = [
    {
      field: "cpf",
      headerName: "CPF",
      headerAlign: "center",
      align: "center",
      width: 200,
    },
    {
      field: "nome",
      headerName: "Nome",
      headerAlign: "center",
      align: "",
      width: 400,
    },
    {
      field: "dataNascimento",
      headerName: "Data Nascimento",
      headerAlign: "center",
      align: "center",
      width: 200,
      valueGetter: ({ value }) => value && new Date(value).toLocaleDateString("pt-BR"),
    },
    {
      field: "opcoes",
      headerName: "Opções",
      headerAlign: "center",
      align: "center",
      flex: 1,
      width: 20,
      type: "number",
      editable: false,
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: params => (
        <div style={{ cursor: "pointer" }}>
          <OpcoesTabela atualizarTabela={atualizarTabela} row={params.row} />
        </div>
      ),
    },
  ];

  const [rows, setRows] = useState([]);
  const [atualizaTabela, setAtualizaTabela] = useState(false);

  const atualizarTabela = () => {
    setAtualizaTabela(!atualizaTabela);
  };

  const getClientes = async () => {
    try {
      const {
        data: { data },
      } = await api.get("v2/controleDna/clientes");
      setRows(data);
    } catch (error) {
      console.error(error);
      // enqueueSnackbar("Erro ao tentar encontrar clientes!", { variant: "error" });
    }
  };

  useEffect(() => {
    getClientes();
  }, [atualizaTabela]);

  return (
    <Box sx={{ height: "600px", width: "100%", paddingTop: "15px" }}>
      <TabelaV2 ocultarToolbar columns={columns} rows={rows} autoPageSize />
    </Box>
  );
}
