import React from "react";
import { Grid } from '@mui/material';
import { Select } from "../../../../componentsv2/Select";

export default function SelectFormaPagamento({
  onChange,
  value,
  disabled = false,
  required = false,
}) {
  const options = [
    "Boleto",
    "Crédito - Parcelado",
    "Crédito - Vencimento",
    "Debito - Espécie",
    "Pix",
    "Transferencia Bancária",
  ];

  return (
    <>
      <Grid container spacing={1} justify="center" alignItems="stretch">
        <Grid item xs={12}>
          <Select
            required={required}
            disabled={disabled}
            onChange={onChange}
            value={value}
            label="Forma de Pagamento"
            variant="standard"
            arrayOptions={options}
          />
        </Grid>
      </Grid>
    </>
  );
}
