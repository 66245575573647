import React from "react";
import { Modal as MuiModal, Typography, Box, Grid } from "@mui/material";
import Button from "../Button";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  bgcolor: "background.paper",
  boxShadow: 24,
  maxWidth: 500,
  p: 4,
};

export function Modal({
  open,
  onClose,
  modalText,
  confirmActionButtonText,
  confirmActionButtonColor,
  onClick,
  loading = false,
}) {
  return (
    <MuiModal open={open} onClose={onClose}>
      <Box sx={modalStyle}>
        <Grid container spacing={6}>
          <Grid item>
            <Typography variant="body1" sx={{ color: "black" }}>
              {modalText
                ? modalText
                : "Tem certeza que deseja confirmar esta ação?"}
            </Typography>
          </Grid>
          <Grid container justifyContent="flex-end" item spacing={2}>
            <Grid item>
              <Button
                size="small"
                color="gray"
                variant="outlined"
                onClick={() => onClose(false)}
              >
                Cancelar
              </Button>
            </Grid>
            <Grid item>
              <Button
                size="small"
                variant="contained"
                color={confirmActionButtonColor}
                onClick={onClick}
                disabled={loading}
              >
                {confirmActionButtonText
                  ? confirmActionButtonText
                  : "Confirmar"}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </MuiModal>
  );
}
