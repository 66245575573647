import React, { useContext } from "react";
import { Dialog, DialogActions, DialogTitle } from "@mui/material";

import { ModaisColetaDomiciliarContext } from "../../../Context/ModaisColetaDomiciarContext";

export default function ModalAlteraStatus() {
  const {
    menuStatus,
    fecharMenuStatus,
    ativaAtualizaStatus
  } = useContext(ModaisColetaDomiciliarContext);

  return (
    <Dialog
      open={menuStatus}
      onClose={() => {
          fecharMenuStatus();
        }}
    >
      <DialogTitle id="alert-dialog-title">
        Você realmente deseja alterar o status?
      </DialogTitle>
      <DialogActions>
        <button
          type="button"
          className="btnDialogs"
          onClick={() => ativaAtualizaStatus()}
        >
          Confirmar
        </button>
        <button
          type="button"
          className="btnDialogs"
          onClick={() => fecharMenuStatus()}
        >
          Desistir
        </button>
      </DialogActions>
    </Dialog>
  )
}
