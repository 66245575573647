import React, { memo, useContext } from "react";
import { Paper } from "@mui/material";

import CardTotal from "./CardTotal";
import { FinanceiroContext } from "../../../Context/FinanceiroContext";

import "./styles.css";

function Totalizadores() {
  const { total } = useContext(FinanceiroContext);



  return (
    <Paper className="container_totalizadores">
      <CardTotal titulo="Cartão de Crédito" valor={total.somaTotalCartao?.valor} quantidade={total.somaTotalCartao?.qtd} />
      <CardTotal titulo="Dinheiro" valor={total.somaTotalDinheiro?.valor} quantidade={total.somaTotalDinheiro?.qtd} />
      <CardTotal titulo="Pix" valor={total.somaTotalPix?.valor} quantidade={total.somaTotalPix?.qtd} />
      <CardTotal titulo="Total" valor={total.somaTotal?.valor} quantidade={total.somaTotal?.qtd} />
    </Paper>
  );
}

export default memo(Totalizadores);
