import EditIcon from '@mui/icons-material/Edit';
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import SettingsIcon from '@mui/icons-material/Settings';
import { Button, Container, Grid, Modal, Paper } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { Context as PermissoesContext } from "../../../../Context/PermissoesContext";

export default function Opcoes({ row }) {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { checarPermissao } = useContext(PermissoesContext);
  const [open, setOpen] = useState(false);

  function ModalOption(props) {
    async function handleClickEdit() {
      if (await !checarPermissao("AL005")) {
        enqueueSnackbar("É necessário a permissão AL005", {
          variant: "warning",
        });
        history.push("/almoxarifado/produtos");
      } else {
        history.push({
          pathname: "/almoxarifado/produtos/CadastraProduto",
          state: { produto: props.row },
        });
      }
    }

    return (
      <Container className="options-container" maxWidth="xs">
        <Paper className="options-paper" elevation={2}>
          <Grid container style={{ padding: "1rem 1rem" }}>
            <Grid item sm={12}>
              <Button
                onClick={() => handleClickEdit()}
                variant="standard"
                startIcon={<EditIcon />}
              >
                Editar Produto
              </Button>
            </Grid>

            <Grid item sm={12}>
              <Button
                onClick={() => setOpen(false)}
                variant="standard"
                startIcon={<KeyboardReturnIcon />}
              >
                Voltar
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Container>
    );
  }
  return (
    <>
      <Button style={{ color: "#000" }} onClick={() => setOpen(true)}>
        <SettingsIcon />
      </Button>
      <Modal open={open} onClose={() => setOpen(false)}>
        <ModalOption row={row} />
      </Modal>
    </>
  );
}
