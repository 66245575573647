import React, { useState, useContext } from "react";
import { useSnackbar } from "notistack";

import { 
  ArrowBack,
  ChangeCircle,
  Edit,
  MoveDown,
  RemoveRedEye,
  Settings,
 } from "@mui/icons-material";
 import  RemoveIcon from "@mui/icons-material/Delete";

 import DadosContext from "../../context/DadosContext";
 
import Button from "../../../../../../componentsv2/Button";
import GridItem from "../../../../../../componentsv2/GridItem";
import GridContainer from "../../../../../../componentsv2/GridContainer";
import Paper from "../../../../../../componentsv2/Paper";
import Container from "../../../../../../componentsv2/Container";
import ModalSettings from "../../../../../../componentsv2/ModalSettings";
import { Modal } from "../../../../../../componentsv2/Modal";
import { ModalForm } from "../../../../../../componentsv2/ModalForm";

import CheckBoxWithLabel from "../../../../../../componentsv2/CheckBoxWithLabel";
import { Context as PermissoesContext } from "../../../../../../Context/PermissoesContext";

import SelectSituacao from "../../../../components/SelectSituacao";
import Visualizar from "../../pages/Visualizar";
import { useHistory } from "react-router-dom";

import { api } from "../../../../../../services/Api";
import { Input } from "../../../../../../componentsv2/Input";
import useUser from "../../../../../../hooks/useUser";

export default function OpcoesTabela({ row }) {
  const { atualizaTabela } = useContext(DadosContext);
  const [open, setOpen] = useState(false);
  const [openSituacao, setOpenSituacao] = useState(false);
  const [openVisualizar, setOpenVisualizar] = useState(false);
  const [openConfirmar, setOpenConfirmar] = useState(false);
  const [situacao, setSituacao] = useState(row.situacao);
  const [solucao, setSolucao] = useState(row.solucao);
  const [clienteContatado, setClienteContatado] = useState(row.clienteContatado);
  const { checarPermissao } = useContext(PermissoesContext);
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const usuario = useUser();

  const openFormEditar = () => {
    if(checarPermissao("ATC003") || usuario === row.usuarioCadastro.username){
      history.push({
        pathname: "/atendimentoAoCliente/formularioSAC",
        state: { row },
      });
    } else {
      enqueueSnackbar("Você não possui permissão para editar algo que não foi cadastrado por você!", { variant: "error" });
    }
  };

  const handleExcluir = async () => {
    try {
      await api.delete(`v2/atendimentoAoCliente/sac/${row.id}`);
      enqueueSnackbar("Exclusão realizada com sucesso!", { variant: "success" });
    } catch (error) {
      enqueueSnackbar("Erro ao tentar excluir!", { variant: "error" });
    } finally {
      atualizaTabela();
      setOpen(false);
    }
  };
  const handleTransferirAuditoria = async () => {
    try {
      await api.put(`v2/atendimentoAoCliente/transferirParaOuvidoria/${row.id}`);
      enqueueSnackbar("Transferido para ouvidoria!", { variant: "success" });
    } catch (error) {
      enqueueSnackbar("Erro ao transferir para ouvidoria!", { variant: "error" });
    } finally {
      atualizaTabela();
      setOpenConfirmar(false);
      setOpen(false);
    }
  };
  const handleMudarSituacao = async () => {
    try {
      await api.put(`v2/atendimentoAoCliente/situacaoSac/${row.id}`, {
        situacao,
        solucao,
        clienteContatado,
      });
      enqueueSnackbar("Situação alterada com sucesso!", { variant: "success" });
    } catch (error) {
      enqueueSnackbar("Erro ao alterar situação!", { variant: "error" });
    } finally {
      atualizaTabela();
      setOpenSituacao(false);
      setOpen(false);
    }
  };

  return (
    <>
      <Button style={{ color: "#000" }} onClick={() => setOpen(true)}>
        <Settings />
      </Button>
      <ModalSettings open={open} onClose={setOpen}>
        <Container className="options-container" maxWidth="xs">
          <Paper className="options-paper" elevation={2}>
            <GridContainer style={{ padding: "1rem 1rem" }}>
              <GridItem sm={12}>
                {openVisualizar && (
                  <Visualizar
                    row={row}
                    open={openVisualizar}
                    setOpen={setOpenVisualizar}
                  />
                )}

                <Button
                  onClick={() => {
                    setOpenVisualizar(true);
                  }}
                  variant="standard"
                  startIcon={<RemoveRedEye />}
                >
                  Visualizar
                </Button>
              </GridItem>
              <GridItem sm={12}>
                <Button variant="standard" onClick={()=>{openFormEditar()}} startIcon={<Edit />}>
                  Editar
                </Button>
              </GridItem>
              <GridItem sm={12}>
                <Button
                  onClick={() => {
                    if(checarPermissao("ATC004")){
                      setOpenConfirmar(true);
                    }else{
                      enqueueSnackbar("Você não possui permissão para transferir!", { variant: "error" });
                    }
                  }}
                  variant="standard"
                  startIcon={<MoveDown />}
                >
                  Transferir para Ouvidoria
                </Button>
              </GridItem>
              <GridItem sm={12}>
                <Button
                  onClick={() => {
                    setOpenSituacao(true);
                  }}
                  variant="standard"
                  startIcon={<ChangeCircle />}
                >
                  Alterar Situação
                </Button>
              </GridItem>
              <GridItem sm={12}>
                <Button
                  onClick={() => {
                    if(checarPermissao("ATC008")){
                      handleExcluir()
                    }else{
                      enqueueSnackbar("Você não possui permissão para excluír!", { variant: "error" });
                    }
                  }}
                  variant="standard"
                  startIcon={<RemoveIcon />}
                >
                  Excluir
                </Button>
              </GridItem>
              <GridItem sm={12}>
                <Button
                  variant="standard"
                  startIcon={<ArrowBack />}
                  onClick={() => setOpen(false)}
                >
                  Voltar
                </Button>
              </GridItem>
            </GridContainer>
          </Paper>
          <Modal
            modalText="Tem certeza que deseja transferir para ouvidoria?"
            open={openConfirmar}
            onClose={setOpenConfirmar}
            onClick={handleTransferirAuditoria}
          />
          <ModalForm
            onSubmit={handleMudarSituacao}
            open={openSituacao}
            onClose={setOpenSituacao}
            title="Alterar situação"
          >
            <GridItem xs={6}>
              <SelectSituacao
                todas={false}
                value={situacao}
                onChange={e => {
                  setSituacao(e.target.value);
                }}
              />
            </GridItem>
            <GridItem xs={6}>
              <CheckBoxWithLabel
                label="O cliente foi contatado?"
                checked={clienteContatado}
                setChecked={setClienteContatado}
                labelPlacement="start"
              />
            </GridItem>
            <GridItem xs={12}>
              <Input
                fullWidth 
                value={solucao}
                onChange={e => {
                  setSolucao(e.target.value);
                }} 
                label="Solução" 
                placeholder="Digite a solução aqui..." 
                multiline
                rows={4}
              />
            </GridItem>      
          </ModalForm>
        </Container>
      </ModalSettings>
    </>
  );
}
