import React from "react";
import { Modal, Paper } from "@mui/material";
import { styled } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";

import "./styles.css";

const CustomPaper = styled(Paper)({
  root: {
    width: "95%",
    marginLeft: "auto",
    marginRight: "auto",
    maxWidth: "600px",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    padding: "1.5rem",
  },
});

export default function OptionsModal({ open, onClose, options }) {
  return (
    <Modal open={open} onClose={onClose}>
      <CustomPaper>
        <CloseIcon
          onClick={onClose}
          style={{
            position: "absolute",
            top: "8px",
            right: "8px",
            cursor: "pointer",
            fontSize: "30px",
          }}
        />
        <h1 style={{ marginTop: "0", marginBottom: "2rem", textAlign: "left" }}>
          Escolha uma opção
        </h1>
        {options.map((option, index) => (
          <div
            onClick={option.onClick}
            className="option"
            key={`option-${option.title}`}
            style={{
              marginBottom: index !== options.length - 1 ? ".5rem" : "0px",
            }}
          >
            {option.icon}
            <div className="option-text">
              <h2>{option.title}</h2>
              <span>{option.subtitle}</span>
            </div>
          </div>
        ))}
      </CustomPaper>
    </Modal>
  );
}
