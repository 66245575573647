import React, { useState, useEffect, useRef } from "react";
import { useSnackbar } from "notistack";

import {
  Button,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Grid,
  IconButton,
  Container,
} from '@mui/material';
import { AddCircleOutline } from '@mui/icons-material';

import "./styles.css";
import { api } from "../../../services/Api";

function AdicionarEditarExame() {
  const { enqueueSnackbar } = useSnackbar();
  const [id_exame, setId_exame] = useState(0);
  const [configuracao_tempos, setConfiguracao_tempos] = useState("");
  const [intervalo, setIntervalo] = useState("");
  const [exameAdd, setExameAdd] = useState("");
  const [codigoAdd, setCodigoAdd] = useState("");
  const [examesColeta, setExamesColeta] = useState("");
  const idExame = useRef(-1);
  const intervalos = useRef([]);
  const [buttonText, setButtonText] = useState("Adicionar exame");

  function preencheIntervalo(exame) {
    examesColeta.map(i => {
      if (exame === i.id) {
        setConfiguracao_tempos(i.configuracao_tempos);
        intervalos.current = i.configuracao_tempos.split(",");
        const ct = intervalos.current.reduce((curr, acc) => curr + acc + ",", "");
        setConfiguracao_tempos(ct.slice(0, -1));
        setIntervalo("");
      }
      return null;
    });
  }

  function preencheExame(exame) {
    examesColeta.map(i => {
      if (exame === i.id) {
        setExameAdd(i.nome);
      }
      return null;
    });
  }

  function preencheCodigo(exame) {
    examesColeta.map(i => {
      if (exame === i.id) {
        setCodigoAdd(i.codigo);
      }
      return null;
    });
  }

  function preencheIdExameEditado(exame) {
    examesColeta.map(i => {
      if (exame === i.id) {
        idExame.current = i.id;
      }
      return null;
    });
  }

  function addIntervalo() {
    if (intervalo === "") {
      return;
    }
    intervalos.current.push(intervalo);
    const ct = intervalos.current.reduce((curr, acc) => curr + acc + ",", "");

    setConfiguracao_tempos(ct.slice(0, -1));
    setIntervalo("");
  }

  function limpar() {
    setId_exame(0);
    setConfiguracao_tempos("");
    setExameAdd("");
    setCodigoAdd("");
    setButtonText("Adicionar Exame");
    idExame.current = -1;
    intervalos.current = [];
  }

  async function submitNovoExame() {
    try {
      const dados = {
        nome: exameAdd,
        tempos: `${intervalos.current.length}`,
        codigo: codigoAdd,
        configuracao_tempos,
      };
      await api.post("/v2/mapaCurvas/examesDetalhes", dados);
      enqueueSnackbar("Exame criado com sucesso!", {
        variant: "success",
      });
      pegarExames();
    } catch (error) {
      enqueueSnackbar("Erro ao tentar criar exame!", { variant: "error" });
    }
  }
  async function submitExameEditado() {
    try {
      const dados = {
        nome: exameAdd,
        tempos: `${intervalos.current.length}`,
        codigo: codigoAdd,
        configuracao_tempos,
      };
      await api.put(`/v2/mapaCurvas/examesDetalhes/${idExame.current}`, dados);
      enqueueSnackbar("Exame editado com sucesso!", {
        variant: "success",
      });
      pegarExames();
    } catch (error) {
      enqueueSnackbar("Erro ao tentar criar exame!", { variant: "error" });
    }
  }

  async function pegarExames() {
    try {
      const {
        data: { exames },
      } = await api.get("/v2/mapaCurvas/examesDetalhes");
      setExamesColeta(exames);
    } catch (error) {
      enqueueSnackbar("Erro ao procurar os exames!", {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  }

  useEffect(() => {
    pegarExames();
    // eslint-disable-next-line
  }, []);

  return (
    <Container maxWidth="md">
      <Grid container spacing={1} justify="center" alignItems="stretch">
        <Grid item xs={12}>
          <h1 className="modal-title">Adicionar/Editar Exame</h1>
        </Grid>

        <Grid item xs={12}>
          <FormControl variant="filled" fullWidth>
            <InputLabel>Exames</InputLabel>
            <Select
              fullWidth
              value={id_exame}
              onChange={event => {
                setId_exame(event.target.value);
                preencheIntervalo(event.target.value);
                preencheExame(event.target.value);
                preencheCodigo(event.target.value);
                preencheIdExameEditado(event.target.value);
                setButtonText("Editar exame");
              }}
            >
              <MenuItem value={0} key="exame-0">
                Selecione o exame
              </MenuItem>
              {examesColeta.length > 0
                ? examesColeta.map(exame => (
                    <MenuItem value={exame.id} key={`exame-${exame.id}`}>
                      {exame.nome} - {exame.codigo}
                    </MenuItem>
                  ))
                : null}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Nome do exame"
            onChange={event => {
              setExameAdd(event.target.value);
            }}
            margin="normal"
            type="text"
            variant="filled"
            placeholder="Exame..."
            id="input"
            value={exameAdd}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Codigo do exame"
            onChange={event => {
              setCodigoAdd(event.target.value);
            }}
            margin="normal"
            type="text"
            variant="filled"
            placeholder="CURIA..."
            id="input"
            value={codigoAdd}
            fullWidth
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            label="Intervalo"
            onChange={event => {
              setIntervalo(event.target.value);
            }}
            margin="normal"
            type="number"
            variant="filled"
            placeholder="Ex:0,30,60,90"
            id="input"
            value={intervalo}
          />
          <IconButton onClick={addIntervalo} variant="outlined">
            <AddCircleOutline />
          </IconButton>
          <span>Intervalos - {configuracao_tempos}</span>
        </Grid>
        <Grid item xs={9}>
          <div className="buttons">
            <div>
              <Button onClick={limpar} variant="outlined" color="primary">
                Limpar
              </Button>
            </div>
          </div>
        </Grid>

        <Grid item xs={3}>
          <div className="buttons">
            <div>
              <Button
                onClick={() => {
                  if (idExame.current === -1) {
                    submitNovoExame();
                  } else {
                    submitExameEditado();
                  }
                  limpar();
                }}
                variant="contained"
                color="primary"
              >
                {buttonText}
              </Button>
            </div>
          </div>
        </Grid>
      </Grid>
    </Container>
  );
}

export default AdicionarEditarExame;
