import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
} from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useContext, useEffect, useState } from "react";
// import DataPickerAtualizado from "../../../../components/DataPickerAtualizado";
import { api } from "../../../../services/Api";
import SelectLocal from "../../components/SelectLocal";
import useDadosContextEstoque from "../../hooks/useDadosContextEstoque";
import AutocompleteEstoque from "../../components/AutocompleteEstoque";

export default function HeaderSelect() {
  const { enqueueSnackbar } = useSnackbar();
  const [local, setLocal] = useState([]);

  const {
    localSelecionado,
    setLocalSelecionado,
    semAcessoUnidade,
    estoqueAtual,
    setEstoqueAtual,
  } = useDadosContextEstoque();

  useEffect(() => {
    async function pegaTodasUnidades() {
      try {
        const { data } = await api.get("/v2/almoxarifado/estoque/unidades");

        setLocal(data.unidades);
      } catch (e) {
        enqueueSnackbar("Falha ao obter as unidades", { variant: "error" });
      }
    }

    pegaTodasUnidades();
  }, [enqueueSnackbar]);

  const handleLocal = (e) => {
    if (!semAcessoUnidade) {
      setLocalSelecionado(e);
      setEstoqueAtual({ nome: "Todos", id: "" });
    }
  };

  return (
    <Paper elevation={2}>
      <Grid container>
        <Grid className="paper-categoria" item xs={12} sm={4}>
          <SelectLocal
            label="Localização"
            value={localSelecionado}
            setValue={handleLocal}
            locais={local}
            ativo={semAcessoUnidade}
          />
        </Grid>
        <Grid className="paper-categoria" item xs={12} sm={4}>
          <AutocompleteEstoque
            onChange={setEstoqueAtual}
            value={estoqueAtual}
            idUnidade={localSelecionado}
          />
        </Grid>
        {/* <Grid className="paper-categoria" item xs={12} sm={4}>
          <FormControl
            defaultValue=" "
            size="small"
            variant="standard"
            fullWidth
          >
            <InputLabel id="select-label">Validade</InputLabel>
            <Select
              value={situacaoSelecionada}
              onChange={(e) => setSituacaoSelecionada(e.target.value)}
              labelId="select-label"
              id="select"
              label="Ramal"
            >
              <MenuItem value="">Todos</MenuItem>
              <MenuItem value="alerta">Em alerta</MenuItem>
              <MenuItem value="critico">Critíco</MenuItem>
            </Select>
          </FormControl>
        </Grid> */}
      </Grid>
    </Paper>
  );
}
