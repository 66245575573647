import { useEffect, useState } from "react";
import useDadosContextEstoque from "../../hooks/useDadosContextEstoque";
import { enqueueSnackbar } from "notistack";
import { api } from "../../../../services/Api";

export default function useFiltros() {
  const { semAcessoUnidade, setLocalSelecionado, setLocalSelecionadoResumo } =
    useDadosContextEstoque();

  const handleLocal = (e) => {
    if (!semAcessoUnidade) {
      setLocalSelecionado(e);
    }
  };

  const handleLocalResumo = (e) => {
    if (!semAcessoUnidade) {
      setLocalSelecionadoResumo(e);
    }
  };

  return {
    handleLocal,
    handleLocalResumo,
  };
}
