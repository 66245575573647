import { Dialog, DialogTitle } from '@mui/material';
import React from 'react';

import "./styles.css";

export default function DialogAviso(props){
  const {
    aviso,
    setAviso,
    mensagem,
    setMensagem
  } = props;

  function fechaAviso() {
      setAviso(false);
      setMensagem('');
  }

  return (
    <Dialog open={aviso} onClose={() => { fechaAviso() }}>
      <DialogTitle id="alert-dialog-title">
        {mensagem}
      </DialogTitle>
      <button
        type="button"
        id="button-dialog-aviso"
        onClick={() => { fechaAviso() }}
      >
        Ok
      </button>
    </Dialog>
  )
}
