import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

import Paper from "../../../../componentsv2/Paper";
import Button from "../../../../componentsv2/Button";
import { Input } from "../../../../componentsv2/Input";
import GridItem from "../../../../componentsv2/GridItem";
import GridContainer from "../../../../componentsv2/GridContainer";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSnackbar } from "notistack";
import formatarData from "../../../../utils/formatarData";

import { api } from "../../../../services/Api";
import Container from "../../../../componentsv2/Container";
import Header from "../../../../components/Header";

export default function EditarCliente() {
  const [nomeCliente, setNomeCliente] = useState("");
  const [rg, setRg] = useState("");
  const [cpf, setCpf] = useState("");
  const [dataExpedicao, setDataExpedicao] = useState("");
  const [orgaoExpedidor, setOrgaoExpedidor] = useState("");
  const [genero, setGenero] = useState("");
  const [dataNascimento, setDataNascimento] = useState("");
  const [peso, setPeso] = useState("");
  const [altura, setAltura] = useState("");
  const [gestante, setGestante] = useState("");
  const [telefone, setTelefone] = useState("");
  const [celular, setCelular] = useState("");
  // const [email, setEmail] = useState("");
  const [nomeMae, setNomeMae] = useState("");
  const [medicacao, setMedicacao] = useState("");
  const [indicacao, setIndicacao] = useState("");
  const [endereco, setEndereco] = useState("");
  const [numeroEndereco, setNumeroEndereco] = useState("");
  const [cidade, setCidade] = useState("");
  const [bairro, setBairro] = useState("");
  const [estado, setEstado] = useState("");
  const [cep, setCep] = useState("");
  const [complemento, setComplemento] = useState("");

  const history = useHistory();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();

  const pegarDadosCliente = async () => {
    if (location.state?.row) {
      try {
        const {
          data: { data },
        } = await api.get(`/v2/controleDna/cliente/${location.state.row.id}`);
        setNomeCliente(data.nome);
        setRg(data.rg);
        setCpf(data.cpf);
        setDataExpedicao(data.dataExpedicao);
        setOrgaoExpedidor(data.orgaoExpedidor);
        setGenero(data.sexo);
        setDataNascimento(data.dataNascimento);
        setPeso(data.peso);
        setAltura(data.altura);
        setGestante(data.gestante);
        setTelefone(data.telefone);
        setCelular(data.celular);
        setNomeMae(data.mae);
        setMedicacao(data.medicacao);
        setIndicacao(data.indicacao);
        setEndereco(data.endereco);
        setNumeroEndereco(data.enderecoNumero);
        setCidade(data.cidade);
        setBairro(data.bairro);
        setEstado(data.estado);
        setCep(data.cep);
        setComplemento(data.complemento);
      } catch (error) {
        enqueueSnackbar("Erro! Não foi possível buscar os dados do cliente.", {
          variant: "error",
        });
      }
    } else {
      history.push("/GestaoDeClientes");
    }
  };

  useEffect(() => {
    pegarDadosCliente();
    // eslint-disable-next-line
  }, [location.state?.row]);

  return (
    <>
    <Header titulo="Informações Do Cliente"/>
    <Container>
      <Button onClick={() => history.push({ pathname: "/GestaoDeClientes" })}>
        <ArrowBackIcon style={{ color: "#003399" }} />
      </Button>
      <Paper style={{ padding: "40px", marginTop: "10px" }} elevation={2}>
        <GridContainer xs={12} spacing={2}>
          <GridItem xs={6}>
            <Input
              inputProps={{ readOnly: true }}
              value={nomeCliente}
              required
              fullWidth
              label="Nome"
              type="text"
              variant="standard"
            />
          </GridItem>

          <GridItem xs={3}>
            <Input
              inputProps={{ readOnly: true }}
              value={rg}
              required
              fullWidth
              label="RG"
              type="text"
              variant="standard"
            />
          </GridItem>

          <GridItem xs={3}>
            <Input
              inputProps={{ readOnly: true }}
              value={cpf}
              required
              fullWidth
              label="CPF"
              type="text"
              variant="standard"
            />
          </GridItem>

          <GridItem xs={2}>
            <Input
              inputProps={{ readOnly: true }}
              value={formatarData(new Date(dataExpedicao), false)}
              required
              variant="standard"
              label="Data de Expedição"
              fullWidth
              type="text"
            />
          </GridItem>

          <GridItem xs={4}>
            <Input
              inputProps={{ readOnly: true }}
              value={orgaoExpedidor}
              fullWidth
              label="Orgão Expedidor"
              type="text"
              variant="standard"
            />
          </GridItem>

          <GridItem xs={3}>
            <Input
              inputProps={{ readOnly: true }}
              fullWidth
              label="Genero"
              type="text"
              variant="standard"
              value={genero}
            />
          </GridItem>

          <GridItem xs={3}>
            <Input
              fullWidth
              type="text"
              inputProps={{ readOnly: true }}
              value={formatarData(new Date(dataNascimento), false)}
              required
              variant="standard"
              label="Data de Nascimento"
            />
          </GridItem>

          <GridItem xs={2}>
            <Input
              inputProps={{ readOnly: true }}
              value={peso}
              fullWidth
              label="Peso"
              type="number"
              variant="standard"
            />
          </GridItem>

          <GridItem xs={2}>
            <Input
              value={altura}
              fullWidth
              label="Altura"
              type="number"
              variant="standard"
            />
          </GridItem>

          <GridItem xs={2}>
            <Input
              inputProps={{ readOnly: true }}
              fullWidth
              label="Gestante"
              type="text"
              variant="standard"
              value={gestante}
            />
          </GridItem>

          <GridItem xs={3}>
            <Input
              value={telefone}
              fullWidth
              label="Telefone"
              type="text"
              variant="standard"
            />
          </GridItem>

          <GridItem xs={3}>
            <Input
              inputProps={{ readOnly: true }}
              value={celular}
              fullWidth
              label="Celular"
              type="text"
              variant="standard"
            />
          </GridItem>

          {/* <GridItem xs={3}>
              <Input
                onChange={e => {
                  setEmail(e.target.value);
                }}
                value={email}
                fullWidth
                label="E-mail"
                type="text"
                variant="standard"
              />
            </GridItem> */}

          <GridItem xs={6}>
            <Input
              inputProps={{ readOnly: true }}
              value={nomeMae}
              fullWidth
              label="Mãe"
              type="text"
              variant="standard"
            />
          </GridItem>

          <GridItem xs={3}>
            <Input
              inputProps={{ readOnly: true }}
              value={medicacao}
              fullWidth
              label="Medicação"
              type="text"
              variant="standard"
            />
          </GridItem>

          <GridItem xs={4}>
            <Input
              inputProps={{ readOnly: true }}
              value={indicacao}
              fullWidth
              label="Indicação"
              type="text"
              variant="standard"
            />
          </GridItem>

          <GridItem xs={8}>
            <Input
              inputProps={{ readOnly: true }}
              value={endereco}
              required
              fullWidth
              label="Endereço"
              type="text"
              variant="standard"
            />
          </GridItem>

          <GridItem xs={4}>
            <Input
              inputProps={{ readOnly: true }}
              value={numeroEndereco}
              fullWidth
              label="Nº Endereço"
              type="text"
              variant="standard"
            />
          </GridItem>

          <GridItem xs={3}>
            <Input
              inputProps={{ readOnly: true }}
              value={cidade}
              fullWidth
              label="Cidade"
              type="text"
              variant="standard"
            />
          </GridItem>

          <GridItem xs={3}>
            <Input
              inputProps={{ readOnly: true }}
              value={bairro}
              fullWidth
              label="Bairro"
              type="text"
              variant="standard"
            />
          </GridItem>

          <GridItem xs={3}>
            <Input
              inputProps={{ readOnly: true }}
              value={estado}
              fullWidth
              label="Estado"
              type="text"
              variant="standard"
            />
          </GridItem>

          <GridItem xs={3}>
            <Input
              inputProps={{ readOnly: true }}
              value={cep}
              fullWidth
              label="CEP"
              type="text"
              variant="standard"
            />
          </GridItem>

          <GridItem xs={12}>
            <Input
              inputProps={{ readOnly: true }}
              value={complemento}
              fullWidth
              label="Complemento"
              type="text"
              variant="standard"
            />
          </GridItem>
        </GridContainer>
      </Paper>
      </Container>
    </>
  );
}
