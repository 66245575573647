import React, { useContext, useEffect, useState } from "react";
import { useSnackbar } from "notistack";

import { FormControl, Grid, InputLabel, MenuItem, Select } from '@mui/material';
import DadosContext from "../DadosContext";
import { api } from "../../../services/Api";
import { TextField } from "@mui/material";

export default function DadosEmpresa() {
  const { enqueueSnackbar } = useSnackbar();
  const {
    unidadeSelecionada,
    setUnidadeSeleciona,
    ramalSelecionado,
    setRamalSelecionado,
  } = useContext(DadosContext);
  const [unidades, setUnidades] = useState([]);

  useEffect(() => {
    async function chamaFuncao() {
      try {
        const getUnidades = await api.get("/v2/geral/unidade");
        setUnidades(getUnidades.data.unidade);
      } catch (error) {
        enqueueSnackbar("Falha ao obter as unidades", {
          variant: "error",
        });
      }
    }
    chamaFuncao();
  }, [enqueueSnackbar]);

  function handleChange(event) {
    setUnidadeSeleciona(event.target.value);
  }

  return (
    <Grid container style={{ padding: "1.5rem 1.5rem" }} spacing={2}>
      <Grid item xs={12} sm={12}>
        <h3>Dados empresariais</h3>
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormControl size="small" variant="outlined" fullWidth>
          <InputLabel id="select-label">Unidade</InputLabel>
          <Select
            value={unidadeSelecionada}
            onChange={e => handleChange(e)}
            labelId="select-label"
            id="select"
            label="Unidade"
            disabled={true}
          >
            {unidades.map(unidade => (
              <MenuItem key={unidade.id} value={unidade.id}>
                {unidade.nome}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextField
          label="Ramal"
          id="Ramal"
          variant="outlined"
          size="small"
          fullWidth
          value={ramalSelecionado}
          onChange={e => setRamalSelecionado(e.target.value)}
        />
      </Grid>
    </Grid>
  );
}
