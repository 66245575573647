import React from "react";
import { Container, Paper, IconButton, Tooltip } from "@mui/material";

export default function CorpoModal(props) {
  const { titulo, fechaModal, children } = props;

  return (
    <Container
      style={{
        display: "flex",
        width: "100%",
        minWidth: "20rem",
        flexDirection: "column",
        padding: 0,
        backgroundColor: "#F2F2F2"
      }}
    >
      <Paper
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          backgroundColor: "#FFF",
          fontWeight: "bold",
          fontSize: "1.5rem",
          padding: "0.25rem 0.5rem",
        }}
      >
        <span />
        <span>{titulo}</span>
        <Tooltip title="Fechar">
          <IconButton
            color="error"
            onClick={fechaModal}
          >
            <i className="fa fa-close" />
          </IconButton>
        </Tooltip>
      </Paper>
      {children}
    </Container>
  )
}
