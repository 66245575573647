import React, { useContext } from "react";
import { Route, useLocation, useHistory } from "react-router-dom";

import { Context as AuthContext } from "../../Context/AuthContext";

import Loading from "../Loading";

export default function CustomRoute({ isPrivate, ...rest }) {
  const { loading, authenticated } = useContext(AuthContext);
  const history = useHistory();
  const location = useLocation();
  let url = location.pathname;

  if (loading) {
    return <Loading />;
  }

  if (isPrivate && !authenticated) {
    return history.push(`/login${url}`);
  }

  if (authenticated && url.includes("/login")) {
    url = url.replaceAll("/login", "");
    return history.push(url);
  }

  return <Route {...rest} />;
}
