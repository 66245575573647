import React, { useContext } from "react";
import { useSnackbar } from "notistack";
import { Dialog, DialogActions, DialogTitle } from "@mui/material";

import { ModaisColetaDomiciliarContext } from "../../../Context/ModaisColetaDomiciarContext";
import { ColetaDomiciliarContext } from "../../../Context/ColetaDomiciarContext";

import SalvaLog from "../../../../../utils/SalvaLog";

import { api } from "../../../../../services/Api";

export default function ModalConfirmar() {
  const { selecionaData, novaData, coleta } = useContext(
    ColetaDomiciliarContext
  );
  const { confirmar, setConfirmar, fechaOpcoes, msg } = useContext(
    ModaisColetaDomiciliarContext
  );

  const { enqueueSnackbar } = useSnackbar();

  async function cancelaColeta() {
    try {
      setConfirmar(false);

      await api.delete(`/api/coletaDomiciliar/apagar/agendamento/${coleta.id}`);
      SalvaLog(
        `Cancelou a coleta externa de ID ${coleta.id} do cliente ${coleta.localizador}`,
        "Coleta Domiciliar",
        JSON.stringify(coleta),
        `${coleta.id}`
      );

      await selecionaData(novaData);
      enqueueSnackbar("Agendamento cancelado com sucesso", {
        variant: "success",
      });
    } catch (error) {
      SalvaLog(
        `Tentou cancelar a coleta externa de ID ${coleta.id} do cliente ${coleta.localizador}`,
        "Coleta Domiciliar",
        JSON.stringify(coleta),
        `${coleta.id}`
      );
      enqueueSnackbar("Não foi possível cancelar este agendamento", {
        variant: "error",
      });
    }
    fechaOpcoes();
  }

  return (
    <Dialog
      open={confirmar}
      onClose={() => {
        setConfirmar(false);
      }}
    >
      <DialogTitle id="alert-dialog-title">{msg}</DialogTitle>
      <DialogActions>
        <button
          type="button"
          className="btnDialogs"
          onClick={() => {
            cancelaColeta();
          }}
        >
          Confirmar
        </button>
        <button
          type="button"
          className="btnDialogs"
          onClick={() => {
            setConfirmar(false);
          }}
        >
          Desistir
        </button>
      </DialogActions>
    </Dialog>
  );
}
