import { api } from "../services/Api";
import CapturaBrowser from "./CapturaBrowser";

export default function SalvaLog(evento, app, detalhe, identidade = null) {
  const usuario = localStorage.getItem("@DNACenter:codigoUsuario");

  const dispositivo = "Web";
  const sistema = CapturaBrowser() || "Outro";

  api.post("/api/logs", {
    app,
    evento,
    detalhe,
    dispositivo,
    sistema,
    usuario,
    identidade,
  });
}
