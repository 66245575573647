
export default function formatoMoeda(valor, semSigla) {
  if (valor) {
    let estilo = { style: "currency", currency: "BRL"};
    if (semSigla) {
      estilo = { style: "decimal", currency: "BRL", maximumFractionDigits: 2, minimumFractionDigits: 2}
    }
    return valor.toLocaleString("pt-BR", estilo);
  }
  return 0
}
