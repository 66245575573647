export default function filtroPesquisa(todasAsLinhas, filtro, pesquisado, filtro2) {
  const pesquisaFiltrada = todasAsLinhas.filter(el => {
    if (el[filtro]) {
      return el[filtro]
        .toString()
        .toLowerCase()
        .includes(pesquisado.toString().toLowerCase());
    }
    return false;
  });
  const pesquisaFiltrada2 = todasAsLinhas.filter(el => {
    if (el[filtro2]) {
      return el[filtro2]
        .toString()
        .toLowerCase()
        .includes(pesquisado.toString().toLowerCase());
    }
    return false;
  });

  return [...pesquisaFiltrada, ...pesquisaFiltrada2];
}
