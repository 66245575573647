import React, { useEffect, useState, useContext, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import clsx from "clsx";
import {
  Paper,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
} from '@mui/material';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import PersonIcon from "@mui/icons-material/Person";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

import useUser from "../../../hooks/useUser";

import PermissaoAcesso from "../../../components/PermissaoAcesso";
import PermissaoPapel from "../../../components/PermissaoPapel";

import { Context as PermissoesContext } from "../../../Context/PermissoesContext";

import { compararDadosUsuario, CustomDialog } from "./util";

import { api } from "../../../services/Api";

import "./styles.css";

export default function PainelControle({
  usuarioSelecionado,
  setUsuarioSelecionado,
  setLoading,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const [modulos, setModulos] = useState([]);
  const [dadosUsuario, setDadosUsuario] = useState({
    originais: {
      acoes: {
        autorizadas: [],
        nao_autorizadas: [],
      },
      papel: null,
    },
    modificados: {
      acoes: {
        autorizadas: [],
        nao_autorizadas: [],
      },
      papel: null,
    },
  });
  const [preMudanca, setPreMudanca] = useState({
    autorizadas: [],
    nao_autorizadas: [],
  });
  const [moduloSelecionado, setModuloSelecionado] = useState("");
  const [permissoesPapelSelecionado, setPermissoesPapelSelecionado] = useState([]);
  const [papeisModuloSelecionado, setPapeisModuloSelecionado] = useState([]);
  const [modo, setModo] = useState("permissoes"); // gerenciando papeis ou permissoes?
  const [dialog, setDialog] = useState({
    open: false,
    title: null,
    text: null,
    continuar: null,
    voltar: null,
  });
  const [permissoesUsuario, setPermissoesUsuario] = useState([]);
  const username = useUser();
  const {
    sobreescreverPermissoes,
    setReload,
    checarPermissaoComAcao,
    checarPermissao } = useContext(PermissoesContext);

  const [codModulo, setCodModulo] = useState('');

  useEffect(() => {
    // pegando todos os modulos
    (async () => {
      setLoading(true);
      try {
        const { data } = await api.get("/v2/controleAcesso/modulos");
        setModulos(data.modulos);
      } catch (e) {
        enqueueSnackbar("Algo deu errado. Por favor, recarregue a página.", {
          variant: "error",
        });
      }
      setLoading(false);
    })();
  }, [setLoading, enqueueSnackbar]);

  useEffect(() => {
    (async () => {
      const {
        data: { permissoes },
      } = await api.get(`/v2/controleAcesso/usuario/${usuarioSelecionado.id}/permissoes`);
      setPermissoesUsuario([...permissoes]);
    })();
  }, [usuarioSelecionado.id]);

  useEffect(() => {
    setPermissoesPapelSelecionado([]);
  }, [modo, moduloSelecionado]);

  useEffect(() => {
    // recuperando as permissoes atreladas ao modulo selecionado
    (async () => {
      setDadosUsuario(prevState => ({
        ...prevState,
        modificados: {
          ...prevState.originais,
        },
      }));
      setPreMudanca({ autorizadas: [], nao_autorizadas: [] });
      if (moduloSelecionado !== "" && modo === "permissoes") {
        setLoading(true);
        try {
          const moduloSelecionadoID = modulos.find(
            modulo => modulo.nome === moduloSelecionado
          ).id;
          // agora, precisamos ver quais dessas acoes o usuario pode e nao pode fazer
          const { data } = await api.get(
            `/v2/controleAcesso/modulo/${moduloSelecionadoID}/permissoes`
          );
          setCodModulo((data.permissoes[0].codigo).replace('1','0'))
          const acoesAutorizadas = data.permissoes.filter(
            permissao =>
              permissoesUsuario.find(permissao2 => permissao2.id === permissao.id) !==
              undefined
          );
          const acoesNaoAutorizadas = data.permissoes.filter(
            permissao =>
              acoesAutorizadas.find(permissao2 => permissao2.id === permissao.id) ===
              undefined
          );
          setDadosUsuario({
            originais: {
              acoes: {
                autorizadas: acoesAutorizadas,
                nao_autorizadas: acoesNaoAutorizadas,
              },
              papel: usuarioSelecionado.id_papel,
            },
            modificados: {
              acoes: {
                autorizadas: acoesAutorizadas,
                nao_autorizadas: acoesNaoAutorizadas,
              },
              papel: usuarioSelecionado.id_papel,
            },
          });
        } catch (e) {
          enqueueSnackbar("Algo deu errado. Tente novamente.", {
            variant: "error",
            autoHideDuration: 3000,
          });
        }
        setLoading(false);
      } else if (moduloSelecionado !== "" && modo === "cargos") {
        // precisamos pegar os papeis associados a este modulo selecionado
        setLoading(true);
        const { id: moduloSelecionadoID } = modulos.find(
          modulo => modulo.nome === moduloSelecionado
        );
        try {
          let response = await api.get(
            `/v2/controleAcesso/modulo/${moduloSelecionadoID}/papeis`
          );
          const papeisModulo = response.data.papeis;
          response = await api.get(`/v2/controleAcesso/papeis/universais`);
          const papeisUniversais = response.data.papeis;
          setPapeisModuloSelecionado([...papeisUniversais, ...papeisModulo]);
        } catch (e) {
          enqueueSnackbar("Algo deu errado. Tente novamente.", {
            variant: "error",
            autoHideDuration: 3000,
          });
        }
        setLoading(false);
      }
    })();
  }, [
    moduloSelecionado,
    modo,
    enqueueSnackbar,
    modulos,
    permissoesUsuario,
    setLoading,
    usuarioSelecionado.id_papel,
  ]);

  useEffect(() => {
    (async () => {
      if (dadosUsuario.modificados.papel !== null && modo === "cargos") {
        setLoading(true);
        try {
          const {
            data: { permissoes },
          } = await api.get(
            `/v2/controleAcesso/papel/${dadosUsuario.modificados.papel}/permissoes`
          );
          const { id: moduloSelecionadoID } = modulos.find(
            modulo => modulo.nome === moduloSelecionado
          );
          if (permissoes[0].id_modulo === moduloSelecionadoID) {
            setPermissoesPapelSelecionado(permissoes);
          }
        } catch (e) {
          enqueueSnackbar("Algo deu errado. Por favor, tente novamente.", {
            variant: "error",
            autoHideDuration: 3000,
          });
        }
        setLoading(false);
      }
    })();
  }, [
    dadosUsuario.modificados.papel,
    modo,
    moduloSelecionado,
    enqueueSnackbar,
    modulos,
    setLoading,
  ]);

  const existemAlteracoesNaoSalvas = useMemo(
    () => !compararDadosUsuario(dadosUsuario, modo),
    [dadosUsuario, modo]
  );

  function checaUsuarioPermissao() {
    if (!checarPermissao("CA002")) {
      if (!checarPermissao(codModulo)) {
        enqueueSnackbar(`Você não tem permissão para realizar esta ação. Código: ${codModulo}`, {
          variant: "error",
          autoHideDuration: 3000,
        });
        return false
      }
    }
    return true
  }

  const moverParaEsquerda = () => {
    if (checaUsuarioPermissao()) {
      if (preMudanca.nao_autorizadas.length === 0) {
        enqueueSnackbar("Você não selecionou nenhuma permissão", {
          variant: "warning",
          autoHideDuration: 1000,
        });
        return;
      }
      // igual a funcao de moverParaDireita, temos que tira-los da direita e coloca-los na esquerda
      // para cada elemento localizado na preMudanca.nao_autorizadas, temos que tira-los de
      // dadosUsuario.modificados.acoes.autorizadas e colocalos em dadosUsuario.modificados.acoes.nao_autorizdas

      const acoesAutorizadasCopia = dadosUsuario.modificados.acoes.autorizadas.slice();
      preMudanca.nao_autorizadas.forEach(item => {
        const targetIndex = acoesAutorizadasCopia.findIndex(
          item2 => item2.id === item.id
        );
        acoesAutorizadasCopia.splice(targetIndex, 1);
      });

      setDadosUsuario(prevState => ({
        ...prevState,
        modificados: {
          ...prevState.modificados,
          acoes: {
            autorizadas: acoesAutorizadasCopia,
            nao_autorizadas: [
              ...prevState.modificados.acoes.nao_autorizadas,
              ...preMudanca.nao_autorizadas,
            ],
          },
        },
      }));
      setPreMudanca(prevState => ({
        ...prevState,
        nao_autorizadas: [],
      }));
    }
  };

  const moverParaDireita = async () => {
    if (await checaUsuarioPermissao()) {
      if (preMudanca.autorizadas.length === 0) {
        enqueueSnackbar("Você não selecionou nenhuma permissão", {
          variant: "warning",
          autoHideDuration: 1000,
        });
        return;
      }
      // precisamos mover os elementos selecionados para a direita e tira-los da esquerda
      // para cada elemento localizado na pre mudanca, temos que tira-lo das acoes nao autorizadas,
      // ja que eles passarao pras acoes autorizadas
      const acoesNaoAutorizadasCopia =
        dadosUsuario.modificados.acoes.nao_autorizadas.slice();
      preMudanca.autorizadas.forEach(item => {
        const targetIndex = acoesNaoAutorizadasCopia.findIndex(
          item2 => item2.id === item.id
        );
        acoesNaoAutorizadasCopia.splice(targetIndex, 1);
      });

      setDadosUsuario(prevState => ({
        ...prevState,
        modificados: {
          ...prevState.modificados,
          acoes: {
            nao_autorizadas: acoesNaoAutorizadasCopia,
            autorizadas: [
              ...prevState.modificados.acoes.autorizadas,
              ...preMudanca.autorizadas,
            ],
          },
        },
      }));

      setPreMudanca(prevState => ({
        ...prevState,
        autorizadas: [],
      }));
    }
  };

  const moverTodosParaEsquerda = async () => {
    if (await checaUsuarioPermissao()) {
      if (dadosUsuario.modificados.acoes.autorizadas.length === 0) {
        enqueueSnackbar("Não há ações autorizadas.", {
          variant: "warning",
          autoHideDuration: 1000,
        });
        return;
      }

      if (
        preMudanca.autorizadas.length !== 0 ||
        preMudanca.nao_autorizadas.length !== 0
      ) {
        enqueueSnackbar("Desselecione todas as ações antes.", {
          variant: "warning",
          autoHideDuration: 1000,
        });
        return;
      }

      setDadosUsuario(prevState => ({
        ...prevState,
        modificados: {
          ...prevState.modificados,
          acoes: {
            autorizadas: [],
            nao_autorizadas: [
              ...prevState.modificados.acoes.nao_autorizadas,
              ...prevState.modificados.acoes.autorizadas,
            ],
          },
        },
      }));
    }
  };

  const moverTodosParaDireita = async () => {
    if (await checaUsuarioPermissao()) {
      if (dadosUsuario.modificados.acoes.nao_autorizadas.length === 0) {
        enqueueSnackbar("Não há ações não autorizadas.", {
          variant: "warning",
          autoHideDuration: 1000,
        });
        return;
      }
      if (
        preMudanca.autorizadas.length !== 0 ||
        preMudanca.nao_autorizadas.length !== 0
      ) {
        enqueueSnackbar("Desselecione todas as ações antes.", {
          variant: "warning",
          autoHideDuration: 1000,
        });
        return;
      }
      setDadosUsuario(prevState => ({
        ...prevState,
        modificados: {
          ...prevState.modificados,
          acoes: {
            nao_autorizadas: [],
            autorizadas: [
              ...prevState.modificados.acoes.autorizadas,
              ...prevState.modificados.acoes.nao_autorizadas,
            ],
          },
        },
      }));
    }
  };

  const mostrarDialogAlteracoesNaoSalvas = continuar => {
    setDialog({
      open: true,
      continuar: () => {
        continuar();
        setDialog(prevState => ({ ...prevState, open: false }));
      },
      voltar: () => setDialog(prevState => ({ ...prevState, open: false })),
      text: `Volte e salve as alterações ou continue sem salvá-las.`,
      title: "Existem alterações não salvas",
    });
  };

  const salvarAlteracoes = async () => {
    if (modo === "permissoes") {
      const { id: moduloSelecionadoID } = modulos.find(
        modulo => modulo.nome === moduloSelecionado
      );
      setLoading(true);
      try {
        await api.put(
          `/v2/controleAcesso/usuario/${usuarioSelecionado.id}/permissoes/${moduloSelecionadoID}`,
          {
            permissoes: dadosUsuario.modificados.acoes.autorizadas,
          }
        );
      } catch (e) {
        enqueueSnackbar(
          "Não foi possível salvar suas alterações. Recarregue a página e tente novamente.",
          {
            variant: "error",
            autoHideDuration: 3000,
          }
        );
        return;
      }
      setDadosUsuario(prevState => ({
        ...prevState,
        originais: {
          ...prevState.modificados,
        },
      }));
      // somente atualizamos o context se o usuario logado for o mesmo manipulado
      if (username === usuarioSelecionado.username) {
          setReload(prevState => !prevState)
      } else {
        // senao, basta atualizar o objeto dadosUsuario
        setPermissoesUsuario(dadosUsuario.modificados.acoes.autorizadas);
      }
      setLoading(false);
      enqueueSnackbar("Alterações salvas com sucesso.", {
        variant: "success",
        autoHideDuration: 3000,
      });
    } else {
      checarPermissaoComAcao("CA003", async () => {
        setLoading(true);
        try {
          await api.put(`/v2/controleAcesso/usuario/${usuarioSelecionado.id}/papel`, {
            id_papel: dadosUsuario.modificados.papel,
          });
        } catch (e) {
          enqueueSnackbar("Algo deu errado. Tente novamente.", {
            variant: "error",
            autoHideDuration: 3000,
          });
          setLoading(false);
          return;
        }
        setLoading(false);
        enqueueSnackbar("Alterações salvas com sucesso.", {
          variant: "success",
          autoHideDuration: 3000,
        });

        // somente atualizamos o context se o usuario logado for o mesmo manipulado
        if (username === usuarioSelecionado.username) {
          sobreescreverPermissoes(permissoesPapelSelecionado);
        } else {
          // senao, basta atualizar o objeto dadosUsuario
          setPermissoesUsuario(permissoesPapelSelecionado);
        }
        setDadosUsuario(prevState => ({
          ...prevState,
          originais: {
            ...prevState.modificados,
          },
        }));
        setUsuarioSelecionado(prevState => ({
          ...prevState,
          id_papel: dadosUsuario.modificados.papel,
        }));
      });
    }
  };

  return (
    <main className="controle-acesso-wrapper">
      <CustomDialog
        open={dialog.open}
        title={dialog.title}
        text={dialog.text}
        continuar={dialog.continuar}
        voltar={dialog.voltar}
      />
      <ArrowBackIcon
        className="back-icon"
        fontSize="large"
        onClick={() => {
          if (usuarioSelecionado.controle === true) {
            history.push("/cadastrausuario");
          } else if (existemAlteracoesNaoSalvas) {
            mostrarDialogAlteracoesNaoSalvas(() => setUsuarioSelecionado(null));
            return;
          }
          setUsuarioSelecionado(null);
        }}
        style={{ marginBottom: "1rem" }}
      />
      <Paper className="controle-acesso-topo">
        <TextField
          className="usuario-selecionado"
          disabled
          label="Usuário selecionado"
          value={usuarioSelecionado.username}
        />
        <FormControl className="modulo-select">
          <InputLabel id="modulo-label">Módulo</InputLabel>
          <Select
            labelId="modulo-label"
            value={moduloSelecionado}
            onChange={event => {
              if (existemAlteracoesNaoSalvas) {
                mostrarDialogAlteracoesNaoSalvas(() =>
                  setModuloSelecionado(event.target.value)
                );
                return;
              }
              setModuloSelecionado(event.target.value);
            }}
          >
            {modulos.map(modulo => (
              <MenuItem value={modulo.nome} key={`${modulo.nome}-${modulo.id}`}>
                {modulo.nome}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Paper>
      {moduloSelecionado !== "" && (
        <section className="controle-acesso-baixo">
          <div className="controle-acesso-baixo-conteudo">
            <div className="painel-secao">
              <h2>{modo === "permissoes" ? "Ações não autorizadas" : "Cargos"}</h2>
              <Paper className="painel-secao-card">
                <div className="card-conteudo">
                  {modo === "permissoes"
                    ? dadosUsuario.modificados.acoes.nao_autorizadas.map(acao => (
                        // eslint-disable-next-line react/jsx-indent
                        <PermissaoAcesso
                          interactive
                          codigo={acao.codigo}
                          nome={acao.nome}
                          key={`acao-${acao.id}`}
                          inserirPreMudanca={
                            () =>
                              setPreMudanca(prevState => ({
                                ...prevState,
                                autorizadas: [...prevState.autorizadas, acao],
                              }))
                            // eslint-disable-next-line react/jsx-curly-newline
                          }
                          removerPreMudanca={() => {
                            const targetIndex = preMudanca.autorizadas.findIndex(
                              acao2 => acao2.id === acao.id
                            );
                            const copia = preMudanca.autorizadas.slice();
                            copia.splice(targetIndex, 1);
                            setPreMudanca(prevState => ({
                              ...prevState,
                              autorizadas: copia,
                            }));
                          }}
                        />
                      ))
                    : papeisModuloSelecionado.map(papel => (
                        // eslint-disable-next-line react/jsx-indent
                        <PermissaoPapel
                          key={`papel-${papel.id}`}
                          nome={papel.nome}
                          descricao={papel.descricao}
                          selected={papel.id === dadosUsuario.modificados.papel}
                          onClick={() => {
                            setDadosUsuario(prevState => ({
                              ...prevState,
                              modificados: {
                                ...prevState.modificados,
                                papel: papel.id,
                              },
                            }));
                          }}
                        />
                      ))}
                </div>
                <div
                  className="card-footer"
                  onClick={
                    () => {
                      if (existemAlteracoesNaoSalvas) {
                        mostrarDialogAlteracoesNaoSalvas(() =>
                          setModo(() => (modo === "permissoes" ? "cargos" : "permissoes"))
                        );
                        return;
                      }
                      setModo(() => (modo === "permissoes" ? "cargos" : "permissoes"));
                    }
                    // eslint-disable-next-line react/jsx-curly-newline
                  }
                >
                  {modo === "permissoes" ? (
                    <PersonIcon fontSize="large" style={{ color: "rgba(0,0,0,0.6)" }} />
                  ) : (
                    <CheckBoxIcon fontSize="large" style={{ color: "rgba(0,0,0,0.6)" }} />
                  )}
                  <span>
                    {modo === "permissoes"
                      ? `Utilizar cargos pré-definidos`
                      : `Selecionar ações individuais`}
                  </span>
                </div>
              </Paper>
            </div>
            <div className="painel-botoes">
              {modo === "permissoes" && (
                <>
                  <div className="painel-botao" onClick={moverTodosParaDireita}>{`>>`}</div>
                  <div className="painel-botao" onClick={moverParaDireita}>{`>`}</div>
                  <div className="painel-botao" onClick={moverParaEsquerda}>{`<`}</div>
                  <div className="painel-botao" onClick={moverTodosParaEsquerda}>{`<<`}</div>
                </>
              )}
            </div>
            <div className="painel-secao">
              <h2>Ações autorizadas</h2>
              <Paper className="painel-secao-card">
                <div className="card-conteudo">
                  {modo === "permissoes"
                    ? dadosUsuario.modificados.acoes.autorizadas.map(acao => (
                        // eslint-disable-next-line react/jsx-indent
                        <PermissaoAcesso
                          codigo={acao.codigo}
                          nome={acao.nome}
                          key={`acao-${acao.id}`}
                          interactive
                          inserirPreMudanca={
                            () =>
                              setPreMudanca(prevState => ({
                                ...prevState,
                                nao_autorizadas: [...prevState.nao_autorizadas, acao],
                              }))
                            // eslint-disable-next-line react/jsx-curly-newline
                          }
                          removerPreMudanca={() => {
                            const targetIndex = preMudanca.nao_autorizadas.findIndex(
                              acao2 => acao2.id === acao.id
                            );
                            const copia = preMudanca.nao_autorizadas.slice();
                            copia.splice(targetIndex, 1);
                            setPreMudanca(prevState => ({
                              ...prevState,
                              nao_autorizadas: copia,
                            }));
                          }}
                        />
                      ))
                    : permissoesPapelSelecionado.map(permissao => (
                      <PermissaoAcesso
                        codigo={permissao.codigo}
                        nome={permissao.nome}
                        key={`acao-${permissao.id}`}
                      />
                    ))}
                </div>
              </Paper>
            </div>
          </div>

          <div className="painel-buttons-wrapper">
            <Button
              onClick={() => {
                setDadosUsuario(prevState => ({
                  ...prevState,
                  modificados: {
                    ...prevState.originais,
                  },
                }));
                setPreMudanca({ autorizadas: [], nao_autorizadas: [] });
                setPermissoesPapelSelecionado([]);
              }}
              className={clsx({ disabled: !existemAlteracoesNaoSalvas, secondary: true })}
              disabled={!existemAlteracoesNaoSalvas}
            >
              CANCELAR ALTERAÇÕES
            </Button>
            <Button
              onClick={salvarAlteracoes}
              className={clsx({ disabled: !existemAlteracoesNaoSalvas })}
              disabled={!existemAlteracoesNaoSalvas}
            >
              SALVAR ALTERAÇÕES
            </Button>
          </div>
        </section>
      )}
    </main>
  );
}
