import React from 'react';

import "./styles.css";

export default function InputData(props){
    const { labelText, data, acao} = props;

    return (
      <div id="divData">
        <label
          id="labelData"
          htmlFor="inputData"
        >
          {labelText}
        </label>
        <input
          id="inputData"
          type="date"
          defaultValue={data}
          onChange={(e) => {
            e.preventDefault()
            acao(e.target.value)
          }}
        />
      </div>
    )
}
