import React from "react";
import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  DialogActions,
} from "@mui/material";
import dayjs from "dayjs";

import Dialog from "../../components/Dialog";
import Button from "../../components/Button";
import DataPickerAtualizado from "../../components/DataPickerAtualizado";

export default function ExamDialog({
  showDialog,
  dialogValues,
  atualizarCampoDialog,
  horarios,
  closeDialog,
  onConfirm,
  modoEdicao,
}) {
  return (
    <Dialog
      title="Agendar exame"
      open={showDialog}
      style={{ width: "95%", maxWidth: "800px" }}
    >
      <Grid container spacing={2}>
        <Grid item xs={8} md={3}>
          <DataPickerAtualizado
            data={dayjs(dialogValues.data)}
            setData={(value) => atualizarCampoDialog("data", value)}
            variant="inline"
            labelName={"Data do exame"}
          />

          {/* <DatePicker
            label="Data do exame"
            format="dd/MM/yyyy"
            fullWidth
            value={dayjs(dialogValues.data)}
            onChange={(value) => atualizarCampoDialog("data", value)}
          /> */}
        </Grid>
        <Grid item xs={4}>
          <FormControl fullWidth>
            <InputLabel id="horario-exame-label">Horário</InputLabel>
            <Select
              labelId="horario-exame-label"
              value={dialogValues.horario}
              onChange={(e) => atualizarCampoDialog("horario", e.target.value)}
            >
              {horarios.map((h) => {
                const vagasRestantes = h.lotacao_maxima - h.quantidade_exames;
                return (
                  <MenuItem
                    value={h.id}
                    key={h.id}
                    style={{
                      color: h.desabilitado ? "rgba(0,0,0,0.6)" : "inherit",
                    }}
                  >
                    <span
                      style={{
                        textDecoration: h.desabilitado
                          ? "line-through"
                          : "none",
                      }}
                    >
                      {h.hora}:{h.minutos}
                      {h.minutos === 0 && "0"}
                    </span>
                    {h.desabilitado && `(Desabilitado)`}
                    {!h.desabilitado && ` (${vagasRestantes} vagas)`}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={9} md={5}>
          <FormControl fullWidth>
            <InputLabel id="tipo-exame-label">Tipo do exame</InputLabel>
            <Select
              labelId="tipo-exame-label"
              value={dialogValues.tipo_exame}
              onChange={(e) =>
                atualizarCampoDialog("tipo_exame", e.target.value)
              }
            >
              <MenuItem value="espermograma">Espermograma</MenuItem>
              <MenuItem value="capacitacao">Capacitação</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={7}>
          <TextField
            fullWidth
            label="Nome do cliente"
            value={dialogValues.nome_cliente}
            onChange={(e) => {
              // e.persist();
              atualizarCampoDialog("nome_cliente", e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={12} sm={5}>
          <TextField
            fullWidth
            label="Contato"
            value={dialogValues.contato}
            onChange={(e) => {
              // e.persist();
              atualizarCampoDialog("contato", e.target.value);
            }}
          />
        </Grid>
      </Grid>
      <DialogActions style={{ marginTop: "2rem" }}>
        <Button secondary onClick={() => closeDialog()}>
          CANCELAR
        </Button>
        <Button secondary onClick={onConfirm}>
          {modoEdicao ? "CONFIRMAR" : "AGENDAR"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
