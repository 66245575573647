import React from "react";

import "./styles.css";

export default function Input({
  value,
  onChange,
  label,
  type,
  name,
  placeholder,
  inputStyles,
  containerStyles,
  className,
}) {
  return (
    <fieldset
      className={`input-container ${className}`}
      style={containerStyles}
    >
      {label && (
        <label className="input-label" htmlFor={name}>
          {label}
        </label>
      )}
      <input
        placeholder={placeholder}
        className="input"
        style={inputStyles}
        type={type || "text"}
        name={name}
        id={name}
        value={value}
        onChange={(e) => {
          // e.persist();
          const {
            target: { value: newValue },
          } = e;
          if (onChange) {
            onChange(newValue);
          }
        }}
      />
    </fieldset>
  );
}
