import React, { useState, useEffect, useMemo, useRef } from "react";
import { useHistory } from "react-router-dom";
import {
  Modal,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Button,
  Paper,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  DialogActions,
  TextField,
  TablePagination,
} from "@mui/material";

import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import SearchIcon from "@mui/icons-material/Search";

import SettingsIcon from "@mui/icons-material/Settings";
import CloseIcon from "@mui/icons-material/Close";
import DescriptionIcon from "@mui/icons-material/Description";
import ReplayIcon from "@mui/icons-material/Replay";
import DeleteIcon from "@mui/icons-material/Delete";
import { useSnackbar } from "notistack";

import "./styles.css";

import useUser from "../../../hooks/useUser";

import Header from "../../../components/Header";
// import FiltroControle from "../../../components/FiltroControle";
import FiltroValor from "../../../components/FiltroValor";
import Loading from "../../../components/Loading";

import { api } from "../../../services/Api";
import formatarData from "../../../utils/formatarData";
import BI from "../BI";
import dayjs from "dayjs";

export default function ControleFormulario() {
  const usuario = useUser();

  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const inputRef = useRef(null);

  const dataHoje = useMemo(() => new Date(Date.now()), []);

  const [filtros, setFiltros] = useState([]);
  const [formularios, setFormularios] = useState([]);
  const [novoFiltroTipo, setNovoFiltroTipo] = useState("Cliente");
  const [novoFiltroValor, setNovoFiltroValor] = useState("");
  const [abrirModal, setAbrirModal] = useState(false);
  const [abrirDialog, setAbrirDialog] = useState(false);
  const [abrirDialogExcluir, setAbrirDialogExcluir] = useState(false);
  const [formularioSelecionadoID, setFormularioSelecionadoID] = useState(null); // id do formulario
  const [dataInicio, setDataInicio] = useState(
    () =>
      new Date(
        dataHoje.getFullYear(),
        dataHoje.getMonth(),
        dataHoje.getDate(),
        0,
        0
      )
  );
  const [dataFim, setDataFim] = useState(
    () =>
      new Date(
        dataHoje.getFullYear(),
        dataHoje.getMonth(),
        dataHoje.getDate(),
        23,
        59
      )
  );

  const [loading, setLoading] = useState(true);
  const [pagination, setPagination] = useState({
    paginaAtual: 0,
    totalFormularios: 0,
  });
  const [pesquisaCPF, setPesquisaCPF] = useState("");

  const [aba, setAba] = useState("Controle");

  useEffect(() => {
    if (dataInicio > dataFim) {
      enqueueSnackbar("A data inicial não pode ocorrer depois da data final.", {
        variant: "error",
        autoHideDuration: 3000,
      });
      setDataInicio(
        new Date(
          dataHoje.getFullYear(),
          dataHoje.getMonth(),
          dataHoje.getDate(),
          0,
          0
        )
      );
      setDataFim(
        new Date(
          dataHoje.getFullYear(),
          dataHoje.getMonth(),
          dataHoje.getDate(),
          23,
          59
        )
      );
      return;
    }
    setPagination((prevState) => ({ ...prevState, paginaAtual: 0 }));
    inputRef.current.value = "";
    setPesquisaCPF("");
  }, [dataInicio, dataFim, dataHoje, enqueueSnackbar]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const { data } = await api.get("/api/controleFormulario", {
        params: {
          dataInicio,
          dataFim,
          pagina: pagination.paginaAtual,
          cpf: pesquisaCPF,
        },
      });
      setFormularios([...data.results]);
      setLoading(false);
      setPagination((prevState) => ({
        ...prevState,
        totalFormularios: parseInt(data.count, 10),
      }));
    })();
  }, [pagination.paginaAtual, dataInicio, dataFim, pesquisaCPF]);

  const adicionarFiltro = (novoFiltro) => {
    // filtro é valido se o valor estiver preenchido e se ele não estiver no array de filtros
    const filtroValido =
      novoFiltro.valor !== "" &&
      filtros.filter(
        (filtro) =>
          filtro.tipo === novoFiltro.tipo && filtro.valor === novoFiltro.valor
      ).length === 0;
    if (filtroValido) {
      setFiltros((prevState) => [...prevState, novoFiltro]);
    }
    setAbrirModal(false);
    setNovoFiltroValor("");
  };

  const visualizarFormulario = () => {
    const formularioSelecionado = formularios.find(
      (form) => form.id === formularioSelecionadoID
    );
    if (formularioSelecionado.respondido === "NAO") {
      history.push(
        `/formularioCovid/cadastro?cpf=${formularioSelecionado.cpf}&visualizar=true`
      );
    } else {
      history.push(`/respostasFormulario/${formularioSelecionadoID}`);
    }
  };

  const excluirFormulario = async () => {
    try {
      await api.delete(`/api/formularioCovid/${formularioSelecionadoID}`);
    } catch (e) {
      setAbrirDialogExcluir(false);
      enqueueSnackbar(e.response.data.message, {
        variant: "error",
        autoHideDuration: 3000,
      });
      return;
    }
    setFormularios((prevState) =>
      prevState.filter((form) => form.id !== formularioSelecionadoID)
    );
    setFormularioSelecionadoID(null);
    setAbrirDialogExcluir(false);
    enqueueSnackbar("Formulário excluído!", {
      variant: "success",
      autoHideDuration: 2000,
    });
  };

  const modalBody = (
    <Paper className="modal-body">
      <CloseIcon
        style={{ position: "absolute", top: 12, right: 16, cursor: "pointer" }}
        onClick={() => {
          setAbrirModal(false);
          // valores padrões
          setNovoFiltroTipo("Cliente");
          setNovoFiltroValor("");
        }}
      />
      <h2>Adicionar filtro</h2>
      <div className="modal-body-inner">
        <div className="modal-input-wrapper">
          <FormControl style={{ width: "45%" }}>
            <InputLabel id="novo-filtro-tipo-label">Tipo do filtro</InputLabel>
            <Select
              labelId="novo-filtro-tipo-label"
              id="novo-filtro-tipo"
              label="Tipo do filtro"
              value={novoFiltroTipo}
              onChange={(event) => {
                setNovoFiltroTipo(event.target.value);
                setNovoFiltroValor("");
              }}
            >
              <MenuItem value="Cliente">Cliente</MenuItem>
              <MenuItem value="Respondido">Respondido</MenuItem>
              <MenuItem value="Notificado">Notificado</MenuItem>
            </Select>
          </FormControl>
          <FiltroValor
            tipo={novoFiltroTipo}
            valor={novoFiltroValor}
            atualizarValor={setNovoFiltroValor}
          />
        </div>
        <Button
          color="primary"
          style={{
            backgroundColor: "#033399",
            fontSize: "22px",
            alignSelf: "flex-end",
          }}
          onClick={() => {
            adicionarFiltro({ tipo: novoFiltroTipo, valor: novoFiltroValor });
          }}
        >
          ADICIONAR
        </Button>
      </div>
    </Paper>
  );

  const dialogBody = (
    <DialogContent>
      <DialogTitle>Opções</DialogTitle>
      <List component="ul">
        <ListItem
          component="li"
          className="dialog-list-item"
          onClick={visualizarFormulario}
        >
          <ListItemIcon>
            <DescriptionIcon />
          </ListItemIcon>
          <ListItemText>Visualizar</ListItemText>
        </ListItem>
        <ListItem
          component="li"
          className="dialog-list-item"
          onClick={() => {
            setAbrirDialog(false);
            setAbrirDialogExcluir(true);
          }}
        >
          <ListItemIcon>
            <DeleteIcon />
          </ListItemIcon>
          <ListItemText>Excluir</ListItemText>
        </ListItem>
        <ListItem
          component="li"
          className="dialog-list-item"
          onClick={() => setAbrirDialog(false)}
        >
          <ListItemIcon>
            <ReplayIcon />
          </ListItemIcon>
          <ListItemText>Voltar</ListItemText>
        </ListItem>
      </List>
    </DialogContent>
  );

  const excluirFormDialogBody = (
    <DialogContent>
      <DialogTitle style={{ padding: 0, marginBottom: "1rem" }}>
        Excluir Formulário
      </DialogTitle>
      <DialogContentText>
        Você tem certeza de que quer excluir esse formulário?
      </DialogContentText>
      <DialogActions style={{ justifyContent: "space-between" }}>
        <Button
          style={{
            backgroundColor: "#ed3237",
          }}
          onClick={excluirFormulario}
        >
          Sim
        </Button>
        <Button
          onClick={() => setAbrirDialogExcluir(false)}
          style={{
            backgroundColor: "#003399",
          }}
        >
          Não
        </Button>
      </DialogActions>
    </DialogContent>
  );

  const controle = () => (
    <>
      <Paper className="wrapper-topo">
        <div className="wrapper-datas">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              invalidDateMessage="Formato inválido"
              label="Data inicial"
              format="dd/MM/yyyy"
              placeholder="dd/mm/aaaa"
              value={dayjs(dataInicio)}
              onChange={(date) => setDataInicio(date)}
              style={{ marginRight: "1rem" }}
              okLabel="Confirmar"
              cancelLabel="Cancelar"
            />
            <DatePicker
              invalidDateMessage="Formato inválido"
              label="Data final"
              format="dd/MM/yyyy"
              placeholder="dd/mm/aaaa"
              value={dayjs(dataFim)}
              onChange={(date) => setDataFim(date)}
              okLabel="Confirmar"
              cancelLabel="Cancelar"
            />
          </LocalizationProvider>
        </div>

        <Button
          onClick={() => history.push("/formularioCovid/cadastro")}
          color="primary"
          style={{
            backgroundColor: "#033399",
            fontSize: "20px",
            color: "white",
          }}
        >
          Cadastrar
        </Button>
      </Paper>
      <form
        onSubmit={(event) => {
          event.preventDefault();
          setPesquisaCPF(inputRef.current.value);
        }}
        style={{
          float: "right",
          marginBottom: "1rem",
          display: "flex",
          alignItems: "flex-end",
        }}
      >
        <TextField label="Pesquisar por CPF" inputRef={inputRef} />
        <Button
          style={{
            cursor: "pointer",
            backgroundColor: "#003399",
            marginLeft: "10px",
          }}
          onClick={() => setPesquisaCPF(inputRef.current.value)}
        >
          <SearchIcon style={{ color: "white" }} />
        </Button>
      </form>
      <TableContainer className="table-container" component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center">CPF do Cliente</TableCell>
              <TableCell align="center">Nome do Cliente</TableCell>
              <TableCell align="center">Data/Hora</TableCell>
              <TableCell align="center">Respondido</TableCell>
              <TableCell align="center">Notificado</TableCell>
              <TableCell align="center">Usuário</TableCell>
              <TableCell align="center">Opções</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {formularios.map((formulario) => {
              let data;
              let dataFormatada;
              if (formulario.data_resposta) {
                data = new Date(formulario.data_resposta);
                dataFormatada = formatarData(data);
              } else {
                dataFormatada = "-";
              }
              return (
                <TableRow key={formulario.id} className="table-row">
                  <TableCell align="center">{formulario.cpf}</TableCell>
                  <TableCell align="center">
                    {formulario.nome_completo}
                  </TableCell>
                  <TableCell align="center">{dataFormatada}</TableCell>
                  <TableCell align="center">
                    {formulario.respondido === "SIM" ? "Sim" : "Não"}
                  </TableCell>
                  <TableCell align="center">
                    {formulario.notificado === "SIM" ? "Sim" : "Não"}
                  </TableCell>
                  <TableCell align="center">{formulario.usuario}</TableCell>
                  <TableCell align="center">
                    <SettingsIcon
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setFormularioSelecionadoID(formulario.id);
                        setAbrirDialog(true);
                      }}
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          page={pagination.paginaAtual}
          count={pagination.totalFormularios}
          rowsPerPage={10}
          rowsPerPageOptions={[]}
          labelDisplayedRows={(paginationInfo) => {
            const { count, page } = paginationInfo;
            console.log(count);
            return `Página ${count > 0 ? page + 1 : 0} de ${Math.ceil(
              count / 10
            )} `;
          }}
          onChangePage={(_, page) => {
            setPagination((prevState) => ({ ...prevState, paginaAtual: page }));
          }}
        />
      </TableContainer>
    </>
  );

  return (
    <>
      <Header titulo="Formulários COVID" usuario={usuario} history={history} />
      {loading && <Loading />}
      <Dialog open={abrirDialog}>{dialogBody}</Dialog>
      <Dialog open={abrirDialogExcluir}>{excluirFormDialogBody}</Dialog>
      <Modal open={abrirModal}>{modalBody}</Modal>
      <main className="controle-form-wrapper">
        <div id="container-aba-ctrl-samples">
          <button
            type="button"
            className="button-aba-ctrl-samples"
            id={
              aba === "Controle" ? "button-aba-ctrl-samples-selected" : "Aba1"
            }
            onClick={() => {
              setAba("Controle");
            }}
          >
            Controle
          </button>
          <button
            type="button"
            className="button-aba-ctrl-samples"
            id={aba === "BI" ? "button-aba-ctrl-samples-selected" : "Aba1"}
            onClick={() => {
              setAba("BI");
            }}
          >
            BI
          </button>
        </div>
        {aba === "Controle" ? controle() : <BI />}
      </main>
    </>
  );
}
