import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import {
  TextField,
  TextareaAutosize,
  InputLabel,
  Dialog,
  DialogTitle,
  DialogActions,
  Tooltip,
  ListItem,
  List,
  AccordionSummary,
  Accordion,
  AccordionDetails,
  ListItemButton,
} from '@mui/material';
import {
  VerifiedUser,
  Create,
  Policy,
  Replay,
  Message,
  Close,
  ExpandMore,
} from '@mui/icons-material';
import Container from "../../componentsv2/Container";
import Header from "../../components/Header";

import Waiter from "../../components/Backdrop";

import useUser from "../../hooks/useUser";

import { api } from "../../services/Api";

import SalvaLog from "../../utils/SalvaLog";

import "./styles.css";

import { Context as PermissoesContext } from "../../Context/PermissoesContext";

export default function MensagensApp() {
  const history = useHistory();
  const { checarPermissao } = useContext(PermissoesContext);
  const { enqueueSnackbar } = useSnackbar();

  const usuario = useUser();

  useEffect(() => {
    async function inicio() {
      try {
        const {
          data: {
            permitido: {
              0: { id },
            },
          },
        } = await api.get(
          `/v2/controleAcesso/permissaoVisualizaModulo?username=${usuario}&&codigo=MAP001`
        );

        return id;
      } catch (e) {
        return history.push("/home");
      }
    }
    inicio();
  }, [history, usuario]);

  const [mensagens, setMensagens] = useState([]);
  const [mensagem, setMensagem] = useState([]);
  const [identificador, setIdentificador] = useState("");
  const [titulo, setTitulo] = useState("");
  const [corpo, setCorpo] = useState("");

  const [id, setId] = useState("");
  const [tipoModal, setTipoModal] = useState("Criar nova");

  const [novaMensagem, setNovaMensagem] = useState(false);
  const [opcoes, setOpcoes] = useState(false);
  const [opcao, setOpcao] = useState(false);

  const [espera, setEspera] = useState(false);

  const [expanded, setExpanded] = useState(false);
  const [tamanho, setTamanho] = useState("");

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  function sizeOfScreen() {
    setTamanho(window.innerWidth);
  }

  useEffect(() => {
    sizeOfScreen();
  }, []);

  window.addEventListener("resize", () => {
    sizeOfScreen();
  });

  useEffect(() => {
    (async () => {
      setEspera(true);
      try {
        SalvaLog("Entrou na agenda de mensagens do app", "Mensagens App");
        const response = await api.get(`/api/mensagensPush/agendaNotification`);
        setMensagens(response.data);
      } catch (e) {
        enqueueSnackbar("Falha no carregamento da tabela. Por favor, tente novamente.", {
          variant: "error",
        });
      }
      setEspera(false);
    })();
  }, [enqueueSnackbar]);

  function defineTitulo(e) {
    setTitulo(e.target.value);
  }

  function defineCorpo(e) {
    setCorpo(e.target.value);
  }

  function abreNovaMensagem() {
    setNovaMensagem(true);
  }

  function removeMensagem() {
    setId("");
    setIdentificador("");
    setTitulo("");
    setCorpo("");
  }

  function fechaOpcao() {
    setOpcao(false);
    setMensagem([]);
  }

  function fechaNovaMensagem() {
    removeMensagem();
    setNovaMensagem(false);
    setTipoModal("Criar nova");
  }

  function capturaMensagem(idMsg, identificadorMsg, tituloMsg, corpoMsg) {
    setId(idMsg);
    setIdentificador(identificadorMsg);
    setTitulo(tituloMsg);
    setCorpo(corpoMsg);
  }

  function abreOpcoes(idMsg, identificadorMsg, tituloMsg, corpoMsg) {
    capturaMensagem(
      idMsg || mensagem.id,
      identificadorMsg || mensagem.identificador,
      tituloMsg || mensagem.titulo_mensagem,
      corpoMsg || mensagem.corpo_mensagem
    );
    setOpcoes(true);
    fechaOpcao();
  }

  function fechaOpcoes() {
    removeMensagem();
    setOpcoes(false);
  }

  async function atualizaTabela() {
    setEspera(true);
    try {
      const response = await api.get(`/api/mensagensPush/agendaNotification`);
      setMensagens(response.data);
    } catch (error) {
      enqueueSnackbar("Falha no carregamento da tabela", { variant: "error" });
    }

    setEspera(false);
  }

  async function agendaPushNotification() {
    const publico = "Todos";
    try {
      setEspera(true);
      await api.post("/api/mensagensPush/agendaNotification", {
        titulo_mensagem: titulo,
        corpo_mensagem: corpo,
        criado_por: usuario,
        publico,
      });

      SalvaLog(
        `Criou uma nova mensagem de Título: ${titulo} e Mensagem: ${corpo}`,
        "Mensagens App"
      );

      fechaNovaMensagem();
      atualizaTabela();
      enqueueSnackbar("Mensagem salva", { variant: "success" });
    } catch (error) {
      SalvaLog(
        `Tentou criar uma nova mensagem  de Título: ${titulo} e Mensagem: ${corpo}`,
        "Mensagens App"
      );
      enqueueSnackbar("Não foi possível salvar a mensagem", { variant: "error" });
    }
    setEspera(false);
  }

  async function editaAgendaPushNotification() {
    try {
      setEspera(true);
      await api.put("/api/mensagensPush/editaAgendaNotification", {
        id,
        titulo_mensagem: titulo,
        corpo_mensagem: corpo,
        criado_por: usuario,
      });

      SalvaLog(
        `Editou a mensagem de ID: ${id}, Título: ${titulo} e Mensagem: ${corpo}`,
        "Mensagens App"
      );

      fechaNovaMensagem();
      atualizaTabela();
      enqueueSnackbar("A mensagem foi editada", { variant: "success" });
    } catch (error) {
      SalvaLog(
        `Tentou editar a mensagem de ID: ${id}, Título: ${titulo} e Mensagem: ${corpo}`,
        "Mensagens App"
      );
      enqueueSnackbar("Não foi possível editar a mensagem", { variant: "error" });
    }
    setEspera(false);
  }

  async function atualizaAutorizacao(status_mensagem) {
    try {
      setEspera(true);
      await api.put("/api/mensagensPush/agendaNotification", {
        id,
        status_mensagem,
        autorizado_por: usuario,
      });

      if (status_mensagem === "Autorizado") {
        await api.post("/api/mensagensPush/salvaAgendaNotification", {
          identificador,
          titulo_mensagem: titulo,
          corpo_mensagem: corpo,
        });

        SalvaLog(
          `A mensagem de ID: ${id}, Título: ${titulo} e Mensagem: ${corpo} foi autorizada para o envio`,
          "Mensagens App"
        );
        enqueueSnackbar("A mensagem foi liberada para o envio", { variant: "success" });
      } else {
        enqueueSnackbar("A mensagem foi negada para envio", { variant: "info" });
      }

      SalvaLog(
        `Atualizou status de autorização da mensagem de ID: ${id} para ${status_mensagem}`,
        "Mensagens App"
      );

      fechaOpcoes();
      atualizaTabela();
    } catch (error) {
      SalvaLog(
        `Tentou atualizar status de autorização da mensagem de ID: ${id} para ${status_mensagem}`,
        "Mensagens App"
      );
      enqueueSnackbar("Não foi possível liberar a mensagem para envio", {
        variant: "error",
      });
    }
    setEspera(false);
  }

  async function opcaoMensagem(opcaoMsg, idMsg, tituloMsg, corpoMsg) {
    capturaMensagem(
      idMsg || mensagem.id,
      "",
      tituloMsg || mensagem.titulo_mensagem,
      corpoMsg || mensagem.corpo_mensagem
    );
    setTipoModal(opcaoMsg);
    setNovaMensagem(true);
    fechaOpcao();
  }

  function corStatus(statusAtual) {
    if (statusAtual === "Negado") {
      return "red";
    }
    if (statusAtual === "Autorizado") {
      return "green";
    }

    return "orange";
  }

  function corStatusMobile(statusAtual) {
    if (statusAtual === "Negado") {
      return "#F5A9A9";
    }
    if (statusAtual === "Autorizado") {
      return "#CEF6E3";
    }

    return "#FFF";
  }

  return (
      <>
        <Header titulo="Mensagens App" />
        
        <Container>
          {checarPermissao("MAP002") ? (
            <div className="form-container-msg">
              <button
                type="button"
                className="btnPadrao"
                onClick={() => {
                  abreNovaMensagem();
                }}
              >
                Nova Mensagem
              </button>
            </div>
          ) : null}

          {tamanho > 499 ? (
            <div className="table-container-msg">
              <table className="table-msg">
                <thead>
                  <tr>
                    <th align="center">Status</th>
                    <th align="center">Data</th>
                    <th align="center">Autorizador</th>
                    <th align="center">Criador</th>
                    <th align="center">Título</th>
                    <th align="center">Opções</th>
                  </tr>
                </thead>
                <tbody>
                  {mensagens.map(row => (
                    <tr key={row.id}>
                      <td align="center">
                        <VerifiedUser
                          style={{ color: corStatus(row.status_mensagens) }}
                        />
                      </td>
                      <td align="center">{row.data_hora}</td>
                      <td align="center">{row.autorizado_por}</td>
                      <td align="center">{row.criado_por}</td>
                      <td align="center">{row.titulo_mensagem}</td>
                      <td align="center">
                        <Tooltip title="Abrir opções">
                          <button
                            type="button"
                            className="btnOpcao"
                            onClick={() => {
                              setMensagem(row);
                              setOpcao(true);
                            }}
                          >
                            <i className="fa fa-cog" />
                          </button>
                        </Tooltip>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <div className="table-container-msg">
              {mensagens.map(row => (
                <Accordion
                  key={row.id}
                  expanded={expanded === `panel${row.id}`}
                  onChange={handleChange(`panel${row.id}`)}
                  style={{
                    borderRadius: "4px",
                    marginBottom: "6px",
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel1a-content"
                    id="container-cell-table"
                    style={{
                      borderRadius: "4px",
                      backgroundColor: corStatusMobile(row.status_mensagens),
                    }}
                  >
                    <div className="container-label-dialog-message-app">
                      <span id="label-dialog-tittle-message-app">
                        {row.titulo_mensagem}
                      </span>
                      <span className="label-dialog-message-app">{row.data_hora}</span>
                      <span
                        id="label-dialog-message-app-right"
                        className="label-dialog-message-app"
                      >
                        {row.status_mensagens}
                      </span>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div id="container-table-dialog-message-app">
                      <div>
                        <InputLabel id="label1-table-dialog-message-app">
                          Autorizado por
                        </InputLabel>
                      </div>

                      <div>
                        <InputLabel id="label2-table-dialog-message-app">
                          {row.autorizado_por}
                        </InputLabel>
                      </div>

                      <div>
                        <InputLabel id="label1-table-dialog-message-app">
                          Criado por
                        </InputLabel>
                      </div>

                      <div>
                        <InputLabel id="label2-table-dialog-message-app">
                          {row.criado_por}
                        </InputLabel>
                      </div>

                      <div id="container-button-accordeon-message-app">
                        <button
                          className="btn-message-app-check-default"
                          type="button"
                          onClick={() =>
                            opcaoMensagem(
                              "Visualizar",
                              row.id,
                              row.titulo_mensagem,
                              row.corpo_mensagem
                            )
                          }
                        >
                          Visualizar
                        </button>

                        {checarPermissao("MAP004") &&
                          row.status_mensagens !== "Autorizado" && (
                            <button
                              className="btn-message-app-check-default"
                              type="button"
                              onClick={() =>
                                abreOpcoes(
                                  row.id,
                                  row.identificador,
                                  row.titulo_mensagem,
                                  row.corpo_mensagem
                                )
                              }
                            >
                              Autorizar
                            </button>
                          )}

                        {checarPermissao("MAP003") &&
                          row.status_mensagens !== "Autorizado" && (
                            <button
                              className="btn-message-app-check-default"
                              type="button"
                              onClick={() =>
                                opcaoMensagem(
                                  "Editar",
                                  row.id,
                                  row.titulo_mensagem,
                                  row.corpo_mensagem
                                )
                              }
                            >
                              Editar
                            </button>
                          )}
                      </div>
                    </div>
                  </AccordionDetails>
                </Accordion>
              ))}
            </div>
          )}
        
        </Container>

      <Waiter espera={espera} />

      <Dialog
        fullWidth
        maxWidth="sm"
        open={novaMensagem}
        onClose={() => {
          fechaNovaMensagem();
        }}
      >
        <div id="header-information-client">
          <i id="icon-info-client" className="fa fa-envelope-open-o" aria-hidden="true" />
          <span>{tipoModal} mensagem</span>
          <button
            type="button"
            id="btn-close-info-client"
            onClick={() => fechaNovaMensagem()}
          >
            <Close id="icon-back-info-client" />
          </button>
        </div>
        <div className="grid-container-msg">
          <div className="grid-item-msg">
            {tipoModal !== "Visualizar" ? (
              <TextField
                fullWidth
                label="Título"
                value={titulo}
                onChange={e => {
                  defineTitulo(e);
                }}
              />
            ) : (
              <span id="content-message-title">{titulo}</span>
            )}
          </div>
          <div className="grid-item-msg">
            <InputLabel className="corpoMensagem">Mensagem</InputLabel>
            <TextareaAutosize
              id="text-area-message"
              disabled={tipoModal === "Visualizar"}
              aria-label="Mensagem"
              rowsMin={5}
              placeholder="Escreva a mensagem"
              value={corpo}
              onChange={e => {
                defineCorpo(e);
              }}
            />
          </div>
        </div>
        <DialogActions>
          {tipoModal === "Visualizar" ? null : (
            <>
              <button
                type="button"
                className="btnPadrao"
                onClick={() => {
                  if (tipoModal === "Criar nova") {
                    agendaPushNotification();
                  } else {
                    editaAgendaPushNotification();
                  }
                }}
              >
                Salvar
              </button>
              <button
                type="button"
                className="btnPadrao"
                onClick={() => {
                  fechaNovaMensagem();
                }}
              >
                Descartar
              </button>
            </>
          )}
        </DialogActions>
      </Dialog>

      <Dialog
        fullWidth
        maxWidth="sm"
        open={opcoes}
        onClose={() => {
          fechaOpcoes();
        }}
      >
        <div id="header-information-client">
          <i id="icon-info-client" className="fa fa-envelope-open-o" aria-hidden="true" />
          <span>Você autoriza o envio dessa mensagem?</span>
          <button type="button" id="btn-close-info-client" onClick={() => setOpcoes()}>
            <Close id="icon-back-info-client" />
          </button>
        </div>
        <div className="grid-container-msg">
          <div className="grid-item-msg">
            <InputLabel className="corpoMensagem">Título</InputLabel>
            <InputLabel id="txtCorpoMsg">{titulo}</InputLabel>
          </div>
          <div className="grid-item-msg">
            <InputLabel className="corpoMensagem">Mensagem</InputLabel>
            <InputLabel id="txtCorpoMsg">{corpo}</InputLabel>
          </div>
        </div>
        <DialogActions>
          <button
            type="button"
            id="btnGreen"
            className="btnPadrao"
            onClick={() => {
              atualizaAutorizacao("Autorizado");
            }}
          >
            Autorizar
          </button>
          <button
            type="button"
            id="btnRed"
            className="btnPadrao"
            onClick={() => {
              atualizaAutorizacao("Negado");
            }}
          >
            Negar
          </button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={opcao}
        onClose={() => fechaOpcao()}
        aria-labelledby="simple-dialog-title"
      >
        <DialogTitle id="selecionaOpcao">Selecione uma opção</DialogTitle>
        <List className="opList">
          <ListItemButton
            onClick={() => opcaoMensagem("Visualizar")}
            key="100"
          >
            <Message color="primary" />
            <InputLabel id="itemLista"> Visualizar</InputLabel>
          </ListItemButton>

          {checarPermissao("MAP004") && mensagem.status_mensagens !== "Autorizado" && (
            <ListItemButton onClick={() => abreOpcoes()} key="101">
              <Policy color="primary" />
              <InputLabel id="itemLista"> Autorizar</InputLabel>
            </ListItemButton>
          )}

          {checarPermissao("MAP003") && mensagem.status_mensagens !== "Autorizado" && (
            <ListItemButton onClick={() => opcaoMensagem("Editar")} key="101">
              <Create color="primary" />
              <InputLabel id="itemLista"> Editar</InputLabel>
            </ListItemButton>
          )}

          <ListItem button onClick={() => fechaOpcao()} key="103">
            <Replay />
            <InputLabel id="itemLista"> Voltar</InputLabel>
          </ListItem>
        </List>
      </Dialog>
      
    </>
  );
}
