import React, { createContext, useContext, useEffect, useState } from "react";
import { useSnackbar } from "notistack";

import formatDateBD from "../../../utils/formatarDataBD";
import { ColetaDomiciliarContext } from "./ColetaDomiciarContext";
import { FormularioColetaDomiciliarContext } from "./FormularioColetaDomiciliarContext";

import { api } from "../../../services/Api";

const FinanceiroContext = createContext();

function FinanceiroContextProvider({ children }) {
  const { enqueueSnackbar } = useSnackbar();

  const { novaData, dataFinal, coletador } = useContext(
    ColetaDomiciliarContext
  );

  const { tipo_cliente } = useContext(FormularioColetaDomiciliarContext);

  const [recebidos, setRecebidos] = useState([]);
  const [recebidosFiltrados, setRecebidosFiltrados] = useState([]);
  const [total, setTotal] = useState([]);
  const [aba, setAba] = useState("");
  const [updateTable, setUpdateTable] = useState(false);

  const updateTableData = () => {
    setUpdateTable(!updateTable);
  };

  useEffect(() => {
    async function iniciar() {
      try {
        const { data: resultado } = await api.get(
          `/api/coletaDomiciliar/financeiro/${formatDateBD(
            novaData
          )}/${formatDateBD(dataFinal)}`
        );

        setRecebidos(resultado);
        setRecebidosFiltrados(resultado);
      } catch (error) {
        enqueueSnackbar("Falha na operação", { variant: "error" });
      }
    }
    iniciar();
  }, [enqueueSnackbar, novaData, dataFinal, updateTable]);

  useEffect(() => {
    (async () => {
      const somaTotal = { valor: 0, qtd: 0 };
      const somaTotalPix = { valor: 0, qtd: 0 };
      const somaTotalDinheiro = { valor: 0, qtd: 0 };
      const somaTotalCartao = { valor: 0, qtd: 0 };

      recebidosFiltrados.forEach((v) => {
        if (v.tipo_pagamento === "Pix") {
          somaTotalPix.valor += Number(v.valor_recebido);
          somaTotalPix.qtd += 1;
        }
        if (v.tipo_pagamento === "Dinheiro") {
          somaTotalDinheiro.valor += Number(v.valor_recebido);
          somaTotalDinheiro.qtd += 1;
        }
        if (v.tipo_pagamento === "Cartão de crédito") {
          somaTotalCartao.valor += Number(v.valor_recebido);
          somaTotalCartao.qtd += 1;
        }

        somaTotal.valor += Number(v.valor_recebido);
        somaTotal.qtd += 1;

        return null;
      });

      setTotal({ somaTotal, somaTotalPix, somaTotalDinheiro, somaTotalCartao });
    })();
  }, [recebidosFiltrados]);

  useEffect(() => {
    if (recebidos) {
      const filtro = recebidos.filter(
        (p) =>
          (coletador ? p.coletador === coletador : true) &&
          (tipo_cliente ? p.tipo_cliente === tipo_cliente : true)
      );

      setRecebidosFiltrados(filtro);
    }
  }, [recebidos, coletador, tipo_cliente]);

  return (
    <FinanceiroContext.Provider
      value={{
        recebidosFiltrados,
        setRecebidosFiltrados,
        total,
        setTotal,
        aba,
        setAba,
        updateTableData,
      }}
    >
      {children}
    </FinanceiroContext.Provider>
  );
}

export { FinanceiroContext, FinanceiroContextProvider };
