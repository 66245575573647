import React, { memo } from "react";
import { Paper } from "@mui/material";
import Button from "../../../../componentsv2/Button";
import CardTotal from "../../Componentes/CardTotal";

import { useState, useContext } from "react";

import ModalAdicionarLote from "../ModalAdicionarLote";

import { Context as PermissoesContext } from "../../../../Context/PermissoesContext";

import "./styles.css";

function Totalizadores(props) {
  const { total, totalSoftlabFiltrado, loadingGeradoSoftlab } = props;

  const [modalNovoLote, setNovoLote] = useState(false);

  const handleOpenModal = () => setNovoLote(true);
  const handleCloseModal = () => setNovoLote(false);

  const { checarPermissao } = useContext(PermissoesContext);

  return (
    <Paper className="container_totalizadores">
      <CardTotal
        titulo="Gerado Softlab"
        valor={totalSoftlabFiltrado}
        loadingGeradoSoftlab={loadingGeradoSoftlab}
      />

      {checarPermissao("FAT012") ? (
        <Button color="blue" variant="outlined" onClick={handleOpenModal}>
          Adicionar Lote
        </Button>
      ) : (
        <></>
      )}

      <CardTotal titulo="Loteado" valor={total.somaTotal} />
      <CardTotal titulo="Enviado" valor={total.somaTotalEnviado} />

      {/* <CardTotal
        titulo="Recebido"
        valor={total.somaTotalRecebido}
      /> */}
      <CardTotal
        titulo="Não enviado"
        valor={
          parseInt(total.somaTotal, 10) - parseInt(total.somaTotalEnviado, 10)
        }
      />

      <ModalAdicionarLote open={modalNovoLote} handleClose={handleCloseModal} />
    </Paper>
  );
}

export default memo(Totalizadores);
