import React, { useContext, useState } from "react";
import {
  InputLabel,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";

import { ColetaDomiciliarContext } from "../../../Context/ColetaDomiciarContext";
import { ModaisColetaDomiciliarContext } from "../../../Context/ModaisColetaDomiciarContext";
import { FormularioColetaDomiciliarContext } from "../../../Context/FormularioColetaDomiciliarContext";

export default function TabelaMobile() {
  const { agendamentos } = useContext(ColetaDomiciliarContext);
  
  const {
    setValor_recebido,
    setCpf_cliente,
    setNome
   } = useContext(FormularioColetaDomiciliarContext);

  const {
    abreOpcoes,
    abrirMenuStatus,
    abrirDetalheCliente,
    abrirDetalheExame,
    setRecibo
  } = useContext(ModaisColetaDomiciliarContext);

  const [expanded, setExpanded] = useState(false);

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div>
      {agendamentos.map(row => (
        <Accordion
          key={row.id}
          expanded={expanded === `panel${row.id}`}
          onChange={handleChange(`panel${row.id}`)}
          style={{
                borderRadius: "4px",
                marginBottom: "6px",
              }}
        >
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="panel1a-content"
            id="container-cell-table"
            style={{
                  borderRadius: "4px",
                  backgroundColor: row.status === "Coletado" ? "#CEF6E3" : "#FFF",
                }}
          >
            <div id="container-label-dialog-coleta">
              <span id="label-dialog-tittle-coleta">
                {row.hora} - {row.coletador}
              </span>
              <span id="label-dialog-coleta">{row.nome}</span>
              <span id="label-dialog-coleta">{row.status}</span>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div>
              <div id="container-table-dialog-coleta">
                <div>
                  <InputLabel id="label1-table-dialog-coleta">
                    Cadastrado por
                  </InputLabel>
                </div>

                <div>
                  <InputLabel id="label2-table-dialog-coleta">
                    {row.codigo_usuario}
                  </InputLabel>
                </div>

                <div>
                  <InputLabel id="label1-table-dialog-coleta">Pedido</InputLabel>
                </div>

                <div>
                  <InputLabel id="label2-table-dialog-coleta">
                    {row.codigo_pedido}-{row.posto_pedido}
                  </InputLabel>
                </div>
              </div>

              <div id="container-button-accordeon-coleta">
                {row.pagou &&
                  <div className="button-accordeon-coleta">
                    <button
                      id="btn-coleta-check"
                      className="btn-coleta-check-default"
                      type="button"
                      onClick={() => {
                        setValor_recebido(row.valor_recebido);
                        setNome(row.nome);
                        setCpf_cliente(row.cpf);
                        setRecibo(true);
                      }}
                    >
                      Recibo
                    </button>
                  </div>}

                <div className="button-accordeon-coleta">
                  <button
                    id="btn-coleta-check"
                    className="btn-coleta-check-default"
                    type="button"
                    onClick={() => abrirDetalheCliente(row)}
                  >
                    Visualizar cliente
                  </button>
                </div>

                <div className="button-accordeon-coleta">
                  <button
                    id="btn-coleta-check"
                    className="btn-coleta-check-default"
                    type="button"
                    onClick={() => abrirDetalheExame(row)}
                  >
                    Visualizar exames
                  </button>
                </div>
                {row.status !== "Coletado" ? (
                  <div className="button-accordeon-coleta">
                    <button
                      id="btn-coleta-check"
                      className="btn-coleta-check-default"
                      type="button"
                      onClick={() => abrirMenuStatus(row, "Coletado")}
                    >
                      Marcar como coletado
                    </button>
                  </div>
                    ) : (
                      <div className="button-accordeon-coleta">
                        <button
                          id="btn-coleta-uncheck"
                          className="btn-coleta-check-default"
                          type="button"
                          onClick={() =>
                            abrirMenuStatus(
                              row,
                              row.codigo_pedido ? "Agendado" : "Pendente"
                            )}
                        >
                          Desmarcar como coletado
                        </button>
                      </div>
                    )}
                <div className="button-accordeon-coleta">
                  <button
                    id="btn-coleta-check"
                    className="btn-coleta-check-default"
                    type="button"
                    onClick={() => abreOpcoes(row)}
                  >
                    Opções
                  </button>
                </div>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
          ))}
    </div>
  )
}
