import React, { useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Button as MuiButton } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import GridItem from "../../../../componentsv2/GridItem";
import Grid from "../../../../componentsv2/GridContainer";
import Paper from "../../../../componentsv2/Paper";
import Header from "../../../../components/Header";
import Container from "../../../../componentsv2/Container";

export default function BI() {
  const history = useHistory();
  const location = useLocation();
  const { search } = location;
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const link = searchParams.get("link");

  return (
    <>
    <Header titulo="Central de BI's"/>
    <Container>
      <Grid>
        <GridItem xs={12} sm={12}>
          <Paper elevation={2}>
            <MuiButton onClick={() => {}}>
              <ArrowBackIcon
                style={{ color: "#003399" }}
                onClick={() =>
                  history.push({ pathname: "/centralDeBIs", state: { dados: 4 } })}
              />
            </MuiButton>
            <div style={{ height: 630, width: "100%" }}>
              {/* <iframe
                width="100%"
                height="620"
                title="BI"
                src={link}
                frameBorder="0"
                allowFullScreen
              /> */}
              <a href={link} target="_blank" rel="noopener noreferrer">Teste </a>
            </div>
          </Paper>
        </GridItem>
      </Grid>
    </Container>
  </>
  );
}
