import React from "react";
import { Paper, InputLabel, CircularProgress } from "@mui/material";
import { styled } from "@mui/system";

import formatoMoeda from "../../../../utils/formatoMoeda";

const PaperStyles = styled(Paper)(({ theme }) => ({
  height: "100%",
  background: "#F2F2F2",
  textAlign: "center",
  padding: "0.7rem",
  fontWeight: "bold",
  marginBottom: "0.3rem",
  cursor: "Pointer",
  "@media (max-width: 500px)": {
    width: "6rem",
    height: "100%",
  },
}));

const InputLabelTituloStyles = styled(InputLabel)(({ theme }) => ({
  fontSize: "0.8rem",
  cursor: "Pointer",
  "@media (max-width: 500px)": {
    fontSize: "0.5rem",
  },
}));

const InputLabelValorStyles = styled(InputLabel)(({ theme }) => ({
  color: "black",
  fontSize: "0.9rem",
  fontWeight: "bold",
  cursor: "Pointer",
  "@media (max-width: 500px)": {
    fontSize: "0.7rem",
    fontWeight: "bold",
  },
}));

export default function CardTotal(props) {
  const { titulo, valor, aba, loadingGeradoSoftlab } = props;
  const listaLaranja = [
    "Glosado",
    "Em recurso",
    "Valor recebido por recurso",
    "Glosa Acatada",
    "Glosas Totais",
    "Diferença de tabela",
  ];
  const ativado = aba === titulo ? true : null;
  return (
    <PaperStyles
      style={
        ativado && listaLaranja.includes(titulo)
          ? {
              color: "#FFF",
              backgroundColor: "#FF4500",
            }
          : !ativado && listaLaranja.includes(titulo)
          ? {
              color: "#FFF",
              backgroundColor: "#E66C37",
            }
          : !ativado && !listaLaranja.includes(titulo)
          ? {
              color: "black",
              backgroundColor: "#FFF",
            }
          : {
              color: "#FFF",
              backgroundColor: "#003399",
            }
      }
    >
      {listaLaranja.includes(titulo) ? (
        <>
          <InputLabelTituloStyles style={{ color: "#FFF" }}>
            {titulo}
          </InputLabelTituloStyles>
          <InputLabelValorStyles style={{ color: "#FFF" }}>
            {valor ? formatoMoeda(valor, true) : "0,00"}
          </InputLabelValorStyles>
        </>
      ) : (
        <>
          <InputLabelTituloStyles style={ativado && { color: "#FFF" }}>
            {titulo}
          </InputLabelTituloStyles>

          {titulo === "Gerado Softlab" && loadingGeradoSoftlab ? (
            <CircularProgress size={15} />
          ) : (
            <InputLabelValorStyles style={ativado && { color: "#FFF" }}>
              {valor ? formatoMoeda(valor, true) : "0,00"}
            </InputLabelValorStyles>
          )}
        </>
      )}
    </PaperStyles>
  );
}
