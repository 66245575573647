import React from "react";

import "./styles.css";

import { Paper } from "@mui/material";

import Config from "./Options";
import Tabela from "../../../componentsv2/Tabela";

export default function TabelaExibeUsuario({ rows }) {
  const columns = [
    {
      field: "id",
      headerName: "ID",
      headerAlign: "center",
    },
    {
      field: "nome_usu",
      headerName: "Nome",
      width: 400,
      headerAlign: "center",
    },
    {
      field: "cod_usu",
      headerName: "Usuário",
      width: 200,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "email_usu",
      headerName: "E-mail",
      width: 300,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "opcoes",
      headerName: "Opções",
      headerAlign: "center",
      flex: 1,
      type: "number",
      editable: false,
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (params) => (
        <div style={{ cursor: "pointer" }}>
          <Config row={params.row} />
        </div>
      ),
    },
  ];

  return (
    <Paper elevation={2}>
      <div style={{ height: 600, width: "100%" }}>
        <Tabela
          initialState={{
            columns: {
              columnVisibilityModel: {
                id: false,
              },
            },
          }}
          rows={rows}
          columns={columns}
          rowsPerPage={[10]}
          disableColumnMenu
        />
      </div>
    </Paper>
  );
}
