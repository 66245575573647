import React, { useContext } from "react";
import { IconButton } from "@mui/material";
import { format } from "date-fns";

import { ModalRecebimentoContext } from "../../Context/ModalRecebimentoContext";
import { RecebimentoContext } from "../../Context/RecebimentoContext";
import formatoMoeda from "../../../../../utils/formatoMoeda";

import Waiter from "../../../../../components/Backdrop";
import Tabela from "../../../../../componentsv2/Tabela";
import { RetornoLoteContext } from "../../Context/RetornoLoteContext";
import { enqueueSnackbar } from "notistack";

export default function TabelaRetornoLote() {
  const {
    setId_retorno_lote,
    setOpcoesGerenciaLote,
    loading,
    setDadosRetornoLoteRow,
  } = useContext(ModalRecebimentoContext);
  const { setNota_fiscal, setData_recebimento } =
    useContext(RetornoLoteContext);
  const { retornoLote } = useContext(RecebimentoContext);
  const handleEditClick = (row) => {
    setId_retorno_lote(row.id);
    setDadosRetornoLoteRow(row);
    // setNota_fiscal(row.nota_fiscal);
    // setData_recebimento(row.data_recebimento);
    setOpcoesGerenciaLote(true);
  };
  // Botão de configuração da tabela de gerenciamento guia (visão lote)
  const ConfigGuia = ({ row }) => {
    return (
      <IconButton
        onClick={() => {
          handleEditClick(row);
        }}
      >
        <i
          className="fa fa-cog"
          style={{
            display: "flex",
            justifyContent: "center",
            color: "#003399",
          }}
        />
      </IconButton>
    );
  };

  // Colunas da tabela de Gereciamento de lote (visão lote)
  const colunas_GerenciaLote = [
    {
      field: "id",
      headerName: "ID",
      headerAlign: "center",
      flex: 1,
      hide: true,
    },
    {
      field: "opcoes",
      headerName: "Opções",
      headerAlign: "center",
      width: 130,
      type: "number",

      renderCell: (params) => (
        <div
          style={{
            cursor: "pointer",
            display: "flex",
            width: "100%",
            justifyContent: "center",
          }}
        >
          <ConfigGuia row={params.row} />
        </div>
      ),
    },
    {
      field: "data_recebimento",
      headerName: "Data Pagamento",
      headerAlign: "center",
      width: 130,
      editable: false,
      valueFormatter: ({ value }) =>
        value ? `${format(new Date(value), "dd/MM/yyyy")}` : null,
    },
    {
      field: "nota_fiscal",
      headerName: "Nota Fiscal",
      headerAlign: "center",
      flex: 1,
      minWidth: 120,
      editable: false,
    },

    {
      field: "protocolo",
      headerName: "Protocolo",
      headerAlign: "center",
      flex: 1,
      minWidth: 120,
      hide: true,
    },
    {
      field: "valor_recuperado",
      headerName: "Faturado",
      type: "number",
      headerAlign: "center",
      flex: 1,
      minWidth: 120,
      valueFormatter: ({ value }) => {
        const valor = value ? formatoMoeda(value, true) : "0,00";
        return valor;
      },
    },
    {
      field: "valor_imposto",
      headerName: "Impostos",
      type: "number",
      headerAlign: "center",
      flex: 1,
      minWidth: 120,
      valueFormatter: ({ value }) => {
        const valor = value ? formatoMoeda(value, true) : "0,00";
        return valor;
      },
      valueGetter: (params) => {
        const valorDescontoUnimed =
          params.row.valor_recuperado * (1 - params.row.desconto_unimed / 100);
        const impost =
          valorDescontoUnimed * (1 - (1 - params.row.imposto / 100));
        return impost;
      },
    },
    {
      field: "valor_desconto_unimed",
      headerName: "Desc. Unimed",
      type: "number",
      headerAlign: "center",
      flex: 1,
      minWidth: 120,
      valueFormatter: ({ value }) => {
        const valor = value ? formatoMoeda(value, true) : "0,00";
        return valor;
      },
      valueGetter: (params) => {
        const valorDescontoUnimed =
          params.row.valor_recuperado *
          (1 - (1 - params.row.desconto_unimed / 100));
        return valorDescontoUnimed;
      },
    },
    {
      field: "valor_liquido",
      headerName: "Líquido",
      type: "number",
      headerAlign: "center",
      flex: 1,
      minWidth: 120,
      valueFormatter: ({ value }) => {
        const valor = value ? formatoMoeda(value, true) : "0,00";
        return valor;
      },
    },
    {
      field: "valor_recebido",
      headerName: "Valor Recebido",
      type: "number",
      headerAlign: "center",
      flex: 1,
      minWidth: 120,
      valueFormatter: ({ value }) => {
        const valor = value ? formatoMoeda(value, true) : "0,00";
        return valor;
      },
    },
    {
      field: "valor_glosado",
      headerName: "Glosado",
      type: "number",
      headerAlign: "center",
      flex: 1,
      minWidth: 120,
      valueFormatter: ({ value }) => {
        const valor = value ? formatoMoeda(value, true) : "0,00";
        return valor;
      },
    },
    {
      field: "valor_acatado",
      headerName: "Acatado",
      type: "number",
      headerAlign: "center",
      flex: 1,
      minWidth: 120,
      valueFormatter: ({ value }) => {
        const valor = value ? formatoMoeda(value, true) : "0,00";
        return valor;
      },
    },
    {
      field: "em_recurso",
      headerName: "Em recurso",
      type: "number",
      headerAlign: "center",
      flex: 1,
      minWidth: 120,
      valueFormatter: ({ value }) => {
        const valor = value ? formatoMoeda(value, true) : "0,00";
        return valor;
      },
    },
    {
      field: "valor_diferenca_tabela",
      headerName: "Diferença de Tabelas",
      type: "number",
      headerAlign: "center",
      flex: 1,
      minWidth: 120,
      valueFormatter: ({ value }) => {
        const valor = value ? formatoMoeda(value, true) : "0,00";
        return valor;
      },
    },
    {
      field: "valor_diferenca_unimed",
      headerName: "Diferença Unimed",
      type: "number",
      headerAlign: "center",
      flex: 1,
      minWidth: 120,
      valueFormatter: ({ value }) => {
        const valor = value ? formatoMoeda(value, true) : "0,00";
        return valor;
      },
    },
  ];

  return (
    <>
      <Tabela
        columns={colunas_GerenciaLote}
        rows={retornoLote}
        pageSize={10}
        rowsPerPageOptions={[10, 20]}
        onRowDoubleClick={(params) => {
          handleEditClick(params.row);
        }}
        initialState={{
          columns: {
            columnVisibilityModel: {
              id: false,
            },
          },
        }}
      />
      <Waiter espera={loading} />
    </>
  );
}
