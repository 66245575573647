export default function CapturaBrowse() {

    const isOpera = !!window.opr  || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;
    // const isOpera = (!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;
    // const isOpera = !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;

    const isFirefox = typeof InstallTrigger !== 'undefined';   // Firefox 1.0+

    const isSafari = Object.prototype.toString.call(window.HTMLElement).indexOf('Constructor') > 0;

    const isChrome = !! window.chrome && !isOpera;              // Chrome 1+

    const isIE = /* @cc_on!@ */false || !!document.documentMode;   // At least IE6

    const isIEDGE = !isIE && !!window.StyleMedia;

    // Blink engine detection
    // const blink = (isChrome || isOpera) && !!window.CSS;

    if (isOpera) {
        return 'Opera';
    }

    if (isFirefox) {
        return 'Firefox';
    }

    if (isIE) {
        return 'IE';
    }

    if (isIEDGE) {
        return 'IEDGE';
    }

    if (isChrome) {
        return 'Chrome';
    }

    if (isSafari) {
        return 'Safari';
    }

    return 'Outro';

}
