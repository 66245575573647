import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import { Autocomplete, TextField } from "@mui/material";

import Button from "../Button";

import { api } from "../../services/Api";

export default function AutoCompleteMedicos({
  label,
  value,
  onChange,
  required = false,
  ...rest
}) {
  const [medicos, setMedicos] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  useEffect(() => {
    async function pegarMedico() {
      try {
        const { data: { data  } } = await api.get("/v2/agendaCare/medicos_care");

        setMedicos(data.sort((a, b) => a.nome.localeCompare(b.nome)));
      } catch (error) {
        enqueueSnackbar("Erro! Não foi possível buscar os Médicos.", {
          variant: "error",
        });
      }
    }
    pegarMedico();
  }, [enqueueSnackbar]);

  return (
    <>
      <Autocomplete
        {...rest}
        noOptionsText={
          <Button
            onClick={() => {
              history.push({
                pathname: "/agendaCare/CadastrarMedico",
                state: { data: "care" },
              });
            }}
          >
            Cadastrar novo Médico
          </Button>
        }
        value={value}
        onChange={(event, value) => onChange(value)}
        disablePortal
        id="combo-box-demo"
        options={medicos || []}
        getOptionLabel={(option) => `${option.nome} - ${option.crm}/${option.estado}`}
        fullWidth
        renderInput={(params) => (
          <TextField
            required={required}
            variant="standard"
            {...params}
            label="Medicos"
          />
        )}
      />
    </>
  );
}
