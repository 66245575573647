import React from "react";
import { Paper } from '@mui/material';

import "./styles.css";

// o icone pode ser tanto uma imagem quanto classes font awesome.
export default function AppPopoverButton({
  nome,
  imgSrc,
  imgAlt,
  iconClassName,
  onClick,
  href,
}) {
  return (
    <>
      {href ? (
        <Paper elevation={3}>
          <a
            href={href}
            style={{ textDecoration: "none" }}
            rel="noopener noreferrer"
            target="_blank"
          >
            <div className="app-popover-button" onClick={onClick}>
              {iconClassName && <div className={`${iconClassName} icon`} />}
              {imgSrc && <img src={imgSrc} alt={imgAlt} className="icon" />}
              <span>{nome}</span>
            </div>
          </a>
        </Paper>
      ) : (
        <Paper elevation={3}>
          <div className="app-popover-button" onClick={onClick}>
            {iconClassName && <div className={`${iconClassName} icon`} />}
            {imgSrc && <img src={imgSrc} alt={imgAlt} className="icon" />}
            <span>{nome}</span>
          </div>
        </Paper>
      )}
    </>
  );
}
