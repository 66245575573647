import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableFooter,
  TablePagination,
} from "@mui/material";

import "./styles.css";

export default function Tabela({
  header,
  children,
  count,
  page,
  setPage,
  containerStyles,
  rowsPerPage,
}) {
  return (
    <TableContainer component={Paper} style={containerStyles}>
      <Table>
        <TableHead>
          <TableRow>
            {header.map((h) => (
              <TableCell align="center" key={h}>
                {h}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody className="my-table-body">{children}</TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[]}
              rowsPerPage={rowsPerPage || 9}
              count={count}
              page={page}
              labelDisplayedRows={(paginationInfo) => {
                const { count: total, page: paginaAtual } = paginationInfo;
                return `Página ${
                  total > 0 ? paginaAtual + 1 : 0
                } de ${Math.ceil(total / (rowsPerPage || 9))} `;
              }}
              onPageChange={(e, newPage) => setPage(newPage)}
              sx={{
                ".MuiTablePagination-displayedRows": {
                  color: "black",
                },
              }}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}
