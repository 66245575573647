import React from "react";
import { ModalForm } from "../../../../componentsv2/ModalForm";
import GridItem from "../../../../componentsv2/GridItem";
import DataPickerAtualizado from "../../../../components/DataPickerAtualizado";
import SelectEnfermeiro from "../SelectEnfermeiro";
import SelectHorariosDisponiveis from "../SelectHorariosDisponiveis";
import { useState } from "react";
import { api } from "../../../../services/Api";
import { useSnackbar } from "notistack";

export default function ModalReagendar({ open, onClose, row }) {
  const [data, setData] = useState(new Date());
  const [hora, setHora] = useState("");
  const [enfermeiroId, setEnfermeiroId] = useState("");

  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Quando o datePicker é utilizado, muda state data para um objeto com multiplas props
      if (data.hasOwnProperty("$d")) {
        setData(data.$d.setHours(0, 0, 0));
      } else {
        setData(data.setHours(0, 0, 0));
      }

      const dados = {
        data: data,
        hora,
        enfermeiroId,
      };
      await api.put(`v2/agendaCare/agendamento/${row.id}`, dados);
      onClose();
      enqueueSnackbar("Reagendamento feito com sucesso!", {
        variant: "success",
      });
    } catch (error) {
      enqueueSnackbar("Erro ao tentar reagendar!", {
        variant: "error",
      });
    }
  };

  return (
    <ModalForm
      open={open}
      onClose={onClose}
      title="Reagendar"
      onSubmit={handleSubmit}
    >
      <GridItem xs={12}>
        <DataPickerAtualizado
          variant={"standard"}
          labelName="Data"
          required
          data={data}
          setData={setData}
        />
      </GridItem>

      <GridItem xs={6}>
        <SelectEnfermeiro
          onChange={(e) => {
            setEnfermeiroId(e.target.value);
            setHora("");
          }}
          required
        />
      </GridItem>

      <GridItem xs={6}>
        <SelectHorariosDisponiveis
          onChange={(e) => {
            setHora(e.target.value);
          }}
          data={data}
          value={hora}
          enfermeiroId={enfermeiroId}
          required
        />
      </GridItem>
    </ModalForm>
  );
}
