import React, { useContext, useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Box,
  IconButton,
  Typography,
} from "@mui/material";
import { Document, pdfjs, Page } from "react-pdf";

import Button from "../../../componentsv2/Button";

import "./styles.css";
import ModalConfirmarAprovacao from "./ModalConfirmarAprovacao";
import { api } from "../../../services/Api";
import { enqueueSnackbar } from "notistack";
import DadosContextContrato from "./DadosContextContrato";
import ModalConfirmarNaoAprovacao from "./ModalConfirmarNaoAprovacao";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import { Close } from "@mui/icons-material";
import VisualizarObservacao from "./VisualizarObservacao";
import ChatIcon from "@mui/icons-material/Chat";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import RotateRightIcon from "@mui/icons-material/RotateRight";
import GridContainer from "../../../componentsv2/GridContainer";
import GridItem from "../../../componentsv2/GridItem";
import SalvaLog from "../../../utils/SalvaLog";
import { PDFDocument, degrees } from "pdf-lib";
import { Context as PermissoesContext } from "../../../Context/PermissoesContext";
import ModalConfirmarConferirDocumento from "./ModalConfirmarConferirDocumento";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default function ModalAnexo({
  situacaoAtual,
  open,
  onClose,
  title,
  anexos,
  onCancel,
  idContrato,
  observacaoAtual,
}) {
  const { update, setUpdate } = useContext(DadosContextContrato);
  const [observacao, setObservacao] = useState(
    observacaoAtual ? observacaoAtual : ""
  );
  const [openModalConfirmacao, setOpenModalConfirmacao] = useState(false);
  const [openModalDevolucao, setOpenModalDevolucao] = useState(false);
  const [openModalVisualizarObservacao, setOpenModalVisualizarObservacao] =
    useState(false);

  const [anexoAtual, setAnexoAtual] = useState("");

  const [numPages, setNumPages] = useState(null);
  const [tamanho, setTamanho] = useState("");
  const { checarPermissao } = useContext(PermissoesContext);
  const [
    openModalConfirmarConferirDocumento,
    setOpenModalConfirmarConferirDocumento,
  ] = useState(false);
  const [pageRotations, setPageRotations] = useState({});

  useEffect(() => {
    if (!anexos) return;
    setAnexoAtual(anexos[0]);
  }, [anexos]);

  function sizeOfScreen() {
    setTamanho(window.innerWidth);
  }

  useEffect(() => {
    sizeOfScreen();
  }, []);

  window.addEventListener("resize", () => {
    sizeOfScreen();
  });

  const conferirDocumento = async (e) => {
    e.preventDefault();
    try {
      const data = {
        status: "Conferido",
      };

      const detalhe = JSON.stringify({ ...data, idContrato });

      await api.put(`v2/gestaoContratos/contrato/${idContrato}`, data);
      setOpenModalConfirmarConferirDocumento(false);
      onClose(false);
      setUpdate(!update);

      SalvaLog("Conferiu um documento", "Gestão de Contratos", detalhe);

      //await rotacionarDocumentos();

      enqueueSnackbar("Documento conferido com sucesso!", {
        variant: "success",
        autoHideDuration: 3000,
      });
    } catch (error) {
      enqueueSnackbar("Não foi possível conferir o documento!", {
        variant: "error",
        autoHideDuration: 3000,
      });
      console.log(error);
    }
  };

  const aprovarDocumento = async (e) => {
    e.preventDefault();
    try {
      const data = {
        status: "Autorizado",
        observacao,
      };

      const detalhe = JSON.stringify({ ...data, idContrato });

      await api.put(`v2/gestaoContratos/contrato/${idContrato}`, data);
      onClose(false);
      setUpdate(!update);
      setOpenModalConfirmacao(false);
      setObservacao("");

      SalvaLog("Aprovou um documento", "Gestão de Contratos", detalhe);
      //await rotacionarDocumentos();
      enqueueSnackbar("Documento aprovado com sucesso!", {
        variant: "success",
        autoHideDuration: 3000,
      });
    } catch (error) {
      enqueueSnackbar("Não foi possível aprovar o documento!", {
        variant: "error",
        autoHideDuration: 3000,
      });
      console.log(error);
    }
  };

  const recusarDocumento = async (e) => {
    e.preventDefault();
    try {
      const data = {
        status: "Não Autorizado",
        observacao,
      };
      const detalhe = JSON.stringify({ ...data, idContrato });
      await api.put(`v2/gestaoContratos/contrato/${idContrato}`, data);

      SalvaLog("Reprovou um documento", "Gestão de Contratos", detalhe);

      onClose(false);
      setUpdate(!update);
      setOpenModalDevolucao(false);
      setObservacao("");
      // await rotacionarDocumentos();
      enqueueSnackbar("Documento recusado com sucesso!", {
        variant: "success",
        autoHideDuration: 3000,
      });
    } catch (error) {
      enqueueSnackbar("Não foi possível recusar o documento!", {
        variant: "error",
        autoHideDuration: 3000,
      });
      console.log(error);
    }
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const rotatePage = (pageNumber, degrees) => {
    setPageRotations((prevPageRotations) => ({
      ...prevPageRotations,
      [`${anexoAtual}-{${pageNumber}}`]:
        (prevPageRotations[`${anexoAtual}-{${pageNumber}}`] || 0) + degrees,
    }));
  };

  const fetchUrlAsBlob = async (url) => {
    const response = await fetch(url);
    const blob = await response.blob();
    return blob;
  };

  const createFileFromBlob = (blob, filename) => {
    const file = new File([blob], filename, { type: blob.type });
    return file;
  };

  const downloadUrlAsFile = async (url, filename) => {
    const blob = await fetchUrlAsBlob(url);
    const file = createFileFromBlob(blob, filename);
    return file;
  };

  const rotacionarDocumentos = async () => {
    try {
      const pdfDocs = {};
      const arrayArquivosRotacionados = [];

      Object.entries(pageRotations).forEach(async ([page, rotation]) => {
        const numeroPaginaRegex = /-(\{\d+\})/;
        const numeroPaginaMatch = page.match(numeroPaginaRegex);

        const numeroPagina = numeroPaginaMatch
          ? parseInt(numeroPaginaMatch[1].replace(/\{|\}/g, ""))
          : null;

        const novaString = page.replace(numeroPaginaRegex, "");
        const filename = novaString
          .substring(novaString.lastIndexOf("/") + 1)
          .split("?token=")[0];
        if (!pdfDocs[filename]) {
          pdfDocs[filename] = {
            novaString,
            pages: [],
          };
        }

        pdfDocs[filename].pages.push({ novaString, rotation, numeroPagina });
      });

      for (const [filename, { novaString, pages }] of Object.entries(pdfDocs)) {
        const existingPdfBytes = await fetch(novaString);

        const buffer = await existingPdfBytes.arrayBuffer();

        const pdfDoc = await PDFDocument.load(buffer);

        pages.forEach(({ rotation, numeroPagina }) => {
          pdfDoc.getPages().forEach((page, index) => {
            if (index + 1 === numeroPagina) {
              page.setRotation(degrees(rotation));
            }
          });
        });

        const pdfBytes = await pdfDoc.save();
        const blob = new File([pdfBytes], filename, {
          type: "application/pdf",
        });

        arrayArquivosRotacionados.push(blob);
      }

      const anexosUtilizados = Object.keys(pageRotations).map((page) => {
        const numeroPaginaRegex = /-(\{\d+\})/;
        const novaString = page.replace(numeroPaginaRegex, "");
        return novaString;
      });

      const naoUtilizados = anexos.filter(
        (anexo) => !anexosUtilizados.includes(anexo)
      );

      const result = naoUtilizados?.map(async (anexo) => {
        const nomeFile = anexo
          .substring(anexo.lastIndexOf("/") + 1)
          .split("?token=")[0];
        return await downloadUrlAsFile(anexo, nomeFile);
      });
      const filesNaoUtilizados = await Promise.all(result);

      const formAnexo = new FormData();

      const arrayFinal = [...arrayArquivosRotacionados, ...filesNaoUtilizados];

      arrayFinal?.forEach((anexo) => {
        formAnexo.append("anexos", anexo, anexo.name);
      });

      await api.put(
        `v2/gestaoContratos/contratoAnexo/${idContrato}`,
        formAnexo
      );
    } catch (error) {
      console.log(error);
    }
  };

  // useEffect(() => {
  //   rotacionarDocumentos();
  // }, [pageRotations, anexos]);

  return (
    <React.Fragment>
      <ModalConfirmarConferirDocumento
        onClose={setOpenModalConfirmarConferirDocumento}
        onSubmit={conferirDocumento}
        open={openModalConfirmarConferirDocumento}
      />
      <ModalConfirmarAprovacao
        setObservacao={setObservacao}
        observacao={observacao}
        open={openModalConfirmacao}
        onClose={setOpenModalConfirmacao}
        onSubmit={aprovarDocumento}
      />
      <ModalConfirmarNaoAprovacao
        setObservacao={setObservacao}
        observacao={observacao}
        open={openModalDevolucao}
        onClose={setOpenModalDevolucao}
        onSubmit={recusarDocumento}
      />
      <VisualizarObservacao
        observacaoAtual={observacaoAtual}
        open={openModalVisualizarObservacao}
        onClose={setOpenModalVisualizarObservacao}
      />
      <Dialog open={open} fullWidth fullScreen onClose={() => onClose(false)}>
        <DialogTitle
          style={{
            margin: 0,
            padding: 5,
            justifyContent: "space-between",
            display: "flex",
            width: "100%",
          }}
        >
          {observacaoAtual === "" ? null : (
            <IconButton onClick={() => setOpenModalVisualizarObservacao(true)}>
              <ChatIcon color="primary" fontSize="large" />
            </IconButton>
          )}
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr 1fr",
              placeContent: "center",
              overflow: "auto",
              width: "100%",
              gap: "10px",
            }}
          >
            {anexos?.map((anexo, index) => (
              <Button
                key={index}
                fullWidth
                color={anexoAtual === anexo ? "blue" : "gray"}
                onClick={() => setAnexoAtual(anexo)}
                variant="contained"
              >
                Anexo {index + 1}
              </Button>
            ))}
          </div>
          <IconButton onClick={() => onClose(false)}>
            <Close fontSize="large" />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {tamanho > 499 ? (
              <iframe src={anexoAtual} width="100%" height={750} />
            ) : (
              // <Document
              //   file={anexoAtual}
              //   onLoadSuccess={({ numPages }) => setNumPages(numPages)}
              // >
              //   {Array.apply(null, Array(numPages))
              //     .map((x, i) => i + 1)
              //     .map((page, index) => (
              //       <Box key={index} sx={{ position: "relative" }}>
              //         <GridContainer
              //           spacing={2}
              //           sx={{
              //             justifyContent: "center",
              //             position: "sticky",
              //             placeItems: "center",
              //             zIndex: 1000,
              //             top: 0,
              //           }}
              //         >
              //           <GridItem>
              //             <IconButton
              //               sx={{
              //                 backgroundColor: "#003399",
              //                 ":hover": {
              //                   backgroundColor: "#003399",
              //                 },
              //               }}
              //               color="primary"
              //               onClick={() => rotatePage(page, -90)}
              //             >
              //               <RotateLeftIcon sx={{ color: "white" }} />
              //             </IconButton>
              //           </GridItem>
              //           <GridItem>
              //             <Typography
              //               variant="body1"
              //               color="black"
              //               fontWeight={600}
              //             >
              //               Página {index + 1}
              //             </Typography>
              //           </GridItem>
              //           <GridItem>
              //             <IconButton
              //               color="primary"
              //               sx={{
              //                 backgroundColor: "#003399",
              //                 ":hover": { backgroundColor: "#003399" },
              //               }}
              //               onClick={() => rotatePage(page, 90)}
              //             >
              //               <RotateRightIcon sx={{ color: "white" }} />
              //             </IconButton>
              //           </GridItem>
              //         </GridContainer>

              //         <Page
              //           pageNumber={page}
              //           scale={2}
              //           rotate={pageRotations[`${anexoAtual}-{${page}}`] || 0}
              //         />
              //       </Box>
              //     ))}
              // </Document>
              <Document
                file={anexoAtual}
                onLoadSuccess={({ numPages }) => setNumPages(numPages)}
              >
                {Array.apply(null, Array(numPages))
                  .map((x, i) => i + 1)
                  .map((page, index) => (
                    <Box key={index}>
                      <GridContainer
                        spacing={2}
                        sx={{
                          justifyContent: "center",
                          position: "sticky",
                          placeItems: "center",
                          zIndex: 1000,
                          top: 0,
                        }}
                      >
                        <GridItem>
                          <IconButton
                            size="small"
                            sx={{
                              backgroundColor: "#003399",
                              ":hover": {
                                backgroundColor: "#003399",
                              },
                            }}
                            color="primary"
                            onClick={() => rotatePage(page, -90)}
                          >
                            <RotateLeftIcon sx={{ color: "white" }} />
                          </IconButton>
                        </GridItem>
                        <GridItem>
                          <Typography
                            variant="body1"
                            color="black"
                            fontWeight={600}
                          >
                            Página {index + 1}
                          </Typography>
                        </GridItem>
                        <GridItem>
                          <IconButton
                            size="small"
                            color="primary"
                            sx={{
                              backgroundColor: "#003399",
                              ":hover": { backgroundColor: "#003399" },
                            }}
                            onClick={() => rotatePage(page, 90)}
                          >
                            <RotateRightIcon sx={{ color: "white" }} />
                          </IconButton>
                        </GridItem>
                      </GridContainer>

                      <Page
                        pageNumber={page}
                        scale={1}
                        rotate={pageRotations[`${anexoAtual}-{${page}}`] || 0}
                      />
                    </Box>
                  ))}
              </Document>
            )}

            {/* <Document
              onLoadError={(e) => console.log(e)}
              style={{ width: "100%" }}
              file="https://www.clickdimensions.com/links/TestPDFfile.pdf"
            /> */}
            {/* <iframe src={`${anexo}`} width="100%" height="900px" /> */}
          </DialogContentText>
        </DialogContent>

        {situacaoAtual === "Pendente" ? (
          <Box
            sx={{
              display: "flex",
              margin: "20px",
              justifyContent: "space-between",
              marginLeft: "15px",
              marginRight: "15px",
            }}
          >
            <Button
              variant="outlined"
              onClick={() => {
                onClose(false);
              }}
            >
              Cancelar
            </Button>

            <Button
              variant="contained"
              color="green"
              onClick={() => {
                if (!checarPermissao("GEC012")) {
                  enqueueSnackbar(
                    "Você não possui permissão para conferir o documento!",
                    {
                      variant: "error",
                      autoHideDuration: 3000,
                    }
                  );
                  return;
                }

                setOpenModalConfirmarConferirDocumento(true);
              }}
            >
              Conferir
            </Button>
          </Box>
        ) : null}
        {situacaoAtual === "Conferido" ? (
          <Box
            sx={{
              display: "flex",
              margin: "20px",
              justifyContent: "space-between",
              marginLeft: "15px",
              marginRight: "15px",
            }}
          >
            <Button
              variant="contained"
              color="red"
              onClick={() => {
                if (!checarPermissao("GEC011")) {
                  enqueueSnackbar(
                    "Você não possui permissão para reprovar o documento!",
                    {
                      variant: "error",
                      autoHideDuration: 3000,
                    }
                  );
                  return;
                }
                setOpenModalDevolucao(true);
              }}
            >
              Não aprovar
            </Button>
            <Button
              variant="outlined"
              onClick={() => {
                onClose(false);
              }}
            >
              Voltar
            </Button>
            <Button
              variant="contained"
              color="green"
              onClick={() => {
                if (!checarPermissao("GEC010")) {
                  enqueueSnackbar(
                    "Você não possui permissão para autorizar o documento!",
                    {
                      variant: "error",
                      autoHideDuration: 3000,
                    }
                  );
                  return;
                }

                setOpenModalConfirmacao(true);
              }}
            >
              Aprovar
            </Button>
          </Box>
        ) : null}
      </Dialog>
    </React.Fragment>
  );
}
