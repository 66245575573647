import React, { useContext, useState, useEffect, useCallback } from "react";
import { useSnackbar } from "notistack";
import { Card, CardContent, Grid, Paper, Typography } from "@mui/material";
import { Bar } from "react-chartjs-2";
import "chartjs-plugin-datalabels";

import { ColetaDomiciliarContext } from "../../Context/ColetaDomiciarContext";
import formatDateBD from "../../../../utils/formatarDataBD";

import { api } from "../../../../services/Api";
import Chart from "chart.js/auto";
import { CategoryScale } from "chart.js";
import "./styles.css";
Chart.register(CategoryScale);

export default function BI() {
  const { enqueueSnackbar } = useSnackbar();

  const { novaData, dataFinal } = useContext(ColetaDomiciliarContext);

  const [qtdColetas, setQtdColetas] = useState(0);

  const [coletaColetador, setColetaColetador] = useState([]);
  const [qtdColetaColetador, setQtdColetaColetador] = useState([]);

  const [coletaStatus, setColetaStatus] = useState([]);
  const [qtdColetaStatus, setQtdColetaStatus] = useState([]);

  let vetor_coletador = [];
  let vetor_qtdcoletador = [];

  let vetor_status = [];
  let vetor_qtdstatus = [];

  const capturaColetaColetador = useCallback((coleta) => {
    // eslint-disable-next-line
    vetor_coletador = [];
    // eslint-disable-next-line
    vetor_qtdcoletador = [];

    coleta.forEach((c) => {
      vetor_coletador.push(c.coletador);
      vetor_qtdcoletador.push(c.qtd_por_coletador);
    });

    setColetaColetador(vetor_coletador);
    setQtdColetaColetador(vetor_qtdcoletador);
    // eslint-disable-next-line
  }, []);

  const capturaColetaSituacao = useCallback((situacao) => {
    // eslint-disable-next-line
    vetor_status = [];
    // eslint-disable-next-line
    vetor_qtdstatus = [];

    situacao.forEach((s) => {
      vetor_status.push(s.status);
      vetor_qtdstatus.push(s.qtd_por_status);
    });

    setColetaStatus(vetor_status);
    setQtdColetaStatus(vetor_qtdstatus);
    // eslint-disable-next-line
  }, []);

  function zeraDados() {
    setQtdColetas(0);
    setColetaColetador([]);
    setQtdColetaColetador([]);
    setColetaStatus([]);
    setQtdColetaStatus([]);
  }

  useEffect(() => {
    async function carregarDados() {
      zeraDados();
      try {
        const dataDateInicial = formatDateBD(new Date(novaData));

        const dataDateFinal = formatDateBD(new Date(dataFinal));

        const responseQtdColetas = await api.get(
          `/api/ColetaDomiciliar/bi/quantidadePorDia/?dataInicial=${dataDateInicial}&dataFinal=${dataDateFinal}`
        );
        setQtdColetas(responseQtdColetas.data.qtd_por_dia);

        const responseColetas = await api.get(
          `/api/ColetaDomiciliar/bi/coletaPorColetador/?dataInicial=${dataDateInicial}&dataFinal=${dataDateFinal}`
        );
        capturaColetaColetador(responseColetas.data);

        const responseColetasStatus = await api.get(
          `/api/ColetaDomiciliar/bi/coletaPorStatus/?dataInicial=${dataDateInicial}&dataFinal=${dataDateFinal}`
        );
        capturaColetaSituacao(responseColetasStatus.data);
      } catch (error) {
        enqueueSnackbar("Falha na operação", { variant: "error" });
      }
    }
    carregarDados();
  }, [
    enqueueSnackbar,
    novaData,
    dataFinal,
    capturaColetaColetador,
    capturaColetaSituacao,
  ]);

  //-----------------------------------------------------------
  const pallet = [
    "#8a50ed",
    "#15f2a8",
    "#db8f02",
    "#a51738",
    "#167275",
    "#db9462",
    "#dd4b81",
    "#e0c164",
    "#bd00f2",
    "#23fc88",
    "#e80d6c",
    "#7D9FF5",
    "#FCD92B",
    "#74d604",
    "#3a419e",
  ];

  const coletadores = {
    labels: coletaColetador,
    datasets: [
      {
        label: "Coletas",
        data: qtdColetaColetador,
        backgroundColor: pallet,
      },
    ],
  };

  const estados = {
    labels: coletaStatus,
    datasets: [
      {
        label: "Coletas",
        data: qtdColetaStatus,
        backgroundColor: ["#42A5F5", "#63F557", "#FF7A43"],
      },
    ],
  };
  return (
    <div>
      <div>
        <Grid container spacing={2}>
          <Grid item xs={6} sm={6}>
            <Card>
              <CardContent>
                <Typography
                  variant="h5"
                  component="h1"
                  style={{ textAlign: "center" }}
                >
                  {qtdColetas || 0}
                </Typography>
                <Typography
                  variant="subtitle1"
                  component="h1"
                  style={{ textAlign: "center" }}
                >
                  Coletas Agendadas
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
      <div className="BarAmostras">
        <Paper className="PaperAmostras">
          <Bar
            data={coletadores}
            options={{
              showAllTooltips: true,
              plugins: {
                datalabels: {
                  display: "auto",
                  anchor: "end",
                  align: "end",
                  fontSize: 20,
                },
              },
              responsive: true,
              maintainAspectRatio: false,
              scales: {
                yAxes: [
                  {
                    ticks: {
                      beginAtZero: true,
                      min: 0,
                    },
                  },
                ],
              },

              title: {
                padding: 25,
                display: true,
                text: "Coletas por Coletadores",
                fontSize: 20,
              },
              legend: {
                display: false,
                position: "bottom",
              },
            }}
          />
        </Paper>
      </div>
      <div className="BarMapas">
        <Paper className="PaperMapas">
          <Bar
            data={estados}
            options={{
              showAllTooltips: true,
              plugins: {
                datalabels: { display: "auto", anchor: "end", align: "end" },
                responsive: true,
              },
              maintainAspectRatio: false,
              scales: {
                yAxes: [
                  {
                    ticks: {
                      beginAtZero: true,
                      min: 0,
                    },
                  },
                ],
              },

              title: {
                display: true,
                text: "Coletas por Situações",
                fontSize: 20,
                padding: 25,
              },
              legend: {
                display: false,
                position: "bottom",
              },
            }}
          />
        </Paper>
      </div>
    </div>
  );
}
