import React, { useContext, useState } from "react";
import { useSnackbar } from "notistack";
import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  TextField,
  Tooltip,
} from "@mui/material";
import { Add } from "@mui/icons-material";

import { RetornoLoteContext } from "../../Context/RetornoLoteContext";
import { ModalRecebimentoContext } from "../../Context/ModalRecebimentoContext";

import DataPickerAtualizado from "../../../../../components/DataPickerAtualizado";
import Waiter from "../../../../../components/Backdrop";

import formatoMoeda from "../../../../../utils/formatoMoeda";

import TabelaRecebimentoManual from "../TabelaRecebimentoManual";
import BotaoImportarAquivo from "../BotaoImportarArquivo";
import { Input } from "../../../../../componentsv2/Input";

export default function RecebimentoManual() {
  const { enqueueSnackbar } = useSnackbar();

  const {
    setGlosas,
    nota_fiscal,
    setNota_fiscal,
    data_recebimento,
    setData_recebimento,
    valor_recuperado,
    imposto,
    setImposto,
    desconto_unimed,
    setDesconto_unimed,
    valor_liquido,
    valor_glosado,
    valor_acatado,
    emAnalise,
    valor_diferenca_tabela,
    setValor_diferenca_tabela,
    valor_diferenca_unimed,
    setValor_diferenca_unimed,
    valorImposto,
    valorDesconto,
    observacoes,
    setObservacoes,
    salvaRetornoLote,
    zeraValores,
    setAtualizaValoresRecebimentoManual,
    loading,
    glosas,
    protocoloRecurso,
    setProtocoloRecurso,
    valorRecuperadoAtual,
  } = useContext(RetornoLoteContext);

  const { adicionaOuRetorna, dadosRetornoLoteRow } = useContext(
    ModalRecebimentoContext
  );
  const [dialogoConfirmar, setDialogoConfirmar] = useState(false);

  const novaLinha = {
    id: Math.floor(Date.now() * Math.random()).toString(36),
    situacao: "",
    motivo: "",
    valor_recebido: null,
  };

  return (
    <Container>
      <Paper sx={{ p: 4 }}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <DataPickerAtualizado
                  size="small"
                  labelName="Data do pagamento"
                  data={data_recebimento}
                  setData={setData_recebimento}
                  disabled={dadosRetornoLoteRow?.valor_recebido}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  autoFocus
                  id="nota-fiscal"
                  label="Nota Fiscal"
                  value={nota_fiscal}
                  onChange={(event) => setNota_fiscal(event.target.value)}
                  size="small"
                  disabled={dadosRetornoLoteRow?.valor_recebido}
                  fullWidth
                />
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  id="imposto_recebimento2"
                  label="Imposto %"
                  value={imposto}
                  onChange={(event) => setImposto(event.target.value)}
                  type="number"
                  size="small"
                  style={{ marginTop: "1rem" }}
                  disabled={dadosRetornoLoteRow?.valor_recebido}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="imposto_recebimento2-unimed"
                  label="Deconto Unimed %"
                  value={desconto_unimed}
                  onChange={(event) =>
                    setDesconto_unimed(event.target.value.replace(",", "."))
                  }
                  size="small"
                  style={{ marginTop: "1rem" }}
                  disabled={dadosRetornoLoteRow?.valor_recebido}
                  fullWidth
                />
              </Grid>
            </Grid>

            <TextField
              id="valor-recuperado2"
              label="Valor Recebido"
              value={formatoMoeda(valor_recuperado, true) || "0,00"}
              size="small"
              style={{ marginTop: "1rem" }}
              disabled={dadosRetornoLoteRow?.valor_recebido}
              fullWidth
            />

            <TextField
              id="valor-liquido2"
              label="Valor líquido"
              value={formatoMoeda(valor_liquido, true) || "0,00"}
              size="small"
              style={{ marginTop: "1rem" }}
              disabled={dadosRetornoLoteRow?.valor_recebido}
              fullWidth
            />

            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  id="valor-imposto2"
                  label="Valor Imposto R$"
                  value={formatoMoeda(valorImposto, true) || "0,00"}
                  size="small"
                  style={{ marginTop: "1rem" }}
                  disabled={dadosRetornoLoteRow?.valor_recebido}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="valor-desconto2-unimed"
                  label="Valor Desc. Unimed R$"
                  value={formatoMoeda(valorDesconto, true) || "0,00"}
                  size="small"
                  style={{ marginTop: "1rem" }}
                  disabled={dadosRetornoLoteRow?.valor_recebido}
                  fullWidth
                />
              </Grid>
            </Grid>

            <TextField
              id="valor-analise"
              label="Em recurso"
              value={formatoMoeda(emAnalise, true) || "0,00"}
              size="small"
              style={{ marginTop: "1rem" }}
              disabled={dadosRetornoLoteRow?.valor_recebido}
              fullWidth
            />

            <TextField
              id="valor-glosado"
              label="Valor Glosado"
              value={formatoMoeda(valor_glosado, true) || "0,00"}
              size="small"
              style={{ marginTop: "1rem" }}
              disabled={dadosRetornoLoteRow?.valor_recebido}
              fullWidth
            />

            <TextField
              id="valor-acatado"
              label="Valor Acatado"
              value={formatoMoeda(valor_acatado, true) || "0,00"}
              size="small"
              style={{ marginTop: "1rem" }}
              disabled={dadosRetornoLoteRow?.valor_recebido}
              fullWidth
            />

            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  id="diferenca-tabela"
                  label="Diferença de tabela"
                  value={formatoMoeda(valor_diferenca_tabela, true) || "0,00"}
                  onChange={(event) => {
                    setValor_diferenca_tabela(
                      event.target.value.replace(",", ".")
                    );
                    setAtualizaValoresRecebimentoManual(
                      (prevvState) => !prevvState
                    );
                  }}
                  size="small"
                  style={{ marginTop: "1rem" }}
                  disabled={dadosRetornoLoteRow?.valor_recebido}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="diferenca-tabela-unimed"
                  label="Diferença Unimed"
                  value={formatoMoeda(valor_diferenca_unimed, true) || "0,00"}
                  onChange={(event) => {
                    setValor_diferenca_unimed(
                      event.target.value.replace(",", ".")
                    );
                    setAtualizaValoresRecebimentoManual(
                      (prevvState) => !prevvState
                    );
                  }}
                  size="small"
                  style={{ marginTop: "1rem" }}
                  disabled={dadosRetornoLoteRow?.valor_recebido}
                  fullWidth
                />
              </Grid>
            </Grid>

            <TextField
              id="observacoes"
              label="Observações"
              value={observacoes}
              onChange={(event) => setObservacoes(event.target.value)}
              multiline
              minRows={2}
              size="small"
              style={{ marginTop: "1rem" }}
              disabled={dadosRetornoLoteRow?.valor_recebido}
              fullWidth
            />
          </Grid>

          <Grid item xs={8}>
            <div
              style={{ display: "flex", flex: 1, justifyContent: "flex-end" }}
            >
              {!dadosRetornoLoteRow?.valor_recebido ? (
                <Tooltip title="Importar xml">
                  <BotaoImportarAquivo />
                </Tooltip>
              ) : null}

              <Tooltip title="Inserir nova linha na tabela">
                <Button
                  disabled={dadosRetornoLoteRow?.valor_recebido}
                  style={{ margin: "0 1rem 1rem 0" }}
                  color="success"
                  variant="contained"
                  onClick={() =>
                    setGlosas((prevState) => [...prevState, novaLinha])
                  }
                >
                  <Add />
                </Button>
              </Tooltip>
            </div>
            <TabelaRecebimentoManual />
          </Grid>

          <Grid item xs={4}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Button
                  fullWidth
                  disabled={dadosRetornoLoteRow?.valor_recebido}
                  variant="contained"
                  color="success"
                  onClick={() => {
                    if (!nota_fiscal) {
                      enqueueSnackbar("Informe a nota fiscal", {
                        variant: "warning",
                      });
                    } else {
                      setDialogoConfirmar(true);
                    }
                  }}
                >
                  Concluir
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  fullWidth
                  variant="outlined"
                  color="error"
                  onClick={() => {
                    adicionaOuRetorna();
                    zeraValores();
                  }}
                >
                  {dadosRetornoLoteRow?.valor_recebido ? "Voltar" : "Cancelar"}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>

      <Dialog
        open={dialogoConfirmar}
        onClose={() => setDialogoConfirmar(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialogo">
          Todos os valores foram adicionados?
        </DialogTitle>

        {glosas?.find((glosa) => glosa?.situacao === "Analise") ? (
          <DialogContent>
            <Input
              value={protocoloRecurso}
              onChange={(e) => setProtocoloRecurso(e.target.value)}
              fullWidth
              label="Protocolo de recurso"
              sx={{ marginTop: 1 }}
            />
          </DialogContent>
        ) : null}

        <DialogActions>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => setDialogoConfirmar(false)}
          >
            Cancelar
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              if (
                glosas?.find((glosa) => glosa?.situacao === "Analise") &&
                protocoloRecurso === ""
              ) {
                enqueueSnackbar(
                  "Informe o protocolo de recurso para as glosas em análise",
                  { variant: "warning" }
                );
              } else {
                salvaRetornoLote();
                setDialogoConfirmar(false);
              }
            }}
            autoFocus
          >
            Salvar
          </Button>
        </DialogActions>
      </Dialog>

      <Waiter espera={loading} />
    </Container>
  );
}
