import React, { useContext, useState } from "react";
import { useSnackbar } from "notistack";

import GridItem from "../../../../componentsv2/GridItem";
import { ModalForm } from "../../../../componentsv2/ModalForm";
import { Input } from "../../../../componentsv2/Input";

import { Context } from "../../../../Context/PermissoesContext";

import { api } from "../../../../services/Api";

export default function ModalProcedimento ({ open, onClose }) {
  const [nome, setNome] = useState("");
  const [precoExame, setPrecoExame] = useState(null);
  const [descricao, setDescricao] = useState("");
  const { enqueueSnackbar } = useSnackbar()
  const { checarPermissao } = useContext(Context);
  const onSubmit = async () => {
    try {
      await api.post("/v2/agendaCare/procedimento", {
        nome,
        precoExame,
        descricao,
      });
      onClose();
    } catch (error) {
    
      enqueueSnackbar("Erro ao tentar adiconar procedimento!", { variant: "error" });
    }
  };

  return (
    <ModalForm
      onSubmit={onSubmit}
      open={open}
      onClose={onClose}
      title="Adicionar Procedimento"
    >
      <GridItem xs={6}>
        <Input
          required
          fullWidth
          onChange={e => {
            setNome(e.target.value);
          }}
          value={nome}
          label="Nome"
          type="text"
          variant="standard"
        />
      </GridItem>
      {
      checarPermissao("AGC002") &&
      <GridItem xs={6}>
        <Input
          required
          fullWidth
          onChange={e => {
            setPrecoExame(e.target.value);
          }}
          value={precoExame}
          label="Valor"
          type="number"
          variant="standard"
        />
      </GridItem>
      }
      <GridItem xs={12}>
        <Input
          onChange={e => {
            setDescricao(e.target.value);
          }}
          value={descricao}
          fullWidth
          label="Descrição"
          type="text"
          variant="standard"
          multiline
          maxRows={5}
        />
      </GridItem>
    </ModalForm>
  );
}
