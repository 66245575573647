import { Button, Grid, Paper, TextField } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useContext, useEffect, useState } from "react";
import { Context as PermissoesContext } from "../../../Context/PermissoesContext";
import { api } from "../../../services/Api";
import filtroPesquisa from "../../../utils/filtroPesquisa";
import ModalCadastro from "../Modal";
import TabelaExibeUsuario from "../Tabela/index";
import "./styles.css";

export default function AdicionaUsuario({ setAtualizar, atualizar, details }) {
  const [open, setOpen] = useState(false);
  const [senha, setSenha] = useState("");
  const [nome, setNome] = useState("");
  const [user, setUser] = useState("");
  const [email, setEmail] = useState("");
  const [cpf, setCpf] = useState("");
  const [funcao, setFuncao] = useState([]);
  const [unidades, setUnidades] = useState([]);
  const [setores, setSetores] = useState([]);

  const [pesquisado, setPesquisado] = useState("");

  const { enqueueSnackbar } = useSnackbar();
  const { checarPermissao } = useContext(PermissoesContext);

  useEffect(() => {
    setAtualizar(!atualizar);
    // eslint-disable-next-line
  }, [open]);

  useEffect(() => {
    async function chamaFuncao() {
      try {
        const getFuncoes = await api.get("/v2/arh/funcoes");
        const getSetores = await api.get("/v2/arh/setores");
        const getUnidades = await api.get("/v2/geral/unidades");

        setUnidades(getUnidades.data.unidade);
        setFuncao(getFuncoes.data.funcoes);
        setSetores(getSetores.data.setores);
      } catch (error) {
        enqueueSnackbar("Falha ao obter as funções", {
          variant: "error",
        });
      }
    }
    chamaFuncao();
  }, [enqueueSnackbar]);

  // const pesquisaFiltrada = details.filter(pop => {
  //   if (pop.nome_usu) {
  //     return pop.nome_usu.toLowerCase().includes(pesquisado.toLowerCase());
  //   }
  //   return false;
  // });

  const handleChangePesquisa = (e) => {
    setPesquisado(e.target.value);
  };

  function handleOpenModalCadastro() {
    if (!checarPermissao("CU002")) {
      enqueueSnackbar("Você não possui permissão para cadastrar usuários", {
        variant: "error",
      });
    } else {
      setOpen(true);
    }
  }

  return (
    <>
      <Paper className="paper-cadastro" elevation={3}>
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          style={{ padding: "1rem 1rem" }}
        >
          <Grid item xs={12} sm={6}>
            {/* <TextField
              id="outlined-basic"
              label="Pesquise pelo nome ou usuário"
              variant="outlined"
              size="small"
              fullWidth
              onChange={handleChangePesquisa}
            /> */}
          </Grid>

          <Grid id="botao-cadastrar" item xs={12} sm={6}>
            <Button
              variant="contained"
              onClick={handleOpenModalCadastro}
              style={{ color: "#fff", backgroundColor: "#003399" }}
            >
              Cadastrar Usuário
            </Button>
            <ModalCadastro
              open={open}
              setOpen={setOpen}
              senha={senha}
              setSenha={setSenha}
              user={user}
              setUser={setUser}
              nome={nome}
              setNome={setNome}
              email={email}
              setEmail={setEmail}
              cpf={cpf}
              setCpf={setCpf}
              funcao={funcao}
              unidades={unidades}
              setores={setores}
            />
          </Grid>
        </Grid>
      </Paper>

      <Paper className="paper-cadastro" elevation={3}>
        <TabelaExibeUsuario rows={details} modal={setOpen} />
      </Paper>
    </>
  );
}
