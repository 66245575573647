import React from "react";
import { FormControl, InputLabel, Select as MuiSelect, MenuItem } from "@mui/material";

// Observações:
// - defaultValue, textAttribute, valueAttribute, onChange, value, label, arrayOptions  são necessários
// -
// eslint-disable-next-line
export function Select({
  label,
  value,
  onChange,
  arrayOptions = [],
  valueAttribute,
  textAttribute,
  ...rest
}) {
  return (
    <FormControl fullWidth>
      <InputLabel {...rest}>{label}</InputLabel>
      <MuiSelect onChange={onChange} value={value} label={label} {...rest}>
        {arrayOptions.map(item =>
          valueAttribute && textAttribute ? (
            <MenuItem key={item[valueAttribute]} value={item[valueAttribute]}>
              {item[textAttribute]}
            </MenuItem>
          ) : (
            <MenuItem key={item} value={item}>
              {item}
            </MenuItem>
          )
        )}
      </MuiSelect>
    </FormControl>
  );
}
