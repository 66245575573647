import React, { createContext, useContext, useEffect, useState } from "react";
import { api } from "../../../services/Api";
import { enqueueSnackbar } from "notistack";
import { Context as AuthContext } from "../../../Context/AuthContext";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import useUser from "../../../hooks/useUser";
import { Context as PermissoesContext } from "../../../Context/PermissoesContext";

const DadosEstoqueContext = createContext({});

export function DadosEstoqueProvider({ children }) {
  const [dataInicial, setDataInicial] = useState(new Date("01-01-2010"));
  const [dataFinal, setDataFinal] = useState(new Date());

  const [dataInicialTransferencias, setDataInicialTransferencias] = useState(
    new Date()
  );
  const [dataFinalTransferencias, setDataFinalTransferencias] = useState(
    new Date()
  );
  const history = useHistory();

  const [localizacaoBaixa, setLocalizacaoBaixa] = useState("");
  const [tipoBaixa, setTipoBaixa] = useState("");

  const [situacaoSelecionada, setSituacaoSelecionada] = useState("");
  const [localSelecionado, setLocalSelecionado] = useState("");

  const [localSelecionadoResumo, setLocalSelecionadoResumo] = useState("");

  const [semAcessoUnidade, setSemAcessoUnidade] = useState(false);

  const [semAcessoUnidadeTransferencias, setSemAcessoUnidadeTransferencia] =
    useState(false);

  const [dataInicialFormatada, setDataInicialFormatada] = useState("");
  const [dataFinalFormatada, setDataFinalFormatada] = useState("");
  const [update, setUpdate] = useState(false);
  const [sincronizar, setSincronizar] = useState(false);
  const [todosOsLocais, setTodosOsLocais] = useState([]);
  const [status, setStatus] = useState("");
  const [localOrigem, setLocalOrigem] = useState("");
  const [localDestino, setLocalDestino] = useState("");
  const [estoqueAtual, setEstoqueAtual] = useState({ nome: "Todos", id: "" });
  const { checarPermissao } = useContext(PermissoesContext);
  const [updateDadosFuncionario, setUpdateDadosFuncionario] = useState(false);

  const { authenticated } = useContext(AuthContext);

  const [idUnidadeUsuario, setIdUnidadeUsuario] = useState("");

  const [listaUnidades, setListaUnidades] = useState([]);
  const usuario = useUser();
  const limparFiltros = () => {
    setDataInicial(null);
    setDataFinal(null);
  };

  useEffect(() => {
    async function pegaTodasUnidades() {
      try {
        const { data } = await api.get("/v2/almoxarifado/estoque/unidades");

        setListaUnidades(data.unidades);
      } catch (e) {
        enqueueSnackbar("Falha ao obter as unidades", { variant: "error" });
      }
    }

    if (authenticated) {
      pegaTodasUnidades();
    }
  }, [enqueueSnackbar, authenticated]);

  useEffect(() => {
    const existe = listaUnidades.find(
      (el) => el.unidade_id == idUnidadeUsuario
    );

    if (!existe && semAcessoUnidade) {
      setLocalSelecionado("");
    } else {
      if (!existe) {
        setLocalSelecionado("");
      } else {
        setLocalSelecionado(idUnidadeUsuario);
      }
    }
  }, [idUnidadeUsuario, semAcessoUnidade, listaUnidades]);

  useEffect(() => {
    async function checaUnidadeFuncionario() {
      const { data } = await api.get(`v2/controleAcesso/funcionarioUnidade`, {
        params: { cod_usu: usuario },
      });

      if (
        history.location?.state?.from === "/almoxarifado/estoque/visualizar"
      ) {
        return;
      } else {
        if (!checarPermissao("AL014")) {
          if (data.funcionario && data.funcionario.unidade_id) {
            setSemAcessoUnidade(true);
            setIdUnidadeUsuario(data.funcionario.unidade_id);
            setLocalSelecionado(data.funcionario.unidade_id);
          } else {
            history.push("/home");
            enqueueSnackbar(
              "Solicite o cadastramento da unidade do seu usuário!",
              {
                variant: "warning",
              }
            );
          }
        } else if (data.funcionario && data.funcionario.unidade_id) {
          setSemAcessoUnidade(false);

          if (
            !todosOsLocais?.find(
              (el) => el.unidade_id == data.funcionario.unidade_id
            )
          ) {
            setLocalSelecionado("");
          } else {
            setLocalSelecionado(data.funcionario.unidade_id);
          }
        } else {
          history.push("/home");
          enqueueSnackbar(
            "Solicite o cadastramento da unidade do seu usuário!",
            {
              variant: "warning",
            }
          );
        }
      }
    }
    if (authenticated) {
      checaUnidadeFuncionario();
    }
    // eslint-disable-next-line
  }, [
    updateDadosFuncionario,
    usuario,
    history,
    todosOsLocais,
    authenticated,
    checarPermissao,
  ]);

  return (
    <DadosEstoqueContext.Provider
      value={{
        localSelecionado,
        setLocalSelecionado,
        dataInicial,
        setDataInicial,
        dataFinal,
        setDataFinal,
        situacaoSelecionada,
        setSituacaoSelecionada,
        semAcessoUnidade,
        dataInicialFormatada,
        setDataInicialFormatada,
        dataFinalFormatada,
        setDataFinalFormatada,
        update,
        setUpdate,
        sincronizar,
        setSincronizar,
        setSemAcessoUnidade,
        todosOsLocais,
        setTodosOsLocais,
        limparFiltros,
        status,
        setStatus,
        localOrigem,
        setLocalOrigem,
        localDestino,
        setLocalDestino,
        dataInicialTransferencias,
        setDataInicialTransferencias,
        dataFinalTransferencias,
        setDataFinalTransferencias,
        semAcessoUnidadeTransferencias,
        setSemAcessoUnidadeTransferencia,
        setLocalizacaoBaixa,
        localizacaoBaixa,
        tipoBaixa,
        setTipoBaixa,
        estoqueAtual,
        setEstoqueAtual,
        idUnidadeUsuario,
        setIdUnidadeUsuario,
        localSelecionadoResumo,
        setLocalSelecionadoResumo,
        listaUnidades,
        setListaUnidades,
        updateDadosFuncionario,
        setUpdateDadosFuncionario,
      }}
    >
      {children}
    </DadosEstoqueContext.Provider>
  );
}
export default DadosEstoqueContext;
