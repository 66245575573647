import React from 'react';

import "./styles.css";

export default function Abas(props) {
  const { tabela, status_pcr, setStatus_pcr, status_atual, setStatus_atual, setN } = props;

    return (
         (tabela === 'placa')
            ? (
              <div className="container-buttons-menu-samples">
                <button
                  type="button"
                  className={(status_atual === 'Triagem') ? "button-menu-samples-on" : "button-menu-samples"}
                  onClick={async () => {
                    setStatus_atual('Triagem');
                    setN(0);
                  }}
                >
                  Triagem
                </button>

                <button
                  type="button"
                  className={(status_atual === 'Pré-Extração') ? "button-menu-samples-on" : "button-menu-samples"}
                  onClick={async () => {
                    setStatus_atual('Pré-Extração');
                    setN(1);
                  }}
                >
                  Pré-Extração
                </button>

                <button
                  type="button"
                  className={(status_atual === 'Extração') ? "button-menu-samples-on" : "button-menu-samples"}
                  onClick={async () => {
                    setStatus_atual('Extração');
                    setN(2);
                  }}
                >
                  Extração
                </button>

                <button
                  type="button"
                  className={(status_atual === 'PCR') ? "button-menu-samples-on" : "button-menu-samples"}
                  onClick={async () => {
                    setStatus_atual('PCR');
                    setN(3);
                  }}
                >
                  PCR
                </button>

                <button
                  type="button"
                  className={(status_atual === 'Processado') ? "button-menu-samples-on" : "button-menu-samples"}
                  onClick={async () => {
                    setStatus_atual('Processado');
                    setN(4);
                  }}
                >
                  Processado
                </button>
              </div>
              )

            : (
              <div className="container-buttons-menu-samples">
                <button
                  type="button"
                  className={(status_pcr === "Preparação") ? "button-menu-samples-on" : "button-menu-samples"}
                  onClick={async () => {
                    if (status_pcr !== "Preparação") {
                      setStatus_pcr("Preparação");
                    }
                  }}
                >
                  Preparação
                </button>

                <button
                  type="button"
                  className={(status_pcr === 'Em processamento') ? "button-menu-samples-on" : "button-menu-samples"}
                  onClick={async () => {
                    if (status_pcr !== 'Em processamento') {
                        setStatus_pcr('Em processamento');
                    }
                    }}
                >
                  Em processamento
                </button>

                <button
                  type="button"
                  className={(status_pcr === 'Em análise') ? "button-menu-samples-on" : "button-menu-samples"}
                  onClick={async () => {
                    if (status_pcr !== 'Em análise') {
                      setStatus_pcr('Em análise');
                    }
                  }}
                >
                  Em análise
                </button>

                <button
                  type="button"
                  className={(status_pcr === 'Concluído') ? "button-menu-samples-on" : "button-menu-samples"}
                  onClick={async () => {
                    if (status_pcr !== 'Concluído') {
                      setStatus_pcr('Concluído');
                    }
                  }}
                >
                  Concluído
                </button>
              </div>
            )
    )
}
