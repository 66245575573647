import React, { useContext } from "react";
import { Paper } from "@mui/material";

import { RecebimentoContext } from "../../Context/RecebimentoContext";
import TotalizadoresRecebimento from "../TotalizadoresRecebimento";

// import BotaoImportarAquivo from "../BotaoImportarArquivo";

export default function FormularioRecebimento() {
   const { aba, setAba, valores } = useContext(RecebimentoContext);

  return (
    <div>
      <Paper style={{ padding: "1rem", marginBottom: "1rem" }}>
        <TotalizadoresRecebimento valores={valores} aba={aba} setAba={setAba} />
      </Paper>

      {/* <BotaoImportarAquivo /> */}
    </div>
  );
}
