import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import SettingsIcon from "@mui/icons-material/Settings";
import AddIcon from "@mui/icons-material/Add";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Button, Container, Grid, Modal, Paper } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { Context as PermissoesContext } from "../../../../Context/PermissoesContext";

export default function Opcoes({ row }) {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { checarPermissao } = useContext(PermissoesContext);
  const [open, setOpen] = useState(false);

  function ModalOption(props) {
    const { rows } = props;

    async function handleClickAdd() {
      if (await !checarPermissao("AL009")) {
        enqueueSnackbar("É necessário a permissão AL009", {
          variant: "warning",
        });
      } else {
        history.push({
          pathname: "/almoxarifado/estoque/adicionar",
          state: {
            id_produto: rows.id,
            nome: rows.nome,
            quantidade: rows.quantidade,
            quantidadeUnitaria: rows.quantidade_total_unitaria
              ? rows.quantidade_total_unitaria
              : 0,
            local: rows.nomeLocal,
            id_produto_estoque: rows.id,
            edit: false,
            local_baixa: rows.idUnidade,
          },
        });
      }
    }

    async function handleClickEdit() {
      if (await !checarPermissao("AL010")) {
        enqueueSnackbar("É necessário a permissão AL010", {
          variant: "warning",
        });
      } else {
        history.push({
          pathname: "/almoxarifado/estoque/adicionar",
          state: {
            nome: rows.nome,
            quantidade: rows.quantidade,
            quantidadeUnitaria: rows.quantidade_total_unitaria,
            id_produto_estoque: rows.id,
            local: rows.nomeLocal,
            local_baixa: rows.idUnidade,
            edit: true,
          },
        });
      }
    }

    async function handleClickVisibility() {
      if (await !checarPermissao("AL008")) {
        enqueueSnackbar("É necessário a permissão AL008", {
          variant: "warning",
        });
      } else {
        history.push({
          pathname: "/almoxarifado/estoque/visualizar",
          state: {
            dados: rows,
          },
        });
      }
    }

    return (
      <Container className="options-container" maxWidth="xs">
        <Paper className="options-paper" elevation={2}>
          <Grid container style={{ padding: "1rem 1rem" }}>
            <Grid item sm={12}>
              <Button
                onClick={() => handleClickVisibility()}
                variant="standard"
                startIcon={<VisibilityIcon />}
              >
                Visualizar informações
              </Button>
            </Grid>
            <Grid item sm={12}>
              <Button
                onClick={() => handleClickAdd()}
                variant="standard"
                startIcon={<AddIcon />}
              >
                Adicionar estoque
              </Button>
            </Grid>
            {rows.quantidade > 0 && (
              <Grid item sm={12}>
                <Button
                  onClick={handleClickEdit}
                  variant="standard"
                  startIcon={<ArrowDownwardIcon />}
                >
                  Dar baixa no produto
                </Button>
              </Grid>
            )}

            <Grid item sm={12}>
              <Button
                onClick={() => setOpen(false)}
                variant="standard"
                startIcon={<KeyboardReturnIcon />}
              >
                Voltar
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Container>
    );
  }
  return (
    <>
      <Button style={{ color: "#000" }} onClick={() => setOpen(true)}>
        <SettingsIcon />
      </Button>
      <Modal open={open} onClose={() => setOpen(false)}>
        <ModalOption rows={row} />
      </Modal>
    </>
  );
}
