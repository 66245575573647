import React, { useContext, useState } from "react";

import SettingsIcon from '@mui/icons-material/Settings';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import EditIcon from "@mui/icons-material/Edit";
import { Button as MuiButton, Container, Grid, Modal, Paper } from "@mui/material";

import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import { Context as PermissoesContext } from "../../../Context/PermissoesContext";
import Button from "../../../componentsv2/Button";

export default function Opcoes({ row }) {
  const [open, setOpen] = useState(false);
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { checarPermissao } = useContext(PermissoesContext);

  function ModalOption(props) {
    const handleClickEdit = () => {
      if (!checarPermissao("GEC003")) {
        enqueueSnackbar("É necessário a permissão GEC003", {
          variant: "error",
        });
      } else {
        history.push({
          pathname: "/financeiro/fornecedor/criar",
          state: { dados: props.row },
        });
      }
    };
    return (
      <Container className="options-container" maxWidth="xs">
        <Paper className="options-paper" elevation={2}>
          <Grid container style={{ padding: "1rem 1rem" }}>
            <Grid item sm={12}>
              <Button
                onClick={() => {
                  if (checarPermissao("GEC003")) {
                    handleClickEdit();
                  } else {
                    enqueueSnackbar(
                      "Você não possui permissão para editar fornecedores!",
                      {
                        variant: "error",
                        autoHideDuration: 3000,
                      }
                    );
                  }
                }}
                variant="standard"
                startIcon={<EditIcon />}
              >
                Editar
              </Button>
            </Grid>
            <Grid item sm={12}>
              <Button
                onClick={() => setOpen(false)}
                variant="standard"
                startIcon={<ArrowBackIcon />}
              >
                Voltar
              </Button>
            </Grid>
          </Grid>
        </Paper>
        {/* <Formulario
          handleClose={handleCloseEdit}
          open={openEdit}
          url={`v2/gestaoContratos/fornecedor/${row.id}`}
          method="PUT"
          row={row}
        /> */}
      </Container>
    );
  }

  return (
    <>
      <MuiButton style={{ color: "#000" }} onClick={() => setOpen(true)}>
        <SettingsIcon />
      </MuiButton>
      <Modal open={open} onClose={() => setOpen(false)}>
        <ModalOption row={row} />
      </Modal>
    </>
  );
}
