export default function converteDataUS(data) {

    const data_conv =
        `${data.slice(6, 10)
        }-${
        data.slice(3, 5)
        }-${
        data.slice(0, 2)}`;

    return data_conv;
  }
